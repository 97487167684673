import React, { useState, useCallback, useMemo } from "react";
import { Checkbox, Button, useToasts } from "@puzzle/ui";
import { useActiveCompany } from "components/companies";
import { CompanySettingFeatureName } from "graphql/types";
import Analytics from "lib/analytics";
import { useUpdateCompanyFeatureMutation } from "components/companies/graphql.generated";
import { DetailText, Label, TextGroup } from "./shared";
import { Box, S } from "ve";

export const AutomatedAccrualRevenueSection = () => {
  const { company } = useActiveCompany<true>();
  const [enableAccrualRevenue, setEnableAccrualRevenue] = useState(false);
  const [updateCompanyFeature] = useUpdateCompanyFeatureMutation();
  const { toast } = useToasts();

  const isEnabled = useMemo(
    () => company.features.accrualEventsEnabled === true,
    [company.features.accrualEventsEnabled]
  );

  const handleClick = useCallback(() => {
    return updateCompanyFeature({
      variables: {
        input: {
          companyId: company.id,
          featureName: CompanySettingFeatureName.AccrualRevenueEventsEnabled,
          value: true,
        },
      },
      onCompleted(data) {
        if (data?.updateCompanyFeature?.company?.features.accrualEventsEnabled) {
          toast({ status: "success", message: "Accrual revenue enabled" });
          Analytics.accrualEventsEnabled({ companyId: company.id });
        }
      },
      onError(err) {
        toast({
          title: "Accrual revenue enable failed",
          message: `Something went wrong, and our team has been notified. We apologize for the inconvenience. Please try again in a few minutes.`,
          status: "warning",
        });
      },
    });
  }, [updateCompanyFeature, company.id, toast]);

  return (
    <>
      <TextGroup css={{ gap: "$2", textAlign: "left" }}>
        <Label>
          {isEnabled ? "Automatic accrual revenue is activated" : "Automatic accrual revenue"}
        </Label>
        <DetailText>
          Automatically handle accrual revenue calculations and recognition based on predefined
          rules and configurations for Stripe transactions. Revenue recognition calculations use a
          30/360 convention, with a prorated first month.
        </DetailText>
        {!isEnabled && (
          <Box css={{ display: "flex", gap: S["1"], alignItems: "flex-start" }}>
            <Checkbox
              checked={enableAccrualRevenue}
              onCheckedChange={(value) => {
                setEnableAccrualRevenue(Boolean(value));
              }}
            />
            <DetailText css={{ marginTop: "-2px" }}>
              Activating Automated Accrual Revenue will post revenue deferral and recognition
              entries to your accrual financial statements and currently, once activated, cannot be
              disabled.
            </DetailText>
          </Box>
        )}
      </TextGroup>
      {!isEnabled && (
        <Button disabled={!enableAccrualRevenue} css={{ width: "100%" }} onClick={handleClick}>
          Activate
        </Button>
      )}
    </>
  );
};
