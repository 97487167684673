import * as Types from '../../../graphql/types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetProvisionGustoAccountUrlQueryVariables = Types.Exact<{
  input: Types.GetProvisionGustoAccountUrlInput;
}>;


export type GetProvisionGustoAccountUrlQuery = { __typename?: 'Query', getProvisionGustoAccountUrl: { __typename?: 'GustoAccountClaimUrl', accountClaimUrl: string } };


export const GetProvisionGustoAccountUrlDocument = gql`
    query getProvisionGustoAccountUrl($input: GetProvisionGustoAccountUrlInput!) {
  getProvisionGustoAccountUrl(input: $input) {
    accountClaimUrl
  }
}
    `;

/**
 * __useGetProvisionGustoAccountUrlQuery__
 *
 * To run a query within a React component, call `useGetProvisionGustoAccountUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProvisionGustoAccountUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProvisionGustoAccountUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProvisionGustoAccountUrlQuery(baseOptions: Apollo.QueryHookOptions<GetProvisionGustoAccountUrlQuery, GetProvisionGustoAccountUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProvisionGustoAccountUrlQuery, GetProvisionGustoAccountUrlQueryVariables>(GetProvisionGustoAccountUrlDocument, options);
      }
export function useGetProvisionGustoAccountUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProvisionGustoAccountUrlQuery, GetProvisionGustoAccountUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProvisionGustoAccountUrlQuery, GetProvisionGustoAccountUrlQueryVariables>(GetProvisionGustoAccountUrlDocument, options);
        }
export type GetProvisionGustoAccountUrlQueryHookResult = ReturnType<typeof useGetProvisionGustoAccountUrlQuery>;
export type GetProvisionGustoAccountUrlLazyQueryHookResult = ReturnType<typeof useGetProvisionGustoAccountUrlLazyQuery>;
export type GetProvisionGustoAccountUrlQueryResult = Apollo.QueryResult<GetProvisionGustoAccountUrlQuery, GetProvisionGustoAccountUrlQueryVariables>;