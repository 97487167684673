import React from "react";
import Icon, { IconProps } from "../Icon";

export default function Rippling({
  viewBox = "0 0 48 48",
  width = 48,
  height = 48,
  fill = "#502D3C",
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} {...iconProps} viewBox={viewBox}>
      <path
        fill={fill}
        d="M14.127 19.526c0-2.192-1.09-4.004-3.127-5.526h4.735a6.98 6.98 0 012.68 5.526 6.979 6.979 0 01-2.68 5.527c1.537.652 2.412 2.247 2.412 4.53v4.349H13.86v-4.35c0-2.174-1.019-3.696-2.859-4.53 2.037-1.521 3.127-3.333 3.127-5.526zm9.291 0c0-2.192-1.09-4.004-3.127-5.526h4.735a6.979 6.979 0 012.68 5.526 6.979 6.979 0 01-2.68 5.527c1.537.652 2.413 2.247 2.413 4.53v4.349H23.15v-4.35c0-2.174-1.018-3.696-2.858-4.53 2.036-1.521 3.126-3.333 3.126-5.526zm9.294 0c0-2.192-1.09-4.004-3.127-5.526h4.735A6.98 6.98 0 0137 19.526a6.979 6.979 0 01-2.68 5.527c1.536.652 2.412 2.247 2.412 4.53v4.349h-4.288v-4.35c0-2.174-1.019-3.696-2.86-4.53 2.038-1.521 3.128-3.333 3.128-5.526z"
      />
    </Icon>
  );
}
