import React, { useCallback, useMemo, useState } from "react";
import { compact, uniqBy, uniqueId } from "lodash";
import { useApolloClient } from "@apollo/client";

import { VendorFragment, YesNoUnknown } from "graphql/types";

import { makePermaName, useCreateVendor } from "components/common/hooks/vendors";
import DropdownField, { DropdownFieldProps } from "../DropdownField";
import { Stack, useToasts } from "@puzzle/ui";
import { VendorFragmentDoc } from "graphql/fragments/vendor.generated";

import { useVendorSearch } from "./useVendorSearch";

type VendorSelectProps = {
  onSelect: (vendor: VendorFragment | null) => void;
  onCreate?: (vendor: VendorFragment) => void;
  value?: VendorFragment | null;
  canEdit?: boolean;
  suggestAutoGenerateRuleContent?: React.ReactNode;
  fullWidth?: boolean;
  menuCss?: React.CSSProperties;
} & Pick<DropdownFieldProps<VendorFragment>, "emptyState" | "customTrigger">;

export const makeOptimisticVendor = (name: string): VendorFragment => ({
  __typename: "Vendor",
  id: uniqueId("vendor"),
  name,
  permaName: makePermaName(name),
  is1099Vendor: YesNoUnknown.Unknown,
});

export const VendorSelect = ({
  onSelect,
  onCreate,
  canEdit = true,
  value,
  suggestAutoGenerateRuleContent,
  fullWidth = false,
  menuCss,
  ...props
}: VendorSelectProps) => {
  const client = useApolloClient();
  const { toast } = useToasts();
  const [filterInput, setFilterInput] = useState<string>("");
  const [suggestRuleVisible, setSuggestRuleVisible] = useState<boolean>(false);

  const { data, loading, variables } = useVendorSearch(filterInput);

  const [_createVendor] = useCreateVendor({
    onError(error) {
      toast({
        message: error?.message || "Something went wrong while creating the vendor.",
        status: "error",
      });
    },
  });

  const handleSelect = useCallback(
    (data: VendorFragment | null) => {
      setSuggestRuleVisible(true);
      onSelect(data);
    },
    [onSelect]
  );

  const createVendor = useCallback(
    async (name: string) => {
      const optimisticVendor = makeOptimisticVendor(name);

      if (onCreate) {
        setSuggestRuleVisible(true);
        onCreate(optimisticVendor);
      } else {
        // If the consumer doesn't create on its own, send the optimistic and final vendors
        // There could be a race condition if the request takes too long and a user picks something else.
        // Not too worried for now...
        const id = `Vendor:${optimisticVendor.id}`;
        client.writeFragment<VendorFragment>({
          id,
          fragment: VendorFragmentDoc,
          fragmentName: "vendor",
          data: optimisticVendor,
        });
        handleSelect(optimisticVendor);
        const result = await _createVendor({ name });
        handleSelect(result.data?.createVendor?.vendor ?? null);
      }
    },
    [_createVendor, client, onCreate, handleSelect]
  );

  const vendorsFromQuery = loading ? undefined : data?.company?.vendors.items;
  const vendors = useMemo(
    () => uniqBy(compact([value, ...(vendorsFromQuery || [])]), "id"),
    [value, vendorsFromQuery]
  );

  return (
    <Stack
      direction="horizontal"
      gap="1"
      css={{
        alignItems: "center",
      }}
    >
      <DropdownField<VendorFragment>
        loading={loading}
        items={vendors}
        filter={filterInput}
        setFilter={setFilterInput}
        onSelect={handleSelect}
        canEdit={canEdit}
        onCreate={createVendor}
        label="Pick a vendor"
        value={value}
        getOptionLabel={(v) => v.name}
        getFreeSoloOption={(name) => ({
          name,
          permaName: name,
          is1099Vendor: YesNoUnknown.Unknown,
        })}
        getEmptyOption={() => ({
          name: "No vendor",
          permaName: "",
          is1099Vendor: YesNoUnknown.Unknown,
        })}
        fullWidth={fullWidth}
        {...props}
        menuCss={menuCss}
      />
      {suggestRuleVisible && suggestAutoGenerateRuleContent ? suggestAutoGenerateRuleContent : null}
    </Stack>
  );
};
