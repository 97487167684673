import React from "react";
import Icon, { IconProps } from "./Icon";

export default function BookkeepingService({
  viewBox = "0 0 62 52",
  width = 62,
  height = 52,
  fill = "none",
  ...iconProps
}: IconProps) {
  return (
    <Icon {...iconProps} width={width} height={height} fill={fill} viewBox={viewBox}>
      <path
        fill="#AB7DE3"
        d="M58.34 6.043v31.195c0 .595-.463 1.105-1.004 1.105h-.077c-8.577-.425-17.309 2.38-25.886 8.16h-.078v.085c-.077.085-.154.085-.231.085-.078.085-.155.085-.232.085-.232 0-.387-.085-.541-.17-8.5-5.865-17.155-8.67-25.732-8.245-.54.085-1.159-.425-1.159-1.105V6.043H0v36.89c10.277.085 20.71 2.805 30.986 8.245 10.2-5.44 20.555-8.33 30.832-8.33V6.043h-3.477z"
        opacity="0.4"
      ></path>
      <path
        fill="#AB7DE3"
        d="M29.905 43.697v-35.7C21.79 2.558 13.523-.162 5.409.008v36.126h.696c7.881 0 15.918 2.464 23.8 7.564zM56.41.008c-8.037-.17-16.382 2.55-24.496 7.99v35.784c8.114-5.184 16.382-7.734 24.495-7.564V.008z"
      ></path>
    </Icon>
  );
}
