import { useAccountQuery } from "graphql/types";

export default function useAccount({ id }: { id?: string }) {
  const { data, refetch, loading } = useAccountQuery({
    skip: !id,
    variables: id ? { id } : undefined,
  });

  return {
    account: data?.account,
    financialInstitution: data?.account.financialInstitution,
    connection: data?.account.connection,
    loading,
    refetch,
  };
}
