import { makeVar, useReactiveVar } from "@apollo/client";
import { useCallback } from "react";

export enum ImportType {
  Transaction = "TRANSACTION",
  Bill = "BILL",
}

const importsInProgressVar = makeVar<{
  [ImportType.Transaction]: number;
  [ImportType.Bill]: number;
}>({
  [ImportType.Transaction]: 0,
  [ImportType.Bill]: 0,
});

export const useIsImporting = (type: ImportType) => {
  const importsInProgress = useReactiveVar(importsInProgressVar);

  const startImportProgress = useCallback(() => {
    importsInProgressVar({
      ...importsInProgressVar(),
      ...{ [type]: importsInProgressVar()[type] + 1 },
    });
  }, [type]);

  const endImportProgress = useCallback(() => {
    importsInProgressVar({
      ...importsInProgressVar(),
      ...{ [type]: Math.max(importsInProgressVar()[type] - 1, 0) },
    });
  }, [type]);

  return {
    isImporting: importsInProgress[type] > 0,
    startImportProgress,
    endImportProgress,
  };
};
