import React from "react";
import { styled, Dialog } from "@puzzle/ui";
import Loader from "components/common/Loader";
import { usePrevious } from "react-use";

const Description = styled("div", {
  marginTop: "$3",
  color: "$gray50",
  textVariant: "$bodyM",
  textAlign: "center",
});

const LoadingModal = ({ accountType, open }: { accountType?: string; open: boolean }) => {
  const previousType = usePrevious(accountType);
  // accountType might disappear as this animates out
  const type = accountType || previousType;

  return (
    <Dialog open={open && type !== "QuickBooks"} width={330}>
      <Dialog.Body css={{ padding: "$4" }}>
        <Loader size={32} />
        <Description>Bringing in your data from {type}...</Description>
      </Dialog.Body>
    </Dialog>
  );
};

export default LoadingModal;
