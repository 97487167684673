import React from "react";
import { IntegrationLogo } from "../shared";
import { Mercury, MercuryWithText } from "@puzzle/icons";

const MercuryLogo = ({
  color,
  withText,
  circular,
}: {
  color?: string;
  withText?: boolean;
  circular?: boolean;
}) => {
  return (
    <IntegrationLogo color={color ?? "#5466F9"} circular={circular}>
      {withText ? <MercuryWithText /> : <Mercury />}
    </IntegrationLogo>
  );
};

export default MercuryLogo;
