import React from "react";
import Icon, { IconProps } from "./Icon";

export default function AIContext({
  viewBox = "0 0 16 16",
  color = "currentColor",
  ...iconProps
}: IconProps) {
  return (
    <Icon {...iconProps} viewBox={viewBox}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Zm.233-3.168.372-1.117a4.917 4.917 0 0 1 3.11-3.11l1.117-.372a.246.246 0 0 0 0-.466l-1.117-.372a4.917 4.917 0 0 1-3.11-3.11l-.372-1.117a.246.246 0 0 0-.466 0l-.372 1.117a4.917 4.917 0 0 1-3.11 3.11l-1.117.372a.246.246 0 0 0 0 .466l1.117.372a4.917 4.917 0 0 1 3.11 3.11l.372 1.117a.246.246 0 0 0 .466 0Z"
        clipRule="evenodd"
      />
    </Icon>
  );
}
