import React from "react";
import { IntegrationLogo } from "../../shared";
import { Tangerine } from "@puzzle/icons";

const TangerineLogo = () => {
  return (
    <IntegrationLogo color="#F58426">
      <Tangerine />
    </IntegrationLogo>
  );
};

export default TangerineLogo;
