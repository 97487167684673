import React, { useMemo } from "react";
import { compact } from "lodash";
import { parseDate } from "@internationalized/date";

import { styled, Stack, DateInput } from "@puzzle/ui";
import { formatAccountName, toCalendarDate } from "@puzzle/utils";

import DescriptionList from "components/common/DescriptionList";
import { useCompanyDateFormatter } from "components/companies";
import { LockedPeriodStatus } from "components/transactions/Cells/LockedPeriodStatus";
import { FullTransactionFragment } from "../graphql.generated";
import { useDetailPaneContext } from "./DetailPaneContext";
import { RampLogo } from "components/integrations";
import { useUpdateAccrualDate } from "../hooks/useSingleTransaction";
import { useActiveCompany } from "components/companies";
import useAppRouter from "lib/useAppRouter";
import { Box } from "ve";
import { rampLogoContainer } from "./SummarySection.css";

const DateContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "left",
  gap: "$0h",
});

export const SummarySection = React.memo(function SummarySection({
  transaction,
}: {
  transaction: FullTransactionFragment;
}) {
  const dateFormatter = useCompanyDateFormatter({
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  const { close, isEditingSplit, canEdit } = useDetailPaneContext();
  const updateAccrualDate = useUpdateAccrualDate(transaction);
  const { lockedPeriodDate } = useActiveCompany<true>();
  const { router } = useAppRouter();

  const showAccrualDate = useMemo(
    () =>
      !isEditingSplit &&
      transaction.splits.length === 0 &&
      (canEdit || transaction.detail.accrualDate) &&
      !transaction.linkedBills.length,
    [isEditingSplit, transaction, canEdit]
  );

  return (
    <DescriptionList
      items={compact([
        [
          "Transaction Date",
          <DateContainer key="txnDate">
            {dateFormatter.format(parseDate(transaction.date))}{" "}
            {
              <LockedPeriodStatus
                transactionDate={parseDate(transaction.date)}
                posted={Boolean(transaction.detail.postedAt)}
                transactionId={transaction.id}
                onClick={(e) => e.stopPropagation()}
                onClose={close}
              />
            }
          </DateContainer>,
        ],
        transaction.sourceTransactionNativeId &&
          router.asPath.includes("stripe") && [
            "Stripe native ID",
            transaction.sourceTransactionNativeId,
          ],
        showAccrualDate && [
          "Accrual Date",
          canEdit ? (
            <DateInput
              value={transaction.detail.accrualDate && parseDate(transaction.detail.accrualDate)}
              onChange={(value) => value && updateAccrualDate(toCalendarDate(value))}
              minDate={lockedPeriodDate && parseDate(lockedPeriodDate.toString()).add({ days: 1 })}
              maxDate={parseDate(transaction.date)}
              inputReadOnly
              css={{
                width: "fit-content",
                height: 32,
              }}
              key="accrualDate"
              onClear={() => updateAccrualDate(null)}
            />
          ) : (
            <DateContainer key="accrualDate">
              {transaction.detail.accrualDate &&
                dateFormatter.format(parseDate(transaction.detail.accrualDate))}
            </DateContainer>
          ),
        ],

        transaction.availableOn && [
          "Available Date",
          <DateContainer key="availableDate">
            {dateFormatter.format(parseDate(transaction.availableOn))}
          </DateContainer>,
        ],

        ["Description", transaction.detail.descriptor ?? transaction.descriptor],

        // TODO PZ-1749 fill these out
        // ["Product/service plan", "CFO Pro Plan"],
        // ["Customer", "Jim"],
        // ["Invoice number", "DB3497B9"],
        [
          "Institution",
          <Stack direction="horizontal" gap="1" key="institution">
            {transaction.account.financialInstitution.name === "Ramp" && (
              <Box className={rampLogoContainer}>
                <RampLogo />
              </Box>
            )}
            <span>{transaction.account.financialInstitution.name}</span>
          </Stack>,
        ],

        ["Source account", formatAccountName(transaction.account)],

        transaction.detail.memo && ["Memo", transaction.detail.memo],

        transaction.detail.card && ["Cardholder", transaction.detail.card.holderName],
      ])}
    />
  );
});
