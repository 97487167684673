import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Subtract({
  className,
  rotate,
  viewBox = "0 0 10 2",
  color = "currentColor",
  ...props
}: IconProps) {
  return (
    <Icon className={className} rotate={rotate} viewBox={viewBox} {...props}>
      <path
        fill={color}
        d="M1.5.25a.75.75 0 000 1.5V.25zm7 1.5a.75.75 0 000-1.5v1.5zm-7 0h7V.25h-7v1.5z"
      ></path>
    </Icon>
  );
}
