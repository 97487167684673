import { FeedItem } from "@knocklabs/client";
import { reportError } from "lib/errors";
import React, { useEffect } from "react";

export const ErrorPane = ({ item }: { item?: FeedItem }) => {
  useEffect(() => {
    if (item) {
      console.debug(item);
      reportError("InboxItemError", { item });
    }
  }, [item]);

  return <>This item can't be displayed</>;
};
