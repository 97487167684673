import React from "react";
import Icon, { IconProps } from "./Icon";

const Paperclip = ({
  className,
  rotate,
  size = 16,
  color = "currentColor",
  viewBox = "0 0 16 16",
  ...rest
}: IconProps) => (
  <Icon className={className} rotate={rotate} viewBox={viewBox} size={size} fill={color} {...rest}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M4.8 2.3C5.638 1.462 6.83 1 8 1s2.362.462 3.2 1.3c.833.832 1.3 1.96 1.3 3.138V10a.5.5 0 01-1 0V5.437c0-.911-.362-1.786-1.007-2.43C9.854 2.367 8.92 2 8 2c-.92 0-1.854.368-2.493 1.007A3.437 3.437 0 004.5 5.437v6.438a2.125 2.125 0 104.25 0v-6.57a.812.812 0 10-1.625 0V11a.5.5 0 01-1 0V5.306a1.812 1.812 0 113.625 0v6.569a3.125 3.125 0 11-6.25 0V5.437c0-1.176.468-2.305 1.3-3.137z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default Paperclip;
