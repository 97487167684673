import React from "react";
import IntegrationLogo from "../shared/IntegrationLogo";
import { Carta } from "@puzzle/icons";

const Logo = () => {
  return (
    <IntegrationLogo color="#1B98ED">
      <Carta />
    </IntegrationLogo>
  );
};

export default Logo;
