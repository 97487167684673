import React from "react";

export const WellcomeLeft = (props: React.PropsWithChildren<unknown>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="175"
      height="258"
      fill="none"
      viewBox="0 0 175 258"
      {...props}
    >
      <path
        fill="url(#paint0_linear_1047_8358)"
        d="M124.656 191.939c-2.39 1.115-4.315 3.829-4.308 6.053.006 2.226 1.949 3.123 4.335 2.004 2.386-1.119 4.315-3.83 4.309-6.053-.009-2.226-1.949-3.123-4.336-2.004z"
      ></path>
      <path
        fill="url(#paint1_linear_1047_8358)"
        d="M124.66 193.647c-1.375.643-2.485 2.206-2.481 3.487.004 1.283 1.123 1.798 2.498 1.154 1.375-.643 2.484-2.206 2.481-3.486-.004-1.282-1.121-1.799-2.498-1.155z"
      ></path>
      <path
        fill="url(#paint2_linear_1047_8358)"
        d="M175 192.222l-.251 28.776c-1.523 9.19-9.723 18.173-24.635 25.207-24.28 11.446-59.635 14.564-90.026 9.343-5.277-.907-10.4-2.062-15.292-3.471-5.643-1.623-10.979-3.582-15.875-5.872-7.86-3.681-13.888-7.902-18.086-12.401a36.69 36.69 0 01-.833-.923c-4.214-4.85-6.342-10.005-6.38-15.16v-24.947L55.51 180.66c21.357-4.272 45.678-4.272 67.093 0L175 192.222z"
      ></path>
      <path
        fill="url(#paint3_radial_1047_8358)"
        d="M149.913 221.256c-.889.422-1.798.83-2.714 1.219-8.052 3.487-17.183 6.11-26.868 7.862-19.362 3.532-40.93 3.615-60.447.262-5.273-.906-10.4-2.059-15.3-3.473 0 0-.005-.002-.02-.002-5.45-1.57-10.613-3.455-15.367-5.648-.153-.067-.309-.144-.476-.22-7.86-3.681-13.89-7.896-18.085-12.401-13.734-14.733-7.86-32.511 17.687-44.563 33.358-15.728 87.617-15.728 121.191 0 33.573 15.729 33.756 41.229.399 56.964z"
      ></path>
      <path
        fill="url(#paint4_linear_1047_8358)"
        d="M16.844 227.303c-.223-.06-.44-.12-.659-.181h.024l.635.181z"
      ></path>
      <path
        fill="url(#paint5_linear_1047_8358)"
        d="M158.738 189.526v-3.027l-.324-.002c-1.504-7.33-8.249-14.475-20.229-20.089-27.276-12.778-71.357-12.778-98.455 0-11.21 5.285-17.753 11.928-19.648 18.807l-.584-.006.001 4.111h.003c-.064 8.449 6.787 16.917 20.552 23.364 18.006 8.436 43.35 11.3 66.289 8.597 9.177-1.083 17.975-3.058 25.761-5.921a78.836 78.836 0 006.405-2.676c13.562-6.394 20.299-14.777 20.229-23.158z"
      ></path>
      <path
        fill="url(#paint6_radial_1047_8358)"
        d="M138.508 209.638a78.127 78.127 0 01-6.405 2.675c-7.786 2.865-16.584 4.838-25.761 5.923-22.938 2.702-48.282-.161-66.29-8.598-27.283-12.778-27.424-33.497-.323-46.276 27.098-12.779 71.179-12.779 98.455 0 27.278 12.783 27.425 33.498.324 46.276z"
      ></path>
      <path
        fill="url(#paint7_linear_1047_8358)"
        d="M143.198 181.269c-.986 5.771-6.161 11.417-15.532 15.832-21.052 9.925-55.293 9.925-76.489 0-9.534-4.468-14.803-10.189-15.777-16.027-1.218-7.134 3.956-14.458 15.535-19.919 21.049-9.931 55.287-9.923 76.48 0 11.757 5.506 17.025 12.91 15.783 20.114z"
      ></path>
      <path
        fill="url(#paint8_linear_1047_8358)"
        d="M88.986 200.982c-12.986 0-25.213-2.378-34.426-6.695-9.297-4.353-14.422-10.156-14.433-16.338-.01-6.141 5.036-11.905 14.206-16.23 9.158-4.318 21.35-6.696 34.331-6.696 12.98 0 25.205 2.378 34.42 6.696 9.297 4.356 14.421 10.16 14.431 16.343.01 6.139-5.035 11.901-14.205 16.225a54.62 54.62 0 01-4.479 1.87c-5.344 1.968-11.395 3.36-17.989 4.138-3.868.457-7.858.687-11.856.687zm-.322-45.515c-12.887 0-24.98 2.356-34.055 6.637-8.997 4.242-13.945 9.869-13.935 15.844.011 6.016 5.04 11.684 14.16 15.955 9.132 4.278 21.26 6.635 34.152 6.635 3.971 0 7.934-.231 11.776-.683 6.542-.771 12.545-2.152 17.842-4.101a54.267 54.267 0 004.43-1.851c8.995-4.241 13.944-9.866 13.933-15.84-.01-6.017-5.037-11.685-14.159-15.96-9.133-4.28-21.259-6.636-34.144-6.636z"
      ></path>
      <path
        fill="url(#paint9_linear_1047_8358)"
        d="M15.026 118.457l-.011-8.702c0 .107.01.213.01.32v8.382z"
      ></path>
      <path
        fill="#F5F3FF"
        d="M75.675 22.997c.544 0 .985-.357.985-.799 0-.441-.44-.799-.985-.799-.544 0-.985.358-.985.8 0 .44.441.798.985.798zM46.482 90.05c.544 0 .985-.358.985-.799 0-.441-.441-.8-.985-.8s-.985.359-.985.8c0 .441.441.8.985.8zM93.398 95.83c.544 0 .985-.357.985-.798 0-.442-.441-.8-.985-.8s-.985.358-.985.8c0 .441.441.799.985.799z"
        opacity="0.4"
      ></path>
      <path
        fill="#F5F3FF"
        d="M149.853 82.946c.543 0 .984-.358.984-.8 0-.44-.441-.799-.984-.799-.544 0-.985.358-.985.8 0 .44.441.799.985.799zM32.41 86.213c.545 0 .985-.358.985-.8 0-.44-.44-.798-.984-.798s-.985.357-.985.799c0 .44.44.799.985.799zM95.367 67.665c.544 0 .985-.358.985-.8 0-.44-.441-.798-.985-.798s-.985.357-.985.799c0 .441.441.799.985.799z"
        opacity="0.2"
      ></path>
      <path
        fill="#F5F3FF"
        d="M115.967 97.428c.543 0 .983-.358.983-.798 0-.441-.44-.798-.983-.798-.544 0-.984.357-.984.798 0 .44.44.798.984.798zM128.99 35.12c.544 0 .985-.358.985-.8 0-.441-.441-.799-.985-.799s-.985.358-.985.8c0 .44.441.798.985.798zM64.523 55.63c.544 0 .984-.358.984-.799 0-.441-.44-.799-.984-.799-.543 0-.984.358-.984.8 0 .44.44.798.984.798zM35.4 19.744c.543 0 .984-.358.984-.799 0-.441-.44-.799-.985-.799-.544 0-.984.358-.984.8 0 .44.44.798.984.798zM66.493 115.579c.544 0 .984-.358.984-.799 0-.442-.44-.799-.984-.799s-.985.357-.985.799c0 .441.44.799.985.799z"
        opacity="0.3"
      ></path>
      <path
        fill="#F5F3FF"
        d="M42.827 120.231c.629 0 1.138-.414 1.138-.924s-.51-.923-1.138-.923c-.628 0-1.138.413-1.138.923s.51.924 1.138.924z"
        opacity="0.8"
      ></path>
      <path
        fill="#F5F3FF"
        d="M110.464 127.302c.544 0 .984-.358.984-.799 0-.441-.44-.799-.984-.799-.543 0-.984.358-.984.799 0 .441.441.799.984.799z"
        opacity="0.3"
      ></path>
      <path
        fill="#F5F3FF"
        d="M138.42 142.668c.629 0 1.139-.414 1.139-.924 0-.511-.51-.924-1.139-.924s-1.139.413-1.139.924c0 .51.51.924 1.139.924zM103.225 147.558c.629 0 1.139-.414 1.139-.925 0-.51-.51-.924-1.139-.924s-1.14.414-1.14.924c0 .511.511.925 1.14.925zM124.21 151.287c.544 0 .985-.358.985-.8 0-.441-.441-.799-.985-.799-.543 0-.984.358-.984.799 0 .442.441.8.984.8z"
        opacity="0.8"
      ></path>
      <path
        fill="#F5F3FF"
        d="M44.128 160.997c.543 0 .984-.358.984-.799 0-.441-.44-.798-.984-.798s-.984.357-.984.798c0 .441.44.799.984.799z"
        opacity="0.3"
      ></path>
      <path
        fill="#F5F3FF"
        d="M119.13 166.42c.544 0 .985-.357.985-.799 0-.441-.441-.799-.985-.799s-.984.358-.984.799c0 .442.44.799.984.799z"
        opacity="0.8"
      ></path>
      <path
        fill="#F5F3FF"
        d="M125.808 178.378c.629 0 1.139-.414 1.139-.924 0-.511-.51-.925-1.139-.925s-1.14.414-1.14.925c0 .51.511.924 1.14.924z"
        opacity="0.9"
      ></path>
      <path
        fill="#F5F3FF"
        d="M59.747 180.05c.63 0 1.139-.413 1.139-.924 0-.51-.51-.924-1.139-.924s-1.139.414-1.139.924c0 .511.51.924 1.14.924z"
        opacity="0.8"
      ></path>
      <path
        fill="#F5F3FF"
        d="M103.225 187.424c.629 0 1.139-.414 1.139-.925 0-.51-.51-.924-1.139-.924s-1.14.414-1.14.924c0 .511.511.925 1.14.925z"
        opacity="0.9"
      ></path>
      <path
        fill="#F5F3FF"
        d="M70.606 16.013a.759.759 0 00-.753.765v76.11a.76.76 0 00.753.765.76.76 0 00.753-.765v-76.11a.76.76 0 00-.753-.765zM114.228 34.322a.758.758 0 00-.751.764v76.11c0 .423.337.765.751.765a.76.76 0 00.755-.765v-76.11a.76.76 0 00-.755-.764z"
        opacity="0.1"
      ></path>
      <path
        fill="#F5F3FF"
        d="M40.88 37.518a.76.76 0 00-.754.764v76.11c0 .423.338.765.754.765a.76.76 0 00.753-.765v-76.11a.76.76 0 00-.753-.764z"
        opacity="0.2"
      ></path>
      <path
        fill="#F5F3FF"
        d="M136.755 84.277a.491.491 0 00-.488.495v49.299a.49.49 0 00.488.495.49.49 0 00.488-.495V84.772a.491.491 0 00-.488-.495z"
        opacity="0.4"
      ></path>
      <path
        fill="#F5F3FF"
        d="M56.23 98.13a.758.758 0 00-.753.764v52.768c0 .422.336.765.753.765a.76.76 0 00.754-.765V98.893a.759.759 0 00-.754-.764z"
        opacity="0.3"
      ></path>
      <path
        fill="#F5F3FF"
        d="M111.217 133.518a.759.759 0 00-.753.765v76.11c0 .422.337.764.753.764a.758.758 0 00.753-.764v-76.11a.759.759 0 00-.753-.765z"
        opacity="0.4"
      ></path>
      <path
        fill="url(#paint10_linear_1047_8358)"
        d="M131.323 175.945h-.009a9.27 9.27 0 01-.124 1.624c-.672 3.934-3.905 7.787-9.709 10.965-.658.357-1.349.711-2.076 1.05a48.835 48.835 0 01-5.622 2.217 64.635 64.635 0 01-7.681 1.975c-2.498.483-5.074.85-7.695 1.093-.146.01-.29.024-.433.037a86.62 86.62 0 01-15.388-.022l-.391-.039a77.572 77.572 0 01-8.054-1.184 62.857 62.857 0 01-7.315-1.921 47.499 47.499 0 01-5.475-2.156 37.013 37.013 0 01-2.59-1.329c-5.61-3.163-8.741-6.959-9.393-10.831a9.281 9.281 0 01-.119-1.48h-.017V88.338l.017-27.372c-.037-4.938 3.923-9.874 11.911-13.641 15.975-7.539 41.965-7.535 58.052 0 8.914 4.177 12.91 9.785 11.985 15.241l.126 113.379z"
        opacity="0.4"
      ></path>
      <path
        fill="url(#paint11_linear_1047_8358)"
        d="M89.399 50.557c-7.875 0-15.291-1.442-20.88-4.06-5.645-2.644-8.757-6.17-8.764-9.927-.006-3.732 3.058-7.234 8.627-9.86 5.556-2.62 12.948-4.061 20.822-4.061 7.87 0 15.287 1.442 20.876 4.061 5.645 2.645 8.757 6.17 8.763 9.928.007 3.732-3.058 7.232-8.626 9.858-.837.396-1.751.778-2.718 1.135-3.241 1.193-6.911 2.037-10.91 2.51a61.73 61.73 0 01-7.19.416zm-.195-27.58c-7.802 0-15.124 1.426-20.618 4.016-5.44 2.566-8.433 5.967-8.427 9.577.007 3.636 3.046 7.06 8.562 9.643 5.529 2.59 12.873 4.016 20.678 4.016 2.406 0 4.806-.139 7.13-.412 3.962-.468 7.597-1.304 10.803-2.484a32.82 32.82 0 002.681-1.12c5.44-2.564 8.434-5.964 8.427-9.574-.007-3.636-3.047-7.062-8.563-9.646-5.528-2.59-12.87-4.017-20.673-4.017z"
        opacity="0.5"
      ></path>
      <path
        style={{ mixBlendMode: "overlay" }}
        fill="url(#paint12_linear_1047_8358)"
        d="M143.168 29.346l.171 149.721h-.014c0 .715-.037 1.428-.162 2.144-.889 5.196-5.156 10.285-12.82 14.482-.87.47-1.789.939-2.743 1.388-2.321 1.096-4.81 2.07-7.427 2.928a84.67 84.67 0 01-10.144 2.605 103.082 103.082 0 01-10.73 1.493c-.144.011-.287.026-.43.033-6.576.561-13.322.538-19.892-.063-.172-.015-.348-.034-.519-.049a102.705 102.705 0 01-10.629-1.564 83.275 83.275 0 01-9.665-2.538 63.685 63.685 0 01-7.229-2.845 50.288 50.288 0 01-3.42-1.757c-2.024-1.139-3.807-2.346-5.342-3.594-4.082-3.327-6.44-6.993-7.061-10.707a12.683 12.683 0 01-.158-1.955h-.019V63.378l.02-36.149c-.047-6.52 5.18-13.035 15.73-18.012 21.096-9.956 55.416-9.95 76.655 0 11.774 5.519 17.051 12.921 15.828 20.129z"
        opacity="0.6"
      ></path>
      <path
        fill="#50FAAB"
        d="M90.579 195.023c22.5-.106 40.679-8.739 40.604-19.283-.075-10.544-18.376-19.006-40.877-18.9-22.5.105-40.68 8.738-40.604 19.282.075 10.545 18.376 19.007 40.877 18.901z"
      ></path>
      <path fill="#fff" d="M85.798 186.555l.058-8.465 18.023-.047-.057 8.465-18.024.047z"></path>
      <path
        fill="#211F35"
        d="M94.78 190.741l9.041-4.233-8.983-4.185-9.04 4.232 8.982 4.186z"
      ></path>
      <path fill="#fff" d="M67.834 178.14l.057-8.464 18.024-.047-.058 8.464-18.023.047z"></path>
      <path
        fill="#211F35"
        d="M76.816 182.324l9.04-4.232-8.982-4.232-9.04 4.279 8.982 4.185z"
      ></path>
      <path
        fill="#211F35"
        d="M76.933 165.443l-9.04 4.232 8.982 4.233 9.04-4.233 8.983 4.233-9.04 4.232 8.982 4.232 9.04-4.232c7.534-3.527 7.534-9.217.116-12.65-7.475-3.574-19.645-3.527-27.063-.047z"
      ></path>
      <path
        fill="url(#paint13_linear_1047_8358)"
        d="M89.518 111.416c-17.671 0-34.308-3.236-46.845-9.11-12.647-5.922-19.619-13.815-19.635-22.224-.015-8.352 6.848-16.191 19.327-22.074 12.46-5.875 29.05-9.111 46.714-9.111 17.665 0 34.3 3.236 46.842 9.11 12.646 5.926 19.617 13.821 19.632 22.23.013 8.35-6.85 16.187-19.326 22.069a75.287 75.287 0 01-6.094 2.546c-7.274 2.676-15.509 4.57-24.481 5.629a138.47 138.47 0 01-16.134.935zm-.439-61.953c-17.543 0-34.01 3.21-46.364 9.035C30.462 64.276 23.723 71.94 23.738 80.08c.014 8.197 6.862 15.916 19.283 21.734 12.433 5.825 28.946 9.033 46.497 9.033 5.408 0 10.804-.312 16.032-.928 8.907-1.052 17.081-2.931 24.294-5.584a75.08 75.08 0 006.032-2.52c12.25-5.777 18.991-13.44 18.976-21.578-.012-8.198-6.861-15.919-19.282-21.74-12.436-5.826-28.946-9.035-46.49-9.035z"
        opacity="0.8"
      ></path>
      <path
        fill="url(#paint14_linear_1047_8358)"
        d="M45.497 52.52v49.256c0 1.765-1.567 3.85-3.433 4.757a104.135 104.135 0 00-6.09 3.218C11.758 95.758 8.55 76.404 26.283 60.936c3.32-2.897 10.98-7.397 15.849-9.956 1.824-.96 3.366-.223 3.366 1.54z"
        opacity="0.1"
      ></path>
      <path
        fill="url(#paint15_linear_1047_8358)"
        d="M98.866 94.54v49.102c0 1.764-1.752 2.955-3.926 2.955-17.814-.006-39.495-6.984-43.904-8.605a106.854 106.854 0 01-8.67-3.62c-18.134-8.555-27.233-19.746-27.321-30.955-.009-.178-.02-.356-.02-.534V56.287l-.01-8.699c0 .106.01.214.01.32v-.407c0 2.027.307 4.064.906 6.083.023.08.036.168.067.248.102.339.243.678.362 1.015.133.382.253.765.417 1.147a.776.776 0 00.056.125c3.519 8.282 12.124 16.191 25.828 22.603 6.017 2.82 12.649 5.177 19.688 7.073.133.037.263.06.395.098 10.092 2.691 21.026 4.428 32.196 5.215 2.166.152 3.926 1.666 3.926 3.431z"
        opacity="0.4"
      ></path>
      <path
        fill="#BEB5FF"
        d="M90.279 99.793v18.514c-9.546-.901-18.852-2.506-27.531-4.826-.132-.035-.264-.062-.395-.097a129.102 129.102 0 01-11.42-3.628V91.243c3.653 1.355 7.47 2.56 11.42 3.622.131.04.263.062.395.098 8.68 2.319 17.985 3.923 27.531 4.83zM90.279 124.461v2.413c-9.82-1.048-19.173-2.752-27.833-5.066a4.636 4.636 0 00-.286-.07.678.678 0 01-.093-.022l-.067-.019a145.827 145.827 0 01-11.069-3.439v-2.347a129.813 129.813 0 0011.42 3.621c.132.04.264.062.396.099 8.68 2.319 17.986 3.924 27.532 4.83zM90.279 130.011v3.498c-9.82-1.048-19.173-2.749-27.833-5.066a12.594 12.594 0 00-.286-.067c-.033-.009-.067-.014-.093-.022l-.067-.022a147.206 147.206 0 01-11.069-3.437v-3.251a153.208 153.208 0 009.838 2.999c.114.035.235.063.355.088l.11.027c9.048 2.418 18.809 4.183 29.045 5.253zM90.279 136.654v2.709c-9.82-1.052-19.173-2.755-27.833-5.065-.094-.027-.187-.048-.286-.072-.033-.01-.067-.015-.093-.022l-.067-.018a149.54 149.54 0 01-11.069-3.44v-2.467a153.837 153.837 0 009.838 3.002c.114.035.235.065.355.091l.11.027c9.048 2.418 18.809 4.182 29.045 5.255z"
      ></path>
      <g opacity="0.8">
        <path
          fill="#CAC1DE"
          d="M126.725 148.897V172.9c0 1.103 1.087 1.851 2.418 1.631 6.46-1.065 12.53-2.814 17.709-5.239a44.023 44.023 0 003.601-1.887c1.101-.647 1.941-2.121 1.941-3.224v-23.997c0-1.103-.842-1.419-1.943-.772a44.621 44.621 0 01-3.599 1.885c-5.179 2.423-11.25 4.174-17.709 5.239-1.33.22-2.418 1.258-2.418 2.361z"
        ></path>
        <path
          fill="url(#paint16_linear_1047_8358)"
          d="M126.725 148.897V172.9c0 1.103 1.087 1.851 2.418 1.631 6.46-1.065 12.53-2.814 17.709-5.239a44.023 44.023 0 003.601-1.887c1.101-.647 1.941-2.121 1.941-3.224v-23.997c0-1.103-.842-1.419-1.943-.772a44.621 44.621 0 01-3.599 1.885c-5.179 2.423-11.25 4.174-17.709 5.239-1.33.22-2.418 1.258-2.418 2.361z"
        ></path>
      </g>
      <g opacity="0.3">
        <path
          fill="#CAC1DE"
          d="M141.128 135.132c-5.388 1.986-11.166 3.629-17.195 4.932-2.1.455-3.818-.654-3.818-2.418v-21.002c0-1.765 1.718-3.515 3.818-3.969 6.029-1.305 11.807-2.948 17.195-4.933 2.795-1.034 5.5-2.157 8.067-3.366 16.463-7.763 24.942-17.849 25.453-28.014v25.549c.473 10.783-8.006 21.624-25.453 29.858a100.328 100.328 0 01-8.067 3.363z"
        ></path>
        <path
          fill="url(#paint17_linear_1047_8358)"
          d="M141.128 135.132c-5.388 1.986-11.166 3.629-17.195 4.932-2.1.455-3.818-.654-3.818-2.418v-21.002c0-1.765 1.718-3.515 3.818-3.969 6.029-1.305 11.807-2.948 17.195-4.933 2.795-1.034 5.5-2.157 8.067-3.366 16.463-7.763 24.942-17.849 25.453-28.014v25.549c.473 10.783-8.006 21.624-25.453 29.858a100.328 100.328 0 01-8.067 3.363z"
        ></path>
      </g>
      <path
        fill="#211F35"
        d="M166.093 85.228c-1.391-1.356-2.639-3.798-2.639-5.562V58.722c0-1.765 1.248-2.183 2.641-.83 10.094 9.818 11.279 21.145 3.525 31.296a36.113 36.113 0 00-3.527-3.96z"
        opacity="0.6"
      ></path>
      <path
        fill="#BEB5FF"
        d="M153.157 126.042l-.021-5.306-3.283 1.536.02 5.689 3.284-1.919zM133.519 134.788a96.097 96.097 0 01-3.285.986l-.067-20.089c1.104-.287 2.198-.592 3.282-.906l.07 20.009zM140.086 132.484c-.587.232-1.18.456-1.782.678-.491.18-.994.361-1.497.537l-.053-13.177c.016-.004.036-.013.053-.017.502-.176 1.006-.356 1.497-.537.588-.219 1.164-.433 1.735-.661l.047 13.177zM146.832 129.533c-.354.175-.707.347-1.073.519-.713.34-1.448.673-2.204.999l-.069-18.484 3.284-1.536.062 18.502z"
      ></path>
      <path
        fill="#211F35"
        d="M136.15 44.258c-.574-.27-1.157-.53-1.748-.782-.975-.417-1.781-2.16-1.781-3.923V14.647c0-1.764.805-2.883 1.781-2.467.591.253 1.174.513 1.748.781 19.453 9.114 20.759 23.536 3.847 33.309a47.745 47.745 0 00-3.847-2.012z"
        opacity="0.6"
      ></path>
      <g opacity="0.3">
        <path
          fill="url(#paint18_linear_1047_8358)"
          d="M151.763 61.861c.047 6.369-5.078 12.737-15.378 17.596a61.566 61.566 0 01-4.874 2.034c-5.917 2.18-12.609 3.68-19.589 4.503-2.645.315-5.334.528-8.039.643-2.173.094-3.936-1.33-3.936-3.094V58.575c0-1.765 1.763-3.202 3.936-3.295 2.705-.116 5.394-.328 8.039-.644 6.981-.822 13.672-2.328 19.589-4.503a60.863 60.863 0 004.874-2.034c10.252-4.835 15.378-11.165 15.378-17.505V61.86z"
        ></path>
        <path
          fill="url(#paint19_linear_1047_8358)"
          d="M151.763 61.861c.047 6.369-5.078 12.737-15.378 17.596a61.566 61.566 0 01-4.874 2.034c-5.917 2.18-12.609 3.68-19.589 4.503-2.645.315-5.334.528-8.039.643-2.173.094-3.936-1.33-3.936-3.094V58.575c0-1.765 1.763-3.202 3.936-3.295 2.705-.116 5.394-.328 8.039-.644 6.981-.822 13.672-2.328 19.589-4.503a60.863 60.863 0 004.874-2.034c10.252-4.835 15.378-11.165 15.378-17.505V61.86z"
        ></path>
      </g>
      <path
        fill="#29283D"
        d="M124.133 67.31c.016 4.741-4.093 10.518-9.179 12.9-5.086 2.383-9.221.47-9.238-4.272-.017-4.742 4.092-10.517 9.179-12.9 5.084-2.382 9.221-.47 9.238 4.272zm-9.188 10.145c3.455-1.617 6.245-5.54 6.234-8.76-.012-3.224-2.82-4.522-6.274-2.904-3.457 1.62-6.248 5.541-6.237 8.764.012 3.22 2.822 4.52 6.277 2.9z"
        opacity="0.4"
      ></path>
      <path
        fill="#BEB5FF"
        d="M120.352 71.945c-1.071 2.276-3.102 4.42-5.436 5.51-3.456 1.62-6.265.32-6.28-2.898-.01-3.222 2.784-7.145 6.239-8.765a.044.044 0 01.021-.007V63.03l-.031.012c-5.087 2.382-9.193 8.154-9.179 12.896.015 4.743 4.154 6.658 9.239 4.272 3.326-1.561 6.24-4.575 7.848-7.8l-2.421-.465z"
      ></path>
      <path
        fill="#29283D"
        d="M146.46 59.472c.013 4.265-3.684 9.462-8.26 11.605-4.575 2.144-8.297.422-8.31-3.843-.015-4.267 3.68-9.462 8.257-11.606 4.573-2.143 8.295-.423 8.313 3.844zm-8.267 9.127c3.107-1.455 5.618-4.986 5.607-7.882-.009-2.9-2.537-4.068-5.644-2.612-3.11 1.456-5.621 4.985-5.611 7.884.01 2.897 2.54 4.066 5.648 2.61z"
        opacity="0.8"
      ></path>
      <path
        fill="#BEB5FF"
        d="M138.424 55.755c-4.578 2.142-8.272 7.339-8.257 11.606.006 2.63 1.422 4.293 3.579 4.676l1.419-2.766c-1.413-.288-2.336-1.409-2.344-3.153-.01-2.903 2.502-6.432 5.613-7.885a.039.039 0 01.017-.008v-2.48l-.027.01z"
      ></path>
      <path
        fill="url(#paint20_linear_1047_8358)"
        d="M84.98 58.022V83.79c0 1.543-1.522 2.593-3.382 2.272-6.984-1.203-13.542-3.125-19.16-5.755a50.602 50.602 0 01-3.474-1.79c-1.558-.885-2.733-2.95-2.733-4.493V48.26c0-1.544 1.176-1.984 2.737-1.1a50.305 50.305 0 003.47 1.787c5.618 2.63 12.176 4.553 19.16 5.756 1.86.321 3.383 1.774 3.383 3.318z"
        opacity="0.6"
      ></path>
      <path
        fill="url(#paint21_linear_1047_8358)"
        d="M49.008 35.514v33.423c0 1.764-1.674 2.747-3.718 2.15-8.095-2.362-15.747-5.193-22.79-8.495-7.796-3.653-14.383-7.658-19.753-11.908C1.19 49.453.001 46.966.001 45.202V11.784c0-1.765 1.19-2.14 2.748-.908 5.373 4.25 11.954 8.254 19.75 11.903 7.044 3.3 14.696 6.133 22.79 8.496 2.045.597 3.72 2.475 3.72 4.239z"
        opacity="0.3"
      ></path>
      <path
        fill="#29283D"
        d="M19.138 30.772c6.857 3.212 12.395 10.997 12.374 17.39-.023 6.391-5.6 8.97-12.457 5.758-6.854-3.211-12.394-10.998-12.372-17.39.023-6.393 5.6-8.97 12.455-5.758zm-.069 19.435c4.658 2.182 8.448.43 8.462-3.91.015-4.345-3.747-9.632-8.407-11.815-4.658-2.181-8.443-.431-8.459 3.914-.015 4.34 3.746 9.63 8.404 11.81z"
        opacity="0.8"
      ></path>
      <path
        fill="#BEB5FF"
        d="M19.387 30.857c6.856 3.213 12.394 10.997 12.372 17.39l-3.98-1.864c.015-4.345-3.75-9.633-8.405-11.815l.013-3.71z"
      ></path>
      <path
        stroke="url(#paint22_linear_1047_8358)"
        d="M134.018 21.178c0 5.542-4.764 10.71-12.818 14.523-8.017 3.795-19.126 6.155-31.424 6.155-12.298 0-23.408-2.36-31.424-6.155-8.055-3.812-12.819-8.98-12.819-14.523 0-5.542 4.764-10.71 12.819-14.523C66.368 2.86 77.478.5 89.776.5c12.298 0 23.407 2.36 31.424 6.155 8.054 3.812 12.818 8.98 12.818 14.523z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_1047_8358"
          x1="128.707"
          x2="121.193"
          y1="193.536"
          y2="200.405"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#130CB6"></stop>
          <stop offset="0.245" stopColor="#244EC8"></stop>
          <stop offset="0.616" stopColor="#3DACE1"></stop>
          <stop offset="0.876" stopColor="#4CE8F1"></stop>
          <stop offset="1" stopColor="#52FFF7"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_1047_8358"
          x1="123.277"
          x2="134.036"
          y1="196.622"
          y2="188.941"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B2FFAA"></stop>
          <stop offset="0.005" stopColor="#B2FFAA"></stop>
          <stop offset="0.012" stopColor="#B1FFAB"></stop>
          <stop offset="0.362" stopColor="#6DFFCB"></stop>
          <stop offset="0.654" stopColor="#3CFFE2"></stop>
          <stop offset="0.876" stopColor="#1DFFF1"></stop>
          <stop offset="1" stopColor="#12FFF6"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_1047_8358"
          x1="78.037"
          x2="88.358"
          y1="248.881"
          y2="96.656"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.188" stopColor="#211F35"></stop>
          <stop offset="0.327" stopColor="#292541"></stop>
          <stop offset="0.76" stopColor="#3D3761"></stop>
          <stop offset="1" stopColor="#453E6D"></stop>
        </linearGradient>
        <radialGradient
          id="paint3_radial_1047_8358"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(172.675 140.058 82.803) scale(369.907 182.256)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.121" stopColor="#7B6BA5"></stop>
          <stop offset="0.228" stopColor="#6B5D90"></stop>
          <stop offset="0.463" stopColor="#4B4369"></stop>
          <stop offset="0.678" stopColor="#34304C"></stop>
          <stop offset="0.864" stopColor="#26243A"></stop>
          <stop offset="1" stopColor="#212034"></stop>
        </radialGradient>
        <linearGradient
          id="paint4_linear_1047_8358"
          x1="16.866"
          x2="16.384"
          y1="227.053"
          y2="227.386"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311563"></stop>
          <stop offset="1" stopColor="#6638B6"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear_1047_8358"
          x1="62.954"
          x2="91.344"
          y1="292.711"
          y2="123.776"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#212034"></stop>
          <stop offset="0.089" stopColor="#27253C"></stop>
          <stop offset="0.226" stopColor="#383451"></stop>
          <stop offset="0.394" stopColor="#554B75"></stop>
          <stop offset="0.583" stopColor="#7B6BA5"></stop>
          <stop offset="0.796" stopColor="#C6B1EA"></stop>
        </linearGradient>
        <radialGradient
          id="paint6_radial_1047_8358"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(172.676 121.19 82.748) scale(300.525 148.063)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.121" stopColor="#7B6BA5"></stop>
          <stop offset="0.228" stopColor="#6B5D90"></stop>
          <stop offset="0.463" stopColor="#4B4369"></stop>
          <stop offset="0.678" stopColor="#34304C"></stop>
          <stop offset="0.864" stopColor="#26243A"></stop>
          <stop offset="1" stopColor="#212034"></stop>
        </radialGradient>
        <linearGradient
          id="paint7_linear_1047_8358"
          x1="68.978"
          x2="91.035"
          y1="259.267"
          y2="128.038"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#212034"></stop>
          <stop offset="0.089" stopColor="#27253C"></stop>
          <stop offset="0.226" stopColor="#383451"></stop>
          <stop offset="0.394" stopColor="#554B75"></stop>
          <stop offset="0.583" stopColor="#7B6BA5"></stop>
          <stop offset="0.796" stopColor="#C6B1EA"></stop>
        </linearGradient>
        <linearGradient
          id="paint8_linear_1047_8358"
          x1="58.885"
          x2="64.787"
          y1="199.339"
          y2="159.178"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D6FE99"></stop>
          <stop offset="0.33" stopColor="#50FAAB"></stop>
          <stop offset="0.413" stopColor="#50DBBA"></stop>
          <stop offset="0.593" stopColor="#518CDF"></stop>
          <stop offset="0.736" stopColor="#5149FF"></stop>
          <stop offset="1" stopColor="#9A45B7"></stop>
        </linearGradient>
        <linearGradient
          id="paint9_linear_1047_8358"
          x1="15.015"
          x2="15.026"
          y1="114.106"
          y2="114.106"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4C82FF"></stop>
          <stop offset="1" stopColor="#2AF9DE"></stop>
        </linearGradient>
        <linearGradient
          id="paint10_linear_1047_8358"
          x1="90.279"
          x2="90.279"
          y1="245.049"
          y2="69.372"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint11_linear_1047_8358"
          x1="89.299"
          x2="89.299"
          y1="31.542"
          y2="79.541"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BEB5FF" stopOpacity="0"></stop>
          <stop offset="1" stopColor="#BEB5FF"></stop>
        </linearGradient>
        <linearGradient
          id="paint12_linear_1047_8358"
          x1="90.623"
          x2="90.623"
          y1="204.545"
          y2="3.087"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="0.102" stopColor="#fff" stopOpacity="0.81"></stop>
          <stop offset="0.438" stopColor="#fff" stopOpacity="0.525"></stop>
          <stop offset="0.568" stopColor="#fff" stopOpacity="0.3"></stop>
          <stop offset="0.755" stopColor="#fff" stopOpacity="0.137"></stop>
          <stop offset="0.891" stopColor="#fff" stopOpacity="0.037"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint13_linear_1047_8358"
          x1="89.295"
          x2="89.295"
          y1="68.818"
          y2="176.345"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BEB5FF" stopOpacity="0"></stop>
          <stop offset="1" stopColor="#BEB5FF"></stop>
        </linearGradient>
        <linearGradient
          id="paint14_linear_1047_8358"
          x1="30.261"
          x2="30.261"
          y1="128.952"
          y2="61.234"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity="0"></stop>
          <stop offset="0.578" stopColor="#fff"></stop>
        </linearGradient>
        <linearGradient
          id="paint15_linear_1047_8358"
          x1="56.94"
          x2="56.94"
          y1="178.74"
          y2="65.375"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.536" stopColor="#72649A"></stop>
          <stop offset="0.786" stopColor="#72649A" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint16_linear_1047_8358"
          x1="139.559"
          x2="139.559"
          y1="186.082"
          y2="145.481"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.531" stopColor="#72649A" stopOpacity="0"></stop>
          <stop offset="0.854" stopColor="#72649A"></stop>
        </linearGradient>
        <linearGradient
          id="paint17_linear_1047_8358"
          x1="147.391"
          x2="147.391"
          y1="160.862"
          y2="87.871"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.531" stopColor="#72649A" stopOpacity="0"></stop>
          <stop offset="0.854" stopColor="#72649A"></stop>
        </linearGradient>
        <linearGradient
          id="paint18_linear_1047_8358"
          x1="125.855"
          x2="125.855"
          y1="104.822"
          y2="40.704"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint19_linear_1047_8358"
          x1="125.855"
          x2="125.855"
          y1="104.822"
          y2="40.704"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.531" stopColor="#72649A" stopOpacity="0"></stop>
          <stop offset="0.854" stopColor="#72649A"></stop>
        </linearGradient>
        <linearGradient
          id="paint20_linear_1047_8358"
          x1="70.605"
          x2="70.605"
          y1="98.903"
          y2="53.819"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.531" stopColor="#72649A" stopOpacity="0.3"></stop>
          <stop offset="0.854" stopColor="#72649A"></stop>
        </linearGradient>
        <linearGradient
          id="paint21_linear_1047_8358"
          x1="24.504"
          x2="24.504"
          y1="91.083"
          y2="21.177"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.531" stopColor="#72649A" stopOpacity="0"></stop>
          <stop offset="0.854" stopColor="#72649A"></stop>
        </linearGradient>
        <linearGradient
          id="paint22_linear_1047_8358"
          x1="16.175"
          x2="112.044"
          y1="48.54"
          y2="-37.79"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.274" stopColor="#B89AEA"></stop>
          <stop offset="0.348" stopColor="#A3ADDD"></stop>
          <stop offset="0.5" stopColor="#6DDFBC"></stop>
          <stop offset="0.575" stopColor="#50FAAB"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};
