import React from "react";
import { IntegrationLogo } from "../../shared";
import { SiliconValleyBank } from "@puzzle/icons";

const SiliconValleyBankLogo = ({ circular }: { circular?: boolean }) => {
  return (
    <IntegrationLogo color="#003149" circular={circular}>
      <SiliconValleyBank />
    </IntegrationLogo>
  );
};

export default SiliconValleyBankLogo;
