import React from "react";
import { Exclamation } from "@puzzle/icons";
import { IntegrationConnectionFragment, IntegrationConnectionStatus } from "../../../graphql/types";
import { DetailText } from "./shared";
import DisconnectionErrorMessage from "../../common/DisconnectionErrorMessage";

export const ConnectionStatusMessage = ({
  connection,
}: {
  connection: IntegrationConnectionFragment;
}) => {
  const connectionStatus = connection.status;

  if (connectionStatus === IntegrationConnectionStatus.Error) {
    return (
      <DetailText>
        <Exclamation /> Temporary outage, no action needed.
      </DetailText>
    );
  } else if (connectionStatus === IntegrationConnectionStatus.Disconnected) {
    return <DisconnectionErrorMessage integrationConnections={[connection]} />;
  }

  return <></>;
};
