import React, { useCallback, useMemo, useState } from "react";
import { styled, Button, Dialog, Field, Select } from "@puzzle/ui";

import { IntegrationType, RevenueModel } from "graphql/types";
import { revenueModels } from "components/companies/common";

import { IntegrationsListItemBaseProps } from "../List";
import { useActiveCompany } from "../../companies/ActiveCompanyProvider";
import useStripe from "./useStripeIntegration";
import Logo from "./Logo";
import { IntegrationDisplayComponent } from "../shared/types";

interface SetupStripeListItemProps {
  DisplayComponent: IntegrationDisplayComponent<
    React.PropsWithChildren<IntegrationsListItemBaseProps>
  >;
  companyId: string;
}

export const TITLE = "Stripe";
export const SUBTITLE = "Link customer and product revenue to your financial statements and taxes.";

const Fields = styled("div", {
  marginTop: "$4",
});

const AskRevenueModal = ({
  onComplete,
  ...props
}: React.ComponentProps<typeof Dialog> & {
  onComplete: () => void;
}) => {
  const { updateCompany, updatingCompany, company } = useActiveCompany();
  const [revenueModel, setRevenueModel] = useState<RevenueModel | undefined>(
    company!.revenueModel ?? undefined
  );
  const options = useMemo(() => {
    return Object.entries(revenueModels).map(([value, label]) => ({ value, label }));
  }, []);

  const onSubmit = useCallback(async () => {
    await updateCompany({
      revenueModel: revenueModel,
    });

    onComplete();
  }, [onComplete, revenueModel, updateCompany]);

  return (
    <Dialog {...props} modal width={400}>
      <Dialog.Title>Select your revenue model</Dialog.Title>
      <Dialog.Body>
        Understanding your revenue model helps us recognize and categorize your revenue as it comes
        in. Update it before we import your Stripe data.
        <Fields>
          <Field label="Which best describes your revenue model today?">
            <Select
              value={revenueModel}
              options={options}
              placeholder="Select a revenue model"
              onSelectionChange={(value) => setRevenueModel(value as RevenueModel)}
            />
          </Field>
        </Fields>
      </Dialog.Body>

      <Dialog.Footer>
        <Button onClick={onSubmit} disabled={!revenueModel || updatingCompany}>
          Continue
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};

const SetupStripeListItem = ({ companyId, DisplayComponent }: SetupStripeListItemProps) => {
  const { company } = useActiveCompany();
  const { onClickConnect, connection, loading } = useStripe({ companyId });
  const [revenueOpen, setRevenueOpen] = useState(false);

  const onConnect = useCallback(() => {
    if (
      !company?.revenueModel ||
      [RevenueModel.NotSure, RevenueModel.PreRevenue].includes(company.revenueModel)
    ) {
      setRevenueOpen(true);
      return;
    }

    onClickConnect();
  }, [company?.revenueModel, onClickConnect]);

  return (
    <>
      <DisplayComponent
        integrationType={IntegrationType.Stripe}
        connectIntegration={onConnect}
        title={TITLE}
        connection={connection}
        logo={<Logo />}
        loading={loading}
      />

      <AskRevenueModal
        open={revenueOpen}
        onOpenChange={setRevenueOpen}
        onComplete={onClickConnect}
      />
    </>
  );
};

export default SetupStripeListItem;
