import React, { useCallback, useRef, useState } from "react";
import { styled, Button, useToasts } from "@puzzle/ui";
import useFile from "components/common/files/useFile";
import { Paperclip } from "@puzzle/icons";
import Loader from "components/common/Loader";
import { AssociatedEntity } from "graphql/types";
import { FullTransactionFragment } from "components/dashboard/Transactions/graphql.generated";
import useSingleTransaction from "components/dashboard/Transactions/hooks/useSingleTransaction";
import Analytics from "lib/analytics";
import { useAskAI } from "components/AI/useAskAI";
import { useDetailPaneContext } from "./DetailPaneContext";

const Container = styled("div", {
  width: "100%",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  border: "1px solid",
  borderColor: "transparent",
  borderRadius: "$1",
  overflow: "hidden",
  transition: "border-color 0.1s ease-in",

  "&:focus-within": {
    outline: "none",
    borderColor: "$gray700",
  },

  variants: {
    isDropping: {
      true: {
        borderColor: "$purple400",
      },
    },
  },
});

const TextArea = styled("textarea", {
  resize: "none",
  font: "inherit",
  fontWeight: "normal",
  width: "100%",
  backgroundColor: "#242433",
  border: "none",
  borderBottom: "1px solid $mauve680",
  transition: "border-color 0.1s ease-in",
  outline: "none",
  padding: "$2",
  flexGrow: "1",
  color: "$gray200",

  "&:focus": {
    borderColor: "$gray700",
  },
});

const Footer = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height: "48px",
  padding: "$2",
  backgroundColor: "$mauve950",
  width: "100%",
  color: "$gray400",
});

const Attachment = styled("button", {
  all: "unset",
  background: "transparent",
  color: "inherit",
  display: "flex",
  alignItems: "center",
  gap: "$1",
  cursor: "pointer",
});

const CommentBox = ({
  transaction,
  onSave,
}: {
  transaction: FullTransactionFragment;
  onSave?: () => void;
}) => {
  const { refetch, addMessage } = useSingleTransaction(transaction);
  const { toast } = useToasts();
  const [message, setMessage] = useState("");
  const { commentBoxMode } = useDetailPaneContext();
  const { provideAdditionalContextForTransaction } = useAskAI();

  const handleSaveMessage = async (newMessage: string) => {
    if (newMessage && transaction) {
      if (commentBoxMode === "messaging") {
        await addMessage({
          text: newMessage,
          transaction,
        });
        Analytics.transactionCommentAdded({
          transactionId: transaction.id,
        });
      } else if (commentBoxMode === "provide_ai_context") {
        // todo this will have to be retrieved on the backend, or sent as part of the notification data
        await provideAdditionalContextForTransaction(transaction, newMessage);
      } else {
        throw new Error("unsupported comment box mode");
      }

      onSave?.();
    }
  };

  const handleChangeMemoMessage = (event: React.ChangeEvent<{ value: string }>) =>
    setMessage(event.target.value);

  const handleError = (msg = "There was an error uploading your file") => {
    toast({ message: msg, status: "error" });
  };

  const handleSave = () => {
    if (!message) {
      return;
    }

    handleSaveMessage(message);
    setMessage("");
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    if (e.key === "Enter" && (e.metaKey || e.ctrlKey)) {
      handleSave();
    }
  };

  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const openFilePicker = useCallback(() => hiddenFileInput.current?.click(), []);
  const { dropAreaProps, isDropping, isUploading, onFiles } = useFile({
    entityId: transaction.id,
    entityType: AssociatedEntity.Transaction,
    onError: handleError,
    onUploadComplete: () => {
      Analytics.transactionDocumentationAdded({
        transactionId: transaction.id,
      });
      refetch?.();
    },
  });

  return (
    <Container isDropping={isDropping}>
      {/* todo more ui differentiation between two modes */}
      <TextArea
        placeholder={
          commentBoxMode === "provide_ai_context"
            ? "Leave some context and let Puzzle AI categorize for you"
            : "Leave a comment"
        }
        {...dropAreaProps}
        onChange={handleChangeMemoMessage}
        onKeyDown={handleKeyDown}
        value={message}
        rows={3}
        // TODO add auto-grow to TextArea
        css={{ resize: "vertical", maxHeight: 300 }}
      />
      <Footer>
        <Attachment onClick={openFilePicker}>
          {isUploading && <Loader size={16} css={{ width: "fit-content" }} />}
          {!isUploading && <Paperclip />}
        </Attachment>

        <Button onClick={handleSave} variant="secondary" size="small" disabled={!message}>
          Send
        </Button>
      </Footer>

      <input
        type="file"
        ref={hiddenFileInput}
        onChange={(e) => e.target.files && onFiles(e.target.files)}
        style={{ display: "none" }}
      />
    </Container>
  );
};

export default CommentBox;
