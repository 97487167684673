import React from "react";
import * as Stitches from "@stitches/react";

import { ScrollArea } from "../ScrollArea";
import { DataTable } from "./DataTable";

type Props = {
  children: React.ReactNode;
  css?: Stitches.CSS;
};

const Scrollable = ({ children, css }: Props) => {
  return (
    <ScrollArea css={{ display: "flex", flex: 1, flexDirection: "row", ...css }}>
      <ScrollArea.Viewport
        css={{
          "> div": {
            height: "100%",
          },

          [`${DataTable}`]: {
            "thead th:last-child, tbody tr td:last-child, tfoot tr td:last-child": {
              // scroll bar takes up space within the table so this gives room to the right most cells
              paddingRight: "$2",
            },
          },
        }}
      >
        {children}
      </ScrollArea.Viewport>
      <ScrollArea.Scrollbar orientation="vertical">
        <ScrollArea.Thumb variant="shadowed" />
      </ScrollArea.Scrollbar>
    </ScrollArea>
  );
};

export default Scrollable;
