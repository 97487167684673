/* eslint-disable react/display-name */
import React from "react";

import { Button, Menu } from "@puzzle/ui";
import { Add } from "@puzzle/icons";
import { remove } from "lodash";

type AccountTypeMenuProps = React.ComponentProps<typeof Menu> & {
  accountTypes: { label: string; value: string }[];
  selection: string[];
  onSelectionChange: (values: string[]) => void;
};

const AccountTypeMenu = React.forwardRef<HTMLButtonElement, AccountTypeMenuProps>(
  ({ children, accountTypes, selection, onSelectionChange, ...props }, ref) => {
    const updateSelection = (val: string) => {
      if (selection.includes(val)) {
        remove(selection, (v) => v === val);
        onSelectionChange(selection);
      } else {
        onSelectionChange([...selection, val]);
      }
    };

    return (
      <Menu
        label="Add data source types"
        side="bottom"
        align="start"
        sideOffset={8}
        trigger={
          <Button
            size="small"
            variant="minimal"
            prefix={<Add />}
            css={{
              color: "$gray500",
            }}
          >
            Add source
          </Button>
        }
        {...props}
      >
        <Menu.Group>
          {accountTypes.map((type) => (
            <Menu.CheckboxItem
              key={type.value}
              checked={selection.includes(type.value)}
              onCheckedChange={() => updateSelection(type.value)}
              // Don't auto-close since this is multi-select
              onSelect={(e) => e.preventDefault()}
            >
              {type.label}
            </Menu.CheckboxItem>
          ))}
        </Menu.Group>
      </Menu>
    );
  }
);

export default AccountTypeMenu;
