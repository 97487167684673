import { SpanOptions, Span, trace } from "@opentelemetry/api";

/**
 * Thin wrapper over tracer, note that you MUST close the span with `span.end()`
 */
export const customTrace = (name: string, options: SpanOptions): Span => {
  const tracer = trace.getTracer("default");

  return tracer.startSpan(name, options);
};

/**
 * Creates a parent span, note that you MUST close the span with `span.end()`
 */
export const customParentTrace = (name: string, options: SpanOptions): Span => {
  const tracer = trace.getTracer("default");

  const span = tracer.startActiveSpan(name, options, (span) => span);
  return span;
};
