import React, { useMemo } from "react";
import { styled, Button, Stack, colors } from "@puzzle/ui";
import { FeedItem } from "@knocklabs/client";
import { Check } from "@puzzle/icons";
import { useCompanyDateFormatter } from "components/companies";
import Link from "next/link";

const Root = styled("div", {
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "0 $2",
});

const Card = styled("div", {
  background: "$mauve950",
  border: "1px solid $mauve550",
  borderRadius: "4px",
  maxWidth: 488,
  width: "100%",
  padding: "$2h",
});

const Header = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  borderBottom: "1px solid $mauve550",
  paddingBottom: "$1h",

  fontWeight: "$bold",
  fontSize: "15px",
  lineHeight: "22px",
  alignItems: "center",
  height: "calc($space$3 + $1h)",

  color: "$gray100",
});

const DescriptionList = styled("dl", {
  margin: 0,
  marginTop: "$1h",
  display: "grid",
  gridAutoFlow: "row",
  gridTemplateColumns: "140px 1fr",
  columnGap: "$2h",
  rowGap: "$1h",
  fontSize: "$body",
  lineHeight: "$bodyS",

  "*": {
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
});
const Term = styled("dt", {
  color: "$gray400",
});
const Description = styled("dd", {
  color: "$gray100",
  margin: 0,
});

const Footer = styled("div", {
  marginTop: "$3",
  display: "flex",
  alignItems: "center",
  gap: "$1h",
});

export const MiniPane = ({
  title,
  item,
  data,
  actions,
  headerButtonOptions,
}: {
  title: string;
  actions?: React.ReactElement;
  headerButtonOptions?: { label: string } & (
    | {
        onClick: () => void;
      }
    | {
        href: string;
      }
  );
  data: { label: string; value?: string | number }[];
  item?: FeedItem;
}) => {
  const dateFormatter = useCompanyDateFormatter({
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  const footer = useMemo(() => {
    if (item?.read_at) {
      return (
        <Stack direction="horizontal" gap="0h">
          <Check color={colors.greenalpha} />
          {/* TODO Also show if archived before release date */}
          You took action on {dateFormatter.format(new Date(item.read_at))}
        </Stack>
      );
    }

    return actions;
  }, [actions, dateFormatter, item?.read_at]);

  const headerButton = useMemo(() => {
    if (!headerButtonOptions) {
      return null;
    }

    const button = (
      <Button
        size="mini"
        variant="secondary"
        onClick={"onClick" in headerButtonOptions ? headerButtonOptions.onClick : undefined}
      >
        {headerButtonOptions.label}
      </Button>
    );

    if ("href" in headerButtonOptions) {
      return (
        <Link href={headerButtonOptions.href} passHref>
          {button}
        </Link>
      );
    }

    return button;
  }, [headerButtonOptions]);

  return (
    <Root>
      <Card>
        <Header>
          {title}

          {headerButton}
        </Header>
        <DescriptionList>
          {data.map((data) => (
            <React.Fragment key={data.label}>
              <Term>{data.label}</Term>
              <Description title={typeof data.value === "undefined" ? undefined : `${data.value}`}>
                {data.value ?? "N/A"}
              </Description>
            </React.Fragment>
          ))}
        </DescriptionList>

        {footer && <Footer>{footer}</Footer>}
      </Card>
    </Root>
  );
};
