import React, { SyntheticEvent, useCallback, useEffect, useRef } from "react";
import { omit } from "lodash";
import { styled } from "../stitches";
import { Input, useAutocomplete, AutocompleteProps } from "../form";
import { IconButton } from "../Button";
import Menu from "./Menu";
import { Close } from "@puzzle/icons";
import { useToggle } from "react-use";
import { useComposedRefs } from "@radix-ui/react-compose-refs";
import { AutocompleteFreeSoloValueMapping } from "@mui/material/useAutocomplete";

const SearchInput = styled(Input, {
  border: "none !important",
  boxShadow: "none",

  variants: {
    windowed: {
      true: {
        width: 200,
      },
      false: {},
    },
  },
});

const EmptyState = styled("div", {
  color: "$gray400",
  padding: "$4 $1",
  textAlign: "center",

  div: {
    marginBottom: "$1h",

    "&:last-of-type": {
      marginBottom: 0,
    },
  },
});

export type AutocompleteMenuProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = Omit<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  "onClose" | "getOptionLabel"
> & {
  onClose?: () => void;
  addText?: string;
  label?: string;
  placeholder?: string;
  loading?: boolean;
  getOptionLabel?: (option: T) => string;
  footer?: React.ReactElement;
  css?: React.CSSProperties;
} & Pick<React.ComponentPropsWithoutRef<typeof Menu>, "trigger" | "subMenuTrigger">;

function AutocompleteInlineInner<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  {
    open,
    onClose,
    label,
    footer,
    placeholder = "Search...",
    loading,
    getOptionLabel,
    ...props
  }: AutocompleteMenuProps<T, Multiple, DisableClearable, FreeSolo>,
  _ref: React.Ref<HTMLInputElement>
) {
  const { inputRef, getRootProps, getClearProps, getInputProps, renderedListbox, hasClearIcon } =
    useAutocomplete<T, Multiple, DisableClearable, FreeSolo>({
      // Don't auto-close when multiple options are allowed
      disableCloseOnSelect: props.multiple,
      open: true,
      ...props,
      getOptionLabel: useCallback(
        (option: T | AutocompleteFreeSoloValueMapping<FreeSolo>) =>
          typeof option === "string" ? option : getOptionLabel?.(option) ?? "",
        [getOptionLabel]
      ),
      onClose: useCallback(
        (e: SyntheticEvent<Element, Event>, reason: string) => {
          // Since the input and items are in one container,
          // clicking the input shouldn't close the list.
          // Blurring is also handled by the menu.
          if (!["toggleInput", "blur"].includes(reason)) {
            onClose?.();
          }
        },
        [onClose]
      ),
    });

  useEffect(() => {
    if (!open) {
      return;
    }

    setTimeout(() => {
      inputRef?.current?.focus();
    }, 50);
  }, [open, inputRef]);

  const hasResults = renderedListbox.props.children.length > 0;

  return (
    <div {...getRootProps()}>
      <SearchInput
        windowed={props.windowed}
        {...omit(getInputProps(), "size")}
        ref={useComposedRefs(inputRef, _ref)}
        autoFocus
        placeholder={placeholder}
        onKeyDown={useCallback(
          (e: React.KeyboardEvent<Element>) => {
            if (["Escape", "Tab"].includes(e.key)) {
              onClose?.();
            } else if (["ArrowLeft", "ArrowRight"].includes(e.key)) {
              // Prevent MUI from trying to navigate between tags; we don't render them for multi-select.
              // @ts-expect-error custom MUI type
              e.defaultMuiPrevented = true;
            }
          },
          [onClose]
        )}
        suffix={
          hasClearIcon && (
            <IconButton
              {...(getClearProps() as unknown as React.HTMLAttributes<HTMLButtonElement>)}
            >
              <Close fill="currentColor" size={12} />
            </IconButton>
          )
        }
      />

      {renderedListbox && (
        <>
          {label && hasResults && !loading && <Menu.Label>{label}</Menu.Label>}
          <Menu.Separator />
          {loading && (
            <Menu.Group>
              <Menu.Item disabled>{"Loading..."}</Menu.Item>
            </Menu.Group>
          )}
          {!loading &&
            (hasResults ? (
              renderedListbox
            ) : (
              <EmptyState>
                <div>No results.</div>
                <div>Try different search terms.</div>
              </EmptyState>
            ))}
        </>
      )}
      {footer}
    </div>
  );
}

export const AutocompleteInline = React.forwardRef(AutocompleteInlineInner) as <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  p: Omit<AutocompleteMenuProps<T, Multiple, DisableClearable, FreeSolo>, "ref"> & {
    ref?: React.RefObject<HTMLInputElement>;
  }
) => React.ReactElement;

function AutocompleteMenu<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  children,
  trigger,
  subMenuTrigger,
  footer,
  css,
  ...props
}: Omit<AutocompleteMenuProps<T, Multiple, DisableClearable, FreeSolo>, "onClose"> & {
  children?: React.ReactElement;
}) {
  const ref = useRef<HTMLInputElement | null>(null);
  const [open, toggle] = useToggle(false);

  return (
    <Menu
      trigger={trigger || children}
      subMenuTrigger={subMenuTrigger}
      open={open}
      onOpenChange={(o) => {
        toggle(o);
      }}
      style={css}
    >
      <AutocompleteInline<T, Multiple, DisableClearable, FreeSolo>
        {...props}
        open={open}
        ref={ref}
        footer={footer}
        onClose={() => toggle(false)}
      />
    </Menu>
  );
}

export default AutocompleteMenu;
