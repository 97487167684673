import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Pause({
  viewBox = "0 0 10 10",
  width = 10,
  height = 10,
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} {...iconProps} fill="none" viewBox={viewBox}>
      <path fill="#E8E8EA" d="M6.4 9.2V.8h2.8v8.4H6.4zm-5.6 0V.8h2.8v8.4H.8z"></path>
    </Icon>
  );
}
