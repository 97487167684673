import React from "react";
import { IntegrationsListItemBaseProps } from "../List";
import { usePlaid } from "../plaid";
import { Bank } from "@puzzle/icons";
import IntegrationLogo from "../shared/IntegrationLogo";
import { IntegrationType } from "graphql/types";
import { SelectStartDateModal } from "../setup/modals/SelectStartDateModal";

interface SetupBankListItemProps {
  DisplayComponent: (
    props: React.PropsWithChildren<IntegrationsListItemBaseProps>
  ) => React.ReactNode;
  companyId: string;
  title?: string;
  subtitle?: string;
  logo?: JSX.Element;
}

export const DEFAULT_TITLE = "Banks or credit cards";

const AddBankListItem = ({
  DisplayComponent,
  companyId,
  title,
  subtitle,
  logo,
}: SetupBankListItemProps) => {
  const {
    onClickConnect,
    isReconnect,
    accounts,
    openSelectDate,
    onOpenSelectDateChange,
    connectionId,
  } = usePlaid({
    companyId,
  });

  const defaultLogo = (
    <IntegrationLogo color="#2A2A38">
      <Bank />
    </IntegrationLogo>
  );

  return (
    <>
      <DisplayComponent
        connectIntegration={onClickConnect}
        integrationType={IntegrationType.Plaid}
        title={title ?? DEFAULT_TITLE}
        subtitle={subtitle}
        logo={logo ?? defaultLogo}
      />

      <SelectStartDateModal
        open={openSelectDate}
        companyId={companyId}
        accounts={accounts}
        onOpenChange={onOpenSelectDateChange}
        isReconnect={isReconnect}
        connectionId={connectionId}
        accountsLoading={accounts.length === 0 ?? false}
      />
    </>
  );
};

export default AddBankListItem;
