import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Rule({
  viewBox = "0 0 16 16",
  color = "currentColor",
  fill = color,
  width = 16,
  height = 16,
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} {...iconProps} viewBox={viewBox}>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M8 16A8 8 0 108 0a8 8 0 000 16zM9.433 5.644a3.04 3.04 0 011.923 1.923.152.152 0 00.288 0 3.04 3.04 0 011.923-1.923.152.152 0 000-.288 3.04 3.04 0 01-1.923-1.923.152.152 0 00-.288 0 3.04 3.04 0 01-1.923 1.923.152.152 0 000 .288zm-3.44 5.378l-.303.909a.2.2 0 01-.38 0l-.303-.909a4 4 0 00-2.53-2.53L1.57 8.19a.2.2 0 010-.38l.909-.303a4 4 0 002.53-2.53l.302-.908a.2.2 0 01.38 0l.303.909a4 4 0 002.53 2.53l.908.302a.2.2 0 010 .38l-.909.303a4 4 0 00-2.53 2.53zm4.363 2.545a3.041 3.041 0 00-1.923-1.923.152.152 0 010-.288 3.041 3.041 0 001.923-1.923.152.152 0 01.288 0 3.041 3.041 0 001.923 1.923.152.152 0 010 .288 3.041 3.041 0 00-1.923 1.923.152.152 0 01-.288 0z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
}
