import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useToggle } from "react-use";
import { keyBy } from "lodash";

import { Dialog, Tabs } from "@puzzle/ui";

import { CategoryFragment } from "graphql/types";
import {
  useExtraTransactionState,
  useUpdateCategory,
} from "components/dashboard/Transactions/hooks/useSingleTransaction";
import { useChartOfAccounts } from "components/dashboard/Accounting/shared/useChartOfAccounts";
import { useCompanyDateFormatter } from "components/companies";

import CategorySearch from "./CategorySearch";
import MatchToBills from "./MatchToBills";
import { BasicTransactionFragment } from "../dashboard/Transactions/graphql.generated";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { zIndex } from "@puzzle/utils";

export enum CategorySteps {
  Category = "Category",
  MatchToBills = "MatchToBills",
}

type CategoryModalProps = Omit<React.ComponentPropsWithoutRef<typeof CategorySearch>, "onKeyDown"> &
  Omit<React.ComponentPropsWithoutRef<typeof Dialog>, "children" | "onChange" | "onKeyDown"> & {
    transaction?: BasicTransactionFragment | null;
    isFinalizing?: boolean;
    onCapitalizedConfirm: (val?: boolean) => void;
    onCreditConfirm: (val?: boolean) => void;
    initialStep?: CategorySteps;
  };

type CapitalizableModalProps = Partial<CategoryModalProps>;

// FIXME
// There's an edge case when you open this while a transaction is updating.
// On the /transactions table, when you change a category, then open it back up.
// If the `setSplits` mutation finishes after you re-open the modal, the modal will disappear.
// I know it at least happens with SelectCategoryInput on the /transactions page.
export const CategoryModal = ({
  // TODO should this load its own if undefined..?
  categories = [],
  initialValue,
  onChange,
  open: _open,
  onOpenChange: _onOpenChange,
  transaction,
  isFinalizing = false,
  onCapitalizedConfirm,
  onCreditConfirm,
  initialStep,
  ...props
}: CategoryModalProps | CapitalizableModalProps) => {
  const updateCategory = useUpdateCategory(transaction);
  const [internalOpen, toggleInternalOpen] = useToggle(false);
  const [step, setStep] = useState(initialStep);
  const { canBeBillPayment } = useExtraTransactionState(transaction);

  const { categories: coaData } = useChartOfAccounts();

  const categoryTypes = useMemo(() => {
    const types = coaData.map((account) => {
      return { id: account.displayId, type: account.type };
    });
    return keyBy(types, "id");
  }, [coaData]);

  const onOpenChange = _onOpenChange ?? toggleInternalOpen;
  const open = _open ?? internalOpen;

  useEffect(() => {
    if (!open && step && !isFinalizing) {
      // Reset after closing animation
      // TODO export animating durations
      const timeout = setTimeout(() => {
        setStep(initialStep || CategorySteps.Category);
      }, 200);

      return () => clearTimeout(timeout);
    }
  }, [isFinalizing, open, step, initialStep]);

  const handleChange = useCallback(
    async (selectedCategory: CategoryFragment) => {
      onChange?.(selectedCategory);

      if (!transaction) {
        onOpenChange(false);
        return;
      }

      if (transaction.detail.category.__typename !== "LedgerCategory") {
        return;
      }

      onOpenChange(false);

      if (!onChange) {
        // Perhaps this shouldn't update until you click Yes/No?
        // no metrics location passed because it's unknown in this case.
        updateCategory({ category: selectedCategory });
      }
    },
    [onChange, onOpenChange, transaction, updateCategory]
  );

  const dateFormatter = useCompanyDateFormatter({ dateStyle: "long" });

  const tabs = useMemo(
    () => [
      {
        value: CategorySteps.Category,
        title: "Categorize",
      },
      {
        value: CategorySteps.MatchToBills,
        title: "Match to bills",
        disabled: !canBeBillPayment || (transaction ? transaction.linkedBills.length > 0 : false),
      },
    ],
    [canBeBillPayment, transaction]
  );

  const activeTab = useMemo(() => {
    return tabs.find((t) => t.value === step) || tabs[0];
  }, [step, tabs]);

  const setTabValue = useCallback((value: string) => {
    setStep(value as CategorySteps);
  }, []);

  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      width={660}
      // Prevents things like tables calling onRowClick
      onClick={(e) => e.stopPropagation()}
      {...props}
      style={{ zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z) ? zIndex("modal") : "auto" }}
    >
      {step === CategorySteps.Category || step === CategorySteps.MatchToBills ? (
        <>
          <Dialog.Title>Categorize or match to a bill</Dialog.Title>
          <Tabs
            variant="minimal"
            items={tabs}
            value={activeTab.value}
            onValueChange={setTabValue}
            css={{ padding: "$1 $3" }}
          />
        </>
      ) : null}

      {step === CategorySteps.Category && (
        <CategorySearch
          open={open}
          categories={categories}
          categoryTypes={categoryTypes}
          initialValue={initialValue}
          onChange={handleChange}
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              onOpenChange(false);
              e.stopPropagation();
            }
          }}
        />
      )}

      {step === CategorySteps.MatchToBills && transaction && (
        <MatchToBills transaction={transaction} />
      )}
    </Dialog>
  );
};
