import React from "react";
import Icon, { IconProps } from "../Icon";

export default function AmericanExpress({
  viewBox = "0 0 48 48",
  width = 48,
  height = 48,
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} {...iconProps} viewBox={viewBox}>
      <g clipPath="url(#clip0_5188_5836)">
        <path d="M4.12763 20.6272L3.33413 18.6478L2.5383 20.6272H4.12763Z" fill="white" />
        <path
          d="M23.3295 18.5967H21.717V19.7876H23.3504C23.6331 19.7876 23.9977 19.5679 23.9977 19.1921C23.9977 18.9 23.7163 18.5967 23.3295 18.5967Z"
          fill="white"
        />
        <path d="M34.8167 18.6478L35.6102 20.6272H34.0209L34.8167 18.6478Z" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 20.6054L1.79934 16.4H4.9111L5.93225 18.7557V16.4H9.80046L10.4084 18.1026L10.9977 16.4H28.3619V17.256C28.3619 17.256 29.2748 16.4 30.7749 16.4L36.409 16.4198L37.4125 18.7446V16.4H40.6496L41.5406 17.7354V16.4H44.8074V23.9547H41.5406L40.6868 22.615V23.9547H35.9307L35.4524 22.7638H34.1738L33.7033 23.9547H30.478C29.1871 23.9547 28.3619 23.1163 28.3619 23.1163V23.9547H23.4988L22.5336 22.7638V23.9547H4.45012L3.97216 22.7638H2.69765L2.22307 23.9547H0V20.6054ZM6.86776 17.3304V22.9871H8.27843V18.819L9.77795 22.9871H11.0151L12.5105 18.819V22.9871H13.9211V17.3304H11.6937L10.4191 20.9858L9.11309 17.3387L6.86776 17.3304ZM0.00930044 22.9881L2.43621 17.3314H4.27612L6.70071 22.9881H5.08587L4.64039 21.8553H2.03714L1.58935 22.9881H0.00930044ZM14.8863 22.9871V17.3304H19.4896V18.5957H16.3118V19.5633H19.4153V20.7542H16.3118V21.759H19.4896V22.9871H14.8863ZM20.3063 22.9881V17.3314H23.4455C24.4857 17.3314 25.4177 17.9762 25.4177 19.1665C25.4177 20.1841 24.6319 20.8396 23.8701 20.904L25.7262 22.9881H24.0023L22.3109 20.9785H21.717V22.9881H20.3063ZM27.4339 22.9871H25.9935V17.3304H27.4339V22.9871ZM30.5383 22.9871H30.8492L31.4432 21.7516H31.0719C30.1735 21.7516 29.5499 21.2915 29.5499 20.196C29.5499 19.2734 30.0953 18.6701 30.8664 18.6701H32.4826V17.3304H30.9235C29.0238 17.3304 28.1207 18.525 28.1207 20.182C28.1207 21.799 29.034 22.9871 30.5383 22.9871ZM33.9188 17.3314L31.4919 22.9881H33.0719L33.5197 21.8553H36.123L36.5685 22.9881H38.1833L35.7587 17.3314H33.9188ZM38.348 17.3304V22.9871H39.7587V19.34L42.1067 22.9871H43.8422V17.3304H42.4316V20.8844L40.1415 17.3304H38.348Z"
          fill="white"
        />
        <path
          d="M24.3944 28.5099H22.8306V29.8124H24.3898C24.8018 29.8124 25.0905 29.5387 25.0905 29.1612C25.0905 28.7593 24.8003 28.5099 24.3944 28.5099Z"
          fill="white"
        />
        <path
          d="M28.5476 28.495H30.1601C30.5469 28.495 30.8283 28.7984 30.8283 29.0905C30.8283 29.4663 30.4637 29.6859 30.181 29.6859H28.5476V28.495Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.31787 33.854V26.2993H17.2971L18.1531 27.4181L19.0375 26.2993H48V27.2297H45.3074C43.6595 27.2297 43.0939 28.1044 43.0939 29.0114C43.0939 30.0283 43.7115 30.7056 44.8205 30.7056H46.0963C46.5146 30.7056 46.6949 30.946 46.6949 31.1838C46.6949 31.432 46.514 31.6583 46.0963 31.6583H43.2731V32.8864H46.2795C47.2022 32.8864 47.7693 32.4392 48 31.8036V33.333C48 33.333 47.2426 33.8465 46.3666 33.854H30.3295L29.3643 32.6631V33.854H26.2014V31.8211C26.2014 31.8211 25.7693 32.1049 24.8353 32.1049H23.7587V33.854H18.9698L18.115 32.7112L17.247 33.854H9.31787ZM10.283 32.8864V27.2297H14.8863V28.495H11.7086V29.4626H14.812V30.6535H11.7086V31.6583H14.8863V32.8864H10.283ZM32.839 32.8864H37.4422V31.6583H34.2645V30.6535H37.3531V29.4626H34.2645V28.495H37.4422V27.2297H32.839V32.8864ZM15.0649 32.8864L17.3062 30.093L15.0116 27.2297H16.7888L18.1554 28.9998L19.5267 27.2297H21.2343L18.9698 30.0581L21.2152 32.8864H19.4383L18.1113 31.1443L16.8167 32.8864H15.0649ZM21.3828 32.8874V27.2306H24.4988C25.7774 27.2306 26.5243 28.0567 26.5243 29.1333C26.5243 30.4328 25.572 31.101 24.3155 31.101H22.8306V32.8874H21.3828ZM27.1369 32.8864H28.5476V30.8768H29.1415L30.833 32.8864H32.5569L30.7007 30.8024C31.4625 30.7379 32.2483 30.0825 32.2483 29.0649C32.2483 27.8745 31.3163 27.2297 30.2761 27.2297H27.1369V32.8864ZM38.0956 31.6583V32.8864H41.1021C42.3443 32.8864 42.942 32.0758 42.942 31.0861C42.942 30.1656 42.4056 29.4626 41.1694 29.4626H39.8329C39.486 29.4626 39.3132 29.204 39.3132 28.9695C39.3132 28.7414 39.453 28.5025 39.9072 28.5025H42.2831L42.877 27.2297H40.1299C38.482 27.2297 37.9165 28.1044 37.9165 29.0114C37.9165 30.0283 38.5341 30.7056 39.643 30.7056H40.9188C41.3371 30.7056 41.5174 30.946 41.5174 31.1838C41.5174 31.432 41.3365 31.6583 40.9188 31.6583H38.0956Z"
          fill="white"
        />
        <path
          d="M48 30.4004V27.3465L47.4605 28.5025H45.0847C44.6305 28.5025 44.4907 28.7414 44.4907 28.9695C44.4907 29.204 44.6635 29.4626 45.0104 29.4626H46.3468C47.2507 29.4626 47.7805 29.8385 48 30.4004Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5188_5836">
          <path
            d="M0 24C0 10.7452 10.7452 0 24 0V0C37.2548 0 48 10.7452 48 24V24C48 37.2548 37.2548 48 24 48V48C10.7452 48 0 37.2548 0 24V24Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </Icon>
  );
}
