import React from "react";
import { IconProps } from "./Icon";

export default function Search({
  viewBox = "0 0 18 18",
  width = 18,
  height = 18,
  color = "currentColor",
  stroke = color,
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
    >
      <path
        fill={stroke}
        d="M15.533 14.467l-2.55-2.542A5.94 5.94 0 0014.25 8.25a6 6 0 10-6 6 5.94 5.94 0 003.675-1.268l2.542 2.55a.75.75 0 001.23-.244.751.751 0 00-.164-.82zM3.75 8.25a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0z"
      ></path>
    </svg>
  );
}
