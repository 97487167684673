import { useMemo } from "react";
import { merge } from "lodash";
import { useToasts } from "@puzzle/ui";
import {
  useConnectMeowMutation,
  useMeowIntegrationQuery,
  MeowIntegrationDocument,
  MeowIntegrationQuery,
  IntegrationType,
  IntegrationConnectionCondition,
  useFinancialInstitutionsQuery,
} from "graphql/types";
import { usePendingConnections } from "../shared/PendingConnectionsProvider";
import { UseMeowResult } from "./types";
import useSavePage from "../shared/useSavePage";
import { UseIntegrationHook, useFinancialInstitutions } from "../shared";
import config from "lib/config";
import Analytics from "lib/analytics";

const useMeow: UseIntegrationHook<UseMeowResult> = ({
  companyId,
  integrationConnectionId,
  onClickConnect: baseOnClickConnect,
}): UseMeowResult => {
  const { toast } = useToasts();
  const { data, loading, refetch } = useMeowIntegrationQuery({
    variables: { companyId },
  });
  const { integrations } = data || {};
  const { addPendingConnection, removePendingConnection } = usePendingConnections();
  const { savePage } = useSavePage();
  const { disconnectIntegrationConnection, integrationConnections } = useFinancialInstitutions();

  const connection = useMemo(() => {
    const connectionId = integrationConnectionId ?? integrations?.meow.connection?.id;
    return integrationConnections.find((ic) => ic.id === connectionId);
  }, [integrationConnections, integrationConnectionId, integrations]);

  const disconnectIntegration = useMemo(() => {
    const connectionId = integrationConnectionId ?? integrations?.meow.connection?.id;
    if (!connectionId) {
      return undefined;
    }

    return async () => {
      const result = disconnectIntegrationConnection(connectionId);

      if (!integrationConnectionId) {
        refetch();
      }

      return result;
    };
  }, [
    disconnectIntegrationConnection,
    integrationConnectionId,
    refetch,
    integrations?.meow.connection?.id,
  ]);

  const [connectMeowMutation, { loading: connecting, error: connectionError }] =
    useConnectMeowMutation();

  const initializationInfo = integrations?.meow.initializationInfo;

  const onClickConnect = () => {
    baseOnClickConnect && baseOnClickConnect();
    savePage();

    if (!initializationInfo || !initializationInfo.connectUrl) {
      if (config.IS_LOCAL_DEVELOPMENT) {
        toast({
          message: "onClickConnect was called before initialization info was defined",
          status: "error",
        });
      }
      return;
    }
    window.location.href = initializationInfo.connectUrl;
  };

  const connectMeow = async (input: {
    companyId: string;
    code: string;
    state: string;
    condition?: IntegrationConnectionCondition;
  }) => {
    addPendingConnection(IntegrationType.Meow);

    return connectMeowMutation({
      variables: { input },

      update(cache, { data }) {
        if (!data) return;

        const meowIntegration = cache.readQuery<MeowIntegrationQuery>({
          query: MeowIntegrationDocument,
          variables: {
            companyId: companyId,
          },
        });

        cache.writeQuery({
          query: MeowIntegrationDocument,
          variables: {
            companyId,
          },
          data: merge({}, meowIntegration, {
            integrations: {
              meow: {
                connection: data.connectMeow.connection,
              },
            },
          }),
        });
      },

      onCompleted(data) {
        const { connection } = data.connectMeow;
        removePendingConnection(IntegrationType.Meow);

        Analytics.integrationConnected({
          connectionId: connection.id,
          integrationType: "Meow",
          totalAccounts: 1,
        });
      },

      onError({ message }) {
        Analytics.integrationConnectionFailed({
          integrationType: "Meow",
          reason: message,
        });
      },
    });
  };

  const { data: fiData } = useFinancialInstitutionsQuery({
    variables: { companyId, integrationType: IntegrationType.Meow },
  });

  const financialInstitutions = fiData?.financialInstitutionsFor ?? [];

  return {
    connectionError,
    connectMeow,
    connecting,
    loading,
    onClickConnect,
    connection,
    disconnect: disconnectIntegration,
    financialInstitutions,
  };
};

export default useMeow;
