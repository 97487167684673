import React from "react";
import Icon, { IconProps } from "./Icon";
import { getNumericRotate } from "./utils";

export function ChevronDown({
  viewBox = "0 0 14 14",
  width = 14,
  height = 14,
  color = "currentColor",
  fill = color,
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} {...iconProps} fill="none" viewBox={viewBox}>
      <path
        d="M2.46967 4.96967C2.76256 4.67678 3.23744 4.67678 3.53033 4.96967L7 8.43934L10.4697 4.96967C10.7626 4.67678 11.2374 4.67678 11.5303 4.96967C11.8232 5.26256 11.8232 5.73744 11.5303 6.03033L7 10.5607L2.46967 6.03033C2.17678 5.73744 2.17678 5.26256 2.46967 4.96967Z"
        fill={fill}
      />
    </Icon>
  );
}

export function ChevronUp(props: IconProps) {
  const totalRotate = 180 + getNumericRotate(props.rotate);
  return <ChevronDown {...props} rotate={totalRotate} />;
}

export function ChevronRight(props: IconProps) {
  const totalRotate = -90 + getNumericRotate(props.rotate);
  return <ChevronDown {...props} rotate={totalRotate} />;
}

export function ChevronLeft(props: IconProps) {
  const totalRotate = 90 + getNumericRotate(props.rotate);
  return <ChevronDown {...props} rotate={totalRotate} />;
}
