import React from "react";

import { Help, Stack, CheckboxField, Button } from "@puzzle/ui";
import { Add, StackOfPaper } from "@puzzle/icons";

import { CategoryFragment } from "graphql/types";
import DescriptionList from "components/common/DescriptionList";
import { useActiveCompany } from "components/companies";
import Link from "components/common/Link";
import { Box, S } from "ve";
import { Route } from "lib/routes";

import { EditSplitsSection } from "./EditSplitsSection";
import { FullTransactionFragment } from "../graphql.generated";
import {
  useUpdateBillPayment,
  useUpdateInvoicePayment,
  useExtraTransactionState,
} from "../hooks/useSingleTransaction";

export const CategorySection = React.memo(function CategorySection({
  transaction,
  canEdit,
  categories,
  showHeading = true,
  showSplitButton = true,
  showAIButtons = true,
  showBillCheckbox = true,
}: {
  transaction: FullTransactionFragment;
  canEdit: boolean;
  categories: CategoryFragment[];
  showHeading?: boolean;
  showSplitButton?: boolean;
  showAIButtons?: boolean;
  showBillCheckbox?: boolean;
}) {
  const { company } = useActiveCompany();
  const { canBeBillPayment, canBeInvoicePayment } = useExtraTransactionState(transaction);
  const updateBillPayment = useUpdateBillPayment(transaction);
  const updateInvoicePayment = useUpdateInvoicePayment(transaction);
  const billPaymentChecked = !!transaction.detail.isBillPayment;
  const invoicePaymentChecked = !!transaction.detail.isInvoicePayment;
  const matches = transaction.payment ? transaction.payment.matches : undefined;

  return (
    <DescriptionList
      itemGap={showHeading ? "2" : "0"}
      direction={"vertical"}
      items={[
        [
          showHeading && <>Category {canEdit && <StackOfPaper />}</>,
          <Stack key="category" gap="2">
            <EditSplitsSection
              transaction={transaction}
              categories={categories}
              canEdit={!!company && canEdit}
              showSplitButton={showSplitButton}
              showAIButtons={showAIButtons}
            />

            {showBillCheckbox && canBeBillPayment && transaction.linkedBills.length === 0 && (
              <Box css={{ display: "flex", alignItems: "center" }}>
                <CheckboxField
                  disabled={!canEdit}
                  checked={billPaymentChecked}
                  onCheckedChange={(checked) => {
                    if (!updateBillPayment.loading) {
                      updateBillPayment.mutation(checked);
                    }
                  }}
                  label={
                    <Box css={{ display: "flex", alignItems: "center", gap: S["1"] }}>
                      Bill payment
                      <Help
                        content={
                          <div>
                            Indicate if this transaction is a cash payment on a bill that exists in
                            the Puzzle system. When selected, this causes the accrual accounting
                            treatment to reduce accounts payable instead of record an expense.
                          </div>
                        }
                      />
                    </Box>
                  }
                />
              </Box>
            )}
            {canBeInvoicePayment &&
              (company?.features.arPaymentsEnabled ? (
                transaction.payment ? (
                  <DescriptionList
                    css={{
                      dt: { alignItems: "start" },
                    }}
                    items={[
                      [
                        "Payment(s)",
                        <>
                          {matches && matches.length > 0 && (
                            <div key="matches">
                              {matches.map((match, i) => {
                                if (match.invoice) {
                                  return (
                                    <React.Fragment key={match.invoice.id}>
                                      <Link
                                        href={`${Route.invoices}/${match.invoice.id}`}
                                        underline
                                        target="_blank"
                                      >
                                        Inoivce-{match.invoice.shortId}
                                      </Link>
                                      {i < matches.length - 1 && " "}
                                    </React.Fragment>
                                  );
                                }
                              })}
                            </div>
                          )}
                        </>,
                      ],
                    ]}
                  />
                ) : (
                  <Link
                    href={`${Route.newPayment}?selectedTransactionId=${transaction.id}&isFromTransaction=true`}
                  >
                    <Button variant="minimal" size="small" prefix={<Add />}>
                      Create payment
                    </Button>
                  </Link>
                )
              ) : (
                <Box css={{ display: "flex", alignItems: "center" }}>
                  <CheckboxField
                    disabled={!canEdit}
                    checked={invoicePaymentChecked}
                    onCheckedChange={(checked) => {
                      if (!updateInvoicePayment.loading) {
                        updateInvoicePayment.mutation(checked);
                      }
                    }}
                    label={
                      <Box css={{ display: "flex", alignItems: "center", gap: S["1"] }}>
                        Invoice payment
                        <Help
                          content={
                            <div>
                              Indicate if this transaction is a cash payment received for an invoice
                              that exists in the Puzzle system. When selected, this causes the
                              accrual accounting treatment to reduce accounts receivable instead of
                              record an expense.
                            </div>
                          }
                        />
                      </Box>
                    }
                  />
                </Box>
              ))}
          </Stack>,
        ],
      ]}
    />
  );
});
