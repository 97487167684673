import React from "react";
import IntegrationLogo from "../shared/IntegrationLogo";
import { Salesforce } from "@puzzle/icons";

const Logo = () => {
  return (
    <IntegrationLogo color="#215CA0">
      <Salesforce />
    </IntegrationLogo>
  );
};

export default Logo;
