import { useCallback, useEffect, useState } from "react";
import { useDropArea as useBaseDropArea } from "react-use";
import { MutationFunctionOptions, useApolloClient } from "@apollo/client";
import { now, parseAbsolute } from "@internationalized/date";
import { differenceInMilliseconds } from "date-fns";
import { useToasts } from "@puzzle/ui";

import {
  DeleteFileInput,
  Exact,
  FileFragment,
  HandleFileUploadedInput,
  AsyncReportResult,
} from "graphql/types";
import {
  useDeleteFileMutation,
  DeleteFileMutation,
  useGetDownloadInfoLazyQuery,
  GetFileUploadInfoDocument,
  GetFileUploadInfoQuery,
  GetFileUploadInfoQueryVariables,
  HandleFileUploadedMutation,
  HandleFileUploadedMutationVariables,
  HandleFileUploadedDocument,
} from "./graphql.generated";
import { useActiveCompany } from "components/companies";
import { Route } from "lib/routes";
import config from "lib/config";

const MAX_UPLOADS = 10;

// 5 megabytes (to support common receipt PDFs
const MAX_FILE_SIZE = 5000000;

export const useAsyncReportDownloadComplete = () => {
  const { toast } = useToasts();

  const onAsyncReportDownloadComplete = useCallback(
    async ({ result, reportType }: { result: AsyncReportResult; reportType: string }) => {
      if (result.report.reportId) {
        const url = new URL(
          `${config.ROOT_URL}${Route.report.replace(":id", result?.report.reportId)}`
        );

        url.searchParams.append("isSingle", "true");
        url.searchParams.append("reportType", reportType);
        window.open(url.toString());
      } else {
        toast({
          status: "error",
          message:
            "Your report failed to generate. Please try again. If the issue persists, please contact support.",
        });
      }
    },
    [toast]
  );

  return { onAsyncReportDownloadComplete };
};

export default function useFile({
  entityId,
  entityType,
  onUploadComplete,
  onError,
  contentTypes,
  file,
  onFileDeleted,
  maxUploads = MAX_UPLOADS,
  maxFileSize = MAX_FILE_SIZE,
  skipOnFiles = false,
}: Partial<Pick<HandleFileUploadedInput, "entityId" | "entityType">> & {
  onUploadComplete?: (files: (FileFragment & { path: string })[]) => void;
  onError?: (error?: string) => void;
  contentTypes?: string[];
  onFileDeleted?: (data: DeleteFileMutation) => void;
  file?: FileFragment | null;
  maxUploads?: number;
  /**
   * Max file size in bytes.
   */
  maxFileSize?: number;
  skipOnFiles?: boolean;
}) {
  const { timeZone } = useActiveCompany<true>();
  const apollo = useApolloClient();
  const [isUploading, setIsUploading] = useState(false);
  const [_deleteFile, { loading: isDeleting }] = useDeleteFileMutation();

  const [_getDownloadInfo, { data: downloadInfoData, loading: isDownloading }] =
    useGetDownloadInfoLazyQuery({
      // getDownloadInfo will block this from being called unless it's expired
      fetchPolicy: "network-only",
    });
  // Prefer the injected file reference first; it'll update via cache anyway.
  const downloadedFile = file || downloadInfoData?.file;
  const downloadInfo = downloadedFile?.downloadInfo;
  const getDownloadInfo = useCallback(
    (fileId = downloadedFile?.id) => {
      if (fileId) {
        _getDownloadInfo({ variables: { fileId } });
      }
    },
    [_getDownloadInfo, downloadedFile]
  );

  // Checks if the downloaded file is expired.
  // If it is, immediately refetch.
  // If it isn't, it will automatically download again around the expiration time.
  useEffect(() => {
    if (!downloadInfo || isDownloading) {
      return;
    }

    const currentTime = now(timeZone);
    const expiresAt = parseAbsolute(downloadInfo.urlExpiresAt, timeZone);
    const expired = currentTime.compare(expiresAt) > 0;

    if (expired) {
      getDownloadInfo();
    } else {
      const expiresIn = differenceInMilliseconds(expiresAt.toDate(), currentTime.toDate());

      // setTimeout isn't a perfect scheduler.
      // Replace with more frequent checks if this is buggy.
      const timeout = setTimeout(() => {
        getDownloadInfo();
      }, expiresIn);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [downloadInfo, downloadedFile, isDownloading, file, getDownloadInfo, timeZone]);

  const deleteFile = useCallback(
    async (
      options?: Partial<
        MutationFunctionOptions<
          DeleteFileMutation,
          Exact<{
            input: DeleteFileInput;
          }>
        >
      > & {
        fileId?: string;
      }
    ) => {
      const fileId = options?.fileId || file?.id;
      if (!fileId) {
        return;
      }

      // TODO This is in the payload because files don't know their related entities.
      // Wish I could make this more obvious...
      if (!entityType) {
        console.error("Please specify an entity type");
        return;
      }

      await _deleteFile({
        variables: {
          input: {
            fileId,
            entityType,
          },
        },
        ...options,
        onCompleted(data) {
          onFileDeleted?.(data);
          options?.onCompleted?.(data);
        },
      });
    },
    [_deleteFile, entityType, file, onFileDeleted]
  );

  const getFileUploadInfo = useCallback(
    (file: File) => {
      if (!entityId || !entityType) {
        throw new Error("Missing entityId or entityType");
      }

      return apollo.query<GetFileUploadInfoQuery, GetFileUploadInfoQueryVariables>({
        query: GetFileUploadInfoDocument,
        variables: {
          input: {
            entityId,
            entityType,
            filename: file.name,
            contentType: file.type,
          },
        },
      });
    },
    [apollo, entityId, entityType]
  );

  const handleFileUploaded = useCallback(
    (variables: Omit<HandleFileUploadedInput, "entityId" | "entityType">) => {
      if (!entityId || !entityType) {
        throw new Error("Missing entityId or entityType");
      }

      return apollo.mutate<HandleFileUploadedMutation, HandleFileUploadedMutationVariables>({
        mutation: HandleFileUploadedDocument,
        variables: {
          input: {
            entityId,
            entityType,
            ...variables,
          },
        },
      });
    },
    [apollo, entityId, entityType]
  );

  const uploadFile = useCallback(
    async (file: File) => {
      const { data } = await getFileUploadInfo(file);
      const { signedUrl, path } = data.getFileUploadInfo;

      return new Promise<FileFragment & { path: string }>((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("PUT", signedUrl, true);
        xhr.onerror = () => onError?.("There was an error uploading your file");
        xhr.onload = async () => {
          const status = xhr.status;

          if (status === 200) {
            const handleUploadResult = await handleFileUploaded({
              path,
              filename: file.name,
              contentType: file.type,
              size: file.size,
            });

            const fileResult = handleUploadResult.data?.handleFileUploaded.file;
            if (fileResult) {
              resolve({ ...fileResult, path });
            } else {
              reject("There was an error uploading your file");
            }
          }
        };

        xhr.send(file);
      });
    },
    [getFileUploadInfo, handleFileUploaded, onError]
  );

  const onFiles = useCallback(
    async (files: File[] | FileList) => {
      if (skipOnFiles) return;

      if (files.length > maxUploads) {
        return onError?.(
          `Cannot upload more than ${maxUploads} files at once, currently ${files.length}`
        );
      }

      if (!entityId || !entityType) {
        console.error("Please specify an entityId and entityType for uploads");
        return;
      }

      const errors = Array.from(files)
        .map((file) => {
          if (file.size > maxFileSize) {
            return "Your file is too large";
          } else if (contentTypes && !contentTypes.includes(file.type)) {
            return "The file type is incorrect";
          }
        })
        .filter(Boolean);

      if (errors.length > 0) {
        console.error(errors);
        return onError?.(errors[0]);
      }

      setIsUploading(true);
      try {
        const uploads = await Promise.all(Array.from(files).map(uploadFile));
        onUploadComplete?.(uploads);
      } catch (err) {
        console.error(err);
      }

      setIsUploading(false);
    },
    [
      contentTypes,
      entityId,
      entityType,
      maxFileSize,
      maxUploads,
      onError,
      onUploadComplete,
      uploadFile,
      skipOnFiles,
    ]
  );

  const [dropAreaProps, { over: isDropping }] = useBaseDropArea({
    onFiles,
    // onUri: uri => console.log("uri", uri),
    // onText: text => console.log("text", text),
  });

  return {
    dropAreaProps,
    onFiles,
    isUploading,
    isDropping,
    deleteFile,
    getDownloadInfo,
    downloadInfo,
    isDownloading,
    isDeleting,
  };
}
