import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Recurring({
  viewBox = "0 0 16 16",
  color = "currentColor",
  fill = color,
  ...iconProps
}: IconProps) {
  return (
    <Icon viewBox={viewBox} {...iconProps}>
      <g fill={fill} clipPath="url(#clip0_700_13127)">
        <path d="M8.021 3.89a4.086 4.086 0 00-3.274 1.637.722.722 0 01-1.155-.868A5.54 5.54 0 0113.19 5.99l.472-.786a.722.722 0 011.239.743l-1.445 2.407a.722.722 0 01-1.341-.383A4.093 4.093 0 008.02 3.89zM2.38 10.762l.473-.787a5.54 5.54 0 009.598 1.33.722.722 0 10-1.155-.867 4.093 4.093 0 01-7.368-2.443.722.722 0 00-1.341-.384L1.142 10.02a.722.722 0 101.239.743z"></path>
      </g>
      <defs>
        <clipPath id="clip0_700_13127">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </Icon>
  );
}
