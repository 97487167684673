import React from "react";

import { DateInput, Field } from "@puzzle/ui";
import { CalendarDateString, DateValue, parseDate } from "@puzzle/utils";

import { PagedContractRevenueScheduleFragment } from "../graphql.generated";

type Props = {
  closingDate?: CalendarDateString;
  existingSchedule?: PagedContractRevenueScheduleFragment;
  handleOnChange?: (value: DateValue | null) => void;
};

export const ClosingDayInput = ({ closingDate, existingSchedule, handleOnChange }: Props) => {
  return (
    <Field label="Closing date">
      <DateInput
        size="small"
        minDate={existingSchedule?.startDay ? parseDate(existingSchedule?.startDay) : undefined}
        maxDate={existingSchedule?.endDay ? parseDate(existingSchedule?.endDay) : undefined}
        placeholder="Pick a closing date"
        value={closingDate ? parseDate(closingDate) : undefined}
        onChange={handleOnChange}
      />
    </Field>
  );
};
