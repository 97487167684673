import React from "react";
import Icon, { IconProps } from "./Icon";

export default function File({
  viewBox = "0 0 18 18",
  size = 18,
  color = "currentColor",
  ...iconProps
}: IconProps) {
  return (
    <Icon size={size} viewBox={viewBox} {...iconProps} fill="transparent">
      <path
        d="M3.5 2C3.5 1.72386 3.72386 1.5 4 1.5H9.8C9.92839 1.5 10.0519 1.54939 10.1448 1.63793L14.3448 5.63793C14.4439 5.7323 14.5 5.86316 14.5 6V16C14.5 16.2761 14.2761 16.5 14 16.5H4C3.72386 16.5 3.5 16.2761 3.5 16V2Z"
        stroke={color}
      />
      <path d="M9.5 2V6.5H14" stroke={color} />
    </Icon>
  );
}
