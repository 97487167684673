import React from "react";
import Logo from "../rippling/Logo";
import { useRippling } from "../rippling";
import IntegrationDetails from "./IntegrationDetails";
import { DetailProps } from "./types";

const RipplingDetails = ({ companyId, integrationConnectionId, ...props }: DetailProps) => {
  const { connection, onClickConnect, disconnect } = useRippling({
    companyId,
    integrationConnectionId,
  });

  return (
    <IntegrationDetails
      logo={<Logo />}
      title="Rippling"
      connection={connection}
      onDisconnect={disconnect}
      onReconnect={onClickConnect}
      {...props}
    />
  );
};

export default RipplingDetails;
