import Bugsnag from "@bugsnag/js";
import { H } from "highlight.run";
import config from "./config";

if (!(Bugsnag as any)._client) {
  if (!config.BUGSNAG_API_KEY) {
    if (!config.IS_LOCAL_DEVELOPMENT) {
      console.warn("BUGSNAG_API_KEY not configured");
    }
  } else if (!config.IS_TEST) {
    Bugsnag.start({
      apiKey: config.BUGSNAG_API_KEY,
      releaseStage: config.PUZZLE_ENV,
      enabledReleaseStages: ["development", "staging", "production"],
      appVersion: process.env.BUILD_ID,
      enabledBreadcrumbTypes: config.IS_LOCAL_DEVELOPMENT ? [] : undefined,
      onError: async (event) => {
        if (typeof window === "undefined") {
          return;
        }
        try {
          const highlight = await H.getSessionDetails();
          event.addMetadata("links", {
            highlightUrl: highlight?.url || "",
            highlightDirectUrl: highlight?.urlWithTimestamp || "",
          });
        } catch (err) {
          // TODO There might be potential for an infinite loop between Highlight/bugsnag here
        }
        return true;
      },
    });
  }
}

export default Bugsnag;
