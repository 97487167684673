import { useToasts } from "@puzzle/ui";
import {
  useConnectDeelMutation,
  DeelIntegrationDocument,
  DeelIntegrationQuery,
  IntegrationType,
  useDeelIntegrationQuery,
} from "graphql/types";
import { ConnectDeelInput, UseDeelResult } from "./types";
import { merge } from "lodash";
import { useEffect, useMemo } from "react";
import useSavePage from "../shared/useSavePage";
import { UseIntegrationHook, useFinancialInstitutions, usePendingConnections } from "../shared";
import config from "lib/config";
import Analytics from "lib/analytics";

const useDeel: UseIntegrationHook<UseDeelResult> = ({
  companyId,
  integrationConnectionId,
  onClickConnect: baseOnClickConnect,
}): UseDeelResult => {
  const { toast } = useToasts();
  const { data, loading, refetch } = useDeelIntegrationQuery({
    variables: { companyId },
  });
  const { integrations } = data || {};

  const { addPendingConnection, removePendingConnection } = usePendingConnections();
  const { savePage } = useSavePage();
  const { disconnectIntegrationConnection, integrationConnections } = useFinancialInstitutions();

  const connection = useMemo(() => {
    const connectionId = integrationConnectionId ?? integrations?.deel.connection?.id;
    return integrationConnections.find((ic) => ic.id === connectionId);
  }, [integrationConnections, integrationConnectionId, integrations]);

  const disconnectIntegration = useMemo(() => {
    const connectionId = connection?.id;
    if (!connectionId) {
      return undefined;
    }

    return async () => {
      const result = disconnectIntegrationConnection(connectionId);

      if (!integrationConnectionId) {
        refetch();
      }

      return result;
    };
  }, [disconnectIntegrationConnection, integrationConnectionId, connection?.id, refetch]);

  const [connectDeelMutation, { loading: connecting, error: connectionError }] =
    useConnectDeelMutation();

  useEffect(() => {
    if (connectionError) {
      Analytics.integrationConnectionFailed({
        integrationType: "Deel",
        reason: connectionError.message,
      });
    }
  }, [connectionError]);

  const initializationInfo = integrations?.deel.initializationInfo;

  const onClickConnect = () => {
    baseOnClickConnect && baseOnClickConnect();
    savePage();

    if (!initializationInfo) {
      if (config.IS_LOCAL_DEVELOPMENT) {
        toast({
          message: "useDeel's onClickConnect was called before initialization info was defined",
          status: "error",
        });
      }
      return;
    }
    window.location.href = initializationInfo.connectUrl;
  };

  const connectDeel = async (input: ConnectDeelInput) => {
    addPendingConnection(IntegrationType.Deel);

    return connectDeelMutation({
      variables: { input },

      update(cache, { data }) {
        if (!data) return;
        const deelIntegration = cache.readQuery<DeelIntegrationQuery>({
          query: DeelIntegrationDocument,
          variables: {
            companyId,
          },
        });

        cache.writeQuery({
          query: DeelIntegrationDocument,
          variables: {
            companyId,
          },
          data: merge({}, deelIntegration, {
            integrations: {
              deel: {
                connection: {
                  ...data.connectDeel.connection,
                },
              },
            },
          }),
        });
      },

      onCompleted(data) {
        const { connection } = data.connectDeel;
        removePendingConnection(IntegrationType.Deel);

        Analytics.integrationConnected({
          connectionId: connection.id,
          integrationType: "Deel",
          totalAccounts: 1,
        });
      },

      onError({ message }) {
        Analytics.integrationConnectionFailed({
          integrationType: "Deel",
          reason: message,
        });
      },
    });
  };

  return {
    connectionError,
    connectDeel,
    loading,
    disconnect: disconnectIntegration,
    connecting,
    onClickConnect,
    connection,
  };
};

export default useDeel;
