import { Ramp } from "@puzzle/icons";
import React from "react";
import { IntegrationLogo } from "../shared";

const RampLogo = ({ circular }: { circular?: boolean }) => {
  return (
    <IntegrationLogo key="brex" color="#F7FE8B" circular={circular}>
      <Ramp />
    </IntegrationLogo>
  );
};

export default RampLogo;
