import React from "react";
import Logo from "../stripe/Logo";
import useStripe from "../stripe/useStripeIntegration";
import IntegrationDetails from "./IntegrationDetails";
import { DetailProps } from "./types";

const StripeDetails = ({ companyId, integrationConnectionId, ...props }: DetailProps) => {
  const stripe = useStripe({ companyId, integrationConnectionId });

  return (
    <IntegrationDetails
      logo={<Logo />}
      title="Stripe"
      connection={stripe.connection}
      onDisconnect={stripe.disconnect}
      onReconnect={stripe.onClickConnect}
      {...props}
    />
  );
};

export default StripeDetails;
