import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Subtract({
  className,
  rotate,
  viewBox = "0 0 12 12",
  color = "currentColor",
  ...props
}: IconProps) {
  return (
    <Icon className={className} rotate={rotate} viewBox={viewBox} {...props}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M9 .25a2.75 2.75 0 00-2.52 3.855L4.106 6.48a2.75 2.75 0 101.414 1.414L7.895 5.52A2.75 2.75 0 109 .25zM7.75 3a1.25 1.25 0 112.5 0 1.25 1.25 0 01-2.5 0zM3 7.75a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
}
