import { NextApiRequest, NextApiResponse, NextPageContext } from "next";
import { setCookie, parseCookies, destroyCookie } from "nookies";
import { Cookies } from "lib/cookies";

const COOKIE_MAX_AGE = 60 * 30; // 30 min

export enum CustomizedPartner {
  Stripe = "stripe",
  AngelList = "angellist",
  LTSE = "ltse",
  Index = "index",
  Fondo = "fondo_referral",
  Mercury = "mercury",
  Every = "every",
}

export enum ApiPartnerAuthFlowType {
  LinkToken = "linktoken",
  OAuth = "oauth",
}

export type ApiPartnerLinkTokenConfiguration = {
  token: string;
  redirectToPartnerUri?: string;
};

export type ApiPartnerSettings = {
  name: string;
  supportsRedirect: boolean;
  requiresFitTest: boolean;
  hasWebhooksEnabled: boolean;
};

export const savePartnerLinkTokenConfig = (
  ctx: NextPageContext | { req: NextApiRequest; res: NextApiResponse },
  config: ApiPartnerLinkTokenConfiguration
) => {
  setCookie(ctx, Cookies.ApiPartnerLinkTokenConfig, JSON.stringify(config), {
    maxAge: COOKIE_MAX_AGE,
    path: "/",
  });
  setCookie(ctx, Cookies.ApiPartnerAuthType, ApiPartnerAuthFlowType.LinkToken, {
    maxAge: COOKIE_MAX_AGE,
    path: "/",
  });
};

export const getApiPartnerLinkTokenConfig = () => {
  const cookie = parseCookies()[Cookies.ApiPartnerLinkTokenConfig];
  if (!cookie) {
    return null;
  }

  return JSON.parse(cookie) as ApiPartnerLinkTokenConfiguration;
};

export const clearPartnerCookies = async (
  ctx: NextPageContext | { req: NextApiRequest; res: NextApiResponse }
) => {
  await destroyCookie(ctx, Cookies.OldPartnerAuth);
  await destroyCookie(ctx, Cookies.ThirdPartyLogin);
  await destroyCookie(ctx, Cookies.ApiPartnerOAuthRequestId);
  await destroyCookie(ctx, Cookies.ApiPartnerAuthType);
  await destroyCookie(ctx, Cookies.ApiPartnerSettings);
  await destroyCookie(ctx, Cookies.PartnerOnboardingId);
};

export const savePartnerAuthCookies = async (
  ctx: NextPageContext | { req: NextApiRequest; res: NextApiResponse },
  fetchApiPartnerSettings: (from: string) => Promise<ApiPartnerSettings | undefined>,
  from?: string,
  requestId?: string,
  prefilledOnboardingId?: string
) => {
  if (from) {
    // The current user has come from a partner
    await setCookie(ctx, Cookies.ThirdPartyLogin, from, {
      maxAge: COOKIE_MAX_AGE,
      path: "/",
    });

    if (prefilledOnboardingId) {
      await setCookie(ctx, Cookies.PartnerOnboardingId, prefilledOnboardingId, {
        maxAge: COOKIE_MAX_AGE,
        path: "/",
      });
    }

    const partnerSettings = await fetchApiPartnerSettings(from);
    if (partnerSettings) {
      // The referring partner is an API partner
      await setCookie(ctx, Cookies.ApiPartnerSettings, JSON.stringify(partnerSettings), {
        maxAge: COOKIE_MAX_AGE,
        path: "/",
      });

      if (requestId) {
        // This API partner is using OAuth
        await setCookie(ctx, Cookies.ApiPartnerOAuthRequestId, requestId, {
          maxAge: COOKIE_MAX_AGE,
          path: "/",
          httpOnly: true,
          secure: true,
        });
        await setCookie(ctx, Cookies.ApiPartnerAuthType, ApiPartnerAuthFlowType.OAuth, {
          maxAge: COOKIE_MAX_AGE,
          path: "/",
        });
      }
    }
  }
};

export const getThirdPartyLoginCookie = (
  ctx?: NextPageContext | { req: NextApiRequest; res: NextApiResponse }
) => {
  return parseCookies(ctx)[Cookies.ThirdPartyLogin];
};

export const getApiPartnerAuthType = (
  ctx?: NextPageContext | { req: NextApiRequest; res: NextApiResponse }
): string | undefined => {
  return parseCookies(ctx)[Cookies.ApiPartnerAuthType];
};

export const getApiPartnerOAuthRequestId = (
  ctx?: NextPageContext | { req: NextApiRequest; res: NextApiResponse }
): string | undefined => {
  return parseCookies(ctx)[Cookies.ApiPartnerOAuthRequestId];
};

export const getApiPartnerSettings = (
  ctx?: NextPageContext | { req: NextApiRequest; res: NextApiResponse }
): ApiPartnerSettings | undefined => {
  const cookie = parseCookies(ctx)[Cookies.ApiPartnerSettings];

  if (!cookie) {
    return undefined;
  }

  return JSON.parse(cookie) as ApiPartnerSettings;
};

export const getPartnerOnboardingId = (
  ctx?: NextPageContext | { req: NextApiRequest; res: NextApiResponse }
): string | undefined => {
  return parseCookies(ctx)[Cookies.PartnerOnboardingId];
};

export const clearPartnerOnboardingId = async (
  ctx?: NextPageContext | { req: NextApiRequest; res: NextApiResponse }
) => {
  await destroyCookie(ctx, Cookies.PartnerOnboardingId);
};
