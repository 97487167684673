import packageInfo from "../../package.json";
import { HrTime, SpanContext, SpanStatus } from "@opentelemetry/api";
import { ExportResult, ExportResultCode } from "@opentelemetry/core";
import { now } from "@internationalized/date";

import { ReadableSpan, SpanExporter } from "@opentelemetry/sdk-trace-web";

/**
 * This code is copied from GW with a few changes to make it less specific to GW
 * https://github.com/puzzlefin/gateway/blob/master/src/observe/exporters.ts
 */
enum OTelTypes {
  span = "span",
  metric = "metric",
}

const NANOSECOND_DIGITS = 9;

type StatusCodeRepr = {
  status_code: string;
  description?: string;
};

const MAX_SPANS = 50;

const MAX_QUEUE_SIZE_MB = 5;
const MAX_QUEUE_SIZE_BYTES = MAX_QUEUE_SIZE_MB * 1024 * 1024;

interface LogEntry {
  level: "info" | "error";
  timestamp: string;
  app: string;
  message: string;
}

const logQueue: string[] = [];
let queueSize = 0;
let isProcessing = false;

function sleep(timeMs: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, timeMs);
  });
}

const processLogEntries = () => {
  while (logQueue.length > 0) {
    const entry = logQueue.shift();
    if (!entry) continue;
    queueSize -= Buffer.byteLength(entry, "utf8");
    if (!process.stdout.write(entry + "\n")) {
      // buffer full - give it time to drain
      sleep(100)
        .then(processLogEntries)
        .catch((error) => {
          process.stdout.write("ERROR processing log: " + JSON.stringify(error) + "\n");
        });
      return;
    }
  }
};

const processQueue = () => {
  if (isProcessing) return;
  isProcessing = true;

  setImmediate(() => {
    while (queueSize > MAX_QUEUE_SIZE_BYTES) {
      let removedSize = 0;
      let removedCount = 0;

      while (queueSize > MAX_QUEUE_SIZE_BYTES && logQueue.length > 0) {
        const removedEntry = logQueue.shift();
        if (!removedEntry) continue;
        const entrySize = Buffer.byteLength(removedEntry, "utf8");
        removedSize += entrySize;
        queueSize -= entrySize;
        removedCount++;
      }

      const dt = new Date().toISOString();
      const logMsg: LogEntry = {
        level: "error",
        timestamp: dt,
        app: packageInfo.name,
        message: `dropped ${removedCount} entries from the log, total MB ${(
          removedSize /
          (1024 * 1024)
        ).toFixed(2)}`,
      };

      process.stdout.write(JSON.stringify(logMsg) + "\n");
    }

    processLogEntries();

    isProcessing = false;
  });
};

export const asyncPrint = (message: string): void => {
  logQueue.push(message);
  queueSize += Buffer.byteLength(message, "utf8");
  processQueue();
};

const sendTrace = async (trace: string) => {
  try {
    await fetch("/api/tracing", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Origin: window.location.origin,
      },
      body: trace,
    });
  } catch (error) {
    console.error("Error sending trace:", error);
  }
};

const GEOMETRIC_WEIGHT = 0.8;
function getMeanRtt() {
  if (typeof window !== "undefined" && window.localStorage) {
    const storedRtt = localStorage.getItem("meanRtt");
    return storedRtt ? parseFloat(storedRtt) : 0;
  }
  return 0;
}
function setMeanRtt(rtt: number) {
  if (typeof window !== "undefined" && window.localStorage) {
    localStorage.setItem("meanRtt", rtt.toString());
  }
}

/* eslint-disable no-console */
/* tslint:disable:no-console */
export class StructuredLogSpanExporter implements SpanExporter {
  /**
   * Export spans.
   *
   * @param spans
   * @param resultCallback
   */
  export(spans: ReadableSpan[], resultCallback?: (result: ExportResult) => void): void {
    let spanCount = 0;
    const curSpans: ReadableSpan[] = [];
    spans.forEach((span) => {
      curSpans.push(span);

      if (spanCount >= MAX_SPANS) {
        StructuredLogSpanExporter._emitSpans(curSpans);
        spanCount = 0;
        curSpans.length = 0;
      }
    });
    // Emit any remaining spans
    StructuredLogSpanExporter._emitSpans(curSpans);

    if (resultCallback) {
      return resultCallback({ code: ExportResultCode.SUCCESS });
    }
  }

  /**
   * Shutdown the exporter.
   */
  shutdown(): Promise<void> {
    return Promise.resolve();
  }

  private static async _emitSpans(spans: ReadableSpan[]) {
    if (spans.length === 0) return;

    let meanRtt = getMeanRtt();

    const logMsg = {
      level: "trace",
      spans: spans.map((s) => StructuredLogSpanExporter._toJson(s)),
      otel_type: OTelTypes.span,
      timestamp: now("UTC").toAbsoluteString(),
      rtt: meanRtt,
      app: packageInfo.name,
    };

    const startTime = Date.now();
    await sendTrace(JSON.stringify(logMsg));
    const roundTripTime = Date.now() - startTime;

    meanRtt = meanRtt
      ? meanRtt * GEOMETRIC_WEIGHT + (1 - GEOMETRIC_WEIGHT) * roundTripTime
      : roundTripTime;
    setMeanRtt(meanRtt);
  }

  /**
   * Convert hrTime to timestamp, for example "2019-05-14T17:00:00.000123456Z"
   * Taken from OpenTelemetry under Apache License, Version 2.0 - and bugfix applied for
   * fractional time[1]
   *
   * @param time
   */
  static hrTimeToTimeStamp(time: HrTime): string {
    const precision = NANOSECOND_DIGITS;
    // Seeing non integer time[1] on OSX, truncate it
    const tmp = `${"0".repeat(precision)}${Math.trunc(time[1])}Z`;
    const nanoString = tmp.substr(tmp.length - precision - 1);
    const date = new Date(time[0] * 1000).toISOString();
    return date.replace("000Z", nanoString);
  }
  /**
   * converts span info into a format consistent with what our opentelemetry collector expects
   *
   * @param span
   */
  private static _toJson(span: ReadableSpan) {
    return {
      name: span.name,
      context: StructuredLogSpanExporter._formatContext(span.spanContext()),
      parent_id: span.parentSpanId,
      start_time: StructuredLogSpanExporter.hrTimeToTimeStamp(span.startTime),
      end_time: StructuredLogSpanExporter.hrTimeToTimeStamp(span.endTime),
      kind: span.kind,
      attributes: span.attributes,
      events: span.events.map((e) => {
        return {
          name: e.name,
          timestamp: StructuredLogSpanExporter.hrTimeToTimeStamp(e.time),
          attributes: e.attributes,
        };
      }),
      status: StructuredLogSpanExporter._formatStatus(span.status),
      resource: {
        attributes: span.resource.attributes,
      },
      links: span.links.map((l) => {
        return {
          context: StructuredLogSpanExporter._formatContext(l.context),
          attributes: l.attributes,
        };
      }),
    };
  }
  private static _formatContext(context: SpanContext) {
    return {
      trace_id: `0x${context.traceId}`,
      span_id: `0x${context.spanId}`,
      trace_state: context.traceState,
    };
  }
  private static _formatStatus(status: SpanStatus) {
    const res: StatusCodeRepr = {
      status_code: status.code.toString(),
    };
    if (status.message) {
      res.description = status.message;
    }
    return res;
  }
}
