import { ApolloClient, NormalizedCacheObject, from, split, HttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { RetryLink } from "@apollo/client/link/retry";
import { makeVar } from "@apollo/client";
import { NextApiRequest, NextApiResponse, NextPageContext } from "next";
import { GraphQLFormattedError } from "graphql";
import auth0 from "lib/auth0";
import config from "lib/config";
import redirect from "lib/redirect";
import { Route } from "lib/routes";
import createCache from "./createCache";
import { reportError } from "lib/errors";
import { persistApolloCache } from "./cachePersistor";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";

export const lastRequestVar = makeVar(0);

/**
 * Creates and configures the ApolloClient
 * @param  {Object} [initialState={}]
 */
export default async function useCreateApolloClient(
  initialState: NormalizedCacheObject,
  ctx?: NextPageContext | { req: NextApiRequest; res: NextApiResponse },
  forwardCookies = true
) {
  let accessToken: string | undefined;
  const isServer = typeof window === "undefined";

  try {
    if (ctx && isServer) {
      const result = await auth0.getAccessToken(
        ctx.req as NextApiRequest,
        ctx.res as NextApiResponse,
        {}
      );
      accessToken = result.accessToken;
    }
    // eslint-disable-next-line no-empty
  } catch (err) {}

  const { host, protocol = "http" } = ctx?.req?.headers || {};
  const endpointUri = isServer
    ? config.GRAPHQL_ENDPOINT
    : (host ? `${protocol}://${host}` : "") + "/api/proxy/graphql";
  const logout = () => {
    redirect({ path: Route.logout }, ctx?.req, ctx?.res);
  };

  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: 3000,
      jitter: true,
    },
    attempts: {
      max: 5,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      retryIf: (error: any, _operation: any) => {
        if (error && error.networkError && "statusCode" in error.networkError) {
          return error.networkError.statusCode === 500;
        }
        if (error && error.result) {
          return error.result.status === 500;
        }
        if (error && error.graphQLErrors) {
          return error.graphQLErrors.some(
            (e: any) => e.extensions?.code === "INTERNAL_SERVER_ERROR"
          );
        }
        return false;
      },
    },
  });

  const authLink = setContext((_, { headers }) => {
    const newHeaders = { ...headers };
    // proxy cookies between SSR requests
    if (forwardCookies && ctx?.req?.headers?.cookie) {
      newHeaders.cookie = ctx.req.headers.cookie;
    }

    if (accessToken) {
      newHeaders.authorization = `Bearer ${accessToken}`;
    }

    return {
      headers: newHeaders,
    };
  });

  const isAuthError = (err: GraphQLFormattedError) => {
    return err.extensions?.code === "UNAUTHENTICATED";
  };

  const isUserLockedError = (err: GraphQLFormattedError) => {
    return err.extensions?.code === "USER_LOCKED";
  };

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        if (isAuthError(err) && !config.IS_SSR) {
          logout();
        } else if (isUserLockedError(err)) {
          redirect({ path: Route.emailVerification }, ctx?.req, ctx?.res);
        } else {
          const error = new Error(err.message);
          error.name = "GraphQLError";

          // Don't report these
          // TODO find a better way to manage these
          if (
            // Ledger gives 404 when the company isn't ready
            error.message.includes("Request failed with status code 404") ||
            // Expired email verification code
            error.message.includes("Expired code") ||
            error.message.includes("Internal Server Error")
          ) {
            continue;
          }

          reportError(error, {
            graphql: {
              ...operation,
              code: err.extensions?.code,
              path: err.path?.join(" > "),
              locations: err.locations,
            },
          });
        }
      }
    }

    if (networkError) {
      reportError(`[Network error]: ${networkError}`);
    }
  });

  const cache = createCache(initialState);
  persistApolloCache(cache);

  const fetcher: typeof fetch = async (uri, options) => {
    const result = await fetch(uri, {
      ...options,
      headers: {
        ...options?.headers,
        "content-type": "application/json",
      },
    });
    lastRequestVar(lastRequestVar() + 1);
    return result;
  };

  const httpLink = new HttpLink({
    credentials: "include",
    uri: endpointUri,
    fetch: fetcher,
  });

  const batchLink = new BatchHttpLink({
    credentials: "include",
    uri: endpointUri,
    fetch: fetcher,
    batchMax: 5,
  });

  // TODO enable when actually start running real subscriptions
  // you'll have to fix the 'An insecure WebSocket connection may not be initiated from a page loaded over HTTPS.'
  // error. should be straightforward

  // const subscriptionsUrl = config.GRAPHQL_ENDPOINT.replace(/https|http/, "ws");
  // const wsLink = new WebSocketLink({
  //   uri: subscriptionsUrl,
  //   options: {
  //     reconnect: true,
  //     connectionParams: {
  //       headers: {
  //         authorization,
  //       }
  //     }
  //   }
  // });

  // const splitLink = split(
  //   ({ query }) => {
  //     const definition = getMainDefinition(query);
  //     return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  //   },
  //   wsLink,
  //   httpLink
  // );

  const client = new ApolloClient<NormalizedCacheObject>({
    connectToDevTools: config.IS_LOCAL_DEVELOPMENT,
    ssrMode: config.IS_SSR,
    cache,
    name: "Frontend",
    defaultOptions: {
      watchQuery: {
        fetchPolicy: isPosthogFeatureFlagEnabled(FeatureFlag.ApolloCachePersistor)
          ? "cache-and-network"
          : "cache-first",
      },
    },
    assumeImmutableResults: true,
    link: from([
      errorLink,
      authLink,
      retryLink,
      split(
        // Disable batching by passing:
        // context: { batch: false }
        (operation) => operation.getContext().batch === false || isServer,
        httpLink,
        batchLink
      ),
    ]),
  });

  return client;
}
