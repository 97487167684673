import { AlertDialog } from "@puzzle/ui";
import React from "react";

const StopSyncingAccountConfirmationModal = ({
  onConfirm,
  ...props
}: {
  onConfirm: () => void;
} & React.ComponentProps<typeof AlertDialog>) => {
  return (
    <AlertDialog {...props} size="small">
      <AlertDialog.Title basic showClose>
        Deactivate Account
      </AlertDialog.Title>
      <AlertDialog.Body>
        Deactivating this account will pause the syncing of data from the current source to this
        account. Your historical records will still exist in the system, but no new transactions
        will be added. You can resume syncing by re-activating your account at any time.
      </AlertDialog.Body>
      <AlertDialog.Footer>
        <AlertDialog.CancelButton>Never mind</AlertDialog.CancelButton>
        <AlertDialog.ConfirmButton onClick={onConfirm} variant="negative">
          Deactivate
        </AlertDialog.ConfirmButton>
      </AlertDialog.Footer>
    </AlertDialog>
  );
};

export default StopSyncingAccountConfirmationModal;
