import React, { useMemo } from "react";
import { FeedItem, GenericData } from "@knocklabs/client/dist/types";

import { styled, Avatar } from "@puzzle/ui";
import { Confirmed } from "@puzzle/icons";

import { UpdateType } from "./shared";
import { Feed } from "./Feed";

const UpdatesListItem = styled("div", {
  display: "flex",
  cursor: "pointer",
  padding: "$2 $1h",
  alignItems: "center",
  position: "relative",
  borderBottom: "1px solid $mauve680",

  "&:last-child": {
    borderBottom: 0,
  },

  "&:hover": {
    backgroundColor: "$mauve680",
    borderRadius: "4px",
  },

  defaultVariants: {
    isSeen: false,
  },

  variants: {
    isSeen: {
      false: {
        background: "#414154", // Color not available in theme file
        borderRadius: "4px",

        "&:after": {
          content: '""',
          position: "absolute",
          width: "8px",
          height: "8px",
          borderRadius: "8px",
          background: "$greenalpha",
          top: "calc(50% - 4px)",
          right: "8px",
        },
      },
    },
  },
});

const ItemAvatar = ({ item }: { item: FeedItem<GenericData> }) => {
  const content = useMemo(() => {
    const actor = item.actors?.[0];

    switch (item.data?.messageType) {
      case UpdateType.UserRemoved:
      case UpdateType.UserAdded: {
        return (
          <Avatar
            user={{
              name: item.data?.userName ?? "Unknown",
            }}
          />
        );
      }
      default: {
        return !("email" in actor) && <Confirmed size={28} />;
      }
    }
  }, [item]);

  return <div>{content}</div>;
};

const UpdatesList = () => {
  return (
    <Feed
      feed="updates"
      emptyTitle="No updates to show"
      emptyDescription="When something happens to a transaction you're subscribed to, you'll be notified here."
    />
  );
};

export default UpdatesList;
