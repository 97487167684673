import { useEffect, useMemo } from "react";
import config from "lib/config";
import Knock from "@knocklabs/client";
import useSelf from "components/users/useSelf";

const useKnockClient = (archived?: string) => {
  const { notificationConfig, self } = useSelf();
  const token = notificationConfig?.token;

  const knockClient = useMemo<Knock | null>(() => {
    if (config.KNOCK_API && self?.id) {
      const client = new Knock(config.KNOCK_API as string);
      client.authenticate(self.id, self?.id !== token ? token : undefined);
      return client;
    }

    return null;
  }, [self?.id, token, archived]);

  useEffect(() => {
    return () => knockClient?.teardown();
  }, [self?.id, token, archived]);

  return knockClient;
};

export default useKnockClient;
