import React from "react";
import IntegrationLogo from "../shared/IntegrationLogo";
import { Expensify } from "@puzzle/icons";

const Logo = () => {
  return (
    <IntegrationLogo color="#4C5E6A">
      <Expensify />
    </IntegrationLogo>
  );
};

export default Logo;
