import React from "react";
import { Tooltip } from "./Tooltip";
import { Help as HelpIcon } from "@puzzle/icons";
import { IconButton } from "./Button";

export const Help = ({ className, css, ...props }: React.ComponentProps<typeof Tooltip>) => {
  return (
    <Tooltip className={className} arrow={false} sideOffset={12} {...props}>
      <IconButton size="small" css={css} as="span">
        <HelpIcon fill="currentColor" />
      </IconButton>
    </Tooltip>
  );
};

Help.toString = IconButton.toString;
