import React from "react";
import Icon, { IconProps } from "./Icon";

export default function CheckList({
  viewBox = "0 0 11 6",
  width = 11,
  height = 6,
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} {...iconProps} viewBox={viewBox}>
      <g opacity="0.6">
        <g>
          <path stroke="#D1D1D5" strokeWidth="2" d="M4 1L11 1"></path>
          <circle cx="1" cy="1" r="1" fill="#D9D9D9"></circle>
        </g>
        <g>
          <path stroke="#D1D1D5" strokeWidth="2" d="M4 5L11 5"></path>
          <circle cx="1" cy="5" r="1" fill="#D9D9D9"></circle>
        </g>
      </g>
    </Icon>
  );
}
