import { useEffect, useRef, useCallback } from "react";
import { useRouter } from "next/router";
import { Span } from "@opentelemetry/api";
import config from "lib/config";
import { customParentTrace } from "./tracers";

/**
 * Referenced: https://github.com/vercel/next.js/blob/canary/examples/cms-buttercms/pages/_app.js
 * This hook will trace how long it takes for a page to load on route change
 * Essentially tracks react component lifecycle events like render and componentDidMount
 */
export const usePageLoadTrace = () => {
  const router = useRouter();
  const spanRef = useRef<Span | null>(null);
  const startTimeRef = useRef<number | null>(null);

  const startSpan = useCallback(() => {
    const startTime = performance.now();
    const curSpan = customParentTrace("pageLoad", {
      attributes: { startTime },
    });

    spanRef.current = curSpan;
    startTimeRef.current = startTime;
  }, [spanRef, startTimeRef]);

  const cleanSpan = useCallback(() => {
    if (spanRef.current) {
      spanRef.current.end();
      spanRef.current = null;
      startTimeRef.current = null;
    }
  }, [spanRef, startTimeRef]);

  const endSpan = useCallback(() => {
    if (!spanRef.current) return;

    const endTime = performance.now();
    const duration = startTimeRef.current ? endTime - startTimeRef.current : -1;

    spanRef.current.setAttribute("endTime", endTime);
    spanRef.current.setAttribute("duration", duration);

    cleanSpan();
  }, [spanRef, cleanSpan, startTimeRef]);

  useEffect(() => {
    if (!config.TRACE_ENABLED) {
      return;
    }

    router.events.on("routeChangeStart", startSpan);
    router.events.on("routeChangeComplete", endSpan);
    router.events.on("routeChangeError", cleanSpan);

    return () => {
      router.events.off("routeChangeStart", startSpan);
      router.events.off("routeChangeComplete", endSpan);
      router.events.off("routeChangeError", cleanSpan);
    };
  }, [router, startSpan, endSpan, cleanSpan]);

  return null;
};
