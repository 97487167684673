import React from "react";
import { styled, Dialog, Text, Button } from "@puzzle/ui";

import Stack from "components/common/Stack";
import { StatusIcon } from "@puzzle/ui";

const IconContainer = styled("div", {
  width: "min-content",
  margin: "0 auto $2h",
});

const CompleteStep = ({ onContinue }: { onContinue: () => void }) => {
  return (
    <Dialog.Body
      css={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
      }}
    >
      <IconContainer>
        <StatusIcon status="success" size={54} active />
      </IconContainer>

      <Stack gap="1">
        <Text variant="headingS" color="gray50">
          You're fully categorized!
        </Text>
        <Text variant="bodyS" color="gray400">
          Head over to the dashboard to see updated metrics and financial statements.
        </Text>
      </Stack>

      <Button onClick={onContinue} css={{ marginTop: "$3" }}>
        Go to dashboard
      </Button>
    </Dialog.Body>
  );
};

export default CompleteStep;
