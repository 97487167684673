import React from "react";
import Icon, { IconProps } from "./Icon";

export default function BackButton({
  width = 14,
  height = 12,
  viewBox = "0 0 14 12",
  color = "currentColor",
}: IconProps) {
  return (
    <Icon width={width} height={height} fill="none" viewBox={viewBox}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M6.707.293a1 1 0 010 1.414L3.414 5H13a1 1 0 110 2H3.414l3.293 3.293a1 1 0 11-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 0z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
}
