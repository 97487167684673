import React from "react";
import * as AlertDialogPrimitive from "@radix-ui/react-alert-dialog";
import { VariantProps } from "@stitches/react";
import { Close as CloseIcon } from "@puzzle/icons";
import { styled } from "./stitches";
import { Dialog } from "./Dialog";
import Button, { IconButton } from "./Button";
import { InDialogProvider } from "./InDialogContext";

const Overlay = styled(AlertDialogPrimitive.Overlay, Dialog.styles.overlay);
const Content = styled(AlertDialogPrimitive.Content, Dialog.styles.content);
const TitleRoot = styled(AlertDialogPrimitive.Title, Dialog.styles.titleRoot);
const ContentInner = styled("div", Dialog.styles.contentInner);
const TitleText = styled("div", Dialog.styles.titleText);
const Body = styled("div", Dialog.styles.body);
const FooterRoot = styled("div", Dialog.styles.footer, {
  defaultVariants: {
    align: "right",
  },
});
const Actions = styled("div", Dialog.styles.actions, {});
const Footer = ({ children, ...props }: React.ComponentProps<typeof FooterRoot>) => {
  return (
    <FooterRoot {...props}>
      <Actions>{children}</Actions>
    </FooterRoot>
  );
};
const Cancel = AlertDialogPrimitive.Cancel;
const Action = AlertDialogPrimitive.Action;
const CancelButton = ({
  variant = "secondary",
  children,
  onClick,
  ...props
}: React.ComponentProps<typeof Button>) => {
  return (
    <Cancel asChild onClick={onClick}>
      <Button variant={variant} {...props}>
        {children}
      </Button>
    </Cancel>
  );
};

const WarningButton = ({
  variant = "negative",
  children,
  onClick,
  ...props
}: React.ComponentProps<typeof Button>) => {
  return (
    <Cancel asChild onClick={onClick}>
      <Button variant={variant} {...props}>
        {children}
      </Button>
    </Cancel>
  );
};

const ConfirmButton = ({
  variant = "primary",
  children,
  onClick,
  ...props
}: React.ComponentProps<typeof Button>) => {
  return (
    <Action asChild onClick={onClick}>
      <Button variant={variant} {...props}>
        {children}
      </Button>
    </Action>
  );
};

const Title = ({
  children,
  basic = false,
  showClose = !basic,
  ...props
}: React.PropsWithChildren<{
  basic?: boolean;
  showClose?: boolean;
}>) => {
  return (
    <TitleRoot basic={basic} {...props}>
      <TitleText>{children}</TitleText>
      {showClose && (
        <AlertDialogPrimitive.Cancel tabIndex={-1} asChild>
          <IconButton
            css={{
              width: "16px",
              height: "16px",
            }}
          >
            <CloseIcon color="currentColor" />
          </IconButton>
        </AlertDialogPrimitive.Cancel>
      )}
    </TitleRoot>
  );
};

export const AlertDialog = Object.assign(
  React.forwardRef<
    HTMLDivElement,
    AlertDialogPrimitive.DialogProps &
      AlertDialogPrimitive.DialogContentProps &
      VariantProps<typeof ContentInner> & {
        trigger?: React.ReactNode;
        showOverlay?: boolean;
        width?: number | string;
      }
  >(
    (
      {
        defaultOpen,
        open,
        onOpenChange,
        children,
        trigger,
        width,
        showOverlay = true,
        size,
        ...props
      },
      ref
    ) => {
      return (
        <InDialogProvider>
          <AlertDialogPrimitive.Root
            defaultOpen={defaultOpen}
            open={open}
            onOpenChange={onOpenChange}
          >
            {trigger && (
              <AlertDialogPrimitive.Trigger asChild>{trigger}</AlertDialogPrimitive.Trigger>
            )}
            <AlertDialogPrimitive.Portal>
              {showOverlay && <Overlay onClick={(e) => e.stopPropagation()} />}
              <Content {...props} ref={ref}>
                <ContentInner size={size} css={{ maxWidth: width }}>
                  {children}
                </ContentInner>
              </Content>
            </AlertDialogPrimitive.Portal>
          </AlertDialogPrimitive.Root>
        </InDialogProvider>
      );
    }
  ),
  { Title, Body, Footer, Cancel, Action, CancelButton, ConfirmButton, WarningButton }
);

AlertDialog.displayName = "AlertDialog";
