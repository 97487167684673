import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Restart({
  viewBox = "0 0 10 12",
  color = "currentColor",
  ...iconProps
}: IconProps) {
  return (
    <Icon {...iconProps} viewBox={viewBox}>
      <path
        fill={color}
        d="M4.4 11.6a4.733 4.733 0 01-2.991-1.575C.603 9.131.2 8.067.2 6.833c0-.677.128-1.308.384-1.891a4.793 4.793 0 011.05-1.525l.85.85A3.571 3.571 0 001.4 6.833c0 .9.286 1.678.859 2.334A3.626 3.626 0 004.4 10.383V11.6zm1.2 0v-1.217a3.531 3.531 0 002.142-1.208c.572-.661.858-1.442.858-2.342 0-1-.35-1.85-1.05-2.55-.7-.7-1.55-1.05-2.55-1.05h-.116L5.65 4l-.85.833-2.2-2.2 2.2-2.2.85.85-.75.75H5c1.334 0 2.467.467 3.4 1.4.934.934 1.4 2.067 1.4 3.4 0 1.234-.403 2.3-1.208 3.2-.806.9-1.803 1.423-2.992 1.567z"
      ></path>
    </Icon>
  );
}
