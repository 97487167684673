import React from "react";
import Icon, { IconProps } from "../Icon";

export default function Carta({
  viewBox = "0 0 48 48",
  width = 48,
  height = 48,
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} {...iconProps} viewBox={viewBox}>
      <path
        d="M31.2846 20.0956H30.9802H30.7658H30.7401C30.0241 20.0956 29.1773 20.1345 28.1847 20.0956H28.0261C27.8117 20.0956 27.3572 20.0544 27.3508 20.0544L27.0335 20.0263C26.6048 19.9894 26.1632 19.9331 25.6937 19.853L25.3464 19.7924L25.1577 19.7642C25.0377 19.7404 24.5318 19.6278 24.4181 19.5996C24.3045 19.5715 24.2209 19.552 24.1202 19.526C23.9401 19.4805 23.7579 19.4286 23.5714 19.3744L23.3141 19.2965C23.1405 19.2466 22.9647 19.1882 22.7846 19.1275L22.463 19.0171C22.3065 18.963 22.15 18.9045 21.9893 18.8438L25.5243 16.2451L31.2846 20.0956Z"
        fill="white"
      />
      <path
        d="M17.0991 31.1162C16.5274 31.1162 16.1115 30.9429 15.8514 30.5964C15.5913 30.2499 15.4584 29.7035 15.4527 28.957V27.8742C15.4527 27.112 15.5856 26.5597 15.8514 26.2176C16.1172 25.8754 16.5417 25.7043 17.1248 25.7043C17.4924 25.6836 17.8565 25.7864 18.1602 25.9967C18.278 26.0763 18.3765 26.1817 18.4485 26.3051C18.5205 26.4284 18.5641 26.5665 18.5761 26.7091C18.5786 26.7212 18.585 26.7321 18.5943 26.7399C18.6037 26.7478 18.6154 26.7522 18.6276 26.7525H19.5194C19.5336 26.7525 19.5472 26.7468 19.5573 26.7366C19.5673 26.7264 19.573 26.7127 19.573 26.6983C19.5694 26.2656 19.4013 25.8508 19.1035 25.5397C18.6833 25.0633 18.0059 24.8251 17.0712 24.8251C16.2051 24.8251 15.5491 25.0806 15.1032 25.5917C14.6573 26.1028 14.4351 26.8651 14.4365 27.8786V29.1649C14.4365 30.1034 14.6595 30.8101 15.1054 31.2851C15.5513 31.7601 16.208 31.9983 17.0755 31.9997C18.1216 31.9997 18.8427 31.7009 19.2385 31.1032C19.3568 30.9336 19.4499 30.7476 19.5151 30.5509C19.5656 30.3521 19.5909 30.1476 19.5901 29.9424C19.5901 29.9284 19.5848 29.915 19.5752 29.9049C19.5656 29.8948 19.5525 29.8888 19.5387 29.8883H18.6297C18.6175 29.8885 18.6058 29.8929 18.5965 29.9008C18.5871 29.9087 18.5807 29.9195 18.5783 29.9316C18.4389 30.6549 17.9587 31.1162 17.0991 31.1162Z"
        fill="white"
      />
      <path
        d="M34.8453 25.7499C34.859 25.7499 34.8721 25.7444 34.8817 25.7347C34.8914 25.7249 34.8968 25.7117 34.8968 25.6979V24.8771C34.8971 24.8702 34.896 24.8633 34.8935 24.8569C34.891 24.8504 34.8872 24.8446 34.8824 24.8397C34.8776 24.8348 34.8718 24.831 34.8654 24.8285C34.859 24.826 34.8522 24.8249 34.8453 24.8252H33.3447V23.1468C33.3447 23.133 33.3393 23.1198 33.3296 23.1101C33.32 23.1003 33.3069 23.0948 33.2933 23.0948L32.38 23.3764C32.3664 23.3764 32.3533 23.3819 32.3436 23.3916C32.334 23.4013 32.3286 23.4146 32.3286 23.4284V24.8252H31.3317C31.3181 24.8252 31.305 24.8306 31.2953 24.8404C31.2857 24.8501 31.2803 24.8634 31.2803 24.8771V25.6979C31.2803 25.7117 31.2857 25.7249 31.2953 25.7347C31.305 25.7444 31.3181 25.7499 31.3317 25.7499H32.3286V30.0811C32.3286 30.6788 32.4615 31.1163 32.7273 31.3934C32.9931 31.6706 33.4212 31.8121 34.0114 31.8179H34.8475C34.8611 31.8179 34.8742 31.8124 34.8839 31.8027C34.8935 31.7929 34.8989 31.7797 34.8989 31.7659V30.9603C34.8989 30.9465 34.8935 30.9333 34.8839 30.9236C34.8742 30.9138 34.8611 30.9084 34.8475 30.9084H34.1015C33.9959 30.9201 33.8891 30.9066 33.7897 30.869C33.6903 30.8314 33.601 30.7707 33.5291 30.6918C33.3935 30.4632 33.3327 30.1972 33.3554 29.9317V25.7499H34.8453Z"
        fill="white"
      />
      <path
        d="M25.6956 26.694C25.691 26.6051 25.6759 26.5172 25.6506 26.432C25.5616 26.124 25.4105 25.8379 25.2068 25.5917C24.7609 25.0806 24.1049 24.8251 23.2389 24.8251C22.3042 24.8251 21.6261 25.0633 21.2044 25.5397C20.9192 25.8532 20.7655 26.2662 20.7757 26.6918C20.7757 26.7062 20.7813 26.72 20.7914 26.7301C20.8014 26.7403 20.8151 26.746 20.8293 26.746H21.7211C21.7336 26.7456 21.7455 26.7409 21.7549 26.7326C21.7643 26.7243 21.7706 26.7129 21.7725 26.7005C21.7785 26.5598 21.8164 26.4223 21.8833 26.2988C21.9502 26.1753 22.0443 26.0689 22.1584 25.988C22.4632 25.7782 22.8278 25.6755 23.196 25.6956C23.7791 25.6956 24.2036 25.8667 24.4694 26.2089C24.5918 26.3717 24.6662 26.5661 24.6838 26.7698C24.6838 26.8412 24.6838 26.9149 24.6838 26.9863V27.753C24.4179 27.7183 24.1521 27.6923 23.8863 27.675C23.6205 27.6577 23.4018 27.649 23.2303 27.649C22.2942 27.649 21.6075 27.8266 21.1701 28.1818C20.7328 28.5369 20.5142 29.1007 20.5142 29.8731C20.5142 30.595 20.7221 31.1212 21.138 31.4518C21.5046 31.7377 22.2742 31.8849 23.0502 31.9283C23.2548 31.9373 23.4598 31.93 23.6633 31.9066C24.3173 31.8493 24.9618 31.7104 25.582 31.493C25.6151 31.483 25.6443 31.4628 25.6654 31.4353C25.6866 31.4077 25.6986 31.3741 25.6999 31.3392V26.969C25.7063 26.8694 25.702 26.7763 25.6956 26.694ZM22.1391 30.8801C22.028 30.8427 21.9264 30.781 21.8415 30.6994C21.7567 30.6178 21.6907 30.5183 21.6482 30.408C21.5872 30.2351 21.5581 30.0523 21.5625 29.8688C21.5625 29.3822 21.6889 29.035 21.9419 28.8271C22.1949 28.6192 22.6522 28.5153 23.3139 28.5153C23.4575 28.5153 24.3129 28.5889 24.6323 28.6257C24.6482 28.6273 24.663 28.6349 24.6737 28.6469C24.6844 28.6589 24.6903 28.6745 24.6902 28.6907V30.7263C24.6902 30.7405 24.6858 30.7544 24.6778 30.766C24.6697 30.7776 24.6584 30.7864 24.6452 30.7913C23.8421 31.0979 22.9615 31.1291 22.1391 30.8801Z"
        fill="white"
      />
      <path
        d="M40.8995 26.694C40.8928 26.6079 40.8762 26.5229 40.8501 26.4406C40.7611 26.1326 40.61 25.8466 40.4064 25.6004C39.9605 25.0893 39.3045 24.8337 38.4384 24.8337C37.5009 24.8337 36.8227 25.072 36.404 25.5484C36.1188 25.8618 35.9651 26.2749 35.9752 26.7005C35.9752 26.7149 35.9809 26.7286 35.9909 26.7388C36.001 26.7489 36.0146 26.7546 36.0288 26.7546H36.9206C36.9331 26.7543 36.9451 26.7496 36.9545 26.7413C36.9639 26.733 36.9701 26.7216 36.9721 26.7092C36.978 26.5685 37.0159 26.431 37.0828 26.3075C37.1497 26.1839 37.2439 26.0776 37.358 25.9967C37.6626 25.7866 38.0273 25.6838 38.3955 25.7043C38.9786 25.7043 39.4031 25.8754 39.6689 26.2176C39.7923 26.3798 39.8668 26.5745 39.8833 26.7785C39.8833 26.8499 39.894 26.9235 39.894 26.995V27.7616C39.6282 27.727 39.3624 27.701 39.0966 27.6837C38.8307 27.6664 38.6099 27.6577 38.4384 27.6577C37.5037 27.6577 36.8177 27.8353 36.3804 28.1904C35.9431 28.5456 35.7251 29.1094 35.7266 29.8818C35.7266 30.6036 35.9345 31.1299 36.3504 31.4605C36.717 31.7464 37.4866 31.8936 38.2626 31.9369C38.4672 31.9459 38.6723 31.9387 38.8757 31.9153C39.5298 31.858 40.1742 31.719 40.7944 31.5016C40.8275 31.4917 40.8567 31.4715 40.8778 31.4439C40.899 31.4163 40.9111 31.3827 40.9123 31.3479V26.969C40.9102 26.8694 40.9059 26.7763 40.8995 26.694ZM37.343 30.8801C37.2318 30.8427 37.1302 30.781 37.0454 30.6994C36.9605 30.6178 36.8945 30.5183 36.852 30.408C36.791 30.2351 36.7619 30.0523 36.7663 29.8688C36.7663 29.3822 36.8928 29.035 37.1457 28.8271C37.3987 28.6192 37.856 28.5153 38.5177 28.5153C38.6614 28.5153 39.5167 28.5889 39.8361 28.6257C39.8519 28.6278 39.8664 28.6355 39.877 28.6474C39.8876 28.6593 39.8937 28.6747 39.894 28.6907V30.7264C39.8934 30.7404 39.8889 30.754 39.8809 30.7655C39.8729 30.777 39.8619 30.786 39.849 30.7913C39.0459 31.0979 38.1653 31.1291 37.343 30.8801Z"
        fill="white"
      />
      <path
        d="M30.4144 25.8279C30.4271 25.8274 30.4391 25.8219 30.4478 25.8126C30.4566 25.8033 30.4615 25.791 30.4615 25.7781V25.7391V24.8751C30.4615 24.7018 29.2846 24.9682 29.1753 25.0028C28.9281 25.0592 28.6959 25.1689 28.4946 25.3244C28.2933 25.4799 28.1277 25.6775 28.0091 25.9037V25.083C28.0091 25.0756 28.0076 25.0683 28.0048 25.0614C28.002 25.0546 27.9979 25.0484 27.9927 25.0432C27.9876 25.0379 27.9814 25.0338 27.9746 25.031C27.9679 25.0281 27.9606 25.0267 27.9533 25.0267H27.0679C27.0532 25.0267 27.039 25.0326 27.0285 25.0432C27.0181 25.0537 27.0122 25.068 27.0122 25.083V31.766C27.0122 31.7812 27.018 31.7957 27.0284 31.8066C27.0388 31.8175 27.053 31.8239 27.0679 31.8245H27.9812C27.9962 31.8239 28.0103 31.8175 28.0207 31.8066C28.0311 31.7957 28.0369 31.7812 28.0369 31.766V28.0217C28.0272 27.5817 28.1151 27.1452 28.2942 26.744C28.4067 26.49 28.5862 26.2722 28.8131 26.1145C29.0399 25.9567 29.3054 25.8651 29.5804 25.8496C29.7788 25.8244 29.9786 25.8121 30.1785 25.8128L30.4144 25.8279Z"
        fill="white"
      />
      <path
        d="M30.7764 20.505C17.0992 24.7452 7 20.505 7 20.505L14.5246 15C17.3801 17.1158 19.8625 18.4282 21.9892 19.2381C22.1499 19.2987 22.3064 19.3572 22.4629 19.4113L22.7845 19.5218C22.9646 19.5824 23.1404 19.6409 23.314 19.6907L23.5712 19.7687C23.7578 19.8228 23.94 19.8748 24.1201 19.9203C24.2208 19.9462 24.3194 19.9722 24.418 19.9939C24.5166 20.0155 25.2905 20.1845 25.357 20.1975L25.7043 20.2581C26.1738 20.3382 26.6197 20.3945 27.0441 20.4313L27.3614 20.4595C27.3614 20.4595 27.8223 20.492 28.0367 20.5006H28.1953C29.1879 20.5375 30.0347 20.5006 30.7507 20.5006L30.7764 20.505Z"
        fill="white"
      />
    </Icon>
  );
}
