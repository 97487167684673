import { rgba } from "polished";
import { createBreakpoint } from "react-use";

// Some of these could eventually be pulled from Figma.
// Try to stick to what's defined in Figma, as that's the current source of truth and reference.

export const breakpoints = {
  xs: 376,
  s: 640,
  m: 768,
  l: 1024,
  xl: 1280,
  xxl: 1536,
};

export const useBreakpoint = createBreakpoint(breakpoints) as () => keyof typeof breakpoints;

export const media = {
  xs: `(min-width: ${breakpoints.xs}px)`,
  s: `(min-width: ${breakpoints.s}px)`,
  m: `(min-width: ${breakpoints.m}px)`,
  l: `(min-width: ${breakpoints.l}px)`,
  xl: `(min-width: ${breakpoints.xl}px)`,
  xxl: `(min-width: ${breakpoints.xxl}px)`,
  animationEnabled: "(prefers-reduced-motion: no-preference)",
};

export const fontSizes = {
  heading1: "36px",
  heading2h: "32px",
  heading2: "24px",
  heading3: "20px",
  headingL: "18px",
  headingM: "16px",
  headingS: "14px",

  bodyXL: "20px",
  bodyL: "17px",
  bodyM: "15px",
  bodyS: "13px",
  bodyXS: "12px",

  // global default font size
  body: "14px",
};

export const lineHeights = {
  heading1: "44px",
  heading2h: "36px",
  heading2: "30px",
  heading3: "24px",
  headingL: "22px",
  headingM: "20px",
  headingS: "18px",

  bodyXL: "30px",
  bodyL: "24px",
  bodyM: "22px",
  bodyS: "20px",
  bodyXS: "18px",

  body: "20px",
};

export const letterSpacings = {
  bodyXS: "0.2px",
};

export const fonts = {
  inter: "Inter, Roboto, sans-serif",
};

export const fontWeights = {
  normal: 400,
  // TODO should be medium
  bold: 500,
  heavy: 600,

  heading1: "$bold",
  heading2h: "$bold",
  heading2: "$bold",
  heading3: "$bold",
  headingL: "$bold",
  headingM: "$bold",
  headingS: "$bold",
};

// Hmmm what's the most obvious convention?
export const radii = {
  xs: "2px",
  1: "4px",
  2: "8px",
  3: "12px",
  4: "16px",
  ellipse: "50%",

  scrollThumb: "20px",
};

const baseColors = {
  black: "#121718",
  blue100: "#2DB2E0",
  blue200: "#5AD4FF",
  blue300: "#4FB1F9",
  blue400: "#5B9EFF",
  blue500: "#63C5FF",
  gray50: "#F8F8FA",
  gray100: "#E8E8EA",
  gray200: "#D1D1D5",
  gray300: "#B3B2B8",
  gray400: "#95949B",
  gray500: "#77767E",
  gray600: "#595861",
  gray700: "#3C3B44",
  gray800: "#1E1D26",
  brown500: "#2A292E", // this is a new color so totally a guess
  green100: "#EBFFFC",
  green200: "#C9FFF1",
  green300: "#ADFFE2",
  green400: "#94FFCF",
  green500: "#81FFBB",
  green600: "#72F2A6",
  green700: "#62D89A",
  green800: "#52BD8D",
  greenalpha: "#50FAAB",
  elephant300: "#505066",
  mauve100: "#736285",
  mauve200: "#605172",
  mauve300: "#544868",
  mauve400: "#4A405E",
  mauve500: "#3F3754",
  mauve550: "#353547",
  mauve600: "#352F4A",
  mauve650: "#302F4A",
  mauve680: "#2D2D40",
  mauve700: "#2B2740",
  mauve800: "#211F35",
  mauve850: "#1C1B2E",
  mauve900: "#171629",
  mauve950: "#21212E",
  purple100: "#FDF5FF",
  purple200: "#F3D8FF",
  purple300: "#E5BDFC",
  purple400: "#D3A5F7",
  purple500: "#C08FEE",
  purple600: "#AB7DE3",
  purple700: "#966DD5",
  purple800: "#7A5AB9",
  purple900: "#60479C",
  red100: "#FFEDEB",
  red200: "#FFA8AE",
  red300: "#FF8395",
  red400: "#F74B75",
  red500: "#E7366C",
  red600: "#CB2863",
  red700: "#AC1D58",
  red800: "#8C134C",
  red900: "#780038",
  red1000: "#A61807",
  white: "#FFFFFF",
  white100: "rgba(255, 255, 255, 0.08)",
  yellow100: "#FFFFD5",
  yellow200: "#FFFF92",
  yellow300: "#FFF076",
  yellow350: "#F9E280",
  yellow400: "#F7DB60",
  yellow500: "#E8C34D",
  yellow600: "#CBA13D",
  yellow650: "#94833A",
  yellow700: "#AC802E",
  yellow800: "#8B6121",

  // yes this was in figma
  rhino200: "#4B4B66",
  rhino750: "#343445",
  rhino700: "#353548",
  rhino600: "#44445C",
  porpoise400: "#26253C",

  // aliases
  primary: "$greenalpha",
  positive: "$green600",
  negative: "$red600",

  // specific one-offs
  tableRowBorder: "#302f4a",
};

const alphaColors = {
  // why are these so granular?
  blackA04: rgba(baseColors.black, 0.04),
  blackA05: rgba(baseColors.black, 0.05),
  blackA08: rgba(baseColors.black, 0.08),
  blackA10: rgba(baseColors.black, 0.1),
  blackA16: rgba(baseColors.black, 0.16),
  blackA20: rgba(baseColors.black, 0.2),
  blackA24: rgba(baseColors.black, 0.24),
  blackA30: rgba(baseColors.black, 0.3),
  blackA32: rgba(baseColors.black, 0.32),
  blackA40: rgba(baseColors.black, 0.4),
  mauve800A24: rgba(baseColors.mauve800, 0.24),
  gray50A04: rgba(baseColors.gray50, 0.04),
  gray50A16: rgba(baseColors.gray50, 0.16),
};

export const colors = {
  ...baseColors,
  ...alphaColors,
};

// Unofficial scale for our gradients
// These are images, not normal color values.
// Only compatible with things like background.
export const gradients = {
  labelLight: "linear-gradient(180deg, #343547 0%, #2D2E3D 100%)",
  labelDark: "linear-gradient(180deg, #343547 0%, #22222E 100%)",
};

export const space = {
  "0": "0px",
  "0h": "4px",
  "1": "8px",
  "1h": "12px",
  "2": "16px",
  "2h": "20px",
  "3": "24px",
  "3h": "28px",
  "4": "32px",
  "4h": "36px",
  "5": "40px",
  "5h": "44px",
  "6": "48px",
  "6h": "52px",
  "7": "56px",
  "7h": "60px",
  "8": "64px",
  "8h": "68px",
  "9": "72px",
  "9h": "76px",
  "10": "80px",
  "10h": "84px",
  "11": "88px",
  "11h": "92px",
  "12": "96px",
  "12h": "100px",
  "13": "104px",
  "13h": "108px",
  "14": "112px",
  "14h": "116px",
  "15": "120px",
  "15h": "124px",
  "16": "128px",
  "16h": "132px",
  "17": "136px",
  "17h": "140px",
  "18": "144px",
  "18h": "148px",
  "19": "152px",
  "19h": "156px",
  "20": "160px",
  "20h": "164px",
  "21": "168px",
  "21h": "172px",
  "22": "176px",
  "22h": "180px",
  "23": "184px",
  "23h": "188px",
  "24": "192px",
  "24h": "196px",
  "25": "200px",
  "25h": "204px",
  "26": "208px",
  "26h": "212px",
  "27": "216px",
  "27h": "220px",
  "28": "224px",
  "28h": "228px",
  "29": "232px",
  "29h": "236px",
  "30": "240px",
  "30h": "244px",
  "31": "248px",
  "31h": "252px",
  "32": "256px",
  "32h": "260px",
  "33": "264px",
  "33h": "268px",
  "34": "272px",
  "34h": "276px",
};

export const spaceUnit = 8;

// Tokens should have static properties. The below code can generate the object.
//
// const space = Array(70)
//   .fill(spaceUnit)
//   .reduce((result, x, i) => {
//     const key = i / 2;
//     result[key.toString().replace('.5', 'h')] = key * x;
//     return result;
//   }, {});
