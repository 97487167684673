import { CalendarDate, DateValue } from "@internationalized/date";

import { styled, Text } from "@puzzle/ui";
import { DateFormatterResult, formatMoney, toCalendarMonthString } from "@puzzle/utils";

import { RecurringRevenueMetricType } from "graphql/types";

/**
 * Types
 */
export enum View {
  MRR = "mrr",
  ARR = "arr",
  Count = "count",
}

/**
 * Styles
 */
export const TableContainer = styled("div", { width: "100%", overflowX: "scroll" });

export const FlexCell = styled("div", {
  alignItems: "center",
  display: "flex",
  width: "100%",
});

export const MutedCell = styled("span", {
  color: "$gray500",
  top: "2px",
  position: "relative",
  marginRight: "$1",
});

export const HighlightText = styled(Text, { color: "$gray200", cursor: "pointer" });

export const HighlightTextLink = styled(Text, { color: "$blue400", cursor: "pointer" });

/**
 * Utils
 */
export const DATE_COLUMN_FORMAT = { month: "short", year: "2-digit" } as const;
export const formatPeriod = (period: CalendarDate, dateFormatter: DateFormatterResult) => {
  return dateFormatter.format(period).replace(" ", " '");
};

export const phasesToTableData = <T>(
  phases: T[],
  getDate: (phase: T) => DateValue,
  valueKey: keyof T
) => {
  return phases.reduce((acc, item) => {
    return {
      ...acc,
      [toCalendarMonthString(getDate(item)).toString()]: item[valueKey],
    };
  }, {});
};

export const viewLabels: Record<View, string> = {
  [View.ARR]: "ARR",
  [View.MRR]: "MRR",
  [View.Count]: "Customer",
};

export const footerRows = new Set([
  RecurringRevenueMetricType.GrossChurnRate,
  RecurringRevenueMetricType.GrossRetentionRate,
  RecurringRevenueMetricType.NetChurnRate,
  RecurringRevenueMetricType.NetRetentionRate,
]);

export const getRowOrder = (view: View, showAllMetrics = false) => {
  const top = [
    RecurringRevenueMetricType.BeginningOfPeriod,
    RecurringRevenueMetricType.New,
    ...(showAllMetrics ? [RecurringRevenueMetricType.Reactivation] : []),
  ];

  if (view === View.Count) {
    return [
      ...top,
      RecurringRevenueMetricType.Lost,
      RecurringRevenueMetricType.EndOfPeriod,
      ...(showAllMetrics ? [RecurringRevenueMetricType.Expansion] : []),
      ...(showAllMetrics ? [RecurringRevenueMetricType.Contraction] : []),
      ...Array.from(footerRows),
    ];
  }

  return [
    ...top,
    ...(showAllMetrics ? [RecurringRevenueMetricType.Expansion] : []),
    ...(showAllMetrics ? [RecurringRevenueMetricType.Contraction] : []),
    RecurringRevenueMetricType.Lost,
    RecurringRevenueMetricType.EndOfPeriod,
    ...Array.from(footerRows),
  ];
};

export const cohortFilterOptions = (showAllMetrics = false) => [
  RecurringRevenueMetricType.BeginningOfPeriod,
  RecurringRevenueMetricType.New,
  ...(showAllMetrics ? [RecurringRevenueMetricType.Reactivation] : []),
  ...(showAllMetrics ? [RecurringRevenueMetricType.Expansion] : []),
  ...(showAllMetrics ? [RecurringRevenueMetricType.Contraction] : []),
  RecurringRevenueMetricType.Lost,
  RecurringRevenueMetricType.EndOfPeriod,
];

export const typeToLabel = (type: RecurringRevenueMetricType) => {
  return type.replaceAll("_", " ").toLowerCase();
};

export const typeToStatus = (type: RecurringRevenueMetricType) => {
  switch (type) {
    case RecurringRevenueMetricType.Lost:
    case RecurringRevenueMetricType.Contraction:
      return "negative";
    case RecurringRevenueMetricType.New:
    case RecurringRevenueMetricType.Reactivation:
    case RecurringRevenueMetricType.Expansion:
      return "positive";
    default:
      "neutral";
  }
};

export const typeToTooltip = {
  [RecurringRevenueMetricType.BeginningOfPeriod]: {
    [View.MRR]: `Value of customer subscriptions at the beginning of the month, which always equals the prior month's "End of Period" amount.`,
    [View.ARR]: `Value of customer subscriptions at the beginning of the month, which always equals the prior month's "End of Period" amount, extrapolated for 12 months.`,
    [View.Count]: `Count of customers with subscriptions at the beginning of the month, which always equals the prior month's "End of Period" count.`,
  },
  [RecurringRevenueMetricType.New]: {
    [View.MRR]: `Value of new first-time customers who began subscriptions during the month.`,
    [View.ARR]: `Value of new first-time customers who began subscriptions during the month, extrapolated for 12 months.`,
    [View.Count]: `Count of new first-time customers who began subscriptions during the month.`,
  },
  [RecurringRevenueMetricType.Reactivation]: {
    [View.MRR]: `Value of customers who previously ended subscriptions and returned during the month.`,
    [View.ARR]: `Value of customers who previously ended subscriptions and returned during the month, extrapolated for 12 months.`,
    [View.Count]: `Count of customers who previously ended subscriptions and returned during the month.`,
  },
  [RecurringRevenueMetricType.Expansion]: {
    [View.MRR]: `Value of customers who increased their subscriptions during the month, less the value of their existing subscriptions.`,
    [View.ARR]: `Value of customers who increased their subscriptions during the month, less the value of their existing subscriptions, extrapolated for 12 months.`,
    [View.Count]: `Count of customers who increased their subscriptions during the month less the value of their existing subscriptions.`,
  },
  [RecurringRevenueMetricType.Contraction]: {
    [View.MRR]: `Decrease in value of customers who decreased the value of their subscriptions during the month, excluding customers who ended their subscriptions.`,
    [View.ARR]: `Decrease in value of customers who decreased the value of their subscriptions during the month, excluding customers who ended their subscriptions, extrapolated for 12 months.`,
    [View.Count]: `Count of customers who decreased the value of their subscriptions during the month, excluding customers who ended their subscriptions.`,
  },
  [RecurringRevenueMetricType.Lost]: {
    [View.MRR]: `Value of customers who ended their subscriptions during the month.`,
    [View.ARR]: `Value of customers who ended their subscriptions during the month, extrapolated for 12 months.`,
    [View.Count]: `Count of customers who ended all subscriptions during the month.`,
  },
  [RecurringRevenueMetricType.EndOfPeriod]: {
    [View.MRR]: `The value of customer subscriptions at the end of the month.`,
    [View.ARR]: `The value of customer subscriptions at the end of the month, extrapolated for 12 months.`,
    [View.Count]: `Count of customer subscriptions at the end of the month.`,
  },

  // Footers
  [RecurringRevenueMetricType.GrossChurnRate]: {
    [View.MRR]: `"Lost" revenue during the period divided by "Beginning" MRR.`,
    [View.ARR]: `"Lost" revenue during the period divided by "Beginning" ARR.`,
    [View.Count]: `Number of "Lost" customers during the period divided by number of Beginning customers.`,
  },
  [RecurringRevenueMetricType.GrossRetentionRate]: {
    [View.MRR]: `The inverse of Gross Churn, Gross Retention is "Beginning" MRR less "Lost" divided by "Beginning" MRR.`,
    [View.ARR]: `The inverse of Gross Churn, Gross Retention is Beginning ARR less "Lost" divided by "Beginning" ARR.`,
    [View.Count]: `The inverse of Gross Churn, Gross Retention is Beginning customers less "Lost" customers by Beginning customers.`,
  },
  [RecurringRevenueMetricType.NetChurnRate]: {
    [View.MRR]: `"Lost" + "Expansion" + "Contraction" revenue during the period divided by "Beginning" MRR.`,
    [View.ARR]: `"Lost" + "Expansion" + "Contraction" revenue during the period divided by "Beginning" ARR.`,
    [View.Count]: "",
  },
  [RecurringRevenueMetricType.NetRetentionRate]: {
    [View.MRR]: `The inverse of Net Churn, Net Retention is Beginning MRR plus "Lost" + "Expansion" + "Contraction" divided by "Beginning" MRR. It is possible for this to be greater than 100%.`,
    [View.ARR]: `The inverse of Net Churn, Net Retention is Beginning ARR plus "Lost" + "Expansion" + "Contraction" divided by "Beginning" ARR. It is possible for this to be greater than 100%.`,
    [View.Count]: "",
  },
};

export const formatCellAmount = (value?: string) => {
  if (!value || value === "0") return "-";

  return formatMoney({ currency: "USD", amount: value });
};

export const viewOptions = [
  {
    value: View.MRR,
    label: "MRR",
  },
  {
    value: View.ARR,
    label: "ARR",
  },
];
