import React, { useEffect, useState } from "react";
import Logo from "../billdotcom/Logo";
import IntegrationDetails from "./IntegrationDetails";
import { useBillDotCom } from "../billdotcom/useBillDotCom";
import { ImportBills } from "components/dashboard/Accounting/Bills/Import/ImportBills";
import { useFinancialInstitutions } from "../shared";
import { DetailProps } from "./types";

const BillDotComDetails = ({ companyId, integrationConnectionId, ...props }: DetailProps) => {
  const billDotCom = useBillDotCom({ companyId, integrationConnectionId });
  const { refetchAccountsAndIntegrations } = useFinancialInstitutions();
  const [showWizard, setShowWizard] = useState(false);
  const [isReconnecting, setIsReconnecting] = useState(false);

  useEffect(() => {
    if (integrationConnectionId && !showWizard && isReconnecting) {
      refetchAccountsAndIntegrations();
    }
  }, [integrationConnectionId, showWizard, isReconnecting, refetchAccountsAndIntegrations]);

  return (
    <>
      <IntegrationDetails
        logo={<Logo />}
        title="BILL"
        connection={billDotCom.connection}
        onDisconnect={billDotCom.disconnect}
        onReconnect={() => {
          setShowWizard(true);
          setIsReconnecting(true);
        }}
        {...props}
      />
      <ImportBills open={showWizard} onOpenChange={setShowWizard} mode="billDotCom" />
    </>
  );
};

export default BillDotComDetails;
