import React from "react";
import Icon, { IconProps } from "../Icon";

export default function SiliconValleyBank({
  viewBox = "0 0 48 48",
  width = 48,
  height = 48,
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} {...iconProps} viewBox={viewBox}>
      <g clipPath="url(#clip0_1119_19659)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.1527 21.0972C27.6102 20.7969 28.1494 20.6455 28.6964 20.6637L28.71 20.6652C30.9602 20.6652 32.2424 22.3428 32.2424 24.856C32.2424 27.3242 30.8595 29.0754 28.71 29.0453C28.177 29.0465 27.6545 28.8974 27.2025 28.615C26.7505 28.3326 26.3873 27.9285 26.1546 27.449H26.1305L25.3474 28.862H23.9976V16.5H26.117V22.3413H26.1411C26.3418 21.8321 26.6952 21.3976 27.1527 21.0972ZM26.1245 25.8947C26.3996 26.8056 27.0655 27.3648 28.023 27.3648C29.3819 27.3648 30.0613 26.3412 30.0613 24.8621C30.0613 23.5889 29.5668 22.3413 28.023 22.3413C26.9873 22.3413 26.3786 23.0042 26.1245 23.8549C26.0285 24.182 25.9809 24.5213 25.9832 24.8621C25.9795 25.2113 26.0271 25.5593 26.1245 25.8947Z"
          fill="#F8F8FA"
        />
        <path
          d="M11.918 23.9495L11.8998 23.9464C10.881 23.7719 10.015 23.6236 10.015 22.9063C10.015 22.3126 10.5456 21.9593 11.428 21.9593C12.3599 21.9593 12.889 22.4404 12.889 23.2911H14.8567C14.8567 21.6226 13.5715 20.6426 11.4204 20.6426C9.26942 20.6426 7.96318 21.558 7.96318 23.0822C7.96318 24.9296 9.63319 25.2017 11.0296 25.4271C12.0893 25.603 13.0363 25.7638 13.0363 26.6146C13.0363 27.2895 12.4426 27.6668 11.4144 27.6668C10.3622 27.6668 9.72939 27.1377 9.72939 26.2373H7.76025C7.76025 27.996 9.11309 29.0227 11.4204 29.0227C13.7278 29.0227 15.0806 28.0276 15.0806 26.3741C15.0806 24.4828 13.3551 24.1913 11.9335 23.9511L11.924 23.9495H11.918Z"
          fill="#F8F8FA"
        />
        <path
          d="M19.2173 28.0925H19.1932L17.3518 20.8037H15.0806L17.4285 28.8621H20.9835L23.3314 20.8037H21.0586L19.2173 28.0925Z"
          fill="#F8F8FA"
        />
        <path
          d="M34.8727 18.1641L33.2012 19.2012L36.3683 24.8321L33.2012 30.4629L34.8727 31.5001L40.257 24.8321L34.8727 18.1641Z"
          fill="#00C0FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1119_19659">
          <rect width="33" height="15" fill="white" transform="translate(7.5 16.5)" />
        </clipPath>
      </defs>
    </Icon>
  );
}
