import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Duplicate({
  viewBox = "0 0 16 16",
  color = "currentColor",
  ...iconProps
}: IconProps) {
  return (
    <Icon {...iconProps} viewBox={viewBox}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M5 1.5a.5.5 0 01.5-.5H11a.5.5 0 01.354.146l2.5 2.5A.5.5 0 0114 4v7.5a.5.5 0 01-.5.5h-8a.5.5 0 01-.5-.5v-10zM6 2v9h7V5h-2.5a.5.5 0 01-.5-.5V2H6zm5 .207L12.793 4H11V2.207zM3 5.5a.5.5 0 00-1 0v9a.5.5 0 00.5.5h7a.5.5 0 000-1H3V5.5z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
}
