/* eslint-disable react/display-name */
import React, { useMemo } from "react";
import { styled, theme, CSS } from "./stitches";

const Root = styled("span", {});

type Theme = typeof theme;

type FontSizes = keyof Theme["fontSizes"];
type LineHeights = keyof Theme["lineHeights"];
type FontWeights = keyof Theme["fontWeights"];
type LetterSpacings = keyof Theme["letterSpacings"];
type Colors = keyof Theme["colors"];

type TextProps = React.PropsWithChildren<{
  type?: FontSizes;
  variant?: FontSizes;

  size?: FontSizes;
  lineHeight?: LineHeights;
  letterSpacing?: LetterSpacings;
  weight?: FontWeights;
  color?: Colors | string;
  as?: string | (() => React.ReactNode);
  css?: CSS;
}>;

// Intentionally restricted to theme values for now.
// Feel free to use css prop for specific styles.
export const Text = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & TextProps
>(
  (
    { type, variant = type, color, size, lineHeight, letterSpacing, weight, css: _css, ...props },
    ref
  ) => {
    const css: CSS = useMemo(() => {
      const result = { ..._css };

      if (variant && variant in theme.fontSizes) {
        result.textStyle = `$${variant}`;
      }
      if (color) {
        if (color in theme.colors) {
          result.color = `$${color}`;
        } else {
          result.color = color;
        }
      }
      if (size && size in theme.fontSizes) {
        result.fontSize = `$${size}`;
      }
      if (lineHeight && lineHeight in theme.lineHeights) {
        result.lineHeight = `$${lineHeight}`;
      }
      if (letterSpacing && letterSpacing in theme.letterSpacings) {
        result.letterSpacing = `$${letterSpacing}`;
      }
      if (weight && weight in theme.fontWeights) {
        result.fontWeight = `$${weight}`;
      }

      return result;
    }, [_css, color, letterSpacing, lineHeight, size, variant, weight]);

    return <Root {...props} css={css} ref={ref} />;
  }
);

Text.toString = Root.toString;
