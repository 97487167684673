import React from "react";

export default function PeopleBorderless() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <g>
        <path
          stroke="#fff"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M14.375 5.625V3.958c0-.92-.746-1.666-1.667-1.666h-8.75c-.92 0-1.666.746-1.666 1.666v8.75c0 .92.746 1.667 1.666 1.667h1.667m2.147 3.333c.384-1.904 1.982-3.333 3.895-3.333s3.51 1.428 3.895 3.333m-7.79 0h-.48c-.92 0-1.667-.746-1.667-1.666v-8.75c0-.92.746-1.667 1.667-1.667h8.75c.92 0 1.666.746 1.666 1.667v8.75c0 .92-.746 1.666-1.666 1.666h-.48m-7.79 0h7.79m-2.02-7.291a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0z"
          opacity="0.25"
        ></path>
      </g>
    </svg>
  );
}
