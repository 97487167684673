import React from "react";
import Icon, { IconProps } from "./Icon";
import { getNumericRotate } from "./utils";

export function CaretDown({
  viewBox = "0 0 8 5",
  width = 8,
  height = 5,
  color = "currentColor",
  fill = color,
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} {...iconProps} fill="none" viewBox={viewBox}>
      <path
        d="M7.42843 0.928571C7.42843 0.694196 7.23424 0.5 6.99986 0.5H0.999861C0.765486 0.5 0.571289 0.694196 0.571289 0.928571C0.571289 1.04241 0.618164 1.14955 0.698521 1.22991L3.69852 4.22991C3.77888 4.31027 3.88602 4.35714 3.99986 4.35714C4.1137 4.35714 4.22084 4.31027 4.3012 4.22991L7.3012 1.22991C7.38156 1.14955 7.42843 1.04241 7.42843 0.928571Z"
        fill={fill}
      />
    </Icon>
  );
}

export function CaretUp(props: IconProps) {
  const totalRotate = 180 + getNumericRotate(props.rotate);
  return <CaretDown {...props} rotate={totalRotate} />;
}

export function CaretRight(props: IconProps) {
  const totalRotate = -90 + getNumericRotate(props.rotate);
  return <CaretDown {...props} rotate={totalRotate} />;
}

export function CaretLeft(props: IconProps) {
  const totalRotate = 90 + getNumericRotate(props.rotate);
  return <CaretDown {...props} rotate={totalRotate} />;
}
