import React from "react";
import { IconProps } from "./Icon";

export default function Sparkle2({ size = 16, viewBox = "0 0 16 16", ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={viewBox}
      fill="none"
      {...props}
    >
      <g clip-path="url(#clip0_1249_6918)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.8333 9.99999C11.4768 9.99999 9.99996 11.4768 9.99996 14.8333C9.99996 11.4768 8.52311 9.99999 5.16663 9.99999C8.52311 9.99999 9.99996 8.52314 9.99996 5.16666C9.99996 8.52314 11.4768 9.99999 14.8333 9.99999Z"
          stroke="#C08FEE"
          stroke-width="1.5"
          stroke-linejoin="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.49996 4.33332C5.30088 4.33332 4.33329 5.30092 4.33329 7.49999C4.33329 5.30092 3.3657 4.33332 1.16663 4.33332C3.3657 4.33332 4.33329 3.36573 4.33329 1.16666C4.33329 3.36573 5.30088 4.33332 7.49996 4.33332Z"
          stroke="#C08FEE"
          stroke-width="1.5"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1249_6918">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
