import React, { ReactElement } from "react";
import { styled, IconButton, CSS } from "@puzzle/ui";
import { File, Delete, Download } from "@puzzle/icons";

const Preview = styled("div", {
  display: "flex",
  justifyContent: "center",
  width: 40,
  flexShrink: 0,
  color: "$gray500",
});

export const DocumentRoot = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "$1h",
  alignItems: "center",
  outline: "none",

  cursor: "pointer",
  border: "1px solid #44445C",
  borderRadius: "$xs",
  padding: "$1h $2",

  transition: "all 0.1s ease-in-out",

  "&:hover": {
    borderColor: "$gray600",
  },

  "&:focus": {
    borderColor: "$gray500",
  },
});

const TextContent = styled("div", {
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  overflow: "hidden",
});

const iconColorStyles: CSS = {
  // gray500 is different; hover and focus re-added due to specificity
  [`${IconButton}`]: {
    color: "$gray500",
    "&:hover, &:focus": {
      color: "$gray300",
    },
  },
};

const Title = styled("div", iconColorStyles, {
  fontSize: "$headingS",
  color: "$gray100",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",

  "&:hover, &:focus, &:active": {
    [`${IconButton}`]: {
      color: "$gray300",
    },
  },
});

const Metadata = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "$1",
  fontSize: "$bodyXS",
  color: "$gray600",
});

const Actions = styled("div", iconColorStyles, {
  display: "flex",
  flexDirection: "row",
  gap: "$1",
});

const getHumanReadableFileSize = (sizeInBytes: number) => {
  if (sizeInBytes < 1000) {
    return `${sizeInBytes} bytes`;
  } else if (sizeInBytes < 1000 * 1000) {
    return `${(sizeInBytes / 1000).toFixed(2)} KB`;
  } else if (sizeInBytes < 1000 * 1000 * 1000) {
    return `${(sizeInBytes / 1000 / 1000).toFixed(2)} MB`;
  }
};

interface BaseRowProps {
  downloadUrl?: string;
  setPreviewFile: () => void;
  uploadedBy?: ReactElement | null
  onDelete: React.MouseEventHandler
  fileName: string
  size: number
};

export function BaseRow({
  size,
  fileName,
  setPreviewFile,
  uploadedBy,
  downloadUrl,
  onDelete
}: BaseRowProps) {
  const metadata = getHumanReadableFileSize(size);
  return (
    <DocumentRoot onClick={setPreviewFile} role="button" tabIndex={0}>
      <Preview>
        <File />
      </Preview>

      <TextContent>
        <Title>{fileName}</Title>
        {metadata && (
          <Metadata>
            {metadata}
            {uploadedBy}           
          </Metadata>
        )}
      </TextContent>

      <Actions>
        <IconButton onClick={onDelete}>
          <Delete color="currentColor" size={16} />
        </IconButton>

        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            if (downloadUrl) {
              window.open(downloadUrl);
            }
          }}
        >
          <Download color="currentColor" size={16} />
        </IconButton>
      </Actions>
    </DocumentRoot>
  );
};
