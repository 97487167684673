import React from "react";
import Icon, { IconProps } from "./Icon";

export default function MoneyOut({
  viewBox = "0 0 14 14",
  color = "currentColor",
  ...iconProps
}: IconProps) {
  return (
    <Icon {...iconProps} viewBox={viewBox}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M3.146 3.146a.5.5 0 01.708 0L10 9.293V5.5a.5.5 0 011 0V11H5.5a.5.5 0 010-1h3.793L3.146 3.854a.5.5 0 010-.708z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
}
