import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Schedule({
  viewBox = "0 0 16 16",
  color = "currentColor",
  fill = color,
  ...iconProps
}: IconProps) {
  return (
    <Icon viewBox={viewBox} {...iconProps}>
      <path
        fill={fill}
        d="M5.3 11.533L3.033 9.267l.85-.85L5.3 9.833 8.117 7l.85.85L5.3 11.533zM1.6 13.4c-.33 0-.613-.12-.848-.358A1.162 1.162 0 01.4 12.2V3.4c0-.322.117-.603.352-.842S1.27 2.2 1.6 2.2h1.2V.6H4v1.6h4V.6h1.2v1.6h1.2c.33 0 .612.12.847.358.235.24.353.52.353.842v8.8c0 .322-.118.603-.353.842a1.146 1.146 0 01-.847.358H1.6zm0-1.2h8.8v-6H1.6v6zm0-7.2h8.8V3.4H1.6V5z"
      ></path>
    </Icon>
  );
}
