import { styled } from "@puzzle/ui";
import { Confirmed, Download, Exclamation, ExternalInline } from "@puzzle/icons";

export const DropZone = styled("div", {
  backgroundColor: "$mauve950",
  color: "$gray500",
  cursor: "pointer",
  marginBottom: "$1",
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  gap: "$1h",
  padding: "$4 $1h",
  borderRadius: "4px",
  alignContent: "center",
  justifyContent: "center",
  position: "relative",
  border: "1px solid transparent",
  height: "160px",
  transition: "border-color 0.1s ease-in",

  variants: {
    isDropping: {
      true: {
        borderColor: "$purple400",
      },
    },

    hasSelectedFile: {
      true: {
        cursor: "default",
      },
    },
  },
});

export const Attachment = styled("div", {
  display: "flex",
  pointerEvents: "none",
  flexDirection: "column",
  textAlign: "center",
  alignItems: "center",
  gap: "$1h",
});

export const StyledConfirmed = styled(Confirmed, {
  marginRight: "$1",
  position: "relative",
  top: "2px",
});

export const StyledExclamation = styled(Exclamation, {
  marginRight: "$1",
  position: "relative",
  top: "2px",
});

export const StyledExternal = styled(ExternalInline, {
  color: "$gray400",
  marginLeft: "$0h",
});

export const StyledDownload = styled(Download, { color: "$gray400", marginLeft: "$0h" });
