import React, { SVGProps } from "react";

export const Clock = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 22 22">
      <path
        fill="#595861"
        d="M11 21.875c6.006 0 10.875-4.869 10.875-10.875S17.006.125 11 .125.125 4.994.125 11 4.994 21.875 11 21.875z"
      ></path>
      <path stroke="#fff" strokeLinecap="square" strokeMiterlimit="10" d="M11 2.75v1.875"></path>
      <path
        stroke="#fff"
        strokeLinecap="square"
        strokeMiterlimit="10"
        d="M16.833 5.167l-1.325 1.325M19.25 11h-1.875M16.833 16.833l-1.325-1.325M11 19.25v-1.875M5.167 16.833l1.325-1.325M2.75 11h1.875M5.167 5.167l1.325 1.325"
        opacity="0.4"
      ></path>
      <path stroke="#81FFBB" strokeLinecap="square" strokeMiterlimit="10" d="M11 11V4.625"></path>
      <path
        stroke="#81FFBB"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M11 11V2.75"
      ></path>
    </svg>
  );
};

export const Network = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14">
      <g clipPath="url(#clip0_439_13730)">
        <path
          fill="#fff"
          d="M10.5 9.625a2.175 2.175 0 00-1.595.7L5.44 8.206c-.116.27-.27.521-.457.747l3.468 2.12a2.152 2.152 0 00-.137.74A2.187 2.187 0 1010.5 9.625zM10.5 0a2.19 2.19 0 00-2.187 2.188c.002.252.048.502.137.739l-3.468 2.12c.187.226.341.477.457.746l3.466-2.118A2.184 2.184 0 1010.5 0z"
          opacity="0.4"
        ></path>
        <path
          fill="#81FFBB"
          d="M2.625 9.188a2.187 2.187 0 110-4.375 2.187 2.187 0 010 4.375zm0-3.5a1.313 1.313 0 100 2.625 1.313 1.313 0 000-2.626z"
        ></path>
        <circle cx="10.5" cy="2.188" r="2.188" fill="#81FFBB"></circle>
        <circle cx="10.5" cy="11.813" r="2.188" fill="#81FFBB"></circle>
      </g>
      <defs>
        <clipPath id="clip0_439_13730">
          <path fill="#fff" d="M0 0H14V14H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
