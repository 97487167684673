import React from "react";
import { FeedItem } from "@knocklabs/client";
import { Route } from "lib/routes";
import { UncategorizedTransactionsData } from "../shared";
import { MiniPane } from "./MiniPane";
import { formatMoney } from "@puzzle/utils";

export const UncategorizedTransactionsPane = ({
  item,
}: {
  item: FeedItem<UncategorizedTransactionsData>;
}) => {
  const data = item.data!;
  return (
    <MiniPane
      item={item}
      title="Uncategorized transactions"
      data={[
        {
          label: "Number of transactions",
          value: data.transactionsCount,
        },
        {
          label: "Uncategorized money in",
          value: formatMoney({
            amount: data.moneyIn,
          }),
        },
        {
          label: "Uncategorized money out",
          value: formatMoney({
            amount: data.moneyOut,
          }),
        },
      ]}
      headerButtonOptions={{
        label: "View transactions",
        href: `${Route.transactions}?status=uncategorized&start=${data.start}&end=${data.end}&assignedToMe=true&sortBy=ABS_AMOUNT_DESC`,
      }}
    />
  );
};
