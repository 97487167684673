import { useSyncDataFromMutation } from "graphql/types";
import { UseSyncResult } from "./types";

export default function useSyncData(): UseSyncResult {
  const [syncDataMutation, { data, loading: syncing }] = useSyncDataFromMutation();

  const syncData = async (input: { integrationConnectionId: string }) => {
    return syncDataMutation({ variables: { input } });
  };

  return {
    syncData,
    syncing,
    connection: data?.syncDataFrom.connection,
  };
}
