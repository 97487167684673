import React from "react";

// I guess this is different from components/icons/CreditCard?
// Can they be consolidated or should this have a different name?
function CreditCard({ fill = "currentColor" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" fill="none" viewBox="0 0 14 12">
      <path
        fill={fill}
        d="M13.995 5v6.334a.667.667 0 01-.667.666H.676a.667.667 0 01-.666-.666V5h13.985zm0-2.995H.01V.667A.667.667 0 01.676 0h12.652a.667.667 0 01.667.667v1.338z"
      ></path>
    </svg>
  );
}

export default CreditCard;
