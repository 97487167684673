import * as Types from '../types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
export type PageInfoFragment = { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null, total?: number | null };

export const PageInfoFragmentDoc = gql`
    fragment pageInfo on PageInfo {
  hasNextPage
  endCursor
  total
}
    `;