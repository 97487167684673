import React, { useMemo } from "react";
import { VariantProps } from "@stitches/react";
import * as AvatarPrimitive from "@radix-ui/react-avatar";
import { readableColor } from "polished";

import { colors, CSS, styled } from "@puzzle/theme";

type ColorKey = keyof typeof colors;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Fallback = AvatarPrimitive.Fallback;
const Root = styled(AvatarPrimitive.Root, {
  margin: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "$body",
  fontWeight: "$heavy",
  textTransform: "uppercase",
  borderRadius: "$ellipse",
  userSelect: "none",

  defaultVariants: {
    size: "medium",
  },

  variants: {
    size: {
      mini: {
        width: 20,
        height: 20,
        fontSize: 10,
      },

      small: {
        width: 28,
        height: 28,
        fontSize: "$bodyXS",
      },

      medium: {
        width: 32,
        height: 32,
      },

      // Unused?
      // large: {
      //   width: 45,
      //   height: 45,
      // },
    },
  },
});

type AvatarProps = VariantProps<typeof Root> & {
  user: { email?: string | null; name?: string | null };
  color?: ColorKey;
  textColor?: ColorKey;
  css?: CSS;
};

const avatarColors: ColorKey[] = [
  "purple700",
  "mauve100",
  "mauve400",
  "purple800",
  "red500",
  "red700",
  "greenalpha",
  "purple500",
  "gray500",
];

export const Avatar = ({ user, color, textColor, css, ...props }: AvatarProps) => {
  const { name, email } = user;
  const EMPTY_CHAR = "?";

  const letters = useMemo(() => {
    if (name) {
      const result = name
        .split(" ")
        .slice(0, 2)
        .map((x) => x.charAt(0))
        .join("");

      if (result.length < 2) {
        return name.substr(0, 2);
      }

      return result;
    } else if (email) {
      return email.substr(0, 2);
    }

    return EMPTY_CHAR;
  }, [email, name]);

  const lettersNumber = useMemo(
    () => letters.split("").reduce((num, l) => num + l.charCodeAt(0), 0),
    [letters]
  );
  const colorIndex = lettersNumber % avatarColors.length;
  const currentColor = color ? colors[color] : colors[avatarColors[colorIndex]];

  return (
    <Root
      {...props}
      css={{
        color: textColor ?? readableColor(currentColor, colors.black, colors.gray200),
        backgroundColor: currentColor,
        // TODO configure this per size variant?
        fontSize: letters === EMPTY_CHAR ? "$bodyL" : undefined,
        ...css,
      }}
    >
      {/* We don't use images yet, but we can potentially use Auth0 profile images. */}
      {/* <Image>{letters}</Image> */}

      <Fallback>{letters}</Fallback>
    </Root>
  );
};
