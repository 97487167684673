import React, { useCallback, useMemo } from "react";

import { CalendarView, Modifiers } from "./types";
import { isSelected, mergeModifiers } from "./utils";
import Calendar from "./Calendar";
import { CalendarDate } from "@internationalized/date";

const DatePickerCalendar = ({
  value: selectedDate,
  minDate,
  maxDate,
  modifiers: receivedModifiers,
  onChange,
  view,
  value,
  ...props
}: {
  value?: CalendarDate | null;
  onChange?: (date: CalendarDate) => void;
  minDate?: CalendarDate;
  maxDate?: CalendarDate;
  modifiers?: Modifiers;
  view?: `${CalendarView}`;
}) => {
  const modifiers = useMemo<Modifiers>(
    () =>
      mergeModifiers(
        {
          selected: (date) => isSelected(selectedDate, date, view),
        },
        receivedModifiers
      ),
    [receivedModifiers, selectedDate, view]
  );

  const handleDateChange = useCallback(
    (date: CalendarDate) => {
      onChange?.(date);
    },
    [onChange]
  );

  return (
    <Calendar
      // Needed to reset the current calendar position after externally updating
      initialDate={value ?? undefined}
      view={view || "day"}
      minDate={minDate}
      maxDate={maxDate}
      modifiers={modifiers}
      onCellClick={handleDateChange}
      {...props}
    />
  );
};

export default DatePickerCalendar;
