import Big from "big.js";
import React, { useEffect, useMemo } from "react";
import { UseFormReturn } from "react-hook-form";

import { DataTable, createColumnHelper, useDataTable } from "@puzzle/ui";
import { formatMoney, parseCalendarMonth } from "@puzzle/utils";

import { useActiveCompany, useCompanyDateFormatter } from "components/companies";

import { ScheduleFormValues } from "../InvoiceForm/types";
import { TableContainer, toScheduleDateRangePayload } from "../shared";
import {
  ContractRevenueScheduleFragment,
  ContractRevenueSchedulePeriodFragment,
  usePreviewContractRevenueScheduleLazyQuery,
} from "../graphql.generated";
import { RevenueSchedulePeriodState } from "graphql/types";
import { formatPeriod } from "components/dashboard/Revenue/shared";

type Props = {
  readOnly: boolean;
  existingSchedule?: ContractRevenueScheduleFragment;
  scheduleForm: UseFormReturn<ScheduleFormValues>;
  open?: boolean;
  amount: string;
  isSimulation?: boolean;
};

export const SchedulePreviewTable = ({
  readOnly,
  existingSchedule,
  scheduleForm,
  open,
  amount,
  isSimulation,
}: Props) => {
  const { company } = useActiveCompany<true>();
  const startDate = scheduleForm.watch(`startDate`);
  const serviceDuration = scheduleForm.watch(`serviceDuration`);
  const schedulePreviewColumnHelper = createColumnHelper<ContractRevenueSchedulePeriodFragment>();

  const dateRange = useMemo(() => {
    if (!startDate) {
      return {
        fromInclusive: "",
        toInclusive: "",
      };
    }

    return toScheduleDateRangePayload({ startDate, serviceDuration });
  }, [serviceDuration, startDate]);

  const [_getPreview, { data: previewData, loading: previewLoading }] =
    usePreviewContractRevenueScheduleLazyQuery();

  const dateFormatter = useCompanyDateFormatter({
    month: "short",
    year: "2-digit",
  });

  const tableData = useMemo(() => {
    return readOnly
      ? existingSchedule?.periods ?? []
      : scheduleForm.formState.isDirty || isSimulation
      ? previewData?.previewContractRevenueSchedule.periods ?? []
      : existingSchedule?.periods ?? [];
  }, [
    readOnly,
    isSimulation,
    scheduleForm.formState,
    existingSchedule?.periods,
    previewData?.previewContractRevenueSchedule.periods,
  ]);

  const previewColumns = useMemo(() => {
    return [
      schedulePreviewColumnHelper.accessor("period", {
        header: "Recognition period",
        cell: ({ getValue }) => {
          return formatPeriod(parseCalendarMonth(getValue()), dateFormatter);
        },
      }),
      schedulePreviewColumnHelper.accessor("revenue.amount", {
        header: "Revenue recognized",
        meta: {
          align: "right",
        },
        cell: ({ getValue }) => formatMoney({ currency: "USD", amount: Big(getValue()) }),
      }),
      schedulePreviewColumnHelper.accessor("cumulativeRevenue.amount", {
        header: "Cumulative revenue",
        meta: {
          align: "right",
        },
        cell: ({ getValue }) => formatMoney({ currency: "USD", amount: Big(getValue()) }),
      }),
      schedulePreviewColumnHelper.accessor("deferredRevenue.amount", {
        header: "Deferred revenue",
        meta: {
          align: "right",
          css: { paddingRight: "$2" },
        },
        cell: ({ getValue }) => formatMoney({ currency: "USD", amount: Big(getValue()).abs() }),
      }),
    ];
  }, [schedulePreviewColumnHelper, dateFormatter]);

  const schedulePreviewTable = useDataTable({
    data: tableData,
    columns: previewColumns,
  });

  useEffect(() => {
    if (!scheduleForm.formState.isValid || readOnly || !open) return;

    _getPreview({
      variables: {
        input: {
          amount: {
            amount,
            currency: "USD",
          },
          companyId: company.id,
          dateRange,
        },
      },
    });
  }, [_getPreview, company.id, amount, dateRange, readOnly, scheduleForm.formState.isValid, open]);

  return (
    <TableContainer>
      <DataTable.Scrollable css={{ border: "1px solid $rhino600", borderRadius: "4px" }}>
        <DataTable
          css={{
            'tr[aria-disabled="true"] td': {
              backgroundColor: "$mauve900",
              color: "$gray400",
            },
          }}
          hideSpacerRows
          stickyHeaders
          bordered
          density="small"
          table={schedulePreviewTable}
          loading={previewLoading}
          getRowProps={({ original: { state } }) => ({
            "aria-disabled": Boolean(state) && state !== RevenueSchedulePeriodState.Posted,
          })}
        />
      </DataTable.Scrollable>
    </TableContainer>
  );
};
