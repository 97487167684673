// Our db allows dynamic mapping, but.. we've never used that.

import { FeedItem, GenericData } from "@knocklabs/client";
import { AdditionalServices } from "graphql/types";

// Use this when you want to filter a feed by a specific workflow, or apply type-narrowing for the message shape.
export enum KnockWorkflow {
  CreditCardAccuracy = "credit-card-accuracy",
  IntegrationTasks = "integrations-tasks",
  OpeningBalance = "opening-balance",
}

export enum TaskType {
  /**
   * Integration disconnection/outage notifications
   */
  Integration = "integration",

  /**
   * Integration warning notifications - when non-critical data can not be ingested from this integration
   */
  IntegrationWarning = "integration-warning",
  /**
   * Sending notification to assignee when a transaction is assigned
   */
  Transaction = "transaction",
  /**
   * Sending notification to assignee when a transaction is assigned
   *
   * Unlike above, it initiates an ai-assisted categorization workflow
   */
  TransactionAI = "transactionAI",
  /**
   * Sending notification to admins when there are credit card inaccuracies
   */
  CreditCardAccuracy = "credit_card_accuracy",
  /**
   * Sending notification to admins when there's a non-zero opening balance for an integration
   */
  OpeningBalance = "opening-balance",
  /**
   * Sending notification to admins with uncategorized transactions information
   */
  UncategorizedTransactions = "uncategorized_transactions",

  /**
   * Created when a company completes creation, as a replacement for the old checklist against the sidebar.
   */
  CompanyOnboardChecklist = "company-onboard-checklist",

  /**
   * Created when the free trial subscription has 7 days remaining.
   */
  FreeTrialExpiring7days = "freetrial-expiring-7days",

  /**
   * Created when the free trial subscription has expired.
   */
  FreeTrialExpired = "freetrial-expired",

  /**
   * Created when the free trial subscription has been cancelled.
   */
  AdditionalServices = "additional_services",

  /**
   * Created when the monthly spending limit for free access to certain features has been exceeded.
   */
  MonthlySpendingExceeded = "monthly_spending_exceeded",

  /**
   * Created when a user marks that they have historical books to transfer, this item will start the changover process.
   */
  StartIngestionDate = "start_ingestion_date",

  /**
   * Created when a user has connected to rippling and will send reminders to upgrade to a paid plan.
   */
  RipplingAddOnRequired = "rippling-add-on-required",
}

export enum UpdateType {
  /**
   * Notifications sent for any transaction status updates, comments posted etc. to anyone subscribed to that transaction
   */
  TransactionSubscribe = "transaction_subscribe",
  /**
   * Sent when data is imported (currently just transactions?).
   */
  DirectIngestSucceeded = "direct_ingest_succeeded",
  /**
   * Notifications sent when a user is added to a company
   */
  UserAdded = "user_added",
  /**
   * Notifications sent when a user is removed from a company
   */
  UserRemoved = "user_removed",
  /**
   * Created when a user completes the first step of their historical books conversion.
   */
  HistoricalBooksInputComplete = "historical_books_input_complete",
  /**
   * Created Puzzle has completed the second step of a historical books conversion.
   */
  HistoricalBooksImportComplete = "historical_books_import_complete",
  /**
   * Created when a user completes the final step of their historical books conversion.
   */
  HistoricalBooksReviewComplete = "historical_books_review_complete",
  /**
   * Created when a step of the historical books checklist is marked incomplete.
   */
  HistoricalBooksIncomplete = "historical_books_incomplete",
}

export enum CompanyOnboardChecklistTasktype {
  ConnectAccounts = "connect_accounts",
  BookOnboardingCall = "book_onboarding_call",
  InviteTeam = "invite_team",
  CategorizeTopTransactions = "categorize_top_transactions",
  BookkeepingTaxPrep = "bookkeeping_tax_prep",
}

export enum IntegrationStatus {
  Disconnected = "disconnected",
  Outage = "outage",
}

export enum TransactionType {
  Request = "request",
}

/**
 * @deprecated
 */
export interface TaskItemContent {
  messageType: TaskType;

  // Integration-specific metadata
  integrationType?: string;
  integrationStatus?: IntegrationStatus;
  integrationId?: string;

  // Transaction-specific metadata
  transactionId?: string;
  transactionTitle?: string;
  transactionType?: TransactionType;
  transactionText?: string;

  // Opening balance-specific metadata
  accountId?: string;
}

export type IntegrationFeedItemData = {
  integrationType?: string;
  integrationId?: string;
};
export type IntegrationDisconnectedFeedItemData = IntegrationFeedItemData & {
  messageType: TaskType.Integration;
  integrationStatus?: IntegrationStatus;
};
export type IntegrationWarningFeedItemData = IntegrationFeedItemData & {
  messageType: TaskType.IntegrationWarning;
  entityType?: string;
};
export type TransactionFeedItemData = {
  messageType: TaskType.Transaction;
  transactionId?: string;
  transactionTitle?: string;
  transactionType?: TransactionType;
  transactionText?: string;
};
export type OpeningBalanceFeedItemData = {
  messageType: TaskType.OpeningBalance;
  accountId: string;
  accountName: string;
  balanceDate: string;
};
export type UncategorizedTransactionsData = {
  messageType: TaskType.UncategorizedTransactions;
  transactionsCount: number;
  moneyIn: number;
  moneyOut: number;
  start: string;
  end: string;
};
export type UserAddedFeedItemData = {
  messageType: UpdateType.UserAdded;
  userName: string;
  role: string;
  dateAdded: string;
};
export type UserRemovedFeedItemData = {
  messageType: UpdateType.UserRemoved;
  // TODO double check payload
  userName: string;
  role: string;
  dateAdded: string;
  dateRemoved: string;
  lastActivity?: string;
};
export type TransactionSubscribeFeedItemData = {
  messageType: UpdateType.TransactionSubscribe;
  // TODO double check payload
};
export type AdditionalServicesFeedItemData = {
  messageType: TaskType.AdditionalServices;
  service: AdditionalServices;
};

// TODO this is..... empty.
export type DirectIngestFeedItemData = {
  messageType: UpdateType.DirectIngestSucceeded;
  ingestedDataType: "Transaction";
  count: number;
  accountId?: string;
};

export type CompanyOnboardChecklistFeedItemData = {
  messageType: TaskType.CompanyOnboardChecklist;
  taskSubType: CompanyOnboardChecklistTasktype;
};

// TODO FeedItem and item.data.messageType don't work well with type narrowing; maybe should just type the data payloads?
export type InboxFeedItem = FeedItem<
  | GenericData
  | IntegrationDisconnectedFeedItemData
  | IntegrationWarningFeedItemData
  | TransactionFeedItemData
  | OpeningBalanceFeedItemData
  | UserAddedFeedItemData
  | UserRemovedFeedItemData
  | TransactionSubscribeFeedItemData
  | CompanyOnboardChecklistFeedItemData
  | AdditionalServicesFeedItemData
>;

export enum NotificationChannelTypes {
  Email = "email",
  InAppFeed = "in_app_feed",
}

export const TABS = [
  { title: "My tasks", value: "tasks" },
  { title: "Updates", value: "updates" },
  { title: "Archived", value: "archived" },
] as const;

export type InboxFeed = "tasks" | "updates" | "archived";