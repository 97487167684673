import React from "react";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import { styled } from "@puzzle/theme";

const RadioGroupRoot = styled(RadioGroupPrimitive.Root, {
  display: "flex",
  flexDirection: "column",
  gap: "$1",

  variants: {
    direction: {
      horizontal: {
        flexDirection: "row",
      },
    },
  },
});

export const RadioItem = styled(RadioGroupPrimitive.Item, {
  all: "unset",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "$elephant300",
  width: 14,
  height: 14,
  borderRadius: "100%",
  flexShrink: "0",

  '&[data-state="unchecked"]': {},
  '&[data-state="checked"]': {
    borderColor: "#62D89A",
    backgroundColor: "$green600",
  },

  "&:hover": { borderColor: "#6F6F8F" },
  "&:focus": { borderColor: "$green600 !important" },
});

const RadioGroupItem = styled("label", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "$1",
  cursor: "pointer",

  "&:hover": {
    [`${RadioItem}`]: {
      borderColor: "#6F6F8F",
    },
  },

  variants: {
    variant: {
      mobile: {
        borderRadius: "$1",
        border: "1px solid $gray600",
        padding: "$2",
      },
      box: {
        borderRadius: "$1",
        border: "1px solid $gray600",
        padding: "$1h",
      },
    },
  },
});

export const RadioIndicator = styled(RadioGroupPrimitive.Indicator, {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  position: "relative",
  "&::after": {
    content: '""',
    display: "block",
    width: 4,
    height: 4,
    borderRadius: "$ellipse",
    backgroundColor: "$gray50",
    boxShadow: "0px 1px 2px rgba(33, 31, 53, 0.24)",
  },
});

const Label = styled("span", {
  fontSize: "13px",
  lineHeight: "18px",
  color: "$gray400",
  userSelect: "none",
  cursor: "pointer",
});

type Option = { label: string; value: string } | string;

const RadioOption = ({
  option,
  variant,
  checked,
}: {
  option: Option;
  variant?: "mobile" | "box";
  checked?: boolean;
}) => {
  const value = typeof option === "string" ? option : option.value;
  const label = typeof option === "string" ? option : option.label;

  return (
    <RadioGroupItem
      variant={variant}
      css={{ border: variant === "mobile" && checked ? "1px solid $greenalpha" : undefined }}
      key={value}
    >
      <RadioItem value={value}>
        <RadioIndicator />
      </RadioItem>
      <Label>{label}</Label>
    </RadioGroupItem>
  );
};

export const RadioGroup = ({
  options,
  variant,
  direction,
  ...props
}: React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root> & {
  options: Option[];
  variant?: "mobile" | "box";
  direction?: "horizontal";
}) => {
  return (
    <RadioGroupRoot direction={direction} {...props}>
      {options.map((o, i) => (
        <RadioOption
          variant={variant}
          option={o}
          checked={typeof o === "string" ? o === props.value : o.value === props.value}
          key={i}
        />
      ))}
    </RadioGroupRoot>
  );
};
