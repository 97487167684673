import { Meow } from "@puzzle/icons";
import React from "react";
import { IntegrationLogo } from "../shared";

const MeowLogo = ({ circular }: { circular?: boolean }) => {
  return (
    <IntegrationLogo key="meow" color="#FFFFFF" circular={circular}>
      <Meow />
    </IntegrationLogo>
  );
};

export default MeowLogo;
