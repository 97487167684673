import * as Types from '../types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
export type IntegrationConnectionFragment = { __typename?: 'IntegrationConnection', id: string, createdAt: CalendarDateTimeString, type: Types.IntegrationType, ingressType: Types.IngressType, status: Types.IntegrationConnectionStatus, message?: string | null, errorCode?: Types.DisconnectionErrorCode | null, lastSyncedAt?: CalendarDateTimeString | null, condition?: Types.IntegrationConnectionCondition | null, latestSync?: { __typename?: 'SyncRun', id: any, status: Types.SyncRunStatus } | null };

export type IntegrationConnectionWithAccountStatsFragment = { __typename?: 'IntegrationConnection', id: string, createdAt: CalendarDateTimeString, type: Types.IntegrationType, ingressType: Types.IngressType, status: Types.IntegrationConnectionStatus, message?: string | null, errorCode?: Types.DisconnectionErrorCode | null, lastSyncedAt?: CalendarDateTimeString | null, condition?: Types.IntegrationConnectionCondition | null, accountStats: { __typename?: 'DataSourceTypeAccountStats', activeCount: number, deletedCount: number, archivedCount: number, hiddenCount: number }, latestSync?: { __typename?: 'SyncRun', id: any, status: Types.SyncRunStatus } | null };

export const IntegrationConnectionFragmentDoc = gql`
    fragment integrationConnection on IntegrationConnection {
  id
  createdAt
  type
  ingressType
  status
  message
  errorCode
  lastSyncedAt
  latestSync {
    id
    status
  }
  condition
}
    `;
export const IntegrationConnectionWithAccountStatsFragmentDoc = gql`
    fragment integrationConnectionWithAccountStats on IntegrationConnection {
  ...integrationConnection
  accountStats {
    activeCount
    deletedCount
    archivedCount
    hiddenCount
  }
}
    ${IntegrationConnectionFragmentDoc}`;