import React, { useState } from "react";
import IntegrationDetails from "./IntegrationDetails";
import { SiliconValleyBankLogo, useSvbOfx } from "../svb";
import { ConnectSvbOfxAccountModal } from "../setup/modals/ConnectSvbOfxAccountModal";
import { DetailProps } from "./types";

const SvbOfxDetails = ({ companyId, integrationConnectionId, ...props }: DetailProps) => {
  const svbOfx = useSvbOfx({ companyId, integrationConnectionId });
  const [svbOfxModalOpen, setSvbOfxModalOpen] = useState(false);

  return (
    <>
      <IntegrationDetails
        logo={<SiliconValleyBankLogo />}
        title="Silicon Valley Bank"
        connection={svbOfx.connection}
        onDisconnect={svbOfx.disconnect}
        onReconnect={svbOfx.onClickConnect}
        {...props}
      />
      <ConnectSvbOfxAccountModal
        companyId={companyId}
        open={svbOfxModalOpen}
        onOpenChange={setSvbOfxModalOpen}
        isOnboarding={false}
        onConnect={() => {
          setSvbOfxModalOpen(false);
        }}
        onClose={() => {
          setSvbOfxModalOpen(false);
        }}
      />
    </>
  );
};

export default SvbOfxDetails;
