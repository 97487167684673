import React from "react";
import { styled } from "@puzzle/ui";
import Link from "components/common/Link";
import { Route } from "lib/routes";
import { Box } from "ve";

const Card = styled("div", {
  background: "$mauve950",
  border: "1px solid $mauve550",
  borderRadius: "4px",
  maxWidth: 488,
  width: "75%",
  margin: "0 auto",
  marginTop: "100px",
  padding: "20px",
});

const TextBlock = styled("div", {
  marginTop: "20px",
});

const Header = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  borderBottom: "1px solid $mauve550",
  paddingBottom: "$1h",

  fontWeight: "$bold",
  fontSize: "15px",
  lineHeight: "22px",
  alignItems: "center",
  height: "calc($space$3 + $1h)",

  color: "$gray100",
});

export const HistoricalBooksStepCompletePane = ({
  bodyText,
  linkText,
}: {
  bodyText: string;
  linkText: string;
}) => {
  return (
    <Box css={{ height: "100%", width: "100%" }}>
      <Card>
        <Header>Historical books status</Header>
        <TextBlock>{bodyText}</TextBlock>
        <TextBlock>
          <Link css={{ color: "$green600" }} underline href={Route.historicalBooks}>
            {linkText}
          </Link>
        </TextBlock>
      </Card>
    </Box>
  );
};
