import React, { useState } from "react";
import { styled, Button } from "@puzzle/ui";
import TopTransactionsModal from "components/dashboard/Transactions/TopTransactionsModal";
import Analytics from "lib/analytics";
import { UpdateCategoryMetricsLocations } from "components/dashboard/Transactions/hooks/useSingleTransaction";

const Root = styled("div", {
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: "0 $5h",
  justifyContent: "center",
});

const Card = styled("div", {
  background: "$mauve950",
  border: "1px solid $mauve550",
  borderRadius: "4px",
  width: "100%",
  padding: "$2h",
});

const Header = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  borderBottom: "1px solid $mauve550",
  paddingBottom: "$1h",
  marginBottom: "$2",

  fontWeight: "$bold",
  fontSize: "15px",
  lineHeight: "22px",
  alignItems: "center",
  height: "calc($space$3 + $1h)",

  color: "$gray100",
});

const Description = styled("dd", {
  color: "$gray100",
  margin: 0,
});

const Footer = styled("div", {
  marginTop: "$3",
  display: "flex",
  alignItems: "center",
  gap: "$1h",
});

export const OnboardingTopTransactionsPane = () => {
  const [isShowModal, setIsShowModal] = useState(false);

  const showModal = () => {
    setIsShowModal(true);
    Analytics.categorizeTopTransactionsInboxCtaClicked();
  };

  return (
    <Root>
      <Card>
        <Header>Categorize top transactions</Header>

        <Description>
          Categorize top transactions to get more accurate Spending and Burn metrics.
        </Description>

        <Footer>
          <Button size="mini" variant="secondary" onClick={() => showModal()}>
            Categorize Transactions
          </Button>
        </Footer>
      </Card>
      <TopTransactionsModal
        onInteractOutside={(e) => e.preventDefault()}
        disableOpenAnimation
        basic
        open={isShowModal}
        onOpenChange={(open) => {
          setIsShowModal(open);
        }}
        initialStep="Categorize"
        metricsLocation={UpdateCategoryMetricsLocations.InboxOnboardingChecklist}
      />
    </Root>
  );
};
