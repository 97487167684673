import React, { useMemo, useState } from "react";
import Big from "big.js";
import { uniqBy } from "lodash";

import { Button, Stack, Text, styled, IconButton, Tooltip } from "@puzzle/ui";
import { Add, Unmatch } from "@puzzle/icons";
import { parseDate, formatMoney } from "@puzzle/utils";

import { CategoryFragment } from "graphql/types";
import DescriptionList from "components/common/DescriptionList";
import { CategoryModal, CategorySteps } from "components/transactions";
import { useCompanyDateFormatter } from "components/companies";
import Link from "components/common/Link";
import { Route } from "lib/routes";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { DetachTransactionModal } from "components/dashboard/Accounting/Bills/BillForm/DetachTransactionModal";

import { FullTransactionFragment } from "../graphql.generated";
import useSingleTransaction from "../hooks/useSingleTransaction";
import { Box } from "ve";
import { DocumentRoot } from "components/common/DocumentationSection/BaseRow";

const Row = styled("div", {
  display: "flex",
  flex: "1 0 100%",
  gap: "$3",
});

export const MatchBillsSection = React.memo(function MatchBillsSection({
  transaction,
  categories,
  categoriesByPermaKey,
  canEdit,
  onCategoryChange,
}: {
  transaction: FullTransactionFragment;
  categories: CategoryFragment[];
  categoriesByPermaKey?: Record<string, CategoryFragment>;
  canEdit: boolean;
  onCategoryChange: (c: CategoryFragment) => void;
}) {
  const dateFormatter = useCompanyDateFormatter({
    month: "2-digit",
    day: "2-digit",
    year: "2-digit",
  });
  const [showDetachModal, setShowDetachModal] = useState(false);
  const { refetch } = useSingleTransaction(transaction);

  const list = useMemo(
    () => (
      <DescriptionList
        itemGap="1"
        direction="horizontal"
        items={[
          [
            "Bills paid",
            <>
              {transaction.linkedBills.length > 0 ? (
                <Stack gap="0h">
                  {transaction.linkedBills.map((bill) => {
                    const categories = categoriesByPermaKey
                      ? uniqBy(
                          bill.lines.flatMap(({ coaKey }) =>
                            coaKey ? categoriesByPermaKey[coaKey] : []
                          ),
                          ({ coaKey }) => coaKey
                        )
                      : [];

                    const bundleId = transaction.links.find(
                      (link) => link.bill?.id === bill.id
                    )?.bundleId;

                    return (
                      <>
                        <DocumentRoot
                          key={bill.id}
                          css={{
                            gap: "$1",
                            display: "flex",
                            alignItems: "flex-start",
                            padding: "$1h",
                          }}
                        >
                          <Link
                            target="_blank"
                            href={`${Route.bills}/${bill.id}`}
                            css={{ justifyContent: "space-between", width: "100%" }}
                          >
                            <Row>
                              <div>
                                <Text color="gray400">
                                  {dateFormatter.format(parseDate(bill.issueDate))}
                                </Text>
                              </div>
                              <div>{bill.vendor?.name}</div>
                              <Box css={{ marginLeft: "auto" }}>
                                <Text color="$gray300">
                                  {formatMoney({
                                    currency: "USD",
                                    amount: Big(bill.amount.amount).abs(),
                                  })}
                                </Text>
                              </Box>
                            </Row>
                            <Row>
                              <Text color="$gray300" size="bodyXS">
                                {categories.length > 1 ? "Categories" : "Category"}:{" "}
                                {categories.map(({ name }) => name).join(", ")}
                              </Text>
                            </Row>
                          </Link>
                          {isPosthogFeatureFlagEnabled(FeatureFlag.UnlinkBills) && (
                            <Tooltip content="Detach">
                              <IconButton
                                css={{ marginLeft: "auto", marginTop: "-4px" }}
                                onClick={() => setShowDetachModal(true)}
                              >
                                <Unmatch />
                              </IconButton>
                            </Tooltip>
                          )}
                        </DocumentRoot>

                        <DetachTransactionModal
                          billId={bill.id}
                          bundleId={bundleId}
                          open={showDetachModal}
                          onOpenChange={setShowDetachModal}
                          refetch={refetch}
                        />
                      </>
                    );
                  })}
                </Stack>
              ) : (
                <CategoryModal
                  trigger={
                    <Button size="small" variant="minimal" prefix={<Add />} disabled={!canEdit}>
                      Match to a bill
                    </Button>
                  }
                  categories={categories}
                  initialValue={transaction.detail.category}
                  transaction={transaction}
                  initialStep={CategorySteps.MatchToBills}
                  onChange={onCategoryChange}
                />
              )}
            </>,
          ],
        ]}
      />
    ),
    [
      transaction,
      categories,
      canEdit,
      onCategoryChange,
      categoriesByPermaKey,
      dateFormatter,
      showDetachModal,
      refetch,
    ]
  );

  return list;
});
