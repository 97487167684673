import React from "react";
import { IntegrationsListItemBaseProps } from "../List";
import { IntegrationType } from "graphql/types";
import Logo from "./Logo";
import { IntegrationDisplayComponent } from "../shared/types";

interface SetupTrinetListItemProps {
  DisplayComponent: IntegrationDisplayComponent<
    React.PropsWithChildren<IntegrationsListItemBaseProps>
  >;
  companyId: string;
}

export const TITLE = "TriNet";
export const SUBTITLE = "Link employment costs for your financial statements.";

const SetupTrinetListItem = ({ companyId, DisplayComponent }: SetupTrinetListItemProps) => {
  return (
    <>
      <DisplayComponent
        integrationType={IntegrationType.TriNet}
        connectIntegration={() => ({})}
        title={TITLE}
        subtitle={SUBTITLE}
        logo={<Logo />}
      />
    </>
  );
};

export default SetupTrinetListItem;
