import React from "react";
import { Button, Stack, Text, Tooltip, colors } from "@puzzle/ui";
import { Exclamation } from "@puzzle/icons";

type FooterButtonProps = {
  variant: "primary" | "negative";
  label: string;
  description: string;
  onClick: () => void;
  canBeUndone: boolean;
  disabled?: boolean;
  disabledTooltip?: string;
};

const FooterButton = ({
  onClick,
  description,
  label,
  variant,
  canBeUndone,
  disabled,
  disabledTooltip,
}: FooterButtonProps) => {
  const buttonContent = (
    <Stack css={{ flexDirection: "row" }}>
      <Button
        size="small"
        variant="secondary"
        disabled={disabled}
        color={variant}
        onClick={onClick}
        css={{ minWidth: "100px" }}
      >
        {label}
      </Button>
      <Text>
        {description}{" "}
        {!canBeUndone && !disabled && (
          <Tooltip content="This action cannot be undone.">
            <span>
              <Exclamation size={14} fill={colors.gray300} />
            </span>
          </Tooltip>
        )}
      </Text>
    </Stack>
  );

  return disabled && disabledTooltip ? (
    <Tooltip content={disabledTooltip}>{buttonContent}</Tooltip>
  ) : (
    buttonContent
  );
};

export default FooterButton;
