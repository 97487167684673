import React from "react";
import Icon, { IconProps } from "./Icon";

const LockOutline = ({
  className,
  rotate,
  size = 14,
  viewBox = "0 0 14 14",
  color = "currentColor",
  ...props
}: IconProps) => (
  <Icon className={className} rotate={rotate} viewBox={viewBox} size={size} fill={color} {...props}>
    <path
      d="M7 8C6.58579 8 6.25 8.33579 6.25 8.75V10.25C6.25 10.6642 6.58579 11 7 11C7.41421 11 7.75 10.6642 7.75 10.25V8.75C7.75 8.33579 7.41421 8 7 8Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4V6H2.7C2.3134 6 2 6.3134 2 6.7V12.3C2 12.6866 2.3134 13 2.7 13H11.3C11.6866 13 12 12.6866 12 12.3V6.7C12 6.3134 11.6866 6 11.3 6H10V4C10 3.20435 9.68393 2.44129 9.12132 1.87868C8.55871 1.31607 7.79565 1 7 1C6.20435 1 5.44129 1.31607 4.87868 1.87868C4.31607 2.44129 4 3.20435 4 4ZM7 2C6.46957 2 5.96086 2.21071 5.58579 2.58579C5.21071 2.96086 5 3.46957 5 4V6H9V4C9 3.46957 8.78929 2.96086 8.41421 2.58579C8.03914 2.21071 7.53043 2 7 2ZM3 7V12H11V7H3Z"
      fill={color}
    />
  </Icon>
);

export default LockOutline;
