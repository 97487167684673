import React from "react";
import IntegrationLogo from "../shared/IntegrationLogo";
import { Gusto } from "@puzzle/icons";

const Logo = ({ circular }: { circular?: boolean }) => {
  return (
    <IntegrationLogo color="#F45D48" circular={circular}>
      <Gusto />
    </IntegrationLogo>
  );
};

export default Logo;
