import React from "react";
import { FeedItem } from "@knocklabs/client";
import { useCompanyDateFormatter } from "components/companies";
import { Route } from "lib/routes";
import { UpdateType, UserAddedFeedItemData, UserRemovedFeedItemData } from "../shared";
import { MiniPane } from "./MiniPane";

export const UserPane = ({
  item,
}: {
  item: FeedItem<UserAddedFeedItemData | UserRemovedFeedItemData>;
}) => {
  const dateFormatter = useCompanyDateFormatter({
    dateStyle: "long",
  });
  const data = item.data!;

  const attributes =
    data.messageType === UpdateType.UserAdded
      ? [
          {
            label: "Name",
            value: data.userName,
          },
          {
            label: "Role",
            value: data.role,
          },
          {
            label: "Date added",
            // backwards compatibility
            value: dateFormatter.format(new Date(data.dateAdded ?? item.inserted_at)),
          },
        ]
      : [
          {
            label: "Name",
            value: data.userName,
          },
          {
            label: "Role",
            value: data.role,
          },
          {
            label: "Date added",
            // backwards compatibility
            value: dateFormatter.format(new Date(data.dateAdded ?? item.inserted_at)),
          },
          {
            label: "Last activity",
            value: data.lastActivity && dateFormatter.format(new Date(data.lastActivity)),
          },
          {
            label: "Date removed",
            value: data.dateRemoved && dateFormatter.format(new Date(data.dateRemoved)),
          },
        ];

  return (
    <MiniPane
      item={item}
      title={data.messageType === UpdateType.UserAdded ? "User added" : "User removed"}
      data={attributes}
      headerButtonOptions={{
        label: "Go to User Management",
        href: Route.usersSettings,
      }}
    />
  );
};
