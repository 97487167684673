import React from "react";
import useSvbOfx from "./useSvbOfxIntegration";
import { IntegrationsListItemBaseProps } from "../List";
import { IntegrationType } from "graphql/types";
import { SiliconValleyBankLogo } from "../bank";
import { SILICON_VALLEY_BANK } from "../plaid/usePlaid";
import { IntegrationDisplayComponent } from "../shared/types";

interface SvbOfxListItemProps {
  DisplayComponent: IntegrationDisplayComponent<
    React.PropsWithChildren<IntegrationsListItemBaseProps>
  >;
  companyId: string;
}

const SvbOfxListItem = ({ DisplayComponent, companyId }: SvbOfxListItemProps) => {
  const { onClickConnect, connection, loading } = useSvbOfx({ companyId });

  return (
    <DisplayComponent
      integrationType={IntegrationType.SvbOfx}
      connectIntegration={onClickConnect}
      title={SILICON_VALLEY_BANK}
      connection={connection}
      logo={<SiliconValleyBankLogo />}
      loading={loading}
    />
  );
};

export default SvbOfxListItem;
