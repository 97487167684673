import * as Types from '../types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
export type Category_GatewayCategory_Fragment = { __typename: 'GatewayCategory', name: string, permaKey: string, examples?: string | null, description?: string | null, coaDisplayId?: string | null, coaKey?: string | null, deprecated?: boolean | null, ruleType?: string | null, displayName?: string | null, displayId?: string | null, shortName?: string | null, companyId?: string | null, id?: string | null };

export type Category_LedgerCategory_Fragment = { __typename: 'LedgerCategory', defaultCashlike: Types.Cashlike, accountType?: Types.LedgerAccountType | null, views?: Array<string | null> | null, name: string, permaKey: string, examples?: string | null, description?: string | null, coaDisplayId?: string | null, coaKey: string, deprecated?: boolean | null, ruleType?: string | null, displayName: string, displayId: string, shortName: string, companyId: string, id?: string | null };

export type CategoryFragment = Category_GatewayCategory_Fragment | Category_LedgerCategory_Fragment;

export const CategoryFragmentDoc = gql`
    fragment category on Category {
  __typename
  name
  permaKey
  examples
  description
  coaDisplayId
  coaKey
  deprecated
  ruleType
  displayName
  displayId
  shortName
  companyId
  id
  ... on LedgerCategory {
    defaultCashlike
    accountType
    views
  }
}
    `;