import React, { useState } from "react";
import { AlertDialog, Text, Tooltip, Button, Stack } from "@puzzle/ui";
import { IntegrationsListItemBaseProps } from "../List";
import { IntegrationType } from "graphql/types";
import { IntegrationDisplayComponent } from "../shared/types";
import Logo from "./Logo";
import { Help, External } from "@puzzle/icons";
import { useLocalStorage } from "react-use";
import { Loader } from "ve/Loader";

interface GustoConnectOptionsListProps {
  DisplayComponent: IntegrationDisplayComponent<
    React.PropsWithChildren<IntegrationsListItemBaseProps>
  >;
  title: string;
  onConfirmConnectExisting: () => void;
  onConfirmProvisionNew: () => void;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  companyId: string;
  initiatedAccountProvision: boolean;
}

interface ProvisionAccountButtonProps {
  onClick?: (e: React.MouseEvent) => void;
  basic?: boolean;
  size?: "small" | "large" | "compact";
  disabled: boolean;
  initiatedAccountProvision: boolean;
}

const ProvisionAccountButton = ({
  onClick,
  disabled,
  size = "compact",
  initiatedAccountProvision,
}: ProvisionAccountButtonProps) => {
  return !initiatedAccountProvision ? (
    <Button
      shape="pill"
      variant="secondary"
      size={size}
      onClick={onClick}
      suffix={!disabled ? <External /> : undefined}
      style={{ minWidth: "auto", whiteSpace: "nowrap", border: "1px solid !important" }}
      disabled={disabled}
    >
      {disabled ? "Initiated" : "Create"}
    </Button>
  ) : (
    <Loader variant="primary" />
  );
};

const ConnectGustoModal = ({
  DisplayComponent,
  title,
  onConfirmConnectExisting,
  onConfirmProvisionNew,
  open: _open,
  onOpenChange: _onOpenChange,
  companyId,
  initiatedAccountProvision,
  ...props
}: GustoConnectOptionsListProps) => {
  const [internalOpen, setInternalOpen] = useState(_open);
  const open = _open ?? internalOpen;
  const onOpenChange = _onOpenChange ?? setInternalOpen;
  const [disabled, setDisabled] = useLocalStorage<boolean>(
    `pz:show-gusto-account-provisioning-button-${companyId}`,
    false
  );

  const preInitiationContent = `This must be initiated from the ${IntegrationType.Gusto} portal.`;
  const postInitiationContent = "Your Gusto account creation has been initiated.";
  const contentStart = disabled ? postInitiationContent : preInitiationContent;

  const provisionAccountSubtitle = (
    <Text>
      {`via ${IntegrationType.Gusto} portal `}
      <Tooltip
        content={`${contentStart} After your ${IntegrationType.Gusto} account is created, you can connect it in Puzzle. Receive your first 3 months of Gusto free.`}
      >
        <span>
          <Help />
        </span>
      </Tooltip>
    </Text>
  );

  return (
    <AlertDialog
      {...props}
      size="small"
      open={open}
      onOpenChange={(open) => {
        onOpenChange(open);
      }}
    >
      <AlertDialog.Title basic showClose>
        {title}
      </AlertDialog.Title>
      <AlertDialog.Body>
        <Stack gap="1">
          <DisplayComponent
            integrationType={IntegrationType.Gusto}
            title={"Connect an existing Gusto account"}
            logo={<Logo />}
            connectIntegration={onConfirmConnectExisting}
          />
          <DisplayComponent
            integrationType={IntegrationType.Gusto}
            title={"Create a new Gusto account"}
            logo={<Logo />}
            subtitle={provisionAccountSubtitle}
            endAdornment={
              <ProvisionAccountButton
                onClick={() => {
                  setDisabled(true);
                  if (!disabled) {
                    onConfirmProvisionNew();
                  }
                }}
                disabled={disabled ?? false}
                initiatedAccountProvision={initiatedAccountProvision}
              />
            }
          />
        </Stack>
      </AlertDialog.Body>
    </AlertDialog>
  );
};

export default ConnectGustoModal;
