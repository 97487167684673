import React from "react";
import { DeelLogo, useDeel } from "../deel";
import IntegrationDetails from "./IntegrationDetails";
import { DetailProps } from "./types";

const DeelDetails = ({ companyId, integrationConnectionId, ...props }: DetailProps) => {
  const deel = useDeel({ companyId, integrationConnectionId });

  return (
    <IntegrationDetails
      logo={<DeelLogo />}
      title="Deel"
      connection={deel.connection}
      onDisconnect={deel.disconnect}
      onReconnect={deel.onClickConnect}
      {...props}
    />
  );
};

export default DeelDetails;
