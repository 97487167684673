import {
  useStripeIntegrationQuery,
  useConnectStripeMutation,
  StripeIntegrationDocument,
  StripeIntegrationQuery,
  IntegrationType,
  FinancialInstitutionsDocument,
  IntegrationConnectionCondition,
} from "graphql/types";
import { useToasts } from "@puzzle/ui";
import { UseStripeResult } from "./types";
import { merge } from "lodash";
import { usePendingConnections } from "../shared/PendingConnectionsProvider";
import { useMemo } from "react";
import useSavePage from "../shared/useSavePage";
import { UseIntegrationHook, useFinancialInstitutions } from "../shared";
import config from "lib/config";
import Analytics from "lib/analytics";

const useStripeIntegration: UseIntegrationHook<UseStripeResult> = ({
  companyId,
  integrationConnectionId,
  onClickConnect: baseOnClickConnect,
}): UseStripeResult => {
  const { toast } = useToasts();
  const { addPendingConnection, removePendingConnection } = usePendingConnections();
  const { data, loading, refetch } = useStripeIntegrationQuery({
    variables: { companyId },
  });
  const { integrations } = data || {};
  const { savePage } = useSavePage();
  const { disconnectIntegrationConnection, integrationConnections } = useFinancialInstitutions();

  const connection = useMemo(() => {
    // TODO: assumes a single connection, should return undefined if integrationConnectionId is undefined
    // should be ok to change after GRO-1862 is in 
    const connectionId = integrationConnectionId ?? integrations?.stripe.connections?.[0]?.id;
    return integrationConnections.find((ic) => ic.id === connectionId);
  }, [integrationConnections, integrationConnectionId, integrations?.stripe.connections]);

  const disconnectIntegration = useMemo(() => {
    const connectionId = connection?.id;
    if (!connectionId) {
      return undefined;
    }

    return async () => {
      const result = disconnectIntegrationConnection(connectionId);

      if (!integrationConnectionId) {
        refetch();
      }

      return result;
    };
  }, [disconnectIntegrationConnection, integrationConnectionId, connection?.id, refetch]);

  const [connectStripeMutation, { loading: connecting, error: connectionError }] =
    useConnectStripeMutation();

  const initializationInfo = data?.integrations.stripe.initializationInfo;

  const onClickConnect = () => {
    baseOnClickConnect && baseOnClickConnect();
    savePage();

    if (!initializationInfo) {
      if (config.IS_LOCAL_DEVELOPMENT) {
        toast({
          message: "useStripe's onClickConnect was called before initialization info was defined",
          status: "error",
        });
      }
      return;
    }

    window.location.href = initializationInfo.connectUrl;
  };

  const connectStripe = async (input: {
    companyId: string;
    code: string;
    state: string;
    condition?: IntegrationConnectionCondition;
  }) => {
    addPendingConnection(IntegrationType.Stripe);

    return connectStripeMutation({
      variables: { input: { ...input } },
      refetchQueries: [
        {
          query: FinancialInstitutionsDocument,
          variables: {
            companyId,
            integrationType: IntegrationType.Stripe,
          },
        },
      ],

      update(cache, { data }) {
        if (!data) return;

        const stripeIntegration = cache.readQuery<StripeIntegrationQuery>({
          query: StripeIntegrationDocument,
          variables: {
            companyId,
          },
        });

        const allConnections = stripeIntegration?.integrations.stripe.connections;
        cache.writeQuery({
          query: StripeIntegrationDocument,
          variables: {
            companyId,
          },
          data: merge({}, stripeIntegration, {
            integrations: {
              stripe: {
                connections: allConnections
                  ? [...allConnections, data.connectStripe.connection]
                  : [],
              },
            },
          }),
        });
      },

      onCompleted(data) {
        const { connection } = data.connectStripe;
        removePendingConnection(IntegrationType.Stripe);

        Analytics.integrationConnected({
          connectionId: connection.id,
          integrationType: "Stripe",
          totalAccounts: 1,
        });
      },

      onError({ message }) {
        Analytics.integrationConnectionFailed({
          integrationType: "Stripe",
          reason: message,
        });
      },
    });
  };

  return {
    connectionError,
    connectStripe,
    onClickConnect,
    disconnect: disconnectIntegration,
    connection,
    loading,
    connecting,
  };
};

export default useStripeIntegration;
