import React from "react";
import { IntegrationLogo } from "../../shared";
import { RBC } from "@puzzle/icons";

const RBCLogo = () => {
  return (
    <IntegrationLogo color="#005DAA">
      <RBC />
    </IntegrationLogo>
  );
};

export default RBCLogo;
