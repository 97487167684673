import React, { useState } from "react";
import { External } from "@puzzle/icons";
import {
  Field,
  CurrencyInput,
  styled,
  Dialog,
  Input,
  Select,
  Button,
  ButtonGroup,
} from "@puzzle/ui";
import Link from "components/common/Link";
import { useWizardContext } from "components/common/Wizard";
import { Money } from "@puzzle/utils";
import { makeVar } from "@apollo/client";
import Big from "big.js";
import { isEmpty, some } from "lodash";
import { ManualAccountType } from "../types";

const Form = styled("div", {
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  columnGap: "$2",
  rowGap: "$2",
  marginBottom: "$2h",
});

const FormCell = styled("div", {
  minHeight: 40,
});

export interface ManualAccountFormValues {
  institutionName?: string;
  type?: ManualAccountType;
  name?: string;
  mask?: string;
  balance?: Money;
}

export const manualAccountFormValues = makeVar<ManualAccountFormValues>({});
export const manualAccountIdVar = makeVar<string | undefined>(undefined);

const manualAccountOptions = [
  {
    label: ManualAccountType.Checking,
    value: ManualAccountType.Checking,
  },
  {
    label: ManualAccountType.Savings,
    value: ManualAccountType.Savings,
  },
  {
    label: ManualAccountType.CreditCard,
    value: ManualAccountType.CreditCard,
  },
  {
    label: ManualAccountType.Investment,
    value: ManualAccountType.Investment,
  },
];

export const ConnectManualAccountFormStep = () => {
  const { goToPreviousStep, goToNextStep } = useWizardContext();
  const { institutionName, name, type, mask, balance } = manualAccountFormValues();
  const [values, setValues] = useState<ManualAccountFormValues>({
    institutionName,
    name,
    type,
    mask,
    balance,
  });

  return (
    <>
      <Dialog.Title onBack={goToPreviousStep}>
        Add a bank or credit card account manually
      </Dialog.Title>

      <Dialog.Body>
        <Form>
          <FormCell>
            <Field label="Institution name">
              <Input
                size="small"
                placeholder="e.g., Silicon Valley Bank"
                value={values.institutionName}
                onChange={(e: any) => {
                  setValues((values) => ({
                    ...values,
                    institutionName: e.target.value,
                  }));
                }}
              />
            </Field>
          </FormCell>
          <FormCell>
            <Field label="Account type">
              <Select
                placeholder="Select an option"
                options={manualAccountOptions}
                value={values.type}
                onSelectionChange={(type: any) => {
                  setValues((values) => ({ ...values, type }));
                }}
                size="small"
              />
            </Field>
          </FormCell>
          <FormCell>
            <Field
              label="Account name"
              hint='The account name is the label displayed by your financial institution to identify the nature of the account. It can include names such as "Business Savings," "Blue Business Card Plus," "Premium MMA" or "Mercury Checking."'
            >
              <Input
                size="small"
                placeholder="e.g., Innovators Card"
                value={values.name}
                onChange={(e: any) => {
                  setValues((values) => ({
                    ...values,
                    name: e.target.value,
                  }));
                }}
              />
            </Field>
          </FormCell>
          <FormCell>
            <Field
              label="Account number (last 4 digits)"
              hint="The account number is used as a way for you to identify the account within Puzzle and match it to the corresponding third-party account. Typically, this is the last 4 digits of your account number."
            >
              <Input
                size="small"
                placeholder="e.g., 1234"
                value={values.mask}
                onChange={(e) => {
                  setValues((values) => ({
                    ...values,
                    mask: e.target.value,
                  }));
                }}
              />
            </Field>
          </FormCell>
          <FormCell
            css={{
              gridColumnStart: 1,
              gridColumnEnd: 3,
            }}
          >
            <Field
              label="What is the current account balance?"
              hint="The current account balance is the balance presently displayed by your financial institution for this specific account, including all transactions processed (i.e. not pending) up to this point."
            >
              <CurrencyInput
                size="small"
                placeholder="$0"
                value={values.balance?.amount?.toString()}
                onValueChange={(e) => {
                  // TG: I believe e.value="" may be the only problem
                  // but better safe than sorry
                  const parsed = parseFloat(e.value);
                  const value = isNaN(parsed) ? "0" : e.value;

                  setValues((values) => ({
                    ...values,
                    balance: { amount: Big(value), currency: "USD" },
                  }));
                }}
              />
            </Field>
          </FormCell>
        </Form>
        <Link
          href="#"
          target="_blank"
          underline
          external
          css={{ display: "flex", alignItems: "center", fontSize: "$bodyS" }}
        >
          Learn more about adding accounts manually <External style={{ marginLeft: 4 }} />
        </Link>
      </Dialog.Body>
      <Dialog.Footer>
        <ButtonGroup>
          <Dialog.Close asChild>
            <Button variant="secondary">Cancel</Button>
          </Dialog.Close>
          <Button
            variant="primary"
            disabled={some(values, isEmpty)}
            onClick={() => {
              manualAccountFormValues(values);
              goToNextStep();
            }}
          >
            Continue
          </Button>
        </ButtonGroup>
      </Dialog.Footer>
    </>
  );
};
