// TODO: Make this a common component? BulkForm?

import React from "react";
import { styled, ControlGroup, Button, UnstyledButton } from "@puzzle/ui";
import { Add, Close } from "@puzzle/icons";
import { Detail } from "../Icons";
import { formatMoney } from "@puzzle/utils";
import Big from "big.js";

export const Message = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  gap: "$0h",
  marginBottom: "$3",
  fontSize: "$body",
  lineHeight: "$bodyXS",
  color: "$gray400",

  svg: {
    transform: "translateY(2px)",
    alignSelf: "flex-start",
    flexShrink: 0,
  },
});

const DetailButton = styled(UnstyledButton, {
  opacity: 0,
});

export const Row = styled("div", {
  $$rowActionsWidth: "60px",
  display: "grid",
  gridAutoFlow: "column",
  gridTemplateColumns: `minmax(0, 1fr) $$rowActionsWidth`,
  color: "$gray500",

  "&:last-of-type": {
    marginBottom: "$1h",
  },

  "&:hover": {
    [`${DetailButton}`]: {
      opacity: 1,
    },
  },
});

const RowActions = styled("div", {
  display: "flex",
  flex: 0,
  flexDirection: "row",
  alignItems: "center",
  gap: "$1",
  paddingLeft: "$1",
  color: "$gray500",

  "button:hover": {
    color: "$gray300",
  },
});

const Body = styled("div", {
  display: "grid",
  gridGap: "$1h",
});

const Header = styled(Row, {
  fontWeight: "$bold",
  fontSize: "13px",
  lineHeight: "18px",
  color: "$gray200",
});

const HeaderContent = styled("div", {
  "& > *": {
    padding: "0 $1h $1h 0",

    "&:last-child": {
      textAlign: "right",
    },
  },
});

export const Item = ({
  children,
  onRemove,
  onOpen,
  ...props
}: React.PropsWithChildren<{
  onRemove?: () => void;
  onOpen?: () => void;
}>) => {
  return (
    <Row {...props}>
      {children}

      <RowActions>
        {onRemove && (
          <UnstyledButton onClick={onRemove} tabIndex={-1}>
            <Close size={14} fill="currentColor" />
          </UnstyledButton>
        )}

        {onOpen && (
          <DetailButton onClick={onOpen} tabIndex={-1}>
            <Detail />
          </DetailButton>
        )}
      </RowActions>
    </Row>
  );
};

const Root = styled("div", {
  padding: "0 0 $2 0",

  [`${HeaderContent}, ${ControlGroup}`]: {
    display: "grid",
    gridAutoColumns: "minmax(0, 1fr)",
    gridAutoFlow: "column",
  },

  defaultVariants: {
    offsetActions: false,
    hasDetails: false,
  },

  variants: {
    offsetActions: {
      true: {
        [`${Row}`]: {
          marginRight: `-$$rowActionsWidth`,
        },
      },
      false: {},
    },

    hasDetails: {
      true: {},
      false: {},
    },
  },

  compoundVariants: [
    {
      offsetActions: false,
      hasDetails: false,
      css: {
        [`${Row}`]: {
          $$rowActionsWidth: "25px",
        },
      },
    },
  ],
});

const Footer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "$1",
  marginRight: "$6",

  fontSize: "13px",
  lineHeight: "18px",
  color: "$gray300",

  [`${UnstyledButton}`]: {
    padding: "$1 $1h",
  },
});

const FormGroupTable = ({
  children,
  onAdd,
  addText = "Add transaction",
  hideHeader = false,
  hideDate = false,
  isLocked,
  message,
  remainingBalance,
  ...props
}: {
  onAdd?: () => void;
  addText?: React.ReactNode;
  hideHeader?: boolean;
  // or just pass in the columns now i guess
  hideDate?: boolean;
  isLocked?: boolean;
  message?: string;
  remainingBalance?: Big;
} & React.ComponentPropsWithoutRef<typeof Root>) => {
  return (
    <Root {...props}>
      <Message css={{ marginBottom: "$1h" }}>
        {message ?? "Missing a payment, purchase, refund, or fee? Add transactions manually."}
      </Message>

      {!hideHeader && (
        <Header>
          <HeaderContent>
            {!hideDate && <div>Transaction Date</div>}
            <div>Description</div>
            <div>Category</div>
            <div>Amount</div>
          </HeaderContent>
        </Header>
      )}

      <Body>{children}</Body>

      {onAdd && (
        <Footer
          css={{ textVariant: "$bodyS", justifyContent: "space-between", marginRight: "$4h" }}
        >
          <Button
            variant="secondary"
            size="compact"
            onClick={onAdd}
            disabled={isLocked}
            prefix={<Add />}
          >
            {addText}
          </Button>
          {remainingBalance && !hideHeader && (
            <>Remaining balance {formatMoney({ currency: "USD", amount: remainingBalance })}</>
          )}
        </Footer>
      )}
    </Root>
  );
};

FormGroupTable.toString = Root.toString;

export default FormGroupTable;
