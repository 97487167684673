import React, { ComponentProps } from "react";
import { Dialog, styled } from "@puzzle/ui";
import Stack from "components/common/Stack";
import { SecureMessage } from "./common";

export type ConnectModalProps = React.ComponentPropsWithoutRef<typeof Dialog>;

export const ConnectModal = (props: ComponentProps<typeof Dialog>) => (
  <Dialog {...props} size="small" />
);

export const ConnectModalTitle = (props: ComponentProps<typeof Dialog.Title>) => (
  <Dialog.Title {...props} basic showClose />
);

const Body = styled(Dialog.Body, {
  textStyle: "$bodyS",
  color: "$gray400",
});

export const ConnectModalBody = ({ children, ...rest }: ComponentProps<typeof Body>) => (
  <Body {...rest}>
    <Stack>{children}</Stack>
  </Body>
);

export const ConnectModalFooter = ({ children, ...rest }: ComponentProps<typeof Dialog.Footer>) => (
  <Dialog.Footer
    {...rest}
    css={{
      justifyContent: "left",
      fontSize: "$bodyS",
      color: "$gray400",
    }}
  >
    <div>{children}</div>
  </Dialog.Footer>
);

export const ConnectModalContainer = styled("div", {
  display: "flex",
  flexDirection: "column",

  "@l": { gap: "$1" },
});

export const ConnectModalRow = styled("div", {
  display: "grid",
  gridAutoColumns: "1fr",
  gridAutoFlow: "column",

  "@l": { gap: "$1" },
});

export const ConnectModalSecurityMessage = () => <SecureMessage css={{ background: "$gray800" }} />;
