import React, { useEffect } from "react";
import Link from "components/common/Link";
import { Text, styled } from "@puzzle/ui";
import Cal, { getCalApi } from "@calcom/embed-react";
import links from "lib/links";
import useCal from "components/users/useCal";

const Root = styled("div", {
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: "0 $5h",
  justifyContent: "center",
});

const Card = styled("div", {
  background: "$mauve950",
  border: "1px solid $mauve550",
  borderRadius: "4px",
  width: "100%",
  padding: "$2h",
});

const Header = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  borderBottom: "1px solid $mauve550",
  paddingBottom: "$1h",
  marginBottom: "$2",

  fontWeight: "$bold",
  fontSize: "$bodyM",
  lineHeight: "$headingL",
  alignItems: "center",
  height: "calc($space$3 + $1h)",

  color: "$gray100",
});

const Description = styled("dd", {
  color: "$gray100",
  margin: 0,
});

const Footer = styled("div", {
  marginTop: "$3",
  display: "flex",
  alignItems: "center",
  gap: "$1h",
});

export const BookCallPane = () => {
  return (
    <Root>
      <Text variant="heading2" css={{ marginBottom: "$1" }}>
        Book your free onboarding call
      </Text>

      <Text variant="body" color="$gray400" css={{ marginBottom: "$3" }}>
        Optional • Our specialists can help you with any issues
      </Text>

      <BookCallWidget />
    </Root>
  );
};

export const BookCallWidget = () => {
  const { calId } = useCal();
  /* Snippet provided in Cal.com */
  useEffect(() => {
    (async function () {
      const cal = await getCalApi();
      cal("ui", {
        styles: { branding: { brandColor: "#211F35" } },
        hideEventTypeDetails: false,
        layout: "month_view",
      });
    })();
  }, []);

  return (
    <Cal
      calLink={`team/puzzle/puzzle-concierge-onboarding?source=inbox-setup-checklist&userId=${calId}`}
      style={{ width: "100%", height: "100%", overflow: "scroll" }}
      config={{ layout: "month_view" }}
    />
  );
};

export const BookCallCTAPane = () => {
  const { calId } = useCal();
  return (
    <Root>
      <Card>
        <Header>Book your free onboarding call</Header>

        <Description>Optional • Our specialists can help you with any issues</Description>

        <Footer>
          <Link
            target="_blank"
            href={`${links.freeConciergeOnboarding}?source=inbox-book-call-pane&userId=${calId}`}
          >
            Book a call
          </Link>
        </Footer>
      </Card>
    </Root>
  );
};
