import * as Types from '../types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
export type MoneyFragment = { __typename?: 'Money', amount: string, currency: any };

export const MoneyFragmentDoc = gql`
    fragment money on Money {
  amount
  currency
}
    `;