import React from "react";
import Icon, { IconProps } from "./Icon";
import { Colors } from "./colors";

export default function Close({
  viewBox = "0 0 18 18",
  width = 18,
  height = 18,
  color = Colors.BaseGrey,
  fill = color,
  negative = false,
  ...iconProps
}: IconProps) {
  if (negative && fill === Colors.BaseGrey) {
    fill = Colors.Negative;
  }

  return (
    <Icon width={width} height={height} fill="none" viewBox={viewBox} {...iconProps}>
      <path
        fill={fill}
        d="M13.293 3.293a1 1 0 111.414 1.414L10.414 9l4.293 4.293a1 1 0 01-1.414 1.414L9 10.414l-4.293 4.293a1 1 0 01-1.414-1.414L7.586 9 3.293 4.707a1 1 0 011.414-1.414L9 7.586l4.293-4.293z"
      />
    </Icon>
  );
}
