import { merge } from "lodash";
import { useToasts } from "@puzzle/ui";
import {
  useConnectQuickbooksMutation,
  useQuickbooksIntegrationQuery,
  QuickbooksIntegrationDocument,
  QuickbooksIntegrationQuery,
  IntegrationType,
} from "graphql/types";
import useSavePage from "../shared/useSavePage";
import { usePendingConnections } from "../shared/PendingConnectionsProvider";
import { UseIntegrationHook, useFinancialInstitutions } from "../shared";
import { UseQuickbooksResult } from "..";
import config from "lib/config";
import Analytics from "lib/analytics";
import { useMemo, useState } from "react";

export const TITLE = "Quickbooks";
export const SUBTITLE = "Accounting";

const useQuickbooks: UseIntegrationHook<UseQuickbooksResult> = ({
  companyId,
  integrationConnectionId,
  onClickConnect: baseOnClickConnect,
}) => {
  const { toast } = useToasts();
  const { data, loading, refetch } = useQuickbooksIntegrationQuery({
    variables: { companyId },
  });
  const { integrations } = data || {};
  const { addPendingConnection, removePendingConnection } = usePendingConnections();
  const { savePage } = useSavePage();
  const [openInfoModal, setOpenInfoModal] = useState(false);

  const [connectQuickbooksMutation, { loading: connecting, error: connectionError }] =
    useConnectQuickbooksMutation();

  const { disconnectIntegrationConnection, integrationConnections } = useFinancialInstitutions();

  const connection = useMemo(() => {
    const connectionId = integrationConnectionId ?? integrations?.quickbooks.connection?.id;
    return integrationConnections.find((ic) => ic.id === connectionId);
  }, [integrationConnections, integrationConnectionId, integrations]);

  const disconnectIntegration = useMemo(() => {
    const connectionId = connection?.id;
    if (!connectionId) {
      return undefined;
    }

    return async () => {
      const result = disconnectIntegrationConnection(connectionId);

      if (!integrationConnectionId) {
        refetch();
      }

      return result;
    };
  }, [disconnectIntegrationConnection, integrationConnectionId, connection?.id, refetch]);
  const initializationInfo = integrations?.quickbooks.initializationInfo;

  const onClickConnect = () => {
    baseOnClickConnect && baseOnClickConnect();
    savePage();

    if (!initializationInfo || !initializationInfo.connectUrl) {
      if (config.IS_LOCAL_DEVELOPMENT) {
        toast({
          message: "onClickConnect was called before initialization info was defined",
          status: "error",
        });
      }
      return;
    }
    window.location.href = initializationInfo.connectUrl;
  };

  const connectQuickbooks = (input: {
    companyId: string;
    code: string;
    realmId: string;
    state: string;
  }) => {
    addPendingConnection(IntegrationType.QuickBooks);
    return connectQuickbooksMutation({
      variables: { input },

      update(cache, { data }) {
        if (!data) return;

        const quickbooksIntegration = cache.readQuery<QuickbooksIntegrationQuery>({
          query: QuickbooksIntegrationDocument,
          variables: {
            companyId,
          },
        });

        cache.writeQuery({
          query: QuickbooksIntegrationDocument,
          variables: {
            companyId,
          },
          data: merge({}, quickbooksIntegration, {
            integrations: {
              quickbooks: {
                connection: data.connectQuickbooks.connection,
              },
            },
          }),
        });
      },

      onCompleted(data) {
        const { connection } = data.connectQuickbooks;
        removePendingConnection(IntegrationType.QuickBooks);

        Analytics.integrationConnected({
          connectionId: connection.id,
          integrationType: "QuickBooks",
          totalAccounts: 1,
        });
      },

      onError({ message }) {
        Analytics.integrationConnectionFailed({
          integrationType: "QuickBooks",
          reason: message,
        });
      },
    });
  };

  return {
    connectionError,
    connectQuickbooks,
    connecting,
    loading,
    onClickConnect,
    connection,
    disconnect: disconnectIntegration,
    openInfoModal,
    setOpenInfoModal,
  };
};

export default useQuickbooks;
