import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Split({
  viewBox = "0 0 18 18",
  fill = "none",
  color = "currentColor",
  ...iconProps
}: IconProps) {
  return (
    <Icon fill={fill} {...iconProps} viewBox={viewBox}>
      <path
        d="M13 4H9.69258C8.06636 4 6.60397 4.99009 6 6.5V6.5C5.39603 8.00991 3.93365 9 2.30742 9H2"
        stroke={color}
        strokeLinecap="round"
      />
      <path
        d="M13 14H9.58312C8.0619 14 6.73279 12.9723 6.35 11.5V11.5C5.96721 10.0277 4.6381 9 3.11688 9H3"
        stroke={color}
        strokeLinecap="round"
      />
      <path
        d="M12.3536 1.64645C12.1583 1.45118 11.8417 1.45118 11.6464 1.64645C11.4512 1.84171 11.4512 2.15829 11.6464 2.35355L12.3536 1.64645ZM14 4L14.3536 4.35355L14.7071 4L14.3536 3.64645L14 4ZM11.6464 5.64645C11.4512 5.84171 11.4512 6.15829 11.6464 6.35355C11.8417 6.54882 12.1583 6.54882 12.3536 6.35355L11.6464 5.64645ZM11.6464 2.35355L13.6464 4.35355L14.3536 3.64645L12.3536 1.64645L11.6464 2.35355ZM13.6464 3.64645L11.6464 5.64645L12.3536 6.35355L14.3536 4.35355L13.6464 3.64645Z"
        fill={color}
      />
      <path
        d="M12.3536 11.6464C12.1583 11.4512 11.8417 11.4512 11.6464 11.6464C11.4512 11.8417 11.4512 12.1583 11.6464 12.3536L12.3536 11.6464ZM14 14L14.3536 14.3536L14.7071 14L14.3536 13.6464L14 14ZM11.6464 15.6464C11.4512 15.8417 11.4512 16.1583 11.6464 16.3536C11.8417 16.5488 12.1583 16.5488 12.3536 16.3536L11.6464 15.6464ZM11.6464 12.3536L13.6464 14.3536L14.3536 13.6464L12.3536 11.6464L11.6464 12.3536ZM13.6464 13.6464L11.6464 15.6464L12.3536 16.3536L14.3536 14.3536L13.6464 13.6464Z"
        fill={color}
      />
    </Icon>
  );
}
