import { useEffect } from "react";
import { FieldValues, UseFormWatch } from "react-hook-form";
import { ValueOf } from "type-fest";

type Args<T extends FieldValues> = {
  entity: string;
  setValue: (field: keyof T, value: ValueOf<T>) => void;
  watch: UseFormWatch<T>;
  storage?: Storage;
  exclude?: string[];
  skip?: boolean;
};

function getKey(entity: string) {
  return `pz:${entity}-form-persist`;
}

export function useFormPersist<T extends FieldValues>({
  entity,
  watch,
  setValue,
  storage = window.sessionStorage,
  exclude = [],
  skip = false,
}: Args<T>) {
  const key = getKey(entity);

  /**
   * Restores the form state from the previously saved values in storage.
   */
  useEffect(() => {
    if (skip) return;

    const storedValues = storage.getItem(key);

    if (storedValues) {
      const values = JSON.parse(storedValues);

      Object.keys(values).forEach((key) => {
        if (!exclude.includes(key)) {
          setValue(key, values[key]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storage, key, setValue]);

  /**
   * Sets new value in storage when form values change.
   */
  useEffect(() => {
    if (skip) return;

    const subscription = watch((watchedValues) => {
      const values = exclude.length
        ? Object.entries(watchedValues)
            .filter(([key]) => !exclude.includes(key))
            .reduce((obj, [key, val]) => ({ ...obj, [key]: val }), {})
        : { ...watchedValues };

      if (Object.entries(values).length) {
        storage.setItem(key, JSON.stringify(values));
      }
    });

    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return {
    clearPersistedForm: () => storage.removeItem(key),
  };
}
