import React from "react";
import useRamp from "../ramp/useRamp";
import Logo from "../ramp/RampLogo";
import IntegrationDetails from "./IntegrationDetails";
import { DetailProps } from "./types";

const RampDetails = ({ companyId, integrationConnectionId, ...props }: DetailProps) => {
  const ramp = useRamp({ companyId, integrationConnectionId });

  return (
    <IntegrationDetails
      logo={<Logo />}
      title="Ramp"
      connection={ramp.connection}
      onDisconnect={ramp.disconnect}
      onReconnect={ramp.onClickConnect}
      {...props}
    />
  );
};

export default RampDetails;
