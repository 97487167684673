import * as Types from '../../../../graphql/types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountTransactionDetailQueryVariables = Types.Exact<{
  input: Types.AccountTransactionDetailInput;
}>;


export type AccountTransactionDetailQuery = { __typename?: 'Query', accountTransactionDetail: { __typename?: 'AccountTransactionDetails', accountTransactionDetails: Array<{ __typename?: 'AccountTransactionDetail', id: string, createdAt?: CalendarDateTimeString | null, effectiveAt?: CalendarDateTimeString | null, postedAt?: CalendarDateTimeString | null, updatedAt?: CalendarDateTimeString | null, ledger?: Types.LedgerType | null, view?: Types.View | null, companyId: string, entityId?: string | null, eventId: string, type?: string | null, amount?: string | null, currency?: string | null, description?: string | null, accountId?: string | null, displayName?: string | null, displayId?: string | null, tags?: Array<string> | null, reversedEntryId?: string | null, correctionEventId?: string | null, departmentId?: string | null, productId?: string | null, counterpartyId?: string | null, counterpartyType?: string | null, transaction?: { __typename?: 'Transaction', descriptor: string } | null, journal: { __typename?: 'LedgerJournal', id: string, description?: string | null } }> } };

export type AccountTransactionDetailForCsvQueryVariables = Types.Exact<{
  input: Types.AccountTransactionDetailInput;
}>;


export type AccountTransactionDetailForCsvQuery = { __typename?: 'Query', accountTransactionDetailForCSV: { __typename?: 'FileDownloadInfo', signedUrl: string } };

export type AccountTransactionDetailFragment = { __typename?: 'AccountTransactionDetail', id: string, createdAt?: CalendarDateTimeString | null, effectiveAt?: CalendarDateTimeString | null, postedAt?: CalendarDateTimeString | null, updatedAt?: CalendarDateTimeString | null, ledger?: Types.LedgerType | null, view?: Types.View | null, companyId: string, entityId?: string | null, eventId: string, type?: string | null, amount?: string | null, currency?: string | null, description?: string | null, accountId?: string | null, displayName?: string | null, displayId?: string | null, tags?: Array<string> | null, reversedEntryId?: string | null, correctionEventId?: string | null, departmentId?: string | null, productId?: string | null, counterpartyId?: string | null, counterpartyType?: string | null, transaction?: { __typename?: 'Transaction', descriptor: string } | null, journal: { __typename?: 'LedgerJournal', id: string, description?: string | null } };

export type AccountTransactionDetailJournalFragment = { __typename?: 'LedgerJournal', id: string, description?: string | null, effectiveAt: CalendarDateTimeString, postedAt: CalendarDateTimeString, shortId: string, reversedJournal?: { __typename?: 'LedgerJournal', id: string, shortId: string } | null, event: { __typename?: 'LedgerEvent', externalId: string, shortExternalId: string, type?: string | null, transaction?: { __typename?: 'Transaction', id: string, descriptor: string } | null, bill?: { __typename?: 'Bill', id: string, description?: string | null } | null, fixedAsset?: { __typename?: 'FixedAsset', id: string, description?: string | null } | null, invoice?: { __typename?: 'Invoice', id: any, description?: string | null } | null }, entries: Array<{ __typename?: 'LedgerEntry', id: string, accountId: string, amount?: string | null }> };

export type AccountTransactionDetailJournalEntryFragment = { __typename?: 'LedgerEntry', id: string, accountId: string, amount?: string | null };

export type GetCompanyJournalQueryVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
  journalId: Types.Scalars['ID'];
}>;


export type GetCompanyJournalQuery = { __typename?: 'Query', company?: { __typename?: 'Company', id: string, journal: { __typename?: 'LedgerJournal', id: string, description?: string | null, effectiveAt: CalendarDateTimeString, postedAt: CalendarDateTimeString, shortId: string, reversedJournal?: { __typename?: 'LedgerJournal', id: string, shortId: string } | null, event: { __typename?: 'LedgerEvent', externalId: string, shortExternalId: string, type?: string | null, transaction?: { __typename?: 'Transaction', id: string, descriptor: string } | null, bill?: { __typename?: 'Bill', id: string, description?: string | null } | null, fixedAsset?: { __typename?: 'FixedAsset', id: string, description?: string | null } | null, invoice?: { __typename?: 'Invoice', id: any, description?: string | null } | null }, entries: Array<{ __typename?: 'LedgerEntry', id: string, accountId: string, amount?: string | null }> } } | null };

export const AccountTransactionDetailFragmentDoc = gql`
    fragment accountTransactionDetail on AccountTransactionDetail {
  id
  createdAt
  effectiveAt
  postedAt
  updatedAt
  ledger
  view
  companyId
  entityId
  eventId
  type
  amount
  currency
  description
  accountId
  displayName
  displayId
  tags
  reversedEntryId
  correctionEventId
  departmentId
  productId
  counterpartyId
  counterpartyType
  transaction {
    descriptor
  }
  journal {
    id
    description
  }
}
    `;
export const AccountTransactionDetailJournalEntryFragmentDoc = gql`
    fragment accountTransactionDetailJournalEntry on LedgerEntry {
  id
  accountId
  amount
}
    `;
export const AccountTransactionDetailJournalFragmentDoc = gql`
    fragment accountTransactionDetailJournal on LedgerJournal {
  id
  description
  effectiveAt
  postedAt
  shortId @client
  reversedJournal {
    id
    shortId @client
  }
  event {
    externalId
    shortExternalId @client
    type
    transaction {
      id
      descriptor
    }
    bill {
      id
      description
    }
    fixedAsset {
      id
      description
    }
    invoice {
      id
      description
    }
  }
  entries {
    ...accountTransactionDetailJournalEntry
  }
}
    ${AccountTransactionDetailJournalEntryFragmentDoc}`;
export const AccountTransactionDetailDocument = gql`
    query accountTransactionDetail($input: AccountTransactionDetailInput!) {
  accountTransactionDetail(input: $input) {
    accountTransactionDetails {
      ...accountTransactionDetail
    }
  }
}
    ${AccountTransactionDetailFragmentDoc}`;

/**
 * __useAccountTransactionDetailQuery__
 *
 * To run a query within a React component, call `useAccountTransactionDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountTransactionDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountTransactionDetailQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccountTransactionDetailQuery(baseOptions: Apollo.QueryHookOptions<AccountTransactionDetailQuery, AccountTransactionDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountTransactionDetailQuery, AccountTransactionDetailQueryVariables>(AccountTransactionDetailDocument, options);
      }
export function useAccountTransactionDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountTransactionDetailQuery, AccountTransactionDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountTransactionDetailQuery, AccountTransactionDetailQueryVariables>(AccountTransactionDetailDocument, options);
        }
export type AccountTransactionDetailQueryHookResult = ReturnType<typeof useAccountTransactionDetailQuery>;
export type AccountTransactionDetailLazyQueryHookResult = ReturnType<typeof useAccountTransactionDetailLazyQuery>;
export type AccountTransactionDetailQueryResult = Apollo.QueryResult<AccountTransactionDetailQuery, AccountTransactionDetailQueryVariables>;
export const AccountTransactionDetailForCsvDocument = gql`
    query accountTransactionDetailForCSV($input: AccountTransactionDetailInput!) {
  accountTransactionDetailForCSV(input: $input) {
    signedUrl
  }
}
    `;

/**
 * __useAccountTransactionDetailForCsvQuery__
 *
 * To run a query within a React component, call `useAccountTransactionDetailForCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountTransactionDetailForCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountTransactionDetailForCsvQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccountTransactionDetailForCsvQuery(baseOptions: Apollo.QueryHookOptions<AccountTransactionDetailForCsvQuery, AccountTransactionDetailForCsvQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountTransactionDetailForCsvQuery, AccountTransactionDetailForCsvQueryVariables>(AccountTransactionDetailForCsvDocument, options);
      }
export function useAccountTransactionDetailForCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountTransactionDetailForCsvQuery, AccountTransactionDetailForCsvQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountTransactionDetailForCsvQuery, AccountTransactionDetailForCsvQueryVariables>(AccountTransactionDetailForCsvDocument, options);
        }
export type AccountTransactionDetailForCsvQueryHookResult = ReturnType<typeof useAccountTransactionDetailForCsvQuery>;
export type AccountTransactionDetailForCsvLazyQueryHookResult = ReturnType<typeof useAccountTransactionDetailForCsvLazyQuery>;
export type AccountTransactionDetailForCsvQueryResult = Apollo.QueryResult<AccountTransactionDetailForCsvQuery, AccountTransactionDetailForCsvQueryVariables>;
export const GetCompanyJournalDocument = gql`
    query getCompanyJournal($companyId: ID!, $journalId: ID!) {
  company(id: $companyId) {
    id
    journal(id: $journalId) {
      ...accountTransactionDetailJournal
    }
  }
}
    ${AccountTransactionDetailJournalFragmentDoc}`;

/**
 * __useGetCompanyJournalQuery__
 *
 * To run a query within a React component, call `useGetCompanyJournalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyJournalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyJournalQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      journalId: // value for 'journalId'
 *   },
 * });
 */
export function useGetCompanyJournalQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyJournalQuery, GetCompanyJournalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyJournalQuery, GetCompanyJournalQueryVariables>(GetCompanyJournalDocument, options);
      }
export function useGetCompanyJournalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyJournalQuery, GetCompanyJournalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyJournalQuery, GetCompanyJournalQueryVariables>(GetCompanyJournalDocument, options);
        }
export type GetCompanyJournalQueryHookResult = ReturnType<typeof useGetCompanyJournalQuery>;
export type GetCompanyJournalLazyQueryHookResult = ReturnType<typeof useGetCompanyJournalLazyQuery>;
export type GetCompanyJournalQueryResult = Apollo.QueryResult<GetCompanyJournalQuery, GetCompanyJournalQueryVariables>;