import React, { useMemo } from "react";
import { IntegrationConnectionStatus, IntegrationType } from "graphql/types";
import { usePendingConnections } from "../shared/PendingConnectionsProvider";
import { UniversalProps } from "../../common/utils/types";
import { Check, External, SemiConfirmed } from "@puzzle/icons";
import { Button, styled } from "@puzzle/ui";
import Analytics from "lib/analytics";
import { Box, color } from "ve";

interface ConnectionStatusButtonProps extends UniversalProps {
  onClick?: (e: React.MouseEvent) => void;
  status?: IntegrationConnectionStatus | null;
  integrationType: IntegrationType;
  basic?: boolean;
  size?: "small" | "large" | "compact";
  requiresExternalSetup?: boolean;
  defaultButtonText?: string;
}

const requiresReconnect = (status?: IntegrationConnectionStatus | null) =>
  status && [IntegrationConnectionStatus.Disconnected].includes(status);

const StatusButton = styled(Button, {
  minWidth: "auto",
  whiteSpace: "nowrap",
  border: "1px solid !important",

  defaultVariants: {
    status: "",
    reconnect: false,
  },
  variants: {
    status: {
      OK: {
        color: "$green600",
      },
      Error: {
        color: "$yellow500",
      },
      Disconnected: {
        color: "$red500",
      },
    },
    reconnect: {
      true: {
        color: "$green700",
        "&:hover": {
          color: "$white",
        },
      },
    },
  },
});

const ConnectionStatusButton = ({
  status: _status,
  integrationType,
  onClick,
  className,
  basic = false,
  size = "compact",
  requiresExternalSetup,
  defaultButtonText,
}: ConnectionStatusButtonProps) => {
  const { isPending } = usePendingConnections();

  const status =
    integrationType && isPending(integrationType) ? IntegrationConnectionStatus.Ok : _status;

  const semiConfirmed = integrationType && integrationType === IntegrationType.Rippling;

  const buttonText = useMemo(() => {
    switch (status) {
      case IntegrationConnectionStatus.Ok:
        return basic ? "Connected" : "Detail";
      case IntegrationConnectionStatus.Error:
        return "Detail";
      case IntegrationConnectionStatus.Disconnected:
        return "Reconnect";
      default:
        return defaultButtonText ? defaultButtonText : "Connect";
    }
  }, [basic, status]);

  if (buttonText === "Connected") {
    return (
      <Box css={{ color: color.green600 }}>
        {semiConfirmed ? <SemiConfirmed /> : <Check fill="currentColor" />}
      </Box>
    );
  }

  return (
    <StatusButton
      shape="pill"
      variant="secondary"
      size={size}
      className={className}
      onClick={async (e) => {
        const eventPromise = Analytics.integrationConnectionStarted({
          integrationType,
        });

        if (integrationType !== "Plaid") {
          // Make sure this fires before navigation away
          await eventPromise;
        }

        onClick?.(e);
      }}
      status={status ?? undefined}
      suffix={requiresExternalSetup ? <External /> : <></>}
      reconnect={requiresReconnect(status) ?? false}
    >
      {buttonText}
    </StatusButton>
  );
};

export default ConnectionStatusButton;
