import React, { useMemo } from "react";
import { CSSProps, Text, Tooltip, TooltipProps } from "@puzzle/ui";
import { IntegrationType } from "graphql/types";

export const MANUAL_INTEGRATION_TYPES = [IntegrationType.DirectIngest, IntegrationType.Human];

type AsteriskTooltipProps = TooltipProps & CSSProps;
export const AsteriskTooltip = ({ css = {}, side = "bottom", ...props }: AsteriskTooltipProps) => {
  return (
    <span style={{ position: "relative" }}>
      <Tooltip {...props} side={side} arrow>
        <Text
          css={{
            cursor: "pointer",
            position: "absolute",
            top: "-$1",
            right: "-$0h",
            transform: "translateX(100%)",
            ...css,
          }}
          variant="bodyXS"
        >
          &#10043;
        </Text>
      </Tooltip>
    </span>
  );
};

export const ManualTransactionTooltip = ({
  integrationType,
  ...props
}: AsteriskTooltipProps & {
  integrationType: IntegrationType;
}) => {
  const isVisible = useMemo(
    () => MANUAL_INTEGRATION_TYPES.includes(integrationType),
    [integrationType]
  );

  return isVisible ? (
    <AsteriskTooltip content="This transaction was created manually." {...props} />
  ) : null;
};

export const AvailableDateTooltip = (props: AsteriskTooltipProps) => {
  return <AsteriskTooltip content="Available date" {...props} />;
};
