import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Download({
  viewBox = "0 0 16 16",
  width = 16,
  height = 16,
  fill = "none",
  color = "currentColor",
  stroke = color,
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} fill={fill} {...iconProps} viewBox={viewBox}>
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M14 9.5v2.667a1.334 1.334 0 01-1.333 1.333H3.333A1.334 1.334 0 012 12.167V9.5"
      ></path>
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M4.667 6.667L8 10l3.333-3.333M8 10V2.5"
      ></path>
    </Icon>
  );
}
