import React from "react";
import Icon, { IconProps } from "../Icon";

export default function ChaseAlt({
  viewBox = "0 0 30 30",
  width = 48,
  height = 48,
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} {...iconProps} viewBox={viewBox}>
      <g clipPath="url(#clip0_404_5574)">
        <g transform="scale(0.651 0.651) translate(8,8)">
          <g fill="#126BC5">
            <path d="M11.234.6c-.604 0-.964.49-.964.965v7.142h18.878L20.55.6h-9.317z"></path>
            <path d="M29.754 10.882c0-.605-.49-.965-.965-.965h-7.143v18.878l8.108-8.596v-9.317z"></path>
            <path d="M19.472 29.4c.605 0 .965-.49.965-.965v-7.142H1.559l8.596 8.107h9.317z"></path>
            <path d="M.953 19.119c0 .604.49.964.965.964H9.06V1.32L.953 9.917v9.202z"></path>
          </g>
        </g>
      </g>
    </Icon>
  );
}
