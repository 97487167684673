import compact from "lodash/compact";

// TODO: use actual domain types

// TODO Consider where/how to standardize translations for fragments?
export const formatAccountName = (account: { name: string; mask?: string | null | undefined }) => {
  return compact([account.name, account.mask]).join(" - ");
};

export const formatAccountNameWithInstitution = (
  account: Parameters<typeof formatAccountName>[0] & {
    financialInstitution: { name: string };
  }
) => {
  return compact([account.financialInstitution.name, account.mask || account.name]).join(" - ");
};

export const formatVerboseAccountNameWithInstitution = (
  account: Parameters<typeof formatAccountName>[0] & {
    financialInstitution: { name: string };
  }
) => {
  return compact([account.financialInstitution.name, account.name, account.mask]).join(" - ");
};

export const formatCardName = (card: { holderName: string; lastFour: string }) => {
  return compact([card.holderName, card.lastFour]).join(" - ");
};

export const formatCardAndBankName = (
  card: { lastFour: string },
  account: {
    financialInstitution: { name: string };
  }
) => {
  return compact([account.financialInstitution.name, card.lastFour]).join(" - ");
};
