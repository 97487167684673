import React from "react";
import Icon, { IconProps } from "./Icon";

export default function ReportIssue({
  viewBox = "0 0 14 14",
  color = "currentColor",
  ...iconProps
}: IconProps) {
  return (
    <Icon {...iconProps} viewBox={viewBox}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M6.584 3.375a2.23 2.23 0 00-2.307-.102l-.773.422v3.791l.054-.03a3.73 3.73 0 013.858.17l.237.159a2.25 2.25 0 002.017.242l.83-.302V3.821l-.317.116a3.75 3.75 0 01-3.362-.404l-.237-.158zm-3.08 5.82l.773-.422a2.23 2.23 0 012.307.102l.237.158a3.75 3.75 0 003.362.404l1.323-.482A.75.75 0 0012 8.251v-5.5a.75.75 0 00-1.006-.705l-1.324.481a2.25 2.25 0 01-2.017-.242l-.237-.158a3.73 3.73 0 00-3.858-.17l-1.164.635a.75.75 0 00-.39.659v9a.75.75 0 001.5 0V9.195z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
}
