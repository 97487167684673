/* eslint-disable react/display-name */
import React, { MouseEventHandler, useMemo } from "react";
import NextLink from "next/link";
import { styled } from "@puzzle/ui";
import * as Stitches from "@stitches/react";
import { colors } from "@puzzle/ui";

const A = styled("a", {
  all: "unset",
  color: "inherit",
  textDecoration: "none",
  cursor: "pointer",
});

const Link = React.forwardRef<
  HTMLAnchorElement,
  Pick<React.ComponentPropsWithoutRef<"a">, "target" | "rel"> & {
    css?: Stitches.CSS;
    color?: keyof typeof colors;
    hoverColor?: keyof typeof colors;
    underline?: boolean;
    external?: boolean;
    href?: string;
    onClick?: MouseEventHandler;

    // TODO Why is this suddenly needed..
    // Maybe forwardRef is unnecessary if you use ComponentProps<>?
    children: React.ReactNode;
  }
>(({ color, hoverColor, css: _css, underline, href, external = false, ...props }, ref) => {
  const css = useMemo(() => {
    const result: Stitches.CSS = {};

    // button with styles removed
    if (!href) {
      // `all: unset` needs to come first?
      result["all"] = "unset";
      result["cursor"] = "pointer";
    }
    if (color) {
      result.color = `$${color}`;
    }
    if (hoverColor) {
      result["&:hover, &:focus"] = {
        color: `$${hoverColor}`,
      };
    }
    if (underline) {
      result.textDecoration = "underline";
    }

    return { ...result, ..._css };
  }, [_css, color, hoverColor, href, underline]);

  if (!href) {
    // onClick without href isn't accessible; use a button
    // TODO blah polymorphic ref types
    // @ts-expect-error ts-ignore
    return <A {...props} css={css} as="button" ref={ref} />;
  }

  if (external) {
    return <A {...props} href={href} css={css} ref={ref} />;
  }

  return (
    <NextLink href={href} passHref legacyBehavior>
      <A {...props} css={css} ref={ref} />
    </NextLink>
  );
});

export default Link;
