import React from "react";
import { Button, Stack, Text, Tooltip, styled } from "@puzzle/ui";
import {
  ButtonPaneProps,
  IconedButtonContent,
} from "components/dashboard/Dashboard/PostOnboardModal/Panes";
import { Help, PaperAirplane, Pointer, Search } from "@puzzle/icons";
import { BookkeeperPlan, TaxPreparerPlan } from "graphql/types";
import { AnalyticsQuestions } from "components/dashboard/Dashboard/PostOnboardModal/analytics";
import Analytics from "lib/analytics";
import Link from "components/common/Link";

export const StickyButton = styled(Button, {
  transition: "all 0.15s ease-in-out",
  width: "100%",
  justifyContent: "flex-start",

  defaultVariants: {
    active: false,
  },
  variants: {
    active: {
      true: {
        borderColor: "transparent",
        boxShadow: "0 0 0 2px $colors$green600",
      },
      false: {
        boxShadow: "0 0 0 0px $colors$green600",
      },
    },
  },
});

export const TaxPreparerQuestion = ({
  onNext,
  activeButtonId,
  feedbackContent,
  location,
}: ButtonPaneProps & {
  activeButtonId?: BookkeeperPlan | TaxPreparerPlan | null;
  feedbackContent?: React.ReactNode;
}) => {
  const handleNext = (response: string) => {
    onNext(response);
    Analytics.onboardingQuestionAnswered({
      location,
      question: AnalyticsQuestions.taxPreparerPlan,
      response,
    });
  };

  return (
    <Stack gap="2h" css={{ padding: "$2h" }}>
      <Text size="headingM" weight="bold" lineHeight="headingM" color="grey200">
        How do you plan to file your taxes?{" "}
        <Tooltip
          side="bottom"
          align="center"
          content={
            <div>
              Tax preparers help with general business compliance and preparing returns, including
              consideration of:
              <ol>
                <li>Franchise taxes</li>
                <li>Corporate income taxes</li>
                <li>Sales and use taxes</li>
                <li>Registration with the necessary jurisdictions</li>
              </ol>
            </div>
          }
        >
          <span>
            <Help />
          </span>
        </Tooltip>
      </Text>
      {!activeButtonId ? (
        <span>
          Want help deciding?{" "}
          <Link data-intercom-id="onboarding-checklist-ask-puzzle-trigger">Ask Puzzle</Link>.
        </span>
      ) : (
        feedbackContent
      )}
      <StickyButton
        variant="secondary"
        active={activeButtonId === TaxPreparerPlan.HasTaxPreparer}
        onClick={() => handleNext(TaxPreparerPlan.HasTaxPreparer)}
        css={{ width: "100%", height: "60px" }}
      >
        <IconedButtonContent icon={<PaperAirplane color="#50FAAB" width={14} />}>
          I have a tax preparer.
        </IconedButtonContent>
      </StickyButton>
      <StickyButton
        variant="secondary"
        active={activeButtonId === TaxPreparerPlan.WantsTaxPreparer}
        onClick={() => handleNext(TaxPreparerPlan.WantsTaxPreparer)}
        css={{ width: "100%", height: "60px" }}
      >
        <IconedButtonContent icon={<Search color="#50FAAB" width={18} />}>
          I want to hire a tax preparer.
        </IconedButtonContent>
      </StickyButton>
      <StickyButton
        variant="secondary"
        active={activeButtonId === TaxPreparerPlan.DoItMyself}
        onClick={() => handleNext(TaxPreparerPlan.DoItMyself)}
        css={{ width: "100%", height: "60px" }}
      >
        <IconedButtonContent icon={<Pointer color="#50FAAB" width={10} />}>
          I plan to do it myself.
        </IconedButtonContent>
      </StickyButton>
    </Stack>
  );
};
