import { AlertDialog } from "@puzzle/ui";
import React from "react";

const DisconnectIntegrationConfirmationModal = ({
  onConfirm,
  ...props
}: {
  onConfirm: () => void;
} & React.ComponentProps<typeof AlertDialog>) => {
  return (
    <AlertDialog {...props} size="small">
      <AlertDialog.Title basic showClose>
        Disconnect integration
      </AlertDialog.Title>
      <AlertDialog.Body>
        Disconnecting disables synchronization. Your historical records would still exist in the
        system, but no new transactions will be added. You can re-connect at any time.
      </AlertDialog.Body>
      <AlertDialog.Footer>
        <AlertDialog.CancelButton>Never mind</AlertDialog.CancelButton>
        <AlertDialog.ConfirmButton onClick={onConfirm} variant="negative">
          Disconnect integration
        </AlertDialog.ConfirmButton>
      </AlertDialog.Footer>
    </AlertDialog>
  );
};

export default DisconnectIntegrationConfirmationModal;
