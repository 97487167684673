import React from "react";
import { containerStyle, spinnerRecipe, SpinnerVariants } from "./recipe.css";

export const Loader = ({
  variant,
  size = 24,
  css,
}: SpinnerVariants & {
  size?: number;
  css?: React.CSSProperties;
}) => {
  const spinnerClass = spinnerRecipe({ variant });

  return (
    <div className={containerStyle} style={css}>
      <div className={spinnerClass} style={{ width: size, height: size }} />
    </div>
  );
};
