import { useMemo } from "react";
import { merge } from "lodash";
import { useToasts } from "@puzzle/ui";
import {
  useConnectBrexMutation,
  useBrexIntegrationQuery,
  BrexIntegrationDocument,
  BrexIntegrationQuery,
  IntegrationType,
  IntegrationConnectionCondition,
  useFinancialInstitutionsQuery,
} from "graphql/types";
import { usePendingConnections } from "../shared/PendingConnectionsProvider";
import { UseBrexResult } from "./types";
import useSavePage from "../shared/useSavePage";
import { UseIntegrationHook, useFinancialInstitutions } from "../shared";
import config from "lib/config";
import Analytics from "lib/analytics";

export const TITLE = "Brex";
export const SUBTITLE = "Credit Card";

const useBrex: UseIntegrationHook<UseBrexResult> = ({
  companyId,
  integrationConnectionId,
  onClickConnect: baseOnClickConnect,
}): UseBrexResult => {
  const { toast } = useToasts();
  const { data, loading, refetch } = useBrexIntegrationQuery({
    variables: { companyId },
  });
  const { integrations } = data || {};
  const { addPendingConnection, removePendingConnection } = usePendingConnections();
  const { savePage } = useSavePage();
  const { disconnectIntegrationConnection, integrationConnections } = useFinancialInstitutions();

  const connection = useMemo(() => {
    const connectionId = integrationConnectionId ?? integrations?.brex.connection?.id;
    return integrationConnections.find((ic) => ic.id === connectionId);
  }, [integrationConnections, integrationConnectionId, integrations]);

  const disconnectIntegration = useMemo(() => {
    const connectionId = connection?.id;
    if (!connectionId) {
      return undefined;
    }

    return async () => {
      const result = disconnectIntegrationConnection(connectionId);

      if (!integrationConnectionId) {
        refetch();
      }

      return result;
    };
  }, [disconnectIntegrationConnection, integrationConnectionId, connection?.id, refetch]);

  const [connectBrexMutation, { loading: connecting, error: connectionError }] =
    useConnectBrexMutation();

  const initializationInfo = integrations?.brex.initializationInfo;

  const onClickConnect = () => {
    baseOnClickConnect && baseOnClickConnect();
    savePage();
    if (!initializationInfo || !initializationInfo.connectUrl) {
      if (config.IS_LOCAL_DEVELOPMENT) {
        toast({
          message: "onClickConnect was called before initialization info was defined",
          status: "error",
        });
      }
      return;
    }
    window.open(initializationInfo.connectUrl, "_blank");
  };

  const connectBrex = async (input: {
    companyId: string;
    code: string;
    state: string;
    condition?: IntegrationConnectionCondition;
  }) => {
    addPendingConnection(IntegrationType.Brex);

    return connectBrexMutation({
      variables: { input },

      update(cache, { data }) {
        if (!data) return;

        const brexIntegration = cache.readQuery<BrexIntegrationQuery>({
          query: BrexIntegrationDocument,
          variables: {
            companyId: companyId,
          },
        });

        cache.writeQuery({
          query: BrexIntegrationDocument,
          variables: {
            companyId,
          },
          data: merge({}, brexIntegration, {
            integrations: {
              __typename: "Integrations",
              brex: {
                __typename: "BrexIntegration",
                connection: {
                  __typename: "IntegrationConnection",
                  ...data.connectBrex.connection,
                },
              },
            },
          }),
        });
      },

      onCompleted(data) {
        removePendingConnection(IntegrationType.Brex);
        const { connection } = data.connectBrex;

        Analytics.integrationConnected({
          connectionId: connection.id,
          integrationType: "Brex",
          totalAccounts: 1,
        });
      },

      onError({ message }) {
        Analytics.integrationConnectionFailed({
          integrationType: "Brex",
          reason: message,
        });
      },
    });
  };

  const { data: fiData } = useFinancialInstitutionsQuery({
    variables: { companyId, integrationType: IntegrationType.Brex },
  });

  const financialInstitutions = fiData?.financialInstitutionsFor ?? [];

  return {
    connectionError,
    connectBrex,
    connecting,
    loading,
    onClickConnect,
    connection,
    disconnect: disconnectIntegration,
    financialInstitutions,
  };
};

export default useBrex;
