import { SubscriptionBillingCycle, Plan } from "../types";
import { formatMoney } from "@puzzle/utils";
import Big from "big.js";
import { ADDONS } from "./plans";

export const actualPrice = (cycle: SubscriptionBillingCycle, plan?: Plan) => {
  if (plan && plan.price && plan.priceYear) {
    const val =
      cycle === SubscriptionBillingCycle.Monthly ? plan.price : Math.round(plan.priceYear / 12);
    return formatMoney({ currency: "USD", amount: val }, { truncateValue: false });
  }
  return null;
};

export const actualPriceAddon = (
  cycle: SubscriptionBillingCycle,
  monthlyPrice?: number,
  yearlyPrice?: number
) => {
  if (monthlyPrice && yearlyPrice) {
    const val =
      cycle === SubscriptionBillingCycle.Monthly ? monthlyPrice : Math.round(yearlyPrice / 12);
    return formatMoney({ currency: "USD", amount: val }, { truncateValue: false });
  }
  return null;
};

/**
 * Joins an array using , and `and`
 * @param array string array
 * @returns array joined with , except the last one.
 */
export const joinWithCommasAnd = (array: string[]) => {
  if (array.length === 0) return "";
  if (array.length === 1) return array[0];
  if (array.length === 2) return `${array[0]} and ${array[1]}`;
  const joinedExceptLast = array.slice(0, length - 1).join(", ");
  const lastItem = array[length - 1];
  return `${joinedExceptLast} and ${lastItem}`;
};

export const computeSubtotal = (
  selectedAddons: string[],
  cycle: SubscriptionBillingCycle,
  plan?: Plan,
  estimateMonthly?: boolean
) => {
  const planPrice = calcPlanPrice(cycle, plan, estimateMonthly);
  const addonPrice = calcAddOnPrice(selectedAddons, cycle, estimateMonthly);
  return planPrice.add(addonPrice).toNumber();
};

export const calcPlanPrice = (
  cycle: SubscriptionBillingCycle,
  plan?: Plan,
  estimateMonthly?: boolean
) => {
  if (plan && plan.price && plan.priceYear) {
    const planPriceYear = new Big(plan.priceYear);
    if (cycle === SubscriptionBillingCycle.Monthly) {
      return new Big(plan.price);
    } else if (estimateMonthly) {
      return planPriceYear.div(12).round(0);
    }
    return planPriceYear;
  }
  return new Big(0);
};

export const calcAddOnPrice = (
  selectedAddons: string[],
  cycle: SubscriptionBillingCycle,
  estimateMonthly?: boolean
) => {
  let total = new Big(0);

  for (let i = 0; i < selectedAddons.length; i++) {
    const selectedAddon = ADDONS.find((a) => a.id === selectedAddons[i]);
    if (selectedAddon) {
      const addonPriceYear = new Big(selectedAddon.priceYear || 0);
      if (cycle === SubscriptionBillingCycle.Monthly) {
        total = total.add(new Big(selectedAddon.price || 0));
      } else if (estimateMonthly) {
        total = total.add(addonPriceYear.div(12).round(0));
      } else {
        total = total.add(addonPriceYear);
      }
    }
  }
  return total;
};
