import React, { useCallback, useMemo } from "react";
import { Controller, useFieldArray, UseFormReturn } from "react-hook-form";
import Link from "next/link";

import { Stack, Text, styled, Field, Select, DateInput, CurrencyInput } from "@puzzle/ui";
import { capitalize, formatMoney, parseDate, toCalendarDate } from "@puzzle/utils";

import { useActiveCompany, useCompanyDateFormatter } from "components/companies";
import { Route } from "lib/routes";
import useCategories from "components/common/hooks/useCategories";

import { ContractRevenueScheduleFragment } from "../graphql.generated";
import { ScheduleFormValues, FormValues, LineField } from "../InvoiceForm/types";

import { SchedulePreviewTable } from "./SchedulePreviewTable";
import {
  InputBox,
  InputGroup,
  calculateServiceDuration,
  toScheduleDateRangePayload,
} from "../shared";

import { Box, color, IconButton, Loader, S, vars } from "ve";
import DescriptionList from "components/common/DescriptionList";
import { compact, startCase } from "lodash";
import {
  AccountingRecognitionCalculation,
  AccountingRecognitionTiming,
  RevenueRecognitionConfigurationConfig,
} from "graphql/types";
import { Add, Delete } from "@puzzle/icons";
import { useRemainingToRecognize } from "./hooks/useRemainingToRecognize";
import { AccountingConfigurationFragment } from "components/dashboard/Accounting/Configurations/graphql.generated";

const durationOptions = Array(99)
  .fill(0)
  .map((_, i) => {
    const duration = (i + 1).toString();
    return {
      value: duration,
      label: duration,
    };
  });

type Props = {
  existingSchedule?: ContractRevenueScheduleFragment;
  parentForm?: UseFormReturn<FormValues>;
  scheduleForm: UseFormReturn<ScheduleFormValues>;
  readOnly: boolean;
  lineField?: LineField;
  open?: boolean;
  lineIndex?: number;
  amount: string;
  configurations?: AccountingConfigurationFragment[];
  configurationsLoading?: boolean;
  configuration?: RevenueRecognitionConfigurationConfig;
};

const StyledStack = styled(Stack, {
  gap: "$3",
  padding: "$3",
  flex: 1,
});

export const ScheduleDrawerBody = ({
  existingSchedule,
  parentForm,
  scheduleForm,
  readOnly,
  open,
  lineField,
  lineIndex = 0,
  amount,
  configurations,
  configurationsLoading,
  configuration,
}: Props) => {
  const serviceDuration = scheduleForm?.watch("serviceDuration");
  const startDate = scheduleForm.watch("startDate");
  const { company, lockedPeriodDate, timeZone } = useActiveCompany<true>();
  const { categoriesByPermaKey } = useCategories();
  const firstBookableDate = lockedPeriodDate ? lockedPeriodDate.add({ days: 1 }) : undefined;
  const parentCustomer = parentForm?.watch("customer.name");
  const parentLineProduct = lineField && parentForm?.watch(`${lineField}.${lineIndex}.product`);
  const parentLineCategory = lineField && parentForm?.watch(`${lineField}.${lineIndex}.category`);
  const dateFormatter = useCompanyDateFormatter({
    month: "2-digit",
    day: "2-digit",
    year: "2-digit",
  });
  const accountingConfigurationId = scheduleForm.watch("accountingConfigurationId");

  const configurationsField = useMemo(() => {
    if (configurationsLoading) return <Loader />;

    if (!configurations || configurations.length <= 0) return null;

    return (
      <Field label="Policy">
        <Controller
          control={scheduleForm.control}
          name="accountingConfigurationId"
          render={({ field }) => {
            return (
              <Select
                options={configurations?.map((policy) => ({
                  value: policy.id,
                  label: policy.name,
                }))}
                value={field.value}
                size="small"
                onSelectionChange={(policyId) => {
                  field.onChange({
                    target: {
                      value: policyId,
                      name: field.name,
                    },
                  });
                }}
              />
            );
          }}
        />
      </Field>
    );
  }, [configurationsLoading, configurations, scheduleForm.control]);

  const manualEntriesFieldArray = useFieldArray({
    control: scheduleForm.control,
    name: "manualEntries",
  });
  const manualEntries = manualEntriesFieldArray.fields;
  const { remove, append } = manualEntriesFieldArray;
  const remainingToRecognize = useRemainingToRecognize({ amount, scheduleForm });

  const addManualEntry = useCallback(() => {
    append({
      amount: "",
      effectiveDate: "",
    });
  }, [append]);

  const isOverTime = configuration?.method === AccountingRecognitionTiming.OverTime;

  const lineCoaKey = existingSchedule?.invoiceLine?.coaKey;
  const discountLineCoaKey =
    !!existingSchedule?.contractLine.invoiceDiscountLines?.length &&
    existingSchedule?.contractLine.invoiceDiscountLines[0].ledgerCategory?.coaKey;
  const coaKey = lineCoaKey || discountLineCoaKey;
  const category = coaKey
    ? categoriesByPermaKey?.[coaKey].displayName ?? "-"
    : parentLineCategory?.name ?? "-";
  const showConfigurationsSection =
    company.features.revenueRecognitionConfigurationsEnabled &&
    !company.features.autoConfirmContractLinesEnabled &&
    configurations &&
    configurations.length > 0;

  return (
    <>
      <StyledStack>
        <InputGroup>
          <InputBox>
            <Field label="Total amount">
              <Text>{formatMoney({ currency: "USD", amount })}</Text>
            </Field>
          </InputBox>
          <InputBox>
            <Field label="Customer">
              <Text>
                {existingSchedule?.contractLine.contract.customer?.name ?? parentCustomer ?? "-"}
              </Text>
            </Field>
          </InputBox>
        </InputGroup>
        <InputGroup>
          <InputBox>
            <Field label="Product / Service">
              <Text>
                {parentLineProduct?.name ?? existingSchedule?.contractLine.description ?? "-"}
              </Text>
            </Field>
          </InputBox>
          <InputBox>
            <Field label="Category">
              <Text>{category}</Text>
            </Field>
          </InputBox>
        </InputGroup>
        <InputGroup>
          <InputBox>
            <Field label={readOnly ? "Duration month(s)" : "End date"}>
              <Text>
                {readOnly ? (
                  <>
                    {existingSchedule
                      ? calculateServiceDuration({
                          endDay: existingSchedule?.endDay,
                          startDay: existingSchedule?.startDay,
                          timeZone,
                        })
                      : serviceDuration}
                  </>
                ) : (
                  <>
                    {startDate && serviceDuration
                      ? dateFormatter.format(
                          parseDate(
                            toScheduleDateRangePayload({ startDate, serviceDuration }).toInclusive
                          )
                        )
                      : "-"}
                  </>
                )}
              </Text>
            </Field>
          </InputBox>
          <InputBox>
            <Field label="Status">
              <Text>{existingSchedule?.status ? capitalize(existingSchedule.status) : "-"}</Text>
            </Field>
          </InputBox>
        </InputGroup>
        {showConfigurationsSection && (
          <Box
            css={{
              borderTop: `1px solid ${color.mauve600}`,
              borderBottom: `1px solid ${color.mauve600}`,
              padding: `${S["3"]} ${S["0"]}`,
            }}
          >
            <Text variant="bodyS" style={{ marginBottom: S["2h"], display: "inline-block" }}>
              Revenue recognition policy
            </Text>
            <InputGroup>
              <InputBox>
                {!existingSchedule && configurationsField}
                {accountingConfigurationId && (
                  <>
                    <Box
                      css={{
                        borderTop: `1px solid ${color.mauve600}`,
                        borderBottom: `1px solid ${color.mauve600}`,
                        padding: `${S["3"]} ${S["0"]}`,
                        marginTop: S["2"],
                      }}
                    >
                      <DescriptionList
                        direction="horizontal"
                        termWidth="175px"
                        items={compact([
                          configuration?.method && [
                            "Recognition method",
                            startCase(configuration.method),
                          ],
                          isOverTime &&
                            configuration?.calculation && [
                              "Calculation method",
                              startCase(configuration.calculation),
                            ],
                          isOverTime && ["Interval", "Monthly"],
                          isOverTime &&
                            configuration?.firstPeriodAllocation && [
                              "First period allocation",
                              startCase(configuration.firstPeriodAllocation),
                            ],
                        ])}
                      />
                    </Box>
                    <Box
                      css={{
                        padding: `${S["3"]} ${S["0"]} ${S["0"]}`,
                      }}
                    >
                      <DescriptionList
                        direction="horizontal"
                        termWidth="175px"
                        items={compact([
                          configuration?.revenueAccount && [
                            "Revenue account",
                            startCase(configuration.revenueAccount),
                          ],
                          isOverTime &&
                            configuration?.revenueDeferralAccount && [
                              "Deferred revenue account",
                              startCase(configuration.revenueDeferralAccount),
                            ],
                          configuration?.discountAccount && [
                            "Discount account",
                            startCase(configuration.discountAccount),
                          ],
                          configuration?.refundAccount && [
                            "Refund account",
                            startCase(configuration.refundAccount),
                          ],
                        ])}
                      />
                    </Box>
                  </>
                )}
                {configuration?.calculation === AccountingRecognitionCalculation.Manual && (
                  <Box
                    css={{
                      marginTop: S["2"],
                      padding: S["2"],
                      borderRadius: vars.radii[2],
                      border: `1px solid ${color.mauve600}`,
                    }}
                  >
                    {manualEntries.map((entry, index) => (
                      <Stack
                        key={entry.id}
                        direction="horizontal"
                        css={{ gap: S["2"], marginBottom: S["2"], alignItems: "center" }}
                      >
                        <Controller
                          control={scheduleForm.control}
                          name={`manualEntries.${index}.effectiveDate`}
                          render={({ field }) => {
                            return (
                              <DateInput
                                size="compact"
                                placeholder="Pick a date"
                                value={field.value ? parseDate(field.value) : undefined}
                                onChange={(value) => {
                                  field.onChange({
                                    target: {
                                      value: value && toCalendarDate(value).toString(),
                                      name: field.name,
                                    },
                                  });
                                }}
                              />
                            );
                          }}
                        />
                        <Controller
                          control={scheduleForm.control}
                          name={`manualEntries.${index}.amount`}
                          render={({ field }) => {
                            return (
                              <CurrencyInput
                                size="compact"
                                placeholder="$0.00"
                                value={field.value}
                                onValueChange={({ value }) => {
                                  field.onChange({
                                    target: {
                                      value: value,
                                      name: field.name,
                                    },
                                  });
                                }}
                              />
                            );
                          }}
                        />
                        <IconButton onClick={() => remove(index)}>
                          <Delete />
                        </IconButton>
                      </Stack>
                    ))}
                    <Stack direction="horizontal" css={{ justifyContent: "space-between" }}>
                      <IconButton onClick={addManualEntry}>
                        <Add />
                      </IconButton>
                      <Text>
                        Remaining:{" "}
                        {formatMoney({
                          amount: remainingToRecognize,
                          currency: "USD",
                        })}
                      </Text>
                    </Stack>
                  </Box>
                )}
              </InputBox>
            </InputGroup>
          </Box>
        )}
        {configuration?.calculation !== AccountingRecognitionCalculation.Manual && (
          <InputGroup>
            <InputBox>
              <Field label="Start date">
                <Controller
                  control={scheduleForm.control}
                  name="startDate"
                  render={({ field }) => {
                    if (readOnly)
                      return (
                        <Text>
                          {existingSchedule?.startDay
                            ? dateFormatter.format(parseDate(existingSchedule.startDay))
                            : ""}
                        </Text>
                      );

                    return (
                      <DateInput
                        size="small"
                        placeholder="Pick a date"
                        value={field.value ? parseDate(field.value) : undefined}
                        onChange={(value) => {
                          field.onChange({
                            target: {
                              value: value && toCalendarDate(value).toString(),
                              name: field.name,
                            },
                          });
                        }}
                        minDate={firstBookableDate}
                        footer={
                          firstBookableDate && (
                            <div>
                              Due to{""}
                              <Link href={Route.lockedPeriod}>locked periods</Link>, you cannot set
                              a start date before{""}
                              {dateFormatter.format(firstBookableDate)}
                            </div>
                          )
                        }
                      />
                    );
                  }}
                />
              </Field>
            </InputBox>
            <InputBox>
              <Field label={readOnly ? "End date" : "Duration month(s)"}>
                <Controller
                  control={scheduleForm.control}
                  name="serviceDuration"
                  render={({ field }) => {
                    if (readOnly) {
                      return (
                        <Text>
                          {existingSchedule?.endDay
                            ? dateFormatter.format(parseDate(existingSchedule.endDay))
                            : ""}
                        </Text>
                      );
                    }

                    return (
                      <Select
                        options={durationOptions}
                        value={field.value}
                        size="small"
                        onSelectionChange={(duration) => {
                          field.onChange(duration);
                        }}
                      />
                    );
                  }}
                />
              </Field>
            </InputBox>
          </InputGroup>
        )}
      </StyledStack>
      {configuration?.calculation !== AccountingRecognitionCalculation.Manual && (
        <StyledStack css={{ overflow: "hidden", flex: "100%", paddingTop: 0 }}>
          {(scheduleForm.formState.isValid || readOnly || existingSchedule) && (
            <SchedulePreviewTable
              readOnly={readOnly}
              scheduleForm={scheduleForm}
              amount={amount}
              existingSchedule={existingSchedule}
              open={open}
            />
          )}
        </StyledStack>
      )}
    </>
  );
};
