export enum PricingFeatures {
  accrual_financials = "accrual financials",
  ask_accountant = "ask an accountant",
  audit_log = "audit log",
  auto_reconciliations = "auto reconciliations",
  auto_rules_categorization = "auto rules & categorization",
  automated_data_ingestion = "automated data ingestion",
  bill_pay_ramp_integration = "bill pay - ramp integration",
  burn_metric_card_bank_burn = "burn metric card - bank burn",
  burn_metric_card_net_burn = "burn metric card - net burn",
  burn_metric_custom_burn = "burn metric card - runway calculator (custom)",
  cash_financials = "cash financials",
  cash_metric_card = "cash metric card",
  collaboration_tools_assignments = "collaboration tools — assignments",
  concierge_cpa_review = "concierge cpa review",
  concierge_onboarding_setup = "concierge onboarding & setup",
  dashboard_chart_net_burn = "dashboard chart - net burn",
  dashboard_chart_spending = "dashboard chart - spending",
  email_support = "email support",
  financial_statement_balance_sheet = "financial statement - balance sheet",
  financial_statement_cash_activity_report = "financial statement - cash activity report",
  financial_statement_pnl = "financial statement - p&l",
  fpa_integrations = "fp&a integrations",
  in_app_chat = "in-app chat",
  inbox = "inbox",
  metric_cards_core = "metric cards - core",
  metric_cards_additional = "metric cards - additional",
  monthly_checklist_basic = "monthly checklist - basic",
  monthly_checklist_smart = "monthly checklist - smart",
  monthly_financial_summary_email = "monthly financial summary email",
  people_insights = "people insights",
  priority_support = "priority support",
  puzzle_ai = "puzzle ai",
  qbo_conversion = "qbo conversion",
  report_1click_tax = "report - 1-click tax package",
  report_ap_aging = "report - ap aging",
  report_financial_statements = "report - financial statements",
  report_general_ledger = "report - general ledger",
  report_payroll = "report - payroll",
  report_vendors_for_1099s = "report - vendors for 1099s",
  revenue_insights_annualized_accrual_revenue = "revenue insights - annualized accrual revenue",
  revenue_insights_annualized_revenue = "revenue insights - annualized revenue",
  revenue_insights_stripe_arr = "revenue insights - stripe arr",
  revenue_recognition = "revenue recognition",
  runway_metric_card = "runway metric card",
  self_serve_onboarding = "self-serve onboarding",
  soc2_typeii_compliant = "soc 2 type ii compliant",
  spend_explorer_vendor_insights = "spend explorer & vendor insights",
  spotlight = "spotlight",
  two_factor_authentication = "two-factor authentication",
  user_permissions = "user permissions",
  weekly_financial_summary_email = "weekly financial summary email",
}
