export enum AnalyticsTriggeredFrom {
  Onboarding = "onboarding",
  PostOnboarding = "post_onboarding",
  leftSideNavBar = "left_side_nav_bar",
  inbox = "inbox",
}

export enum AnalyticsSections {
  UnderstandFinances = "understand_my_companys_finances",
  GetFinancesInOrder = "get_my_finances_in_order",
  PrepareForTaxFiling = "prepare_for_tax_filing",
  BookOnboardingCall = "book_onboarding_call",
  Skip = "skip",
}

export enum AnalyticsFeatures {
  SeeMyFinancialOverview = "see_my_financial_overview",
  UnderstandMySpending = "understand_my_spending",
  IdentifySignificantFinancialChanges = "identify_significant_financial_changes",
  ViewAccountingChecklist = "DIY_view_accounting_checklist",
  InviteMyAccountant = "invite_my_accountant_or_bookkeeper",
  HireAProfessionalAccountant = "hire_an_accountant_or_bookkeeper",
  ViewTaxPrepChecklist = "DIY_view_tax_prep_checklist",
  InviteMyTaxPro = "invite_my_tax_professional",
  HireAProfessionalTaxPreparer = "hire_a_professional_tax_preparer",
  CategorizeTransactions = "categories_transactions",
}

export enum AnalyticsQuestions {
  bookkeepingPlan = "bookkeeping-plan",
  taxPreparerPlan = "tax-preparer-plan",
  position = "position",
}

export enum Positions {
  founderCEO = "founder-ceo",
  financeAccounting = "finance-accounting-member",
  outsourcedProfessional = "outsourced-bookkeeper-tax-professional",
}
