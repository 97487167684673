import React from "react";
import Icon, { IconProps } from "./Icon";

export default function ConnectIcon({
  viewBox = "0 0 14 14",
  width = 14,
  height = 14,
  color = "currentColor",
  fill = color,
}: IconProps) {
  return (
    <Icon width={width} height={height} fill={fill} viewBox={viewBox}>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M9 4.5h-.5a.75.75 0 010-1.5H9a4 4 0 010 8h-.5a.75.75 0 010-1.5H9a2.5 2.5 0 000-5zM5 3h.5a.75.75 0 010 1.5H5a2.5 2.5 0 000 5h.5a.75.75 0 010 1.5H5a4 4 0 110-8zm-.25 3.25a.75.75 0 000 1.5h4.5a.75.75 0 000-1.5h-4.5z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
}
