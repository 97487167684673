import { Rippling } from "@puzzle/icons";
import React from "react";
import { IntegrationLogo } from "../shared";

const RipplingLogo = ({
  circular,
  isPaidAddOn,
}: {
  circular?: boolean;
  isPaidAddOn?: boolean;
}) => {
  return (
    <IntegrationLogo key="rippling" color="#FCB315" circular={circular} isPaidAddOn={isPaidAddOn}>
      <Rippling />
    </IntegrationLogo>
  );
};

export default RipplingLogo;
