import React from "react";
import Icon, { IconProps } from "../Icon";

export default function Central({ size = 24, ...iconProps }: IconProps) {
  return (
    <Icon size={size} {...iconProps} viewBox="0 0 1024 1024">
      <path
        fill="#1A0DAB"
        opacity="1.000000"
        stroke="none"
        d="
M572.000000,1025.000000 
	C381.333344,1025.000000 191.166672,1025.000000 1.000000,1025.000000 
	C1.000000,683.666687 1.000000,342.333344 1.000000,1.000000 
	C342.333344,1.000000 683.666687,1.000000 1025.000000,1.000000 
	C1025.000000,342.333344 1025.000000,683.666687 1025.000000,1025.000000 
	C874.166687,1025.000000 723.333313,1025.000000 572.000000,1025.000000 
M916.527771,723.407288 
	C917.004150,721.824097 917.943359,720.223938 917.885986,718.660339 
	C917.265564,701.747192 917.630981,684.677551 915.262268,668.000793 
	C914.114197,659.917297 915.565247,655.268799 920.862793,649.771484 
	C934.236877,635.893311 937.950867,618.655457 933.379272,600.492371 
	C927.016968,575.214417 907.896973,559.011658 880.781982,555.882751 
	C879.138367,555.693115 877.027039,554.277954 876.230896,552.829712 
	C867.221741,536.440247 858.686646,519.786072 849.480408,503.510620 
	C843.702637,493.296478 837.070374,483.559631 830.632080,473.732758 
	C829.822388,472.496918 827.855408,472.019318 825.862061,470.861359 
	C824.444641,468.818420 822.999023,466.794373 821.613953,464.729706 
	C796.226257,426.886169 767.734619,391.456055 737.017639,357.875641 
	C708.178955,326.348511 678.032837,296.022675 644.984924,268.870789 
	C620.691467,248.911499 595.854492,229.552765 570.502686,210.958588 
	C540.898254,189.245392 509.285553,170.633377 476.355835,154.325363 
	C473.577026,152.949188 472.220093,151.116165 471.832916,147.543701 
	C471.076569,140.565323 470.096405,133.294601 467.419861,126.892906 
	C451.456207,88.711517 400.854218,82.565399 375.366241,113.934509 
	C374.389801,115.136269 372.346771,116.337547 370.946564,116.186539 
	C362.623810,115.288948 354.344055,113.992584 345.705475,112.188568 
	C341.242889,108.912361 336.321503,109.991142 330.586853,110.750114 
	C325.875763,110.703384 321.164673,110.656647 315.535767,110.232086 
	C308.357544,110.905403 301.174011,111.530586 294.009277,112.325493 
	C293.282196,112.406151 292.668488,113.508743 291.473969,114.331421 
	C291.100494,114.348381 290.727051,114.365341 289.534760,114.107407 
	C287.718140,114.777763 285.901489,115.448112 283.538818,116.341927 
	C283.147156,116.359169 282.755524,116.376411 281.609314,116.132675 
	C280.124603,116.843300 278.639862,117.553917 276.769043,118.397491 
	C276.769043,118.397491 276.360840,118.386604 275.606934,118.134506 
	C274.452209,118.826431 273.297485,119.518349 271.786346,120.415741 
	C271.786346,120.415741 271.376434,120.450577 270.732056,120.179337 
	C270.160797,120.467972 269.589569,120.756607 268.543457,121.583176 
	C250.129700,130.228470 235.946930,143.376053 226.435806,161.405975 
	C218.820724,175.841629 214.922104,191.339783 214.095840,207.573380 
	C213.872208,211.967133 212.240997,213.804382 207.869919,213.993408 
	C192.806107,214.644806 178.396210,218.218460 164.815750,224.741211 
	C147.128647,233.236389 133.499969,245.979187 124.313309,263.530609 
	C117.383354,276.770538 113.875641,290.793030 111.918388,305.521118 
	C109.087509,326.822998 111.887444,347.660278 115.731796,368.462219 
	C116.278862,371.422424 115.956245,373.031891 113.514542,374.677643 
	C102.831383,381.878265 95.516052,391.549591 92.141434,404.122833 
	C86.897652,423.660309 90.474754,441.121399 104.143150,456.272156 
	C116.086937,469.511261 131.089157,475.014648 148.712997,473.677063 
	C151.432877,473.470642 153.272034,473.753448 154.678360,476.669922 
	C170.296967,509.060822 189.136765,539.568726 209.638000,569.045227 
	C235.259750,605.884094 263.538574,640.596924 294.054779,673.509155 
	C324.028809,705.836670 355.556763,736.577515 389.539764,764.622009 
	C411.733093,782.936890 434.710663,800.394409 458.198334,817.018982 
	C477.460205,830.652588 497.500702,843.371826 518.099548,854.879150 
	C539.182190,866.656738 561.322205,876.553894 583.120178,887.022583 
	C585.702637,888.262878 586.380127,889.507568 586.600464,892.125122 
	C588.336731,912.751526 598.585571,927.871338 616.885742,937.345947 
	C621.752625,939.865540 627.170654,941.320801 631.685852,943.591003 
	C631.877380,944.248840 632.044128,945.472168 632.263916,945.481750 
	C637.516357,945.711609 642.774658,945.892029 648.031311,945.874207 
	C649.264404,945.870056 650.495056,945.115417 652.311646,944.511658 
	C650.894043,943.809021 650.304810,943.516968 650.154419,942.492737 
	C666.922119,939.140137 679.577026,929.944214 689.526794,915.780273 
	C690.881226,915.597961 692.235596,915.415649 694.441956,915.192688 
	C699.476562,915.258240 704.511169,915.323730 710.463684,915.767212 
	C717.789307,915.065186 725.118713,914.397949 732.432800,913.591309 
	C732.984863,913.530457 733.427551,912.477783 734.473022,911.679077 
	C734.864197,911.658630 735.255310,911.638123 736.465393,911.892639 
	C738.282166,911.221985 740.098877,910.551331 742.461731,909.657776 
	C742.853210,909.640686 743.244751,909.623535 744.390808,909.867371 
	C745.875916,909.157471 747.361023,908.447632 749.231201,907.603516 
	C749.231201,907.603516 749.638916,907.613342 750.392883,907.865112 
	C751.547729,907.173279 752.702576,906.481445 754.213745,905.584229 
	C754.213745,905.584229 754.623596,905.549377 755.268127,905.820618 
	C755.839050,905.531433 756.409973,905.242310 757.456604,904.416382 
	C775.896851,895.787598 790.045105,882.596375 799.570984,864.571472 
	C807.362671,849.827942 811.129333,833.972656 812.031189,817.409241 
	C812.222778,813.890686 813.711487,812.596802 817.154968,812.112976 
	C825.836121,810.893372 834.573120,809.723450 843.078796,807.668884 
	C866.423584,802.029968 884.800781,789.048096 898.748535,769.093811 
	C899.868103,768.503967 901.538513,768.197754 902.013306,767.275818 
	C904.233887,762.963989 905.984924,758.412170 908.162292,754.075928 
	C912.920410,744.599976 916.167236,734.767639 915.998962,723.998901 
	C915.998962,723.998901 916.021301,723.970154 916.527771,723.407288 
M836.556946,269.637115 
	C836.270874,270.153442 835.681763,271.082153 835.742432,271.126709 
	C838.141235,272.889618 850.535767,272.134766 853.384521,270.152008 
	C852.478271,269.845520 851.541016,269.528534 850.994263,268.499451 
	C856.078186,267.238770 861.927185,267.155609 866.077820,264.458557 
	C873.451233,259.667297 880.682190,254.056015 886.393921,247.427353 
	C900.397827,231.175385 901.637024,204.812363 890.370544,186.463852 
	C877.751770,165.912979 853.613892,154.872238 828.197510,162.284485 
	C812.550171,166.847794 801.033386,176.275208 794.456726,191.321564 
	C792.316895,196.217285 790.638611,201.314774 788.403992,205.649689 
	C787.797363,205.451340 787.190735,205.252991 786.584106,205.054642 
	C785.735168,211.636398 785.415222,218.192200 787.110657,225.881882 
	C788.074585,224.039734 788.437134,223.346924 789.545776,223.091263 
	C789.696289,223.387482 789.923523,223.668350 789.986389,223.982147 
	C794.190491,244.953583 806.497803,258.888184 826.572205,266.025482 
	C830.069397,267.268890 833.666931,268.230042 836.556946,269.637115 
M203.329681,712.066650 
	C203.978455,709.342529 204.477570,706.572266 205.301712,703.902283 
	C208.992767,691.944763 212.555847,679.940369 216.602234,668.102356 
	C219.412323,659.881226 222.933136,651.903076 226.411377,643.122192 
	C217.999939,639.391663 209.860367,635.781616 201.313049,631.990784 
	C198.216934,639.432129 195.081924,646.112305 192.633057,653.035156 
	C187.908920,666.389832 183.421005,679.833740 179.144470,693.338562 
	C177.250824,699.318481 175.999878,705.513000 174.674194,711.656921 
	C174.009537,714.737244 173.875534,717.329834 178.603333,717.728394 
	C183.824203,718.168457 188.965317,719.749146 194.095428,721.017944 
	C199.434586,722.338440 200.511383,721.784241 201.810440,716.618042 
	C202.132690,715.336487 202.711273,714.119263 203.329681,712.066650 
M825.037659,368.311554 
	C821.887878,376.442047 819.034668,384.706268 815.455627,392.643188 
	C813.813721,396.284363 814.782776,397.828369 817.883240,399.187805 
	C823.740112,401.755798 829.636902,404.243073 835.406311,406.996124 
	C838.157898,408.309174 839.464294,407.147705 840.427124,404.879517 
	C842.156311,400.805878 843.994995,396.765442 845.463684,392.596924 
	C850.663513,377.838379 855.960999,363.106781 860.736633,348.209564 
	C862.949036,341.308228 864.210999,334.078339 865.483826,326.922668 
	C865.701599,325.698364 864.041931,323.130554 862.839233,322.830963 
	C856.258301,321.191589 849.552246,320.051483 842.882935,318.772003 
	C840.329468,318.282135 839.196777,319.168640 838.440918,321.940399 
	C834.275696,337.214691 829.789429,352.401459 825.037659,368.311554 
M663.900452,217.998337 
	C681.863037,212.536926 699.773315,206.890305 717.835999,201.782837 
	C721.132507,200.850708 721.982544,199.808456 721.358826,196.863770 
	C720.155518,191.182846 718.912048,185.510132 717.755127,179.819794 
	C716.565918,173.970474 715.847290,173.332092 709.959595,175.065201 
	C692.683533,180.150711 675.370605,185.140091 658.251953,190.719086 
	C649.397644,193.604706 640.924316,197.659103 631.880249,201.348068 
	C635.845398,210.168121 639.458252,218.204529 643.192200,226.510269 
	C650.366821,223.591400 656.788513,220.978821 663.900452,217.998337 
M329.898529,836.677673 
	C327.829010,837.264587 325.793304,838.074036 323.683289,838.394714 
	C318.285370,839.215210 317.895844,840.021606 319.306091,845.461914 
	C320.632538,850.578979 321.843689,855.787231 322.373230,861.029358 
	C322.767609,864.933533 323.945435,866.573425 327.923309,865.582642 
	C329.530029,865.182495 331.240479,865.174500 332.826447,864.719055 
	C345.816040,860.988098 358.912811,857.570312 371.726318,853.304504 
	C382.804474,849.616333 393.567596,844.975647 404.439697,840.679382 
	C406.820618,839.738464 408.341675,838.246399 406.988495,835.288574 
	C404.248260,829.298584 401.859100,823.136169 398.844604,817.292664 
	C398.177765,815.999939 395.135040,814.692627 393.832886,815.166931 
	C384.712280,818.489502 375.907806,822.712524 366.719543,825.808594 
	C354.859375,829.804993 342.735687,833.019348 329.898529,836.677673 
M226.846252,861.435059 
	C229.930435,862.429932 233.014618,863.424805 236.308655,864.487305 
	C238.788315,856.935486 241.089142,850.059326 243.294601,843.152649 
	C244.294556,840.021240 243.616150,837.981628 240.043030,836.665283 
	C222.233719,830.104248 209.934814,817.805664 203.474014,799.955688 
	C202.100662,796.161499 200.043594,796.014893 196.972137,797.031921 
	C191.344086,798.895691 185.791641,801.074829 180.050110,802.477417 
	C176.329941,803.386230 175.461197,805.648438 176.849335,808.229065 
	C181.987762,817.781677 186.782288,827.666992 193.143524,836.377502 
	C201.477112,847.788757 213.402084,855.216003 226.846252,861.435059 
z"
      />
      <path
        fill="#FAFAFD"
        opacity="1.000000"
        stroke="none"
        d="
M276.360840,118.386597 
	C276.360840,118.386604 276.769043,118.397491 277.313721,118.403702 
	C279.360229,117.737831 280.862030,117.065742 282.363861,116.393661 
	C282.755524,116.376411 283.147156,116.359169 284.176483,116.315033 
	C286.660614,115.652855 288.507111,115.017570 290.353577,114.382294 
	C290.727051,114.365341 291.100494,114.348381 292.015137,114.400650 
	C296.634613,113.878326 300.721008,113.336777 304.788940,112.680847 
	C308.687134,112.052292 312.566132,111.305016 316.453583,110.609909 
	C321.164673,110.656647 325.875763,110.703384 331.296326,110.978508 
	C336.687134,111.747993 341.368469,112.289085 346.049835,112.830177 
	C354.344055,113.992584 362.623810,115.288948 370.946564,116.186539 
	C372.346771,116.337547 374.389801,115.136269 375.366241,113.934509 
	C400.854218,82.565399 451.456207,88.711517 467.419861,126.892906 
	C470.096405,133.294601 471.076569,140.565323 471.832916,147.543701 
	C472.220093,151.116165 473.577026,152.949188 476.355835,154.325363 
	C509.285553,170.633377 540.898254,189.245392 570.502686,210.958588 
	C595.854492,229.552765 620.691467,248.911499 644.984924,268.870789 
	C678.032837,296.022675 708.178955,326.348511 737.017639,357.875641 
	C767.734619,391.456055 796.226257,426.886169 821.613953,464.729706 
	C822.999023,466.794373 824.444641,468.818420 826.269043,471.375641 
	C831.539368,479.592041 836.746033,487.101440 841.198547,495.034210 
	C852.365845,514.930908 863.150696,535.042175 874.233521,554.986938 
	C875.118164,556.578918 876.926636,558.203064 878.630371,558.700317 
	C886.203491,560.910828 894.387512,561.642517 901.445374,564.881592 
	C918.465149,572.692505 928.533142,586.755127 931.199646,605.103638 
	C934.032410,624.596680 927.968872,641.470398 912.917969,654.783630 
	C911.717041,655.845886 910.921570,658.181458 911.073547,659.820007 
	C911.809937,667.759155 912.981445,675.656799 913.912964,683.579224 
	C917.451660,713.676086 913.805481,742.453735 898.117981,768.999939 
	C884.800781,789.048096 866.423584,802.029968 843.078796,807.668884 
	C834.573120,809.723450 825.836121,810.893372 817.154968,812.112976 
	C813.711487,812.596802 812.222778,813.890686 812.031189,817.409241 
	C811.129333,833.972656 807.362671,849.827942 799.570984,864.571472 
	C790.045105,882.596375 775.896851,895.787598 756.905029,904.610046 
	C755.776855,905.052307 755.200256,905.300842 754.623596,905.549377 
	C754.623596,905.549377 754.213745,905.584229 753.698730,905.666748 
	C752.002075,906.370605 750.820496,906.992004 749.638916,907.613403 
	C749.638916,907.613342 749.231201,907.603516 748.687012,907.597656 
	C746.640686,908.263367 745.138489,908.934937 743.636230,909.606445 
	C743.244751,909.623535 742.853210,909.640686 741.823914,909.684448 
	C739.339539,910.346619 737.493042,910.982117 735.646484,911.617676 
	C735.255310,911.638123 734.864197,911.658630 733.783081,911.675781 
	C729.393066,912.201477 725.686035,912.686035 721.994873,913.271057 
	C717.837708,913.929871 713.694885,914.679260 709.545776,915.389221 
	C704.511169,915.323730 699.476562,915.258240 693.809326,914.968994 
	C691.626709,914.991577 690.076782,915.237976 688.526855,915.484314 
	C679.577026,929.944214 666.922119,939.140137 649.595459,942.699463 
	C643.469543,943.025757 637.902527,943.145264 632.335571,943.264709 
	C627.170654,941.320801 621.752625,939.865540 616.885742,937.345947 
	C598.585571,927.871338 588.336731,912.751526 586.600464,892.125122 
	C586.380127,889.507568 585.702637,888.262878 583.120178,887.022583 
	C561.322205,876.553894 539.182190,866.656738 518.099548,854.879150 
	C497.500702,843.371826 477.460205,830.652588 458.198334,817.018982 
	C434.710663,800.394409 411.733093,782.936890 389.539764,764.622009 
	C355.556763,736.577515 324.028809,705.836670 294.054779,673.509155 
	C263.538574,640.596924 235.259750,605.884094 209.638000,569.045227 
	C189.136765,539.568726 170.296967,509.060822 154.678360,476.669922 
	C153.272034,473.753448 151.432877,473.470642 148.712997,473.677063 
	C131.089157,475.014648 116.086937,469.511261 104.143150,456.272156 
	C90.474754,441.121399 86.897652,423.660309 92.141434,404.122833 
	C95.516052,391.549591 102.831383,381.878265 113.514542,374.677643 
	C115.956245,373.031891 116.278862,371.422424 115.731796,368.462219 
	C111.887444,347.660278 109.087509,326.822998 111.918388,305.521118 
	C113.875641,290.793030 117.383354,276.770538 124.313309,263.530609 
	C133.499969,245.979187 147.128647,233.236389 164.815750,224.741211 
	C178.396210,218.218460 192.806107,214.644806 207.869919,213.993408 
	C212.240997,213.804382 213.872208,211.967133 214.095840,207.573380 
	C214.922104,191.339783 218.820724,175.841629 226.435806,161.405975 
	C235.946930,143.376053 250.129700,130.228470 269.094666,121.388382 
	C270.222717,120.945923 270.799561,120.698250 271.376434,120.450577 
	C271.376434,120.450577 271.786346,120.415741 272.301361,120.332321 
	C273.997864,119.628143 275.179352,119.007370 276.360840,118.386597 
M714.024353,377.188324 
	C713.475098,376.745880 712.925903,376.303467 712.138855,375.859833 
	C712.138855,375.859833 712.137451,375.622009 711.998413,374.968201 
	C706.453186,368.615204 701.227234,361.945282 695.297607,355.973969 
	C682.190491,342.774597 668.755920,329.899231 655.372559,316.977020 
	C653.991394,315.643372 652.054504,314.885162 650.137817,313.859161 
	C650.137817,313.859161 650.136414,313.621490 650.006836,313.077972 
	C649.423523,312.681030 648.840149,312.284119 648.102539,311.896301 
	C648.102539,311.896301 648.111328,311.741943 648.008789,311.175446 
	C647.426147,310.745667 646.843567,310.315918 646.104248,309.895233 
	C646.104248,309.895233 646.113098,309.738556 645.993225,309.082092 
	C640.746277,304.683960 635.499329,300.285858 630.101501,295.897064 
	C630.101501,295.897064 630.110168,295.746155 630.008911,295.179138 
	C629.427063,294.747894 628.845276,294.316681 628.104919,293.894806 
	C628.104919,293.894806 628.113525,293.736267 627.983704,293.080627 
	C625.677734,291.376404 623.371704,289.672211 621.003235,287.990082 
	C621.003235,287.990082 621.033508,287.931152 620.964478,287.262146 
	C619.394653,286.137115 617.824829,285.012085 616.102051,283.895996 
	C616.102051,283.895996 616.110962,283.743073 615.991699,283.161499 
	C615.371887,282.751923 614.752136,282.342346 614.056641,281.943085 
	C614.056641,281.943085 614.067017,281.867401 613.969604,281.184052 
	C612.334839,280.111969 610.700012,279.039886 609.002686,277.989655 
	C609.002686,277.989655 609.033569,277.931030 608.965271,277.262390 
	C607.396729,276.136810 605.828186,275.011230 604.103943,273.894653 
	C604.103943,273.894653 604.112976,273.739014 603.973450,273.062958 
	C602.277527,272.059448 600.581543,271.055939 598.279724,269.787811 
	C596.164551,267.907471 594.049377,266.027130 591.840393,263.466461 
	C590.917480,262.968658 589.994629,262.470825 588.449219,261.765289 
	C587.671814,261.046173 586.894348,260.327087 585.976013,259.053162 
	C585.360840,258.679871 584.745728,258.306580 584.055481,257.946472 
	C584.055481,257.946472 584.064941,257.870789 583.925049,257.222504 
	C582.973999,256.803223 582.023010,256.383911 581.006042,255.985947 
	C581.006042,255.985947 581.036987,255.923996 580.963135,255.248795 
	C579.351440,254.143967 577.739746,253.039154 576.052979,251.948181 
	C576.052979,251.948181 576.064026,251.872620 575.924866,251.223740 
	C574.973511,250.804291 574.022217,250.384842 573.005554,249.986557 
	C573.005554,249.986557 573.036133,249.925110 572.958801,249.241989 
	C570.330444,247.485519 567.702026,245.729050 564.457336,243.771622 
	C563.669006,243.091400 562.880615,242.411194 561.968323,241.070145 
	C557.398926,238.008423 552.829468,234.946716 548.106506,231.900146 
	C548.106506,231.900146 548.109009,231.745819 547.929688,231.121307 
	C546.978149,230.738205 546.026611,230.355103 544.457642,229.776596 
	C543.668701,229.094910 542.879822,228.413223 541.917236,227.109207 
	C540.969849,226.730133 540.022461,226.351059 538.457642,225.776596 
	C537.668701,225.094910 536.879822,224.413239 535.917236,223.109207 
	C534.969849,222.730133 534.022461,222.351059 532.457703,221.776627 
	C531.668762,221.095123 530.879761,220.413620 529.915771,219.093292 
	C524.695435,216.022064 519.475159,212.950836 514.106262,209.895157 
	C514.106262,209.895157 514.109070,209.745834 513.927246,209.103546 
	C510.704315,207.363449 507.481415,205.623367 504.106506,203.898636 
	C504.106506,203.898636 504.109161,203.745911 503.927399,203.103622 
	C490.585602,195.604919 477.270081,188.058197 463.839600,180.721832 
	C462.996338,180.261215 461.255005,181.444672 459.939178,181.939316 
	C459.939178,181.939316 459.863129,181.931671 459.206146,182.091507 
	C458.933990,182.857544 458.661835,183.623581 458.001221,184.000000 
	C458.131073,184.129593 458.260956,184.259171 457.648468,184.231247 
	C456.561859,185.619568 455.475220,187.007889 454.047974,188.002731 
	C454.165771,188.130325 454.283569,188.257919 453.726044,188.233154 
	C453.144379,188.805481 452.562714,189.377792 451.742065,190.557541 
	C450.494507,191.369202 449.246948,192.180878 447.596497,192.572189 
	C446.728119,193.365051 445.859741,194.157913 444.498291,195.397430 
	C443.340546,195.957367 442.182831,196.517303 440.563263,196.575562 
	C438.046417,197.743668 435.529572,198.911774 432.550201,200.644287 
	C431.379028,200.822769 430.207825,201.001251 428.483856,200.607712 
	C426.864105,200.510849 425.210419,200.152969 423.629913,200.358185 
	C410.232697,202.097870 397.982147,199.165710 386.953247,191.396240 
	C383.205658,188.756195 379.691589,185.784714 376.011017,182.995834 
	C376.011017,182.995834 376.033356,182.932236 376.038116,182.203369 
	C369.698212,171.458847 364.671753,160.323593 364.694580,147.447159 
	C364.696564,146.309784 362.459381,144.421341 361.023560,144.151443 
	C352.595551,142.567169 344.136200,140.664642 335.612854,140.179520 
	C320.594238,139.324677 305.452087,139.281647 290.966217,144.208618 
	C285.695862,146.001190 280.902252,149.195526 275.889923,151.900391 
	C275.889923,151.900391 275.741058,151.881058 275.026917,151.893616 
	C273.313538,153.176514 271.600128,154.459427 269.895508,155.896683 
	C269.895508,155.896683 269.741211,155.887527 269.068787,155.996674 
	C267.034332,157.967468 264.999908,159.938263 262.832245,162.438843 
	C262.528503,162.616180 262.224731,162.793518 261.253601,163.038574 
	C260.131042,164.606155 259.008453,166.173752 257.897675,167.895721 
	C257.897675,167.895721 257.742950,167.889694 257.118500,168.069931 
	C256.733490,169.022003 256.348511,169.974091 255.987854,170.991211 
	C255.987854,170.991211 255.924103,170.963654 255.251892,171.093094 
	C248.514603,178.975967 246.478973,188.788040 244.139999,198.451935 
	C243.960480,199.193680 244.798599,200.181732 245.352295,201.838867 
	C245.189026,202.886429 245.025742,203.933975 244.159637,205.191757 
	C243.850769,208.570419 242.436539,214.462189 243.422882,214.896011 
	C247.874008,216.853790 252.753510,219.652664 258.775818,216.696259 
	C259.508881,216.860855 260.241943,217.025452 261.146942,217.879349 
	C263.493164,220.378784 266.112396,220.780899 269.697876,218.753220 
	C270.123566,218.899307 270.549225,219.045395 271.152802,219.888809 
	C276.112183,220.890839 281.071564,221.892868 286.710999,222.774277 
	C287.128113,222.945557 287.545197,223.116852 288.074524,223.957031 
	C289.727051,224.268616 291.379547,224.580200 293.707550,224.751328 
	C294.127228,224.917221 294.546906,225.083115 295.093994,225.953659 
	C295.656860,226.526047 296.118622,227.388824 296.798340,227.625381 
	C302.824860,229.722717 308.855957,231.820221 314.955353,233.690414 
	C319.241211,235.004532 323.635651,235.964569 328.658966,237.131088 
	C329.415588,237.489731 330.172241,237.848389 331.082275,238.807037 
	C332.065430,238.835434 333.048553,238.863846 334.657410,238.832901 
	C335.103729,239.225159 335.550018,239.617416 336.038574,240.578461 
	C336.709229,240.662201 337.379852,240.745926 338.735138,240.736725 
	C339.485931,241.168289 340.236725,241.599854 341.120422,242.716797 
	C341.766113,243.193588 342.355682,243.786194 343.065735,244.130112 
	C358.001373,251.364288 372.940613,258.591187 387.902069,265.771759 
	C393.820831,268.612396 399.794922,271.337891 405.895050,274.102142 
	C405.895050,274.102142 405.890717,274.253906 406.078949,274.905487 
	C408.362549,275.948547 410.646179,276.991638 412.993286,278.010132 
	C412.993286,278.010132 412.965515,278.072327 413.089386,278.740143 
	C416.368805,280.505402 419.648193,282.270630 422.992096,284.011383 
	C422.992096,284.011383 422.964355,284.074554 423.088898,284.741394 
	C426.330414,286.517242 429.571930,288.293091 433.448944,290.251312 
	C434.269196,290.922913 435.089447,291.594543 436.083954,292.905457 
	C442.933655,297.556702 449.722931,302.300537 456.656403,306.823334 
	C460.623535,309.411133 464.825195,311.639435 469.540588,314.223999 
	C470.329590,314.905609 471.118561,315.587219 472.031708,316.929993 
	C475.661530,319.296570 479.291351,321.663147 483.546326,324.232300 
	C484.324921,324.952057 485.103485,325.671814 486.034088,327.038025 
	C487.663879,328.034973 489.293701,329.031921 491.541656,330.225983 
	C492.329865,330.908234 493.118073,331.590485 494.029144,332.938019 
	C495.660583,333.968292 497.292053,334.998596 499.548279,336.232422 
	C500.326385,336.953094 501.104492,337.673737 502.023895,338.950195 
	C502.641418,339.321259 503.258942,339.692322 503.951385,340.048615 
	C503.951385,340.048615 503.937439,340.123688 504.067535,340.777679 
	C506.626617,342.556061 509.185699,344.334442 511.897491,346.103821 
	C511.897491,346.103821 511.888580,346.256531 512.021179,346.922180 
	C513.656372,347.960175 515.291565,348.998169 516.993164,350.014923 
	C516.993164,350.014923 516.962219,350.077454 517.033875,350.745270 
	C518.603638,351.868042 520.173340,352.990814 521.897400,354.104584 
	C521.897400,354.104584 521.888489,354.258911 522.009033,354.840302 
	C522.629700,355.248779 523.250427,355.657288 523.946350,356.053284 
	C523.946350,356.053284 523.935120,356.128754 524.069336,356.786255 
	C526.109985,358.161072 528.150635,359.535919 530.647583,360.923950 
	C530.975281,361.035461 531.152527,361.263763 530.918274,362.396942 
	C529.649719,362.926270 528.392090,363.880066 527.110718,363.913300 
	C520.695190,364.079590 514.273438,364.014191 507.853882,364.011719 
	C502.258392,364.009583 496.662933,363.982758 491.003540,363.987885 
	C491.003540,363.987885 491.034973,363.928375 490.963348,363.252686 
	C489.351837,362.146423 487.740356,361.040161 486.053436,359.945923 
	C486.053436,359.945923 486.065338,359.870453 485.933197,359.217377 
	C483.373962,357.440582 480.814758,355.663788 478.103088,353.897125 
	C478.103088,353.897125 478.110870,353.744476 477.979370,353.086090 
	C475.406464,351.352417 472.833527,349.618774 470.106079,347.899170 
	C470.106079,347.899170 470.109772,347.744080 469.929169,347.119446 
	C468.976471,346.734711 468.023773,346.350006 467.005829,345.986572 
	C467.005829,345.986572 467.036102,345.924957 466.958710,345.241791 
	C464.330292,343.485352 461.701904,341.728912 458.458099,339.772125 
	C457.669037,339.091949 456.879944,338.411774 455.916382,337.108734 
	C454.967651,336.727783 454.018921,336.346832 453.005402,335.987000 
	C453.005402,335.987000 453.035492,335.925842 452.959167,335.243317 
	C446.613007,330.645630 440.376648,325.884521 433.875031,321.518280 
	C430.557495,319.290314 426.764099,317.770935 422.550171,315.749023 
	C421.730347,315.076721 420.910553,314.404419 419.915802,313.093475 
	C415.632507,310.718140 411.349213,308.342804 407.004639,305.992065 
	C407.004639,305.992065 407.032349,305.932129 406.910583,305.264252 
	C404.777161,303.753021 402.753571,302.047882 400.490112,300.766327 
	C393.173370,296.623657 385.860809,292.455963 378.377045,288.629669 
	C365.343109,281.965637 352.194244,275.525482 339.047882,269.084656 
	C338.219452,268.678802 336.997223,269.076691 335.341705,269.112854 
	C334.898712,268.731232 334.455688,268.349609 333.959534,267.400757 
	C333.287445,267.332153 332.615356,267.263519 331.255707,267.299500 
	C330.495605,266.885254 329.735504,266.471008 328.950684,265.483521 
	C328.288391,265.364105 327.626068,265.244690 326.283691,265.247375 
	C325.529236,264.814423 324.774750,264.381470 323.965485,263.387573 
	C323.295715,263.308228 322.625916,263.228912 321.272614,263.228058 
	C320.523926,262.796387 319.775238,262.364716 318.925781,261.310364 
	C317.942200,261.235260 316.958618,261.160126 315.354614,261.131470 
	C314.906342,260.745972 314.458099,260.360474 313.866974,259.287354 
	C311.225769,258.590729 308.584534,257.894104 305.272308,257.289795 
	C304.856689,257.135193 304.441040,256.980591 303.927856,256.168243 
	C302.603149,255.829498 301.278442,255.490753 299.284027,255.259903 
	C298.864502,255.114975 298.444977,254.970062 297.926544,254.165283 
	C296.599213,253.840103 295.271881,253.514938 293.274261,253.290695 
	C292.857300,253.137100 292.440369,252.983490 291.878998,252.179245 
	C290.236755,251.843094 288.594513,251.506943 286.285919,251.318512 
	C285.863678,251.170303 285.441467,251.022110 284.909760,250.187775 
	C283.680176,247.844254 282.073608,247.288696 279.289703,249.265579 
	C278.867279,249.122299 278.444855,248.979019 277.863464,248.167542 
	C275.891968,247.829193 273.920502,247.490845 271.206421,247.310242 
	C270.480286,247.135345 269.754120,246.960464 268.877686,246.138840 
	C267.232880,245.825897 265.588043,245.512955 263.204407,245.376419 
	C262.473602,245.225220 261.742798,245.074020 260.831451,244.226471 
	C258.091675,242.046448 255.219070,240.972122 251.215927,243.347366 
	C250.481918,243.199707 249.747925,243.052048 248.863998,242.271301 
	C246.595230,240.258514 245.219589,240.377548 245.302460,244.378326 
	C245.246445,244.566116 245.190414,244.753891 244.614243,245.369568 
	C244.063568,248.283127 242.552383,251.468353 247.137589,253.753296 
	C247.147537,254.818344 247.157486,255.883408 246.624084,257.444458 
	C245.619431,260.218781 245.720566,262.623291 249.165741,264.700714 
	C249.165665,265.450165 249.165588,266.199585 248.627869,267.437286 
	C247.644394,269.993317 247.548248,272.192963 251.232513,273.687775 
	C251.223862,274.437164 251.215210,275.186554 250.667786,276.383728 
	C249.506653,278.826263 250.171234,280.403870 253.208511,281.604462 
	C253.206940,282.049042 253.205368,282.493622 252.694061,283.327484 
	C253.119522,284.222137 253.544968,285.116791 254.572479,286.425751 
	C254.781067,287.596710 254.989655,288.767670 254.682495,290.340332 
	C255.394470,291.569000 256.106445,292.797668 257.177734,294.603394 
	C257.179657,295.050201 257.181610,295.496979 256.674011,296.355957 
	C257.179596,297.209778 257.685211,298.063629 258.745819,299.317322 
	C258.891724,300.192810 259.037628,301.068298 258.674011,302.355896 
	C259.179596,303.209747 259.685211,304.063599 260.745117,305.316559 
	C260.892853,306.191528 261.040619,307.066467 260.672760,308.354279 
	C261.434967,309.571960 262.197144,310.789673 263.270905,312.602631 
	C263.247864,313.046844 263.224854,313.491028 262.709991,314.345642 
	C264.121033,316.900360 265.532074,319.455048 267.258728,322.602570 
	C267.239502,323.046783 267.220276,323.490997 266.707031,324.351013 
	C269.895630,332.304077 272.940613,340.319763 276.365143,348.169861 
	C277.323334,350.366302 279.400391,352.074585 281.374390,354.561249 
	C281.935669,356.034454 282.496918,357.507660 282.640167,359.336243 
	C283.110962,359.883362 283.581726,360.430450 284.615387,361.246338 
	C284.764587,361.824066 284.913788,362.401825 284.643585,363.333832 
	C285.113098,363.881805 285.582611,364.429810 286.597900,365.222809 
	C286.817932,365.957825 287.037994,366.692871 287.157166,368.235382 
	C289.377228,372.179291 291.597290,376.123169 294.386169,380.318726 
	C294.624115,380.868225 294.862061,381.417694 294.648010,382.393219 
	C296.079590,384.598755 297.511169,386.804321 299.407684,389.532074 
	C302.192017,394.050262 304.976318,398.568451 307.843353,403.793823 
	C312.561554,411.360901 316.951233,419.158112 322.060242,426.451416 
	C336.677124,447.317657 351.612915,467.960358 366.316742,488.766235 
	C367.253479,490.091705 367.759399,492.093719 367.643097,493.722260 
	C367.278900,498.823914 366.520691,503.897461 365.678009,509.815277 
	C365.726379,512.346191 365.774780,514.877136 365.447235,517.912781 
	C365.556396,518.495483 365.665588,519.078186 365.435852,520.440308 
	C365.466827,524.403503 365.497772,528.366638 364.896820,532.634766 
	C363.466492,531.817017 361.648926,531.319092 360.667664,530.130554 
	C353.383667,521.308167 346.137390,512.448425 339.118347,503.414673 
	C311.149506,467.417511 286.070587,429.512695 264.561646,389.299988 
	C250.534073,363.074280 238.298294,336.007111 229.143326,307.696655 
	C224.776947,294.194275 221.679535,280.263092 218.414505,266.430054 
	C216.488617,258.270538 215.359589,249.922943 213.718399,240.805634 
	C207.746002,241.693512 201.847504,242.359024 196.036163,243.477371 
	C183.040573,245.978241 171.174026,250.822647 161.228516,259.988831 
	C152.808502,267.749084 147.409592,277.243164 143.053864,288.378387 
	C142.319382,292.867859 141.584900,297.357300 140.350449,302.061493 
	C140.238358,302.705750 140.126266,303.349976 139.702164,304.680176 
	C139.673920,305.683350 139.645691,306.686554 139.202423,308.379486 
	C138.986221,310.000610 138.549454,311.627563 138.591934,313.241882 
	C138.778275,320.324127 139.103287,327.402740 139.303558,335.026245 
	C139.367737,335.568665 139.431915,336.111053 139.077087,337.266327 
	C139.265518,338.941193 139.453949,340.616089 139.593231,342.983398 
	C139.717957,343.656342 139.842682,344.329254 139.473602,345.337708 
	C139.930740,346.277313 140.387878,347.216919 140.739716,349.026031 
	C141.398621,353.398407 141.869247,357.813110 142.837280,362.115936 
	C143.108795,363.322876 144.702744,364.852844 145.931290,365.118286 
	C151.758423,366.377258 157.668976,367.250153 164.104080,368.720215 
	C164.997009,369.159058 165.883270,369.612122 166.783829,370.034729 
	C202.026352,386.573700 210.070801,432.840729 180.775925,460.071045 
	C179.616653,461.148590 179.244415,464.498657 180.022354,466.008118 
	C186.062332,477.727509 191.890015,489.622711 198.896194,500.763733 
	C212.981766,523.162170 227.222809,545.502075 242.393021,567.170593 
	C269.776367,606.283936 301.202087,642.116394 334.524139,676.231445 
	C365.988251,708.444397 399.148560,738.887939 435.264313,765.848999 
	C458.480743,783.180359 482.062958,800.124939 506.386353,815.842224 
	C532.400696,832.652100 559.774109,847.213501 588.425903,859.276123 
	C592.805725,861.120056 594.443726,860.411072 596.779419,857.010498 
	C602.848450,848.174255 610.959106,841.827759 621.086304,838.102600 
	C623.820618,837.096680 626.435730,835.766907 629.395996,834.825562 
	C629.691284,834.650208 629.986633,834.474915 630.794556,834.686462 
	C631.388733,834.511414 631.982849,834.336426 633.401001,834.435730 
	C638.744934,834.345764 644.088928,834.255859 649.943909,834.555725 
	C650.536438,834.469727 651.129028,834.383728 651.839233,834.624329 
	C652.146057,834.615295 652.452820,834.606201 653.164856,835.295776 
	C669.697144,839.956238 682.259949,849.557007 690.308411,865.716492 
	C692.386719,872.646912 694.465088,879.577332 696.883301,887.641174 
	C703.027893,887.327087 710.209961,887.677856 717.104736,886.434631 
	C726.762939,884.693176 736.391235,882.314880 745.695435,879.202271 
	C756.899536,875.453979 764.532715,866.688354 772.698853,857.890808 
	C773.115295,856.903503 773.531738,855.916138 773.992493,854.996033 
	C773.992493,854.996033 773.925293,854.951599 774.498169,854.943115 
	C774.634949,854.286011 774.771790,853.628906 774.812561,852.349976 
	C775.179199,851.887329 775.545776,851.424683 776.487000,850.949341 
	C776.627502,850.290161 776.768066,849.630981 776.812805,848.349487 
	C777.180298,847.887451 777.547852,847.425476 778.631348,846.856079 
	C783.414673,838.941711 784.735779,830.028564 785.901917,821.104309 
	C786.073608,819.790100 785.227600,818.342834 784.647888,816.170532 
	C784.558289,814.633850 784.468750,813.097168 784.388306,811.717957 
	C782.184143,811.400085 780.596680,811.171143 778.818481,810.233826 
	C775.383667,808.092896 771.845825,806.949951 767.223877,809.305786 
	C766.490845,809.140930 765.757751,808.976135 764.853210,808.121704 
	C762.506958,805.620667 759.887634,805.218689 756.301697,807.249268 
	C755.876160,807.101807 755.450623,806.954346 754.846985,806.109741 
	C749.887695,805.108032 744.928467,804.106323 739.288879,803.225525 
	C738.871887,803.053650 738.454834,802.881836 737.925659,802.041199 
	C736.272827,801.731079 734.619934,801.420898 732.291382,801.248474 
	C731.872009,801.082458 731.452698,800.916443 730.905640,800.046021 
	C730.632507,799.639771 730.443909,798.991699 730.073853,798.862305 
	C717.940735,794.618530 705.794678,790.411682 693.623596,786.278381 
	C693.212952,786.138916 692.525635,786.814026 691.341736,787.167236 
	C690.895691,786.775146 690.449585,786.383057 689.961121,785.422180 
	C689.290344,785.338867 688.619568,785.255493 687.264099,785.263123 
	C686.513611,784.831543 685.763062,784.400024 684.879883,783.283142 
	C684.234009,782.806641 683.644165,782.214539 682.934021,781.870605 
	C667.998413,774.636475 653.058960,767.409790 638.097534,760.229126 
	C632.178650,757.388367 626.204895,754.662231 620.106384,751.900146 
	C620.106384,751.900146 620.108032,751.749512 619.920410,751.097351 
	C617.635559,750.054016 615.350708,749.010742 613.004517,747.992310 
	C613.004517,747.992310 613.032532,747.932373 612.910156,747.263733 
	C609.629211,745.498169 606.348206,743.732605 603.005310,741.991516 
	C603.005310,741.991516 603.033020,741.930969 602.911133,741.263245 
	C599.669373,739.485901 596.427551,737.708557 592.550171,735.748962 
	C591.730408,735.076660 590.910645,734.404419 589.915771,733.093323 
	C586.029724,730.344055 582.245483,727.434692 578.233643,724.883301 
	C572.284668,721.099792 566.174377,717.569946 560.057678,713.945801 
	C560.057678,713.945801 560.065125,713.869873 559.924927,713.221680 
	C558.973999,712.805542 558.023071,712.389404 556.454895,711.775391 
	C555.667358,711.093933 554.879822,710.412537 553.968872,709.071594 
	C551.354797,707.359375 548.740784,705.647156 546.051941,703.950073 
	C546.051941,703.950073 546.063354,703.874634 545.925354,703.225891 
	C544.975220,702.807983 544.025146,702.390076 542.450867,701.768127 
	C541.673096,701.048828 540.895325,700.329468 539.983582,698.995239 
	C539.254944,697.838501 538.526306,696.681702 537.438782,694.955200 
	C541.223816,694.584717 544.350586,694.459290 547.409546,693.937134 
	C552.073242,693.141052 556.745239,692.278442 561.313049,691.065186 
	C567.325073,689.468323 573.293579,685.692200 579.001404,691.757141 
	C579.347168,692.124573 580.267761,691.951111 581.538208,692.224915 
	C582.328796,692.905090 583.119385,693.585266 584.081909,694.888000 
	C585.027649,695.268555 585.973450,695.649170 587.537964,696.221985 
	C588.328613,696.903137 589.119324,697.584351 590.081909,698.887939 
	C591.027710,699.268494 591.973511,699.649109 593.537964,700.221985 
	C594.328613,700.903137 595.119263,701.584290 596.083923,702.904785 
	C599.473267,705.226746 602.766785,707.708252 606.282715,709.819397 
	C609.021301,711.463684 612.036621,712.647034 614.992737,714.010498 
	C614.992737,714.010498 614.964783,714.072937 615.088867,714.740540 
	C618.369141,716.505249 621.649475,718.269958 624.993164,720.010132 
	C624.993164,720.010132 624.965393,720.072144 625.088562,720.739319 
	C629.667908,723.187012 634.247314,725.634766 638.926208,728.075256 
	C638.926208,728.075256 638.920105,728.174805 639.082886,728.830322 
	C640.233948,729.760193 641.262268,730.935242 642.554871,731.582581 
	C657.643250,739.138245 672.581543,747.031799 687.932251,754.016846 
	C712.694702,765.284485 738.421082,773.905029 765.028381,779.712036 
	C770.317505,780.866272 775.764465,781.427612 781.170593,781.807739 
	C782.285706,781.886169 784.500183,779.988892 784.556091,778.916077 
	C784.726379,775.646729 784.520935,772.222595 783.693237,769.059753 
	C780.002502,754.956238 776.743164,740.681946 772.004944,726.925110 
	C766.124756,709.852722 759.684570,692.885925 752.117310,676.505920 
	C744.012024,658.961243 734.530762,642.033325 725.206543,625.080139 
	C721.506165,618.352234 716.613464,612.280029 711.695129,605.671021 
	C711.506531,605.322693 711.317932,604.974304 711.150085,603.900940 
	C706.922363,596.922363 702.865906,589.831543 698.397278,583.010742 
	C696.032837,579.401611 692.951599,576.262085 690.084534,572.917847 
	C690.084534,572.917847 690.092163,572.808411 690.085083,572.095459 
	C686.788086,567.700439 683.491028,563.305481 680.084534,558.917847 
	C680.084534,558.917847 680.092163,558.808411 680.085083,558.095459 
	C676.121399,553.033875 672.157715,547.972229 668.084595,542.917786 
	C668.084595,542.917786 668.092163,542.808411 668.090515,542.149475 
	C667.436707,541.411194 666.782959,540.672913 666.053650,539.946228 
	C666.053650,539.946228 666.065491,539.870850 665.989197,539.178101 
	C663.411743,536.081116 660.834351,532.984070 658.103027,529.895874 
	C658.103027,529.895874 658.112183,529.742004 658.004150,529.075195 
	C656.693237,527.705078 655.382324,526.334961 654.011292,524.995911 
	C654.011292,524.995911 654.033325,524.931946 654.042847,524.228821 
	C652.781494,522.781372 651.520142,521.333862 650.103821,519.895447 
	C650.103821,519.895447 650.112793,519.740479 650.001099,519.069702 
	C648.771729,517.006165 647.542297,514.942566 646.796143,513.690186 
	C647.947815,511.201630 648.779724,509.403961 649.995117,507.998779 
	C649.867859,507.868042 649.740662,507.737305 650.326294,507.776398 
	C652.255371,505.936859 654.184509,504.097351 656.104614,502.103027 
	C656.104614,502.103027 656.259460,502.112000 656.826050,502.008362 
	C657.255005,501.424744 657.683960,500.841125 658.103943,500.102844 
	C658.103943,500.102844 658.258667,500.111816 658.811279,500.024017 
	C659.253662,499.473877 659.696045,498.923737 660.137695,498.136627 
	C660.137695,498.136627 660.374634,498.137085 661.041077,497.993225 
	C663.279419,496.672119 664.774841,497.176819 666.370178,499.360077 
	C667.748352,501.246246 669.921753,502.551270 671.896912,504.104248 
	C671.896912,504.104248 671.887939,504.257507 671.994690,504.924469 
	C673.304504,506.295441 674.614380,507.666443 675.986511,509.006836 
	C675.986511,509.006836 675.964417,509.072662 675.955017,509.775543 
	C677.218323,511.221191 678.481567,512.666809 679.897400,514.103455 
	C679.897400,514.103455 679.888428,514.255981 679.991760,514.916809 
	C681.263184,516.307739 682.534607,517.698608 683.915405,519.082275 
	C683.915405,519.082275 683.907898,519.191650 683.914368,519.901855 
	C687.146545,523.974548 690.378723,528.047241 694.176331,532.337402 
	C694.481018,532.827148 694.785706,533.316956 695.072083,534.538940 
	C697.354553,537.372498 699.636963,540.206055 701.983459,543.009094 
	C701.983459,543.009094 701.962585,543.076782 701.957336,543.801758 
	C704.510498,547.240601 707.063721,550.679382 710.181396,554.336609 
	C710.484497,554.826477 710.787659,555.316284 711.073242,556.541077 
	C718.912292,568.991577 726.940063,581.328186 734.541504,593.922058 
	C743.588257,608.910767 752.552063,623.964233 761.005554,639.290894 
	C766.342834,648.967590 771.147949,659.002747 775.363159,669.218994 
	C782.631714,686.835449 789.221313,704.731506 796.184692,722.475281 
	C797.207397,725.081360 798.718018,727.495972 800.586975,730.361572 
	C800.763733,731.228516 800.940552,732.095520 800.577942,733.484863 
	C800.588562,738.119507 800.946777,742.639587 805.097900,746.609863 
	C805.099182,747.062317 805.100464,747.514771 804.581238,748.476685 
	C805.322998,750.659546 806.064758,752.842346 807.167236,755.698730 
	C807.156006,756.451416 807.144775,757.204102 806.599670,758.474304 
	C805.897949,761.093140 805.032410,763.762878 808.824036,765.823242 
	C808.849915,766.890564 808.875793,767.957886 808.436707,769.639771 
	C808.769714,772.702881 808.739929,775.861023 809.511536,778.809326 
	C811.098145,784.872009 815.987976,785.762451 821.708862,780.808472 
	C822.460388,780.834045 823.211914,780.859619 824.508301,781.424072 
	C827.350952,781.886658 830.502136,783.437866 832.607239,778.904236 
	C833.058533,778.900879 833.509827,778.897522 834.465454,779.415649 
	C838.812866,778.237732 843.619263,777.860596 847.399475,775.693359 
	C852.991211,772.487610 857.854065,768.010559 863.223999,763.470459 
	C864.174377,762.656006 865.124695,761.841614 866.743408,760.959595 
	C867.866272,759.390564 868.989136,757.821472 870.102966,756.101624 
	C870.102966,756.101624 870.253784,756.110474 870.917908,755.970215 
	C871.655823,754.437012 872.393738,752.903748 873.269287,750.772583 
	C873.598389,750.544312 873.927551,750.316101 874.905579,749.910217 
	C882.795898,739.251160 884.934204,726.499451 885.639221,714.024902 
	C886.428345,700.062561 884.660095,685.952637 883.941833,671.910095 
	C883.735596,667.877808 881.378784,666.163086 877.419067,665.977661 
	C873.489746,665.793701 869.341309,665.923584 865.708801,664.684875 
	C858.407959,662.194946 851.392761,658.867493 844.104004,655.894714 
	C844.104004,655.894714 844.112976,655.739075 843.987244,655.085815 
	C840.787781,652.017334 837.588318,648.948792 833.771545,645.623291 
	C833.201965,644.693909 832.632385,643.764526 832.067444,642.090149 
	C829.827698,634.557861 826.539734,627.145813 825.657166,619.457703 
	C824.852722,612.450012 826.005676,605.002258 827.513184,598.010986 
	C828.773254,592.167358 831.793823,586.703308 834.013306,581.005615 
	C834.013306,581.005615 834.075012,581.036438 834.743591,580.965576 
	C835.866089,579.393433 836.988586,577.821289 838.101990,576.100281 
	C838.101990,576.100281 838.250854,576.109131 838.819458,576.007568 
	C839.250305,575.423218 839.681152,574.838867 840.102966,574.102539 
	C840.102966,574.102539 840.254944,574.111389 840.926147,574.001282 
	C842.629028,572.357178 844.331970,570.713074 846.010498,569.005127 
	C846.010498,569.005127 846.071655,569.035645 846.752686,568.880310 
	C848.749573,563.783386 845.352417,560.897583 842.096863,557.890564 
	C842.096863,557.890564 842.115845,557.740234 842.100098,556.993713 
	C839.563232,551.665588 837.218323,546.233887 834.419189,541.047241 
	C832.676758,537.818542 830.247375,534.960510 828.050354,531.943604 
	C828.050354,531.943604 828.066528,531.869080 827.982178,531.147400 
	C826.611023,528.153748 825.561462,524.954651 823.805359,522.207031 
	C819.416870,515.340759 814.707703,508.679321 810.051208,501.942841 
	C810.051208,501.942841 810.067078,501.868164 809.989075,501.167877 
	C807.409302,497.408844 804.829529,493.649811 802.096802,489.892334 
	C802.096802,489.892334 802.112854,489.740204 802.072693,489.249847 
	C801.635742,488.808777 801.198853,488.367706 800.278687,487.461609 
	C800.208679,487.249329 800.138611,487.037048 800.071472,486.102173 
	C796.830322,481.006287 793.704407,475.831635 790.299377,470.847626 
	C788.590515,468.346313 786.373657,466.192108 783.824036,463.670502 
	C783.513123,463.192078 783.202148,462.713623 782.918335,461.498230 
	C780.636841,458.653442 778.355347,455.808655 776.012695,452.994507 
	C776.012695,452.994507 776.034119,452.929504 776.044800,452.255829 
	C775.404907,451.482697 774.765015,450.709564 774.049622,449.947784 
	C774.049622,449.947784 774.064819,449.872955 773.992065,449.187439 
	C772.686584,447.779236 771.381165,446.371033 770.013733,444.993622 
	C770.013733,444.993622 770.035217,444.927826 770.045410,444.254700 
	C769.406250,443.481445 768.767090,442.708221 768.052429,441.946167 
	C768.052429,441.946167 768.065613,441.870972 767.989075,441.178314 
	C765.411621,438.081207 762.834167,434.984100 760.102905,431.895660 
	C760.102905,431.895660 760.112183,431.741882 760.004211,431.075134 
	C758.693237,429.705017 757.382324,428.334900 756.011230,426.995697 
	C756.011230,426.995697 756.033264,426.931824 756.042786,426.228699 
	C754.781372,424.781219 753.520020,423.333710 752.103760,421.895172 
	C752.103760,421.895172 752.112793,421.740387 752.000122,421.066589 
	C749.377441,418.021942 746.754761,414.977295 744.056458,411.942871 
	C744.056458,411.942871 744.066772,411.867157 743.996887,411.277222 
	C743.416443,410.814087 742.836060,410.350952 742.102722,409.896759 
	C742.102722,409.896759 742.111755,409.743835 742.001038,409.068939 
	C738.754883,405.340912 735.508789,401.612854 732.104126,397.893616 
	C732.104126,397.893616 732.113342,397.735138 732.007935,397.169281 
	C731.423462,396.742218 730.839050,396.315155 730.102234,395.896851 
	C730.102234,395.896851 730.111450,395.744537 730.000488,395.071136 
	C725.420837,390.009155 720.841187,384.947174 716.103821,379.894012 
	C716.103821,379.894012 716.112976,379.736267 716.008484,379.170593 
	C715.425049,378.742676 714.841675,378.314758 714.102844,377.895660 
	C714.102844,377.895660 714.112000,377.740295 714.024353,377.188324 
M146.105270,446.912567 
	C146.460556,446.772369 146.815842,446.632202 147.880295,446.396301 
	C148.591537,446.319977 149.302765,446.243652 150.362640,446.580597 
	C151.634232,445.912994 152.905823,445.245392 154.799438,444.418518 
	C155.204330,444.282715 155.609207,444.146912 156.601028,444.077728 
	C157.159531,443.572083 157.718033,443.066406 158.874573,442.218323 
	C160.116364,441.220154 161.358154,440.221985 162.989761,439.258972 
	C163.278412,439.112854 163.360306,438.890656 163.699997,438.191528 
	C163.977142,437.891296 164.254303,437.591064 165.204361,437.212646 
	C165.874359,435.894836 166.544357,434.576996 167.602966,432.766418 
	C167.927628,432.236359 168.252274,431.706329 169.060440,430.857788 
	C169.461838,429.576447 169.863251,428.295135 170.375565,426.300934 
	C170.451630,425.583954 170.527710,424.867004 171.031723,423.591583 
	C170.870880,420.343475 170.710037,417.095367 170.436630,413.224640 
	C170.353851,412.811188 170.271072,412.397705 170.584885,411.638458 
	C169.959167,410.658508 169.333450,409.678528 168.465057,408.142792 
	C168.299332,407.774048 168.133621,407.405273 168.078491,406.452240 
	C167.596237,405.872925 167.113983,405.293640 166.278320,404.112946 
	C162.909470,401.235626 159.540604,398.358307 155.899597,395.057129 
	C155.581253,395.056305 155.262924,395.055481 154.289108,394.725128 
	C152.909027,394.283478 151.528961,393.841827 149.555710,392.963013 
	C145.650955,393.123718 141.746185,393.284393 137.222076,393.567383 
	C136.809555,393.650208 136.397034,393.733032 135.640961,393.417084 
	C134.660141,394.041870 133.679321,394.666687 132.065872,395.572601 
	C130.669662,396.352875 129.273453,397.133148 127.286201,397.970032 
	C126.792824,398.545959 126.299438,399.121857 125.412636,400.143921 
	C124.856323,400.707001 124.300011,401.270081 123.150932,401.974915 
	C122.764549,402.645752 122.378174,403.316559 121.681656,404.584991 
	C121.522545,405.004303 121.363434,405.423615 120.821556,405.964355 
	C120.604416,406.203552 120.595581,406.450104 120.422813,407.193970 
	C120.094078,407.708679 119.765350,408.223358 118.913719,409.003326 
	C118.574554,410.333435 118.235382,411.663574 117.706642,413.789612 
	C117.601303,415.145996 117.495964,416.502350 117.012856,418.322632 
	C117.140839,419.264008 117.268822,420.205353 117.449417,421.708893 
	C117.440369,422.084961 117.431320,422.460999 117.081360,423.104218 
	C117.219360,423.460144 117.357353,423.816101 117.588921,424.788818 
	C117.665375,425.197449 117.741837,425.606110 117.416382,426.374176 
	C118.085030,427.641937 118.753677,428.909698 119.580139,430.801147 
	C119.715050,431.207123 119.849960,431.613098 119.927368,432.614349 
	C120.451813,433.164795 120.976250,433.715240 121.876091,434.798737 
	C122.844849,435.969238 123.813614,437.139740 124.778618,439.001434 
	C126.057678,439.756989 127.336731,440.512573 129.105011,441.774933 
	C130.970062,442.770599 132.835114,443.766296 134.951538,445.295471 
	C136.296265,445.622864 137.640991,445.950287 139.842453,446.358765 
	C141.840836,446.429596 143.839218,446.500458 146.105270,446.912567 
M650.241638,913.987976 
	C650.836670,913.690979 651.422119,913.372742 652.028076,913.100037 
	C662.669678,908.311523 669.520569,895.666138 667.659180,885.245728 
	C664.693726,868.644714 652.811340,861.683228 641.198364,861.751038 
	C630.020264,861.816223 621.130493,867.050598 616.422852,877.794067 
	C611.926147,888.056274 613.565735,897.719971 620.588440,906.130188 
	C628.208740,915.256042 638.373657,916.857910 650.241638,913.987976 
M416.610352,174.907440 
	C435.422943,176.830948 449.819061,155.953552 442.800507,138.619980 
	C438.397491,127.746048 426.915070,120.343216 414.251312,121.514793 
	C403.790283,122.482574 392.502991,132.795761 390.933838,143.091171 
	C388.309692,160.308472 399.842041,173.996017 416.610352,174.907440 
M904.114075,618.004700 
	C905.542786,607.435059 902.258057,598.480042 893.800171,592.118286 
	C885.040955,585.529968 875.239502,584.835632 865.244202,589.552856 
	C854.371277,594.684387 848.761719,607.318176 851.487732,620.319336 
	C853.672424,630.738586 865.107422,640.040527 875.987488,640.460144 
	C887.150085,640.890625 900.311035,633.860352 904.114075,618.004700 
z"
      />
      <path
        fill="#F8F8FC"
        opacity="1.000000"
        stroke="none"
        d="
M788.749207,206.319977 
	C790.638611,201.314774 792.316895,196.217285 794.456726,191.321564 
	C801.033386,176.275208 812.550171,166.847794 828.197510,162.284485 
	C853.613892,154.872238 877.751770,165.912979 890.370544,186.463852 
	C901.637024,204.812363 900.397827,231.175385 886.393921,247.427353 
	C880.682190,254.056015 873.451233,259.667297 866.077820,264.458557 
	C861.927185,267.155609 856.078186,267.238770 850.475525,268.702698 
	C845.710571,269.044464 841.464294,269.183014 837.218079,269.321564 
	C833.666931,268.230042 830.069397,267.268890 826.572205,266.025482 
	C806.497803,258.888184 794.190491,244.953583 789.986389,223.982147 
	C789.923523,223.668350 789.696289,223.387482 789.321045,222.538086 
	C788.980652,216.763275 788.864929,211.541626 788.749207,206.319977 
M831.611145,238.363846 
	C834.944153,239.235016 838.235413,240.607681 841.617554,240.889618 
	C855.481750,242.045288 866.678345,233.374557 870.120300,219.190796 
	C872.805359,208.125885 865.265564,194.408981 853.270447,189.673233 
	C851.139038,188.948349 849.059082,187.914856 846.867737,187.549850 
	C834.757202,185.532623 820.786011,194.171387 817.755432,206.407272 
	C814.745056,218.561646 817.524963,230.887268 831.611145,238.363846 
z"
      />
      <path
        fill="#F7F6FC"
        opacity="1.000000"
        stroke="none"
        d="
M203.250839,712.469604 
	C202.711273,714.119263 202.132690,715.336487 201.810440,716.618042 
	C200.511383,721.784241 199.434586,722.338440 194.095428,721.017944 
	C188.965317,719.749146 183.824203,718.168457 178.603333,717.728394 
	C173.875534,717.329834 174.009537,714.737244 174.674194,711.656921 
	C175.999878,705.513000 177.250824,699.318481 179.144470,693.338562 
	C183.421005,679.833740 187.908920,666.389832 192.633057,653.035156 
	C195.081924,646.112305 198.216934,639.432129 201.313049,631.990784 
	C209.860367,635.781616 217.999939,639.391663 226.411377,643.122192 
	C222.933136,651.903076 219.412323,659.881226 216.602234,668.102356 
	C212.555847,679.940369 208.992767,691.944763 205.301712,703.902283 
	C204.477570,706.572266 203.978455,709.342529 203.250839,712.469604 
z"
      />
      <path
        fill="#F7F6FC"
        opacity="1.000000"
        stroke="none"
        d="
M825.225464,367.964905 
	C829.789429,352.401459 834.275696,337.214691 838.440918,321.940399 
	C839.196777,319.168640 840.329468,318.282135 842.882935,318.772003 
	C849.552246,320.051483 856.258301,321.191589 862.839233,322.830963 
	C864.041931,323.130554 865.701599,325.698364 865.483826,326.922668 
	C864.210999,334.078339 862.949036,341.308228 860.736633,348.209564 
	C855.960999,363.106781 850.663513,377.838379 845.463684,392.596924 
	C843.994995,396.765442 842.156311,400.805878 840.427124,404.879517 
	C839.464294,407.147705 838.157898,408.309174 835.406311,406.996124 
	C829.636902,404.243073 823.740112,401.755798 817.883240,399.187805 
	C814.782776,397.828369 813.813721,396.284363 815.455627,392.643188 
	C819.034668,384.706268 821.887878,376.442047 825.225464,367.964905 
z"
      />
      <path
        fill="#F7F6FC"
        opacity="1.000000"
        stroke="none"
        d="
M663.555359,218.182281 
	C656.788513,220.978821 650.366821,223.591400 643.192200,226.510269 
	C639.458252,218.204529 635.845398,210.168121 631.880249,201.348068 
	C640.924316,197.659103 649.397644,193.604706 658.251953,190.719086 
	C675.370605,185.140091 692.683533,180.150711 709.959595,175.065201 
	C715.847290,173.332092 716.565918,173.970474 717.755127,179.819794 
	C718.912048,185.510132 720.155518,191.182846 721.358826,196.863770 
	C721.982544,199.808456 721.132507,200.850708 717.835999,201.782837 
	C699.773315,206.890305 681.863037,212.536926 663.555359,218.182281 
z"
      />
      <path
        fill="#F7F7FC"
        opacity="1.000000"
        stroke="none"
        d="
M330.311981,836.624023 
	C342.735687,833.019348 354.859375,829.804993 366.719543,825.808594 
	C375.907806,822.712524 384.712280,818.489502 393.832886,815.166931 
	C395.135040,814.692627 398.177765,815.999939 398.844604,817.292664 
	C401.859100,823.136169 404.248260,829.298584 406.988495,835.288574 
	C408.341675,838.246399 406.820618,839.738464 404.439697,840.679382 
	C393.567596,844.975647 382.804474,849.616333 371.726318,853.304504 
	C358.912811,857.570312 345.816040,860.988098 332.826447,864.719055 
	C331.240479,865.174500 329.530029,865.182495 327.923309,865.582642 
	C323.945435,866.573425 322.767609,864.933533 322.373230,861.029358 
	C321.843689,855.787231 320.632538,850.578979 319.306091,845.461914 
	C317.895844,840.021606 318.285370,839.215210 323.683289,838.394714 
	C325.793304,838.074036 327.829010,837.264587 330.311981,836.624023 
z"
      />
      <path
        fill="#F7F6FC"
        opacity="1.000000"
        stroke="none"
        d="
M226.476837,861.336914 
	C213.402084,855.216003 201.477112,847.788757 193.143524,836.377502 
	C186.782288,827.666992 181.987762,817.781677 176.849335,808.229065 
	C175.461197,805.648438 176.329941,803.386230 180.050110,802.477417 
	C185.791641,801.074829 191.344086,798.895691 196.972137,797.031921 
	C200.043594,796.014893 202.100662,796.161499 203.474014,799.955688 
	C209.934814,817.805664 222.233719,830.104248 240.043030,836.665283 
	C243.616150,837.981628 244.294556,840.021240 243.294601,843.152649 
	C241.089142,850.059326 238.788315,856.935486 236.308655,864.487305 
	C233.014618,863.424805 229.930435,862.429932 226.476837,861.336914 
z"
      />
      <path
        fill="#1C16AF"
        opacity="1.000000"
        stroke="none"
        d="
M898.433228,769.046875 
	C913.805481,742.453735 917.451660,713.676086 913.912964,683.579224 
	C912.981445,675.656799 911.809937,667.759155 911.073547,659.820007 
	C910.921570,658.181458 911.717041,655.845886 912.917969,654.783630 
	C927.968872,641.470398 934.032410,624.596680 931.199646,605.103638 
	C928.533142,586.755127 918.465149,572.692505 901.445374,564.881592 
	C894.387512,561.642517 886.203491,560.910828 878.630371,558.700317 
	C876.926636,558.203064 875.118164,556.578918 874.233521,554.986938 
	C863.150696,535.042175 852.365845,514.930908 841.198547,495.034210 
	C836.746033,487.101440 831.539368,479.592041 826.550293,471.540161 
	C827.855408,472.019318 829.822388,472.496918 830.632080,473.732758 
	C837.070374,483.559631 843.702637,493.296478 849.480408,503.510620 
	C858.686646,519.786072 867.221741,536.440247 876.230896,552.829712 
	C877.027039,554.277954 879.138367,555.693115 880.781982,555.882751 
	C907.896973,559.011658 927.016968,575.214417 933.379272,600.492371 
	C937.950867,618.655457 934.236877,635.893311 920.862793,649.771484 
	C915.565247,655.268799 914.114197,659.917297 915.262268,668.000793 
	C917.630981,684.677551 917.265564,701.747192 917.885986,718.660339 
	C917.943359,720.223938 917.004150,721.824097 916.141357,723.490967 
	C915.548828,723.325684 915.301819,723.127869 914.963989,722.885254 
	C914.914124,722.789368 914.643982,722.780151 914.643982,722.780151 
	C914.643982,722.780151 914.980103,723.014771 915.109131,723.215637 
	C915.441833,723.666992 915.685913,723.868652 915.970337,724.021423 
	C916.167236,734.767639 912.920410,744.599976 908.162292,754.075928 
	C905.984924,758.412170 904.233887,762.963989 902.013306,767.275818 
	C901.538513,768.197754 899.868103,768.503967 898.433228,769.046875 
M932.517456,609.455505 
	C932.517456,609.455505 932.542175,609.512268 932.517456,609.455505 
M933.460266,617.494019 
	C933.460266,617.494019 933.516418,617.469177 933.460266,617.494019 
M914.470581,679.490356 
	C914.470581,679.490356 914.526550,679.463928 914.470581,679.490356 
z"
      />
      <path
        fill="#635EBD"
        opacity="1.000000"
        stroke="none"
        d="
M315.994690,110.421005 
	C312.566132,111.305016 308.687134,112.052292 304.788940,112.680847 
	C300.721008,113.336777 296.634613,113.878326 292.279236,114.303490 
	C292.668488,113.508743 293.282196,112.406151 294.009277,112.325493 
	C301.174011,111.530586 308.357544,110.905403 315.994690,110.421005 
z"
      />
      <path
        fill="#635EBD"
        opacity="1.000000"
        stroke="none"
        d="
M710.004761,915.578247 
	C713.694885,914.679260 717.837708,913.929871 721.994873,913.271057 
	C725.686035,912.686035 729.393066,912.201477 733.507080,911.779297 
	C733.427551,912.477783 732.984863,913.530457 732.432800,913.591309 
	C725.118713,914.397949 717.789307,915.065186 710.004761,915.578247 
z"
      />
      <path
        fill="#0100A8"
        opacity="1.000000"
        stroke="none"
        d="
M632.010742,943.427856 
	C637.902527,943.145264 643.469543,943.025757 649.376099,943.065552 
	C650.304810,943.516968 650.894043,943.809021 652.311646,944.511658 
	C650.495056,945.115417 649.264404,945.870056 648.031311,945.874207 
	C642.774658,945.892029 637.516357,945.711609 632.263916,945.481750 
	C632.044128,945.472168 631.877380,944.248840 632.010742,943.427856 
z"
      />
      <path
        fill="#0100A8"
        opacity="1.000000"
        stroke="none"
        d="
M788.576599,205.984833 
	C788.864929,211.541626 788.980652,216.763275 788.947998,222.319519 
	C788.437134,223.346924 788.074585,224.039734 787.110657,225.881882 
	C785.415222,218.192200 785.735168,211.636398 786.584106,205.054642 
	C787.190735,205.252991 787.797363,205.451340 788.576599,205.984833 
z"
      />
      <path
        fill="#0500A8"
        opacity="1.000000"
        stroke="none"
        d="
M836.887512,269.479340 
	C841.464294,269.183014 845.710571,269.044464 850.280273,269.058716 
	C851.541016,269.528534 852.478271,269.845520 853.384521,270.152008 
	C850.535767,272.134766 838.141235,272.889618 835.742432,271.126709 
	C835.681763,271.082153 836.270874,270.153442 836.887512,269.479340 
z"
      />
      <path
        fill="#635EBD"
        opacity="1.000000"
        stroke="none"
        d="
M345.877655,112.509369 
	C341.368469,112.289085 336.687134,111.747993 331.723175,110.946304 
	C336.321503,109.991142 341.242889,108.912361 345.877655,112.509369 
z"
      />
      <path
        fill="#635EBD"
        opacity="1.000000"
        stroke="none"
        d="
M736.055908,911.755127 
	C737.493042,910.982117 739.339539,910.346619 741.550842,909.795898 
	C740.098877,910.551331 738.282166,911.221985 736.055908,911.755127 
z"
      />
      <path
        fill="#635EBD"
        opacity="1.000000"
        stroke="none"
        d="
M289.944153,114.244843 
	C288.507111,115.017570 286.660614,115.652855 284.449524,116.203293 
	C285.901489,115.448112 287.718140,114.777763 289.944153,114.244843 
z"
      />
      <path
        fill="#635EBD"
        opacity="1.000000"
        stroke="none"
        d="
M689.026855,915.632324 
	C690.076782,915.237976 691.626709,914.991577 693.383301,914.989258 
	C692.235596,915.415649 690.881226,915.597961 689.026855,915.632324 
z"
      />
      <path
        fill="#635EBD"
        opacity="1.000000"
        stroke="none"
        d="
M744.013550,909.736938 
	C745.138489,908.934937 746.640686,908.263367 748.494507,907.664795 
	C747.361023,908.447632 745.875916,909.157471 744.013550,909.736938 
z"
      />
      <path
        fill="#635EBD"
        opacity="1.000000"
        stroke="none"
        d="
M281.986572,116.263168 
	C280.862030,117.065742 279.360229,117.737831 277.506775,118.337227 
	C278.639862,117.553917 280.124603,116.843300 281.986572,116.263168 
z"
      />
      <path
        fill="#635EBD"
        opacity="1.000000"
        stroke="none"
        d="
M275.983887,118.260551 
	C275.179352,119.007370 273.997864,119.628143 272.479553,120.229584 
	C273.297485,119.518349 274.452209,118.826431 275.983887,118.260551 
z"
      />
      <path
        fill="#635EBD"
        opacity="1.000000"
        stroke="none"
        d="
M750.015869,907.739258 
	C750.820496,906.992004 752.002075,906.370605 753.520508,905.769409 
	C752.702576,906.481445 751.547729,907.173279 750.015869,907.739258 
z"
      />
      <path
        fill="#635EBD"
        opacity="1.000000"
        stroke="none"
        d="
M271.054260,120.314957 
	C270.799561,120.698250 270.222717,120.945923 269.332092,121.119415 
	C269.589569,120.756607 270.160797,120.467972 271.054260,120.314957 
z"
      />
      <path
        fill="#635EBD"
        opacity="1.000000"
        stroke="none"
        d="
M754.945862,905.684998 
	C755.200256,905.300842 755.776855,905.052307 756.667236,904.878418 
	C756.409973,905.242310 755.839050,905.531433 754.945862,905.684998 
z"
      />
      <path
        fill="#1B0FAB"
        opacity="1.000000"
        stroke="none"
        d="
M591.934204,264.146820 
	C594.049377,266.027130 596.164551,267.907471 598.720581,270.216675 
	C600.811951,271.676666 602.462463,272.707855 604.112976,273.739014 
	C604.112976,273.739014 604.103943,273.894653 604.224365,274.207245 
	C605.907654,275.656921 607.470642,276.793976 609.033569,277.931030 
	C609.033569,277.931030 609.002686,277.989655 609.093689,278.302307 
	C610.812134,279.699127 612.439575,280.783264 614.067017,281.867401 
	C614.067017,281.867401 614.056641,281.943085 614.119263,282.238495 
	C614.824951,282.936981 615.467957,283.340027 616.110962,283.743073 
	C616.110962,283.743073 616.102051,283.895996 616.227661,284.206146 
	C617.913330,285.654572 619.473450,286.792877 621.033508,287.931152 
	C621.033508,287.931152 621.003235,287.990082 621.097656,288.304199 
	C623.499207,290.324310 625.806335,292.030273 628.113525,293.736267 
	C628.113525,293.736267 628.104919,293.894806 628.204895,294.185242 
	C628.906616,294.899170 629.508423,295.322662 630.110168,295.746155 
	C630.110168,295.746155 630.101501,295.897064 630.230713,296.212891 
	C635.610962,300.932007 640.862061,305.335297 646.113098,309.738556 
	C646.113098,309.738556 646.104248,309.895233 646.203979,310.185699 
	C646.906311,310.898102 647.508789,311.320007 648.111328,311.741943 
	C648.111328,311.741943 648.102539,311.896301 648.201599,312.180023 
	C648.912598,312.849670 649.524475,313.235565 650.136414,313.621490 
	C650.136414,313.621490 650.137817,313.859161 650.299438,314.178986 
	C664.048828,327.852570 677.664429,341.178345 691.213135,354.571686 
	C698.248901,361.526764 705.166504,368.601379 712.137451,375.622009 
	C712.137451,375.622009 712.138855,375.859833 712.276001,376.149658 
	C712.979492,376.873077 713.545715,377.306702 714.112000,377.740295 
	C714.112000,377.740295 714.102844,377.895660 714.201599,378.186523 
	C714.904541,378.897003 715.508789,379.316620 716.112976,379.736267 
	C716.112976,379.736267 716.103821,379.894012 716.216125,380.225220 
	C720.922729,385.619110 725.517090,390.681824 730.111450,395.744537 
	C730.111450,395.744537 730.102234,395.896851 730.201538,396.187347 
	C730.904968,396.896942 731.509155,397.316040 732.113342,397.735138 
	C732.113342,397.735138 732.104126,397.893616 732.217773,398.226929 
	C735.591614,402.288086 738.851685,406.015961 742.111755,409.743835 
	C742.111755,409.743835 742.102722,409.896759 742.203613,410.193115 
	C742.891846,410.948700 743.479309,411.407928 744.066772,411.867157 
	C744.066772,411.867157 744.056458,411.942871 744.123413,412.284882 
	C746.831116,415.664734 749.471924,418.702576 752.112793,421.740417 
	C752.112793,421.740387 752.103760,421.895172 752.207825,422.225189 
	C753.552368,424.014069 754.792786,425.472961 756.033264,426.931824 
	C756.033264,426.931824 756.011230,426.995697 756.116455,427.304443 
	C757.518555,428.989441 758.815369,430.365662 760.112183,431.741882 
	C760.112183,431.741882 760.102905,431.895660 760.205322,432.236816 
	C762.893677,435.675629 765.479614,438.773315 768.065613,441.870972 
	C768.065613,441.870972 768.052429,441.946167 768.102783,442.268555 
	C768.780457,443.369904 769.407837,444.148865 770.035217,444.927826 
	C770.035217,444.927826 770.013733,444.993622 770.118530,445.305054 
	C771.503906,447.035278 772.784363,448.454102 774.064819,449.872955 
	C774.064819,449.872955 774.049622,449.947784 774.099731,450.270142 
	C774.777893,451.371490 775.406006,452.150513 776.034119,452.929504 
	C776.034119,452.929504 776.012695,452.994507 776.122314,453.315918 
	C778.451721,456.503265 780.671448,459.369232 782.891235,462.235199 
	C783.202148,462.713623 783.513123,463.192078 784.122192,464.122681 
	C788.310303,470.498291 792.200195,476.421692 796.090149,482.345123 
	C795.703186,482.747711 795.316223,483.150330 794.929260,483.552917 
	C792.607544,482.632172 790.285828,481.711395 787.752686,480.536194 
	C787.338135,480.195923 787.135010,480.110046 786.856323,479.697876 
	C784.253418,477.832397 781.700989,476.332611 779.203796,474.745911 
	C773.825684,471.328613 773.208252,471.557465 770.513306,478.271179 
	C770.184631,478.670166 770.103455,478.867065 769.708130,479.129181 
	C769.014221,480.158051 768.634521,481.121704 767.969421,482.200806 
	C767.496521,482.663849 767.309021,483.011383 766.862000,483.543823 
	C761.090027,492.162506 755.577576,500.596313 750.036865,509.015686 
	C750.008606,509.001282 750.028931,509.061310 749.712280,509.120605 
	C749.015259,510.148315 748.634949,511.116730 747.956543,512.208008 
	C747.113220,513.242188 746.568054,514.153503 745.699219,515.135132 
	C743.646790,517.832214 741.918091,520.458862 740.135254,523.082031 
	C740.081116,523.078552 740.088379,523.186768 739.777832,523.244202 
	C739.057251,524.235168 738.647217,525.168762 738.165161,526.099365 
	C738.093140,526.096436 738.104126,526.240173 737.786133,526.299805 
	C735.731812,528.935730 733.995422,531.511963 731.977234,534.184814 
	C731.431702,534.783447 731.167908,535.285400 730.613403,535.945190 
	C724.072754,542.922119 724.184814,543.642578 732.237732,547.923340 
	C733.261414,548.810242 734.161499,549.394287 735.126831,550.297607 
	C736.830261,551.662964 738.468384,552.708984 740.102173,553.828918 
	C740.097839,553.902771 740.245544,553.894104 740.302734,554.210876 
	C742.252197,556.087952 744.144409,557.648193 746.945251,559.957703 
	C749.295288,556.483032 751.523926,553.187866 753.827454,549.897034 
	C753.902283,549.901306 753.893372,549.751648 754.211731,549.694946 
	C756.266357,547.062561 758.002563,544.486938 760.040527,541.794739 
	C760.886902,540.763000 761.431641,539.847839 762.299072,538.858032 
	C764.993652,535.159180 767.365601,531.535095 770.039124,527.794312 
	C770.886108,526.763062 771.431580,525.848511 772.291016,524.864380 
	C772.988831,523.828491 773.372559,522.862183 773.831543,521.897156 
	C773.906860,521.898499 773.892090,521.748657 774.210083,521.688354 
	C775.647278,519.732910 776.766541,517.837830 777.923035,515.950317 
	C777.960266,515.957886 777.941528,515.884338 778.266113,515.821289 
	C782.307983,509.810120 786.025269,503.862030 790.041199,497.791626 
	C790.885864,496.757507 791.431946,495.845764 792.305420,494.853577 
	C794.370544,491.657654 796.108337,488.542236 797.836304,485.444366 
	C798.715637,485.988098 799.392090,486.406433 800.068604,486.824738 
	C800.138611,487.037048 800.208679,487.249329 800.473145,487.957581 
	C801.149292,488.882416 801.631104,489.311310 802.112854,489.740204 
	C802.112854,489.740204 802.096802,489.892334 802.183960,490.251801 
	C804.869812,494.363556 807.468445,498.115875 810.067078,501.868164 
	C810.067078,501.868164 810.051208,501.942841 810.106812,502.302826 
	C811.001038,504.250153 811.746094,505.895935 812.693970,507.415131 
	C817.790405,515.583801 822.937134,523.721008 828.066528,531.869080 
	C828.066528,531.869080 828.050354,531.943604 828.107910,532.309692 
	C832.815552,541.030579 837.465698,549.385376 842.115845,557.740234 
	C842.115845,557.740234 842.096863,557.890564 842.178467,558.265137 
	C843.530640,562.104980 844.801147,565.570312 846.071655,569.035645 
	C846.071655,569.035645 846.010498,569.005127 845.692261,569.102417 
	C843.667664,570.836914 841.961304,572.474121 840.254944,574.111389 
	C840.254944,574.111389 840.102966,574.102539 839.812866,574.201904 
	C839.098755,574.903931 838.674805,575.506531 838.250854,576.109131 
	C838.250854,576.109131 838.101990,576.100281 837.792114,576.225159 
	C836.346436,577.912170 835.210693,579.474304 834.075012,581.036438 
	C834.075012,581.036438 834.013306,581.005615 833.695312,581.099243 
	C831.564148,584.439148 829.518372,587.580627 827.996033,590.958008 
	C826.044128,595.288513 824.513367,599.808838 822.589600,603.964966 
	C822.112976,603.559204 821.847839,603.435852 821.582703,603.312500 
	C821.413635,609.976990 821.244568,616.641479 821.075500,623.306030 
	C821.678772,623.109680 822.282043,622.913391 823.255615,622.925049 
	C825.455750,630.121094 826.861145,637.290955 832.062805,642.835144 
	C832.632385,643.764526 833.201965,644.693909 834.105591,646.084473 
	C837.664062,649.610107 840.888489,652.674561 844.112976,655.739075 
	C844.112976,655.739075 844.104004,655.894714 844.228760,656.214600 
	C853.565735,663.867676 863.929504,668.145813 875.828613,667.629639 
	C879.957764,667.450562 881.502869,669.205627 881.909424,672.856079 
	C882.460327,677.801392 883.047180,682.742554 883.614807,687.685974 
	C885.256836,701.987488 884.710266,716.191833 880.950989,730.112427 
	C879.123413,736.880005 876.512756,743.435974 874.256714,750.087769 
	C873.927551,750.316101 873.598389,750.544312 872.941895,751.243469 
	C871.827637,753.179749 871.040710,754.645142 870.253784,756.110474 
	C870.253784,756.110474 870.102966,756.101624 869.793579,756.226440 
	C868.347839,757.909912 867.211426,759.468506 866.075073,761.027100 
	C865.124695,761.841614 864.174377,762.656006 862.790649,763.854980 
	C854.239014,770.839600 845.254639,775.730957 834.896118,777.745239 
	C833.007263,777.154114 831.199768,776.640991 829.431885,776.752808 
	C825.471985,777.003174 821.533081,777.823608 817.582764,777.843445 
	C816.377869,777.849426 814.560852,776.229553 814.077454,774.954895 
	C813.028015,772.187866 812.689392,769.155945 812.004150,766.245483 
	C809.141235,754.085815 806.308716,741.918274 803.267151,729.803284 
	C803.063293,728.991272 801.219910,728.590942 799.946228,727.643188 
	C799.058594,725.085022 798.497375,722.829834 797.648132,720.688965 
	C789.877808,701.102844 782.746765,681.225586 774.092529,662.036194 
	C760.572083,632.056702 743.798096,603.803345 725.528076,576.463135 
	C720.861633,569.479919 715.910889,562.686646 711.090759,555.806152 
	C710.787659,555.316284 710.484497,554.826477 709.881592,553.880981 
	C707.041992,549.975769 704.502319,546.526306 701.962585,543.076782 
	C701.962585,543.076782 701.983459,543.009094 701.873901,542.687317 
	C699.539734,539.512573 697.315063,536.659668 695.090393,533.806702 
	C694.785706,533.316956 694.481018,532.827148 693.865234,531.889771 
	C690.338684,527.358704 687.123291,523.275146 683.907898,519.191650 
	C683.907898,519.191650 683.915405,519.082275 683.821777,518.755127 
	C682.448242,517.037354 681.168335,515.646667 679.888428,514.255981 
	C679.888428,514.255981 679.897400,514.103455 679.792114,513.773865 
	C678.446045,511.987061 677.205261,510.529846 675.964417,509.072662 
	C675.964417,509.072662 675.986511,509.006836 675.881714,508.697754 
	C674.480591,507.011627 673.184265,505.634552 671.887939,504.257507 
	C671.887939,504.257507 671.896912,504.104248 671.782593,503.771667 
	C669.259338,500.416229 666.850464,497.393372 664.027832,493.851318 
	C662.561707,495.571289 661.468140,496.854187 660.374634,498.137085 
	C660.374634,498.137085 660.137695,498.136627 659.848389,498.272827 
	C659.125671,498.976654 658.692139,499.544220 658.258667,500.111816 
	C658.258667,500.111816 658.103943,500.102844 657.813232,500.201355 
	C657.101501,500.903900 656.680481,501.507935 656.259460,502.112000 
	C656.259460,502.112000 656.104614,502.103027 655.780212,502.216125 
	C653.508362,504.088318 651.560913,505.847473 649.613403,507.606628 
	C649.740662,507.737305 649.867859,507.868042 649.486938,507.913666 
	C647.520020,510.034332 642.944031,510.714264 645.106018,514.620728 
	C646.220337,516.634216 648.409363,518.052979 650.112793,519.740479 
	C650.112793,519.740479 650.103821,519.895447 650.207886,520.225464 
	C651.552368,522.014282 652.792847,523.473083 654.033325,524.931946 
	C654.033325,524.931946 654.011292,524.995911 654.116577,525.304626 
	C655.518616,526.989563 656.815430,528.365784 658.112183,529.742004 
	C658.112183,529.742004 658.103027,529.895874 658.205383,530.236938 
	C660.893677,533.675598 663.479553,536.773254 666.065491,539.870850 
	C666.065491,539.870850 666.053650,539.946228 666.108521,540.258301 
	C666.806274,541.316345 667.449219,542.062378 668.092163,542.808411 
	C668.092163,542.808411 668.084595,542.917786 668.184570,543.256042 
	C672.220398,548.665649 676.156250,553.737061 680.092163,558.808411 
	C680.092163,558.808411 680.084534,558.917847 680.183472,559.259338 
	C683.552246,564.003357 686.822205,568.405884 690.092163,572.808411 
	C690.092163,572.808411 690.084534,572.917847 690.184143,573.260498 
	C697.232300,583.944031 704.180847,594.284973 711.129395,604.625916 
	C711.317932,604.974304 711.506531,605.322693 711.985840,606.149902 
	C721.364258,622.702209 731.138306,638.433594 739.361267,654.937805 
	C749.045898,674.375549 757.923767,694.264282 766.110779,714.380920 
	C771.115173,726.677551 774.529846,739.672607 777.963135,752.539490 
	C780.330505,761.411560 781.507263,770.601318 783.377869,780.505371 
	C777.136963,779.612793 771.834839,779.338440 766.796265,778.041016 
	C752.908325,774.464539 738.854370,771.234863 725.377563,766.432617 
	C707.745911,760.149780 690.456360,752.828613 673.303894,745.305969 
	C661.591125,740.168945 650.364258,733.924255 638.920105,728.174805 
	C638.920105,728.174805 638.926208,728.075256 638.809937,727.752869 
	C634.117554,724.977722 629.541504,722.524902 624.965393,720.072144 
	C624.965393,720.072144 624.993164,720.010132 624.888794,719.694031 
	C621.511230,717.609619 618.238037,715.841248 614.964783,714.072937 
	C614.964783,714.072937 614.992737,714.010498 614.888184,713.694702 
	C612.681580,712.147400 610.577087,710.920349 608.478333,709.683472 
	C604.287170,707.213562 600.099182,704.738403 595.909912,702.265442 
	C595.119263,701.584290 594.328613,700.903137 593.165405,699.811646 
	C591.831848,699.022705 590.870911,698.644104 589.909973,698.265503 
	C589.119324,697.584351 588.328613,696.903137 587.165405,695.811646 
	C585.831848,695.022766 584.870911,694.644165 583.909973,694.265503 
	C583.119385,693.585266 582.328796,692.905090 581.160767,691.799744 
	C576.082947,687.122009 571.213318,684.958313 564.763672,688.205444 
	C561.639465,689.778259 557.782898,689.977173 554.221375,690.583252 
	C547.471375,691.731873 540.695129,692.725464 533.929749,693.783447 
	C533.714722,694.195923 533.499695,694.608459 533.284668,695.020996 
	C535.562317,696.550659 537.839966,698.080383 540.117554,699.610107 
	C540.895325,700.329468 541.673096,701.048828 542.823853,702.187622 
	C544.152283,703.029602 545.107788,703.452148 546.063354,703.874634 
	C546.063354,703.874634 546.051941,703.950073 546.146179,704.267090 
	C548.857727,706.299805 551.474976,708.015442 554.092285,709.731079 
	C554.879822,710.412537 555.667358,711.093933 556.828796,712.189575 
	C558.156860,713.025818 559.110962,713.447815 560.065125,713.869873 
	C560.065125,713.869873 560.057678,713.945801 560.160095,714.261292 
	C563.214844,716.680115 566.076904,718.926575 569.137756,720.857910 
	C576.070068,725.231812 583.100342,729.450439 590.090820,733.732178 
	C590.910645,734.404419 591.730408,735.076660 592.932373,736.163208 
	C596.554138,738.361938 599.793579,740.146423 603.033020,741.930969 
	C603.033020,741.930969 603.005310,741.991516 603.107910,742.307007 
	C606.484558,744.392517 609.758545,746.162415 613.032532,747.932373 
	C613.032532,747.932373 613.004517,747.992310 613.109009,748.309692 
	C615.511658,749.667908 617.809814,750.708679 620.108032,751.749512 
	C620.108032,751.749512 620.106384,751.900146 620.243042,752.214844 
	C639.993591,762.492920 659.607422,772.456299 679.102295,782.744995 
	C679.859985,784.083252 680.544373,785.642151 681.641602,786.028442 
	C702.533691,793.383606 723.305054,801.154907 744.474609,807.617798 
	C755.446716,810.967529 767.156250,811.874817 778.488831,814.108032 
	C779.726257,814.351929 781.736023,816.254150 781.594482,817.079651 
	C779.506409,829.255493 778.207092,841.650757 771.615112,852.615967 
	C766.218079,861.593445 759.585510,869.185486 750.191833,874.131836 
	C735.403503,881.918701 719.567627,884.512634 703.021973,884.096436 
	C699.503235,884.007874 697.945984,883.186218 697.740723,879.438965 
	C697.580383,876.511902 697.035645,873.205933 695.464661,870.875366 
	C694.064270,868.798096 695.561157,863.462891 690.130188,864.997742 
	C682.259949,849.557007 669.697144,839.956238 653.287476,834.804199 
	C653.620422,833.848633 653.830811,833.384644 654.041138,832.920654 
	C651.924500,832.643311 649.812866,832.184265 647.690247,832.126587 
	C643.267334,832.006409 638.837524,832.041016 634.412720,832.126343 
	C631.996521,832.172913 629.584717,832.452881 627.170959,832.627625 
	C627.215515,833.001465 627.260071,833.375366 627.304626,833.749207 
	C627.904968,834.028076 628.505310,834.306946 629.105591,834.585815 
	C626.435730,835.766907 623.820618,837.096680 621.086304,838.102600 
	C610.959106,841.827759 602.848450,848.174255 596.779419,857.010498 
	C594.443726,860.411072 592.805725,861.120056 588.425903,859.276123 
	C559.774109,847.213501 532.400696,832.652100 506.386353,815.842224 
	C482.062958,800.124939 458.480743,783.180359 435.264313,765.848999 
	C399.148560,738.887939 365.988251,708.444397 334.524139,676.231445 
	C301.202087,642.116394 269.776367,606.283936 242.393021,567.170593 
	C227.222809,545.502075 212.981766,523.162170 198.896194,500.763733 
	C191.890015,489.622711 186.062332,477.727509 180.022354,466.008118 
	C179.244415,464.498657 179.616653,461.148590 180.775925,460.071045 
	C210.070801,432.840729 202.026352,386.573700 166.783829,370.034729 
	C165.883270,369.612122 164.997009,369.159058 164.178619,368.399109 
	C165.177765,367.834167 166.102356,367.590302 167.975479,367.096222 
	C162.260010,365.455231 157.683823,363.784912 152.961533,362.863190 
	C144.386017,361.189453 145.189209,361.408936 143.901306,351.710327 
	C143.591476,349.377075 142.024872,347.210754 141.102814,344.557617 
	C141.448303,341.532837 141.949081,338.919769 141.969543,336.302979 
	C142.086929,321.288025 141.083038,306.201935 145.527679,291.505615 
	C145.812759,290.562958 144.301270,289.076965 143.625519,287.843750 
	C147.409592,277.243164 152.808502,267.749084 161.228516,259.988831 
	C171.174026,250.822647 183.040573,245.978241 196.036163,243.477371 
	C201.847504,242.359024 207.746002,241.693512 213.718399,240.805634 
	C215.359589,249.922943 216.488617,258.270538 218.414505,266.430054 
	C221.679535,280.263092 224.776947,294.194275 229.143326,307.696655 
	C238.298294,336.007111 250.534073,363.074280 264.561646,389.299988 
	C286.070587,429.512695 311.149506,467.417511 339.118347,503.414673 
	C346.137390,512.448425 353.383667,521.308167 360.667664,530.130554 
	C361.648926,531.319092 363.466492,531.817017 365.460114,532.718933 
	C366.681061,531.821167 367.872009,530.858032 367.907318,529.854187 
	C368.094879,524.528015 368.152985,519.183838 367.895111,513.863464 
	C367.814911,512.208130 366.606873,510.607513 365.915161,508.981873 
	C366.520691,503.897461 367.278900,498.823914 367.643097,493.722260 
	C367.759399,492.093719 367.253479,490.091705 366.316742,488.766235 
	C351.612915,467.960358 336.677124,447.317657 322.060242,426.451416 
	C316.951233,419.158112 312.561554,411.360901 308.077148,403.298798 
	C307.906250,400.770721 307.955292,398.494995 307.019836,396.745758 
	C302.482086,388.260193 297.364716,380.074463 293.045227,371.484619 
	C286.399170,358.267975 279.940338,344.939789 273.937317,331.421265 
	C264.660126,310.529297 257.529663,288.865601 252.403564,266.581360 
	C250.903519,260.060364 250.044678,253.391876 248.838562,246.485321 
	C250.324554,246.416595 250.989243,246.235931 251.573303,246.380676 
	C267.550049,250.340332 283.541473,254.244507 299.476562,258.366302 
	C305.415497,259.902466 311.315002,261.705017 317.057281,263.854279 
	C322.386688,265.848969 327.444519,268.560730 332.719849,270.717133 
	C333.569458,271.064392 334.930359,270.160858 336.410858,269.866699 
	C350.913208,276.907654 365.119568,283.799774 379.184937,290.968475 
	C388.571289,295.752502 397.756897,300.930481 407.032349,305.932129 
	C407.032349,305.932129 407.004639,305.992065 407.108307,306.308685 
	C411.504913,308.994232 415.797821,311.363159 420.090759,313.732086 
	C420.910553,314.404419 421.730347,315.076721 422.932556,316.163483 
	C425.411255,317.850159 427.549133,319.059204 429.596497,320.405853 
	C437.425262,325.555206 445.224945,330.748779 453.035492,335.925842 
	C453.035492,335.925842 453.005402,335.987000 453.092834,336.294373 
	C454.150513,336.978394 455.120697,337.354980 456.090881,337.731567 
	C456.879944,338.411774 457.669037,339.091949 458.832397,340.196228 
	C461.816528,342.388550 464.426300,344.156738 467.036102,345.924957 
	C467.036102,345.924957 467.005829,345.986572 467.094757,346.295135 
	C468.159058,346.983826 469.134399,347.363953 470.109772,347.744080 
	C470.109772,347.744080 470.106079,347.899170 470.234314,348.212830 
	C472.945312,350.265808 475.528076,352.005157 478.110870,353.744476 
	C478.110870,353.744476 478.103088,353.897125 478.229248,354.213867 
	C480.925385,356.310547 483.495361,358.090515 486.065338,359.870483 
	C486.065338,359.870453 486.053436,359.945923 486.137146,360.262024 
	C487.825562,361.694885 489.430267,362.811646 491.034973,363.928375 
	C491.034973,363.928375 491.003540,363.987885 491.085785,364.300354 
	C493.038116,365.368286 494.868530,366.597748 496.788361,366.758362 
	C499.150330,366.955994 501.586853,366.262238 504.199463,366.291504 
	C505.255005,367.066956 506.097290,367.873444 506.950226,367.884857 
	C514.840942,367.990631 522.735901,368.054321 530.622925,367.859497 
	C531.735291,367.832031 532.811707,366.348206 533.895508,365.275177 
	C532.984070,363.877533 532.081726,362.743195 531.179443,361.608856 
	C531.152527,361.263763 530.975281,361.035461 530.262085,360.620758 
	C527.896118,358.921295 525.915588,357.525024 523.935120,356.128754 
	C523.935120,356.128754 523.946350,356.053284 523.882935,355.758881 
	C523.175903,355.062622 522.532166,354.660767 521.888489,354.258911 
	C521.888489,354.258911 521.897400,354.104584 521.772705,353.794189 
	C520.086121,352.348389 518.524170,351.212921 516.962219,350.077454 
	C516.962219,350.077454 516.993164,350.014923 516.902100,349.704285 
	C515.170288,348.347961 513.529419,347.302246 511.888580,346.256531 
	C511.888580,346.256531 511.897491,346.103821 511.770416,345.786804 
	C509.074707,343.687744 506.506073,341.905701 503.937439,340.123657 
	C503.937439,340.123688 503.951385,340.048615 503.887085,339.763245 
	C503.176086,339.116699 502.529358,338.755524 501.882629,338.394379 
	C501.104492,337.673737 500.326385,336.953094 499.176697,335.810913 
	C497.172180,334.350555 495.539215,333.311646 493.906281,332.272736 
	C493.118073,331.590485 492.329865,330.908234 491.174011,329.808472 
	C489.164948,328.391174 487.523499,327.391388 485.882050,326.391602 
	C485.103485,325.671814 484.324921,324.952057 483.166321,323.805969 
	C479.160034,321.009338 475.533783,318.639099 471.907532,316.268860 
	C471.118561,315.587219 470.329590,314.905609 469.157837,313.804077 
	C464.963654,310.755402 461.228851,308.006104 457.324097,305.524170 
	C450.239288,301.020874 443.053406,296.676758 435.909698,292.266174 
	C435.089447,291.594543 434.269196,290.922913 433.066528,289.837097 
	C429.444214,287.640137 426.204285,285.857330 422.964355,284.074554 
	C422.964355,284.074554 422.992096,284.011383 422.888672,283.695984 
	C419.511993,281.611176 416.238739,279.841736 412.965515,278.072327 
	C412.965515,278.072327 412.993286,278.010132 412.888214,277.693115 
	C410.485657,276.335388 408.188202,275.294647 405.890717,274.253906 
	C405.890717,274.253906 405.895050,274.102142 405.759888,273.785400 
	C386.009125,263.506470 366.393494,253.544250 346.897400,243.252884 
	C346.779236,242.074890 346.762756,240.650116 346.271454,240.461395 
	C333.513916,235.560852 320.806030,230.497101 307.870880,226.105713 
	C300.312958,223.539902 292.366180,222.130356 284.617493,220.111710 
	C279.444397,218.764038 274.352539,217.085846 269.150024,215.874954 
	C264.007172,214.677933 258.786896,213.782852 253.570435,212.942810 
	C249.596069,212.302780 247.369690,210.765350 247.840775,206.150162 
	C248.041016,204.188202 246.594681,202.058167 245.852158,199.621460 
	C249.179810,189.813492 252.551956,180.388565 255.924103,170.963654 
	C255.924103,170.963654 255.987854,170.991211 256.292969,170.896896 
	C256.979736,169.831619 257.361359,168.860657 257.742981,167.889694 
	C257.742950,167.889694 257.897675,167.895721 258.208740,167.775696 
	C259.653534,166.094086 260.787262,164.532486 261.920990,162.970871 
	C262.224731,162.793518 262.528503,162.616180 263.221649,162.084259 
	C265.654449,159.782288 267.697815,157.834900 269.741211,155.887527 
	C269.741211,155.887527 269.895508,155.896683 270.229980,155.795105 
	C272.289978,154.422699 274.015503,153.151871 275.741058,151.881042 
	C275.741058,151.881058 275.889923,151.900391 276.269592,151.817886 
	C304.512360,138.405945 333.320862,140.231674 362.236359,146.005203 
	C363.017395,151.142746 363.350555,155.979965 364.542603,160.595367 
	C366.674713,168.850784 369.616608,176.820953 376.033356,182.932236 
	C376.033356,182.932236 376.011017,182.995834 376.116760,183.306854 
	C377.457611,184.933792 378.539886,186.450073 379.955139,187.529922 
	C385.234436,191.557983 390.622925,195.442917 395.715393,199.579666 
	C396.973541,200.518738 398.486786,201.259674 400.239014,202.274170 
	C404.065399,203.641525 407.635895,205.563950 411.243988,205.637283 
	C416.824982,205.750717 422.431671,204.600906 428.447327,203.980133 
	C438.076782,203.692749 445.210907,198.616287 452.324951,193.622849 
	C453.022125,193.133484 452.804901,191.341461 453.190277,189.924759 
	C453.712738,189.258774 454.057068,188.822144 454.401367,188.385498 
	C454.283569,188.257919 454.165771,188.130325 454.522583,188.097778 
	C456.128418,186.924789 457.259644,185.656784 458.390839,184.388763 
	C458.260956,184.259171 458.131073,184.129593 458.484161,184.086090 
	C459.265778,183.425339 459.564453,182.678497 459.863098,181.931671 
	C459.863129,181.931671 459.939178,181.939316 460.332764,181.876938 
	C467.953766,185.248093 475.251343,188.543091 482.389221,192.153656 
	C489.710510,195.856979 496.875671,199.868988 504.109161,203.745911 
	C504.109161,203.745911 504.106506,203.898636 504.242676,204.212860 
	C507.622223,206.266663 510.865662,208.006241 514.109070,209.745834 
	C514.109070,209.745834 514.106262,209.895157 514.251099,210.213928 
	C519.627625,213.599167 524.859192,216.665634 530.090820,219.732086 
	C530.879761,220.413620 531.668762,221.095123 532.829041,222.189148 
	C534.163879,222.978302 535.127380,223.354935 536.090881,223.731552 
	C536.879822,224.413239 537.668701,225.094910 538.828979,226.189148 
	C540.163879,226.978302 541.127380,227.354935 542.090881,227.731552 
	C542.879822,228.413223 543.668701,229.094910 544.830200,230.190384 
	C546.171509,230.984726 547.140259,231.365265 548.109009,231.745819 
	C548.109009,231.745819 548.106506,231.900146 548.238770,232.212479 
	C552.944824,235.593536 557.518555,238.662262 562.092285,241.730972 
	C562.880615,242.411194 563.669006,243.091400 564.831970,244.195923 
	C567.816467,246.388504 570.426270,248.156799 573.036133,249.925110 
	C573.036133,249.925110 573.005554,249.986557 573.094849,250.294373 
	C574.144043,251.025650 575.104065,251.449127 576.064026,251.872620 
	C576.064026,251.872620 576.052979,251.948181 576.143188,252.260849 
	C577.834595,253.690338 579.435791,254.807159 581.036987,255.923996 
	C581.036987,255.923996 581.006042,255.985947 581.092773,256.295532 
	C582.141357,257.027008 583.103149,257.448914 584.064941,257.870789 
	C584.064941,257.870789 584.055481,257.946472 584.119873,258.232178 
	C584.828491,258.881287 585.472717,259.244629 586.116882,259.608002 
	C586.894348,260.327087 587.671814,261.046173 588.821167,262.197876 
	C590.106812,263.135956 591.020508,263.641388 591.934204,264.146820 
M401.996826,593.730835 
	C402.260315,594.858093 402.523773,595.985352 402.787231,597.112610 
	C403.061188,597.068665 403.335144,597.024719 403.609131,596.980774 
	C403.795502,595.872986 403.981903,594.765198 404.576172,594.245667 
	C406.628296,598.208984 408.489319,602.285767 410.762939,606.117676 
	C428.438568,635.907837 453.739075,655.540527 487.677551,663.606873 
	C492.782013,664.820068 497.855255,666.164978 502.206421,667.742310 
	C501.280151,667.921997 500.353882,668.101685 499.259186,668.314026 
	C505.679871,670.805481 536.492798,670.683899 541.322021,668.282043 
	C539.973816,668.009338 538.521729,667.715637 537.618652,666.807068 
	C544.121216,665.549500 550.768677,664.790405 557.102051,662.950439 
	C589.480164,653.543945 613.121704,633.763672 626.415039,602.555359 
	C630.372559,593.264526 632.796265,583.320374 636.270508,572.602966 
	C630.327026,572.602966 625.887268,572.624878 621.447693,572.599121 
	C603.163330,572.492920 584.879089,572.372253 567.560730,572.020386 
	C589.730957,572.020386 611.901123,572.020386 634.071350,572.020386 
	C634.075928,571.542725 634.080505,571.065002 634.085083,570.587341 
	C633.647583,570.412781 633.210449,570.086304 632.772644,570.085388 
	C611.345032,570.042786 589.917053,569.988403 568.490356,570.123169 
	C567.368958,570.130188 566.257874,571.772156 565.078064,573.338013 
	C561.384277,583.574097 556.247803,592.738464 545.755066,598.279602 
	C545.210022,598.910889 544.664917,599.542175 543.547913,600.562683 
	C541.837585,601.198303 540.127258,601.833923 537.720215,602.201477 
	C536.539246,602.476318 535.358337,602.751221 533.398987,602.709351 
	C524.737061,602.604858 516.075195,602.490417 507.413025,602.424683 
	C506.969269,602.421326 506.522400,602.836243 505.859314,602.361328 
	C501.049377,600.632202 496.239441,598.903076 491.358887,597.117004 
	C491.358887,597.117004 491.303802,597.044922 491.566010,596.267822 
	C490.551300,592.192871 487.364563,591.394531 483.903473,591.132996 
	C483.903473,591.132996 483.884644,591.213013 483.699646,590.691284 
	C483.394928,590.499939 483.090240,590.308594 482.865265,590.097839 
	C482.865265,590.097839 482.864624,590.179932 482.924286,589.403259 
	C482.622711,588.317322 482.607788,587.015625 481.977020,586.177429 
	C470.814270,571.344727 465.875732,554.548950 465.952820,536.127441 
	C465.982422,529.050537 465.668213,521.957825 466.051788,514.901672 
	C466.326874,509.841370 466.721252,504.541473 468.451965,499.862457 
	C471.235596,492.336761 475.222168,485.260468 478.527832,477.916595 
	C478.860321,477.178009 478.006378,475.905426 477.820801,474.192474 
	C478.344330,473.548737 478.874451,472.910248 479.390411,472.260498 
	C494.436218,453.313538 522.906250,447.628662 544.183167,459.228943 
	C553.999390,464.580811 559.777466,473.236664 564.428223,482.971771 
	C564.994873,484.157837 566.663574,485.592468 567.831421,485.601349 
	C589.152832,485.763123 610.475769,485.717560 631.798340,485.736725 
	C634.458496,485.739105 635.398193,484.765564 634.570618,482.026093 
	C633.373352,478.063293 632.602478,473.971344 631.395874,470.011841 
	C624.178955,446.329071 611.435364,426.466339 590.860229,412.157318 
	C571.195740,398.481598 549.118469,392.653076 525.380737,391.922028 
	C504.322235,391.273468 483.921570,394.225891 464.862762,403.326630 
	C432.960907,418.560150 411.583008,443.344360 400.115204,476.757263 
	C399.122070,479.650909 397.637787,482.376007 396.196136,484.506836 
	C395.826569,484.115845 395.457001,483.724854 395.087433,483.333862 
	C394.418915,488.297394 390.185303,492.835571 393.122131,498.019043 
	C393.122131,498.019043 393.334930,498.226746 392.564148,498.268494 
	C391.716248,502.855316 390.868347,507.442108 389.493988,512.629517 
	C386.034027,527.472290 389.312164,542.209961 390.337433,556.969543 
	C390.430115,558.304321 392.287598,559.516418 393.147766,560.964111 
	C393.147766,560.964111 393.291656,560.757263 392.877014,561.469177 
	C391.911194,564.765808 390.533661,568.183655 395.143646,569.971436 
	C395.143646,569.971436 395.293579,569.757568 394.884277,570.455933 
	C393.493073,575.261414 395.677490,581.379150 399.145996,582.942566 
	C399.145996,582.942566 399.255188,582.774231 398.874878,583.444580 
	C396.799774,587.648071 400.001770,590.130371 401.996826,593.730835 
M486.327942,764.873047 
	C482.447388,767.398865 478.660950,770.088806 474.650818,772.388794 
	C472.251038,773.765198 472.218414,775.021240 473.568970,777.145874 
	C477.286255,782.993469 480.963745,788.872864 484.425720,794.873047 
	C485.765503,797.195129 486.978394,797.557312 489.136688,796.057556 
	C511.543610,780.488586 533.968262,764.945007 556.415222,749.433838 
	C558.787048,747.794800 559.396729,746.355286 557.310425,743.847107 
	C553.182129,738.883911 549.271912,733.730835 545.449585,728.525391 
	C543.544373,725.930664 541.882629,725.758728 539.254517,727.631897 
	C521.883179,740.013550 504.419708,752.265991 486.327942,764.873047 
M489.850800,286.454041 
	C486.826538,288.854156 483.802277,291.254242 480.795074,293.640839 
	C486.728302,301.211182 492.105804,308.072418 497.463684,314.908661 
	C507.965424,307.335876 517.764099,299.993561 527.849365,293.068726 
	C539.992798,284.730713 552.420166,276.806824 564.602295,268.523712 
	C565.896606,267.643646 567.498047,264.923706 567.051636,264.133148 
	C563.024353,257.001068 558.571655,250.109222 553.490356,241.935150 
	C532.136169,256.869995 511.284180,271.453613 489.850800,286.454041 
M269.815918,562.433289 
	C284.550598,540.828796 299.285278,519.224304 314.433411,497.013641 
	C307.924500,491.987244 300.930725,486.586456 293.451569,480.810822 
	C274.897278,504.385742 258.293701,528.767578 242.710022,554.060730 
	C249.067886,557.991028 255.019455,561.274475 260.515900,565.195801 
	C264.746796,568.214294 267.536957,568.150757 269.815918,562.433289 
M368.000916,394.492340 
	C363.120178,399.480011 358.108307,404.350006 353.451447,409.538605 
	C352.295776,410.826263 351.265991,414.193054 351.914825,414.826996 
	C357.791870,420.569672 364.054779,425.917450 370.477234,431.623047 
	C391.266357,411.291504 411.741791,391.266724 432.372772,371.089813 
	C426.642853,364.893646 421.990967,359.969910 417.462128,354.935516 
	C413.036224,350.015564 413.027344,349.901062 408.106201,354.752594 
	C394.873077,367.798676 381.699951,380.905609 368.000916,394.492340 
M662.577820,616.075684 
	C644.772095,634.142334 626.966309,652.208984 609.098572,670.338562 
	C614.303833,676.172668 619.405151,682.080444 624.791077,687.716248 
	C625.574524,688.536072 628.423950,688.770142 629.130310,688.072815 
	C648.869446,668.585999 668.477539,648.965881 687.981750,629.243652 
	C688.727417,628.489624 688.869141,625.820557 688.207153,625.198425 
	C682.309204,619.655457 676.166748,614.372620 669.247742,608.263855 
	C667.118896,610.763977 665.083435,613.154297 662.577820,616.075684 
M597.500000,488.030029 
	C587.155334,488.030029 576.810730,488.030029 566.466064,488.030029 
	C566.470032,488.236023 566.474060,488.442017 566.478088,488.648010 
	C588.765015,488.648010 611.051941,488.648010 633.338928,488.648010 
	C633.338318,488.442047 633.337769,488.236084 633.337158,488.030121 
	C621.724792,488.030121 610.112366,488.030121 597.500000,488.030029 
z"
      />
      <path
        fill="#1D11AC"
        opacity="1.000000"
        stroke="none"
        d="
M649.875793,914.135986 
	C638.373657,916.857910 628.208740,915.256042 620.588440,906.130188 
	C613.565735,897.719971 611.926147,888.056274 616.422852,877.794067 
	C621.130493,867.050598 630.020264,861.816223 641.198364,861.751038 
	C652.811340,861.683228 664.693726,868.644714 667.659180,885.245728 
	C669.520569,895.666138 662.669678,908.311523 652.028076,913.100037 
	C651.422119,913.372742 650.836670,913.690979 649.875793,914.135986 
z"
      />
      <path
        fill="#1B10AC"
        opacity="1.000000"
        stroke="none"
        d="
M416.162231,174.887283 
	C399.842041,173.996017 388.309692,160.308472 390.933838,143.091171 
	C392.502991,132.795761 403.790283,122.482574 414.251312,121.514793 
	C426.915070,120.343216 438.397491,127.746048 442.800507,138.619980 
	C449.819061,155.953552 435.422943,176.830948 416.162231,174.887283 
z"
      />
      <path
        fill="#1C10AC"
        opacity="1.000000"
        stroke="none"
        d="
M904.001953,618.428589 
	C900.311035,633.860352 887.150085,640.890625 875.987488,640.460144 
	C865.107422,640.040527 853.672424,630.738586 851.487732,620.319336 
	C848.761719,607.318176 854.371277,594.684387 865.244202,589.552856 
	C875.239502,584.835632 885.040955,585.529968 893.800171,592.118286 
	C902.258057,598.480042 905.542786,607.435059 904.001953,618.428589 
z"
      />
      <path
        fill="#1C10AC"
        opacity="1.000000"
        stroke="none"
        d="
M127.877243,397.913422 
	C129.273453,397.133148 130.669662,396.352875 132.700470,395.569641 
	C134.218201,394.983093 135.101349,394.399475 135.984497,393.815857 
	C136.397034,393.733032 136.809555,393.650208 137.987900,393.643158 
	C142.552109,393.612671 146.350510,393.506409 150.148895,393.400146 
	C151.528961,393.841827 152.909027,394.283478 154.738861,395.118561 
	C155.516342,395.501678 155.844040,395.491333 156.171753,395.480988 
	C159.540604,398.358307 162.909470,401.235626 166.338486,404.718079 
	C166.921738,405.894318 167.444824,406.465424 167.967911,407.036530 
	C168.133621,407.405273 168.299332,407.774048 168.448364,408.738892 
	C169.017212,410.218048 169.602753,411.101166 170.188293,411.984253 
	C170.271072,412.397705 170.353851,412.811188 170.349762,413.986267 
	C170.376526,417.881927 170.490143,421.015991 170.603775,424.150024 
	C170.527710,424.867004 170.451630,425.583954 169.996140,426.808258 
	C169.270126,428.602478 168.923523,429.889374 168.576935,431.176270 
	C168.252274,431.706329 167.927628,432.236359 167.113373,433.130493 
	C165.926331,434.759979 165.228882,436.025391 164.531448,437.290802 
	C164.254303,437.591064 163.977142,437.891296 163.250427,438.332031 
	C162.800858,438.472504 162.480896,438.789398 162.480896,438.789398 
	C162.480896,438.789398 162.599945,439.223816 162.599945,439.223816 
	C161.358154,440.221985 160.116364,441.220154 158.261566,442.318176 
	C157.103745,442.949066 156.558914,443.480072 156.014099,444.011108 
	C155.609207,444.146912 155.204330,444.282715 154.147919,444.376862 
	C152.335602,444.945892 151.174805,445.556610 150.014008,446.167328 
	C149.302765,446.243652 148.591537,446.319977 147.185944,446.357178 
	C146.273590,446.402496 146.055603,446.486908 145.837601,446.571320 
	C143.839218,446.500458 141.840836,446.429596 139.225266,446.016357 
	C137.305435,445.369965 136.002792,445.065979 134.700150,444.761993 
	C132.835114,443.766296 130.970062,442.770599 128.769714,441.221649 
	C127.217072,439.882324 125.999725,439.096283 124.782379,438.310242 
	C123.813614,437.139740 122.844849,435.969238 121.742851,434.215210 
	C121.068039,433.094147 120.526466,432.556610 119.984879,432.019043 
	C119.849960,431.613098 119.715050,431.207123 119.622269,430.148224 
	C119.049034,428.335114 118.433670,427.174927 117.818298,426.014740 
	C117.741837,425.606110 117.665375,425.197449 117.631485,424.140747 
	C117.590126,423.274170 117.506203,423.055603 117.422272,422.837067 
	C117.431320,422.460999 117.440369,422.084961 117.584549,421.031677 
	C117.609993,419.522522 117.500305,418.690643 117.390625,417.858734 
	C117.495964,416.502350 117.601303,415.145996 118.140305,413.267120 
	C118.861519,411.409119 119.149071,410.073578 119.436615,408.738068 
	C119.765350,408.223358 120.094078,407.708679 120.831696,406.918091 
	C121.501755,406.363464 121.489670,406.097046 121.204323,405.842926 
	C121.363434,405.423615 121.522545,405.004303 122.156624,404.260498 
	C123.002289,403.235077 123.372993,402.534088 123.743698,401.833130 
	C124.300011,401.270081 124.856323,400.707001 125.926193,399.921021 
	C126.918915,399.103210 127.398079,398.508331 127.877243,397.913422 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M639.001465,728.502563 
	C650.364258,733.924255 661.591125,740.168945 673.303894,745.305969 
	C690.456360,752.828613 707.745911,760.149780 725.377563,766.432617 
	C738.854370,771.234863 752.908325,774.464539 766.796265,778.041016 
	C771.834839,779.338440 777.136963,779.612793 783.377869,780.505371 
	C781.507263,770.601318 780.330505,761.411560 777.963135,752.539490 
	C774.529846,739.672607 771.115173,726.677551 766.110779,714.380920 
	C757.923767,694.264282 749.045898,674.375549 739.361267,654.937805 
	C731.138306,638.433594 721.364258,622.702209 712.270020,606.269043 
	C716.613464,612.280029 721.506165,618.352234 725.206543,625.080139 
	C734.530762,642.033325 744.012024,658.961243 752.117310,676.505920 
	C759.684570,692.885925 766.124756,709.852722 772.004944,726.925110 
	C776.743164,740.681946 780.002502,754.956238 783.693237,769.059753 
	C784.520935,772.222595 784.726379,775.646729 784.556091,778.916077 
	C784.500183,779.988892 782.285706,781.886169 781.170593,781.807739 
	C775.764465,781.427612 770.317505,780.866272 765.028381,779.712036 
	C738.421082,773.905029 712.694702,765.284485 687.932251,754.016846 
	C672.581543,747.031799 657.643250,739.138245 642.554871,731.582581 
	C641.262268,730.935242 640.233948,729.760193 639.001465,728.502563 
z"
      />
      <path
        fill="#1C16AF"
        opacity="1.000000"
        stroke="none"
        d="
M690.219299,865.357117 
	C695.561157,863.462891 694.064270,868.798096 695.464661,870.875366 
	C697.035645,873.205933 697.580383,876.511902 697.740723,879.438965 
	C697.945984,883.186218 699.503235,884.007874 703.021973,884.096436 
	C719.567627,884.512634 735.403503,881.918701 750.191833,874.131836 
	C759.585510,869.185486 766.218079,861.593445 771.615112,852.615967 
	C778.207092,841.650757 779.506409,829.255493 781.594482,817.079651 
	C781.736023,816.254150 779.726257,814.351929 778.488831,814.108032 
	C767.156250,811.874817 755.446716,810.967529 744.474609,807.617798 
	C723.305054,801.154907 702.533691,793.383606 681.641602,786.028442 
	C680.544373,785.642151 679.859985,784.083252 679.461182,782.861328 
	C681.630188,783.091125 683.321411,783.529846 685.012573,783.968506 
	C685.763062,784.400024 686.513611,784.831543 687.799500,785.436768 
	C688.891113,785.737305 689.447327,785.864136 690.003540,785.990967 
	C690.449585,786.383057 690.895691,786.775146 691.938232,787.352417 
	C693.597351,788.240234 694.568848,789.226501 695.736633,789.601685 
	C707.483704,793.375427 719.263062,797.048767 731.033325,800.750427 
	C731.452698,800.916443 731.872009,801.082458 732.903564,801.379028 
	C735.023132,801.909729 736.530457,802.309875 738.037842,802.709961 
	C738.454834,802.881836 738.871887,803.053650 739.940552,803.363403 
	C745.403137,804.603088 750.214111,805.704956 755.025085,806.806885 
	C755.450623,806.954346 755.876160,807.101807 756.952148,807.365784 
	C760.076599,807.925293 762.550659,808.368286 765.024719,808.811279 
	C765.757751,808.976135 766.490845,809.140930 767.932007,809.395020 
	C772.096436,809.970276 775.552856,810.456238 779.009216,810.942261 
	C780.596680,811.171143 782.184143,811.400085 784.388306,811.717957 
	C784.468750,813.097168 784.558289,814.633850 784.569824,816.915771 
	C782.299683,827.428467 780.107483,837.195984 777.915344,846.963440 
	C777.547852,847.425476 777.180298,847.887451 776.644775,848.852295 
	C776.288635,849.890808 776.100525,850.426392 775.912415,850.962036 
	C775.545776,851.424683 775.179199,851.887329 774.645203,852.850830 
	C774.293640,853.884949 774.109436,854.418274 773.925293,854.951599 
	C773.925293,854.951599 773.992493,854.996033 773.654236,855.046326 
	C772.882385,856.066345 772.448853,857.036133 772.015259,858.005859 
	C764.532715,866.688354 756.899536,875.453979 745.695435,879.202271 
	C736.391235,882.314880 726.762939,884.693176 717.104736,886.434631 
	C710.209961,887.677856 703.027893,887.327087 696.883301,887.641174 
	C694.465088,879.577332 692.386719,872.646912 690.219299,865.357117 
z"
      />
      <path
        fill="#231FB0"
        opacity="1.000000"
        stroke="none"
        d="
M336.054901,269.835510 
	C334.930359,270.160858 333.569458,271.064392 332.719849,270.717133 
	C327.444519,268.560730 322.386688,265.848969 317.057281,263.854279 
	C311.315002,261.705017 305.415497,259.902466 299.476562,258.366302 
	C283.541473,254.244507 267.550049,250.340332 251.573303,246.380676 
	C250.989243,246.235931 250.324554,246.416595 248.838562,246.485321 
	C250.044678,253.391876 250.903519,260.060364 252.403564,266.581360 
	C257.529663,288.865601 264.660126,310.529297 273.937317,331.421265 
	C279.940338,344.939789 286.399170,358.267975 293.045227,371.484619 
	C297.364716,380.074463 302.482086,388.260193 307.019836,396.745758 
	C307.955292,398.494995 307.906250,400.770721 308.035797,402.945190 
	C304.976318,398.568451 302.192017,394.050262 299.205292,388.922729 
	C297.701935,386.197998 296.400970,384.082581 295.100006,381.967194 
	C294.862061,381.417694 294.624115,380.868225 294.084351,379.832184 
	C291.607727,375.373047 289.432861,371.400482 287.258026,367.427887 
	C287.037994,366.692871 286.817932,365.957825 286.305054,364.811462 
	C285.695801,363.926575 285.379395,363.453064 285.062988,362.979553 
	C284.913788,362.401825 284.764587,361.824066 284.313110,360.822968 
	C283.693298,359.926697 283.375732,359.453766 283.058167,358.980865 
	C282.496918,357.507660 281.935669,356.034454 281.186462,353.919312 
	C279.766479,350.769928 278.491852,348.282349 277.310394,345.751221 
	C273.920380,338.488678 270.568146,331.208466 267.201050,323.935211 
	C267.220276,323.490997 267.239502,323.046783 267.125702,321.944550 
	C265.729065,318.836090 264.465454,316.385681 263.201843,313.935242 
	C263.224854,313.491028 263.247864,313.046844 263.172852,311.979401 
	C262.446014,310.217926 261.817200,309.079681 261.188354,307.941437 
	C261.040619,307.066467 260.892853,306.191528 260.473541,304.794434 
	C259.862488,303.496155 259.523010,302.719971 259.183533,301.943787 
	C259.037628,301.068298 258.891724,300.192810 258.473877,298.794830 
	C257.862488,297.496155 257.523010,296.719971 257.183533,295.943787 
	C257.181610,295.496979 257.179657,295.050201 257.066772,293.994690 
	C256.369965,292.236847 255.784119,291.087738 255.198257,289.938629 
	C254.989655,288.767670 254.781067,287.596710 254.336945,285.896301 
	C253.802200,284.557312 253.503006,283.747772 253.203796,282.938202 
	C253.205368,282.493622 253.206940,282.049042 253.107315,280.972382 
	C252.406265,278.872192 251.806412,277.404053 251.206543,275.935944 
	C251.215210,275.186554 251.223862,274.437164 251.115311,272.997955 
	C250.387238,270.521759 249.776382,268.735413 249.165497,266.949036 
	C249.165588,266.199585 249.165665,265.450165 249.068176,263.994141 
	C248.369553,261.174500 247.768494,259.061493 247.167435,256.948456 
	C247.157486,255.883408 247.147537,254.818344 247.038605,252.990204 
	C246.337875,249.798630 245.736130,247.370163 245.134399,244.941681 
	C245.190414,244.753891 245.246445,244.566116 245.614883,243.900879 
	C246.956177,243.250412 247.985046,243.077393 249.013916,242.904373 
	C249.747925,243.052048 250.481918,243.199707 251.912796,243.435760 
	C255.410446,243.990372 258.211212,244.456604 261.011993,244.922821 
	C261.742798,245.074020 262.473602,245.225220 263.842712,245.495636 
	C265.996674,246.005096 267.512329,246.395325 269.027985,246.785553 
	C269.754120,246.960464 270.480286,247.135345 271.706512,247.408813 
	C272.502289,247.562759 272.762024,247.690964 273.002075,248.112000 
	C273.128296,248.580063 273.238190,248.828156 273.348083,249.076248 
	C273.563660,248.765671 273.779236,248.455078 274.342773,248.176422 
	C275.801331,248.417480 276.911865,248.626602 278.022430,248.835739 
	C278.444855,248.979019 278.867279,249.122299 279.897186,249.406235 
	C282.009521,249.989227 283.514374,250.431564 285.019226,250.873901 
	C285.441467,251.022110 285.863678,251.170303 286.902832,251.430450 
	C289.020966,251.971542 290.522186,252.400726 292.023407,252.829895 
	C292.440369,252.983490 292.857300,253.137100 293.846863,253.462036 
	C295.621490,254.030624 296.823456,254.427872 298.025452,254.825134 
	C298.444977,254.970062 298.864502,255.114975 299.860260,255.425049 
	C301.632812,256.002136 302.829102,256.414093 304.025421,256.826019 
	C304.441040,256.980591 304.856689,257.135193 305.856384,257.489075 
	C308.963593,258.450562 311.486694,259.212769 314.009827,259.974976 
	C314.458099,260.360474 314.906342,260.745972 315.917114,261.312866 
	C317.328613,261.640503 318.177582,261.786774 319.026550,261.933044 
	C319.775238,262.364716 320.523926,262.796387 321.809570,263.407654 
	C322.904449,263.707642 323.462372,263.828064 324.020294,263.948517 
	C324.774750,264.381470 325.529236,264.814423 326.828247,265.386017 
	C327.906982,265.702026 328.441193,265.879395 328.975403,266.056763 
	C329.735504,266.471008 330.495605,266.885254 331.794983,267.464691 
	C332.893707,267.742584 333.453186,267.855316 334.012665,267.968018 
	C334.455688,268.349609 334.898712,268.731232 335.645020,269.294312 
	C335.948303,269.475739 336.054901,269.835510 336.054901,269.835510 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M711.082031,556.173584 
	C715.910889,562.686646 720.861633,569.479919 725.528076,576.463135 
	C743.798096,603.803345 760.572083,632.056702 774.092529,662.036194 
	C782.746765,681.225586 789.877808,701.102844 797.648132,720.688965 
	C798.497375,722.829834 799.058594,725.085022 799.928650,727.979614 
	C800.070190,729.114380 800.036499,729.556763 800.002808,729.999146 
	C798.718018,727.495972 797.207397,725.081360 796.184692,722.475281 
	C789.221313,704.731506 782.631714,686.835449 775.363159,669.218994 
	C771.147949,659.002747 766.342834,648.967590 761.005554,639.290894 
	C752.552063,623.964233 743.588257,608.910767 734.541504,593.922058 
	C726.940063,581.328186 718.912292,568.991577 711.082031,556.173584 
z"
      />
      <path
        fill="#1C16AF"
        opacity="1.000000"
        stroke="none"
        d="
M245.896637,200.004517 
	C246.594681,202.058167 248.041016,204.188202 247.840775,206.150162 
	C247.369690,210.765350 249.596069,212.302780 253.570435,212.942810 
	C258.786896,213.782852 264.007172,214.677933 269.150024,215.874954 
	C274.352539,217.085846 279.444397,218.764038 284.617493,220.111710 
	C292.366180,222.130356 300.312958,223.539902 307.870880,226.105713 
	C320.806030,230.497101 333.513916,235.560852 346.271454,240.461395 
	C346.762756,240.650116 346.779236,242.074890 346.538391,243.137085 
	C344.369080,242.910797 342.678284,242.471100 340.987518,242.031418 
	C340.236725,241.599854 339.485931,241.168289 338.199341,240.564667 
	C337.107788,240.264969 336.552063,240.137329 335.996307,240.009705 
	C335.550018,239.617416 335.103729,239.225159 334.090637,238.658142 
	C332.658875,238.391266 331.793884,238.299149 330.928864,238.207031 
	C330.172241,237.848389 329.415588,237.489731 328.127777,236.804413 
	C316.719910,232.734833 305.843262,228.991913 294.966583,225.249008 
	C294.546906,225.083115 294.127228,224.917221 293.095337,224.622421 
	C290.976166,224.091736 289.469238,223.689941 287.962311,223.288147 
	C287.545197,223.116852 287.128113,222.945557 286.059326,222.636475 
	C280.596771,221.396255 275.785828,220.293869 270.974915,219.191483 
	C270.549225,219.045395 270.123566,218.899307 269.047913,218.635422 
	C265.923645,218.075104 263.449310,217.632584 260.975006,217.190063 
	C260.241943,217.025452 259.508881,216.860855 258.066315,216.605377 
	C253.036667,215.693665 248.716507,214.872849 244.425705,214.057587 
	C244.582382,210.802032 244.722427,207.891785 244.862473,204.981537 
	C245.025742,203.933975 245.189026,202.886429 245.430420,201.310486 
	C245.555038,200.481567 245.684418,200.222366 245.896637,200.004517 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M376.035736,182.567810 
	C369.616608,176.820953 366.674713,168.850784 364.542603,160.595367 
	C363.350555,155.979965 363.017395,151.142746 362.236359,146.005203 
	C333.320862,140.231674 304.512360,138.405945 276.270172,151.742844 
	C280.902252,149.195526 285.695862,146.001190 290.966217,144.208618 
	C305.452087,139.281647 320.594238,139.324677 335.612854,140.179520 
	C344.136200,140.664642 352.595551,142.567169 361.023560,144.151443 
	C362.459381,144.421341 364.696564,146.309784 364.694580,147.447159 
	C364.671753,160.323593 369.698212,171.458847 376.035736,182.567810 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M874.581116,749.999023 
	C876.512756,743.435974 879.123413,736.880005 880.950989,730.112427 
	C884.710266,716.191833 885.256836,701.987488 883.614807,687.685974 
	C883.047180,682.742554 882.460327,677.801392 881.909424,672.856079 
	C881.502869,669.205627 879.957764,667.450562 875.828613,667.629639 
	C863.929504,668.145813 853.565735,663.867676 844.306641,656.210083 
	C851.392761,658.867493 858.407959,662.194946 865.708801,664.684875 
	C869.341309,665.923584 873.489746,665.793701 877.419067,665.977661 
	C881.378784,666.163086 883.735596,667.877808 883.941833,671.910095 
	C884.660095,685.952637 886.428345,700.062561 885.639221,714.024902 
	C884.934204,726.499451 882.795898,739.251160 874.581116,749.999023 
z"
      />
      <path
        fill="#1C16AF"
        opacity="1.000000"
        stroke="none"
        d="
M800.294922,730.180359 
	C800.036499,729.556763 800.070190,729.114380 800.121460,728.335571 
	C801.219910,728.590942 803.063293,728.991272 803.267151,729.803284 
	C806.308716,741.918274 809.141235,754.085815 812.004150,766.245483 
	C812.689392,769.155945 813.028015,772.187866 814.077454,774.954895 
	C814.560852,776.229553 816.377869,777.849426 817.582764,777.843445 
	C821.533081,777.823608 825.471985,777.003174 829.431885,776.752808 
	C831.199768,776.640991 833.007263,777.154114 834.715210,777.876282 
	C834.301392,778.405579 834.077209,778.580627 833.961121,778.894165 
	C833.509827,778.897522 833.058533,778.900879 831.943237,779.029663 
	C828.840637,779.731750 826.402039,780.308472 823.963440,780.885254 
	C823.211914,780.859619 822.460388,780.834045 820.953308,780.849976 
	C817.469116,781.193115 814.740479,781.494812 812.173279,781.778625 
	C811.012146,777.252319 809.956909,773.138794 808.901611,769.025208 
	C808.875793,767.957886 808.849915,766.890564 808.787170,765.046082 
	C808.211426,762.164856 807.672485,760.060852 807.133545,757.956787 
	C807.144775,757.204102 807.156006,756.451416 807.084595,754.994873 
	C806.368530,752.183044 805.735107,750.075134 805.101746,747.967224 
	C805.100464,747.514771 805.099182,747.062317 804.973022,745.943359 
	C803.604492,741.171997 802.360962,737.067261 801.117371,732.962463 
	C800.940552,732.095520 800.763733,731.228516 800.294922,730.180359 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M712.067932,375.295105 
	C705.166504,368.601379 698.248901,361.526764 691.213135,354.571686 
	C677.664429,341.178345 664.048828,327.852570 650.418213,314.179382 
	C652.054504,314.885162 653.991394,315.643372 655.372559,316.977020 
	C668.755920,329.899231 682.190491,342.774597 695.297607,355.973969 
	C701.227234,361.945282 706.453186,368.615204 712.067932,375.295105 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M336.410858,269.866699 
	C336.054901,269.835510 335.948303,269.475739 335.953247,269.288208 
	C336.997223,269.076691 338.219452,268.678802 339.047882,269.084656 
	C352.194244,275.525482 365.343109,281.965637 378.377045,288.629669 
	C385.860809,292.455963 393.173370,296.623657 400.490112,300.766327 
	C402.753571,302.047882 404.777161,303.753021 406.971466,305.598206 
	C397.756897,300.930481 388.571289,295.752502 379.184937,290.968475 
	C365.119568,283.799774 350.913208,276.907654 336.410858,269.866699 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M832.065125,642.462646 
	C826.861145,637.290955 825.455750,630.121094 823.363098,622.575073 
	C823.000549,616.093872 822.900879,610.170654 822.801147,604.247375 
	C824.513367,599.808838 826.044128,595.288513 827.996033,590.958008 
	C829.518372,587.580627 831.564148,584.439148 833.706055,581.131958 
	C831.793823,586.703308 828.773254,592.167358 827.513184,598.010986 
	C826.005676,605.002258 824.852722,612.450012 825.657166,619.457703 
	C826.539734,627.145813 829.827698,634.557861 832.065125,642.462646 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M684.946228,783.625854 
	C683.321411,783.529846 681.630188,783.091125 679.580139,782.536072 
	C659.607422,772.456299 639.993591,762.492920 620.317993,752.206787 
	C626.204895,754.662231 632.178650,757.388367 638.097534,760.229126 
	C653.058960,767.409790 667.998413,774.636475 682.934021,781.870605 
	C683.644165,782.214539 684.234009,782.806641 684.946228,783.625854 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M341.053955,242.374115 
	C342.678284,242.471100 344.369080,242.910797 346.418854,243.466278 
	C366.393494,253.544250 386.009125,263.506470 405.684265,273.792236 
	C399.794922,271.337891 393.820831,268.612396 387.902069,265.771759 
	C372.940613,258.591187 358.001373,251.364288 343.065735,244.130112 
	C342.355682,243.786194 341.766113,243.193588 341.053955,242.374115 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M395.970459,199.381546 
	C390.622925,195.442917 385.234436,191.557983 379.955139,187.529922 
	C378.539886,186.450073 377.457611,184.933792 376.146637,183.291260 
	C379.691589,185.784714 383.205658,188.756195 386.953247,191.396240 
	C397.982147,199.165710 410.232697,202.097870 423.629913,200.358185 
	C425.210419,200.152969 426.864105,200.510849 428.545471,201.071335 
	C428.068451,201.677551 427.529846,201.820145 426.662598,201.866943 
	C425.370239,202.243622 424.406494,202.716095 423.026764,203.154694 
	C419.261200,203.148117 415.911621,203.175446 412.371277,202.905365 
	C410.807343,202.373978 409.434235,202.139999 407.656921,201.825638 
	C405.168976,201.509186 403.085266,201.273102 400.797516,200.920944 
	C400.593506,200.804855 400.131989,200.719116 400.004517,200.396027 
	C398.574829,199.842453 397.272644,199.612000 395.970459,199.381546 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M540.050537,699.302673 
	C537.839966,698.080383 535.562317,696.550659 533.284668,695.020996 
	C533.499695,694.608459 533.714722,694.195923 533.929749,693.783447 
	C540.695129,692.725464 547.471375,691.731873 554.221375,690.583252 
	C557.782898,689.977173 561.639465,689.778259 564.763672,688.205444 
	C571.213318,684.958313 576.082947,687.122009 580.852112,691.701965 
	C580.267761,691.951111 579.347168,692.124573 579.001404,691.757141 
	C573.293579,685.692200 567.325073,689.468323 561.313049,691.065186 
	C556.745239,692.278442 552.073242,693.141052 547.409546,693.937134 
	C544.350586,694.459290 541.223816,694.584717 537.438782,694.955200 
	C538.526306,696.681702 539.254944,697.838501 540.050537,699.302673 
z"
      />
      <path
        fill="#0500A8"
        opacity="1.000000"
        stroke="none"
        d="
M143.339691,288.111084 
	C144.301270,289.076965 145.812759,290.562958 145.527679,291.505615 
	C141.083038,306.201935 142.086929,321.288025 141.969543,336.302979 
	C141.949081,338.919769 141.448303,341.532837 141.089447,344.569519 
	C141.009506,344.991425 141.029190,344.970978 140.762558,344.938416 
	C140.319748,344.937988 140.143570,344.970093 139.967407,345.002197 
	C139.842682,344.329254 139.717957,343.656342 139.784332,342.183716 
	C139.815643,339.807159 139.655869,338.230316 139.496109,336.653473 
	C139.431915,336.111053 139.367737,335.568665 139.546844,334.290039 
	C139.732574,324.932495 139.675018,316.311096 139.617447,307.689728 
	C139.645691,306.686554 139.673920,305.683350 140.191467,304.239929 
	C140.737320,303.148682 140.793869,302.497742 140.850403,301.846771 
	C141.584900,297.357300 142.319382,292.867859 143.339691,288.111084 
z"
      />
      <path
        fill="#0500A8"
        opacity="1.000000"
        stroke="none"
        d="
M141.022873,344.979553 
	C142.024872,347.210754 143.591476,349.377075 143.901306,351.710327 
	C145.189209,361.408936 144.386017,361.189453 152.961533,362.863190 
	C157.683823,363.784912 162.260010,365.455231 167.975479,367.096222 
	C166.102356,367.590302 165.177765,367.834167 163.901489,368.169312 
	C157.668976,367.250153 151.758423,366.377258 145.931290,365.118286 
	C144.702744,364.852844 143.108795,363.322876 142.837280,362.115936 
	C141.869247,357.813110 141.398621,353.398407 140.920502,348.207916 
	C141.077271,346.583527 141.053223,345.777252 141.029190,344.970978 
	C141.029190,344.970978 141.009506,344.991425 141.022873,344.979553 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M503.991150,365.953766 
	C501.586853,366.262238 499.150330,366.955994 496.788361,366.758362 
	C494.868530,366.597748 493.038116,365.368286 491.117737,364.289856 
	C496.662933,363.982758 502.258392,364.009583 507.853882,364.011719 
	C514.273438,364.014191 520.695190,364.079590 527.110718,363.913300 
	C528.392090,363.880066 529.649719,362.926270 531.048828,362.002899 
	C532.081726,362.743195 532.984070,363.877533 533.432861,365.266296 
	C523.316650,365.665070 513.653931,365.809418 503.991150,365.953766 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M504.018280,203.424774 
	C496.875671,199.868988 489.710510,195.856979 482.389221,192.153656 
	C475.251343,188.543091 467.953766,185.248093 460.328918,181.838898 
	C461.255005,181.444672 462.996338,180.261215 463.839600,180.721832 
	C477.270081,188.058197 490.585602,195.604919 504.018280,203.424774 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M800.070068,486.463440 
	C799.392090,486.406433 798.715637,485.988098 797.836304,485.444366 
	C796.108337,488.542236 794.370544,491.657654 792.304016,494.482483 
	C794.991455,489.018372 794.984314,484.689331 788.656067,482.044037 
	C788.311096,481.899841 788.189636,481.220734 787.964111,480.790649 
	C790.285828,481.711395 792.607544,482.632172 794.929260,483.552917 
	C795.316223,483.150330 795.703186,482.747711 796.090149,482.345123 
	C792.200195,476.421692 788.310303,470.498291 784.403442,464.227905 
	C786.373657,466.192108 788.590515,468.346313 790.299377,470.847626 
	C793.704407,475.831635 796.830322,481.006287 800.070068,486.463440 
z"
      />
      <path
        fill="#231FB0"
        opacity="1.000000"
        stroke="none"
        d="
M426.991272,201.962738 
	C427.529846,201.820145 428.068451,201.677551 428.821838,201.357330 
	C430.207825,201.001251 431.379028,200.822769 433.157715,200.385742 
	C436.185181,199.110535 438.605133,198.093887 441.025085,197.077240 
	C442.182831,196.517303 443.340546,195.957367 445.062256,195.218262 
	C446.417297,194.356888 447.208344,193.674713 447.999390,192.992554 
	C449.246948,192.180878 450.494507,191.369202 452.116150,190.320938 
	C452.664215,190.107590 452.838196,190.130844 453.012146,190.154099 
	C452.804901,191.341461 453.022125,193.133484 452.324951,193.622849 
	C445.210907,198.616287 438.076782,203.692749 428.087402,203.974869 
	C426.830688,203.933380 426.353790,203.890488 426.033783,203.622101 
	C426.457550,202.918640 426.724396,202.440689 426.991272,201.962738 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M435.996826,292.585815 
	C443.053406,296.676758 450.239288,301.020874 457.324097,305.524170 
	C461.228851,308.006104 464.963654,310.755402 468.848175,313.706848 
	C464.825195,311.639435 460.623535,309.411133 456.656403,306.823334 
	C449.722931,302.300537 442.933655,297.556702 435.996826,292.585815 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M730.969482,800.398193 
	C719.263062,797.048767 707.483704,793.375427 695.736633,789.601685 
	C694.568848,789.226501 693.597351,788.240234 692.251038,787.323486 
	C692.525635,786.814026 693.212952,786.138916 693.623596,786.278381 
	C705.794678,790.411682 717.940735,794.618530 730.073853,798.862305 
	C730.443909,798.991699 730.632507,799.639771 730.969482,800.398193 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M711.139771,604.263428 
	C704.180847,594.284973 697.232300,583.944031 690.238831,573.256836 
	C692.951599,576.262085 696.032837,579.401611 698.397278,583.010742 
	C702.865906,589.831543 706.922363,596.922363 711.139771,604.263428 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M245.852158,199.621460 
	C245.684418,200.222366 245.555038,200.481567 245.336121,200.918701 
	C244.798599,200.181732 243.960480,199.193680 244.139999,198.451935 
	C246.478973,188.788040 248.514603,178.975967 255.587997,171.028381 
	C252.551956,180.388565 249.179810,189.813492 245.852158,199.621460 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M452.997314,335.584595 
	C445.224945,330.748779 437.425262,325.555206 429.596497,320.405853 
	C427.549133,319.059204 425.411255,317.850159 423.250366,316.254608 
	C426.764099,317.770935 430.557495,319.290314 433.875031,321.518280 
	C440.376648,325.884521 446.613007,330.645630 452.997314,335.584595 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M590.003296,733.412720 
	C583.100342,729.450439 576.070068,725.231812 569.137756,720.857910 
	C566.076904,718.926575 563.214844,716.680115 560.197632,714.254517 
	C566.174377,717.569946 572.284668,721.099792 578.233643,724.883301 
	C582.245483,727.434692 586.029724,730.344055 590.003296,733.412720 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M828.024353,531.508240 
	C822.937134,523.721008 817.790405,515.583801 812.693970,507.415131 
	C811.746094,505.895935 811.001038,504.250153 810.144775,502.299255 
	C814.707703,508.679321 819.416870,515.340759 823.805359,522.207031 
	C825.561462,524.954651 826.611023,528.153748 828.024353,531.508240 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M834.213257,779.154907 
	C834.077209,778.580627 834.301392,778.405579 834.814514,778.237915 
	C845.254639,775.730957 854.239014,770.839600 862.692261,764.157104 
	C857.854065,768.010559 852.991211,772.487610 847.399475,775.693359 
	C843.619263,777.860596 838.812866,778.237732 834.213257,779.154907 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M295.030273,225.601334 
	C305.843262,228.991913 316.719910,232.734833 327.789093,236.780228 
	C323.635651,235.964569 319.241211,235.004532 314.955353,233.690414 
	C308.855957,231.820221 302.824860,229.722717 296.798340,227.625381 
	C296.118622,227.388824 295.656860,226.526047 295.030273,225.601334 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M266.954041,324.143127 
	C270.568146,331.208466 273.920380,338.488678 277.310394,345.751221 
	C278.491852,348.282349 279.766479,350.769928 280.981384,353.642090 
	C279.400391,352.074585 277.323334,350.366302 276.365143,348.169861 
	C272.940613,340.319763 269.895630,332.304077 266.954041,324.143127 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M842.107971,557.366943 
	C837.465698,549.385376 832.815552,541.030579 828.145874,532.305908 
	C830.247375,534.960510 832.676758,537.818542 834.419189,541.047241 
	C837.218323,546.233887 839.563232,551.665588 842.107971,557.366943 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M778.273315,846.909790 
	C780.107483,837.195984 782.299683,827.428467 784.672180,817.309082 
	C785.227600,818.342834 786.073608,819.790100 785.901917,821.104309 
	C784.735779,830.028564 783.414673,838.941711 778.273315,846.909790 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M808.669189,769.332520 
	C809.956909,773.138794 811.012146,777.252319 812.173279,781.778625 
	C814.740479,781.494812 817.469116,781.193115 820.607605,781.011047 
	C815.987976,785.762451 811.098145,784.872009 809.511536,778.809326 
	C808.739929,775.861023 808.769714,772.702881 808.669189,769.332520 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M244.511063,205.086639 
	C244.722427,207.891785 244.582382,210.802032 244.425705,214.057587 
	C248.716507,214.872849 253.036667,215.693665 257.694885,216.700653 
	C252.753510,219.652664 247.874008,216.853790 243.422882,214.896011 
	C242.436539,214.462189 243.850769,208.570419 244.511063,205.086639 
z"
      />
      <path
        fill="#0100A8"
        opacity="1.000000"
        stroke="none"
        d="
M365.796570,509.398560 
	C366.606873,510.607513 367.814911,512.208130 367.895111,513.863464 
	C368.152985,519.183838 368.094879,524.528015 367.907318,529.854187 
	C367.872009,530.858032 366.681061,531.821167 365.776062,532.566467 
	C365.497772,528.366638 365.466827,524.403503 365.664673,519.487549 
	C365.870056,518.159180 365.846619,517.783569 365.823181,517.408020 
	C365.774780,514.877136 365.726379,512.346191 365.796570,509.398560 
z"
      />
      <path
        fill="#635EBD"
        opacity="1.000000"
        stroke="none"
        d="
M139.409943,308.034607 
	C139.675018,316.311096 139.732574,324.932495 139.584076,334.018250 
	C139.103287,327.402740 138.778275,320.324127 138.591934,313.241882 
	C138.549454,311.627563 138.986221,310.000610 139.409943,308.034607 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M595.996948,702.585083 
	C600.099182,704.738403 604.287170,707.213562 608.478333,709.683472 
	C610.577087,710.920349 612.681580,712.147400 614.856262,713.707031 
	C612.036621,712.647034 609.021301,711.463684 606.282715,709.819397 
	C602.766785,707.708252 599.473267,705.226746 595.996948,702.585083 
z"
      />
      <path
        fill="#0100A8"
        opacity="1.000000"
        stroke="none"
        d="
M629.250793,834.705688 
	C628.505310,834.306946 627.904968,834.028076 627.304626,833.749207 
	C627.260071,833.375366 627.215515,833.001465 627.170959,832.627625 
	C629.584717,832.452881 631.996521,832.172913 634.412720,832.126343 
	C638.837524,832.041016 643.267334,832.006409 647.690247,832.126587 
	C649.812866,832.184265 651.924500,832.643311 654.041138,832.920654 
	C653.830811,833.384644 653.620422,833.848633 653.084839,834.454834 
	C652.452820,834.606201 652.146057,834.615295 651.210205,834.375610 
	C650.198425,834.139954 649.815613,834.152954 649.432861,834.165955 
	C644.088928,834.255859 638.744934,834.345764 632.411133,834.281860 
	C631.041504,834.185242 630.661743,834.242432 630.281921,834.299561 
	C629.986633,834.474915 629.691284,834.650208 629.250793,834.705688 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M730.055969,395.407837 
	C725.517090,390.681824 720.922729,385.619110 716.295044,380.220795 
	C720.841187,384.947174 725.420837,390.009155 730.055969,395.407837 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M646.053162,309.410339 
	C640.862061,305.335297 635.610962,300.932007 630.306152,296.208252 
	C635.499329,300.285858 640.746277,304.683960 646.053162,309.410339 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M680.088623,558.451904 
	C676.156250,553.737061 672.220398,548.665649 668.239258,543.252441 
	C672.157715,547.972229 676.121399,553.033875 680.088623,558.451904 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M530.003296,219.412689 
	C524.859192,216.665634 519.627625,213.599167 514.325439,210.206146 
	C519.475159,212.950836 524.695435,216.022064 530.003296,219.412689 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M562.030273,241.400558 
	C557.518555,238.662262 552.944824,235.593536 548.315552,232.204910 
	C552.829468,234.946716 557.398926,238.008423 562.030273,241.400558 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M660.707886,498.065155 
	C661.468140,496.854187 662.561707,495.571289 664.027832,493.851318 
	C666.850464,497.393372 669.259338,500.416229 671.705933,503.776154 
	C669.921753,502.551270 667.748352,501.246246 666.370178,499.360077 
	C664.774841,497.176819 663.279419,496.672119 660.707886,498.065155 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M625.026978,720.405762 
	C629.541504,722.524902 634.117554,724.977722 638.760132,727.756470 
	C634.247314,725.634766 629.667908,723.187012 625.026978,720.405762 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M690.088623,572.451904 
	C686.822205,568.405884 683.552246,564.003357 680.238159,559.255676 
	C683.491028,563.305481 686.788086,567.700439 690.088623,572.451904 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M683.911133,519.546753 
	C687.123291,523.275146 690.338684,527.358704 693.582458,531.781067 
	C690.378723,528.047241 687.146545,523.974548 683.911133,519.546753 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M650.056946,519.405090 
	C648.409363,518.052979 646.220337,516.634216 645.106018,514.620728 
	C642.944031,510.714264 647.520020,510.034332 649.295166,507.717407 
	C648.779724,509.403961 647.947815,511.201630 646.796143,513.690186 
	C647.542297,514.942566 648.771729,517.006165 650.056946,519.405090 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M742.056396,409.406372 
	C738.851685,406.015961 735.591614,402.288086 732.297058,398.222534 
	C735.508789,401.612854 738.754883,405.340912 742.056396,409.406372 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M420.003296,313.412781 
	C415.797821,311.363159 411.504913,308.994232 407.138977,306.296387 
	C411.349213,308.342804 415.632507,310.718140 420.003296,313.412781 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M754.936035,806.458252 
	C750.214111,805.704956 745.403137,804.603088 740.280640,803.302979 
	C744.928467,804.106323 749.887695,805.108032 754.936035,806.458252 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M271.063843,219.540146 
	C275.785828,220.293869 280.596771,221.396255 285.719299,222.696777 
	C281.071564,221.892868 276.112183,220.890839 271.063843,219.540146 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M810.028076,501.518005 
	C807.468445,498.115875 804.869812,494.363556 802.260498,490.251007 
	C804.829529,493.649811 807.409302,497.408844 810.028076,501.518005 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M844.050110,655.412476 
	C840.888489,652.674561 837.664062,649.610107 834.414185,646.212952 
	C837.588318,648.948792 840.787781,652.017334 844.050110,655.412476 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M471.969604,316.599426 
	C475.533783,318.639099 479.160034,321.009338 482.853760,323.704651 
	C479.291351,321.663147 475.661530,319.296570 471.969604,316.599426 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M701.959961,543.439270 
	C704.502319,546.526306 707.041992,549.975769 709.599304,553.771729 
	C707.063721,550.679382 704.510498,547.240601 701.959961,543.439270 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M800.847656,733.223633 
	C802.360962,737.067261 803.604492,741.171997 804.780029,745.657288 
	C800.946777,742.639587 800.588562,738.119507 800.847656,733.223633 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M287.207581,367.831635 
	C289.432861,371.400482 291.607727,375.373047 293.799957,379.706360 
	C291.597290,376.123169 289.377228,372.179291 287.207581,367.831635 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M846.412170,568.958008 
	C844.801147,565.570312 843.530640,562.104980 842.254272,558.265625 
	C845.352417,560.897583 848.749573,563.783386 846.412170,568.958008 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M752.056458,421.403503 
	C749.471924,418.702576 746.831116,415.664734 744.161255,412.279755 
	C746.754761,414.977295 749.377441,418.021942 752.056458,421.403503 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M768.027344,441.524658 
	C765.479614,438.773315 762.893677,435.675629 760.282227,432.232483 
	C762.834167,434.984100 765.411621,438.081207 768.027344,441.524658 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M666.027344,539.524475 
	C663.479553,536.773254 660.893677,533.675598 658.282349,530.232544 
	C660.834351,532.984070 663.411743,536.081116 666.027344,539.524475 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M602.972046,741.597107 
	C599.793579,740.146423 596.554138,738.361938 593.250244,736.254333 
	C596.427551,737.708557 599.669373,739.485901 602.972046,741.597107 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M615.026855,714.406738 
	C618.238037,715.841248 621.511230,717.609619 624.857178,719.706299 
	C621.649475,718.269958 618.369141,716.505249 615.026855,714.406738 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M612.971313,747.598022 
	C609.758545,746.162415 606.484558,744.392517 603.138916,742.294800 
	C606.348206,743.732605 609.629211,745.498169 612.971313,747.598022 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M413.027466,278.406250 
	C416.238739,279.841736 419.511993,281.611176 422.856415,283.708252 
	C419.648193,282.270630 416.368805,280.505402 413.027466,278.406250 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M514.018188,209.424683 
	C510.865662,208.006241 507.622223,206.266663 504.318665,204.205170 
	C507.481415,205.623367 510.704315,207.363449 514.018188,209.424683 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M778.913818,810.588013 
	C775.552856,810.456238 772.096436,809.970276 768.303345,809.298950 
	C771.845825,806.949951 775.383667,808.092896 778.913818,810.588013 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M423.026611,284.407959 
	C426.204285,285.857330 429.444214,287.640137 432.748810,289.745911 
	C429.571930,288.293091 426.330414,286.517242 423.026611,284.407959 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M695.081238,534.172852 
	C697.315063,536.659668 699.539734,539.512573 701.841919,542.702576 
	C699.636963,540.206055 697.354553,537.372498 695.081238,534.172852 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M782.904785,461.866699 
	C780.671448,459.369232 778.451721,456.503265 776.152954,453.300598 
	C778.355347,455.808655 780.636841,458.653442 782.904785,461.866699 
z"
      />
      <path
        fill="#9A96D5"
        opacity="1.000000"
        stroke="none"
        d="
M149.852295,393.181580 
	C146.350510,393.506409 142.552109,393.612671 138.297577,393.582001 
	C141.746185,393.284393 145.650955,393.123718 149.852295,393.181580 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M260.921722,244.574646 
	C258.211212,244.456604 255.410446,243.990372 252.282120,243.342133 
	C255.219070,240.972122 258.091675,242.046448 260.921722,244.574646 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M466.997406,345.583374 
	C464.426300,344.156738 461.816528,342.388550 459.140137,340.296387 
	C461.701904,341.728912 464.330292,343.485352 466.997406,345.583374 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M572.997437,249.583542 
	C570.426270,248.156799 567.816467,246.388504 565.140137,244.296387 
	C567.702026,245.729050 570.330444,247.485519 572.997437,249.583542 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M554.030579,709.401367 
	C551.474976,708.015442 548.857727,706.299805 546.183594,704.259521 
	C548.740784,705.647156 551.354797,707.359375 554.030579,709.401367 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M485.999268,359.543945 
	C483.495361,358.090515 480.925385,356.310547 478.305481,354.208771 
	C480.814758,355.663788 483.373962,357.440582 485.999268,359.543945 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M478.045105,353.415283 
	C475.528076,352.005157 472.945312,350.265808 470.311584,348.205811 
	C472.833527,349.618774 475.406464,351.352417 478.045105,353.415283 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M504.002502,340.450684 
	C506.506073,341.905701 509.074707,343.687744 511.694061,345.791321 
	C509.185699,344.334442 506.626617,342.556061 504.002502,340.450684 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M628.048584,293.408447 
	C625.806335,292.030273 623.499207,290.324310 621.128906,288.293182 
	C623.371704,289.672211 625.677734,291.376404 628.048584,293.408447 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M806.866577,758.215576 
	C807.672485,760.060852 808.211426,762.164856 808.694885,764.656372 
	C805.032410,763.762878 805.897949,761.093140 806.866577,758.215576 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M262.955933,314.140442 
	C264.465454,316.385681 265.729065,318.836090 266.967896,321.648132 
	C265.532074,319.455048 264.121033,316.900360 262.955933,314.140442 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M269.404999,155.942108 
	C267.697815,157.834900 265.654449,159.782288 263.288269,161.819366 
	C264.999908,159.938263 267.034332,157.967468 269.404999,155.942108 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M764.938965,808.466492 
	C762.550659,808.368286 760.076599,807.925293 757.287109,807.289307 
	C759.887634,805.218689 762.506958,805.620667 764.938965,808.466492 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M261.060974,217.534698 
	C263.449310,217.632584 265.923645,218.075104 268.713013,218.711411 
	C266.112396,220.780899 263.493164,220.378784 261.060974,217.534698 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M824.235840,781.154663 
	C826.402039,780.308472 828.840637,779.731750 831.658691,779.222534 
	C830.502136,783.437866 827.350952,781.886658 824.235840,781.154663 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M244.874329,245.155624 
	C245.736130,247.370163 246.337875,249.798630 246.860260,252.628662 
	C242.552383,251.468353 244.063568,248.283127 244.874329,245.155624 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M649.969849,507.691528 
	C651.560913,505.847473 653.508362,504.088318 655.784668,502.293518 
	C654.184509,504.097351 652.255371,505.936859 649.969849,507.691528 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M405.984833,274.579712 
	C408.188202,275.294647 410.485657,276.335388 412.856445,277.705444 
	C410.646179,276.991638 408.362549,275.948547 405.984833,274.579712 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M620.014221,751.423462 
	C617.809814,750.708679 615.511658,749.667908 613.139648,748.297302 
	C615.350708,749.010742 617.635559,750.054016 620.014221,751.423462 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M440.794189,196.826416 
	C438.605133,198.093887 436.185181,199.110535 433.388977,200.103516 
	C435.529572,198.911774 438.046417,197.743668 440.794189,196.826416 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M804.841492,748.221924 
	C805.735107,750.075134 806.368530,752.183044 806.904175,754.658081 
	C806.064758,752.842346 805.322998,750.659546 804.841492,748.221924 
z"
      />
      <path
        fill="#9A96D5"
        opacity="1.000000"
        stroke="none"
        d="
M170.817749,423.870789 
	C170.490143,421.015991 170.376526,417.881927 170.406052,414.297577 
	C170.710037,417.095367 170.870880,420.343475 170.817749,423.870789 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M294.874023,382.180206 
	C296.400970,384.082581 297.701935,386.197998 298.972839,388.661621 
	C297.511169,386.804321 296.079590,384.598755 294.874023,382.180206 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M313.938416,259.631165 
	C311.486694,259.212769 308.963593,258.450562 306.191895,257.442932 
	C308.584534,257.894104 311.225769,258.590729 313.938416,259.631165 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M524.002197,356.457520 
	C525.915588,357.525024 527.896118,358.921295 530.033936,360.614166 
	C528.150635,359.535919 526.109985,358.161072 524.002197,356.457520 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M246.895752,257.196472 
	C247.768494,259.061493 248.369553,261.174500 248.897430,263.655640 
	C245.720566,262.623291 245.619431,260.218781 246.895752,257.196472 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M840.590576,574.056335 
	C841.961304,572.474121 843.667664,570.836914 845.704468,569.134399 
	C844.331970,570.713074 842.629028,572.357178 840.590576,574.056335 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M248.896698,267.193176 
	C249.776382,268.735413 250.387238,270.521759 250.934097,272.663940 
	C247.548248,272.192963 247.644394,269.993317 248.896698,267.193176 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M275.383972,151.887329 
	C274.015503,153.151871 272.289978,154.422699 270.225586,155.717926 
	C271.600128,154.459427 273.313538,153.176514 275.383972,151.887329 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M277.942932,248.501633 
	C276.911865,248.626602 275.801331,248.417480 274.114990,248.015472 
	C273.354736,247.845734 273.170288,247.868866 272.985809,247.891998 
	C272.762024,247.690964 272.502289,247.562759 272.077820,247.329926 
	C273.920502,247.490845 275.891968,247.829193 277.942932,248.501633 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M604.043213,273.401001 
	C602.462463,272.707855 600.811951,271.676666 599.023560,270.348938 
	C600.581543,271.055939 602.277527,272.059448 604.043213,273.401001 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M614.018311,281.525726 
	C612.439575,280.783264 610.812134,279.699127 609.125000,278.291382 
	C610.700012,279.039886 612.334839,280.111969 614.018311,281.525726 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M675.959717,509.424103 
	C677.205261,510.529846 678.446045,511.987061 679.715820,513.778381 
	C678.481567,512.666809 677.218323,511.221191 675.959717,509.424103 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M485.958069,326.714813 
	C487.523499,327.391388 489.164948,328.391174 490.864929,329.709900 
	C489.293701,329.031921 487.663879,328.034973 485.958069,326.714813 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M493.967712,332.605377 
	C495.539215,333.311646 497.172180,334.350555 498.864319,335.709167 
	C497.292053,334.998596 495.660583,333.968292 493.967712,332.605377 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M756.038025,426.580261 
	C754.792786,425.472961 753.552368,424.014069 752.285278,422.220703 
	C753.520020,423.333710 754.781372,424.781219 756.038025,426.580261 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M654.038086,524.580383 
	C652.792847,523.473083 651.552368,522.014282 650.285339,520.220947 
	C651.520142,521.333862 652.781494,522.781372 654.038086,524.580383 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M511.954895,346.589355 
	C513.529419,347.302246 515.170288,348.347961 516.868896,349.714905 
	C515.291565,348.998169 513.656372,347.960175 511.954895,346.589355 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M737.981750,802.375610 
	C736.530457,802.309875 735.023132,801.909729 733.241455,801.310181 
	C734.619934,801.420898 736.272827,801.731079 737.981750,802.375610 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M866.409180,760.993347 
	C867.211426,759.468506 868.347839,757.909912 869.798096,756.301880 
	C868.989136,757.821472 867.866272,759.390564 866.409180,760.993347 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M834.409302,581.000977 
	C835.210693,579.474304 836.346436,577.912170 837.796631,576.299561 
	C836.988586,577.821289 835.866089,579.393433 834.409302,581.000977 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M671.941284,504.591003 
	C673.184265,505.634552 674.480591,507.011627 675.850586,508.713043 
	C674.614380,507.666443 673.304504,506.295441 671.941284,504.591003 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M658.058167,529.408569 
	C656.815430,528.365784 655.518616,526.989563 654.146606,525.289062 
	C655.382324,526.334961 656.693237,527.705078 658.058167,529.408569 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M284.964478,250.530838 
	C283.514374,250.431564 282.009521,249.989227 280.231720,249.345398 
	C282.073608,247.288696 283.680176,247.844254 284.964478,250.530838 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M261.587280,163.004730 
	C260.787262,164.532486 259.653534,166.094086 258.202850,167.698517 
	C259.008453,166.173752 260.131042,164.606155 261.587280,163.004730 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M288.018433,223.622589 
	C289.469238,223.689941 290.976166,224.091736 292.757568,224.692657 
	C291.379547,224.580200 289.727051,224.268616 288.018433,223.622589 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M679.940063,514.586426 
	C681.168335,515.646667 682.448242,517.037354 683.767090,518.758789 
	C682.534607,517.698608 681.263184,516.307739 679.940063,514.586426 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M250.937164,276.159851 
	C251.806412,277.404053 252.406265,278.872192 252.935272,280.680359 
	C250.171234,280.403870 249.506653,278.826263 250.937164,276.159851 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M760.058228,431.408508 
	C758.815369,430.365662 757.518555,428.989441 756.146545,427.289001 
	C757.382324,428.334900 758.693237,429.705017 760.058228,431.408508 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M774.028442,449.530212 
	C772.784363,448.454102 771.503906,447.035278 770.149536,445.289612 
	C771.381165,446.371033 772.686584,447.779236 774.028442,449.530212 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M516.998047,350.411377 
	C518.524170,351.212921 520.086121,352.348389 521.695557,353.798706 
	C520.173340,352.990814 518.603638,351.868042 516.998047,350.411377 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M620.999023,287.596649 
	C619.473450,286.792877 617.913330,285.654572 616.304138,284.201691 
	C617.824829,285.012085 619.394653,286.137115 620.999023,287.596649 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M490.999146,363.590515 
	C489.430267,362.811646 487.825562,361.694885 486.174866,360.256012 
	C487.740356,361.040161 489.351837,362.146423 490.999146,363.590515 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M581.000061,255.586395 
	C579.435791,254.807159 577.834595,253.690338 576.180725,252.253922 
	C577.739746,253.039154 579.351440,254.143967 581.000061,255.586395 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M608.999390,277.596710 
	C607.470642,276.793976 605.907654,275.656921 604.302185,274.202759 
	C605.828186,275.011230 607.396729,276.136810 608.999390,277.596710 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M458.019653,184.309998 
	C457.259644,185.656784 456.128418,186.924789 454.692902,188.294510 
	C455.475220,187.007889 456.561859,185.619568 458.019653,184.309998 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M303.976624,256.497131 
	C302.829102,256.414093 301.632812,256.002136 300.195129,255.371109 
	C301.278442,255.490753 302.603149,255.829498 303.976624,256.497131 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M268.952820,246.462189 
	C267.512329,246.395325 265.996674,246.005096 264.212128,245.407440 
	C265.588043,245.512955 267.232880,245.825897 268.952820,246.462189 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M291.951202,252.504578 
	C290.522186,252.400726 289.020966,251.971542 287.236023,251.356583 
	C288.594513,251.506943 290.236755,251.843094 291.951202,252.504578 
z"
      />
      <path
        fill="#9A96D5"
        opacity="1.000000"
        stroke="none"
        d="
M119.175171,408.870697 
	C119.149071,410.073578 118.861519,411.409119 118.235092,412.869141 
	C118.235382,411.663574 118.574554,410.333435 119.175171,408.870697 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M870.585815,756.040344 
	C871.040710,754.645142 871.827637,753.179749 872.873047,751.542480 
	C872.393738,752.903748 871.655823,754.437012 870.585815,756.040344 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M260.930542,308.147858 
	C261.817200,309.079681 262.446014,310.217926 263.017090,311.681763 
	C262.197144,310.789673 261.434967,309.571960 260.930542,308.147858 
z"
      />
      <path
        fill="#9A96D5"
        opacity="1.000000"
        stroke="none"
        d="
M134.825836,445.028748 
	C136.002792,445.065979 137.305435,445.369965 138.796890,445.975830 
	C137.640991,445.950287 136.296265,445.622864 134.825836,445.028748 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M254.940369,290.139465 
	C255.784119,291.087738 256.369965,292.236847 256.887146,293.706146 
	C256.106445,292.797668 255.394470,291.569000 254.940369,290.139465 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M248.938965,242.587830 
	C247.985046,243.077393 246.956177,243.250412 245.576660,243.609436 
	C245.219589,240.377548 246.595230,240.258514 248.938965,242.587830 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M297.976013,254.495209 
	C296.823456,254.427872 295.621490,254.030624 294.182007,253.411560 
	C295.271881,253.514938 296.599213,253.840103 297.976013,254.495209 
z"
      />
      <path
        fill="#9A96D5"
        opacity="1.000000"
        stroke="none"
        d="
M150.188324,446.373962 
	C151.174805,445.556610 152.335602,444.945892 153.836914,444.456482 
	C152.905823,445.245392 151.634232,445.912994 150.188324,446.373962 
z"
      />
      <path
        fill="#9A96D5"
        opacity="1.000000"
        stroke="none"
        d="
M168.818695,431.017029 
	C168.923523,429.889374 169.270126,428.602478 169.940689,427.164703 
	C169.863251,428.295135 169.461838,429.576447 168.818695,431.017029 
z"
      />
      <path
        fill="#9A96D5"
        opacity="1.000000"
        stroke="none"
        d="
M117.617340,426.194458 
	C118.433670,427.174927 119.049034,428.335114 119.543365,429.836365 
	C118.753677,428.909698 118.085030,427.641937 117.617340,426.194458 
z"
      />
      <path
        fill="#9A96D5"
        opacity="1.000000"
        stroke="none"
        d="
M135.812729,393.616455 
	C135.101349,394.399475 134.218201,394.983093 133.016785,395.429108 
	C133.679321,394.666687 134.660141,394.041870 135.812729,393.616455 
z"
      />
      <path
        fill="#9A96D5"
        opacity="1.000000"
        stroke="none"
        d="
M170.386597,411.811340 
	C169.602753,411.101166 169.017212,410.218048 168.569702,409.016785 
	C169.333450,409.678528 169.959167,410.658508 170.386597,411.811340 
z"
      />
      <path
        fill="#9A96D5"
        opacity="1.000000"
        stroke="none"
        d="
M164.867889,437.251709 
	C165.228882,436.025391 165.926331,434.759979 166.919067,433.376892 
	C166.544357,434.576996 165.874359,435.894836 164.867889,437.251709 
z"
      />
      <path
        fill="#9A96D5"
        opacity="1.000000"
        stroke="none"
        d="
M124.780502,438.655823 
	C125.999725,439.096283 127.217072,439.882324 128.525101,440.968262 
	C127.336731,440.512573 126.057678,439.756989 124.780502,438.655823 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M447.797943,192.782364 
	C447.208344,193.674713 446.417297,194.356888 445.308777,194.994934 
	C445.859741,194.157913 446.728119,193.365051 447.797943,192.782364 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M252.948929,283.132843 
	C253.503006,283.747772 253.802200,284.557312 254.035919,285.689148 
	C253.544968,285.116791 253.119522,284.222137 252.948929,283.132843 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M318.976166,261.621704 
	C318.177582,261.786774 317.328613,261.640503 316.227356,261.289612 
	C316.958618,261.160126 317.942200,261.235260 318.976166,261.621704 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M542.004028,227.420380 
	C541.127380,227.354935 540.163879,226.978302 539.137695,226.286835 
	C540.022461,226.351059 540.969849,226.730133 542.004028,227.420380 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M591.887329,263.806641 
	C591.020508,263.641388 590.106812,263.135956 589.132446,262.301758 
	C589.994629,262.470825 590.917480,262.968658 591.887329,263.806641 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M583.995972,694.576782 
	C584.870911,694.644165 585.831848,695.022766 586.856018,695.715576 
	C585.973450,695.649170 585.027649,695.268555 583.995972,694.576782 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M589.995972,698.576721 
	C590.870911,698.644104 591.831848,699.022705 592.856018,699.715515 
	C591.973511,699.649109 591.027710,699.268494 589.995972,698.576721 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M545.994385,703.550293 
	C545.107788,703.452148 544.152283,703.029602 543.135864,702.289612 
	C544.025146,702.390076 544.975220,702.807983 545.994385,703.550293 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M536.004028,223.420380 
	C535.127380,223.354935 534.163879,222.978302 533.137695,222.286835 
	C534.022461,222.351059 534.969849,222.730133 536.004028,223.420380 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M559.994995,713.545776 
	C559.110962,713.447815 558.156860,713.025818 557.137451,712.288574 
	C558.023071,712.389404 558.973999,712.805542 559.994995,713.545776 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M256.928772,296.149872 
	C257.523010,296.719971 257.862488,297.496155 258.196381,298.594910 
	C257.685211,298.063629 257.179596,297.209778 256.928772,296.149872 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M258.928772,302.149841 
	C259.523010,302.719971 259.862488,303.496155 260.196381,304.594910 
	C259.685211,304.063599 259.179596,303.209747 258.928772,302.149841 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M470.019470,347.431763 
	C469.134399,347.363953 468.159058,346.983826 467.127380,346.284485 
	C468.023773,346.350006 468.976471,346.734711 470.019470,347.431763 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M257.430725,167.979813 
	C257.361359,168.860657 256.979736,169.831619 256.280823,170.864380 
	C256.348511,169.974091 256.733490,169.022003 257.430725,167.979813 
z"
      />
      <path
        fill="#635EBD"
        opacity="1.000000"
        stroke="none"
        d="
M140.762558,344.938416 
	C141.053223,345.777252 141.077271,346.583527 140.973160,347.773163 
	C140.387878,347.216919 139.930740,346.277313 139.720505,345.169952 
	C140.143570,344.970093 140.319748,344.937988 140.762558,344.938416 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M456.003632,337.420166 
	C455.120697,337.354980 454.150513,336.978394 453.125244,336.283813 
	C454.018921,336.346832 454.967651,336.727783 456.003632,337.420166 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M575.994446,251.548187 
	C575.104065,251.449127 574.144043,251.025650 573.127441,250.283783 
	C574.022217,250.384842 574.973511,250.804291 575.994446,251.548187 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M331.005554,238.507050 
	C331.793884,238.299149 332.658875,238.391266 333.777802,238.687820 
	C333.048553,238.863846 332.065430,238.835434 331.005554,238.507050 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M770.040283,444.591248 
	C769.407837,444.148865 768.780457,443.369904 768.140503,442.262970 
	C768.767090,442.708221 769.406250,443.481445 770.040283,444.591248 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M776.039429,452.592651 
	C775.406006,452.150513 774.777893,451.371490 774.137451,450.264465 
	C774.765015,450.709564 775.404907,451.482697 776.039429,452.592651 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M583.994995,257.546631 
	C583.103149,257.448914 582.141357,257.027008 581.125732,256.284851 
	C582.023010,256.383911 582.973999,256.803223 583.994995,257.546631 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M548.019348,231.433563 
	C547.140259,231.365265 546.171509,230.984726 545.138916,230.288086 
	C546.026611,230.355103 546.978149,230.738205 548.019348,231.433563 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M772.357056,857.948364 
	C772.448853,857.036133 772.882385,856.066345 773.632080,855.012695 
	C773.531738,855.916138 773.115295,856.903503 772.357056,857.948364 
z"
      />
      <path
        fill="#635EBD"
        opacity="1.000000"
        stroke="none"
        d="
M139.286591,336.959900 
	C139.655869,338.230316 139.815643,339.807159 139.808899,341.837463 
	C139.453949,340.616089 139.265518,338.941193 139.286591,336.959900 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M668.091309,542.478943 
	C667.449219,542.062378 666.806274,541.316345 666.146240,540.252441 
	C666.782959,540.672913 667.436707,541.411194 668.091309,542.478943 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M744.031860,411.572205 
	C743.479309,411.407928 742.891846,410.948700 742.280029,410.188660 
	C742.836060,410.350952 743.416443,410.814087 744.031860,411.572205 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M650.071655,313.349731 
	C649.524475,313.235565 648.912598,312.849670 648.278748,312.175476 
	C648.840149,312.284119 649.423523,312.681030 650.071655,313.349731 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M648.060059,311.458679 
	C647.508789,311.320007 646.906311,310.898102 646.282349,310.181152 
	C646.843567,310.315918 647.426147,310.745667 648.060059,311.458679 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M501.953247,338.672302 
	C502.529358,338.755524 503.176086,339.116699 503.849640,339.770630 
	C503.258942,339.692322 502.641418,339.321259 501.953247,338.672302 
z"
      />
      <path
        fill="#9A96D5"
        opacity="1.000000"
        stroke="none"
        d="
M119.956131,432.316711 
	C120.526466,432.556610 121.068039,433.094147 121.555145,433.948700 
	C120.976250,433.715240 120.451813,433.164795 119.956131,432.316711 
z"
      />
      <path
        fill="#9A96D5"
        opacity="1.000000"
        stroke="none"
        d="
M156.307556,444.044434 
	C156.558914,443.480072 157.103745,442.949066 157.962555,442.489380 
	C157.718033,443.066406 157.159531,443.572083 156.307556,444.044434 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M453.190277,189.924759 
	C452.838196,190.130844 452.664215,190.107590 452.235626,190.017227 
	C452.562714,189.377792 453.144379,188.805481 454.063721,188.309326 
	C454.057068,188.822144 453.712738,189.258774 453.190277,189.924759 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M658.534973,500.067932 
	C658.692139,499.544220 659.125671,498.976654 659.848816,498.391327 
	C659.696045,498.923737 659.253662,499.473877 658.534973,500.067932 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M689.982300,785.706543 
	C689.447327,785.864136 688.891113,785.737305 688.141846,785.391357 
	C688.619568,785.255493 689.290344,785.338867 689.982300,785.706543 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M459.534607,182.011597 
	C459.564453,182.678497 459.265778,183.425339 458.678406,184.280884 
	C458.661835,183.623581 458.933990,182.857544 459.534607,182.011597 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M656.542725,502.060181 
	C656.680481,501.507935 657.101501,500.903900 657.817749,500.278687 
	C657.683960,500.841125 657.255005,501.424744 656.542725,502.060181 
z"
      />
      <path
        fill="#635EBD"
        opacity="1.000000"
        stroke="none"
        d="
M140.600433,301.954132 
	C140.793869,302.497742 140.737320,303.148682 140.347473,303.896942 
	C140.126266,303.349976 140.238358,302.705750 140.600433,301.954132 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M630.059570,295.462646 
	C629.508423,295.322662 628.906616,294.899170 628.284180,294.180542 
	C628.845276,294.316681 629.427063,294.747894 630.059570,295.462646 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M616.051331,283.452271 
	C615.467957,283.340027 614.824951,282.936981 614.157104,282.233337 
	C614.752136,282.342346 615.371887,282.751923 616.051331,283.452271 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M776.199707,850.955688 
	C776.100525,850.426392 776.288635,849.890808 776.692627,849.163452 
	C776.768066,849.630981 776.627502,850.290161 776.199707,850.955688 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M774.211670,854.947388 
	C774.109436,854.418274 774.293640,853.884949 774.693237,853.161743 
	C774.771790,853.628906 774.634949,854.286011 774.211670,854.947388 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M521.948730,354.549622 
	C522.532166,354.660767 523.175903,355.062622 523.845337,355.765137 
	C523.250427,355.657288 522.629700,355.248779 521.948730,354.549622 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M284.853271,363.156677 
	C285.379395,363.453064 285.695801,363.926575 286.032166,364.688965 
	C285.582611,364.429810 285.113098,363.881805 284.853271,363.156677 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M328.963043,265.770142 
	C328.441193,265.879395 327.906982,265.702026 327.168274,265.324982 
	C327.626068,265.244690 328.288391,265.364105 328.963043,265.770142 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M732.060669,397.452209 
	C731.509155,397.316040 730.904968,396.896942 730.277710,396.182983 
	C730.839050,396.315155 731.423462,396.742218 732.060669,397.452209 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M586.046448,259.330566 
	C585.472717,259.244629 584.828491,258.881287 584.157471,258.225586 
	C584.745728,258.306580 585.360840,258.679871 586.046448,259.330566 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M282.849182,359.158569 
	C283.375732,359.453766 283.693298,359.926697 284.031677,360.688599 
	C283.581726,360.430450 283.110962,359.883362 282.849182,359.158569 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M716.060730,379.453430 
	C715.508789,379.316620 714.904541,378.897003 714.279297,378.182129 
	C714.841675,378.314758 715.425049,378.742676 716.060730,379.453430 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M838.535156,576.058350 
	C838.674805,575.506531 839.098755,574.903931 839.817322,574.277893 
	C839.681152,574.838867 839.250305,575.423218 838.535156,576.058350 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M336.017456,240.294083 
	C336.552063,240.137329 337.107788,240.264969 337.857025,240.611115 
	C337.379852,240.745926 336.709229,240.662201 336.017456,240.294083 
z"
      />
      <path
        fill="#9A96D5"
        opacity="1.000000"
        stroke="none"
        d="
M127.581726,397.941711 
	C127.398079,398.508331 126.918915,399.103210 126.122902,399.697937 
	C126.299438,399.121857 126.792824,398.545959 127.581726,397.941711 
z"
      />
      <path
        fill="#9A96D5"
        opacity="1.000000"
        stroke="none"
        d="
M123.447311,401.904022 
	C123.372993,402.534088 123.002289,403.235077 122.311691,403.961731 
	C122.378174,403.316559 122.764549,402.645752 123.447311,401.904022 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M714.068176,377.464294 
	C713.545715,377.306702 712.979492,376.873077 712.394897,376.150238 
	C712.925903,376.303467 713.475098,376.745880 714.068176,377.464294 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M333.986084,267.684387 
	C333.453186,267.855316 332.893707,267.742584 332.138733,267.412415 
	C332.615356,267.263519 333.287445,267.332153 333.986084,267.684387 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M802.092773,489.495026 
	C801.631104,489.311310 801.149292,488.882416 800.714722,488.190063 
	C801.198853,488.367706 801.635742,488.808777 802.092773,489.495026 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M323.992889,263.668030 
	C323.462372,263.828064 322.904449,263.707642 322.151337,263.368408 
	C322.625916,263.228912 323.295715,263.308228 323.992889,263.668030 
z"
      />
      <path
        fill="#9A96D5"
        opacity="1.000000"
        stroke="none"
        d="
M168.023193,406.744385 
	C167.444824,406.465424 166.921738,405.894318 166.515182,405.018768 
	C167.113983,405.293640 167.596237,405.872925 168.023193,406.744385 
z"
      />
      <path
        fill="#9A96D5"
        opacity="1.000000"
        stroke="none"
        d="
M117.201736,418.090698 
	C117.500305,418.690643 117.609993,419.522522 117.558243,420.750580 
	C117.268822,420.205353 117.140839,419.264008 117.201736,418.090698 
z"
      />
      <path
        fill="#1B0FAB"
        opacity="1.000000"
        stroke="none"
        d="
M649.688354,834.360840 
	C649.815613,834.152954 650.198425,834.139954 651.151367,834.212341 
	C651.129028,834.383728 650.536438,834.469727 649.688354,834.360840 
z"
      />
      <path
        fill="#1B0FAB"
        opacity="1.000000"
        stroke="none"
        d="
M630.538208,834.493042 
	C630.661743,834.242432 631.041504,834.185242 631.999146,834.144775 
	C631.982849,834.336426 631.388733,834.511414 630.538208,834.493042 
z"
      />
      <path
        fill="#9A96D5"
        opacity="1.000000"
        stroke="none"
        d="
M145.971436,446.741943 
	C146.055603,446.486908 146.273590,446.402496 146.831360,446.405029 
	C146.815842,446.632202 146.460556,446.772369 145.971436,446.741943 
z"
      />
      <path
        fill="#1B0FAB"
        opacity="1.000000"
        stroke="none"
        d="
M365.635193,517.660400 
	C365.846619,517.783569 365.870056,518.159180 365.834167,519.097778 
	C365.665588,519.078186 365.556396,518.495483 365.635193,517.660400 
z"
      />
      <path
        fill="#9A96D5"
        opacity="1.000000"
        stroke="none"
        d="
M156.035675,395.269043 
	C155.844040,395.491333 155.516342,395.501678 155.066605,395.283325 
	C155.262924,395.055481 155.581253,395.056305 156.035675,395.269043 
z"
      />
      <path
        fill="#9A96D5"
        opacity="1.000000"
        stroke="none"
        d="
M162.794861,439.241394 
	C162.599945,439.223816 162.480896,438.789398 162.480896,438.789398 
	C162.480896,438.789398 162.800858,438.472504 163.018158,438.532440 
	C163.360306,438.890656 163.278412,439.112854 162.794861,439.241394 
z"
      />
      <path
        fill="#9A96D5"
        opacity="1.000000"
        stroke="none"
        d="
M117.251816,422.970642 
	C117.506203,423.055603 117.590126,423.274170 117.584702,423.832367 
	C117.357353,423.816101 117.219360,423.460144 117.251816,422.970642 
z"
      />
      <path
        fill="#9A96D5"
        opacity="1.000000"
        stroke="none"
        d="
M121.012939,405.903625 
	C121.489670,406.097046 121.501755,406.363464 121.017822,406.673126 
	C120.595581,406.450104 120.604416,406.203552 121.012939,405.903625 
z"
      />
      <path
        fill="#1B0FAC"
        opacity="1.000000"
        stroke="none"
        d="
M853.988037,189.841736 
	C865.265564,194.408981 872.805359,208.125885 870.120300,219.190796 
	C866.678345,233.374557 855.481750,242.045288 841.617554,240.889618 
	C838.235413,240.607681 834.944153,239.235016 831.354248,237.824677 
	C830.955322,236.375183 831.108704,234.885605 830.627441,234.640091 
	C820.954712,229.705566 819.686096,221.054642 819.933899,211.507080 
	C820.286743,197.910141 836.450806,186.626251 849.318787,190.975433 
	C850.600952,191.408813 852.418823,190.257553 853.988037,189.841736 
z"
      />
      <path
        fill="#231FB0"
        opacity="1.000000"
        stroke="none"
        d="
M853.629272,189.757477 
	C852.418823,190.257553 850.600952,191.408813 849.318787,190.975433 
	C836.450806,186.626251 820.286743,197.910141 819.933899,211.507080 
	C819.686096,221.054642 820.954712,229.705566 830.627441,234.640091 
	C831.108704,234.885605 830.955322,236.375183 831.051025,237.597382 
	C817.524963,230.887268 814.745056,218.561646 817.755432,206.407272 
	C820.786011,194.171387 834.757202,185.532623 846.867737,187.549850 
	C849.059082,187.914856 851.139038,188.948349 853.629272,189.757477 
z"
      />
      <path
        fill="#1A0DAB"
        opacity="1.000000"
        stroke="none"
        d="
M932.529785,609.483887 
	C932.542175,609.512268 932.517456,609.455505 932.529785,609.483887 
z"
      />
      <path
        fill="#1A0DAB"
        opacity="1.000000"
        stroke="none"
        d="
M933.488342,617.481567 
	C933.516418,617.469177 933.460266,617.494019 933.488342,617.481567 
z"
      />
      <path
        fill="#1A0DAB"
        opacity="1.000000"
        stroke="none"
        d="
M914.498535,679.477173 
	C914.526550,679.463928 914.470581,679.490356 914.498535,679.477173 
z"
      />
      <path
        fill="#1A0DAB"
        opacity="1.000000"
        stroke="none"
        d="
M915.984619,724.010132 
	C915.685913,723.868652 915.441833,723.666992 915.118652,723.208252 
	C914.999084,723.000000 915.013855,722.981140 915.013855,722.981140 
	C915.301819,723.127869 915.548828,723.325684 915.888062,723.772400 
	C916.021301,723.970154 915.998962,723.998901 915.984619,724.010132 
z"
      />
      <path
        fill="#1A0DAB"
        opacity="1.000000"
        stroke="none"
        d="
M914.989624,723.007385 
	C914.980103,723.014771 914.643982,722.780151 914.643982,722.780151 
	C914.643982,722.780151 914.914124,722.789368 914.963989,722.885254 
	C915.013855,722.981140 914.999084,723.000000 914.989624,723.007385 
z"
      />
      <path
        fill="#FCFCFE"
        opacity="1.000000"
        stroke="none"
        d="
M396.381622,485.179382 
	C397.637787,482.376007 399.122070,479.650909 400.115204,476.757263 
	C411.583008,443.344360 432.960907,418.560150 464.862762,403.326630 
	C483.921570,394.225891 504.322235,391.273468 525.380737,391.922028 
	C549.118469,392.653076 571.195740,398.481598 590.860229,412.157318 
	C611.435364,426.466339 624.178955,446.329071 631.395874,470.011841 
	C632.602478,473.971344 633.373352,478.063293 634.570618,482.026093 
	C635.398193,484.765564 634.458496,485.739105 631.798340,485.736725 
	C610.475769,485.717560 589.152832,485.763123 567.831421,485.601349 
	C566.663574,485.592468 564.994873,484.157837 564.428223,482.971771 
	C559.777466,473.236664 553.999390,464.580811 544.183167,459.228943 
	C522.906250,447.628662 494.436218,453.313538 479.390411,472.260498 
	C478.874451,472.910248 478.344330,473.548737 477.482910,474.707336 
	C470.981934,483.441895 467.111359,492.833618 465.025787,502.693909 
	C460.632996,523.462524 460.791870,544.249512 467.502350,564.634399 
	C470.675323,574.273254 475.088074,583.263000 482.864624,590.179932 
	C482.864624,590.179932 482.865265,590.097839 482.915283,590.370483 
	C483.271729,590.833069 483.578186,591.023010 483.884644,591.213013 
	C483.884644,591.213013 483.903473,591.132996 483.934814,591.475952 
	C486.412018,593.560974 488.857910,595.302917 491.303802,597.044922 
	C491.303802,597.044922 491.358887,597.117004 491.483704,597.483093 
	C492.946533,598.684326 494.284546,599.519409 495.806946,600.560486 
	C496.344391,600.768799 496.697479,600.771118 497.305298,600.989136 
	C498.562012,601.622986 499.563995,602.041077 500.824951,602.671570 
	C501.730499,602.898987 502.377045,602.913879 503.185791,603.038391 
	C503.347992,603.147949 503.737427,603.187500 503.767151,603.451172 
	C504.534271,603.876892 505.271606,604.038879 506.279602,604.288086 
	C506.928680,604.374207 507.307068,604.373169 507.974487,604.615234 
	C516.329224,607.095398 524.359619,607.573730 532.598755,604.392212 
	C533.250854,604.344360 533.620544,604.279907 534.209473,604.388123 
	C535.758057,603.863708 537.087463,603.166626 538.416931,602.469543 
	C540.127258,601.833923 541.837585,601.198303 544.141113,600.349365 
	C545.301819,599.567444 545.869385,598.998779 546.436951,598.430176 
	C556.247803,592.738464 561.384277,583.574097 565.372070,573.035278 
	C565.975708,572.574036 566.285278,572.415527 566.594788,572.256958 
	C584.879089,572.372253 603.163330,572.492920 621.447693,572.599121 
	C625.887268,572.624878 630.327026,572.602966 636.270508,572.602966 
	C632.796265,583.320374 630.372559,593.264526 626.415039,602.555359 
	C613.121704,633.763672 589.480164,653.543945 557.102051,662.950439 
	C550.768677,664.790405 544.121216,665.549500 537.058167,666.966858 
	C535.207275,667.355286 533.916870,667.583923 532.293030,667.627808 
	C530.465454,667.579407 528.971313,667.715820 527.061768,667.722656 
	C521.938293,667.679321 517.230225,667.765564 512.177856,667.684326 
	C510.345886,667.615845 508.858185,667.714905 507.096008,667.614624 
	C505.528687,667.426453 504.235809,667.437744 502.942932,667.448975 
	C497.855255,666.164978 492.782013,664.820068 487.677551,663.606873 
	C453.739075,655.540527 428.438568,635.907837 410.762939,606.117676 
	C408.489319,602.285767 406.628296,598.208984 404.390411,593.718872 
	C404.051392,592.854065 403.793610,592.725342 403.361145,592.488037 
	C402.925629,591.969543 402.560303,591.768921 402.203003,591.195740 
	C401.225769,588.140198 400.240479,585.457214 399.255188,582.774231 
	C399.255188,582.774231 399.145996,582.942566 399.214905,582.484375 
	C397.953705,577.936584 396.623627,573.847107 395.293579,569.757568 
	C395.293579,569.757568 395.143646,569.971436 395.220215,569.474487 
	C394.628418,566.237488 393.960052,563.497375 393.291656,560.757263 
	C393.291656,560.757263 393.147766,560.964111 393.221191,560.449646 
	C392.924774,555.294067 392.554901,550.652893 392.367462,545.648071 
	C392.428284,534.854797 392.306671,524.425232 392.255981,513.540527 
	C392.662872,508.132477 392.998901,503.179596 393.334900,498.226746 
	C393.334930,498.226746 393.122131,498.019043 393.592377,497.965363 
	C394.760803,494.473389 395.459015,491.035095 396.365540,487.333374 
	C396.504608,486.454620 396.435333,485.839233 396.376587,485.192078 
	C396.387146,485.160309 396.381622,485.179382 396.381622,485.179382 
z"
      />
      <path
        fill="#F7F6FC"
        opacity="1.000000"
        stroke="none"
        d="
M486.657104,764.716797 
	C504.419708,752.265991 521.883179,740.013550 539.254517,727.631897 
	C541.882629,725.758728 543.544373,725.930664 545.449585,728.525391 
	C549.271912,733.730835 553.182129,738.883911 557.310425,743.847107 
	C559.396729,746.355286 558.787048,747.794800 556.415222,749.433838 
	C533.968262,764.945007 511.543610,780.488586 489.136688,796.057556 
	C486.978394,797.557312 485.765503,797.195129 484.425720,794.873047 
	C480.963745,788.872864 477.286255,782.993469 473.568970,777.145874 
	C472.218414,775.021240 472.251038,773.765198 474.650818,772.388794 
	C478.660950,770.088806 482.447388,767.398865 486.657104,764.716797 
z"
      />
      <path
        fill="#F7F6FC"
        opacity="1.000000"
        stroke="none"
        d="
M490.141479,286.245667 
	C511.284180,271.453613 532.136169,256.869995 553.490356,241.935150 
	C558.571655,250.109222 563.024353,257.001068 567.051636,264.133148 
	C567.498047,264.923706 565.896606,267.643646 564.602295,268.523712 
	C552.420166,276.806824 539.992798,284.730713 527.849365,293.068726 
	C517.764099,299.993561 507.965424,307.335876 497.463684,314.908661 
	C492.105804,308.072418 486.728302,301.211182 480.795074,293.640839 
	C483.802277,291.254242 486.826538,288.854156 490.141479,286.245667 
z"
      />
      <path
        fill="#F7F6FC"
        opacity="1.000000"
        stroke="none"
        d="
M269.681213,562.767578 
	C267.536957,568.150757 264.746796,568.214294 260.515900,565.195801 
	C255.019455,561.274475 249.067886,557.991028 242.710022,554.060730 
	C258.293701,528.767578 274.897278,504.385742 293.451569,480.810822 
	C300.930725,486.586456 307.924500,491.987244 314.433411,497.013641 
	C299.285278,519.224304 284.550598,540.828796 269.681213,562.767578 
z"
      />
      <path
        fill="#F7F7FC"
        opacity="1.000000"
        stroke="none"
        d="
M368.251953,394.240356 
	C381.699951,380.905609 394.873077,367.798676 408.106201,354.752594 
	C413.027344,349.901062 413.036224,350.015564 417.462128,354.935516 
	C421.990967,359.969910 426.642853,364.893646 432.372772,371.089813 
	C411.741791,391.266724 391.266357,411.291504 370.477234,431.623047 
	C364.054779,425.917450 357.791870,420.569672 351.914825,414.826996 
	C351.265991,414.193054 352.295776,410.826263 353.451447,409.538605 
	C358.108307,404.350006 363.120178,399.480011 368.251953,394.240356 
z"
      />
      <path
        fill="#F6F6FB"
        opacity="1.000000"
        stroke="none"
        d="
M662.812927,615.810181 
	C665.083435,613.154297 667.118896,610.763977 669.247742,608.263855 
	C676.166748,614.372620 682.309204,619.655457 688.207153,625.198425 
	C688.869141,625.820557 688.727417,628.489624 687.981750,629.243652 
	C668.477539,648.965881 648.869446,668.585999 629.130310,688.072815 
	C628.423950,688.770142 625.574524,688.536072 624.791077,687.716248 
	C619.405151,682.080444 614.303833,676.172668 609.098572,670.338562 
	C626.966309,652.208984 644.772095,634.142334 662.812927,615.810181 
z"
      />
      <path
        fill="#F7F6FC"
        opacity="1.000000"
        stroke="none"
        d="
M787.752686,480.536194 
	C788.189636,481.220734 788.311096,481.899841 788.656067,482.044037 
	C794.984314,484.689331 794.991455,489.018372 791.976624,494.562927 
	C791.431946,495.845764 790.885864,496.757507 789.722778,497.881897 
	C785.384338,504.024445 781.662964,509.954407 777.941528,515.884338 
	C777.941528,515.884338 777.960266,515.957886 777.598145,516.018738 
	C776.121338,517.969238 775.006714,519.858948 773.892090,521.748657 
	C773.892090,521.748657 773.906860,521.898499 773.520142,521.990234 
	C772.747925,523.032593 772.362427,523.983276 771.976929,524.933960 
	C771.431580,525.848511 770.886108,526.763062 769.709595,527.860107 
	C766.711121,531.672607 764.343750,535.302673 761.976318,538.932678 
	C761.431641,539.847839 760.886902,540.763000 759.712036,541.859741 
	C757.352417,544.611389 755.622864,547.181519 753.893372,549.751648 
	C753.893372,549.751648 753.902283,549.901306 753.496094,549.966797 
	C752.432373,550.515198 751.420471,550.861145 751.172363,551.502441 
	C748.862915,557.473755 745.192261,557.289612 740.726746,553.988281 
	C740.608032,553.900513 740.407776,553.923035 740.245544,553.894165 
	C740.245544,553.894104 740.097839,553.902771 740.032593,553.496521 
	C738.332153,552.052979 736.696838,551.015686 735.061584,549.978394 
	C734.161499,549.394287 733.261414,548.810242 732.158325,547.601929 
	C728.131287,544.157593 727.626160,540.946411 730.737732,537.235291 
	C730.998291,536.924683 730.858093,536.277954 730.904114,535.787415 
	C731.167908,535.285400 731.431702,534.783447 732.305847,534.120056 
	C734.645508,531.385803 736.374817,528.812988 738.104126,526.240173 
	C738.104126,526.240173 738.093140,526.096436 738.472290,526.015015 
	C739.263733,525.017944 739.676086,524.102356 740.088379,523.186768 
	C740.088379,523.186768 740.081116,523.078552 740.468994,523.024353 
	C742.578857,520.335022 744.300842,517.699890 746.022827,515.064758 
	C746.568054,514.153503 747.113220,513.242188 748.267395,512.117920 
	C749.260559,510.957031 749.644714,510.009155 750.028931,509.061310 
	C750.028931,509.061310 750.008606,509.001282 750.377563,508.974365 
	C755.460510,502.393646 760.263977,495.901154 764.826172,489.243378 
	C765.985291,487.551819 766.377136,485.334625 767.121521,483.358948 
	C767.309021,483.011383 767.496521,482.663849 768.280151,482.110901 
	C769.258301,480.958374 769.640320,480.011169 770.022339,479.063965 
	C770.103455,478.867065 770.184631,478.670166 770.796936,478.171509 
	C772.600159,476.680298 773.872253,475.490814 774.822937,474.601837 
	C779.074219,476.505585 783.003052,478.264893 786.931824,480.024200 
	C787.135010,480.110046 787.338135,480.195923 787.752686,480.536194 
z"
      />
      <path
        fill="#231FB0"
        opacity="1.000000"
        stroke="none"
        d="
M482.894470,589.791626 
	C475.088074,583.263000 470.675323,574.273254 467.502350,564.634399 
	C460.791870,544.249512 460.632996,523.462524 465.025787,502.693909 
	C467.111359,492.833618 470.981934,483.441895 477.424011,475.051453 
	C478.006378,475.905426 478.860321,477.178009 478.527832,477.916595 
	C475.222168,485.260468 471.235596,492.336761 468.451965,499.862457 
	C466.721252,504.541473 466.326874,509.841370 466.051788,514.901672 
	C465.668213,521.957825 465.982422,529.050537 465.952820,536.127441 
	C465.875732,554.548950 470.814270,571.344727 481.977020,586.177429 
	C482.607788,587.015625 482.622711,588.317322 482.894470,589.791626 
z"
      />
      <path
        fill="#0100A8"
        opacity="1.000000"
        stroke="none"
        d="
M567.077759,572.138672 
	C566.285278,572.415527 565.975708,572.574036 565.404053,572.692749 
	C566.257874,571.772156 567.368958,570.130188 568.490356,570.123169 
	C589.917053,569.988403 611.345032,570.042786 632.772644,570.085388 
	C633.210449,570.086304 633.647583,570.412781 634.085083,570.587341 
	C634.080505,571.065002 634.075928,571.542725 634.071350,572.020386 
	C611.901123,572.020386 589.730957,572.020386 567.077759,572.138672 
z"
      />
      <path
        fill="#0500A8"
        opacity="1.000000"
        stroke="none"
        d="
M392.185028,546.011780 
	C392.554901,550.652893 392.924774,555.294067 393.310089,560.360352 
	C392.287598,559.516418 390.430115,558.304321 390.337433,556.969543 
	C389.312164,542.209961 386.034027,527.472290 389.829956,512.444214 
	C390.165894,512.258972 390.312134,512.489685 390.303040,512.909607 
	C390.244171,522.734863 390.104065,532.141541 390.236908,541.544312 
	C390.258087,543.042297 391.504852,544.523071 392.185028,546.011780 
z"
      />
      <path
        fill="#0100A8"
        opacity="1.000000"
        stroke="none"
        d="
M532.626465,667.812622 
	C533.916870,667.583923 535.207275,667.355286 536.783630,667.274292 
	C538.521729,667.715637 539.973816,668.009338 541.322021,668.282043 
	C536.492798,670.683899 505.679871,670.805481 499.259186,668.314026 
	C500.353882,668.101685 501.280151,667.921997 502.574677,667.595642 
	C504.235809,667.437744 505.528687,667.426453 507.607178,667.709106 
	C509.769257,667.952576 511.145660,667.902222 512.522095,667.851807 
	C517.230225,667.765564 521.938293,667.679321 527.576416,667.806274 
	C529.879700,667.950500 531.253113,667.881531 532.626465,667.812622 
z"
      />
      <path
        fill="#0100A8"
        opacity="1.000000"
        stroke="none"
        d="
M598.000000,488.030090 
	C610.112366,488.030121 621.724792,488.030121 633.337158,488.030121 
	C633.337769,488.236084 633.338318,488.442047 633.338928,488.648010 
	C611.051941,488.648010 588.765015,488.648010 566.478088,488.648010 
	C566.474060,488.442017 566.470032,488.236023 566.466064,488.030029 
	C576.810730,488.030029 587.155334,488.030029 598.000000,488.030090 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M766.862000,483.543823 
	C766.377136,485.334625 765.985291,487.551819 764.826172,489.243378 
	C760.263977,495.901154 755.460510,502.393646 750.405884,508.988800 
	C755.577576,500.596313 761.090027,492.162506 766.862000,483.543823 
z"
      />
      <path
        fill="#0100A8"
        opacity="1.000000"
        stroke="none"
        d="
M504.199463,366.291504 
	C513.653931,365.809418 523.316650,365.665070 533.442078,365.529602 
	C532.811707,366.348206 531.735291,367.832031 530.622925,367.859497 
	C522.735901,368.054321 514.840942,367.990631 506.950226,367.884857 
	C506.097290,367.873444 505.255005,367.066956 504.199463,366.291504 
z"
      />
      <path
        fill="#0500A8"
        opacity="1.000000"
        stroke="none"
        d="
M425.876862,203.847626 
	C426.353790,203.890488 426.830688,203.933380 427.667542,203.981537 
	C422.431671,204.600906 416.824982,205.750717 411.243988,205.637283 
	C407.635895,205.563950 404.065399,203.641525 400.239014,202.273865 
	C400.000000,202.000000 400.000000,201.999390 400.200256,201.875885 
	C400.657196,201.561279 400.857544,201.322830 401.001526,201.037033 
	C403.085266,201.273102 405.168976,201.509186 407.771851,202.200302 
	C409.714691,202.837830 411.138367,203.020309 412.562073,203.202759 
	C415.911621,203.175446 419.261200,203.148117 423.392517,203.383301 
	C424.741791,203.713074 425.309326,203.780350 425.876862,203.847626 
z"
      />
      <path
        fill="#0500A8"
        opacity="1.000000"
        stroke="none"
        d="
M533.990234,604.215454 
	C533.620544,604.279907 533.250854,604.344360 532.130615,604.234314 
	C523.481873,604.163940 515.583679,604.268066 507.685455,604.372131 
	C507.307068,604.373169 506.928680,604.374207 506.252930,603.999146 
	C505.913055,603.434265 505.870544,603.245544 505.890320,603.067871 
	C505.952545,603.078979 506.076965,603.056458 506.076965,603.056458 
	C506.522400,602.836243 506.969269,602.421326 507.413025,602.424683 
	C516.075195,602.490417 524.737061,602.604858 533.732300,603.163635 
	C534.040466,603.817078 534.015320,604.016296 533.990234,604.215454 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M786.856323,479.697876 
	C783.003052,478.264893 779.074219,476.505585 774.822937,474.601837 
	C773.872253,475.490814 772.600159,476.680298 771.044434,477.969421 
	C773.208252,471.557465 773.825684,471.328613 779.203796,474.745911 
	C781.700989,476.332611 784.253418,477.832397 786.856323,479.697876 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M778.266113,515.821289 
	C781.662964,509.954407 785.384338,504.024445 789.424194,498.004211 
	C786.025269,503.862030 782.307983,509.810120 778.266113,515.821289 
z"
      />
      <path
        fill="#0100A8"
        opacity="1.000000"
        stroke="none"
        d="
M822.589600,603.964966 
	C822.900879,610.170654 823.000549,616.093872 822.992798,622.367126 
	C822.282043,622.913391 821.678772,623.109680 821.075500,623.306030 
	C821.244568,616.641479 821.413635,609.976990 821.582703,603.312500 
	C821.847839,603.435852 822.112976,603.559204 822.589600,603.964966 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M740.302734,554.210938 
	C740.407776,553.923035 740.608032,553.900513 740.726746,553.988281 
	C745.192261,557.289612 748.862915,557.473755 751.172363,551.502441 
	C751.420471,550.861145 752.432373,550.515198 753.421265,549.962524 
	C751.523926,553.187866 749.295288,556.483032 746.945251,559.957703 
	C744.144409,557.648193 742.252197,556.087952 740.302734,554.210938 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M730.613403,535.945190 
	C730.858093,536.277954 730.998291,536.924683 730.737732,537.235291 
	C727.626160,540.946411 728.131287,544.157593 732.034790,547.299133 
	C724.184814,543.642578 724.072754,542.922119 730.613403,535.945190 
z"
      />
      <path
        fill="#231FB0"
        opacity="1.000000"
        stroke="none"
        d="
M503.737427,603.187500 
	C503.737427,603.187500 503.347992,603.147949 502.950806,602.787354 
	C501.891052,602.437500 501.228516,602.448303 500.565979,602.459106 
	C499.563995,602.041077 498.562012,601.622986 497.124512,600.780884 
	C496.333527,600.356079 495.978027,600.355286 495.622559,600.354492 
	C494.284546,599.519409 492.946533,598.684326 491.518982,597.511597 
	C496.239441,598.903076 501.049377,600.632202 505.968140,602.708862 
	C506.076965,603.056458 505.952545,603.078979 505.538513,603.034607 
	C504.662140,603.056030 504.199799,603.121765 503.737427,603.187500 
z"
      />
      <path
        fill="#0500A8"
        opacity="1.000000"
        stroke="none"
        d="
M392.949524,498.247620 
	C392.998901,503.179596 392.662872,508.132477 392.020508,513.441772 
	C391.246765,513.362000 390.779449,512.925842 390.312134,512.489685 
	C390.312134,512.489685 390.165894,512.258972 390.093170,512.143921 
	C390.868347,507.442108 391.716248,502.855316 392.949524,498.247620 
z"
      />
      <path
        fill="#0500A8"
        opacity="1.000000"
        stroke="none"
        d="
M396.157196,487.596802 
	C395.459015,491.035095 394.760803,494.473389 393.685150,498.081543 
	C390.185303,492.835571 394.418915,488.297394 395.087463,483.333862 
	C395.457001,483.724854 395.826569,484.115845 396.288879,484.843109 
	C396.381622,485.179382 396.387146,485.160309 396.271118,485.781860 
	C396.155823,486.801178 396.156494,487.199005 396.157196,487.596802 
z"
      />
      <path
        fill="#0500A8"
        opacity="1.000000"
        stroke="none"
        d="
M395.088928,570.106750 
	C396.623627,573.847107 397.953705,577.936584 399.289215,582.416992 
	C395.677490,581.379150 393.493073,575.261414 395.088928,570.106750 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M762.299011,538.858032 
	C764.343750,535.302673 766.711121,531.672607 769.408020,527.976807 
	C767.365601,531.535095 764.993652,535.159180 762.299011,538.858032 
z"
      />
      <path
        fill="#231FB0"
        opacity="1.000000"
        stroke="none"
        d="
M491.434906,596.656372 
	C488.857910,595.302917 486.412018,593.560974 483.893738,591.476440 
	C487.364563,591.394531 490.551300,592.192871 491.434906,596.656372 
z"
      />
      <path
        fill="#0500A8"
        opacity="1.000000"
        stroke="none"
        d="
M399.065033,583.109375 
	C400.240479,585.457214 401.225769,588.140198 402.143921,591.550720 
	C402.052917,592.516968 402.029053,592.755737 402.002716,592.997253 
	C402.000244,593.000000 401.993896,593.007324 401.993896,593.007324 
	C400.001770,590.130371 396.799774,587.648071 399.065033,583.109375 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M754.211731,549.694946 
	C755.622864,547.181519 757.352417,544.611389 759.410400,541.976318 
	C758.002563,544.486938 756.266357,547.062561 754.211731,549.694946 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M745.699219,515.135132 
	C744.300842,517.699890 742.578857,520.335022 740.523071,523.027832 
	C741.918091,520.458862 743.646790,517.832214 745.699219,515.135132 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M737.786133,526.299805 
	C736.374817,528.812988 734.645508,531.385803 732.587646,534.023438 
	C733.995422,531.511963 735.731812,528.935730 737.786133,526.299805 
z"
      />
      <path
        fill="#0500A8"
        opacity="1.000000"
        stroke="none"
        d="
M393.084351,561.113220 
	C393.960052,563.497375 394.628418,566.237488 395.311401,569.380981 
	C390.533661,568.183655 391.911194,564.765808 393.084351,561.113220 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M774.210083,521.688354 
	C775.006714,519.858948 776.121338,517.969238 777.560913,516.011230 
	C776.766541,517.837830 775.647278,519.732910 774.210083,521.688354 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M735.126831,550.297607 
	C736.696838,551.015686 738.332153,552.052979 740.036987,553.422668 
	C738.468384,552.708984 736.830261,551.662964 735.126831,550.297607 
z"
      />
      <path
        fill="#0500A8"
        opacity="1.000000"
        stroke="none"
        d="
M395.715393,199.579666 
	C397.272644,199.612000 398.574829,199.842453 399.962646,200.715149 
	C400.032196,201.571396 400.016083,201.785385 400.000000,201.999390 
	C400.000000,201.999390 400.000000,202.000000 400.000000,202.000305 
	C398.486786,201.259674 396.973541,200.518738 395.715393,199.579666 
z"
      />
      <path
        fill="#0500A8"
        opacity="1.000000"
        stroke="none"
        d="
M403.431305,592.805908 
	C403.793610,592.725342 404.051392,592.854065 404.186462,593.424744 
	C403.981903,594.765198 403.795502,595.872986 403.609131,596.980774 
	C403.335144,597.024719 403.061188,597.068665 402.787231,597.112610 
	C402.523773,595.985352 402.260315,594.858093 401.995361,593.369080 
	C401.993896,593.007324 402.000244,593.000000 402.360901,592.963135 
	C402.958160,592.886169 403.194733,592.846008 403.431305,592.805908 
z"
      />
      <path
        fill="#635EBD"
        opacity="1.000000"
        stroke="none"
        d="
M534.209473,604.388123 
	C534.015320,604.016296 534.040466,603.817078 534.121460,603.322021 
	C535.358337,602.751221 536.539246,602.476318 538.068604,602.335510 
	C537.087463,603.166626 535.758057,603.863708 534.209473,604.388123 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M772.291016,524.864380 
	C772.362427,523.983276 772.747925,523.032593 773.444824,521.988892 
	C773.372559,522.862183 772.988831,523.828491 772.291016,524.864380 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M749.712219,509.120605 
	C749.644714,510.009155 749.260559,510.957031 748.565430,511.995026 
	C748.634949,511.116730 749.015259,510.148315 749.712219,509.120605 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M769.708130,479.129181 
	C769.640320,480.011169 769.258301,480.958374 768.565552,481.995453 
	C768.634521,481.121704 769.014221,480.158051 769.708130,479.129181 
z"
      />
      <path
        fill="#E3E2F3"
        opacity="1.000000"
        stroke="none"
        d="
M739.777832,523.244202 
	C739.676086,524.102356 739.263733,525.017944 738.544312,526.017944 
	C738.647217,525.168762 739.057251,524.235168 739.777832,523.244202 
z"
      />
      <path
        fill="#635EBD"
        opacity="1.000000"
        stroke="none"
        d="
M546.096008,598.354858 
	C545.869385,598.998779 545.301819,599.567444 544.427063,600.154785 
	C544.664917,599.542175 545.210022,598.910889 546.096008,598.354858 
z"
      />
      <path
        fill="#231FB0"
        opacity="1.000000"
        stroke="none"
        d="
M483.792145,590.952148 
	C483.578186,591.023010 483.271729,590.833069 482.875397,590.380127 
	C483.090240,590.308594 483.394928,590.499939 483.792145,590.952148 
z"
      />
      <path
        fill="#1B0FAB"
        opacity="1.000000"
        stroke="none"
        d="
M273.002075,248.112000 
	C273.170288,247.868866 273.354736,247.845734 273.766998,247.983551 
	C273.779236,248.455078 273.563660,248.765671 273.348083,249.076248 
	C273.238190,248.828156 273.128296,248.580063 273.002075,248.112000 
z"
      />
      <path
        fill="#564DBF"
        opacity="1.000000"
        stroke="none"
        d="
M412.371277,202.905365 
	C411.138367,203.020309 409.714691,202.837830 408.176025,202.280685 
	C409.434235,202.139999 410.807343,202.373978 412.371277,202.905365 
z"
      />
      <path
        fill="#564DBF"
        opacity="1.000000"
        stroke="none"
        d="
M426.033783,203.622101 
	C425.309326,203.780350 424.741791,203.713074 423.808533,203.417191 
	C424.406494,202.716095 425.370239,202.243622 426.662598,201.866943 
	C426.724396,202.440689 426.457550,202.918640 426.033783,203.622101 
z"
      />
      <path
        fill="#1B0FAB"
        opacity="1.000000"
        stroke="none"
        d="
M400.200256,201.875885 
	C400.016083,201.785385 400.032196,201.571396 400.090149,201.038254 
	C400.131989,200.719116 400.593506,200.804855 400.797516,200.920944 
	C400.857544,201.322830 400.657196,201.561279 400.200256,201.875885 
z"
      />
      <path
        fill="#564DBF"
        opacity="1.000000"
        stroke="none"
        d="
M390.303040,512.909607 
	C390.779449,512.925842 391.246765,513.362000 391.949585,513.896973 
	C392.306671,524.425232 392.428284,534.854797 392.367462,545.648071 
	C391.504852,544.523071 390.258087,543.042297 390.236908,541.544312 
	C390.104065,532.141541 390.244171,522.734863 390.303040,512.909607 
z"
      />
      <path
        fill="#635EBD"
        opacity="1.000000"
        stroke="none"
        d="
M507.974487,604.615234 
	C515.583679,604.268066 523.481873,604.163940 531.848145,604.217773 
	C524.359619,607.573730 516.329224,607.095398 507.974487,604.615234 
z"
      />
      <path
        fill="#1B0FAB"
        opacity="1.000000"
        stroke="none"
        d="
M512.177856,667.684326 
	C511.145660,667.902222 509.769257,667.952576 507.881653,667.908447 
	C508.858185,667.714905 510.345886,667.615845 512.177856,667.684326 
z"
      />
      <path
        fill="#1B0FAB"
        opacity="1.000000"
        stroke="none"
        d="
M532.293030,667.627808 
	C531.253113,667.881531 529.879700,667.950500 527.991760,667.935791 
	C528.971313,667.715820 530.465454,667.579407 532.293030,667.627808 
z"
      />
      <path
        fill="#635EBD"
        opacity="1.000000"
        stroke="none"
        d="
M503.767151,603.451233 
	C504.199799,603.121765 504.662140,603.056030 505.476257,603.023560 
	C505.870544,603.245544 505.913055,603.434265 505.982269,603.911987 
	C505.271606,604.038879 504.534271,603.876892 503.767151,603.451233 
z"
      />
      <path
        fill="#635EBD"
        opacity="1.000000"
        stroke="none"
        d="
M500.824951,602.671570 
	C501.228516,602.448303 501.891052,602.437500 502.788605,602.677734 
	C502.377045,602.913879 501.730499,602.898987 500.824951,602.671570 
z"
      />
      <path
        fill="#1B0FAB"
        opacity="1.000000"
        stroke="none"
        d="
M396.365540,487.333374 
	C396.156494,487.199005 396.155823,486.801178 396.260590,485.813629 
	C396.435333,485.839233 396.504608,486.454620 396.365540,487.333374 
z"
      />
      <path
        fill="#635EBD"
        opacity="1.000000"
        stroke="none"
        d="
M495.806946,600.560486 
	C495.978027,600.355286 496.333527,600.356079 496.869781,600.565125 
	C496.697479,600.771118 496.344391,600.768799 495.806946,600.560486 
z"
      />
      <path
        fill="#1B0FAB"
        opacity="1.000000"
        stroke="none"
        d="
M403.361145,592.488037 
	C403.194733,592.846008 402.958160,592.886169 402.363403,592.960388 
	C402.029053,592.755737 402.052917,592.516968 402.135864,591.923279 
	C402.560303,591.768921 402.925629,591.969543 403.361145,592.488037 
z"
      />
    </Icon>
  );
}
