export enum PricingPlanNames {
  FREE = "freeTier1",
  PAID_PLAN_1 = "paidTier1Standard",
  PAID_PLAN_2 = "paidTier2Autonomous",
  CUSTOM_PLAN = "customPlan",
}

export interface ExtraCompanyProps {
  isOnFreeTrial: boolean;
  isOnPaidPlan: boolean;
  currentSubscription: PricingPlanNames;
}

export type StringOrFunction = string | ((params: ExtraCompanyProps) => string);

export interface Plan {
  id: PricingPlanNames;
  title: string;
  titleColor: string;
  price?: number;
  priceYear?: number;
  priceId?: string;
  priceYearId?: string;
  description: string;
  badges: string[];
  upgradeButtonVariant: "primary" | "secondary" | "outline" | "gold";
  upgradeButtonBody: StringOrFunction;
  featureTitle: string | React.ReactNode;
  features: string[];
  specialBody?: string;
  specialBodyColor?: string;
  specialBackground?: string;
  specialTierBorder?: string;
  bookkeepingMonthlyCost: number;
  helpText?: string | React.ReactNode;
}

export enum SubscriptionBillingCycle {
  Monthly = "monthly",
  Yearly = "yearly",
}

// See gateway src/store/entities/PuzzlePricingPlan.ts and src/db/migrations/1710283655963-add-ai-add-on-plan.ts
export enum AddOnNames {
  ADDON_AI_CATEGORIZER = "addon1", // AI categorizer addon, $25 / month
  ADDON_MONTHLY_CHECKLIST_SMART = "addon2", // Monthly checklist - smart, $25 / month
  ADDON_RIPPLING = "addon3", // Rippling, $2/employee/month
}

export interface AddOn {
  id: AddOnNames;
  icon: React.ReactNode;
  title: string;
  price: number;
  priceYear: number;
  priceId?: string;
  priceYearId?: string;
  contentHeading: string;
  contentBullets: string[];
}
