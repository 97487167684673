import React, { useMemo, useState } from "react";
import { styled } from "@puzzle/ui";
import { useActiveCompany } from "components/companies";
import {
  IngressType,
  IntegrationConnectionCondition,
  IntegrationConnectionStatus,
  IntegrationType,
} from "graphql/types";
import { FinancialInstitutionGroup, IntegrationCategory } from "../setup/types";
import AccountListItem from "./AccountListItem";
import {
  getConnectionStatusForAccount,
  getIntegratedAccount,
  getIntegrationTypeForAccount,
} from "./ListItemUtils";

import { Exclamation } from "@puzzle/icons";
import Loader from "../../common/Loader";
import { CalendarDateTimeString } from "@puzzle/utils";
import { ConnectionSubText } from "../DetailDrawer/shared";
import { SelectStartDateModal } from "../setup/modals/SelectStartDateModal";
import { connectionContainerStyle, connectionCardStyle } from "./connectionCardStyles.css";
import { ConnectionCardHeader } from "./ConnectionCardHeader";
import { ConnectionCardBanner } from "./ConnectionCardBanner";

const AccountsList = styled("div", {
  marginTop: "$1",
});

export type IntegrationConnectionInfo = {
  integrationConnectionId?: string;
  status: IntegrationConnectionStatus;
  type: IntegrationType;
  lastSyncedAt?: CalendarDateTimeString | null;
  financialInstitutionId?: string;
  condition?: IntegrationConnectionCondition | null;
  ingressType?: IngressType;
};

const FinancialInstitutionCard = ({
  integrationCategory,
  financialInstitution,
  showFullContent,
}: {
  integrationCategory: IntegrationCategory;
  financialInstitution: FinancialInstitutionGroup;
  showFullContent: boolean;
}) => {
  const { company, integrationConnections: allIntegrationConnections } = useActiveCompany<true>();
  const [openSelectDate, onOpenSelectDateChange] = useState(false);

  const {
    financialInstitutionName,
    accounts,
    integrationConnections: connectionsForFinancialInstitution,
  } = financialInstitution;
  const testId = `financial-institution-section-${financialInstitutionName}`;

  const isArchived = accounts.length > 0 && accounts.every((account) => !!account.archivedAt);
  const hasActiveAccounts =
    accounts.length > 0 &&
    accounts.some(
      (account) => !account.archivedAt && account.status === IntegrationConnectionStatus.Ok
    );

  const sortedAccounts = useMemo(() => {
    return accounts.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }, [accounts]);

  const connectionInfo: IntegrationConnectionInfo | undefined = useMemo(() => {
    const integratedAccount = getIntegratedAccount(accounts);
    if (integratedAccount) {
      let connection = integratedAccount.connection;
      if (!connection) {
        const integrationType = getIntegrationTypeForAccount(integratedAccount);
        const possibleConnections = allIntegrationConnections.filter(
          (ic) => ic.type === integrationType
        );

        if (possibleConnections.length === 1) {
          connection = possibleConnections[0];
        }
      }

      return {
        ...connection,
        integrationConnectionId: connection?.id,
        status: connection?.status ?? getConnectionStatusForAccount(integratedAccount),
        type: connection?.type ?? getIntegrationTypeForAccount(integratedAccount),
        financialInstitutionId: integratedAccount.financialInstitution.id,
        condition: connection?.condition ?? null,
        ingressType: connection?.ingressType,
      };
    } else if (
      connectionsForFinancialInstitution.length === 1 &&
      connectionsForFinancialInstitution[0].type !== IntegrationType.DirectIngest
    ) {
      const connection = connectionsForFinancialInstitution[0];
      return {
        ...connection,
        integrationConnectionId: connection?.id,
      };
    }
    return undefined;
  }, [accounts, connectionsForFinancialInstitution, allIntegrationConnections]);

  return (
    <>
      <div className={connectionCardStyle} data-testid={testId}>
        {!financialInstitution ? (
          <Loader />
        ) : (
          <div className={connectionContainerStyle}>
            <ConnectionCardHeader
              isArchived={isArchived}
              showFullContent={showFullContent}
              financialInstitutionName={financialInstitution.financialInstitutionName}
              hasActiveAccounts={hasActiveAccounts}
              connectionInfo={connectionInfo}
              onOpenSelectDateChange={onOpenSelectDateChange}
            />

            <ConnectionCardBanner financialInstitutionName={financialInstitutionName} />
            {sortedAccounts.length > 0 && (
              <AccountsList>
                {sortedAccounts.map((account) => (
                  <AccountListItem
                    key={account.id}
                    account={account}
                    companyId={company.id}
                    showFullContent={showFullContent}
                  />
                ))}
              </AccountsList>
            )}

            {sortedAccounts.length === 0 && integrationCategory === IntegrationCategory.Other && (
              <ConnectionSubText status="warning">
                <Exclamation /> No accounts received
              </ConnectionSubText>
            )}
          </div>
        )}
      </div>

      <SelectStartDateModal
        open={openSelectDate}
        companyId={company.id}
        accounts={accounts}
        onOpenChange={onOpenSelectDateChange}
        isReconnect={true}
        connectionId={connectionInfo?.integrationConnectionId ?? ""}
        accountsLoading={accounts.length === 0 ?? false}
      />
    </>
  );
};

export default FinancialInstitutionCard;
