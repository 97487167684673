import React from "react";
import { AutocompleteMenu, styled } from "@puzzle/ui";
import { RecurrencePeriod } from "graphql/types";
import { DropdownButton } from "./../../../transactions/DropdownField";
import { Recurring } from "@puzzle/icons";

const Label = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "$0h",
});

interface RecurrenceSelectProps {
  onSelect: (recurrence: RecurrencePeriod) => void;
  value?: RecurrencePeriod | null;
  canEdit?: boolean;
  fullWidth?: boolean;
}

export type RecurrencePeriodItem = {
  key?: string;
  label: string;
};
export const recurrenceOptions = [
  { key: RecurrencePeriod.None, label: "Non-recurring" },
  { key: RecurrencePeriod.Monthly, label: "Monthly" },
  { key: RecurrencePeriod.Quarterly, label: "Quarterly" },
  { key: RecurrencePeriod.Yearly, label: "Yearly" },
] as RecurrencePeriodItem[];

const RecurrenceSelect = ({
  onSelect,
  value,
  canEdit,
  fullWidth = false,
}: RecurrenceSelectProps) => {
  const selectedValue =
    recurrenceOptions.find((o) => o.key === value) ??
    ({
      key: undefined,
      label: "Unknown",
    } as RecurrencePeriodItem);

  if (!canEdit) {
    return (
      <Label>
        {selectedValue?.label} {value !== RecurrencePeriod.None && <Recurring />}
      </Label>
    );
  }

  return (
    <AutocompleteMenu<RecurrencePeriodItem, false, false, false>
      value={selectedValue}
      label="Pick recurrence"
      options={recurrenceOptions}
      onChange={(e, value) => {
        if (!value) {
          return;
        }
        onSelect(value.key as RecurrencePeriod);
      }}
      trigger={
        <DropdownButton css={{ width: fullWidth ? "100%" : undefined }}>
          {selectedValue?.label}
        </DropdownButton>
      }
      getOptionKey={(o) => o.key ?? o.label}
      getOptionLabel={(o) => o.label}
    />
  );
};
export default RecurrenceSelect;
