import * as Types from '../types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
export type CompanySlackChannelFragment = { __typename?: 'CompanySlackChannel', id: string, knockChannelKey: Types.CompanyKnockChannelKey, subscribed: boolean, channelId?: string | null, channelName?: string | null };

export const CompanySlackChannelFragmentDoc = gql`
    fragment companySlackChannel on CompanySlackChannel {
  id
  knockChannelKey
  subscribed
  channelId
  channelName
}
    `;