// Adapted from https://github.com/apollographql/apollo-client/blob/d3c7218a0bcd1722f4e0c4636632744564456fb6/src/utilities/policies/pagination.ts
import { FieldPolicy } from "@apollo/client";
import { TupleToIntersection, mergeDeep } from "@apollo/client/utilities";
import { PageInfo } from "graphql/types";

type KeyArgs = FieldPolicy<any>["keyArgs"];

export type PaginationFieldPolicy = FieldPolicy<
  TExisting | null,
  TIncoming | null,
  TIncoming | null
>;

// export type TPageInfo = {
// hasNextPage: boolean;
// endCursor: string;
// total: number;

/**
 * Relay standardizes these. Without these, we have to keep track of all cursors.
 * Interestingly, they also add individual cursors to edges. (We skip edges.)
 */
// hasPreviousPage: boolean;
// startCursor: string;
// };
export type TPageInfo = PageInfo;
export type TPaginator = Readonly<
  {
    pageInfo: TPageInfo;
  } & {
    [x in string]: any;
  }
>;

export type TExisting = TPaginator;
export type TIncoming = TPaginator;

// Siiigh, I tried, but I don't know how to type this correctly.
// I wanted to support a single dynamic key that contains T[], with a mapped type for other possible properties.
// We'd also need the query type to know the variables...
// Just be careful! And prefer "items"!
export function concatWrappedPagination(
  keyArgs: KeyArgs = false,
  itemsKey = "items",
  getAfter: (args: Record<string, any>) => string = (args) => args.page.after,
  deepMerge = true
): PaginationFieldPolicy {
  return {
    keyArgs,

    read(existing) {
      return existing;
    },

    merge(existing, incoming, { args }) {
      if (args && !getAfter(args)) {
        // Initial fetch or refetch
        return incoming;
      }
      const existingItems = existing?.[itemsKey] || [];
      const incomingItems = incoming?.[itemsKey] || [];

      if (!(Array.isArray(existingItems) || Array.isArray(incomingItems))) {
        console.error("Attempted to grab pagination items, but did not find an array.");
      }

      const items = [...existingItems, ...incomingItems];

      if (deepMerge) {
        return mergeDeep(incoming, {
          [itemsKey]: items,
        });
      } else {
        return {
          ...incoming,
          [itemsKey]: items,
        } as TupleToIntersection<[TExisting, TIncoming]>;
      }
    },
  };
}
