import React from "react";
import {
  ripplingPaneContainerStyle,
  ripplingPaneContentStyle,
  ripplingPaneHeaderStyle,
} from "./connectRipplingPane.css";
import UpgradeButton from "components/featureGate/UpgradeButton";
import { SubscriptionBillingCycle, AddOnNames } from "components/monetization/types";
import useMonetization from "components/monetization/useMonetization";

export const UpgradeRipplingPane = () => {
  const { setSelectedCycle, updatePreSelectedAddOns } = useMonetization();

  const onClickUpgrade = async () => {
    await setSelectedCycle(SubscriptionBillingCycle.Monthly);
    updatePreSelectedAddOns([AddOnNames.ADDON_RIPPLING]);
  };

  return (
    <div className={ripplingPaneContainerStyle} style={{ height: "100%" }}>
      <div className={ripplingPaneHeaderStyle}>Unlock your Rippling integration</div>
      <div className={ripplingPaneContentStyle}>
        Upgrade to a monthly paid plan and select the rippling add-on to enable your Rippling
        integration and have your payroll data ingested automatically
      </div>
      <UpgradeButton onClick={onClickUpgrade} />
    </div>
  );
};
