import React, { useState } from "react";
import { Button, Dialog, useDialogReset } from "@puzzle/ui";
import { useCloseSchedule, useVoidSchedule } from "./useScheduleActions";
import { ScheduleWarningMessage, ScheduleWarningMode } from "./ScheduleWarningMessage";
import { ClosingDayInput } from "./ScheduleDrawer/ClosingDayInput";
import { PagedContractRevenueScheduleFragment } from "./graphql.generated";
import { CalendarDateString } from "scalars";
import { toCalendarDate } from "@internationalized/date";
import { Box, S } from "ve";

export const VoidOrCloseScheduleModal = ({
  existingSchedule,
  mode,
  onDone,
  open: _open,
  onOpenChange: _onOpenChange,
  ...props
}: React.ComponentProps<typeof Dialog> & {
  existingSchedule?: PagedContractRevenueScheduleFragment;
  mode?: ScheduleWarningMode;
  onDone?: () => void;
}) => {
  const [internalOpen, setInternalOpen] = useState(_open);
  const [closingDate, setClosingDate] = useState<CalendarDateString>();
  const voidSchedule = useVoidSchedule();
  const closeSchedule = useCloseSchedule();
  const open = _open ?? internalOpen;
  const onOpenChange = _onOpenChange ?? setInternalOpen;

  useDialogReset(open, () => {
    setClosingDate(undefined);
  });

  if (!mode) return null;

  return (
    <Dialog {...props} size="xsmall" open={open} onOpenChange={onOpenChange}>
      <Dialog.Title>
        Are you sure you want to {mode === "void" ? "void" : "close"} this schedule?
      </Dialog.Title>
      <Dialog.Body>
        <ScheduleWarningMessage mode={mode} />
        {mode === "close" && (
          <Box css={{ marginTop: S["2"] }}>
            <ClosingDayInput
              existingSchedule={existingSchedule}
              closingDate={closingDate}
              handleOnChange={(value) => {
                if (value) {
                  setClosingDate(toCalendarDate(value).toString());
                }
              }}
            />
          </Box>
        )}
      </Dialog.Body>
      <Dialog.Footer align="right">
        <Dialog.Actions>
          <Dialog.Close asChild>
            <Button variant="minimal">Cancel</Button>
          </Dialog.Close>
          <Button
            variant="negative"
            loading={voidSchedule.loading || closeSchedule.loading}
            disabled={mode === "close" && !closingDate}
            onClick={async () => {
              if (mode === "void") {
                await voidSchedule.mutation({ scheduleId: existingSchedule?.id });
              }

              if (mode === "close") {
                await closeSchedule.mutation({
                  scheduleId: existingSchedule?.id,
                  closedDay: closingDate,
                });
              }

              onDone?.();
            }}
          >
            {mode === "void" ? "Void" : "Close"}
          </Button>
        </Dialog.Actions>
      </Dialog.Footer>
    </Dialog>
  );
};
