import React, { useCallback, useState } from "react";
import { AccountWithIntegrationFragment } from "graphql/types";
import { useToasts } from "@puzzle/ui";
import { useFinancialInstitutions } from "../shared";
import ArchiveAccountConfirmationModal from "./ArchiveAccountConfirmationModal";
import FooterButton from "./FooterButton";

const ArchiveAccountButton = ({ account }: { account: AccountWithIntegrationFragment }) => {
  const { toast } = useToasts();
  const { archiveAccount: setAccountArchived } = useFinancialInstitutions();
  const [isArchiving, setIsArchiving] = useState(false);

  const isArchived = !!account.archivedAt;

  const archiveAccount = useCallback(async () => {
    if (!account) {
      return;
    }
    const result = await setAccountArchived(account.id, true);

    if (result?.errors) {
      toast({ message: "Failed to archive account.", status: "error" });
    } else {
      toast({ message: "Successfully archived account.", status: "success" });
    }
  }, [account, toast, setAccountArchived]);

  const restoreAccount = useCallback(async () => {
    if (!account) {
      return;
    }
    const result = await setAccountArchived(account.id, false);

    if (result?.errors) {
      toast({ message: "Failed to restore account.", status: "error" });
    } else {
      toast({ message: "Successfully restored account.", status: "success" });
    }
  }, [account, toast, setAccountArchived]);

  if (!account || account.manuallyAdded) {
    return null;
  }

  return isArchived ? (
    <>
      <FooterButton
        label="Restore"
        variant="primary"
        description={`This account is currently archived. Restore this account to start ingesting your financial data from ${account.financialInstitution.name}.`}
        onClick={restoreAccount}
        canBeUndone={true}
      />
    </>
  ) : (
    <>
      <FooterButton
        label="Archive"
        variant="negative"
        description={`Pause syncing data for this account and hide it from this page.`}
        onClick={() => setIsArchiving(true)}
        canBeUndone={true}
      />

      <ArchiveAccountConfirmationModal
        open={isArchiving}
        onOpenChange={setIsArchiving}
        onConfirm={() => {
          archiveAccount();
        }}
      />
    </>
  );
};

export default ArchiveAccountButton;
