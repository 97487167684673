import React from "react";
import { IconProps } from "./Icon";

export default function PaperAirplane({ width = 17, height = 13 }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 17 17"
    >
      <path
        fill="#50FAAB"
        d="M11.883 6.436a.4.4 0 00-.044-.521.39.39 0 00-.516-.026l-7.024 6.048-3.72-1.456a.918.918 0 01-.104-1.655L15.868.1a.751.751 0 011.059.339c.06.127.083.268.069.409L15.633 15.09a.951.951 0 01-.438.71.926.926 0 01-.823.079l-4.517-1.768L7.022 16.8a.728.728 0 01-1.232-.546V14.15l6.093-7.714z"
      ></path>
    </svg>
  );
}
