import { useCallback, useEffect, useMemo, useState } from "react";
import { usePlaidLink } from "react-plaid-link";
import {
  useConnectPlaidMutation,
  FinancialInstitutionsQuery,
  usePlaidIntegrationQuery,
  FinancialInstitutionsDocument,
  IntegrationType,
  AccountFragment,
  IntegrationConnectionCondition,
  IntegrationConnectionStatus,
  AccountType,
  AccountsWithIntegrationsDocument,
  IntegrationConnectionsForCompanyDocument,
  useFinancialInstitutionsQuery,
} from "graphql/types";
import { useFinancialInstitutions, usePendingConnections } from "../shared";
import { UsePlaidResult } from "./types";
import { useToasts } from "@puzzle/ui";
import { useApolloClient } from "@apollo/client";
import Analytics from "lib/analytics";
import { getFetchPolicyForKey, ONE_HOUR } from "apollo/getFetchPolicyForKey";
import { reportError } from "lib/errors";
import { useActiveCompany } from "components/companies";
import { useLocalStorage } from "react-use";

export const BREX_INSTITUTION_NAME = "Brex";
export const MERCURY_INSTITUTION_NAME = "Mercury";
export const SILICON_VALLEY_BANK = "Silicon Valley Bank - SVB Online Banking";
const MEOW_INSTITUTION_NAME = "Meow";
const FINANCIAL_INSTITUTIONS_KEY = "fin";

const usePlaid = ({
  companyId,
  integrationConnectionId,
  financialInstitutionId,
  onSuccess,
  enableMercuryViaPlaid = false,
  onClickConnect: baseOnClickConnect,
}: {
  companyId: string;
  integrationConnectionId?: string;
  financialInstitutionId?: string;
  onSuccess?: () => void;
  enableMercuryViaPlaid?: boolean;
  onClickConnect?: () => void;
  openSelectDate?: boolean;
  setOpen?: () => void;
}): UsePlaidResult => {
  const client = useApolloClient();
  const [openSelectDate, onOpenSelectDateChange] = useState(false);
  const [isReconnect, setReconnect] = useState(false);
  const [accounts, setAccounts] = useState<AccountFragment[]>([]);
  const [connectionId, setConnectionId] = useState<string | undefined>(integrationConnectionId);
  const { toast } = useToasts();
  const { completedOnboarding, company } = useActiveCompany();
  const { disconnectIntegrationConnection } = useFinancialInstitutions();
  const fetchPolicyKey = `${companyId}.${financialInstitutionId || "new"}.usePlaidIntegrationQuery`;
  const { data, loading, refetch } = usePlaidIntegrationQuery({
    variables: { companyId, financialInstitutionId },
    fetchPolicy: getFetchPolicyForKey(
      fetchPolicyKey,
      // Plaid has an expiration of 4 hours; refresh early since this depends on re-renders
      ONE_HOUR * 3,
      "network-only"
    ),
  });
  const { integrations } = data || {};

  const disconnectIntegration = useMemo(() => {
    if (!connectionId) {
      return undefined;
    }

    return async () => {
      const result = disconnectIntegrationConnection(connectionId);

      if (!integrationConnectionId) {
        refetch();
      }

      return result;
    };
  }, [disconnectIntegrationConnection, integrationConnectionId, connectionId, refetch]);

  const [connectPlaidMutation, { loading: connecting, error: connectionError }] =
    useConnectPlaidMutation();

  const initializationInfo = integrations?.plaid.initializationInfo;

  const { addPendingConnection, removePendingConnection, isPending } = usePendingConnections();

  // store any accounts provided by the service, so that we can
  // optimistically show the accounts while waiting for the BE to complete
  // fetching them
  const [storedInstitutions, setStoredInstitutions] = useLocalStorage<{
    [key: string]: AccountFragment[];
  }>(`${companyId}-${FINANCIAL_INSTITUTIONS_KEY}`, {});

  const storeFinancialInstitution = useCallback(
    (institutionId: string, accounts?: AccountFragment[]) => {
      setStoredInstitutions((storedState) => {
        return { ...storedState, [institutionId]: accounts || [] };
      });
    },
    [setStoredInstitutions]
  );

  // plaidLink which adds an iframe is somehow adding overflow: hidden to body, and doesn't remove it
  // when the iframe is removed. This is a hack to remove the overflow: hidden from body on unmount.
  useEffect(() => {
    return () => {
      const body = document.querySelector("body");
      if (body) {
        setTimeout(() => {
          body.style.overflow = "auto";
        });
      }
    };
  }, []);

  const connectPlaid = useCallback(
    async (
      input: {
        publicToken: string;
        institutionName: string;
        companyId: string;
        institutionId: string;
        accounts?: AccountFragment;
        condition?: IntegrationConnectionCondition;
      },
      isReconnect: boolean,
      accounts?: AccountFragment[]
    ) => {
      const financialInstitutionsForVariables = {
        companyId,
        integrationType: IntegrationType.Plaid,
      };

      if (!isReconnect) {
        if (input.institutionName === BREX_INSTITUTION_NAME) {
          toast({
            message:
              "It looks like you are trying to connect to Brex! Connecting to Brex through Plaid is currently not recommended. Please go to the integration link for Brex in order to connect directly.",
            status: "warning",
          });
          return;
        }

        if (
          !enableMercuryViaPlaid &&
          input.institutionName.toLowerCase().includes(MERCURY_INSTITUTION_NAME.toLowerCase())
        ) {
          toast({
            message:
              "It looks like you are trying to connect to Mercury! Connecting to Mercury through Plaid is currently not recommended. Please go to the integration link for Mercury in order to connect directly.",
            status: "warning",
          });
          return;
        }

        if (input.institutionName === MEOW_INSTITUTION_NAME) {
          toast({
            message:
              "It looks like you are trying to connect to Meow! Connecting to Meow through Plaid is currently not recommended. Please go to the integration link for Meow in order to connect directly.",
            status: "warning",
          });
          return;
        }

        // if we have a pending connection to the same financial institution
        // don't continue with connection
        if (isPending(IntegrationType.Plaid, input.institutionId)) {
          return;
        }

        // we can't use the react variable to determine the financial institutions
        // in the cache because this is part of the onSuccess handler in plaidLink
        // which doesn't update reactively
        const fiInCache = client.readQuery({
          query: FinancialInstitutionsDocument,
          variables: financialInstitutionsForVariables,
        });

        // check that they don't already have an existing connected connection
        for (const i of fiInCache.financialInstitutionsFor) {
          if (
            i.nativeInstitutionId === input.institutionId &&
            i.connection.status !== IntegrationConnectionStatus.Disconnected
          ) {
            toast({
              message: "This institution has already been connected.",
              status: "warning",
            });
            return;
          }
        }
      }

      addPendingConnection(IntegrationType.Plaid, input.institutionId);

      const result = await connectPlaidMutation({
        variables: { input },
        refetchQueries: [
          { query: FinancialInstitutionsDocument, variables: financialInstitutionsForVariables },
          AccountsWithIntegrationsDocument,
          IntegrationConnectionsForCompanyDocument,
        ],

        update(cache, { data }) {
          const financialInstitutions = cache.readQuery<FinancialInstitutionsQuery>({
            query: FinancialInstitutionsDocument,
            variables: financialInstitutionsForVariables,
          });

          if (!data || !financialInstitutions) return;

          // replace the institution if it already exists in the list, otherwise add it to the list
          let existingFound = false;
          const newInstitutions = [];
          const returnedAccounts = data.connectPlaid.financialInstitution.accounts;
          const newFinancialInstitutionWithAccounts = {
            ...data.connectPlaid.financialInstitution,
            // defer to any accounts returned from the BE
            accounts: returnedAccounts.length ? returnedAccounts : accounts,
          };

          financialInstitutions.financialInstitutionsFor.forEach((i) => {
            if (i.id === data.connectPlaid.financialInstitution.id) {
              existingFound = true;
              newInstitutions.push(newFinancialInstitutionWithAccounts);
              return;
            }
            newInstitutions.push(i);
          });

          if (!existingFound) {
            newInstitutions.push(newFinancialInstitutionWithAccounts);
          }

          cache.writeQuery({
            query: FinancialInstitutionsDocument,
            variables: financialInstitutionsForVariables,
            data: {
              financialInstitutionsFor: [...newInstitutions],
            },
          });

          // todo this blocks the button on the intro flow, make it compatible
          // toast("Institution connected successfully", { level: "success" });
        },

        onCompleted(data) {
          const institution = data.connectPlaid.financialInstitution;
          storeFinancialInstitution(institution.id, accounts);
          removePendingConnection(IntegrationType.Plaid, input.institutionId);
          onSuccess?.();
          onOpenSelectDateChange(true);
          setAccounts(institution.accounts);
          const accountWithConnection = institution.accounts.find((acct) => acct.connection);
          const connection = accountWithConnection?.connection;
          setConnectionId(connection?.id ?? "");

          const accountTypeCounts = institution.accounts.reduce((result, account) => {
            result[`total${account.type}Accounts`] =
              (result[`total${account.type}Accounts`] ?? 0) + 1;
            return result;
          }, {} as Record<`total${AccountType}Accounts`, number>);

          Analytics.integrationConnected({
            institutionName: institution.name,
            connectionId: institution.id,
            integrationType: "Plaid",
            totalAccounts: institution.accounts.length,
            ...accountTypeCounts,
          });
        },

        onError({ message }) {
          Analytics.integrationConnectionFailed({
            integrationType: "Plaid",
            reason: message,
          });
        },
      });

      return result;
    },
    [
      companyId,
      addPendingConnection,
      connectPlaidMutation,
      enableMercuryViaPlaid,
      isPending,
      client,
      toast,
      storeFinancialInstitution,
      removePendingConnection,
      onSuccess,
      onOpenSelectDateChange,
      setAccounts,
      setConnectionId,
    ]
  );

  const { open, ready } = usePlaidLink({
    token: initializationInfo?.connectInfo.linkToken ?? null,

    async onSuccess(publicToken, metadata) {
      const { name = "", institution_id: institutionId = "" } = metadata.institution || {};

      const accounts = metadata.accounts.map((a, i) => ({
        __typename: "Account",
        id: `optimisticacccount-${i}`,
        name: a.name,
        type: a.type,
        mask: a.mask,
        todaysBalance: {
          __typename: "AccountBalance",
          balance: "0",
        },
      })) as AccountFragment[];

      const isReconnect = Boolean(financialInstitutionId);
      setReconnect(isReconnect);

      // need to revisit this and probably pass back everything from this to the backend to save
      await connectPlaid(
        {
          publicToken,
          institutionName: name,
          institutionId,
          companyId,
          condition: completedOnboarding
            ? IntegrationConnectionCondition.WaitingForUserEpoch
            : undefined,
        },
        isReconnect,
        accounts
      );
    },

    onExit(error, metadata) {
      if (error) {
        console.error(error);

        if (["INVALID_LINK_TOKEN", "INTERNAL_SERVER_ERROR"].includes(error.error_code)) {
          // Force the link token to refetch
          refetch();
        } else {
          Analytics.integrationConnectionFailed({
            integrationType: "Plaid",
            reason: error.display_message,
          });
        }
      }
    },
  });

  const onClickConnect = useCallback(() => {
    if (!ready) {
      reportError("Plaid wasn't ready; add guard to prevent this.");
    }

    baseOnClickConnect?.();
    open();
  }, [baseOnClickConnect, open, ready]);

  const { data: fiData } = useFinancialInstitutionsQuery({
    variables: { companyId, integrationType: IntegrationType.Plaid },
  });

  const financialInstitutions = useMemo(
    () =>
      fiData?.financialInstitutionsFor
        ? fiData?.financialInstitutionsFor.map((bank) => {
            const storedAccounts = storedInstitutions ? storedInstitutions[bank.id] : [];
            if (bank.accounts.length === 0) {
              return {
                ...bank,
                accounts: storedAccounts,
              };
            }
            return bank;
          })
        : [],
    [fiData?.financialInstitutionsFor, storedInstitutions]
  );

  return {
    onClickConnect,
    ready,
    loading,
    connecting,
    connectionError,
    openSelectDate,
    onOpenSelectDateChange,
    isReconnect,
    accounts,
    connectionId,
    disconnect: disconnectIntegration,
    financialInstitutions,
  };
};

export default usePlaid;
