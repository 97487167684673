import React from "react";
import { styled, Dialog, Button } from "@puzzle/ui";

const CollapsibleContent = styled("div", {
  textVariant: "$bodyS",
  color: "$gray300",
  paddingTop: "$1",
  display: "flex",
  flexDirection: "column",
  gap: "$2",
  p: {
    margin: 0,
  },
});

const FooterAlign = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const ButtonMargin = styled("div", {
  marginLeft: "12px",
});

type FinalizeCCModalProps = Pick<React.ComponentProps<typeof Dialog>, "open"> & {
  onConfirm: () => void;
  onCancel: () => void;
};

export const FinalizeCCModal = ({ open, onConfirm, onCancel, ...props }: FinalizeCCModalProps) => {
  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          onCancel();
        }
      }}
      width={520}
      {...props}
    >
      <Dialog.Title>Finalize a credit card payment</Dialog.Title>
      <Dialog.Body>
        <CollapsibleContent>
          <p>
            Puzzle advises against finalizing unlinked credit card payments. In most instances,
            Puzzle will match the credit card payment with an offsetting transaction and link them.
            If your credit card payment is more than three days old and unlinked you may need to
            manually create an offsetting transaction.
          </p>
        </CollapsibleContent>
      </Dialog.Body>
      <Dialog.Footer>
        <FooterAlign>
          <Button
            variant="secondary"
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </Button>
          <ButtonMargin>
            <Button
              variant="primary"
              onClick={() => {
                onConfirm();
              }}
            >
              Finalize anyway
            </Button>
          </ButtonMargin>
        </FooterAlign>
      </Dialog.Footer>
    </Dialog>
  );
};
