import * as Types from '../types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
import { IntegrationConnectionFragmentDoc } from './integrationConnection.generated';
export type AccountFragment = { __typename?: 'Account', id: string, manuallyAdded: boolean, name: string, subType?: Types.AccountSubType | null, type: Types.AccountType, mask?: string | null, status: Types.IntegrationConnectionStatus, updatedAt: CalendarDateTimeString, lastImportedAt?: CalendarDateTimeString | null, archivedAt?: CalendarDateTimeString | null, connection?: { __typename?: 'IntegrationConnection', id: string, createdAt: CalendarDateTimeString, type: Types.IntegrationType, ingressType: Types.IngressType, status: Types.IntegrationConnectionStatus, message?: string | null, errorCode?: Types.DisconnectionErrorCode | null, lastSyncedAt?: CalendarDateTimeString | null, condition?: Types.IntegrationConnectionCondition | null, latestSync?: { __typename?: 'SyncRun', id: any, status: Types.SyncRunStatus } | null } | null, financialInstitution: { __typename?: 'FinancialInstitution', id: string, name: string }, initialAccountBalance: { __typename?: 'AccountBalance', balance: string, date: CalendarDateTimeString, splits: Array<{ __typename?: 'AccountBalanceSplit', amount: string, description: string, category: { __typename?: 'GatewayCategory', permaKey: string, name: string, displayName?: string | null } | { __typename?: 'LedgerCategory', permaKey: string, name: string, displayName: string } }> }, todaysBalance: { __typename?: 'AccountBalance', balance: string }, epoch?: { __typename?: 'AccountEpoch', cutoffBefore?: CalendarDateString | null, cutoffAtOrAfter?: CalendarDateString | null } | null };

export type AccountWithIntegrationFragment = { __typename?: 'Account', id: string, manuallyAdded: boolean, name: string, subType?: Types.AccountSubType | null, type: Types.AccountType, mask?: string | null, status: Types.IntegrationConnectionStatus, updatedAt: CalendarDateTimeString, lastImportedAt?: CalendarDateTimeString | null, archivedAt?: CalendarDateTimeString | null, connection?: { __typename?: 'IntegrationConnection', id: string, createdAt: CalendarDateTimeString, type: Types.IntegrationType, ingressType: Types.IngressType, status: Types.IntegrationConnectionStatus, message?: string | null, errorCode?: Types.DisconnectionErrorCode | null, lastSyncedAt?: CalendarDateTimeString | null, condition?: Types.IntegrationConnectionCondition | null, latestSync?: { __typename?: 'SyncRun', id: any, status: Types.SyncRunStatus } | null } | null, financialInstitution: { __typename?: 'FinancialInstitution', id: string, name: string }, todaysBalance: { __typename?: 'AccountBalance', balance: string }, epoch?: { __typename?: 'AccountEpoch', cutoffBefore?: CalendarDateString | null, cutoffAtOrAfter?: CalendarDateString | null, shouldCorrectHistory: boolean } | null, epochs: Array<{ __typename?: 'AccountEpoch', cutoffBefore?: CalendarDateString | null, cutoffAtOrAfter?: CalendarDateString | null, shouldCorrectHistory: boolean }> };

export const AccountFragmentDoc = gql`
    fragment account on Account {
  id
  manuallyAdded
  name
  subType
  type
  mask
  status
  updatedAt
  lastImportedAt
  archivedAt
  connection {
    ...integrationConnection
  }
  financialInstitution {
    id
    name
  }
  initialAccountBalance {
    balance
    date
    splits {
      amount
      description
      category {
        permaKey
        name
        displayName
      }
    }
  }
  todaysBalance {
    balance
  }
  epoch {
    cutoffBefore
    cutoffAtOrAfter
  }
}
    ${IntegrationConnectionFragmentDoc}`;
export const AccountWithIntegrationFragmentDoc = gql`
    fragment accountWithIntegration on Account {
  id
  manuallyAdded
  name
  subType
  type
  mask
  status
  updatedAt
  lastImportedAt
  archivedAt
  connection {
    ...integrationConnection
  }
  financialInstitution {
    id
    name
  }
  todaysBalance {
    balance
  }
  epoch {
    cutoffBefore
    cutoffAtOrAfter
    shouldCorrectHistory
  }
  epochs {
    cutoffBefore
    cutoffAtOrAfter
    shouldCorrectHistory
  }
}
    ${IntegrationConnectionFragmentDoc}`;