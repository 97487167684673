import React from "react";
import { styled, keyframes } from "@puzzle/ui";
import * as Stitches from "@stitches/react";

const Container = styled("div", {
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const rotate = keyframes({
  "0%": { transform: "rotate(0)" },
  "100%": { transform: "rotate(360deg)" },
});

export const Spinner = styled("div", {
  userSelect: "none",
  transformOrigin: "50% 50%",
  // wtf was this for... it goes on top of modals
  // zIndex: "1000",
  color: "rgb(0, 119, 179)",
  borderRadius: "$ellipse",
  animation: `1.2s linear 0s infinite normal none running ${rotate}`,
  borderWidth: "2px",
  borderStyle: "solid",
  borderColor: "transparent transparent transparent #50faab",
  borderImage: "initial",

  defaultVariants: {
    variant: "primary",
  },

  variants: {
    variant: {
      primary: {
        borderLeft: "2px solid #50faab",
      },
      secondary: {
        borderLeft: "2px solid $gray300",
      },
    },
  },

  "&::after": {
    content: '""',
    position: "absolute",
    boxSizing: "content-box",
    transformOrigin: "0% 0%",
    opacity: "0.4",
    top: "50%",
    left: "50%",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    transform: "translateZ(0px) translateX(-50%) translateY(-50%)",
    zIndex: "999",
    borderRadius: "$ellipse",
    border: "2px solid #595861",
  },
});

const Loader = ({
  size = 24,
  css,
  variant = "primary",
}: {
  size?: number;
  css?: Stitches.CSS;
  variant?: "primary" | "secondary";
}) => (
  <Container css={css}>
    <Spinner variant={variant} css={{ width: size, height: size }} />
  </Container>
);

export default Loader;
