import { useCallback } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FixedAssetStatus, FixedAssetType } from "graphql/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToasts } from "@puzzle/ui";
import { useActiveCompany } from "components/companies";
import {
  TransactionForFixedAssetsFragment,
  useUpsertFixedAssetForDetailMutation,
} from "../graphql.generated";
import Analytics from "lib/analytics";
import {
  NewAssetFromPieceFormValues,
  isValidFixedAssetType,
  transformType,
  validationSchema,
} from "./shared";

export function useNewFixedAssetFromTransactionForm(
  transaction?: TransactionForFixedAssetsFragment,
  onComplete?: () => void
) {
  const transformedType = transformType(transaction?.detail?.category.permaKey);

  const form = useForm<NewAssetFromPieceFormValues>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),

    defaultValues: {
      type: isValidFixedAssetType(transformedType)
        ? (transformedType as FixedAssetType)
        : undefined,
      description: transaction?.descriptor ?? "",
      inServiceAt: "",
      usefulLifeMonths: "",
    },
  });

  const [upsertAsset] = useUpsertFixedAssetForDetailMutation();
  const { company } = useActiveCompany<true>();
  const { toast } = useToasts();

  const onSubmitAsset = useCallback(() => {
    const handle: SubmitHandler<NewAssetFromPieceFormValues> = async (formData) => {
      await upsertAsset({
        variables: {
          input: {
            ...formData,
            companyId: company.id,
            detailId: transaction?.detail.id,
            usefulLifeMonths: parseInt(formData.usefulLifeMonths ?? "0") * 12,
            status: FixedAssetStatus.InService,
          },
        },
        onError: ({ message }) => {
          toast({
            status: "error",
            message: "Unable to create fixed asset.",
          });

          Analytics.fixedAssetsCreateFailed({
            numberOfItems: 1,
            errorMessage: message,
          });
        },
        onCompleted: () => {
          toast({
            status: "success",
            message: "Successfully created fixed asset.",
          });

          Analytics.fixedAssetsCreated({
            numberOfItems: 1,
          });

          onComplete?.();
        },
      });
    };

    return form.handleSubmit((data) => handle(data))();
  }, [company.id, transaction?.detail.id, form, upsertAsset, toast, onComplete]);

  return { ...form, onSubmitAsset };
}
