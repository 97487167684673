import React from "react";
import qs from "querystring";
import { useActiveCompany } from "components/companies";
import {
  CustomizedPartner,
  getApiPartnerAuthType,
  getApiPartnerLinkTokenConfig,
  getApiPartnerSettings,
  getThirdPartyLoginCookie,
  ApiPartnerAuthFlowType,
  getPartnerOnboardingId,
  clearPartnerOnboardingId,
} from "lib/partnerCookie";
import { useCallback } from "react";
import { useMemo } from "react";
import {
  useConnectPartnerUserMutation,
  useKickCompanyIngestionMutation,
} from "./graphql.generated";
import { ApiUser } from "graphql/types";
import { useLocalStorage } from "react-use";
import { AngellistSimpleLogo } from "@puzzle/icons";
import { IntegrationLogo } from "components/integrations";
import useAppRouter from "lib/useAppRouter";
import { Route } from "lib/routes";
import redirect from "lib/redirect";
import Analytics from "lib/analytics";

type StoredIdentity = Partial<Record<string, ApiUser>>;

interface ContinueOAuthParams {
  companyId?: string;
  accessDenied?: boolean;
}

const WEBFLOW = "https://puzzle-welcome.webflow.io";
const WELCOME_PAGES: Record<CustomizedPartner, string | undefined> = {
  [CustomizedPartner.Stripe]: `${WEBFLOW}/welcome/stripe`,
  [CustomizedPartner.AngelList]: `${WEBFLOW}/welcome/angellist`,
  [CustomizedPartner.LTSE]: `${WEBFLOW}/welcome/ltse`,
  [CustomizedPartner.Index]: `${WEBFLOW}/welcome/acme`,
  [CustomizedPartner.Fondo]: undefined,
  [CustomizedPartner.Mercury]: `${WEBFLOW}/welcome/acme`,
  [CustomizedPartner.Every]: undefined,
};

export const getWelcomePage = (thirdParty: string): string | undefined => {
  return WELCOME_PAGES[thirdParty as CustomizedPartner];
};

export const AngellistIntegrationLogo = () => (
  <IntegrationLogo color="#ffffff">
    <AngellistSimpleLogo />
  </IntegrationLogo>
);

export default function useThirdPartyLogin() {
  const { goToLogin } = useAppRouter();

  const { company } = useActiveCompany();
  const thirdPartyLogin = getThirdPartyLoginCookie();
  const apiPartnerAuthType = getApiPartnerAuthType();
  const apiPartnerSettings = getApiPartnerSettings();
  const partnerOnboardingId = getPartnerOnboardingId();

  const [apiUserIdentities = {} as StoredIdentity, setApiUserIdentities] =
    useLocalStorage<StoredIdentity>("api:api_user_identities", {} as StoredIdentity);
  const [_connectPartnerUser, { called }] = useConnectPartnerUserMutation();
  const [kickIngestion] = useKickCompanyIngestionMutation();

  const didConnect = called || thirdPartyLogin === CustomizedPartner.Stripe;
  const supportsRedirect =
    thirdPartyLogin && apiPartnerSettings && apiPartnerSettings.supportsRedirect;

  const getLabel = () => {
    if (thirdPartyLogin && apiPartnerSettings && apiPartnerSettings.name) {
      return apiPartnerSettings.name;
    }
    return "";
  };

  const continueOAuthFlow = useCallback((params: ContinueOAuthParams) => {
    redirect({ path: Route.authorizePartner, query: params });
  }, []);

  const redirectToPartnerUri = useMemo(() => {
    if (!supportsRedirect) {
      return null;
    }

    const config = getApiPartnerLinkTokenConfig();
    const identity = apiUserIdentities[thirdPartyLogin];
    if (config && config.redirectToPartnerUri) {
      // LTSE is special
      if (thirdPartyLogin === CustomizedPartner.LTSE) {
        if (!identity?.externalId) {
          return null;
        }
        const parsedUri = new URL(config.redirectToPartnerUri);
        const query = qs.stringify({
          ...qs.parse(parsedUri.search.substr(1)),
          user_id: identity.externalId,
        });
        return `${parsedUri.origin}${parsedUri.pathname}?${query}`;
      }
      return config.redirectToPartnerUri;
    }

    return null;
  }, [thirdPartyLogin, apiUserIdentities, supportsRedirect]);

  const redirectToPartner = useCallback(() => {
    Analytics.onboardingPartnerReturnButtonClicked();
    if (!thirdPartyLogin) {
      console.warn("redirect to partner called without a thirdpartylogin defined");
      return;
    }

    if (redirectToPartnerUri) {
      window.location.href = redirectToPartnerUri;
    }

    console.warn("redirect to partner called without a redirectToPartnerUri defined");
  }, [redirectToPartnerUri, thirdPartyLogin, company, apiPartnerAuthType, continueOAuthFlow]);

  /**
   * TODO add stripe connect here rather than on logic - call handleStripeConnect, will require storing the
   * state token in a cookie so it can be passed to the backend
   */
  const connectPartnerUser = useCallback(
    async (companyId: string) => {
      if (!company) {
        throw new Error("Company not defined");
      }
      const partnerConfig = getApiPartnerLinkTokenConfig();
      if (!partnerConfig) {
        throw new Error(`Config for partner not found, cannot link account`);
      }

      const result = await _connectPartnerUser({
        variables: { input: { token: partnerConfig.token, companyId } },
      });

      if (result && result.data && thirdPartyLogin) {
        setApiUserIdentities({
          [thirdPartyLogin]: result.data?.connectPartnerUserToPuzzleUser.apiUser,
        });
      }

      if (thirdPartyLogin && apiPartnerSettings?.hasWebhooksEnabled) {
        // ensure that this company gets put into the company watchdog queue so
        // that any webhooks are hit (needed for LTSE)
        // will want to disentangle company watchdogs from webhook hitting at some point
        // very soon
        await kickIngestion({
          variables: { input: { id: company.id } },
        });
      }
    },
    [
      _connectPartnerUser,
      company,
      kickIngestion,
      thirdPartyLogin,
      apiPartnerSettings,
      setApiUserIdentities,
    ]
  );

  const linkAccount = useCallback(
    (companyId: string) => {
      if (!thirdPartyLogin) {
        return;
      }

      if (apiPartnerAuthType === ApiPartnerAuthFlowType.OAuth) {
        return continueOAuthFlow({ companyId });
      }

      // this is currently handled in registerStripeUser on login
      if (thirdPartyLogin !== CustomizedPartner.Stripe) {
        return connectPartnerUser(companyId);
      }
    },
    [thirdPartyLogin, connectPartnerUser, apiPartnerAuthType, continueOAuthFlow]
  );

  const handleConnect = useCallback(
    (companyId: string) => {
      linkAccount(companyId);
      if (supportsRedirect) {
        redirectToPartner();
      }
    },
    [supportsRedirect, redirectToPartner]
  );

  const cancelLinking = () => {
    if (apiPartnerAuthType === ApiPartnerAuthFlowType.OAuth) {
      continueOAuthFlow({ accessDenied: true });
    } else {
      goToLogin();
    }
  };

  return {
    thirdParty: thirdPartyLogin,
    customizedPartner: thirdPartyLogin as CustomizedPartner,
    didConnect,
    isApiPartner: thirdPartyLogin && apiPartnerSettings && !partnerOnboardingId,
    label: getLabel(),
    apiPartnerAuthType,
    cancelLinking,
    supportsRedirect,
    handleConnect,
    partnerOnboardingId,
    clearPartnerOnboardingId,
  };
}
