import { Big, BigSource } from "big.js";

export interface FormatNumberOptions {
  locale?: "en-US";
  style?: "decimal" | "percent" | "unit";
  usingCostNotation?: boolean;
  decimals?: number;
}

const defaultNumberOptions: Required<FormatNumberOptions> = {
  locale: "en-US",
  style: "decimal",
  usingCostNotation: true,
  decimals: 2,
};

const createNumberFormatter = (options: Required<FormatNumberOptions>) => {
  const { locale, style, decimals } = options;

  return new Intl.NumberFormat(locale, {
    style,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
};

export const formatNumber = (
  value: BigSource,
  options: FormatNumberOptions = defaultNumberOptions
) => {
  const mergedOptions = { ...defaultNumberOptions, ...options };
  const formatter = createNumberFormatter(mergedOptions);
  if (mergedOptions.usingCostNotation) {
    // currencySign of accounting only works with currencies
    return applyCostNotation(value, formatter.format);
  }
  return formatter.format(Big(value).toNumber());
};

const applyCostNotation = (value: BigSource, format: (val: number) => string) => {
  const amount = Big(value);
  return amount.lt(0) ? `(${format(amount.abs().toNumber())})` : format(amount.toNumber());
};
