import React from "react";
import {
  ConnectModal,
  ConnectModalBody,
  ConnectModalContainer,
  ConnectModalFooter,
  ConnectModalProps,
  ConnectModalSecurityMessage,
  ConnectModalTitle,
} from "components/intro/components/ConnectModal";
import { useActiveCompany } from "components/companies";
import { SetupGustoListItem } from "../../gusto";
import { BasicListItem } from "../../ListItem";
import Wizard, { useWizardContext } from "../../../common/Wizard";
import { AddPayrollAccountModal } from "../../../dashboard/Transactions/Payroll/AddPayrollAccountModal";
import Link from "../../../common/Link";
import { useForm } from "react-hook-form";
import { PayrollFormValues } from "../../../dashboard/Transactions/Payroll/UpsertPayrollDrawer";
import { useFinancialInstitutions } from "../../shared";
import { SetupDeelListItem } from "../../deel";
import SetupPushIntegrationListItem from "../../pushIntegrations/SetupPushIntegrationListItem";
import { IntegrationType } from "graphql/types";
import { SetupRipplingListItem } from "../../rippling";

const ConnectPayrollIntegrationStep = ({
  parentModalOnOpen,
}: {
  parentModalOnOpen?(open: boolean): void;
}) => {
  const { company } = useActiveCompany<true>();
  const { goToNextStep } = useWizardContext();

  return (
    <>
      <ConnectModalTitle>Add payroll accounts</ConnectModalTitle>
      <ConnectModalBody>
        <ConnectModalSecurityMessage />
        <ConnectModalContainer>
          <SetupGustoListItem companyId={company.id} DisplayComponent={BasicListItem} />
          <SetupRipplingListItem
            companyId={company.id}
            DisplayComponent={BasicListItem}
          />
          <SetupDeelListItem companyId={company.id} DisplayComponent={BasicListItem} />
          {company.features.centralEnabled && (
            <SetupPushIntegrationListItem
              companyId={company.id}
              integrationType={IntegrationType.Central}
              DisplayComponent={BasicListItem}
            />
          )}
        </ConnectModalContainer>
      </ConnectModalBody>
      <ConnectModalFooter>
        <Link underline color="greenalpha" onClick={goToNextStep}>
          Add an account
        </Link>{" "}
        that has no supported integration.
      </ConnectModalFooter>
    </>
  );
};

const AddManualAccountStep = () => {
  const { goToNextStep } = useWizardContext();
  const form = useForm<PayrollFormValues>({
    mode: "onChange",
  });

  return (
    <AddPayrollAccountModal
      onOpenChange={() => {
        goToNextStep();
      }}
      open={true}
      payrollForm={form}
    />
  );
};

export const ConnectPayrollModal = ({ onOpenChange, ...rest }: ConnectModalProps) => {
  const { refetchAccountsAndIntegrations } = useFinancialInstitutions();
  return (
    <ConnectModal
      {...rest}
      onOpenChange={(open: boolean) => {
        onOpenChange?.(open);
      }}
    >
      <Wizard
        steps={[
          {
            label: "Integration",
            name: "integration",
            component: () => <ConnectPayrollIntegrationStep parentModalOnOpen={onOpenChange} />,
          },
          {
            label: "Manual Account Form",
            name: "manual-account-form",
            component: AddManualAccountStep,
          },
        ]}
        onComplete={async () => {
          onOpenChange?.(false);
          refetchAccountsAndIntegrations();
        }}
        render={({ activeStep }) => {
          const Step = activeStep.component;
          return <Step />;
        }}
      />
    </ConnectModal>
  );
};
