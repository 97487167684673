import React, { useCallback, useMemo } from "react";
import { CalendarDate, parseDate } from "@internationalized/date";
import { QueryHookOptions, useApolloClient } from "@apollo/client";
import { mergeDeep } from "@apollo/client/utilities";
import Big from "big.js";
import { uniqueId } from "lodash";

import { useToasts } from "@puzzle/ui";
import { pluralize } from "@puzzle/utils";

import {
  AccountType,
  ActorType,
  AssociatedEntity,
  CardFragment,
  CategoryFragment,
  CustomerFragment,
  DetailConfirmedState,
  Exact,
  FixedAssetStatus,
  PrepaidStatus,
  ProductFragment,
  RecurrencePeriod,
  TransactionMemoType,
  UpdateCardInput,
  VendorFragment,
} from "graphql/types";
import Link from "components/common/Link";
import { Route } from "lib/routes";

import {
  CustomersDocument,
  CustomersQuery,
  CustomersQueryVariables,
  ProductsDocument,
  ProductsQuery,
  ProductsQueryVariables,
} from "components/common/hooks/graphql.generated";
import { useActiveCompany } from "components/companies";
import useFile from "components/common/files/useFile";
import useSelf from "components/users/useSelf";
import { PagedBillForMatchingFragment } from "components/dashboard/Accounting/Bills/graphql.generated";
import useCategories from "components/common/hooks/useCategories";
import { isCapitalizable } from "components/transactions/utils";
import Analytics from "lib/analytics";
import { isEditorRole } from "lib/roles";

import {
  useRecategorizeTransactionMutation,
  useMoveDateForTransactionMutation,
  useUpdateTransactionVendorMutation,
  useAddTransactionMessageMutation,
  BasicTransactionFragment,
  useUpdateFinalizedTransactionStateMutation,
  useGetTransactionQuery,
  useGetFullTransactionQuery,
  useUpdateTransactionCustomerMutation,
  useUpdateTransactionProductMutation,
  BasicTransactionFragmentDoc,
  useUpdateCardMutation,
  GetTransactionQuery,
  FullTransactionFragment,
  FullTransactionFragmentDoc,
  GetFullTransactionQuery,
  useUpdateIsBillPaymentMutation,
  useUpdateIsInvoicePaymentMutation,
  useLinkBillsToTransactionMutation,
  GetFullTransactionDocument,
  useUpdateRecurrenceMutation,
  TransactionPageDetailFragment,
  useUpdateDescriptorMutation,
} from "../graphql.generated";

export function useGetTransaction(
  id?: string,
  options?: QueryHookOptions<
    GetTransactionQuery,
    Exact<{
      id: string;
    }>
  >
) {
  return useGetTransactionQuery({
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-first",
    variables: id ? { id } : undefined,
    skip: !id,
    ...options,
  });
}

export function useGetFullTransaction(
  id?: string,
  options?: QueryHookOptions<
    GetFullTransactionQuery,
    {
      id: string;
    }
  >
) {
  return useGetFullTransactionQuery({
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-first",
    variables: id ? { id } : undefined,
    context: { batch: false },
    skip: !id,
    ...options,
  });
}

export function useLinkBillsToTransaction(transaction?: BasicTransactionFragment | null) {
  const [linkBillsMutation] = useLinkBillsToTransactionMutation();
  const { company } = useActiveCompany<true>();
  const { categoriesByPermaKey } = useCategories();
  const { toast } = useToasts();
  const { refetch } = useSingleTransaction({ id: transaction?.id });

  return useCallback(
    (
      bills: PagedBillForMatchingFragment[],
      unmatchedAmountCoaKey?: string,
      unmatchedSplitDescription?: string
    ) => {
      if (!transaction) return;
      if (transaction.detail.confirmedState === DetailConfirmedState.Finalized) return;

      const splits: TransactionPageDetailFragment[] = categoriesByPermaKey
        ? bills
            .flatMap(({ lines }) => lines)
            .map((line) => ({
              __typename: "TransactionDetail" as const,
              amount: `-${line.amount.amount}`,
              capitalizable: null,
              category: categoriesByPermaKey[line.coaKey!],
              categoryIsLocked: false,
              descriptor: line.description ?? "",
              id: uniqueId("line"),
              isBillPayment: true,
              transactionId: transaction.id,
              accrualDate: transaction.detail.accrualDate,
              classSegments: transaction.detail.classSegments,
            }))
        : [];

      return linkBillsMutation({
        variables: {
          input: {
            billIds: bills.map((b) => b.id),
            transactionId: transaction.id,
            companyId: company.id,
            unmatchedAmountCoaKey: unmatchedAmountCoaKey,
            unmatchedSplitDescription: unmatchedSplitDescription,
          },
        },

        optimisticResponse: {
          __typename: "Mutation",
          linkBillsToTransaction: {
            transaction: {
              ...transaction,
              splits,
              linkedBills: bills,
              detail: {
                ...transaction.detail,
                isBillPayment: true,
              },
            },
          },
        },

        onCompleted({ linkBillsToTransaction }) {
          toast({
            message: `You successfully matched ${pluralize(bills.length, "bill")}.`,
            status: "success",
          });
          Analytics.matchedTransactionToBills({
            transactionId: linkBillsToTransaction.transaction.id,
            numberOfBills: linkBillsToTransaction.transaction.linkedBills.length,
          });
          refetch();
        },
        onError() {
          toast({
            message: `Something went wrong, and our team has been notified. We apologize for the inconvenience. Please try again later.`,
            status: "warning",
          });
        },
      });
    },
    [transaction, company.id, linkBillsMutation, categoriesByPermaKey, toast]
  );
}

export enum UpdateCategoryMetricsLocations {
  TransactionsTable = "transactionsTable",
  TransactionsDrawer = "transactionsDrawer",
  IntroTour = "introTour",
  InboxOnboardingChecklist = "inboxOnboardingChecklist",
  TransactionsHelpMeCategorizeButton = "transactionsHelpMeCategorizeButton",
  StripeDetailTable = "stripeDetailTable",
  PostOnboardModal = "postOnboardModal",
}

export enum UpdateCategoryMetricsView {
  TopTransactionsModal = "TopTransactionsModal",
  CategoryModal = "CategoryModal",
}

export function useUpdateCategory(transaction?: BasicTransactionFragment | null) {
  const client = useApolloClient();
  const { toast } = useToasts();
  const [recategorizeTransaction] = useRecategorizeTransactionMutation();

  return useCallback(
    ({
      category,
      metrics: { location, component } = {},
      onCompleted,
    }: {
      category: CategoryFragment;
      metrics?: {
        location?: UpdateCategoryMetricsLocations;
        component?: UpdateCategoryMetricsView;
      };
      onCompleted?: () => void;
    }) => {
      if (!transaction) return;
      if (transaction.detail.confirmedState === DetailConfirmedState.Finalized) return;

      const fullTransaction = client.readFragment<FullTransactionFragment>({
        id: `Transaction:${transaction.id}`,
        fragmentName: "fullTransaction",
        fragment: FullTransactionFragmentDoc,
      });

      const optimisticFixedAsset = isCapitalizable(transaction, category)
        ? {
            id: uniqueId("fixedAsset"),
            status: FixedAssetStatus.Draft,
          }
        : null;

      return recategorizeTransaction({
        variables: {
          input: {
            id: transaction.id,
            ledgerCoaKey: category.permaKey,
          },
        },

        update(cache, { data }) {
          const updated = data?.recategorizeTransaction;

          if (!updated || !fullTransaction) return;

          cache.writeQuery<GetFullTransactionQuery>({
            variables: {
              id: transaction.id,
            },
            query: GetFullTransactionDocument,
            data: {
              transaction: {
                ...fullTransaction,
                detail: {
                  ...updated.transaction.detail,
                  fixedAsset: updated.transaction.detail.fixedAsset ?? optimisticFixedAsset,
                },
              },
            },
          });
        },

        optimisticResponse: {
          __typename: "Mutation",
          recategorizeTransaction: {
            transaction: mergeDeep({ activity: { activity: [] } }, fullTransaction ?? transaction, {
              detail: {
                category,
                confirmedState: DetailConfirmedState.UserAssigned,
                fixedAsset: optimisticFixedAsset,
              },
            }),
          },
        },

        onCompleted(data) {
          const updatedTransaction = data.recategorizeTransaction.transaction;

          Analytics.transactionCategoryChanged({
            categoryId: category.coaKey!,
            oldCategoryId: transaction.detail.category.coaKey!,
            transactionId: transaction.id,
            transactionStatus: updatedTransaction.detail.confirmedState ?? undefined,
            source: "in-app",
            location,
            component,
          });
          onCompleted?.();
        },

        onError({ message }) {
          toast({ message, status: "error" });
        },
      });
    },
    [client, recategorizeTransaction, transaction, toast]
  );
}

export function useUpdateAccrualDate(transaction?: BasicTransactionFragment | null) {
  const client = useApolloClient();
  const { toast } = useToasts();
  const [moveDateForTransaction] = useMoveDateForTransactionMutation();

  return useCallback(
    (accrualDate: CalendarDate | null | undefined) => {
      if (!transaction) return;
      if (transaction.detail.confirmedState === DetailConfirmedState.Finalized) return;
      const fullTransaction = client.readFragment<FullTransactionFragment>({
        id: `Transaction:${transaction.id}`,
        fragmentName: "fullTransaction",
        fragment: FullTransactionFragmentDoc,
      });

      return moveDateForTransaction({
        variables: {
          input: {
            transactionId: transaction.id,
            accrualDate: accrualDate?.toString() ?? null,
          },
        },
        optimisticResponse: {
          __typename: "Mutation",
          moveDateForTransaction: {
            transaction: mergeDeep({ activity: { activity: [] } }, fullTransaction ?? transaction, {
              detail: {
                accrualDate: accrualDate?.toString() ?? null,
              },
            }),
          },
        },
        onCompleted() {
          Analytics.transactionAccrualDateAdded({ transactionId: transaction.id });
        },
        onError({ message }) {
          toast({ message, status: "error" });
        },
      });
    },
    [client, moveDateForTransaction, transaction, toast]
  );
}

export function useToggleFinalizedState(transaction?: BasicTransactionFragment | null) {
  const client = useApolloClient();
  const { toast } = useToasts();
  const [updateFinalizedStateMutation] = useUpdateFinalizedTransactionStateMutation();

  return useCallback(() => {
    if (!transaction) return;

    let isFinalized;
    if (transaction.detail.confirmedState === DetailConfirmedState.Finalized) {
      isFinalized = false;
    } else if (transaction.detail.confirmedState !== DetailConfirmedState.Locked) {
      isFinalized = true;
    } else {
      return;
    }

    // FIXME If you run two mutations in a row (capitalizable + finalizable),
    // this one will not see the updated transaction yet. Unfortunately we need to manually read the fragment.
    // Try not to do multiple mutations on a single entity like this...
    const currentFullTransaction = client.readFragment<FullTransactionFragment>({
      id: `Transaction:${transaction.id}`,
      fragmentName: "fullTransaction",
      fragment: FullTransactionFragmentDoc,
    });
    const currentBasicTransaction = client.readFragment<BasicTransactionFragment>({
      id: `Transaction:${transaction.id}`,
      fragmentName: "basicTransaction",
      fragment: BasicTransactionFragmentDoc,
    });
    const currentTransaction = currentFullTransaction ?? currentBasicTransaction;

    const optimisticTransaction = mergeDeep(currentTransaction, {
      // Defaults for BasicTransactionFragment.
      // The response expects new activity to be returned, but the activity is only loaded and used in the sidebar.
      activity: {
        currentAssignment: null,
        activity: [],
      },
      detail: {
        confirmedState: isFinalized
          ? DetailConfirmedState.Finalized
          : DetailConfirmedState.UserAssigned,
      },
    });

    return updateFinalizedStateMutation({
      variables: {
        input: {
          transactionId: transaction.id,
          isFinalized,
        },
      },

      optimisticResponse: {
        __typename: "Mutation",
        updateFinalizedTransactionState: {
          __typename: "UpdateTransactionResult",
          transaction: optimisticTransaction,
        },
      },

      onCompleted(data) {
        const transaction = data.updateFinalizedTransactionState.transaction;
        Analytics.transactionStatusChanged({
          transactionId: transaction.id,
          transactionStatus: transaction.detail.confirmedState ?? undefined,
        });
      },

      onError({ message }) {
        const copy = message.includes("capitalizable is not set") ? (
          <>
            If this transaction has a related pending fixed asset, please{" "}
            <Link underline target="_blank" href={Route.fixedAssets}>
              place the asset in service or delete it
            </Link>{" "}
            to successfully finalize transaction.
          </>
        ) : (
          message
        );

        toast({
          status: "error",
          title: "Failed to finalize transaction",
          message: copy,
          duration: 15000,
        });
      },
    });
  }, [client, transaction, updateFinalizedStateMutation, toast]);
}

export function useUpdateBillPayment(transaction?: FullTransactionFragment | null) {
  const [updateBillPaymentMutation, { loading }] = useUpdateIsBillPaymentMutation();
  const { company } = useActiveCompany<true>();

  return {
    mutation: useCallback(
      (isBillPayment: boolean) => {
        if (!transaction) {
          return;
        }

        return updateBillPaymentMutation({
          variables: {
            input: {
              companyId: company.id,
              detailId: transaction.detail.id,
              isBillPayment,
            },
          },

          update(cache, { data }) {
            const updated = data?.updateIsBillPayment;

            if (!updated) return;

            cache.writeQuery<GetFullTransactionQuery>({
              variables: {
                id: transaction.id,
              },
              query: GetFullTransactionDocument,
              data: {
                transaction: {
                  ...transaction,
                  detail: updated.detail,
                  activity: updated.activity,
                },
              },
            });
          },

          optimisticResponse: {
            __typename: "Mutation",
            updateIsBillPayment: {
              __typename: "UpdateTransactionDetailResult",
              activity: {
                ...transaction.activity,
              },
              detail: {
                ...transaction.detail,
                isBillPayment,
              },
            },
          },
        });
      },
      [transaction, updateBillPaymentMutation, company]
    ),
    loading,
  };
}

export function useUpdateInvoicePayment(transaction?: FullTransactionFragment | null) {
  const [updateInvoicePaymentMutation, { loading }] = useUpdateIsInvoicePaymentMutation();
  const { company } = useActiveCompany<true>();

  return {
    mutation: useCallback(
      (isInvoicePayment: boolean) => {
        if (!transaction) {
          return;
        }

        return updateInvoicePaymentMutation({
          variables: {
            input: {
              companyId: company.id,
              detailId: transaction.detail.id,
              isInvoicePayment,
            },
          },

          update(cache, { data }) {
            const updated = data?.updateIsInvoicePayment;

            if (!updated) return;

            cache.writeQuery<GetFullTransactionQuery>({
              variables: {
                id: transaction.id,
              },
              query: GetFullTransactionDocument,
              data: {
                transaction: {
                  ...transaction,
                  detail: updated.detail,
                  activity: updated.activity,
                },
              },
            });
          },

          optimisticResponse: {
            __typename: "Mutation",
            updateIsInvoicePayment: {
              __typename: "UpdateTransactionDetailResult",
              activity: {
                ...transaction.activity,
              },
              detail: {
                ...transaction.detail,
                isInvoicePayment,
              },
            },
          },
        });
      },
      [transaction, updateInvoicePaymentMutation, company]
    ),
    loading,
  };
}

export function useUpdateCard(transaction?: BasicTransactionFragment | null) {
  const [updateCardMutation] = useUpdateCardMutation();
  return useCallback(
    (card: CardFragment | null) => {
      if (!transaction) {
        return;
      }
      const input: UpdateCardInput = {
        detailId: transaction.detail.id,
        transactionId: transaction.id,
        cardId: card?.id,
      };
      return updateCardMutation({
        variables: { input },
        optimisticResponse: {
          __typename: "Mutation",
          updateCard: {
            __typename: "UpdateTransactionResult",
            transaction: {
              id: transaction.id,
              detail: {
                id: transaction.detail.id,
                card,
              },
            },
          },
        },
      });
    },
    [transaction, updateCardMutation]
  );
}

export function useExtraTransactionState(transaction?: BasicTransactionFragment | null) {
  const { membershipRole, isWithinLockedPeriod } = useActiveCompany<true>();

  // TODO add an UNSET category state - no null states
  const canEditCategory = transaction?.detail.confirmedState
    ? ![DetailConfirmedState.Finalized, DetailConfirmedState.Locked].includes(
        transaction?.detail.confirmedState
      )
    : true;

  const isLockedPeriod = Boolean(transaction && isWithinLockedPeriod(parseDate(transaction.date)));

  const canEditForFixedAsset =
    !transaction?.detail.fixedAsset ||
    [FixedAssetStatus.Draft, FixedAssetStatus.Void].includes(transaction.detail.fixedAsset.status);

  const canEditForPrepaid =
    !transaction?.detail.prepaid ||
    [PrepaidStatus.Draft, PrepaidStatus.Void].includes(transaction.detail.prepaid.status);

  const canEdit =
    canEditCategory &&
    !!membershipRole &&
    isEditorRole(membershipRole) &&
    !isLockedPeriod &&
    !transaction?.detail.schedule &&
    canEditForFixedAsset &&
    canEditForPrepaid;

  const canEditSplits = canEdit && !transaction?.linkedBills.length;

  const lockWarning = Boolean(isLockedPeriod && transaction?.detail.postedAt === null);

  const areAccrualDates =
    !!transaction?.detail.accrualDate || transaction?.splits.some((s) => s.accrualDate) || false;
  const canBeBillPayment = transaction && Big(transaction.amount).lt(0) && !areAccrualDates;
  const canBeInvoicePayment =
    transaction &&
    Big(transaction.amount).gt(0) &&
    transaction.account.type === AccountType.Depository;

  return useMemo(
    () => ({
      canEdit,
      canEditSplits,
      isLockedPeriod,
      lockWarning,
      canBeBillPayment,
      canBeInvoicePayment,
    }),
    [canEdit, canEditSplits, isLockedPeriod, lockWarning, canBeBillPayment, canBeInvoicePayment]
  );
}

export const useUpdateCustomer = (transaction?: BasicTransactionFragment | null) => {
  const { company } = useActiveCompany<true>();
  const client = useApolloClient();
  const [updateCustomerMutation] = useUpdateTransactionCustomerMutation();

  return (customerInput?: CustomerFragment | string | null) => {
    if (!transaction || !customerInput) return;
    const customer = typeof customerInput === "string" ? undefined : customerInput;
    const customerName = typeof customerInput === "string" ? customerInput : customer?.name;
    const customerId = typeof customerInput === "string" ? undefined : customer?.id;
    const variables: CustomersQueryVariables = { companyId: company.id, filterBy: { name: "" } };
    const optimisticCustomer = !customerInput
      ? null
      : customer || {
          __typename: "Customer",
          id: "temp id",
          name: customerName ?? "",
        };

    updateCustomerMutation({
      variables: {
        input: {
          transactionId: transaction.id,
          name: customerName,
          id: customerId,
          companyId: company.id,
        },
      },

      update(cache, { data }) {
        const customer = data?.updateTransactionCustomer.transaction.detail.customer;
        if (!customer) {
          return;
        }

        const customerData = client.readQuery<CustomersQuery>({
          query: CustomersDocument,
          variables,
        });
        const customers = customerData?.customers.items || [];

        if (customers.find((c) => c.id === customer.id)) {
          return;
        }

        cache.writeQuery<CustomersQuery>({
          variables,
          query: CustomersDocument,
          data: {
            customers: {
              items: [...customers, customer],
            },
          },
        });
      },

      // Types of property 'activity' are incompatible.
      optimisticResponse: {
        __typename: "Mutation",
        updateTransactionCustomer: {
          __typename: "UpdateTransactionResult",
          transaction: {
            ...transaction,
            detail: {
              ...transaction.detail,
              customer: optimisticCustomer,
            },
          },
        },
      },
    });
  };
};

export const useUpdateVendor = (transaction?: BasicTransactionFragment | null) => {
  const { company } = useActiveCompany<true>();
  const { toast } = useToasts();
  const [updateVendorMutation] = useUpdateTransactionVendorMutation();

  return (vendor?: VendorFragment | null) => {
    if (!transaction || !vendor) return;

    updateVendorMutation({
      variables: {
        input: {
          transactionId: transaction.id,
          vendorName: vendor?.name,
          companyId: company.id,
        },
      },

      onCompleted(data) {
        const transaction = data.updateTransactionVendor.transaction;
        Analytics.transactionVendorChanged({
          vendorId: transaction.detail.vendor?.id,
          transactionId: transaction.id,
        });
      },

      onError({ message }) {
        toast({ message, status: "error" });
      },

      optimisticResponse: {
        __typename: "Mutation",
        updateTransactionVendor: {
          __typename: "UpdateTransactionResult",
          transaction: {
            ...transaction,
            detail: {
              ...transaction.detail,
              vendor,
            },
          },
        },
      },
    });
  };
};

export const useUpdateDescriptor = (transaction?: BasicTransactionFragment | null) => {
  const [updateDescriptorMutation] = useUpdateDescriptorMutation();
  const { toast } = useToasts();

  return (descriptor: string) => {
    if (!transaction) return;

    updateDescriptorMutation({
      variables: { input: { transactionId: transaction.id, descriptor } },

      onCompleted(data) {
        const transaction = data.updateDescriptor.transaction;
        Analytics.transactionDescriptionChanged({
          transactionId: transaction.id,
          description: transaction.detail.descriptor,
        });
      },

      onError({ message }) {
        toast({ message, status: "error" });
      },

      optimisticResponse: {
        __typename: "Mutation",
        updateDescriptor: {
          __typename: "UpdateTransactionResult",
          transaction: {
            ...transaction,
            detail: {
              ...transaction.detail,
              descriptor,
            },
          },
        },
      },
    });
  };
};

// Using this in tables may be a performance hit. Prefer using the individual hooks.
export default function useSingleTransaction({ id }: { id?: string }) {
  const { company } = useActiveCompany<true>();
  const client = useApolloClient();

  const { data, refetch } = useGetFullTransaction(id);
  const transaction = data?.transaction;

  const [updateProductMutation] = useUpdateTransactionProductMutation();
  const [addMessageMutation] = useAddTransactionMessageMutation();
  const [updateRecurrenceMutation] = useUpdateRecurrenceMutation();

  const updateCategory = useUpdateCategory(transaction);
  const toggleFinalizedState = useToggleFinalizedState(transaction);
  const updateCard = useUpdateCard(transaction);
  const extraState = useExtraTransactionState(transaction);

  // TODO move these to useProducts, useCustomers, useVendors
  // Could also be DRYer
  const updateProduct = async (productInput: ProductFragment | string | null) => {
    if (!transaction) return;

    const product = typeof productInput === "string" ? undefined : productInput;
    const productName = typeof productInput === "string" ? productInput : productInput?.name;
    const productId = typeof productInput === "string" ? undefined : productInput?.id;
    const optimisticProduct = !productInput
      ? null
      : product || {
          __typename: "Product",
          id: uniqueId("product"),
          name: productName ?? "",
        };

    return updateProductMutation({
      variables: {
        input: {
          transactionId: transaction.id,
          name: productName,
          id: productId,
          companyId: company.id,
        },
      },

      update(cache, { data }) {
        const product = data?.updateTransactionProduct.transaction.detail.product;
        if (!product) {
          return;
        }

        const variables: ProductsQueryVariables = { companyId: company.id, filterBy: { name: "" } };
        const productData = client.readQuery<ProductsQuery>({
          query: ProductsDocument,
          variables,
        });
        const products = productData?.products.items || [];

        if (products.find((p) => p.id === product.id)) {
          return;
        }

        cache.writeQuery<ProductsQuery>({
          variables,
          query: ProductsDocument,
          data: {
            products: {
              items: [...products, product],
            },
          },
        });
      },

      optimisticResponse: {
        __typename: "Mutation",
        updateTransactionProduct: {
          __typename: "UpdateTransactionResult",
          transaction: {
            ...transaction,
            detail: {
              ...transaction.detail,
              product: productInput ? optimisticProduct : null,
            },
          },
        },
      },
    });
  };

  const updateCustomer = useUpdateCustomer(transaction);
  const updateVendor = useUpdateVendor(transaction);

  const updateDescriptor = useUpdateDescriptor(transaction);

  const updateRecurrence = async (recurrence: RecurrencePeriod) => {
    if (!transaction) return;
    return updateRecurrenceMutation({
      variables: { input: { transactionId: transaction.id, recurrence } },
      onCompleted(data) {
        const transaction = data.updateRecurrence.transaction;
        Analytics.transactionRecurrenceChanged({
          transactionId: transaction.id,
          recurrence: recurrence,
        });
      },
      optimisticResponse: {
        __typename: "Mutation",
        updateRecurrence: {
          __typename: "UpdateTransactionResult",
          transaction: {
            ...transaction,
            detail: {
              ...transaction.detail,
              recurrence,
            },
          },
        },
      },
    });
  };

  const { self } = useSelf();
  const addMessage = ({
    text,
    transaction,
  }: {
    transaction: FullTransactionFragment;
    text: string;
  }) => {
    return addMessageMutation({
      variables: {
        input: {
          transactionId: transaction.id,
          text,
        },
      },

      optimisticResponse: {
        addTransactionMessage: {
          transaction: {
            ...transaction,
            activity: {
              ...transaction.activity,
              activity: [
                ...transaction.activity.activity,
                {
                  __typename: "TransactionMessage",
                  id: "new",
                  actor: {
                    type: ActorType.UserActor,
                    name: self?.name,
                  },
                  type: TransactionMemoType.Message,
                  createdAt: new Date().toISOString(),
                  createdByUser: {
                    id: self!.id,
                    name: self!.name,
                  },
                  text,
                },
              ],
            },
          },
        },
      },
    });
  };

  const { getDownloadInfo, downloadInfo, deleteFile } = useFile({
    entityId: transaction?.id,
    entityType: AssociatedEntity.Transaction,
  });

  const deleteDocumentation = (fileId: string) => {
    deleteFile({ fileId });
    // TODO update cache instead
    refetch?.();
  };

  return {
    addMessage,
    toggleFinalizedState,
    updateCategory,
    updateVendor,
    updateCustomer,
    updateProduct,
    updateRecurrence,
    deleteDocumentation,
    updateCard,
    categorizedByRule: transaction?.detail.rule,
    transaction,
    refetch,
    activity: transaction?.activity.activity,
    getDownloadInfo,
    signedUrl: downloadInfo?.signedUrl,
    updateDescriptor,
    ...extraState,
  };
}
