import React from "react";
import { Button, ButtonGroup, Dialog } from "@puzzle/ui";
import { manualAccountFormValues } from "./ConnectManualAccountFormStep";

type SuccessfullyAddedManualAccountModalProps = React.ComponentPropsWithoutRef<typeof Dialog> & {
  onPrimary: () => void;
  onSecondary: () => void;
};

export const SuccessfullyAddedManualAccountModal = ({
  onPrimary,
  onSecondary,
  ...rest
}: SuccessfullyAddedManualAccountModalProps) => {
  const { institutionName, name, mask } = manualAccountFormValues();
  const display = `${institutionName}・${name} - ${mask}`;

  return (
    <Dialog
      {...rest}
      width={480}
      onOpenChange={(open) => {
        if (!open) {
          onSecondary();
        }
        rest.onOpenChange?.(open);
      }}
    >
      <Dialog.Title showClose>Import transactions into the new account</Dialog.Title>
      <Dialog.Body>
        <p>
          You have successfully added the new account {display}. Since this account is not directly
          connected with the institution, you will need to manually import your transactions in
          order to have complete financial data in Puzzle.
        </p>

        <p>Would you like to import transactions now?</p>
      </Dialog.Body>
      <Dialog.Footer>
        <ButtonGroup>
          <Dialog.Close asChild>
            <Button variant="secondary" onClick={onSecondary}>
              Not now
            </Button>
          </Dialog.Close>
          <Button variant="primary" onClick={onPrimary}>
            Yes, import
          </Button>
        </ButtonGroup>
      </Dialog.Footer>
    </Dialog>
  );
};
