import React from "react";
import useMercury from "../mercury/useMercuryIntegration";
import Logo from "../mercury/MercuryLogo";
import IntegrationDetails from "./IntegrationDetails";
import { DetailProps } from "./types";

const MercuryDetails = ({ companyId, integrationConnectionId, ...props }: DetailProps) => {
  const mercury = useMercury({ companyId, integrationConnectionId });

  return (
    <IntegrationDetails
      logo={<Logo />}
      title="Mercury"
      connection={mercury.connection}
      onDisconnect={mercury.disconnect}
      onReconnect={mercury.onClickConnect}
      {...props}
    />
  );
};

export default MercuryDetails;
