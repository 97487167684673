import React from "react";
import { styled, keyframes, CSSProps } from "@puzzle/theme";

// We need to deprecate this library and prefer autogeneration.
// Too much is done by hand.
// For now, try to optimize this to be as generic as possible.

const DEFAULT_ICON_SIZE = 16;

export type IconState = "active" | "warning" | "negative";
export type IconProps = React.PropsWithChildren<
  {
    fill?: string;
    stroke?: string;
    color?: string;
    rotate?: number | string;
    className?: string;
    viewBox?: string;
    size?: number;
    onClick?: React.MouseEventHandler;
    width?: number;
    height?: number;
    spinning?: boolean;
    style?: React.CSSProperties;

    // Icons should be generic and stateless. Start handling these externally.

    /** @deprecated */
    active?: boolean;
    /** @deprecated */
    negative?: boolean;
    /** @deprecated */
    state?: IconState;
  } & CSSProps
>;

const rotateAnimation = keyframes({
  to: {
    transform: "rotate(360deg)",
  },
});

const StyledSvg = styled("svg", {
  transition: "transform 0.3s",
  alignSelf: "center",

  defaultVariants: {
    clickable: false,
  },

  variants: {
    clickable: {
      true: {
        cursor: "pointer",
      },
      false: {},
    },
    spinning: {
      true: {
        animation: `${rotateAnimation} 1500ms infinite linear`,
      },
    },
  },
});

const Icon = React.memo(
  React.forwardRef<SVGSVGElement, IconProps>(
    (
      {
        size,
        width = DEFAULT_ICON_SIZE,
        height = DEFAULT_ICON_SIZE,
        rotate,
        css,
        color = "currentColor",

        // I don't think these should be defined on the outside?
        fill = color,
        stroke,

        ...props
      },
      ref
    ) => {
      return (
        <StyledSvg
          // Historically, size would override width/height.
          // Size should only be used for 1:1 (square) aspect ratios.
          width={size ?? width}
          height={size ?? height}
          // These ideally both be "none", but I can't verify all icons
          fill={fill}
          stroke={stroke}
          // Additional variants
          clickable={Boolean(props.onClick)}
          {...props}
          css={{
            transform: rotate ? `rotate(${rotate}deg)` : undefined,
            ...css,
          }}
          ref={ref}
          xmlns="http://www.w3.org/2000/svg"
        />
      );
    }
  )
);

export default Icon;
