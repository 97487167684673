import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Sparkle({
  className,
  rotate,
  size = 14,
  viewBox = "0 0 14 14",
  color,

  ...props
}: IconProps) {
  return (
    <Icon className={className} rotate={rotate} viewBox={viewBox} size={size} {...props}>
      <path
        d="M9.55428 8.26933L14 7L9.55428 5.73067C8.93233 5.5538 8.44622 5.06767 8.26933 4.44572L7 0L5.73067 4.44572C5.5538 5.06767 5.06767 5.55378 4.44572 5.73067L0 7L4.44572 8.26933C5.06767 8.4462 5.55378 8.93233 5.73067 9.55428L7 14L8.26933 9.55428C8.4462 8.93233 8.93233 8.44622 9.55428 8.26933Z"
        fill={color ?? "#DD4EB9"}
      />
    </Icon>
  );
}
