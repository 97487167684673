import React from "react";

import { styled, StatusIcon } from "@puzzle/ui";

import { Feed } from "./Feed";

const UpdatesListItem = styled("div", {
  display: "flex",
  cursor: "pointer",
  padding: "$2 $1h",
  alignItems: "center",
  position: "relative",
  borderBottom: "1px solid $mauve680",

  "&:last-child": {
    borderBottom: 0,
  },

  "&:hover": {
    backgroundColor: "$mauve680",
    borderRadius: "4px",
  },
});

const TaskList = ({ archived }: { archived?: boolean }) => {
  return (
    <Feed
      feed={archived ? "archived" : "tasks"}
      emptyIcon={archived ? undefined : <StatusIcon status="completeAndInProgress" size={44} />}
      emptyTitle={archived ? "Nothing archived" : "You're all caught up!"}
      emptyDescription={
        archived
          ? "You can clean up your Inbox by archiving items."
          : "When there are tasks that need your attention, you'll see them here."
      }
    />
  );
};

export default TaskList;
