import React from "react";
import useEvery from "./useEvery";
import { IntegrationsListItemBaseProps } from "../List";
import { IntegrationType } from "graphql/types";
import { IntegrationDisplayComponent } from "../shared";
import { EveryLogo } from ".";

interface EveryListItemProps {
  DisplayComponent: IntegrationDisplayComponent<
    React.PropsWithChildren<IntegrationsListItemBaseProps>
  >;
  companyId: string;
}
export const TITLE = "Every";

const EveryListItem = ({ DisplayComponent, companyId }: EveryListItemProps) => {
  const { connection, loading } = useEvery({ companyId });

  if (!connection) {
    return null;
  }

  return (
    <DisplayComponent
      integrationType={IntegrationType.Every}
      title={TITLE}
      logo={<EveryLogo />}
      connection={connection}
      loading={loading}
      isBeta={false}
    />
  );
};

export default EveryListItem;
