import { useCallback, useMemo } from "react";
import { useActiveCompany } from "components/companies";
import { useAccountingConfigurationsQuery } from "../graphql.generated";
import { keyBy } from "lodash";

export function useConfigurationsQuery() {
  const { company } = useActiveCompany<true>();
  const { data, loading } = useAccountingConfigurationsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        companyId: company.id,
      },
    },
  });

  const keyd = useMemo(() => {
    return keyBy(data?.accountingConfigurations?.configurations, "id");
  }, [data?.accountingConfigurations.configurations]);
  const getConfigurationById = useCallback((id?: string) => (id ? keyd[id] : undefined), [keyd]);

  return useMemo(
    () => ({
      configurations: data?.accountingConfigurations?.configurations,
      getConfigurationById,
      loading,
    }),
    [data, loading, getConfigurationById]
  );
}
