import React from "react";

// Slightly different from the other Paperclip
export function Paperclip() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
        stroke="#77767E"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.75 6.978l-5.488 5.49a3.375 3.375 0 01-4.773-4.774l4.508-4.507a2.25 2.25 0 113.181 3.181l-4.507 4.51a1.125 1.125 0 01-1.591-1.59l4.508-4.51"
      ></path>
    </svg>
  );
}

// TODO replace with LongArrow in circular button?
export function Detail() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.646 15.354a.5.5 0 010-.708L16.291 12l-2.647-2.646a.501.501 0 01.709-.708l3 3a.502.502 0 010 .708l-3 3a.499.499 0 01-.708 0z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M7 12h10m0 0l-4-4m4 4l-4 4"
      ></path>
      <circle cx="12" cy="12" r="11.5" stroke="#3C3B44"></circle>
    </svg>
  );
}
