import React, { useRef, useCallback, useEffect, useMemo } from "react";
import { shallow } from "zustand/shallow";
import { parseAbsoluteToLocal } from "@internationalized/date";
import { differenceInHours } from "date-fns";
import { Feed, FeedItem, GenericData, Recipient } from "@knocklabs/client/dist/types";

import { styled, Avatar, Text } from "@puzzle/ui";
import { Confirmed } from "@puzzle/icons";
import { useLocalDateFormatter } from "@puzzle/utils";
import useAppRouter from "lib/useAppRouter";
import Analytics from "lib/analytics";

import { UpdateType } from "../shared";
import { useInboxContext, useInboxStore } from "../InboxContext";

const UpdatesListItem = styled("div", {
  display: "flex",
  cursor: "pointer",
  padding: "$2 $1h",
  alignItems: "center",
  position: "relative",
  borderBottom: "1px solid $mauve680",

  "&:last-child": {
    borderBottom: 0,
  },

  "&:hover": {
    backgroundColor: "$mauve680",
    borderRadius: "4px",
  },

  defaultVariants: {
    isSeen: false,
  },

  variants: {
    isSeen: {
      false: {
        background: "#414154", // Color not available in theme file
        borderRadius: "4px",

        "&:after": {
          content: '""',
          position: "absolute",
          width: "8px",
          height: "8px",
          borderRadius: "8px",
          background: "$greenalpha",
          top: "calc(50% - 4px)",
          right: "8px",
        },
      },
    },
  },
});

const UpdateDetail = styled("div", {
  paddingLeft: "$3",
  paddingRight: "$2",

  "& p": {
    margin: 0,
  },
});

const UpdateBody = styled("div", {
  color: "$gray300",
  textVariant: "$bodyXS",
});

const UpdateDatetime = styled("div", {
  color: "$gray400",
  textVariant: "$bodyXS",
  marginTop: "4px",
});

const ItemAvatar = ({ item }: { item: FeedItem<GenericData> }) => {
  const content = useMemo(() => {
    const actor = item.actors?.[0] as Recipient | undefined;

    switch (item.data?.messageType) {
      case UpdateType.UserRemoved:
      case UpdateType.UserAdded: {
        return (
          <Avatar
            user={{
              name: item.data?.userName ?? "Unknown",
            }}
          />
        );
      }
      default: {
        return actor && "email" in actor ? (
          <Avatar
            user={{
              email: actor.email ?? undefined,
              name: actor.name ?? "Unknown",
            }}
          />
        ) : (
          <Confirmed size={28} />
        );
      }
    }
  }, [item]);

  return <div>{content}</div>;
};

const UpdateItem = ({
  item,
  feedClient,
}: {
  item: FeedItem<GenericData>;
  feedClient?: Feed | null;
}) => {
  const { goToTransaction } = useAppRouter();
  const dateFormatter = useLocalDateFormatter({ month: "short", day: "numeric" });
  const timeFormatter = useLocalDateFormatter({ timeStyle: "short" });
  const insertedAtDate = parseAbsoluteToLocal(item.inserted_at);

  const handleUpdateClick = useCallback(() => {
    Analytics.notificationClicked({
      id: item.id,
      isUnread: !item.seen_at,
      source: item.source.key,
      messageType: item.data?.messageType,
      tab: "updates",
      createdHoursAgo: differenceInHours(new Date(), new Date(item.inserted_at)),
    });

    feedClient?.markAsSeen(item);

    // TODO possibly define routes in Knock..
    switch (item.data?.messageType) {
      case UpdateType.TransactionSubscribe: {
        goToTransaction(item.data?.transactionId);
        return;
      }
      // Handle more cases here as and when new update types are added
      default: {
        // Do Nothing
        return;
      }
    }
  }, [feedClient, goToTransaction, item]);

  return (
    <UpdatesListItem
      key={item.id}
      onClick={() => handleUpdateClick()}
      isSeen={Boolean(item.seen_at)}
    >
      <ItemAvatar item={item} />
      <UpdateDetail>
        <UpdateBody
          dangerouslySetInnerHTML={{
            __html: item.blocks?.[0].rendered,
          }}
        />
        <UpdateDatetime>
          {dateFormatter.format(insertedAtDate)} at {timeFormatter.format(insertedAtDate)}
        </UpdateDatetime>
      </UpdateDetail>
    </UpdatesListItem>
  );
};

const NoUpdatesWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  textAlign: "center",
  paddingTop: "$3",
});

const UpdatesList = () => {
  const {
    feeds: { updates: feedClient },
  } = useInboxContext();
  const { loading, pageInfo, totalItems, items } = useInboxStore(
    "updates",
    (state) => ({
      loading: state.loading,
      pageInfo: state.pageInfo,
      totalItems: state.metadata.total_count,
      items: state.items,
    }),
    shallow
  );

  const observer = useRef<IntersectionObserver | null>(null);

  const itemsRef = useRef(items);
  itemsRef.current = items;

  // Mark all items as seen after unmounting
  useEffect(() => {
    return () => {
      const unseenItems = itemsRef.current.filter((item) => item.seen_at === undefined);
      if (unseenItems.length > 0) {
        feedClient?.markAsSeen(unseenItems);
      }
    };
  }, [feedClient]);

  const lastUpdateItem = useCallback(
    (node: Element) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
        if (entries[0].isIntersecting && pageInfo.after) {
          feedClient?.fetch({ after: pageInfo.after });
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, feedClient, pageInfo.after]
  );

  return (
    <>
      {items.length === 0 ? (
        <NoUpdatesWrapper>
          <Text variant="headingS" color="gray50" css={{ marginTop: "$1" }}>
            No updates to show
          </Text>
          <Text variant="bodyS" color="gray400" css={{ marginTop: "$1", marginBottom: "$2" }}>
            When something happens to a transaction you're subscribed to, you'll be notified here.
          </Text>
        </NoUpdatesWrapper>
      ) : (
        <>
          {items.map((item) => (
            <UpdateItem key={item.id} item={item} feedClient={feedClient} />
          ))}

          {pageInfo.after && !loading && items.length < totalItems && (
            <Text
              variant="bodyS"
              color="gray200"
              css={{
                marginTop: "$2",
                textAlign: "center",
                display: "block",
              }}
              // @ts-expect-error passing void callback as ref
              ref={lastUpdateItem}
            >
              Keep scrolling to see more updates
            </Text>
          )}
        </>
      )}
    </>
  );
};

export default UpdatesList;
