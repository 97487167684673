import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Clear({
  viewBox = "0 0 16 16",
  color = "currentColor",
  ...iconProps
}: IconProps) {
  return (
    <Icon {...iconProps} viewBox={viewBox}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M1 8a7 7 0 1114 0A7 7 0 011 8zm4.148-2.852a.5.5 0 01.707 0L8 7.293l2.145-2.145a.5.5 0 01.707.707L8.707 8l2.145 2.145a.5.5 0 01-.707.707L8 8.707l-2.145 2.145a.5.5 0 01-.707-.707L7.293 8 5.148 5.855a.5.5 0 010-.707z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
}
