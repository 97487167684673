import React from "react";
import Icon, { IconProps } from "../Icon";

export default function Ramp({ viewBox = "0 0 48 48", size = 48, ...iconProps }: IconProps) {
  return (
    <Icon size={size} {...iconProps} viewBox={viewBox}>
      <path
        d="M34.5 33.9389V34.0407L22.5179 34.0458V33.9389C24.2458 32.7345 25.4384 31.5076 26.5115 30.2256H31.4316L34.5 33.9389ZM31.5311 14.6773L28.494 11H28.4057C28.4057 11 28.457 17.8531 23.3577 24.0858C18.3679 30.1859 12.5 30.1997 12.5 30.1997V30.3066L15.594 34.0476C15.594 34.0476 21.3765 34.1182 26.4845 27.9337C31.5738 21.7699 31.5311 14.6773 31.5311 14.6773Z"
        fill="black"
      />
    </Icon>
  );
}
