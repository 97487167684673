import { useCallback, useMemo, useState } from "react";
import { chunk, concat } from "lodash";

import useTransactions from "components/dashboard/Transactions/hooks/useTransactions";
import useCategories from "components/common/hooks/useCategories";
import {
  DetailConfirmedState,
  ProgrammaticRuleType,
  TransactionDetailActorType,
  TransactionFilterBy,
  TransactionSortOrder,
} from "graphql/types";
import { useActiveCompany } from "components/companies";
import { WatchQueryFetchPolicy } from "@apollo/client";
import { RefetchWritePolicy } from "@apollo/client/core/watchQueryOptions";
import { endOfMonth, startOfMonth, today } from "@internationalized/date";
import { GetTransactionsQueryVariables } from "components/dashboard/Transactions/graphql.generated";
import { CalendarDateString } from "scalars";

/**
 * Grabs transactions needing categorization review.
 *
 * Intentionally inflexible for now, this hook owns which transactions we consider
 * needing categorization review. As this is used now, we want the set of returned
 * transactions to be the same everywhere this is used, with the exception of date range
 */
export default function useTransactionsNeedingCategorizationReview(
  companyId: string,
  {
    prefetch = true,
    pageSize = 10,
    prefetchSize = pageSize * 10,
    startDate,
    endDate,
    options,
  }: {
    pageSize?: number;
    startDate?: CalendarDateString;
    endDate?: CalendarDateString;

    /**
     * TODO This is currently a cap.
     * If we want more, we need to fetchMore before the user categorizes.
     */
    prefetchSize?: number;
    /**
     * We don't use real cursor pagination.
     * Leave this on if you want to fetch a large list of transactions in advance.
     */
    prefetch?: boolean;
    options?: {
      fetchPolicy?: WatchQueryFetchPolicy;
      nextFetchPolicy?: WatchQueryFetchPolicy;
      refetchWritePolicy?: RefetchWritePolicy;
    };
  } = {}
) {
  const { timeZone } = useActiveCompany();
  const { commonCategories, uncategorizedPermaKeys, coaReady } = useCategories();

  const defaultLedgerCoaKeys = useMemo(
    () => (coaReady && uncategorizedPermaKeys && commonCategories ? uncategorizedPermaKeys : []),
    [commonCategories, uncategorizedPermaKeys, coaReady]
  );

  const sharedQueryVariables = useMemo<TransactionFilterBy>(
    () => ({
      // by default do last 3 full months + this month
      startDate: startDate ?? startOfMonth(today(timeZone).subtract({ months: 3 })).toString(),
      endDate: endDate ?? endOfMonth(today(timeZone)).toString(),
      state: DetailConfirmedState.Automated,
      accountIds: [],
      actorType: TransactionDetailActorType.SystemActor,
      isLinked: false,
      categorizedByUserRule: false,
    }),
    [startDate, endDate, timeZone]
  );

  // everything uncategorized
  const uncategorizedQueryVariables = useMemo<GetTransactionsQueryVariables>(
    () => ({
      companyId,
      page: { count: prefetch ? prefetchSize : pageSize, after: null },
      sortBy: TransactionSortOrder.AbsAmountDesc,
      filterBy: {
        // by default do last 3 full months + this month
        ledgerCoaKeys: defaultLedgerCoaKeys,
        ...sharedQueryVariables,
      },
    }),
    [companyId, defaultLedgerCoaKeys, pageSize, prefetch, prefetchSize, sharedQueryVariables]
  );

  // transactions categorized by categorizer rules that are notoriously bad
  const iffyRulesQueryVariables = useMemo<GetTransactionsQueryVariables>(
    () => ({
      companyId,
      page: { count: prefetch ? prefetchSize : pageSize, after: null },
      sortBy: TransactionSortOrder.AbsAmountDesc,
      filterBy: {
        // by default do last 3 full months + this month
        programmaticRuleTypes: [
          ProgrammaticRuleType.PlaidTransactionMatcher,
          ProgrammaticRuleType.GloballyPreviouslyCategorized,
        ],
        excludingCoaKeys: uncategorizedPermaKeys,
        ...sharedQueryVariables,
      },
    }),
    [companyId, pageSize, prefetch, prefetchSize, sharedQueryVariables, uncategorizedPermaKeys]
  );

  const [pagesFetched, setPagesFetched] = useState(1);
  const uncategorizedTransactionsResult = useTransactions(uncategorizedQueryVariables, {
    fetchPolicy: "network-only",
    skip: !commonCategories,
    ...options,
  });
  const iffyRulesTransactionsResult = useTransactions(iffyRulesQueryVariables, {
    fetchPolicy: "network-only",
    skip: !commonCategories,
    ...options,
  });

  const allTransactions = useMemo(
    () => [
      ...uncategorizedTransactionsResult.transactions,
      ...iffyRulesTransactionsResult.transactions,
    ],
    [uncategorizedTransactionsResult, iffyRulesTransactionsResult]
  );

  const hasMore = prefetch
    ? allTransactions.length > pagesFetched * pageSize
    : uncategorizedTransactionsResult.hasMore || iffyRulesTransactionsResult.hasMore;

  const fetchNextPage = useCallback(async () => {
    if (!hasMore) {
      return;
    }

    if (!prefetch) {
      await uncategorizedTransactionsResult.fetchNextPage();
      await iffyRulesTransactionsResult.fetchNextPage();
    }

    setPagesFetched((x) => x + 1);
  }, [hasMore, prefetch, uncategorizedTransactionsResult, iffyRulesTransactionsResult]);

  const transactions = useMemo(() => {
    const maxLength = pagesFetched * pageSize;
    return allTransactions.slice(0, maxLength);
  }, [pageSize, pagesFetched, allTransactions]);

  const transactionsByPage = chunk(transactions, pageSize);

  const totalItems = useMemo(() => {
    if (prefetch) {
      return Math.min(allTransactions.length, prefetchSize);
    }

    const totalUncategorized = uncategorizedTransactionsResult?.pageInfo?.total ?? 0;
    const totalIffy = iffyRulesTransactionsResult?.pageInfo?.total ?? 0;
    return totalIffy + totalUncategorized;
  }, [
    prefetch,
    prefetchSize,
    uncategorizedTransactionsResult,
    iffyRulesTransactionsResult,
    allTransactions.length,
  ]);

  const nextPageCount = useMemo(() => {
    return Math.max(0, Math.min(totalItems - pageSize * pagesFetched, pageSize));
  }, [pageSize, pagesFetched, totalItems]);

  const refetch = useCallback(() => {
    const iffyResult = iffyRulesTransactionsResult.refetch();
    const uncategorizedResult = uncategorizedTransactionsResult.refetch();
    return Promise.all([iffyResult, uncategorizedResult]).then((results) => {
      return concat(
        results[0].data.company?.transactions.nodes,
        results[1].data.company?.transactions.nodes
      );
    });
  }, [iffyRulesTransactionsResult, uncategorizedTransactionsResult]);

  return {
    transactions,
    transactionsByPage,
    fetchNextPage,
    loading: iffyRulesTransactionsResult.loading || uncategorizedTransactionsResult.loading,
    isInitialLoad:
      iffyRulesTransactionsResult.loading &&
      uncategorizedTransactionsResult.loading &&
      transactions.length === 0,
    refetch,
    hasMore,
    totalItems,
    nextPageCount,
  };
}
