import React, { useState } from "react";
import { parseDate } from "@internationalized/date";

import { styled, Button, IconButton, Stack, Text } from "@puzzle/ui";
import { External, Sparkle } from "@puzzle/icons";
import { formatMoney, formatAccountNameWithInstitution } from "@puzzle/utils";
import { OffsetArrow } from "@puzzle/icons";

import DescriptionList from "components/common/DescriptionList";

import { AccountType, AssociatedEntity, ClassAwareType, FileFragment, IntegrationType } from "graphql/types";

import useSingleTransaction, {
  UpdateCategoryMetricsLocations,
  UpdateCategoryMetricsView,
  useExtraTransactionState,
  useUpdateCategory,
} from "../hooks/useSingleTransaction";
import {
  CreateRuleInput,
  isUserCategorizationRule,
} from "components/dashboard/Transactions/Rules/hooks";
import CustomerSelect from "../UpdateField/CustomerSelect";
import ProductSelect from "../UpdateField/ProductSelect";

import { VendorSelect } from "components/transactions/vendors";
import { AccountCell, StatusIcon } from "components/transactions/Cells";
import { RuleModal } from "components/dashboard/Transactions/Rules/RuleModal";
import useSearchOptions from "../hooks/useSearchOptions";
import ActivitySection from "./ActivitySection";
import { AssignmentsSection } from "./AssignmentsSection";
import { SummarySection } from "./SummarySection";
import { CategorySection } from "./CategorySection";
import { DocumentationSection } from "components/common/DocumentationSection";
import { useDetailPaneContext } from "./DetailPaneContext";
import { AssociatedSection } from "./AssociatedSection";
import { isCreditCard } from "components/transactions/utils";
import { CCOffsetModal, CCOffsetSteps } from "components/transactions/CCOffsetModal";
import { ManualTransactionTooltip } from "../AsteriskTooltip";
import { MatchBillsSection } from "./MatchBillsSection";
import { CategoryFragment } from "graphql/fragments/category.generated";
import RecurrenceSelect from "../UpdateField/RecurrenceSelect";
import { Alert } from "@puzzle/ui";
import useSelf from "components/users/useSelf";
import ClassificationsSection from "components/common/Classifications/ClassificationsSection";
import { IS_DEV } from "lib/config";
import { FullTransactionFragment, TransactionDocumentFragment } from "../graphql.generated";
import { ApolloCache } from "@apollo/client";
import { getInstitutionForDocument, updateTransactionDocumentCache } from "./utils";

const Header = styled("div", {
  display: "flex",
  gap: "$0h",
  margin: "0 0 $4 0",
  height: "40px",
  alignItems: "center",
  paddingLeft: "$0h",
});

const Sections = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "$3",

  [`${DescriptionList}`]: {
    "&[data-direction='horizontal']": {
      $$itemGap: "$space$3",
      $$termWidth: "135px",
    },
  },
});
const CreditCardSection = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  contentAlign: "center",
});

function UploadedBy({document, transaction }: { document: TransactionDocumentFragment, transaction: FullTransactionFragment}) {
  if(document.uploadActorType === "SYSTEM_ACTOR") {
    return <span>via {getInstitutionForDocument(document, transaction)}</span>;
  }
 return null;
}

const isCreatingRule = (input: CreateRuleInput | null | string): input is CreateRuleInput => {
  return !!input && typeof input !== "string";
};

const AI_AUTO_ASSIGN_SEEN_KEY = "ai-auto-assign-by-comment-seen";

const DetailBody = () => {
  const [editingRule, setEditingRule] = useState<CreateRuleInput | string | null>(null);

  const { messageSettings, upsertMessageSettings } = useSelf();

  const [showAIAutoAssign, setShowAIAutoAssign] = useState(
    false
    // !messageSettings[AI_AUTO_ASSIGN_SEEN_KEY] && company.features.aiAssistedCategorizationEnabled
  );

  const { transaction, isEditingSplit } = useDetailPaneContext<true>();
  const updateCategory = useUpdateCategory(transaction);

  const onCategoryChange = (c: CategoryFragment) => {
    if (canEdit && c)
      updateCategory({
        category: c,
        metrics: {
          location: UpdateCategoryMetricsLocations.TransactionsDrawer,
          component: UpdateCategoryMetricsView.CategoryModal,
        },
      });
  };

  const dismissAIAutoAssign = async () => {
    setShowAIAutoAssign(false);
    await upsertMessageSettings(AI_AUTO_ASSIGN_SEEN_KEY, true);
  };

  const {
    // FIXME: useExtraTransactionState
    canEdit,
    canEditSplits,
    categorizedByRule,
    isLockedPeriod,

    // FIXME: Individually load these mutation hooks.
    // They create state which will re-render the whole sidebar.
    updateVendor,
    updateCustomer,
    updateProduct,
    updateRecurrence,
    refetch,
  } = useSingleTransaction({ id: transaction?.id });

  const { categories, categoriesByPermaKey } = useSearchOptions();
  const [addOffset, setAddOffset] = useState<boolean>(false);
  const [autoSuggestRule, setAutoSuggestRule] = useState<boolean>(false);
  const { canBeBillPayment } = useExtraTransactionState(transaction);
  const { setPreviewFile } = useDetailPaneContext<true>();

  // todo: loading indication
  if (!transaction) return null;

  const openCreateRule = () => {
    setEditingRule({
      ledgerCoaKey: transaction.detail.category.coaKey!,
      vendorId: transaction.detail.vendor?.id,
      effectiveAt: parseDate(transaction.date).toString(),
      rule: transaction.descriptor,
      matchEntireString: true,
      accountTypes: [transaction.account.type],
    });
  };

  const openEditRule = (id: string) => {
    setEditingRule(id);
  };

  const clearEditingRule = () => {
    setAutoSuggestRule(false);
    setEditingRule(null);
  };

  const handleModalTriggerClick = (ruleId = "", autoSuggestLocation = false) => {
    setAutoSuggestRule(autoSuggestLocation);

    if (hasEditableRule) {
      openEditRule(ruleId);
    } else {
      openCreateRule();
    }
  };

  // Is this a revenue txn or an expense txn?
  // todo these !s can be removed soon
  if (transaction.detail.category.__typename !== "LedgerCategory") {
    throw new Error("Wrong category type");
  }

  const isRevenueTransaction = transaction.detail.category.defaultCashlike === "REVENUE";
  const creditCard = isCreditCard(transaction);
  const hasEditableRule = categorizedByRule && isUserCategorizationRule(categorizedByRule);

  return (
    <>
      <Header>
        <Stack gap="1" direction="horizontal" css={{ alignItems: "center", flex: 1 }}>
          <AccountCell account={transaction.account} css={{ width: 18, textAlign: "center" }} />
          <Text size="headingL" letterSpacing="bodyXS">
            {formatMoney({ currency: "USD", amount: transaction.amount })}
            <ManualTransactionTooltip
              integrationType={transaction.integrationType}
              css={{ top: "-10px" }}
            />
          </Text>
          {transaction.linkToSourceTransaction && (
            <IconButton
              onClick={() => window.open(transaction.linkToSourceTransaction || "", "_blank")}
            >
              <External />
            </IconButton>
          )}
        </Stack>

        <Stack gap="3" direction="horizontal" css={{ alignItems: "center" }}>
          {
            // FIXME Uhhh should we hide revenue categories in RuleModal?
            !isRevenueTransaction && transaction.splits.length === 0 && canEdit ? (
              <Button
                variant="minimal"
                color={!hasEditableRule ? "primary" : undefined}
                size="small"
                onClick={() => handleModalTriggerClick(categorizedByRule?.id)}
              >
                {hasEditableRule ? "Edit rule" : "Create rule"}
              </Button>
            ) : null
          }
          {!isLockedPeriod && (
            <StatusIcon transaction={transaction} location="transactionSideDrawer" />
          )}
        </Stack>
      </Header>
      <RuleModal
        open={Boolean(editingRule)}
        onOpenChange={(open) => !open && clearEditingRule()}
        initialValues={isCreatingRule(editingRule) ? editingRule : undefined}
        id={!editingRule || isCreatingRule(editingRule) ? undefined : editingRule}
        originTransaction={transaction}
        location={autoSuggestRule ? "transactionDrawerAutoSuggest" : "transactionSideDrawer"}
        onEscapeKeyDown={(e) => e.stopPropagation()}
      />
      <Sections>
        <SummarySection transaction={transaction} />

        <CategorySection
          transaction={transaction}
          canEdit={canEditSplits}
          categories={categories}
        />

        {isEditingSplit || transaction.splits.length > 0 ? null : (
          <ClassificationsSection
            hideAddButton={transaction.integrationType === IntegrationType.Stripe && !IS_DEV}
            entity={{
              id: transaction.detail.id,
              name: transaction.detail.descriptor,
              type: ClassAwareType.TransactionDetail,
              classSegments: transaction.detail.classSegments,
            }}
          />
        )}

        {creditCard && transaction.account.type === AccountType.Depository && (
          <DescriptionList
            direction="horizontal"
            items={[
              [
                "Credit account paid",
                <CreditCardSection key="credit-account-paid">
                  {canEdit ? (
                    <>
                      <CCOffsetModal
                        open={addOffset}
                        onOpenChange={setAddOffset}
                        trigger={
                          <Button variant="minimal" size="small" prefix={<OffsetArrow />}>
                            Add offsetting transaction
                          </Button>
                        }
                        isFinalizing
                        transaction={transaction}
                        initialStep={CCOffsetSteps.Offset}
                      />
                    </>
                  ) : (
                    <>
                      {transaction.detail.linkedTransaction && (
                        <>
                          {" "}
                          {formatAccountNameWithInstitution(
                            transaction.detail.linkedTransaction.account
                          )}
                        </>
                      )}
                    </>
                  )}
                </CreditCardSection>,
              ],
            ]}
          />
        )}
        {canBeBillPayment && (
          <MatchBillsSection
            transaction={transaction}
            categories={categories}
            canEdit={canEdit}
            onCategoryChange={onCategoryChange}
            categoriesByPermaKey={categoriesByPermaKey}
          />
        )}

        {isRevenueTransaction ? (
          <DescriptionList
            items={[
              [
                "Customer",
                <CustomerSelect
                  key="customer-select"
                  value={transaction.detail.customer}
                  onSelect={updateCustomer}
                  onCreateCustomer={updateCustomer}
                  canEdit={canEdit}
                />,
              ],
              [
                "Product",
                <ProductSelect
                  key="product-select"
                  value={transaction.detail.product}
                  onSelect={updateProduct}
                  onCreateProduct={updateProduct}
                  canEdit={canEdit}
                />,
              ],
            ]}
          />
        ) : (
          <DescriptionList
            items={[
              [
                "Vendor",
                <VendorSelect
                  key="vendor-select"
                  value={transaction.detail.vendor}
                  onSelect={updateVendor}
                  onCreate={updateVendor}
                  canEdit={canEditSplits}
                  suggestAutoGenerateRuleContent={
                    !isRevenueTransaction && transaction.splits.length === 0 && canEdit ? (
                      <Button
                        variant="secondary"
                        size="small"
                        onClick={() => handleModalTriggerClick(categorizedByRule?.id, true)}
                        prefix={<Sparkle />}
                      >
                        {hasEditableRule ? "Edit rule" : "Create rule"}
                      </Button>
                    ) : null
                  }
                />,
              ],
              [
                "Recurrence",
                <RecurrenceSelect
                  key="recurrence-select"
                  value={transaction.detail.recurrence}
                  onSelect={updateRecurrence}
                  canEdit={canEdit}
                />,
              ],
            ]}
          />
        )}

        <DocumentationSection
          label="Documentation"
          setPreviewFile={file => setPreviewFile(file as FileFragment)}
          associatedEntity={AssociatedEntity.Transaction}
          documents={transaction.documentation}
          uploadedBy={document => <UploadedBy document={document as TransactionDocumentFragment} transaction={transaction}/>}
          refetch={(cache, fileId) => {
            updateTransactionDocumentCache(cache as ApolloCache<FullTransactionFragment>, fileId, transaction);
            // Refetching to update the activity...
            // TODO Maybe we can predict the new activity item?
            refetch();
          }}
        />

        <AssociatedSection transaction={transaction} />

        <AssignmentsSection transaction={transaction} />

        {showAIAutoAssign && (
          <Alert onClose={dismissAIAutoAssign}>
            When you’re assigned a category review, you can write any context in the comment box and
            Puzzle AI will assign the category based on that comment.
          </Alert>
        )}

        <ActivitySection transaction={transaction} />
      </Sections>
    </>
  );
};

export default DetailBody;
