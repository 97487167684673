// Adapted from dayzed: https://github.com/deseretdigital/dayzed
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { UseCalendarOptions, DateCell, ComputedModifiers } from "./types";
import {
  computeModifiers,
  isBackDisabled,
  isForwardDisabled,
  isSelectable,
  mergeModifiers,
  weekdayNamesShort,
} from "./utils";
import { composeEventHandlers } from "@radix-ui/primitive";
import getCalendars from "./getCalendars";
import {
  CalendarDate,
  getLocalTimeZone,
  isSameMonth,
  isToday,
  today,
} from "@internationalized/date";

// TODO TimeZone-awareness only matters for knowing what "today" is
// This can be passed in or inferred, but consumers don't do this yet.
export function useCalendar({
  initialDate,
  maxDate,
  minDate,
  count = 1,
  firstDayOfWeek = 0,
  view = "day",
  modifiers,
  timeZone = getLocalTimeZone(),
}: UseCalendarOptions) {
  const [offset, setOffset] = useState(0);

  const date = useMemo(() => initialDate ?? today(timeZone), [initialDate, timeZone]);

  useEffect(() => {
    setOffset(0);
  }, [date]);

  const calendars = useMemo(
    () =>
      getCalendars({
        date,
        count,
        minDate,
        maxDate,
        offset,
        firstDayOfWeek,
        view,
      }),
    [count, date, firstDayOfWeek, maxDate, minDate, offset, view]
  );

  const getDateModifiers = useCallback(
    (date: CalendarDate): ComputedModifiers =>
      computeModifiers(
        mergeModifiers(
          {
            today: (date) => isToday(date, timeZone),
            outside: (date) => {
              if (view !== "day") {
                return false;
              }

              return !isSameMonth(date, calendars[0].firstDay);
            },
            disabled: (date) => !isSelectable(minDate, maxDate, date),
          },
          modifiers
        ),
        date
      ),
    [calendars, maxDate, minDate, modifiers, timeZone, view]
  );

  const getDateProps = useCallback(
    ({
      onClick,
      onMouseEnter,
      onMouseLeave,
      dateCell,
      modifiers,
    }: {
      onClick?: React.MouseEventHandler;
      onMouseEnter?: React.MouseEventHandler;
      onMouseLeave?: React.MouseEventHandler;
      dateCell: DateCell;
      modifiers: ComputedModifiers;
    }) => {
      return {
        onClick,
        onMouseEnter,
        onMouseLeave,
        // onTouchEnd: onClick as React.TouchEventHandler,
        disabled: modifiers.disabled,
        "aria-label": dateCell.date.toString(),
        "aria-pressed": modifiers.selected,
        "aria-selected": modifiers.selected,
        role: "gridcell",
      };
    },
    []
  );

  const getBackProps = useCallback(
    ({ onClick }: { onClick?: React.MouseEventHandler } = {}) => {
      return {
        onClick: composeEventHandlers(onClick, () => {
          setOffset((x) => x - 1);
        }),
        disabled: isBackDisabled({ calendars, minDate }),
        "aria-label": `Go back ${count} month${count === 1 ? "" : "s"}`,
      };
    },
    [calendars, count, minDate]
  );

  const getForwardProps = useCallback(
    ({ onClick }: { onClick?: React.MouseEventHandler } = {}) => {
      return {
        onClick: composeEventHandlers(onClick, () => {
          setOffset((x) => x + 1);
        }),
        disabled: isForwardDisabled({ calendars, maxDate }),
        "aria-label": `Go forward ${count} month${count === 1 ? "" : "s"}`,
      };
    },
    [calendars, count, maxDate]
  );

  const headerCells = useMemo(() => {
    if (view !== "day") {
      return [];
    }

    let cells = [...weekdayNamesShort];
    if (firstDayOfWeek > 0) {
      const weekdaysFromFront = cells.splice(0, firstDayOfWeek);
      cells = [...cells, ...weekdaysFromFront];
    }

    return cells;
  }, [firstDayOfWeek, view]);

  return {
    calendars,
    getDateModifiers,
    getDateProps,
    getBackProps,
    getForwardProps,
    headerCells,
  };
}
