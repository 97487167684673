import React from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { InDialogProvider } from "./InDialogContext";
import { Dialog } from "./Dialog";

import { styled, CSS, keyframes } from "@puzzle/theme";
import { VariantProps } from "@stitches/react";

const slideInRight = keyframes({
  from: { transform: "translateX(100%)" },
  to: { transform: "translateX(0)" },
});

const slideOutRight = keyframes({
  from: { transform: "translateX(0)" },
  to: { transform: "translateX(100%)" },
});

const slideInLeft = keyframes({
  from: { transform: "translateX(-100%)" },
  to: { transform: "translateX(0)" },
});

const slideOutLeft = keyframes({
  from: { transform: "translateX(0)" },
  to: { transform: "translateX(-100%)" },
});

const Overlay = styled(DialogPrimitive.Overlay, Dialog.styles.overlay);
const Content = styled(DialogPrimitive.Content, {
  outline: "none",
  position: "fixed",
  top: 0,
  bottom: 0,
  width: "600px",
  backgroundColor: "#29293b",
  // TODO half-pixel not always visible on safari
  boxShadow: `0.5px 0px 0px $colors$mauve400`,
  color: "$white",
  padding: "$3",

  defaultVariants: {
    side: "right",
  },

  variants: {
    side: {
      left: {
        left: 0,
        borderRight: "1px solid $rhino600",
        '&[data-state="open"]': { animationName: `${slideInLeft}` },
        '&[data-state="closed"]': { animationName: `${slideOutLeft}` },
      },

      right: {
        right: 0,
        borderLeft: "1px solid $rhino600",
        '&[data-state="open"]': { animationName: `${slideInRight}` },
        '&[data-state="closed"]': { animationName: `${slideOutRight}` },
      },
    },
  },

  '&[data-state="open"]': {
    animation: `300ms cubic-bezier(0.4, 0, 0.2, 1)`,
  },

  '&[data-state="closed"]': {
    animation: `300ms cubic-bezier(0.4, 0, 0.2, 1)`,
  },
});

type DrawerProps = React.ComponentProps<typeof DialogPrimitive.Root> &
  React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof Content> & {
    css?: CSS;
    dark?: boolean;
  };

export const Drawer = Object.assign(
  function Drawer({ children, css, side, className, dark = true, ...props }: DrawerProps) {
    return (
      <InDialogProvider value={dark}>
        <DialogPrimitive.Root modal {...props}>
          <DialogPrimitive.Portal>
            <Overlay />
            <Content side={side} css={css} className={className}>
              {children}
            </Content>
          </DialogPrimitive.Portal>
        </DialogPrimitive.Root>
      </InDialogProvider>
    );
  },
  {
    Content,
  }
);

Drawer.toString = Content.toString;
