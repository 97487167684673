import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Delete({
  viewBox = "0 0 16 16",
  color = "currentColor",
  ...iconProps
}: IconProps) {
  return (
    <Icon {...iconProps} viewBox={viewBox}>
      <path
        fill={color}
        d="M6.5 6a.5.5 0 01.5.5v5a.5.5 0 01-1 0v-5a.5.5 0 01.5-.5zM10 6.5a.5.5 0 00-1 0v5a.5.5 0 001 0v-5z"
      ></path>
      <path
        fill={color}
        fillRule="evenodd"
        d="M10.91 3.5l-.259-1.294A1.5 1.5 0 009.181 1H6.82a1.5 1.5 0 00-1.471 1.206L5.09 3.5H2a.5.5 0 000 1h1.023l.432 9.071A1.5 1.5 0 004.954 15h6.093a1.5 1.5 0 001.498-1.429l.432-9.071H14a.5.5 0 000-1h-3.09zM6.82 2a.5.5 0 00-.49.402L6.11 3.5h3.78l-.22-1.098A.5.5 0 009.18 2H6.82zm3.67 2.5h1.486l-.43 9.024a.5.5 0 01-.5.476H4.955a.5.5 0 01-.5-.476L4.024 4.5h6.466z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
}
