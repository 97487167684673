import React from "react";
import { IntegrationLogo } from "../shared";
import { Plaid } from "@puzzle/icons";

const PlaidLogo = () => {
  return (
    <IntegrationLogo color="#111111">
      <Plaid />
    </IntegrationLogo>
  );
};

export default PlaidLogo;
