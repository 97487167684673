import { css, styled } from "../stitches";
import { CalendarView } from "./types";

const cellStyles = css({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: "$bold",
  fontSize: "13px",
  lineHeight: "16px",
  height: 36,
  minWidth: 36,
});

export const Cell = styled("div", cellStyles, {
  $$borderRadius: "$radii$2",
  userSelect: "none",

  defaultVariants: {
    dark: false,
    disabled: false,
    outside: false,
    today: false,

    selected: false,
    selectedStart: false,
    selectedMiddle: false,
    selectedEnd: false,
  },

  variants: {
    unit: {
      [CalendarView.Day]: {},
      [CalendarView.Quarter]: {},
      [CalendarView.Month]: {
        width: "auto",
      },
      [CalendarView.Year]: {},
    },

    outside: {
      false: {},
      true: {
        color: "$gray600",
      },
    },

    disabled: {
      false: {
        cursor: "pointer",
        color: "$gray200",
      },
      true: {
        color: "$gray600",
        textDecoration: "line-through",

        "&:not(:empty)": {
          cursor: "not-allowed",
        },
      },
    },

    dark: {
      true: {},
      false: {},
    },

    selected: {
      true: {},
      false: {},
    },

    selectedStart: {
      true: {},
      false: {},
    },

    selectedMiddle: {
      true: {},
      false: {},
    },

    selectedEnd: {
      true: {},
      false: {},
    },

    today: {
      true: {
        color: "$purple500",
      },
      false: {},
    },
  },

  compoundVariants: [
    {
      disabled: false,
      selected: false,
      css: {
        "&:hover": {
          backgroundColor: "#404057",
        },
      },
    },
    {
      selectedStart: false,
      selectedMiddle: false,
      selectedEnd: false,
      selected: true,
      css: {
        borderRadius: "$$borderRadius",
        backgroundColor: "$purple400",
        color: "$purple900",
      },
    },
    {
      selectedMiddle: true,
      selectedStart: false,
      selectedEnd: false,
      selected: true,
      css: {
        backgroundColor: "$rhino200",

        "&:hover": {
          backgroundColor: "#5A5A7A",
        },
      },
    },
    {
      selectedMiddle: true,
      selectedStart: false,
      selectedEnd: false,
      selected: true,
      dark: true,
      css: {
        backgroundColor: "#15151F",

        "&:hover": {
          backgroundColor: "#5A5A7A",
        },
      },
    },
    {
      selected: true,
      selectedStart: true,
      css: {
        backgroundColor: "$purple400",
        color: "$purple900",
        borderTopLeftRadius: "$$borderRadius",
        borderBottomLeftRadius: "$$borderRadius",

        "&:hover": {
          backgroundColor: "$purple300",
        },
      },
    },
    {
      selected: true,
      selectedEnd: true,
      css: {
        backgroundColor: "$purple400",
        color: "$purple900",
        borderTopRightRadius: "$$borderRadius",
        borderBottomRightRadius: "$$borderRadius",

        "&:hover": {
          backgroundColor: "$purple300",
        },
      },
    },
  ],
});

export const HeaderCell = styled("div", cellStyles, {
  color: "#636A77",
});
