import React, { useMemo, useState } from "react";
import { compact, startCase, uniqBy } from "lodash";
import { usePathname } from "next/navigation";

import { IconButton, Stack, Tag, colors, Button } from "@puzzle/ui";
import { Add, External } from "@puzzle/icons";

import { Route } from "lib/routes";
import Link from "components/common/Link";
import DescriptionList from "components/common/DescriptionList";
import { RevenueRecognitionSchedule } from "components/dashboard/Invoices/ScheduleDrawer/RevenueRecognitionSchedule";
import { FixedAssetStatus, LedgerEventStatus, LedgerView, PrepaidStatus } from "graphql/types";

import { PostingStatus } from "../TransactionStatus";
import { FullTransactionFragment, TransactionLedgerEntryFragment } from "../graphql.generated";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { NewAssetFromTransactionDrawer } from "components/dashboard/Accounting/FixedAssetsV2/NewAssetFromTransactionDrawer";
import { useActiveCompany } from "components/companies";
import { isEditorRole } from "lib/roles";
import useSingleTransaction from "../hooks/useSingleTransaction";
import Big from "big.js";
import { Box } from "ve";

const JournalWithStatus = ({
  view,
  status,
  journals,
}: {
  view: LedgerView;
  status: LedgerEventStatus;
  journals?: TransactionLedgerEntryFragment[];
}) => {
  return (
    <>
      {journals && journals.length > 0 ? (
        <div key="journals">
          {journals.map((entry, i) => {
            if (entry.journal.view === view) {
              const href = `${Route.generalLedger}?accountId=${entry.accountId}&journalId=${entry.journal.id}`;
              return (
                <React.Fragment key={href}>
                  <Link href={href} underline>
                    {entry.journal.shortId}
                  </Link>
                  {i < journals.length - 1 && " "}
                </React.Fragment>
              );
            }
          })}
        </div>
      ) : (
        <PostingStatus status={status} includeText={true} />
      )}
    </>
  );
};

export const AssociatedSection = React.memo(function AssociatedSection({
  transaction,
}: {
  transaction: FullTransactionFragment;
}) {
  const { refetch } = useSingleTransaction(transaction);
  const { linkedTransaction, schedule, fixedAsset, prepaid } = transaction.detail;
  const [scheduleDrawerOpen, setScheduleDrawerOpen] = useState(false);
  const [newAssetDrawerOpen, setNewAssetDrawerOpen] = useState(false);
  const pathname = usePathname();
  const { membershipRole } = useActiveCompany<true>();
  const isEditor = isEditorRole(membershipRole);

  // Do we need to show System or Admin rules? They don't have names.
  const rule =
    transaction.detail.rule?.__typename === "UserCategorizationRule"
      ? transaction.detail.rule
      : undefined;

  const journals = useMemo(() => {
    let eventAmount: string | null = null;
    return compact(
      uniqBy(
        transaction?.events
          .filter((event) => {
            eventAmount = event.amount;
            return event.entries && event.entries.length > 0;
          })
          .flatMap(({ entries }) => entries)
          .filter((entry) => entry?.amount === eventAmount),
        "accountId"
      )
    );
  }, [transaction?.events]);

  const cashStatus = transaction.eventViews.cash.status;
  const accrualStatus = transaction.eventViews.accrual.status;

  return (
    <>
      {cashStatus !== LedgerEventStatus.NotApplicable && (
        <DescriptionList
          css={{
            dt: { alignItems: "start" },
          }}
          items={[
            [
              "Cash journal entry",
              <JournalWithStatus
                view={LedgerView.Cash}
                status={cashStatus}
                journals={journals}
                key="journals_cash"
              />,
            ],
          ]}
        />
      )}

      {accrualStatus !== LedgerEventStatus.NotApplicable && (
        <DescriptionList
          css={{
            dt: { alignItems: "start" },
          }}
          items={[
            [
              "Accrual journal entry",
              <JournalWithStatus
                view={LedgerView.Accrual}
                status={accrualStatus}
                journals={journals}
                key="journals_cash"
              />,
            ],
          ]}
        />
      )}
      {Big(transaction.amount).lt(0) &&
        !fixedAsset?.id &&
        pathname !== Route.fixedAssets &&
        isPosthogFeatureFlagEnabled(FeatureFlag.FixedAssetsAutoDepreciation) &&
        isEditor && (
          <DescriptionList
            css={{
              dt: { alignItems: "start" },
              dd: { width: "fit-content" },
            }}
            items={[
              [
                "Fixed asset",
                <Box key="fixedAsset">
                  <NewAssetFromTransactionDrawer
                    open={newAssetDrawerOpen}
                    onOpenChange={(open) => {
                      if (!open) setNewAssetDrawerOpen(false);
                    }}
                    transaction={transaction}
                    onComplete={refetch}
                  />
                  <Stack
                    direction="horizontal"
                    css={{ alignItems: "center", justifyContent: "space-between" }}
                  >
                    <Button
                      size="small"
                      variant="secondary"
                      onClick={() => setNewAssetDrawerOpen(true)}
                      prefix={<Add />}
                      css={{ position: "static" }}
                    >
                      New asset
                    </Button>
                  </Stack>
                </Box>,
              ],
            ]}
          />
        )}
      {fixedAsset?.id &&
        pathname !== Route.fixedAssets &&
        isPosthogFeatureFlagEnabled(FeatureFlag.FixedAssetsAutoDepreciation) && (
          <DescriptionList
            css={{
              dt: { alignItems: "start" },
              dd: { width: "fit-content" },
            }}
            items={[
              [
                "Fixed asset",
                <Box key="fixedAsset">
                  <Link
                    href={`${Route.fixedAssets}${
                      fixedAsset.status === FixedAssetStatus.Draft
                        ? ""
                        : `?view=summary&status=${fixedAsset.status}`
                    }`}
                  >
                    <Stack gap="0h" direction="horizontal">
                      <Tag
                        variant={
                          fixedAsset.status === FixedAssetStatus.Draft
                            ? "warningPill"
                            : "filledPill"
                        }
                      >
                        {fixedAsset.status === FixedAssetStatus.Draft
                          ? "Pending"
                          : startCase(fixedAsset.status)}
                      </Tag>
                      <IconButton>
                        <External color={colors.gray400} />
                      </IconButton>
                    </Stack>
                  </Link>
                </Box>,
              ],
            ]}
          />
        )}

      {prepaid?.id && (
        <DescriptionList
          css={{
            dt: { alignItems: "start" },
            dd: { width: "fit-content" },
          }}
          items={[
            [
              "Prepaid expense",
              <Box key="prepaid">
                <Link
                  href={`${Route.prepaidExpenses}${
                    prepaid.status === PrepaidStatus.Draft ? "" : `?status=${prepaid.status}`
                  }`}
                >
                  <Stack gap="0h" direction="horizontal">
                    <Tag
                      variant={
                        prepaid.status === PrepaidStatus.Draft ? "warningPill" : "filledPill"
                      }
                    >
                      {prepaid.status === PrepaidStatus.Draft
                        ? "Pending"
                        : startCase(prepaid.status)}
                    </Tag>
                    <IconButton>
                      <External color={colors.gray400} />
                    </IconButton>
                  </Stack>
                </Link>
              </Box>,
            ],
          ]}
        />
      )}

      <DescriptionList
        css={{
          dt: { alignItems: "start" },
        }}
        items={[
          [
            "Associated with",
            <Stack gap="1" direction="vertical" key="associatedWith">
              {compact([
                // TODO this should show up in activity. Needs Gateway support.
                rule && (
                  <div key="rule">
                    Rule:{" "}
                    <Link href={`${Route.rules}/${rule.id}`} underline>
                      {rule.rule}
                      {" -> "}
                      {rule.category.name}
                    </Link>
                  </div>
                ),

                linkedTransaction && (
                  <div key="linkedTransaction">
                    <Link href={`${Route.transactions}/${linkedTransaction.id}`} underline>
                      {linkedTransaction.descriptor}
                    </Link>
                  </div>
                ),

                schedule && (
                  <div key="schedule">
                    <Link underline onClick={() => setScheduleDrawerOpen(true)}>
                      Revenue schedule
                    </Link>
                    <RevenueRecognitionSchedule
                      scheduleId={schedule?.id}
                      open={scheduleDrawerOpen}
                      onOpenChange={setScheduleDrawerOpen}
                    />
                  </div>
                ),
              ])}
            </Stack>,
          ],
        ]}
      />
    </>
  );
});
