import { formatNumber } from "../number/formatNumber";

export const pluralize = (value: number, word: string, plural = `${word}s`, showNumber = true) => {
  const finalWord = value === 1 ? word : plural;
  if (!showNumber) return finalWord;
  return `${formatNumber(value, { decimals: 0 })} ${finalWord}`;
};

export const capitalize = (word: string) => {
  const result = word.toLowerCase();
  return result.charAt(0).toUpperCase() + result.substring(1);
};

export const capitalizeName = (word: string) => {
  return word && word.split(" ").map(capitalize).join(" ");
};

export const snakeCaseToSentenceCase = (word: string) => {
  return word
    .replace(/(^\w)/g, (g) => g[0].toUpperCase())
    .replace(/([-_]\w)/g, (g) => " " + g[1].toUpperCase())
    .trim();
};

export const asBrandName = (word: string) => {
  switch (word) {
    case "bill":
      return "BILL";

    default:
      return capitalizeName(word);
  }
};
