import React from "react";
import { Button, Dialog } from "@puzzle/ui";

import { useDeleteBillLink } from "../useBillActions";

export const DetachTransactionModal = ({
  billId,
  bundleId,
  refetch,
  open,
  onOpenChange,
  ...props
}: React.ComponentProps<typeof Dialog> & {
  billId?: string;
  bundleId?: string;
  refetch?: () => void;
}) => {
  const deleteBillLink = useDeleteBillLink();

  return (
    <Dialog
      {...props}
      size="xsmall"
      open={open}
      onOpenChange={(open) => {
        onOpenChange?.(open);
      }}
    >
      <Dialog.Title>Detach cash transaction from bill</Dialog.Title>
      <Dialog.Body>
        Detaching the bill(s) might change their status and reset the transaction to its original
        state.
      </Dialog.Body>
      <Dialog.Footer align="right">
        <Dialog.Actions>
          <Dialog.Close asChild>
            <Button variant="minimal">Cancel</Button>
          </Dialog.Close>
          <Button
            variant="negative"
            loading={deleteBillLink.loading}
            onClick={async () => {
              await deleteBillLink.mutation({
                billId,
                bundleId,
              });
              refetch?.();
              onOpenChange?.(false);
            }}
          >
            Detach
          </Button>
        </Dialog.Actions>
      </Dialog.Footer>
    </Dialog>
  );
};
