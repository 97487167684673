import { useCallback, useMemo } from "react";
import { keyBy, reduce, uniq } from "lodash";
import Big from "big.js";
import { eachMonthOfInterval } from "date-fns";

import {
  dateToCalendarDate,
  toCalendarMonthString,
  CalendarDate,
  getLocalTimeZone,
} from "@puzzle/utils";
import { GroupBy, useToasts } from "@puzzle/ui";
import {
  AvailableClassesResult,
  DynamicReportType,
  LedgerAccountInfoFragment,
  LedgerReportColumnBy,
  LedgerReportFilterInput,
  LedgerReportLineFragment,
  LedgerView,
  ReportLineTag,
  ReportTimePeriod,
  useLedgerReportQuery,
} from "graphql/types";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import {
  DeltaColumns,
  LedgerReportLineMap,
  EnhancedLedgerReportLine,
  EnhancedBalanceByReportColumn,
} from "./types";
import { balanceForColumn } from "./util";
import { useDelta } from "./Filters/DeltaProvider";
import useCategories from "components/common/hooks/useCategories";
import config from "lib/config";
import { useIsInProgress } from "components/reports/useReportTimePeriods";

import {
  getClassificationColumns,
  getColumnBy,
  getReportIntervals,
} from "./reportClassificationUtils";

// TODO Extract.. shouldn't useReportTimePeriods switch to periods?
export const getMonths = (start: CalendarDate, end: CalendarDate): string[] => {
  // TZ doesn't matter since we're using CalendarDate
  const tz = getLocalTimeZone();
  return eachMonthOfInterval({
    start: start.toDate(tz),
    end: end.toDate(tz),
  }).map((date) => toCalendarMonthString(dateToCalendarDate(date, tz)));
};

export default function useLedgerReport({
  timePeriods,
  type,
  view,
  groupBy = GroupBy.Month,
  classifications,
  filter = {},
}: {
  /** @deprecated */
  companyId?: string;
  /**
   * Time periods represent arbitrary chunks of time to fetch for the report.
   * You can supply a friendly name and make columns of months, quarters, etc.
   */
  timePeriods: ReportTimePeriod[];
  type: DynamicReportType;
  view?: LedgerView;
  groupBy?: string;
  classifications?: AvailableClassesResult;
  filter?: LedgerReportFilterInput;
}) {
  const { commonCategories } = useCategories();
  const { company, initialIngestCompleted } = useActiveCompany<true>();
  const companyId = company.id;

  const isInProgress = useIsInProgress(timePeriods);
  const { deltaOptions, firstTimePeriod, lastTimePeriod } = useDelta();

  const { toast } = useToasts();

  const columnBy = getColumnBy(groupBy);

  const waitForClassification = columnBy === LedgerReportColumnBy.Segment && !classifications;
  const skip = !initialIngestCompleted || waitForClassification;
  const reportResponse = useLedgerReportQuery({
    fetchPolicy: "cache-and-network",
    skip,
    context: { batch: false },
    variables: {
      input: {
        companyId,
        config: {
          intervals: { byPeriod: getReportIntervals(timePeriods, columnBy, type), byDate: [] },
          columns: {
            segments: getClassificationColumns(filter, columnBy, groupBy, classifications),
          },
          filter: columnBy === LedgerReportColumnBy.Interval ? filter : {},
          columnBy: columnBy,
        },
        type,
        view: view || LedgerView.Cash,
      },
    },
  });

  const report = useMemo(
    () => (reportResponse.data ? reportResponse.data.ledgerReport : undefined),
    [reportResponse]
  );

  const accounts = useMemo<Partial<Record<string, LedgerAccountInfoFragment>>>(
    () => keyBy(report?.accounts, "accountId"),
    [report]
  );

  const { parsedNodes, rootNodes } = useMemo(() => {
    const parsedResult: LedgerReportLineMap = keyBy(report?.lines, "id");

    const nodes: LedgerReportLineMap = {};

    const rootIds: string[] = [];
    const roots = Object.values(parsedResult).filter((x) => !x.parentId);

    const hasActivity = (node: LedgerReportLineFragment) => {
      if (!node) {
        return false;
      }

      // returns true if a node has balances of non zero anywhere in the
      // tree including on the root
      const isZero = node.balanceByColumn
        .reduce((total, balance) => {
          return total.add(Big(balance.balance.amount).abs());
        }, Big(0))
        .eq(0);

      if (!isZero) {
        return true;
      }

      for (const n of node.childIds) {
        const hasChildActivity = hasActivity(parsedResult[n]);
        if (hasChildActivity) {
          return true;
        }
      }

      return false;
    };

    const visitNode = (node: LedgerReportLineFragment, depth = 0) => {
      if (depth > 0 && !hasActivity(node)) {
        return;
      }
      const { formatting } = node;
      nodes[node.id] = {
        ...node,
        parentId: depth === 0 ? null : node.parentId,
        childIds: formatting.indentChildren ? node.childIds : [],
      };

      node.childIds.forEach((n) =>
        visitNode(parsedResult[n], formatting.indentChildren ? depth + 1 : depth)
      );

      if (depth === 0) {
        rootIds.push(node.id);
      }
    };

    roots.forEach((root) => visitNode(root));

    const rootNodes = rootIds.map((id) => nodes[id]);

    return {
      parsedNodes: nodes,
      rootNodes,
    };
  }, [report?.lines]);

  // map from type of root node to the root node
  const parsedRootNodesByType = useMemo(() => {
    return rootNodes.reduce((result, node) => {
      const type = node.nodeTags[0];

      if (!type) {
        return result;
      }

      if (Object.values(type).includes(type) && config.IS_LOCAL_DEVELOPMENT) {
        toast({
          title: `Recieved node of type ${type} which is not a ReportLineTag that is currently handled`,
          status: "error",
        });
      }

      return { [type]: node, ...result };
    }, {}) as Record<ReportLineTag, LedgerReportLineFragment>;
  }, [rootNodes, toast]);

  const totalExpensesNode = parsedRootNodesByType[ReportLineTag.TotalExpenses];

  const applyComparisons = useCallback(
    (node: LedgerReportLineFragment) => {
      const { value: mostRecentValue } = balanceForColumn(
        lastTimePeriod.timePeriodKey,
        node.balanceByColumn
      );
      const { value: previousValue } = balanceForColumn(
        firstTimePeriod.timePeriodKey,
        node.balanceByColumn
      );

      let percentDiff = 0;
      if (!Big(previousValue).eq(0)) {
        percentDiff = Big(mostRecentValue)
          .sub(previousValue)
          .div(Big(previousValue).abs())
          .toNumber();
      }

      const dollarDiff = Big(mostRecentValue).sub(previousValue).toNumber();

      // TODO Make a different type or property for delta columns
      const additionalBalancesByTimePeriod: EnhancedBalanceByReportColumn[] = [
        {
          balance: { amount: Big(dollarDiff).toString(), currency: "USD" },
          columnKey: DeltaColumns.DollarDiff,
          isPercent: false,
          dateRange: {},
        },
        {
          balance: { amount: Big(percentDiff).toString(), currency: "USD" },
          columnKey: DeltaColumns.PercentDiff,
          isPercent: true,
          dateRange: {},
        },
      ];

      if (totalExpensesNode) {
        const { value: totalExpenses } = balanceForColumn(
          lastTimePeriod.timePeriodKey,
          totalExpensesNode.balanceByColumn
        );
        const percentOfExpenses = Big(totalExpenses).eq(0)
          ? 0
          : Big(mostRecentValue).div(totalExpenses).toNumber();
        additionalBalancesByTimePeriod.push({
          balance: { amount: Big(percentOfExpenses).toString(), currency: "USD" },
          columnKey: DeltaColumns.PercentOfExpenses,
          isPercent: true,
          dateRange: {},
        });
      }

      const nodeWithColumns = {
        ...node,
        balanceByColumn: [...node.balanceByColumn, ...additionalBalancesByTimePeriod],
      };

      return nodeWithColumns;
    },
    [lastTimePeriod.timePeriodKey, firstTimePeriod.timePeriodKey, totalExpensesNode]
  );

  // Data decorated with comparisons
  const data = useMemo(() => {
    return reduce(
      parsedNodes,
      (result: LedgerReportLineMap, node: LedgerReportLineFragment, nodeId: string) => {
        const nodeWithColumns = applyComparisons(node);

        return {
          [nodeId]: nodeWithColumns,
          ...result,
        };
      },
      {}
    );
  }, [parsedNodes, applyComparisons]);

  const parsedRootNodes = useMemo(() => {
    return rootNodes.map((node) => data[node.id]);
  }, [data, rootNodes]);

  const enhancedRootNodes = useMemo(() => {
    const mapNodeToTree = (
      node: LedgerReportLineFragment,
      parent?: LedgerReportLineFragment
    ): EnhancedLedgerReportLine => {
      const children =
        node.childIds
          .map((childId) => data[childId])
          .filter(Boolean)
          .map((child) => mapNodeToTree(child, node)) || [];
      const accountInfo = node.relatedObjectId ? accounts[node.relatedObjectId] : undefined;
      const parentAccountInfo =
        parent && parent.relatedObjectId && accounts[parent.relatedObjectId];
      const parentCategoryPermaKeys = parentAccountInfo
        ? parentAccountInfo?.categories.map((x) => x.permaKey)
        : [];
      const vendorIds =
        node.metadata?.counterpartyType === "vendor" && node.relatedObjectId
          ? [node.relatedObjectId]
          : [];
      const accountIds = accountInfo?.externalAccountId ? [accountInfo?.externalAccountId] : [];

      // Sometimes a transaction with a vendor is actually uncategorized.
      // We add "No Category" just in case.
      // If this leads to weird results, maybe only filter by the vendor...
      if (
        parentCategoryPermaKeys.length > 0 &&
        vendorIds.length > 0 &&
        commonCategories?.noCategory
      ) {
        parentCategoryPermaKeys.push(commonCategories.noCategory.permaKey);
      }

      const childCategoryPermaKeys = children.flatMap((node) => node.ledgerCoaKeys);
      const aggregateLineTypes = uniq([
        node.lineType,
        ...children.flatMap((node) => node.aggregateLineTypes),
      ]);
      const ledgerCoaKeys = uniq([
        ...childCategoryPermaKeys,
        ...(accountInfo?.categories.map((c) => c.permaKey) || []),
        ...parentCategoryPermaKeys,
      ]);

      return {
        ...node,
        ledgerCoaKeys,
        vendorIds,
        accountIds,
        children,
        aggregateLineTypes,
      };
    };

    return parsedRootNodes.map((node) => mapNodeToTree(node));
  }, [accounts, commonCategories, data, parsedRootNodes]);

  const columns = useMemo(() => {
    if (columnBy === LedgerReportColumnBy.Interval) {
      if (!deltaOptions.enabled) {
        return timePeriods.map((t) => t.timePeriodKey);
      }

      return [
        firstTimePeriod.timePeriodKey,
        lastTimePeriod.timePeriodKey,
        DeltaColumns.DollarDiff,
        DeltaColumns.PercentDiff,
        totalExpensesNode && DeltaColumns.PercentOfExpenses,
      ].filter(Boolean);
    }
    if (!reportResponse.data?.ledgerReport?.lines[0]?.balanceByColumn) return [];

    const segmentCols = reportResponse.data?.ledgerReport.lines[0].balanceByColumn.map(
      (col) => col.columnKey
    ) as string[];

    return segmentCols;
  }, [
    deltaOptions.enabled,
    firstTimePeriod.timePeriodKey,
    lastTimePeriod.timePeriodKey,
    timePeriods,
    totalExpensesNode,
    columnBy,
    reportResponse.data?.ledgerReport.lines,
  ]);

  const hiddenColumns = useMemo(() => {
    if (deltaOptions.enabled) {
      return [];
    }

    return [DeltaColumns.DollarDiff, DeltaColumns.PercentDiff, DeltaColumns.PercentOfExpenses];
  }, [deltaOptions.enabled]);

  return {
    columns,
    hiddenColumns,
    columnBy,
    type,
    data,
    rootNodes: parsedRootNodes,
    loading:
      // no report for given filters and waiting on needed inputs
      (skip && !reportResponse.data?.ledgerReport) ||
      // no report for given filters and request is in flight
      (!reportResponse.data?.ledgerReport && reportResponse.loading),
    highWatermarkToken: report?.highWatermarkToken,
    timePeriods:
      reportResponse.data?.ledgerReport &&
      reportResponse.data.ledgerReport.__typename === "LedgerReportByInterval"
        ? reportResponse.data?.ledgerReport?.intervalMetadata
        : undefined,
    enhancedRootNodes,
    isInProgress,
    firstTimePeriod,
    lastTimePeriod,
  };
}

export type UseDynamicReportResult = ReturnType<typeof useLedgerReport>;
