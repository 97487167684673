import React, { ReactNode, useMemo } from "react";
import DescriptionList from "components/common/DescriptionList";
import TagEntity, {
  ClassSegment,
  TaggableEntity,
} from "components/common/Classifications/TagEntity";
import { Text } from "@puzzle/ui";
import { compact } from "lodash";

const ClassificationsSection = ({
  entity,
  readOnly = false,
  hideAddButton = readOnly,
  onSegmentChange,
  classSegments,
}: {
  entity?: TaggableEntity;
  readOnly?: boolean;
  hideAddButton?: boolean;
  onSegmentChange?: (newValue: ClassSegment[]) => void;
  classSegments?: ClassSegment[];
}) => {
  const displaySegments = useMemo(() => {
    if (classSegments && classSegments.length > 0) return classSegments;
    const segments = entity && entity.classSegments.length > 0 ? [...entity.classSegments] : [];
    segments.sort((a, b) => (a.reportingClass.name < b.reportingClass.name ? -1 : 1));
    return segments;
  }, [entity, classSegments]);

  return (
    <DescriptionList
      data-testid="classification_section"
      items={compact([
        !hideAddButton && [
          "Classification(s)",
          <TagEntity
            key="tagger"
            entity={entity}
            hideClassIds={displaySegments.map((reportingClassSegment) => {
              return reportingClassSegment.reportingClass.id;
            })}
            onSegmentChange={onSegmentChange}
            classSegments={classSegments}
          />,
        ],
        ...displaySegments.map((segment) => {
          return [
            <Text key={segment.reportingClass.id} css={hideAddButton ? {} : { paddingLeft: "$2h" }}>
              {segment.reportingClass.name}
            </Text>,
            <TagEntity
              data-testid={`classification_tag_${segment.name}`}
              key="tagger"
              entity={entity}
              activeSegment={segment}
              readOnly={readOnly}
              onSegmentChange={onSegmentChange}
              classSegments={classSegments}
            />,
          ] as [ReactNode, ReactNode];
        }),
      ])}
    />
  );
};

export default ClassificationsSection;
