import React, { useCallback } from "react";
import { Drawer } from "@puzzle/ui";

import { BasicTransactionFragment } from "components/dashboard/Transactions/graphql.generated";
import useAppRouter from "lib/useAppRouter";
import { DetailPane } from "./DetailPane";
import { usePrevious } from "react-use";

export const DetailDrawer = ({
  id: _id,
  transactions,
  onClose: _onClose,
}: {
  id?: string;
  /**
   * List of all transactions visible in the table. Used for next/previous navigation,
   * and prepopulates a few fields.
   */
  transactions?: BasicTransactionFragment[];
  onClose?: () => void;
}) => {
  const open = Boolean(_id);
  const previousId = usePrevious(_id);
  const id = _id ?? previousId;
  const { goToTransactions } = useAppRouter();

  const onClose = useCallback(() => {
    if (_onClose) {
      _onClose();
    } else {
      goToTransactions(true);
    }
  }, [_onClose, goToTransactions]);

  return (
    <Drawer
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
      css={{ padding: 0 }}
    >
      {id && <DetailPane id={id} onClose={onClose} transactions={transactions} />}
    </Drawer>
  );
};
