import { IntegrationType, useFinancialInstitutionsQuery } from "graphql/types";

export default function useStripeInstitution({ companyId }: { companyId: string }) {
  const { data, loading } = useFinancialInstitutionsQuery({
    variables: {
      companyId,
      integrationType: IntegrationType.Stripe,
    },
  });

  const stripeInstitution = data?.financialInstitutionsFor[0];

  let connection;
  if (stripeInstitution) {
    const accountWithConnection = stripeInstitution.accounts.find((acct) => acct.connection);
    connection = accountWithConnection?.connection;
  }

  return {
    stripeInstitution,
    connection,
    loading,
  };
}
