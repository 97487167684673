import React from "react";
import { IntegrationLogo } from "../../shared";
import { WellsFargo } from "@puzzle/icons";

const WellsFargoLogo = ({ circular }: { circular?: boolean }) => {
  return (
    <IntegrationLogo color="#CA1932" circular={circular}>
      <WellsFargo />
    </IntegrationLogo>
  );
};

export default WellsFargoLogo;
