import React, { useState } from "react";
import Big from "big.js";

import { styled, Input, Button, Tooltip } from "@puzzle/ui";
import { MoneyIn, MoneyOut, Check, Close, Restart } from "@puzzle/icons";

import {
  useExtraTransactionState,
  useUpdateDescriptor,
} from "components/dashboard/Transactions/hooks/useSingleTransaction";
import { BasicTransactionFragment } from "components/dashboard/Transactions/graphql.generated";
import { Box, S } from "ve";

const DescriptionRoot = styled("div", {
  display: "flex",
  gap: "$1",
  flexDirection: "row",
  marginleft: "-$3",
  alignItems: "center",

  variants: {
    inactiveBorder: {
      true: {
        borderRadius: "8px",
        border: "1px solid rgba(255, 255, 255, 0.1)",
        padding: "$1h $1h",
      },
      false: {},
    },
  },
});

const DescriptionIconRoot = styled("span", {
  lineHeight: 0,

  variants: {
    negative: {
      false: { color: "$green700" },
      true: { color: "$red600" },
    },
  },
});

const IconButton = styled(Button, { width: 32, height: 32 });

export const DescriptorCell = ({
  transaction,
  descriptor,
  originalDescriptor,
  amount,
  inactiveBorder = false,
  showArrow = true,
  size = "mini",
  singleClick = false,
}: {
  transaction: BasicTransactionFragment;
  descriptor: string;
  originalDescriptor: string;
  amount: string;
  inactiveBorder?: boolean;
  showArrow?: boolean;
  size?: "mini" | "medium";
  singleClick?: boolean;
}) => {
  const { canEdit } = useExtraTransactionState(transaction);
  const updateDescriptor = useUpdateDescriptor(transaction);

  const negative = Big(amount).lte(0);
  const [isEditing, setIsEditing] = useState(false);
  const [newDescriptor, setNewDescriptor] = useState<string | undefined>(undefined);

  return (
    <Box
      onClick={(e) => {
        if (canEdit && !isEditing) {
          e.stopPropagation();
          // double click
          if (e.detail === (singleClick ? 1 : 2)) {
            setIsEditing(true);
          }
        }
      }}
    >
      {isEditing ? (
        <Box
          css={{
            display: "flex",
            flexDirection: "row",
            gap: S["1"],
            alignItems: "center",
            width: "100%",
          }}
        >
          <Input
            id="descriptor"
            value={newDescriptor ?? descriptor}
            onChange={(e) => setNewDescriptor(e.target.value)}
            size={size}
          />
          <Box css={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <IconButton variant="minimal" onClick={() => setIsEditing(false)}>
              <Close width={14} height={14} />
            </IconButton>
            {originalDescriptor !== descriptor && (
              <Tooltip content="Revert to original description" side="top" align="center">
                <IconButton
                  variant="minimal"
                  onClick={() => {
                    updateDescriptor(originalDescriptor);
                    setNewDescriptor(originalDescriptor);
                    setIsEditing(false);
                  }}
                >
                  <Restart />
                </IconButton>
              </Tooltip>
            )}
            <IconButton
              variant="minimal"
              onClick={() => {
                if (newDescriptor && newDescriptor.trim()) {
                  updateDescriptor(newDescriptor);
                }
                setIsEditing(false);
              }}
            >
              <Check width={14} height={14} />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <DescriptionRoot inactiveBorder={inactiveBorder}>
          {showArrow && (
            <DescriptionIconRoot negative={negative}>
              {negative ? <MoneyOut /> : <MoneyIn />}
            </DescriptionIconRoot>
          )}

          {descriptor}
        </DescriptionRoot>
      )}
    </Box>
  );
};
