import React, { useCallback, useState, useMemo } from "react";
import { styled, Text, Button, Stack, Tooltip } from "@puzzle/ui";
import {
  Calculator,
  ChartRising,
  Loan,
  Close,
  Arrow,
  ChevronLeft,
  Help,
  Search,
  PaperAirplane,
  Pointer,
  Check,
} from "@puzzle/icons";
import { UnstyledButton, color, vars, S, Text as TextVE, Box } from "ve";

import Analytics from "lib/analytics";
import { useAI } from "components/AI/useAI";
import useSelf from "components/users/useSelf";
import { ProgressBar } from "./ProgressBar";
import { StepperPane, StepperPaneProps } from "./StepperPane";
import { WellcomeLeft } from "./images/WellcomeLeft";
import { OpenAISharing } from "./images/OpenAISharing";
import { OpenAIShareInfo } from "./content/OpenAIShareInfo";

import { AnalyticsQuestions, AnalyticsTriggeredFrom } from "./analytics";
import { StickyButton } from "components/dashboard/Inbox/DetailPane/TaxPreparerQuestion";
import { BookkeeperPlan, TaxPreparerPlan, UserPosition } from "graphql/types";
import { useActiveCompany } from "components/companies";
import { useUpdateCompanyMembershipAsUserMutation } from "components/dashboard/Users/graphql.generated";
import { reportError } from "lib/errors";
import Link from "components/common/Link";
import { roles } from "components/companies/common";

export type WelcomeToPuzzleLeftProps = {
  progress: number;
};

export type WelcomeToPuzzleRightProps = {
  onNext: () => void;
};

const CircledIcon = styled("div", {
  minWidth: "36px",
  width: "36px",
  height: "36px",
  backgroundColor: "$mauve800",
  borderRadius: "$ellipse",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const CircledIconSmall = styled("div", {
  minWidth: "24px",
  width: "24px",
  height: "24px",
  backgroundColor: "$mauve800",
  borderRadius: "$ellipse",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const TextLine = ({ children }: React.PropsWithChildren<unknown>) => (
  <Text
    size="bodyS"
    lineHeight="headingM"
    color="gray300"
    css={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "$1h" }}
  >
    {children}
  </Text>
);

const TextLineSmall = ({ children }: React.PropsWithChildren<unknown>) => (
  <Text
    size="bodyXS"
    lineHeight="bodyXS"
    color="gray300"
    css={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "$1" }}
  >
    {children}
  </Text>
);

const FancyButton = ({
  text,
  onClick,
  active,
}: {
  text: string;
  onClick: () => void;
  active: boolean;
}) => {
  return (
    <UnstyledButton
      onClick={onClick}
      css={{
        borderRadius: vars.radii[2],
        backgroundColor: color.rhino700,
        padding: S.$2,
        textAlign: "left",
        border: active ? "1px solid #72F2A6" : undefined,
      }}
    >
      <TextVE variant="headingS" color="gray300" weight="bold">
        {text}
      </TextVE>
    </UnstyledButton>
  );
};

export const WelcomeToPuzzleRight = ({ onNext }: WelcomeToPuzzleRightProps) => {
  const { self } = useSelf();
  const nameStr = self?.name ? `, ${self?.name}` : "";

  return (
    <Box
      css={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: S["2h"],
      }}
    >
      <Stack gap="2h">
        <Text size="heading2" weight="bold" lineHeight="bodyXL" color="grey400">
          {`Welcome to Puzzle${nameStr}!`}
        </Text>
        <Text size="bodyS" lineHeight="headingM" color="gray300">
          Puzzle is a real-time accounting and financial insights solution that can help you:
        </Text>
        <Stack gap="1h">
          <TextLine>
            <CircledIcon>
              <ChartRising />
            </CircledIcon>
            Understand your financial health in real time
          </TextLine>

          <TextLine>
            <CircledIcon>
              <Calculator />
            </CircledIcon>
            Be tax ready
          </TextLine>

          <TextLine>
            <CircledIcon>
              <Loan />
            </CircledIcon>
            Prepare for investor updates and fundraising
          </TextLine>
        </Stack>
        <Text size="bodyS" lineHeight="headingM" color="gray300">
          Let's quickly gather some info so we can personalize your experience.
        </Text>
      </Stack>
      <Button
        onClick={onNext}
        css={{
          alignSelf: "end",
        }}
      >
        Let's do it <Arrow color="black" css={{ marginBottom: "-2px" }} />
      </Button>
    </Box>
  );
};

const ColumnContainer = styled("div", {
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: "$2h",
});

export const WelcomeToPuzzleLeft = ({ progress }: WelcomeToPuzzleLeftProps) => (
  <ColumnContainer css={{ gap: "54px" }}>
    <ProgressBar progress={progress} />
    <Box style={{ alignSelf: "center" }}>
      <WellcomeLeft />
    </Box>
  </ColumnContainer>
);

interface ProgressBarWithStepperProps extends StepperPaneProps {
  progress: number;
}

export const ProgressWithStepper = ({
  activeIndex,
  farthestViewedIndex,
  farthestCompletedIndex,
  jumpTo,
  progress,
}: ProgressBarWithStepperProps) => (
  <ColumnContainer css={{ gap: "$4h" }}>
    <ProgressBar progress={progress} />
    <div>
      <StepperPane
        activeIndex={activeIndex}
        farthestViewedIndex={farthestViewedIndex}
        farthestCompletedIndex={farthestCompletedIndex}
        jumpTo={jumpTo}
      />
    </div>
  </ColumnContainer>
);

type IconedButtonContentProps = {
  icon: JSX.Element;
};

export const IconedButtonContent = ({
  icon,
  children,
}: React.PropsWithChildren<IconedButtonContentProps>) => (
  <Box
    css={{
      width: "100%",
      display: "flex",
      flexDirection: "row",
      gap: S["1h"],
      alignItems: "center",
    }}
  >
    <CircledIcon>{icon}</CircledIcon>
    <Text size="bodyS" lineHeight="headingM" color="gray300">
      {children}
    </Text>
  </Box>
);

export type ButtonPaneProps = {
  onNext: (value?: string) => void;
  location: string;
  onGoBack?: () => void;
};

export const WhatsYourPosition = ({ onNext, onGoBack, location }: ButtonPaneProps) => {
  const [updateCompanyMembershipAsUser, { error }] = useUpdateCompanyMembershipAsUserMutation();
  const { membership } = useActiveCompany();

  const handleNext = (response: UserPosition) => {
    onNext();

    Analytics.onboardingQuestionAnswered({
      location,
      question: AnalyticsQuestions.position,
      response,
    });

    Analytics.userPositionChanged({ oldValue: membership?.position ?? "", newValue: response });

    if (membership) {
      updateCompanyMembershipAsUser({
        variables: { input: { id: membership.id, position: response } },
      });
    } else {
      reportError(
        `Membership not defined when updating user position in the intro tour - can't update user position`
      );
    }
  };

  return (
    <Box
      css={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Stack gap="2h" css={{}}>
        <FancyButton
          text={roles[UserPosition.Founder]}
          onClick={() => handleNext(UserPosition.Founder)}
          active={membership?.position === UserPosition.Founder}
        />
        <FancyButton
          text={roles[UserPosition.CompanyEmployee]}
          onClick={() => handleNext(UserPosition.CompanyEmployee)}
          active={membership?.position === UserPosition.CompanyEmployee}
        />
        <FancyButton
          text={roles[UserPosition.OutsourcedAccountantOrCfo]}
          onClick={() => handleNext(UserPosition.OutsourcedAccountantOrCfo)}
          active={membership?.position === UserPosition.OutsourcedAccountantOrCfo}
        />
      </Stack>
      {onGoBack && (
        <Button
          variant="minimal"
          onClick={onGoBack}
          css={{ alignSelf: "flex-start", paddingLeft: "0", marginTop: "$2" }}
        >
          <ChevronLeft css={{ marginBottom: "-2px" }} /> Back
        </Button>
      )}
    </Box>
  );
};

export const HowMaintainBooks = ({
  onNext,
  onGoBack,
  showTitleTooltip = true,
  location,
  feedbackContent,
  activeButtonId,
  showSubtext,
  showCompletionText,
}: ButtonPaneProps & {
  activeButtonId?: BookkeeperPlan | TaxPreparerPlan | null;
  feedbackContent?: React.ReactNode;
  showTitleTooltip?: boolean;
  showSubtext?: boolean;
  showCompletionText?: boolean;
}) => {
  const handleNext = useCallback(
    (response: string) => {
      onNext(response);
      Analytics.onboardingQuestionAnswered({
        location,
        question: AnalyticsQuestions.bookkeepingPlan,
        response,
      });
    },
    [location, onNext]
  );

  const sortOrders = useMemo(() => {
    return [Math.random(), Math.random(), Math.random()];
  }, []);

  const content = useMemo(() => {
    return [
      {
        component: (
          <StickyButton
            variant="secondary"
            active={activeButtonId === BookkeeperPlan.HasBookkeeper}
            onClick={() => handleNext(BookkeeperPlan.HasBookkeeper)}
            css={{ width: "100%", height: "60px" }}
            suffix={
              location === AnalyticsTriggeredFrom.PostOnboarding ? (
                <Arrow color="gray500" />
              ) : undefined
            }
            suffixCss={{ position: "absolute", right: "$2" }}
          >
            <IconedButtonContent icon={<PaperAirplane color="#50FAAB" width={14} />}>
              I have a bookkeeper
            </IconedButtonContent>
          </StickyButton>
        ),
        sort: sortOrders[0],
      },
      {
        component: (
          <StickyButton
            variant="secondary"
            active={activeButtonId === BookkeeperPlan.WantsBookkeeper}
            onClick={() => handleNext(BookkeeperPlan.WantsBookkeeper)}
            css={{ width: "100%", height: "60px" }}
            suffix={
              location === AnalyticsTriggeredFrom.PostOnboarding ? (
                <Arrow color="gray500" />
              ) : undefined
            }
            suffixCss={{ position: "absolute", right: "$2" }}
          >
            <IconedButtonContent icon={<Search color="#50FAAB" width={18} />}>
              I want to hire a bookkeeper
            </IconedButtonContent>
          </StickyButton>
        ),
        sort: sortOrders[1],
      },
      {
        component: (
          <StickyButton
            variant="secondary"
            active={activeButtonId === BookkeeperPlan.DoItMyself}
            onClick={() => handleNext(BookkeeperPlan.DoItMyself)}
            css={{ width: "100%", height: "60px" }}
            suffix={
              location === AnalyticsTriggeredFrom.PostOnboarding ? (
                <Arrow color="gray500" />
              ) : undefined
            }
            suffixCss={{ position: "absolute", right: "$2" }}
          >
            <IconedButtonContent icon={<Pointer color="#50FAAB" width={10} />}>
              I plan to do it myself for now
            </IconedButtonContent>
          </StickyButton>
        ),
        sort: sortOrders[2],
      },
    ].sort((a, b) => a.sort - b.sort);
  }, [activeButtonId, location, handleNext, sortOrders]);

  return (
    <Stack gap="2h" css={{ padding: "$2h" }}>
      <Text size="headingM" weight="bold" lineHeight="headingM" color="grey200">
        What's your bookkeeping plan?{" "}
        {showTitleTooltip && (
          <Tooltip
            side="bottom"
            align="center"
            content={
              <div>
                Bookkeeping is the process of recording financial transactions and maintaining
                financial records. It's a key part of the accounting process.
              </div>
            }
          >
            <span>
              <Help />
            </span>
          </Tooltip>
        )}
      </Text>
      {showSubtext && (
        <Text>
          Let us know how you plan to address your bookkeeping needs so we can personalize your
          experience. If you need a bookkeeper we can help pair you with the ideal expert from our
          Bookkeeping & Tax Partner Network.
        </Text>
      )}

      {!activeButtonId && feedbackContent ? (
        <span>
          Want help deciding?{" "}
          <Link data-intercom-id="onboarding-checklist-ask-puzzle-trigger">Ask Puzzle</Link>.
        </span>
      ) : (
        feedbackContent
      )}

      {showCompletionText ? (
        <BookkeeperPlanCompletionText bookkeeperPlan={activeButtonId} />
      ) : (
        content.map((c) => c.component)
      )}

      {onGoBack ? (
        <Button
          variant="minimal"
          onClick={onGoBack}
          css={{ alignSelf: "flex-start", paddingLeft: "0" }}
        >
          <ChevronLeft css={{ marginBottom: "-2px" }} /> Back
        </Button>
      ) : null}
    </Stack>
  );
};

const BookkeeperPlanCompletionText = ({
  bookkeeperPlan,
}: {
  bookkeeperPlan: BookkeeperPlan | TaxPreparerPlan | undefined | null;
}) => {
  if (
    bookkeeperPlan !== BookkeeperPlan.HasBookkeeper &&
    bookkeeperPlan !== BookkeeperPlan.WantsBookkeeper
  ) {
    return null;
  }

  const text =
    bookkeeperPlan === BookkeeperPlan.HasBookkeeper
      ? "You’ll be able to invite your bookkeeper to Puzzle from Settings once you’re all set up."
      : "One of our accounting experts will reach out to better understand your situation and goals so we can match you with the optimal professional.";

  return (
    <Box
      css={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: S["1h"],
        padding: S["2"],
        backgroundColor: color.rhino700,
        borderRadius: vars.radii[2],
      }}
    >
      <Box
        css={{
          background: color.greenalpha,
          display: "inline-flex",
          padding: S["0h"],
          borderRadius: vars.radii["ellipse"],
        }}
      >
        <Check color="#000000" width={16} height={16} />
      </Box>

      <Text>{text}</Text>
    </Box>
  );
};

export const PersistedHowMaintainBooks = ({
  location,
  onNext,
  showTitleTooltip = true,
  ...props
}: Omit<ButtonPaneProps, "onNext"> & {
  onNext?: (value?: string) => void;
  showTitleTooltip?: boolean;
  activeButtonId?: BookkeeperPlan | TaxPreparerPlan | null;
  feedbackContent?: React.ReactNode;
  showSubtext?: boolean;
  showCompletionText?: boolean;
}) => {
  const { company, updateCompany } = useActiveCompany<true>();
  const [activeButtonId, setActiveButtonId] = useState(company.bookkeeperPlan);

  const handleNext = useCallback(
    (bookkeeperPlanValue?: string) => {
      setActiveButtonId(bookkeeperPlanValue as BookkeeperPlan);
      updateCompany({ bookkeeperPlan: bookkeeperPlanValue as BookkeeperPlan });
      if (onNext) onNext(bookkeeperPlanValue);
    },
    [setActiveButtonId, onNext, updateCompany]
  );

  return (
    <HowMaintainBooks
      location={location}
      onNext={handleNext}
      showTitleTooltip={showTitleTooltip}
      activeButtonId={activeButtonId}
      {...props}
    />
  );
};

export type OpenAIPaneProps = {
  onNext: () => void;
  onGoBack: () => void;
};

export const OpenAIPane = ({ onNext, onGoBack }: OpenAIPaneProps) => {
  const [showWhatWeShare, setShowWhatWeShare] = useState(false);
  const { consentToAI, enableAutonomousAccounting } = useAI();

  const confirmAutonomousAccounting = async () => {
    await consentToAI().then(async () => {
      await enableAutonomousAccounting().then(() => {
        onNext();
      });
    });
  };

  return (
    <ColumnContainer>
      <Text variant="headingM" weight="bold" color="gray50" css={{ alignSelf: "center" }}>
        OpenAI data sharing (optional)
      </Text>
      <Text variant="bodyXS" color="gray300" css={{ marginTop: "$1", textAlign: "center" }}>
        Some of the Autonomous Accounting features (AI Categorizer, AI Insights, and AI Lookup)
        leverage OpenAI to power them. To enable those features, click "Allow".
      </Text>
      <Box css={{ alignSelf: "center", marginTop: S["2h"] }}>
        <OpenAISharing />
      </Box>
      <Text variant="bodyXS" color="gray300" css={{ marginTop: "$2h" }}>
        By clicking Allow, you authorize Puzzle Financial Inc. to share certain information with
        OpenAI. We do NOT share:
      </Text>
      <Stack gap="0h" css={{ marginTop: "$1h" }}>
        <TextLineSmall>
          <CircledIconSmall>
            <Close size={8} />
          </CircledIconSmall>
          Company Name
        </TextLineSmall>

        <TextLineSmall>
          <CircledIconSmall>
            <Close size={8} />
          </CircledIconSmall>
          User emails or names
        </TextLineSmall>

        <TextLineSmall>
          <CircledIconSmall>
            <Close size={8} />
          </CircledIconSmall>
          Employee details
        </TextLineSmall>

        <TextLineSmall>
          <CircledIconSmall>
            <Close size={8} />
          </CircledIconSmall>
          Customer names or identifiers
        </TextLineSmall>
      </Stack>
      {showWhatWeShare ? (
        <OpenAIShareInfo />
      ) : (
        <Button
          variant="secondary"
          css={{ border: "none", color: "$blue500", padding: "0", marginTop: "$1h" }}
          onClick={() => setShowWhatWeShare(true)}
        >
          What we share
        </Button>
      )}

      <Box
        css={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          marginTop: S["2h"],
        }}
      >
        <Button onClick={onGoBack} variant="minimal" css={{ paddingLeft: "0" }}>
          <ChevronLeft css={{ marginBottom: "-2px" }} /> Back
        </Button>
        <Button onClick={onNext} variant="secondary" css={{ marginLeft: "auto" }}>
          No, thanks
        </Button>
        <Button onClick={confirmAutonomousAccounting} css={{ marginLeft: "$2" }}>
          Allow <Arrow color="black" css={{ marginBottom: "-2px" }} />
        </Button>
      </Box>
    </ColumnContainer>
  );
};

export const WantToHireBookkeeperPane = ({ onNext, onGoBack }: OpenAIPaneProps) => {
  return (
    <Stack css={{ padding: "$2h", gap: "$2h" }}>
      <Text css={{ fontSize: "$headingM", fontWeight: "bold" }}>What's your bookeeping plan?</Text>
      <Stack
        css={{
          backgroundColor: "$rhino600",
          padding: "$4",
          borderRadius: "8px",
          gap: "$2",
          alignItems: "center",
        }}
      >
        <Box
          css={{
            width: "60px",
            height: "60px",
            borderRadius: "1000px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: color.mauve800,
          }}
        >
          <Check color="#29cc6a" width={16} height={16} />
        </Box>

        <Text css={{ fontSize: "$bodyM", color: "$gray200", lineHeight: "$headingL" }}>Great!</Text>

        <Text
          css={{
            fontSize: "$bodyM",
            color: "$gray200",
            lineHeight: "$headingL",
            textAlign: "center",
          }}
        >
          We will follow up to help you find a great accounting partner.
        </Text>
      </Stack>
      <Box
        css={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: S["2h"],
        }}
      >
        <Button onClick={onGoBack} variant="minimal" css={{ paddingLeft: "0" }}>
          <ChevronLeft css={{ marginBottom: "-2px" }} /> Back
        </Button>
        <Button onClick={onNext} variant="primary" css={{}}>
          Next
        </Button>
      </Box>
    </Stack>
  );
};
