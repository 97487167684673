import React from "react";
import IntegrationLogo from "../shared/IntegrationLogo";
import { BillDotCom } from "@puzzle/icons";

const Logo = ({ circular }: { circular?: boolean }) => {
  return (
    <IntegrationLogo color="#EFEFF0" circular={circular}>
      <BillDotCom />
    </IntegrationLogo>
  );
};

export default Logo;
