import {
  AccountType,
  IntegrationConnectionFragment,
  IntegrationConnectionWithAccountStatsFragment,
  IntegrationType,
} from "../../../graphql/types";
import { IntegrationCategory, integrationsByCategory } from "./types";

export const getIntegrationCategoryForAccountType = (
  accountType: AccountType
): IntegrationCategory => {
  switch (accountType) {
    case AccountType.Invoice:
      return IntegrationCategory.AccountsPayable;
    case AccountType.PaymentProcessing:
      return IntegrationCategory.Revenue;
    case AccountType.Payroll:
      return IntegrationCategory.PayrollAndBenefits;
    default:
      return IntegrationCategory.BanksAndCreditCards;
  }
};

export const isPushIntegationType = (type: IntegrationType) => {
  switch (type) {
    case IntegrationType.Central:
    case IntegrationType.Zaprite:
      return true;
    default:
      return false;
  }
};

// Integration categories where an integration connection can exist without an account
export const integrationCategoriesWithoutAccounts = [
  IntegrationCategory.Accounting,
  IntegrationCategory.AccountsPayable,
];

export const integrationTypesWithoutAccounts = integrationCategoriesWithoutAccounts.reduce(
  (acc, category) => {
    return acc.concat(integrationsByCategory[category]);
  },
  [] as IntegrationType[]
);

export const isIntegrationConnectionMissingAccounts = (
  connection: IntegrationConnectionWithAccountStatsFragment
) => {
  const { activeCount, deletedCount, archivedCount, hiddenCount } = connection.accountStats;

  const hasNoAccounts = activeCount + deletedCount + archivedCount + hiddenCount === 0;
  const shouldHaveAccounts = !integrationTypesWithoutAccounts.includes(connection.type);

  return hasNoAccounts && shouldHaveAccounts;
};

export const getIntegrationConnectionForCategory = (
  category: IntegrationCategory,
  connections: IntegrationConnectionWithAccountStatsFragment[]
): IntegrationConnectionFragment[] => {
  const types = integrationsByCategory[category];
  return connections.filter((connection) => types.includes(connection.type));
};

export const getDisplayNameForIntegrationType = (integrationType: IntegrationType): string => {
  switch (integrationType) {
    case IntegrationType.BillDotCom:
      return "BILL";
    default:
      return integrationType;
  }
};
