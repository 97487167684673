import React from "react";
import Icon, { IconProps } from "../Icon";

export default function Expensify({
  viewBox = "0 0 48 48",
  width = 48,
  height = 48,
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} {...iconProps} viewBox={viewBox}>
      <path
        d="M37.4523 37.4518C34.2413 40.6818 29.9853 42.6388 25.4443 42.9808H22.5563C18.0153 42.6388 13.7403 40.6628 10.5293 37.4328L13.9113 34.0508C19.3833 39.6368 28.3323 39.7318 33.9183 34.2598C33.9753 34.2028 34.0323 34.1458 34.1083 34.0698L37.4523 37.4518Z"
        fill="#2EAAE2"
      />
      <path
        d="M42.9811 22.727V25.273C42.6771 29.871 40.7201 34.184 37.4521 37.433L34.0701 34.051C39.5801 28.465 39.5231 19.478 33.9751 13.949L37.4141 10.51C40.7011 13.778 42.6771 18.129 42.9811 22.727Z"
        fill="#2ECB70"
      />
      <path
        d="M37.414 10.529L33.975 13.968C31.334 11.327 27.743 9.845 24.019 9.845C16.191 9.845 9.845 16.21 9.845 24.057C9.845 27.781 11.308 31.372 13.911 34.032L10.529 37.414C6.976 33.861 5 29.035 5 24.019C5 13.512 13.512 5 24.019 5C29.035 5 33.861 6.976 37.414 10.529Z"
        fill="#FFC600"
      />
      <path
        d="M38.1932 24.0763C38.1932 31.9043 31.8472 38.2503 24.0192 38.2503C16.1912 38.2503 9.84521 31.9043 9.84521 24.0763C9.84521 16.2293 16.1912 9.86426 24.0192 9.86426C31.8472 9.86426 38.1932 16.2293 38.1932 24.0763Z"
        fill="white"
      />
      <path
        d="M18.0908 32.322H29.5098V28.902H22.2898V25.691H28.4078V22.195H22.2898V19.136H29.5098V15.735H18.0908V32.322Z"
        fill="#313E48"
      />
    </Icon>
  );
}
