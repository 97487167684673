import { useEffect } from "react";
import { useIntercom } from "react-use-intercom";

export function useHideIntercomOnMount() {
  const intercom = useIntercom();

  useEffect(() => {
    intercom.update({ hideDefaultLauncher: true });
    return () => {
      intercom.update({ hideDefaultLauncher: false });
    };
  }, [intercom]);
}
