import React, { MouseEventHandler } from "react";
import { CSSProps, styled } from "@puzzle/ui";
import { Button } from "@puzzle/ui";
import Link from "next/link";

const Container = styled("div", {
  paddingTop: "$20",
  margin: "0 auto",
  maxWidth: 430,
  textAlign: "center",

  [`${Button}`]: {
    margin: "0 auto",
  },
});

const Title = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "20px",
  color: "$gray100",

  svg: {
    marginRight: "$1",
  },
});

const Message = styled("div", {
  textStyle: "$bodyS",
  color: "$gray400",
  margin: "$2 $0 $5",
});

const NoData = ({
  callToAction,
  children,
  icon,
  title,
  onClick,
  href,
  buttonProps,
  ...props
}: CSSProps &
  React.PropsWithChildren<{
    title: string;
    callToAction?: string;
    icon?: React.ReactElement;
    onClick?: MouseEventHandler;
    href?: string;
    buttonProps?: React.ComponentProps<typeof Button>;
  }>) => {
  return (
    <Container {...props}>
      <Title>
        {icon} {title}
      </Title>
      <Message>{children}</Message>

      {callToAction && (
        <>
          {href ? (
            <Link href={href} passHref>
              <Button variant="primary" {...buttonProps}>
                {callToAction}
              </Button>
            </Link>
          ) : (
            <Button variant="primary" onClick={onClick} {...buttonProps}>
              {callToAction}
            </Button>
          )}
        </>
      )}
    </Container>
  );
};

NoData.toString = Container.toString;

export default NoData;
