export const FORMATION_SPENDING_LIMIT = 15000;

export const getAveragePercentage = (
  expenses: number,
  limit: number = FORMATION_SPENDING_LIMIT
) => {
  const progress =
    expenses > limit
      ? Math.max(0, (1 - (expenses - limit) / limit) * 100)
      : Math.max(0, (expenses / limit) * 100);
  return {
    progress,
    exceeding: expenses > limit,
  };
};
