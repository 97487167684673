import React, { useCallback, useEffect, useRef } from "react";
import { useDropArea as useBaseDropArea, usePrevious } from "react-use";
import Papa, { ParseResult } from "papaparse";
import { useApolloClient } from "@apollo/client";

import { Text, Dialog, IconButton, Button, Alert, useToasts, Select } from "@puzzle/ui";
import { Delete, Paperclip, Exclamation } from "@puzzle/icons";
import { pluralize } from "@puzzle/utils";

import {
  DropZone,
  Attachment,
  StyledConfirmed,
  StyledExclamation,
} from "components/common/files/styles";
import { useFile } from "components/common/files";
import { useActiveCompany } from "components/companies";
import Link from "components/common/Link";
import { AssociatedEntity, IntegrationConnectionStatus } from "graphql/types";
import { ImportType, useIsImporting } from "components/common/import/useIsImporting";
import Loader from "components/common/Loader";
import Analytics from "lib/analytics";

import {
  useImportBillsContext,
  parseCSV,
  ParsedBillCSVRow,
  BILL_MAPPERS,
  Provider,
} from "./ImportBillsProvider";
import { GetBillsDocument, useDirectIngestBillsMutation } from "../graphql.generated";
import { Box, S } from "ve";

export const ManualImportBills = () => {
  const { company } = useActiveCompany<true>();
  const {
    selectedFile,
    setSelectedFile,
    setMode,
    parsedCSV,
    setParsedCSV,
    resetFileState,
    onOpenChange,
    billDotComConnection,
    provider,
    setProvider,
  } = useImportBillsContext();
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const openFilePicker = useCallback(() => hiddenFileInput.current?.click(), []);
  const { startImportProgress, endImportProgress, isImporting } = useIsImporting(ImportType.Bill);
  const [ingestBillsMutation] = useDirectIngestBillsMutation();
  const { toast } = useToasts();
  const client = useApolloClient();
  const isValidCSV = parsedCSV?.meta.isValid;
  const prevProvider = usePrevious(provider);
  const endImport = useCallback(() => {
    onOpenChange?.(false);

    const t = setTimeout(() => {
      endImportProgress();
      resetFileState();
      return () => clearTimeout(t);
    }, 200);
  }, [endImportProgress, onOpenChange, resetFileState]);

  const handleError = useCallback(() => {
    endImport();
    toast({
      title: `Importing bills failed`,
      message: `Something went wrong. We recommend importing a maximum of 100 bills at a time; we apologize for the inconvenience. If the problem persists, contact support.`,
      status: "error",
      duration: 10000,
    });
  }, [toast, endImport]);

  const handleTimeout = useCallback(() => {
    endImport();
    toast({
      title: `Still importing bills`,
      message: `The import process is taking longer than expected - check back in a few minutes to see whether your bills have been added.`,
      status: "warning",
      duration: 10000,
    });
  }, [toast, endImport]);

  const { onFiles } = useFile({
    maxUploads: 1,
    entityId: company.id,
    entityType: AssociatedEntity.DirectIngest,
    // Upload error
    onError: () => {
      handleError();
      Analytics.billImportCsvUploadFailed({
        fileName: selectedFile?.name ?? "",
        fileSize: selectedFile?.size ?? 0,
        contentType: selectedFile?.type ?? "",
        totalRows: parsedCSV?.data.length ?? 0,
      });
    },
    onUploadComplete: ([file]) => {
      // Shouldn't get here, just for type safety
      if (!parsedCSV?.meta.isValid) return;

      ingestBillsMutation({
        variables: {
          input: {
            companyId: company.id,
            filename: file.filename ?? "",
            contentType: file.contentType,
            path: file.path,
            size: file.size,
            provider: provider,
            bills: parsedCSV.data as Required<ParsedBillCSVRow>[],
          },
        },

        onError: (err) => {
          const trackingParams = {
            fileName: file.filename ?? "",
            fileSize: file.size,
            contentType: file.contentType,
            totalRows: parsedCSV?.data.length ?? 0,
          };

          // @ts-expect-error check network error
          if (err?.networkError?.statusCode === 524) {
            handleTimeout();
            Analytics.billImportTimedOut(trackingParams);
            return;
          }

          handleError();
          Analytics.transactionImportFailed(trackingParams);
        },

        onCompleted({ directIngestBills }) {
          endImport();
          client.refetchQueries({
            include: [GetBillsDocument],
          });

          toast({
            title: `${pluralize(directIngestBills.savedCount, "bill")} imported`,
            message: "Your imported bills have been successfully added.",
            status: "success",
          });

          Analytics.billImportSucceeded({
            id: directIngestBills.id,
            fileName: file.filename ?? "",
            fileSize: file.size,
            contentType: file.contentType,
            totalRows: directIngestBills.savedCount,
          });
        },
      });
    },
  });

  const [dropAreaProps, { over: isDropping }] = useBaseDropArea({
    onFiles: (files) => {
      if (files[0].type === "text/csv") {
        setSelectedFile(files[0]);
      }
    },
  });
  const dropProps = selectedFile ? {} : dropAreaProps;

  useEffect(() => {
    if (!selectedFile) return;
    if (!parsedCSV || prevProvider !== provider) {
      Papa.parse(selectedFile, {
        header: true,
        complete: (results: ParseResult<Record<keyof ParsedBillCSVRow, string>>) => {
          setParsedCSV(parseCSV(results, provider, selectedFile));
        },
        skipEmptyLines: "greedy",
      });
    }
  }, [selectedFile, parsedCSV, setParsedCSV, provider, prevProvider]);

  if (isImporting) {
    return (
      <Dialog.Body>
        <Box css={{ textAlign: "center", margin: S["2"] }}>
          <Loader size={32} css={{ marginBottom: "$2" }} />
          <Text size="bodyM">Importing your bills...</Text>
        </Box>
      </Dialog.Body>
    );
  }

  return (
    <div>
      <Dialog.Title>Import bills</Dialog.Title>
      <Dialog.Body>
        <Box css={{ marginBottom: S["1"] }}>
          <Text color="gray200" css={{ display: "flex", marginBottom: "$1" }}>
            Source
          </Text>
          <Select
            options={Object.keys(BILL_MAPPERS).map((p) => ({ id: p, value: p }))}
            value={provider}
            onSelectionChange={(provider) => {
              setProvider(provider as Provider);
            }}
            size="small"
          />
        </Box>
        {provider === "Other" && (
          <Alert css={{ marginBottom: "$1" }} kind="minimal">
            <Text size="bodyS">
              By selecting &ldquo;Other&rdquo; as the source, please use the{" "}
              <Link
                target="_blank"
                href="https://docs.google.com/spreadsheets/d/1fqjv8rUUpXXiOppl47OK2byNQZ79O7k2B3lmaawcbjw/copy"
                underline
                onClick={() => {
                  Analytics.billImportTemplateLinkClicked();
                }}
              >
                provided template
              </Link>
              .
            </Text>
          </Alert>
        )}
        <Box css={{ marginBottom: S["1"] }}>
          <Text>Upload bill(s)</Text>
        </Box>
        <Box css={{ marginBottom: S["2"] }}>
          <DropZone
            isDropping={isDropping && !selectedFile}
            {...dropProps}
            hasSelectedFile={!!selectedFile}
            onClick={openFilePicker}
          >
            {selectedFile ? (
              <div>
                <div>
                  {parsedCSV?.meta.isValid ? (
                    <StyledConfirmed />
                  ) : (
                    <StyledExclamation css={{ color: "$red500" }} />
                  )}
                  <Text color={parsedCSV?.meta.isValid ? "$white" : "$red500"}>
                    {selectedFile.name}
                  </Text>
                </div>
                <IconButton
                  css={{ position: "absolute", bottom: "0", right: "0", margin: "$1" }}
                  onClick={resetFileState}
                >
                  <Delete />
                </IconButton>
              </div>
            ) : (
              <Attachment>
                <Paperclip />
                <Text>Drag and drop or select a .csv file</Text>
                <input
                  type="file"
                  accept=".csv"
                  ref={hiddenFileInput}
                  style={{ display: "none" }}
                  onChange={(e) => {
                    if (e.target.files) {
                      setSelectedFile(e.target.files[0]);
                    }
                  }}
                />
              </Attachment>
            )}
          </DropZone>
        </Box>
        {parsedCSV && !isValidCSV && (
          <Alert kind="error" css={{ marginBottom: "$2" }} icon={<Exclamation />}>
            <Text size="bodyM">
              <Text weight="bold">We have detected an error in your uploaded file.</Text> Please
              make sure you selected the correct source or modify and re-upload the CSV.
            </Text>
          </Alert>
        )}
        <Box css={{ height: "14px" }}>
          {!billDotComConnection ||
            (billDotComConnection.status !== IntegrationConnectionStatus.Ok && (
              <Text color="gray50" size="bodyM">
                Use BILL?{" "}
                <Link
                  color="greenalpha"
                  onClick={() => {
                    setMode("billDotCom");
                  }}
                >
                  Integrate with BILL
                </Link>{" "}
                to automatically import.
              </Text>
            ))}
        </Box>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.Actions>
          <Dialog.Close asChild>
            <Button variant="secondary">Cancel</Button>
          </Dialog.Close>
          <Button
            variant="primary"
            disabled={!selectedFile || !isValidCSV}
            onClick={() => {
              startImportProgress();
              if (selectedFile && isValidCSV) onFiles([selectedFile]);
            }}
            loading={isImporting}
          >
            Import
          </Button>
        </Dialog.Actions>
      </Dialog.Footer>
    </div>
  );
};
