import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Receipt({
  viewBox = "0 0 16 16",
  color = "currentColor",
  fill = color,
  ...iconProps
}: IconProps) {
  return (
    <Icon {...iconProps} viewBox={viewBox}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 1.5C2 1.22386 2.22386 1 2.5 1H10.7777C10.909 1 11.0351 1.05166 11.1286 1.14381L13.8509 3.82563C13.9463 3.9196 14 4.04791 14 4.18182V14.5C14 14.7761 13.7761 15 13.5 15H2.5C2.22386 15 2 14.7761 2 14.5V1.5ZM3 2V14H13V4.39112L10.5728 2H3Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 5C5 4.72386 5.22386 4.5 5.5 4.5H9.5C9.77614 4.5 10 4.72386 10 5C10 5.27614 9.77614 5.5 9.5 5.5H5.5C5.22386 5.5 5 5.27614 5 5ZM5 8C5 7.72386 5.22386 7.5 5.5 7.5H10.5C10.7761 7.5 11 7.72386 11 8C11 8.27614 10.7761 8.5 10.5 8.5H5.5C5.22386 8.5 5 8.27614 5 8ZM5 11C5 10.7239 5.22386 10.5 5.5 10.5H10.5C10.7761 10.5 11 10.7239 11 11C11 11.2761 10.7761 11.5 10.5 11.5H5.5C5.22386 11.5 5 11.2761 5 11Z"
        fill={fill}
      />
    </Icon>
  );
}
