import { useMemo } from "react";
import { useActiveCompany } from "components/companies";
import { useGetReconciliationOptionsQuery } from "./graphql.generated";

export const useReconciliationCategories = () => {
  const { company } = useActiveCompany<true>();
  const { data: optionsData, loading } = useGetReconciliationOptionsQuery({
    variables: { companyId: company.id },
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-only",
  });

  const { categories, balanceCategories } = useMemo(() => {
    return {
      categories: optionsData?.categories || [],
      balanceCategories: optionsData?.balanceCategories || [],
    };
  }, [optionsData]);

  return useMemo(
    () => ({
      categories,
      balanceCategories,
      loading,
    }),
    [categories, balanceCategories, loading]
  );
};
