import React from "react";
import { styled } from "@puzzle/ui";
import { PaidAddOn } from "@puzzle/icons";

const Logo = styled("div", {
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",

  variants: {
    borderRoudness: {
      normal: {
        borderRadius: "12px",
      },
      circular: {
        borderRadius: "50%",
      },
    },
  },
});

const IntegrationLogo = ({
  children,
  color = "transparent",
  width = "100%",
  height = "100%",
  circular = false,
  onClick,
  isPaidAddOn = false,
  tooltipContent = "",
  ...props
}: React.PropsWithChildren<{
  color?: string;
  width?: string;
  height?: string;
  circular?: boolean;
  isPaidAddOn?: boolean;
  onClick?: () => void;
  tooltipContent?: string;
}>) => {
  return (
    <div style={{ position: "relative", width: width, height: height }}>
      <Logo
        color={color}
        onClick={onClick}
        borderRoudness={circular ? "circular" : "normal"}
        css={{
          backgroundColor: color,
          width: width,
          height: height,
        }}
        {...props}
      >
        {children}
      </Logo>
      {isPaidAddOn && <PaidAddOn style={{ position: "absolute", bottom: "-5px", right: "-5px" }} />}
    </div>
  );
};

export default IntegrationLogo;
