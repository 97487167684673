import React, { useState } from "react";
import { useRippling } from "./useRippling";
import { IntegrationsListItemBaseProps } from "../List";
import { IntegrationConnectionStatus, IntegrationType } from "graphql/types";
import Logo from "./Logo";
import { IntegrationDisplayComponent } from "../shared/types";
import Analytics, { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { useRouter } from "next/router";
import { ConnectRipplingModal } from "./ConnectRipplingModal";
import { Alert, Tooltip } from "@puzzle/ui";

interface RipplingListItemProps {
  DisplayComponent: IntegrationDisplayComponent<
    React.PropsWithChildren<IntegrationsListItemBaseProps>
  >;
  companyId: string;
}
export const TITLE = "Rippling";
export const SUBTITLE = "Link employment costs for your financial statements and taxes.";
const PAID_ADD_ON_TOOLTIP =
  "Rippling is a paid add-on. Rippling charges $2 per employee per month for API access";
const RipplingListItem = ({ DisplayComponent, companyId }: RipplingListItemProps) => {
  const { onClickConnect, connection, loading } = useRippling({ companyId });
  const [showModal, setShowModal] = useState(false);
  const router = useRouter();
  const isOnboarding = router.pathname.includes("intro");
  const isRipplingIntegrationConnectionEnabled = isPosthogFeatureFlagEnabled(
    FeatureFlag.RipplingIntegrationConnection
  );
  const isExistingConnection =
    connection?.status !== undefined &&
    [IntegrationConnectionStatus.Ok, IntegrationConnectionStatus.Error].includes(
      connection?.status
    );

  const onClickConnectWrapper = () => {
    setShowModal(true);
    Analytics.ripplingIntegrationSelected({ source: isOnboarding ? "Onboarding" : "Integrations" });
  };

  // We want to show Rippling in the Integrations list if,
  // 1. The company already has an active Rippling connection (even if the feature flag is disabled)
  // 2. The feature flag is enabled
  if (isExistingConnection || isRipplingIntegrationConnectionEnabled) {
    return (
      <>
        <DisplayComponent
          integrationType={IntegrationType.Rippling}
          connectIntegration={onClickConnectWrapper}
          title={TITLE}
          logo={
            <Tooltip content={PAID_ADD_ON_TOOLTIP}>
              <div style={{ position: "relative", width: 48, height: 48 }}>
                <Logo isPaidAddOn={true} />
              </div>
            </Tooltip>
          }
          connection={connection}
          loading={loading}
        />
        <ConnectRipplingModal
          open={showModal}
          onOpenChange={() => {
            setShowModal(false);
          }}
          showSteps={!isOnboarding}
          forceShowWarning={isOnboarding}
        />
        {connection && isOnboarding && (
          <Alert>
            Upgrade to a paid plan after onboarding to unlock your Rippling integration.
          </Alert>
        )}
      </>
    );
  } else {
    return null;
  }
};

export default RipplingListItem;
