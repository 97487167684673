import React, { LabelHTMLAttributes, ReactNode, useMemo } from "react";
import * as Stitches from "@stitches/react";
import { styled } from "../stitches";
import { useLabel } from "react-aria";
import { Feedback } from "./types";
import { Help } from "../Help";

export interface FieldContextType {
  feedback?: Feedback;
  message?: string;
}

const FieldContext = React.createContext<FieldContextType | null>(null);

export const useFieldContext = () => {
  const context = React.useContext(FieldContext);

  return context || {};
};

const Label = styled("label", {
  display: "flex",
  alignItems: "center",
  gap: "$0h",
  fontWeight: "$bold",
  fontSize: "$bodyS",
  lineHeight: "$bodyXS",
});

const Message = styled("div", {
  marginTop: "$0h",
  textVariant: "$bodyXS",

  variants: {
    feedback: {
      negative: {
        color: "$negative",
      },
      positive: {
        color: "$positive",
      },
      neutral: {
        marginTop: "0",
        color: "$gray500",
      },
    },
  },
});

const Control = styled("div");

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",

  defaultVariants: {
    readOnly: false,
  },

  variants: {
    readOnly: {
      true: {
        gap: "$1h",

        [`${Label}`]: {
          color: "$gray500",
        },

        [`${Control}`]: {
          color: "$gray100",
          fontSize: "$bodyS",
          lineHeight: "$bodyXS",
        },
      },
      false: {
        gap: "$1",

        [`${Label}`]: {
          color: "$gray200",
        },
      },
    },
  },
});

export const Field = ({
  children,
  feedback,
  message,
  error,
  hint,
  readOnly,
  css,
  ...props
}: {
  label?: ReactNode;
  id?: string;
  feedback?: Feedback;
  message?: string;
  error?: string;
  children: React.ReactElement;
  hint?: string;
  readOnly?: boolean;
  css?: Stitches.CSS;
} & LabelHTMLAttributes<HTMLLabelElement>) => {
  const { labelProps, fieldProps } = useLabel(props);

  const context = useMemo(() => {
    return {
      feedback: error ? "negative" : feedback,
      message: error || message,
    };
  }, [error, feedback, message]);

  return (
    <FieldContext.Provider value={context}>
      <Container readOnly={readOnly} css={css}>
        {props.label && (
          <Label {...labelProps}>
            {props.label}
            {hint && <Help content={hint} />}
          </Label>
        )}

        <Control>{React.cloneElement(children, fieldProps)}</Control>

        {context.message && <Message feedback={context.feedback}>{context.message}</Message>}
      </Container>
    </FieldContext.Provider>
  );
};
