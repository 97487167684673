import * as Types from '../../graphql/types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PartnerCompanyFragment = { __typename?: 'Company', id: string, name: string };

export type PartnerQueryVariables = Types.Exact<{
  clientId: Types.Scalars['ID'];
}>;


export type PartnerQuery = { __typename?: 'Query', partner: { __typename?: 'ApiPartner', name?: string | null } };

export type ConnectPartnerUserMutationVariables = Types.Exact<{
  input: Types.ConnectPartnerUserToPuzzleUserInput;
}>;


export type ConnectPartnerUserMutation = { __typename?: 'Mutation', connectPartnerUserToPuzzleUser: { __typename?: 'ConnectPartnerUserToPuzzleUserResult', apiUser: { __typename?: 'ApiUser', externalId: string } } };

export type SeedCompanyMutationVariables = Types.Exact<{
  input: Types.SeedWithTestDataInput;
}>;


export type SeedCompanyMutation = { __typename?: 'Mutation', seedWithTestData: { __typename?: 'SeedWithTestDataResult', company: { __typename?: 'Company', id: string, name: string } } };

export type KickCompanyIngestionMutationVariables = Types.Exact<{
  input: Types.KickCompanyIngestionInput;
}>;


export type KickCompanyIngestionMutation = { __typename?: 'Mutation', kickCompanyIngestion: { __typename?: 'KickCompanyIngestionResult', company: { __typename?: 'Company', id: string } } };

export type ConnectionRequestContextQueryVariables = Types.Exact<{
  input: Types.ConnectionRequestContextInput;
}>;


export type ConnectionRequestContextQuery = { __typename?: 'Query', connectionRequestContext: { __typename?: 'ConnectionRequestContextResult', scopes: Array<{ __typename?: 'ExternalApiScopeInfo', name: string, description: string }> } };

export const PartnerCompanyFragmentDoc = gql`
    fragment partnerCompany on Company {
  id
  name
}
    `;
export const PartnerDocument = gql`
    query partner($clientId: ID!) {
  partner(clientId: $clientId) {
    name
  }
}
    `;

/**
 * __usePartnerQuery__
 *
 * To run a query within a React component, call `usePartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function usePartnerQuery(baseOptions: Apollo.QueryHookOptions<PartnerQuery, PartnerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PartnerQuery, PartnerQueryVariables>(PartnerDocument, options);
      }
export function usePartnerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartnerQuery, PartnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PartnerQuery, PartnerQueryVariables>(PartnerDocument, options);
        }
export type PartnerQueryHookResult = ReturnType<typeof usePartnerQuery>;
export type PartnerLazyQueryHookResult = ReturnType<typeof usePartnerLazyQuery>;
export type PartnerQueryResult = Apollo.QueryResult<PartnerQuery, PartnerQueryVariables>;
export const ConnectPartnerUserDocument = gql`
    mutation connectPartnerUser($input: ConnectPartnerUserToPuzzleUserInput!) {
  connectPartnerUserToPuzzleUser(input: $input) {
    apiUser {
      externalId
    }
  }
}
    `;
export type ConnectPartnerUserMutationFn = Apollo.MutationFunction<ConnectPartnerUserMutation, ConnectPartnerUserMutationVariables>;

/**
 * __useConnectPartnerUserMutation__
 *
 * To run a mutation, you first call `useConnectPartnerUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectPartnerUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectPartnerUserMutation, { data, loading, error }] = useConnectPartnerUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConnectPartnerUserMutation(baseOptions?: Apollo.MutationHookOptions<ConnectPartnerUserMutation, ConnectPartnerUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectPartnerUserMutation, ConnectPartnerUserMutationVariables>(ConnectPartnerUserDocument, options);
      }
export type ConnectPartnerUserMutationHookResult = ReturnType<typeof useConnectPartnerUserMutation>;
export type ConnectPartnerUserMutationResult = Apollo.MutationResult<ConnectPartnerUserMutation>;
export type ConnectPartnerUserMutationOptions = Apollo.BaseMutationOptions<ConnectPartnerUserMutation, ConnectPartnerUserMutationVariables>;
export const SeedCompanyDocument = gql`
    mutation seedCompany($input: SeedWithTestDataInput!) {
  seedWithTestData(input: $input) {
    company {
      ...partnerCompany
    }
  }
}
    ${PartnerCompanyFragmentDoc}`;
export type SeedCompanyMutationFn = Apollo.MutationFunction<SeedCompanyMutation, SeedCompanyMutationVariables>;

/**
 * __useSeedCompanyMutation__
 *
 * To run a mutation, you first call `useSeedCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSeedCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [seedCompanyMutation, { data, loading, error }] = useSeedCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSeedCompanyMutation(baseOptions?: Apollo.MutationHookOptions<SeedCompanyMutation, SeedCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SeedCompanyMutation, SeedCompanyMutationVariables>(SeedCompanyDocument, options);
      }
export type SeedCompanyMutationHookResult = ReturnType<typeof useSeedCompanyMutation>;
export type SeedCompanyMutationResult = Apollo.MutationResult<SeedCompanyMutation>;
export type SeedCompanyMutationOptions = Apollo.BaseMutationOptions<SeedCompanyMutation, SeedCompanyMutationVariables>;
export const KickCompanyIngestionDocument = gql`
    mutation kickCompanyIngestion($input: KickCompanyIngestionInput!) {
  kickCompanyIngestion(input: $input) {
    company {
      id
    }
  }
}
    `;
export type KickCompanyIngestionMutationFn = Apollo.MutationFunction<KickCompanyIngestionMutation, KickCompanyIngestionMutationVariables>;

/**
 * __useKickCompanyIngestionMutation__
 *
 * To run a mutation, you first call `useKickCompanyIngestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKickCompanyIngestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kickCompanyIngestionMutation, { data, loading, error }] = useKickCompanyIngestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useKickCompanyIngestionMutation(baseOptions?: Apollo.MutationHookOptions<KickCompanyIngestionMutation, KickCompanyIngestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<KickCompanyIngestionMutation, KickCompanyIngestionMutationVariables>(KickCompanyIngestionDocument, options);
      }
export type KickCompanyIngestionMutationHookResult = ReturnType<typeof useKickCompanyIngestionMutation>;
export type KickCompanyIngestionMutationResult = Apollo.MutationResult<KickCompanyIngestionMutation>;
export type KickCompanyIngestionMutationOptions = Apollo.BaseMutationOptions<KickCompanyIngestionMutation, KickCompanyIngestionMutationVariables>;
export const ConnectionRequestContextDocument = gql`
    query connectionRequestContext($input: ConnectionRequestContextInput!) {
  connectionRequestContext(input: $input) {
    scopes {
      name
      description
    }
  }
}
    `;

/**
 * __useConnectionRequestContextQuery__
 *
 * To run a query within a React component, call `useConnectionRequestContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectionRequestContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectionRequestContextQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConnectionRequestContextQuery(baseOptions: Apollo.QueryHookOptions<ConnectionRequestContextQuery, ConnectionRequestContextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConnectionRequestContextQuery, ConnectionRequestContextQueryVariables>(ConnectionRequestContextDocument, options);
      }
export function useConnectionRequestContextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConnectionRequestContextQuery, ConnectionRequestContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConnectionRequestContextQuery, ConnectionRequestContextQueryVariables>(ConnectionRequestContextDocument, options);
        }
export type ConnectionRequestContextQueryHookResult = ReturnType<typeof useConnectionRequestContextQuery>;
export type ConnectionRequestContextLazyQueryHookResult = ReturnType<typeof useConnectionRequestContextLazyQuery>;
export type ConnectionRequestContextQueryResult = Apollo.QueryResult<ConnectionRequestContextQuery, ConnectionRequestContextQueryVariables>;