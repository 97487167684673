import * as Types from '../../types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
import { IntegrationConnectionFragmentDoc } from '../../fragments/integrationConnection.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BillDotComIntegrationQueryVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
}>;


export type BillDotComIntegrationQuery = { __typename?: 'Query', integrations: { __typename?: 'Integrations', billDotCom: { __typename?: 'BillDotComIntegration', connection?: { __typename?: 'IntegrationConnection', id: string, createdAt: CalendarDateTimeString, type: Types.IntegrationType, ingressType: Types.IngressType, status: Types.IntegrationConnectionStatus, message?: string | null, errorCode?: Types.DisconnectionErrorCode | null, lastSyncedAt?: CalendarDateTimeString | null, condition?: Types.IntegrationConnectionCondition | null, latestSync?: { __typename?: 'SyncRun', id: any, status: Types.SyncRunStatus } | null } | null } } };


export const BillDotComIntegrationDocument = gql`
    query billDotComIntegration($companyId: ID!) {
  integrations(companyId: $companyId) {
    billDotCom {
      connection(companyId: $companyId) {
        ...integrationConnection
      }
    }
  }
}
    ${IntegrationConnectionFragmentDoc}`;

/**
 * __useBillDotComIntegrationQuery__
 *
 * To run a query within a React component, call `useBillDotComIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillDotComIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillDotComIntegrationQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useBillDotComIntegrationQuery(baseOptions: Apollo.QueryHookOptions<BillDotComIntegrationQuery, BillDotComIntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BillDotComIntegrationQuery, BillDotComIntegrationQueryVariables>(BillDotComIntegrationDocument, options);
      }
export function useBillDotComIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BillDotComIntegrationQuery, BillDotComIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BillDotComIntegrationQuery, BillDotComIntegrationQueryVariables>(BillDotComIntegrationDocument, options);
        }
export type BillDotComIntegrationQueryHookResult = ReturnType<typeof useBillDotComIntegrationQuery>;
export type BillDotComIntegrationLazyQueryHookResult = ReturnType<typeof useBillDotComIntegrationLazyQuery>;
export type BillDotComIntegrationQueryResult = Apollo.QueryResult<BillDotComIntegrationQuery, BillDotComIntegrationQueryVariables>;