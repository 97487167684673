import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Ellipsis({
  viewBox = "0 0 16 16",
  width = 16,
  height = 16,
  color = "currentColor",
  fill = color,
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} {...iconProps} fill="none" viewBox={viewBox}>
      <path
        fill={fill}
        d="M9.313 8c0-.355-.137-.656-.383-.93-.274-.246-.575-.382-.93-.382-.383 0-.684.136-.93.382a1.239 1.239 0 00-.383.93c0 .383.11.684.383.93.246.273.547.383.93.383.355 0 .656-.11.93-.383.246-.246.383-.547.383-.93zm3.28-1.312c.356 0 .657.136.93.382.247.274.383.575.383.93 0 .383-.136.684-.383.93a1.239 1.239 0 01-.93.383c-.382 0-.683-.11-.929-.383-.273-.246-.383-.547-.383-.93 0-.355.11-.656.383-.93.246-.246.547-.382.93-.382zm-9.187 0c.356 0 .656.136.93.382.246.274.383.575.383.93 0 .383-.137.684-.383.93a1.239 1.239 0 01-.93.383c-.383 0-.683-.11-.93-.383-.273-.246-.382-.547-.382-.93 0-.355.11-.656.383-.93.246-.246.546-.382.93-.382z"
      ></path>
    </Icon>
  );
}
