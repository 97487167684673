import React from "react";
import Icon, { IconProps } from "./Icon";

export default function StackOfPaper({
  viewBox = "0 0 16 16",
  size = 16,
  fill = "#595861",
  ...iconProps
}: IconProps) {
  return (
    <Icon fill={fill} size={size} {...iconProps} viewBox={viewBox}>
      <path
        d="M1.81592 5.28027L7.73193 7.94629C7.90967 8.02246 8.0874 8.02246 8.26514 7.94629L14.1812 5.28027C14.6128 5.07715 14.6128 4.44238 14.1812 4.23926L8.26514 1.57324C8.18896 1.54785 8.0874 1.49707 8.01123 1.49707C7.90967 1.49707 7.80811 1.54785 7.73193 1.57324L1.81592 4.23926C1.38428 4.44238 1.38428 5.07715 1.81592 5.28027ZM14.1812 7.51465L12.7085 6.8291L8.59521 8.70801C8.41748 8.78418 8.21436 8.83496 8.01123 8.83496C7.78271 8.83496 7.57959 8.78418 7.40186 8.70801L3.28857 6.8291L1.81592 7.51465C1.38428 7.69238 1.38428 8.32715 1.81592 8.53027L7.73193 11.1963C7.90967 11.2725 8.0874 11.2725 8.26514 11.1963L14.1812 8.53027C14.6128 8.32715 14.6128 7.69238 14.1812 7.51465ZM14.1812 10.7646L12.7085 10.0791L8.59521 11.958C8.41748 12.0342 8.21436 12.085 8.01123 12.085C7.78271 12.085 7.57959 12.0342 7.40186 11.958L3.28857 10.0791L1.81592 10.7646C1.38428 10.9424 1.38428 11.5771 1.81592 11.7803L7.73193 14.4463C7.90967 14.5225 8.0874 14.5225 8.26514 14.4463L14.1812 11.7803C14.6128 11.5771 14.6128 10.9424 14.1812 10.7646Z"
        fill={fill}
      />
    </Icon>
  );
}
