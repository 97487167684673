import React from "react";
import { IconProps } from "./Icon";
import { colors } from "@puzzle/theme";

interface CircledIconProps extends IconProps {
  backgroundColor?: string;
  borderColor?: string;
}

export default function CircledIcon({
  width = 24,
  height = 24,
  backgroundColor = colors.gray500,
  borderColor,
  children,
}: CircledIconProps) {
  return (
    <div
      style={{
        width,
        height,
        backgroundColor,
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: borderColor ? `3px solid ${borderColor}` : "none",
      }}
    >
      {children}
    </div>
  );
}
