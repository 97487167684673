import React from "react";
import { IntegrationLogo } from "../../shared";
import { Scotia } from "@puzzle/icons";

const ScotiaLogo = () => {
  return (
    <IntegrationLogo color="#ED1B2E">
      <Scotia />
    </IntegrationLogo>
  );
};

export default ScotiaLogo;
