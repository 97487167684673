// I took bits of these two libraries and went from there to fit our needs.
// - dayzed: https://github.com/deseretdigital/dayzed
// - react-nice-dates: https://reactnicedates.hernansartorio.com/

// These date components aim for modularity.
// e.g. range selection logic can be built on top of a calendar.

// TODO Running list of missing features:
// * keyboard navigation
// * touch support?
// * update date input to use DatePopover
// * update reports to use DateRangePicker/DateRangePickerCalendar
// * tests

import * as RangePresets from "./rangePresets";
export { default as DateRangePickerCalendar } from "./DateRangePickerCalendar";
export type { RangeValue } from "./DateRangePickerCalendar";
export { default as DatePickerCalendar } from "./DatePickerCalendar";
export { default as DateRangePicker } from "./DateRangePicker";

// Not a fan of exposing these..
export * from "./utils";
export * from "./types";

export { RangePresets };
export const RangePresetLists = {
  DashboardReports: RangePresets.DashboardReportPresets,
  TopTransactions: RangePresets.TopTransactionPresets,
  Transactions: RangePresets.TransactionPresets,
  Revenue: RangePresets.RevenuePresets,
  AccrualRevenue: RangePresets.AccrualRevenuePresets,
  Reports: RangePresets.ReportPresets,
  ManualJournals: RangePresets.ManualJournalsPresets,
  Burn: RangePresets.BurnPresets,
  People: RangePresets.PeoplePresets,
  GeneralLedger: RangePresets.GeneralLedgerPresets,
  Default: RangePresets.DefaultPresets,
};
