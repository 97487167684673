import * as Types from '../types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
export type PendingInvitationFragment = { __typename?: 'CompanyInvitation', id: string, email: string, companyId: string, mfaRequired?: boolean | null, role: Types.MembershipRole, createdAt: CalendarDateTimeString, updatedAt: CalendarDateTimeString };

export const PendingInvitationFragmentDoc = gql`
    fragment pendingInvitation on CompanyInvitation {
  id
  email
  companyId
  mfaRequired
  role
  createdAt
  updatedAt
}
    `;