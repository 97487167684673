export const validItemName = (
  itemName: string,
  allItems: {
    name: string;
  }[]
): boolean => {
  const duplicateFound = allItems.find(
    (item) => item.name.toLowerCase() === itemName.toLowerCase()
  );

  return !duplicateFound;
};
