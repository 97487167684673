import React from "react";
import useBrex from "../brex/useBrexIntegration";
import Logo from "../brex/BrexLogo";
import IntegrationDetails from "./IntegrationDetails";
import { DetailProps } from "./types";

const BrexDetails = ({ companyId, integrationConnectionId, ...props }: DetailProps) => {
  const brex = useBrex({ companyId, integrationConnectionId });

  return (
    <IntegrationDetails
      logo={<Logo />}
      title="Brex"
      connection={brex.connection}
      onDisconnect={brex.disconnect}
      onReconnect={brex.onClickConnect}
      {...props}
    />
  );
};

export default BrexDetails;
