import useProducts from "components/common/hooks/useProducts";
import { useActiveCompany } from "components/companies";
import { ProductFragment } from "graphql/types";
import React, { useState } from "react";
import DropdownField from "../../../transactions/DropdownField";

interface ProductSelect {
  onSelect: (product: ProductFragment | null) => void;
  value?: ProductFragment | null;
  canEdit?: boolean;
  onCreateProduct: (name: string) => void;
  onBlur?: () => void;
  light?: boolean;
}

const ProductSelect = ({
  onSelect,
  canEdit = true,
  onCreateProduct,
  value,
  onBlur,
  light,
}: ProductSelect) => {
  const [filter, setFilter] = useState("");
  const { company } = useActiveCompany<true>();
  const { products, loading } = useProducts();

  return (
    <DropdownField<ProductFragment>
      items={products || []}
      loading={loading}
      filter={filter}
      setFilter={setFilter}
      onSelect={onSelect}
      canEdit={canEdit}
      onCreate={onCreateProduct}
      label="Pick a product/service plan"
      value={value}
      getOptionLabel={(v) => v?.name || ""}
      getFreeSoloOption={(name) => ({ name })}
      getEmptyOption={() => ({ name: "No product/service plan" })}
      onBlur={onBlur}
      light={light}
    />
  );
};

export default ProductSelect;
