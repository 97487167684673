import { useCallback } from "react";
import { savePageToReturnTo } from "lib/cookies";
import { useRouter } from "next/router";

/**
 * When the user is redirected back to puzzle after connecting an integration
 * they are always redirected to oauth/[type].page.tsx
 *
 * We need to store which place in the app to send users back to since they may connect
 * from many different places in the application.
 */
export default function useSavePage() {
  const router = useRouter();
  const savePage = useCallback(
    (route?: string) => {
      if (route) {
        savePageToReturnTo(route);
      } else if (router) {
        savePageToReturnTo(router.asPath);
      }
    },
    [router]
  );
  return {
    savePage,
  };
}
