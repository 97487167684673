import React from "react";
import useBrex from "./useBrexIntegration";
import { IntegrationsListItemBaseProps } from "../List";
import { IntegrationType } from "graphql/types";
import BrexLogo from "./BrexLogo";
import { IntegrationDisplayComponent } from "../shared/types";

interface BrexListItemProps {
  DisplayComponent: IntegrationDisplayComponent<
    React.PropsWithChildren<IntegrationsListItemBaseProps>
  >;
  companyId: string;
}
export const TITLE = "Brex";
export const SUBTITLE = "Credit Card";

const BrexListItem = ({ DisplayComponent, companyId }: BrexListItemProps) => {
  const { onClickConnect, connection, loading } = useBrex({ companyId });
  return (
    <DisplayComponent
      integrationType={IntegrationType.Brex}
      connectIntegration={onClickConnect}
      title={TITLE}
      // subtitle={SUBTITLE}
      connection={connection}
      logo={<BrexLogo />}
      loading={loading}
    />
  );
};

export default BrexListItem;
