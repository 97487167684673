import React, { useEffect } from "react";
import { styled, colors, Dialog, Button } from "@puzzle/ui";

import Stack from "components/common/Stack";
import { Star } from "@puzzle/icons";
import Link from "components/common/Link";
import { Route } from "lib/routes";
import Analytics from "lib/analytics";

const StarContainer = styled("div", {
  width: "min-content",
  margin: "0 auto $3",
});

const Question = styled("div", {
  textStyle: "$headingS",
  color: "$gray200",
});

const Answer = styled("div", {
  textStyle: "$bodyS",
  color: "$gray400",
});

const FAQItem = ({ question, answer }: { question: string; answer: React.ReactNode }) => {
  return (
    <Stack gap="1" css={{ marginBottom: "$2h" }}>
      <Question>{question}</Question>
      <Answer>{answer}</Answer>
    </Stack>
  );
};

const IntroductionStep = ({ onContinue }: { onContinue: () => void }) => {
  useEffect(() => {
    Analytics.categorizerIntroViewed();
  }, []);

  return (
    <>
      <Dialog.Body>
        <StarContainer>
          <Star size={54} color={colors.mauve300} />
        </StarContainer>

        <FAQItem
          question="What does this tool do?"
          answer={
            <>
              {" "}
              Puzzle has identified the top transactions that need your categorization. To see all
              categories, view the{" "}
              <Link underline href={Route.chartOfAccounts}>
                Chart of Accounts
              </Link>
              .
            </>
          }
        />

        <FAQItem
          question="Why is categorizing important?"
          answer="By categorizing your transactions, you can make your financial statements as accurate as possible."
        />

        <FAQItem
          question="What if I’m not sure which category to choose?"
          answer="You can skip a transaction or change a transaction’s category at anytime."
        />
      </Dialog.Body>
      <Dialog.Footer divider>
        <Button onClick={onContinue}>{"Let's go"}</Button>
      </Dialog.Footer>
    </>
  );
};

export default IntroductionStep;
