import React from "react";

/**
 *  Calls the provided callback on mount only
 */
export function useEffectOnFirstRender(cb: () => any) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(cb, []);
}

/**
 *  Calls the provided callback on mount only
 */
export function useEffectAfterMount(cb: () => any, dependencies: any[]) {
  const justMounted = React.useRef(true);
  React.useEffect(() => {
    if (!justMounted.current) {
      return cb();
    }
    justMounted.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}

/**
 *  Calls the provided callback when all in the dependency array are defined
 */
export function useEffectAfterDefined<T>(
  cb: (args: T) => any,
  dependency: T | undefined | null
): void {
  React.useEffect(() => {
    if (!dependency) return;
    return cb(dependency);
  }, [dependency, cb]);
}

/**
 *  Calls the provided callback when ref.current is defined
 *
 * ref requires special treatment because if ref.current is passed into useEffectAfterDefined,
 * react may not recognize that it needs to treat it as a variable to re-render when changed
 */
export function useEffectAfterRefDefined<T>(
  cb: (args: T) => any,
  ref: React.RefObject<T> | undefined | null
): void {
  React.useEffect(() => {
    if (!ref || !ref.current) return;
    return cb(ref.current);
  }, [ref, cb]);
}

/**
 *  A react effect that run only once in the provided time interval
 */
export function useDebouncedffect(cb: () => any, dependencies: any[], delay: number): void {
  React.useEffect(() => {
    const handler = setTimeout(() => {
      cb();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependencies]);
}
