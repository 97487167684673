import React from "react";
import Icon, { IconProps } from "./Icon";

export default function OpenBox({
  viewBox = "0 0 14 12",
  color = "currentColor",
  fill = color,
  ...iconProps
}: IconProps) {
  return (
    <Icon width={14} height={12} {...iconProps} viewBox={viewBox}>
      <path
        fill={fill}
        d="M13.9604 4.63148L12.8858 2.28526C12.8362 2.18101 12.7535 2.11142 12.6543 2.0593L7.13229 0.0260939C7.0496 -0.00869798 6.95042 -0.00869798 6.86773 0.0260939L1.36223 2.05942C1.26305 2.09422 1.18036 2.18114 1.13078 2.28539L0.0395646 4.63148C-0.0100257 4.73573 -0.0100258 4.87477 0.0230731 4.97902C0.0726634 5.10073 0.155352 5.18753 0.271021 5.22232L1.08117 5.51775V9.54965C1.08117 9.74082 1.19696 9.8972 1.36222 9.94933L6.86772 11.9827C6.91731 12 6.95041 12 7 12C7.04959 12 7.09918 12 7.13228 11.9827L12.6378 9.94933C12.8032 9.87987 12.9188 9.72337 12.9188 9.54965V5.53513L13.729 5.2397C13.8448 5.20491 13.9273 5.11799 13.9769 4.9964C14.01 4.87469 14.01 4.7531 13.9604 4.63138L13.9604 4.63148ZM6.99996 0.895014L11.2655 2.47652L6.99996 4.04056L2.73444 2.45905L6.99996 0.895014ZM1.70939 2.9978L6.43784 4.73569L5.71035 6.29973L0.981904 4.56184L1.70939 2.9978ZM1.90775 5.83059L5.79301 7.25572C5.8426 7.27306 5.8757 7.27306 5.92529 7.27306C6.07406 7.27306 6.22283 7.18614 6.28902 7.02976L6.58656 6.36933V10.9574L1.9077 9.21947L1.90775 5.83059ZM12.0921 9.25423L7.41325 10.9921V6.38671L7.71079 7.04714C7.77687 7.20352 7.92575 7.29044 8.07451 7.29044C8.1241 7.29044 8.17369 7.29044 8.20679 7.27311L12.0921 5.84797L12.0921 9.25423ZM8.2895 6.31717L7.56202 4.75313L12.2905 3.01524L13.018 4.57928L8.2895 6.31717Z"
      ></path>
    </Icon>
  );
}
