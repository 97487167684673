import React from "react";
import { IntegrationLogo } from "../../shared";
import { CIBC } from "@puzzle/icons";

const CIBCLogo = () => {
  return (
    <IntegrationLogo color="#A80232">
      <CIBC />
    </IntegrationLogo>
  );
};

export default CIBCLogo;
