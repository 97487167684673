// NOTE: Each term's search result ends up in the Apollo cache.
// The list of vendors points to references so it's generally small,

import { useEffect, useMemo } from "react";
import { useDebounce } from "use-debounce";
import { getFetchPolicyForKey, ONE_HOUR } from "apollo/getFetchPolicyForKey";
import { VendorSortOrder } from "graphql/types";
import { useVendors } from "components/common/hooks/vendors";

// but still could be worth expiring.
const CachedTerms = new Set<string>();

export const useVendorSearch = (filterInput: string) => {
  const [debouncedFilter] = useDebounce(filterInput, 500);

  // If a search input was already cached, immediately bring it up instead of waiting on debouncing
  const filter = useMemo(
    () => (CachedTerms.has(filterInput) ? filterInput : debouncedFilter),
    [debouncedFilter, filterInput]
  );

  const result = useVendors({
    // This refetches in case new ones were added
    fetchPolicy: filter ? "cache-and-network" : getFetchPolicyForKey("topVendors", ONE_HOUR),

    variables: {
      filterBy: {
        name: filter,
      },
      sortBy: filter ? VendorSortOrder.NameAsc : VendorSortOrder.PopularityDesc,
    },
  });

  useEffect(() => {
    if (!result.loading) {
      CachedTerms.add(filter);
    }
  }, [filter, result.loading]);

  const loading = Boolean(
    (result.loading && !result.data) ||
      (filterInput && filterInput !== result.variables?.filterBy?.name)
  );

  return useMemo(
    () => ({
      ...result,
      loading,
    }),
    [loading, result]
  );
};
