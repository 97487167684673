import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Copy({
  viewBox = "0 0 16 17",
  color = "currentColor",
  ...iconProps
}: IconProps) {
  return (
    <Icon {...iconProps} viewBox={viewBox}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M6 7.55a1.5 1.5 0 011.5-1.5h5a1.5 1.5 0 011.5 1.5v5a1.5 1.5 0 01-1.5 1.5h-5a1.5 1.5 0 01-1.5-1.5v-5zm1.5-.5a.5.5 0 00-.5.5v5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-5a.5.5 0 00-.5-.5h-5z"
        clipRule="evenodd"
      ></path>
      <path
        fill={color}
        fillRule="evenodd"
        d="M2 3.55a1.5 1.5 0 011.5-1.5h5a1.5 1.5 0 011.5 1.5v1a.5.5 0 11-1 0v-1a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5v5a.5.5 0 00.5.5h1a.5.5 0 010 1h-1A1.5 1.5 0 012 8.55v-5z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
}
