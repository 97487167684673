import * as Types from '../types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
export type CompanyFragment = { __typename?: 'Company', id: string, name: string, type: Types.CompanyType, createdAt: CalendarDateTimeString, onboardingStage: Types.OnboardingStage, uniquePathname: string, timeZone?: string | null, trackingId?: string | null, revenueModel?: Types.RevenueModel | null };

export const CompanyFragmentDoc = gql`
    fragment company on Company {
  id
  name
  type
  createdAt
  onboardingStage
  uniquePathname
  timeZone
  trackingId
  revenueModel
}
    `;