import React, { useRef, useCallback, useMemo } from "react";
import { shallow } from "zustand/shallow";
import { FeedItem } from "@knocklabs/client/dist/types";
import { differenceInHours } from "date-fns";
import { parseAbsoluteToLocal } from "@internationalized/date";

import { styled, colors, Text, Avatar, Button, Tooltip } from "@puzzle/ui";
import { Exclamation, Confirmed, Close } from "@puzzle/icons";
import { useLocalDateFormatter } from "@puzzle/utils";

import Analytics from "lib/analytics";

import { TaskType, IntegrationStatus, TaskItemContent } from "../shared";
import { useInboxContext, useInboxStore } from "../InboxContext";

const ItemRoot = styled("div", {
  position: "relative",
  display: "flex",
  cursor: "pointer",
  padding: "$2 $1h",
  alignItems: "center",
  borderBottom: "1px solid $mauve680",

  "&:last-child": {
    borderBottom: 0,
  },

  [`${Close}`]: {
    visibility: "hidden",
  },

  "&:hover": {
    backgroundColor: "$mauve680",
    borderRadius: "$1",

    [`${Close}`]: {
      visibility: "visible",
    },
  },
});

const Item = ({
  onArchive,
  item,
}: React.PropsWithChildren<
  React.ComponentProps<typeof ItemRoot> & {
    onArchive?: () => void;
    item: FeedItem<TaskItemContent>;
  }
>) => {
  const actor = item.actors?.[0];
  const dateFormatter = useLocalDateFormatter({ month: "short", day: "numeric" });
  const timeFormatter = useLocalDateFormatter({ timeStyle: "short" });
  const insertedAtDate = parseAbsoluteToLocal(item.inserted_at);

  const handleClick = useCallback(() => {
    Analytics.notificationClicked({
      id: item.id,
      isUnread: !item.seen_at,
      source: item.source.key,
      messageType: item.data?.messageType,
      tab: "updates",
      createdHoursAgo: differenceInHours(new Date(), new Date(item.inserted_at)),
    });

    const data = item.data;

    if (!data) {
      return;
    }

    if (
      (data.messageType === TaskType.Integration ||
        data.messageType === TaskType.IntegrationWarning) &&
      data.integrationType
    ) {
      window.open(`/integrations/${data.integrationType.toLowerCase()}/${data.integrationId}`);
    } else if (data.messageType === TaskType.Transaction && data.transactionId) {
      window.open(`/transactions/${data.transactionId}?assignedToMe=true`);
    } else if (data.messageType === TaskType.OpeningBalance && data.accountId) {
      window.open(`/accounting/reconciliation/start/${data.accountId}`);
    }
  }, [item]);

  return (
    <ItemRoot onClick={() => handleClick()}>
      <ItemAvatar>
        {item.data?.messageType === TaskType.Transaction && "email" in actor ? (
          <Avatar
            user={{
              email: actor.email || undefined,
              name: actor.name || "Unknown",
            }}
          />
        ) : item.data?.messageType === TaskType.Integration ? (
          <Exclamation
            fill={
              item.data?.integrationStatus === IntegrationStatus.Disconnected
                ? colors.red500
                : colors.yellow600
            }
            width={28}
            height={28}
          />
        ) : (
          <Exclamation fill={colors.red500} width={28} height={28} />
        )}
      </ItemAvatar>
      <Detail>
        <Body
          dangerouslySetInnerHTML={{
            __html: item.blocks?.[0].rendered,
          }}
        />
        <Timestamp>
          {dateFormatter.format(insertedAtDate)} at {timeFormatter.format(insertedAtDate)}
        </Timestamp>
      </Detail>

      {onArchive && (
        <Tooltip content="Archive" arrow={false} side="right">
          <Button
            variant="minimal"
            css={{
              alignSelf: "start",
              padding: "$0h",
              marginRight: "$0h",
              height: "auto",
              "*": { lineHeight: 0 },
            }}
          >
            <Close
              size={12}
              onClick={(e) => {
                e.stopPropagation();
                onArchive();
              }}
              css={{}}
            />
          </Button>
        </Tooltip>
      )}
    </ItemRoot>
  );
};

const Detail = styled("div", {
  paddingLeft: "$3",

  "& p": {
    margin: 0,
  },
});

const Body = styled("div", {
  color: "$gray300",
  textVariant: "$bodyXS",
});

const Timestamp = styled("div", {
  color: "$gray400",
  textVariant: "$bodyXS",
  marginTop: "4px",
});

const ItemAvatar = styled("div", {});

const NoTasksWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  textAlign: "center",
  paddingTop: "$3",
});

const TaskList = ({ archived = false }: { archived?: boolean }) => {
  const feed = archived ? "archived" : "tasks";

  const { feeds } = useInboxContext();
  const feedClient = archived ? feeds.archived : feeds.tasks;
  const { loading, items, pageInfo, totalItems } = useInboxStore(
    feed,
    (state) => ({
      loading: state.loading,
      items: state.items as FeedItem<TaskItemContent>[],
      pageInfo: state.pageInfo,
      totalItems: state.metadata.total_count,
    }),
    shallow
  );

  const observer = useRef<IntersectionObserver | null>(null);

  const infiniteScrollAnchor = useCallback(
    (node: Element) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
        if (entries[0].isIntersecting) {
          if (pageInfo.after) {
            feedClient?.fetch({
              after: pageInfo.after,
            });
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, pageInfo.after, feedClient]
  );

  const content = useMemo(() => {
    if (totalItems === 0) {
      return (
        <NoTasksWrapper>
          {!archived ? (
            <>
              <Confirmed size={44} />
              <Text variant="headingS" color="gray50" css={{ marginTop: "$4" }}>
                You're all caught up!
              </Text>
              <Text variant="bodyS" color="gray400" css={{ marginTop: "$1", marginBottom: "$2" }}>
                When there are tasks that need your attention, you'll see them here.
              </Text>
            </>
          ) : (
            <Text variant="bodyS" color="gray400" css={{ marginTop: "$1", marginBottom: "$2" }}>
              Nothing has been archived yet.
            </Text>
          )}
        </NoTasksWrapper>
      );
    }

    return (
      <>
        {items.map((item) => (
          <Item
            item={item}
            key={item.id}
            onArchive={
              archived
                ? undefined
                : () => {
                    feedClient?.markAsArchived(item);
                  }
            }
          />
        ))}

        {pageInfo.after && !loading && items.length < totalItems && (
          <Text
            variant="bodyS"
            color="gray200"
            css={{
              marginTop: "$2",
              textAlign: "center",
              display: "block",
            }}
            // @ts-expect-error passing void callback as ref
            ref={infiniteScrollAnchor}
          >
            Keep scrolling to see more tasks
          </Text>
        )}
      </>
    );
  }, [archived, feedClient, infiniteScrollAnchor, items, loading, pageInfo.after, totalItems]);

  return content;
};

export default TaskList;
