import { AccountType } from "graphql/types";
import { invertBy } from "lodash";

export enum SelectableAccountTypes {
  CreditCards = "credit_cards",
  BankAndFintech = "bank_accounts",
  PaymentProcessors = "payment_processors",
  InvestmentAccounts = "investment_accounts",
  Loan = "loan_accounts",
  Other = "other_accounts",
}

export const accountTypeLabels = {
  [SelectableAccountTypes.CreditCards]: "Credit cards",
  [SelectableAccountTypes.BankAndFintech]: "Bank accounts",
  [SelectableAccountTypes.PaymentProcessors]: "Payment processors",
  [SelectableAccountTypes.InvestmentAccounts]: "Investment accounts",
  [SelectableAccountTypes.Loan]: "Loan accounts",
  [SelectableAccountTypes.Other]: "Other accounts",
};

export const accountTypeOptions = Object.entries(accountTypeLabels).map(([value, label]) => ({
  value,
  label,
}));

export const accountTypeToSelectableAccountType: Partial<
  Record<AccountType, SelectableAccountTypes>
> = {
  [AccountType.Depository]: SelectableAccountTypes.BankAndFintech,
  [AccountType.Loan]: SelectableAccountTypes.Loan,
  [AccountType.Investment]: SelectableAccountTypes.InvestmentAccounts,
  [AccountType.PaymentProcessing]: SelectableAccountTypes.PaymentProcessors,
  [AccountType.Other]: SelectableAccountTypes.Other,
  [AccountType.Credit]: SelectableAccountTypes.CreditCards,
};

export const selectableAccountTypeToAccountTypes = (v: string): AccountType[] => {
  return invertBy(accountTypeToSelectableAccountType)[v] as AccountType[];
};
