import { colors } from "@puzzle/theme";
import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Confirmed({
  className,
  rotate,
  size = 16,
  viewBox = "0 0 16 16",
  // TODO currentColor
  color = colors.greenalpha,
  fill = color,
  stroke,
  ...props
}: IconProps) {
  return (
    <Icon className={className} rotate={rotate} viewBox={viewBox} size={size} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM12.0575 6.50173C12.3346 6.19385 12.3096 5.71963 12.0017 5.44254C11.6938 5.16544 11.2196 5.1904 10.9425 5.49828L7.0218 9.85464L5.07615 7.51987C4.81098 7.20166 4.33806 7.15867 4.01985 7.42384C3.70164 7.68901 3.65865 8.16194 3.92382 8.48014L6.42382 11.4801C6.56312 11.6473 6.76816 11.7457 6.98571 11.7499C7.20327 11.754 7.4119 11.6635 7.55746 11.5017L12.0575 6.50173Z"
        fill={fill}
        stroke={stroke}
      />
    </Icon>
  );
}
