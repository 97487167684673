import { useActiveCompany } from "../../companies";
import {
  FinancialInstitutionFragment,
  IntegrationType,
  useFinancialInstitutionsQuery,
} from "graphql/types";
import useSelf from "../../users/useSelf";
import { useFinancialInstitutions } from "./FinancialInstitutionsProvider";
import { useCallback, useEffect, useMemo } from "react";
import { usePendingConnections } from "./PendingConnectionsProvider";

const POLL_INTERVAL = 10 * 1000;

export interface UsePollIntegrationResult {
  pollInterval?: number;
  financialInstitutions: FinancialInstitutionFragment[];
  accounts: FinancialInstitutionFragment["accounts"];
  refetch: () => void;
  startPolling: (interval: number) => void;
  stopPolling: () => void;
  loading: boolean;
}

export const usePollIntegration = (
  integrationType: IntegrationType,
  refetchAfterPolling: boolean,
  automaticallyStopPolling: boolean = true
): UsePollIntegrationResult => {
  const { self } = useSelf();
  const { refetchAccountsAndIntegrations } = useFinancialInstitutions();
  const { company, completedOnboarding } = useActiveCompany<true>();
  const { addPendingConnection, removePendingConnection } = usePendingConnections();

  // poll during onboarding to ensure all the financial institutions are shown once connected
  const pollInterval = completedOnboarding ? undefined : POLL_INTERVAL;

  const {
    data,
    loading,
    refetch,
    startPolling: startPollingQuery,
    stopPolling: stopPollingQuery,
  } = useFinancialInstitutionsQuery({
    variables: { companyId: company.id, integrationType },
    pollInterval,
  });

  const financialInstitutions = (self && data?.financialInstitutionsFor) || [];

  const accounts = useMemo(
    () =>
      financialInstitutions
        .map((f) => {
          return f.accounts;
        })
        .flat(),
    [financialInstitutions]
  );

  const startPolling = useCallback(
    (interval: number) => {
      startPollingQuery(interval);
      addPendingConnection(integrationType);
    },
    [addPendingConnection, integrationType, startPollingQuery]
  );

  const stopPolling = useCallback(() => {
    stopPollingQuery();
    removePendingConnection(integrationType);
    if (refetchAfterPolling) {
      refetchAccountsAndIntegrations();
    }
  }, [
    integrationType,
    refetchAccountsAndIntegrations,
    refetchAfterPolling,
    removePendingConnection,
    stopPollingQuery,
  ]);

  useEffect(() => {
    if (automaticallyStopPolling && financialInstitutions.length > 0) {
      stopPolling();
    }
  }, [
    financialInstitutions,
    refetchAfterPolling,
    stopPolling,
    refetchAccountsAndIntegrations,
    automaticallyStopPolling,
  ]);

  return {
    pollInterval,
    financialInstitutions,
    accounts,
    refetch,
    startPolling,
    stopPolling,
    loading,
  };
};
