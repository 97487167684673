import React from "react";

export const OpenAISharing = (props: React.PropsWithChildren<unknown>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="415"
      height="166"
      fill="none"
      viewBox="0 0 415 166"
      {...props}
    >
      <rect width="415" height="166" fill="url(#paint0_linear_1047_8490)" rx="8"></rect>
      <rect width="415" height="166" fill="#000" fillOpacity="0.2" rx="8"></rect>
      <path
        fill="#50FAAB"
        d="M85 118c17.673 0 32-14.327 32-32 0-17.673-14.327-32-32-32-17.673 0-32 14.327-32 32 0 17.673 14.327 32 32 32z"
      ></path>
      <path
        fill="#fff"
        d="M80.2 66.8l-9.6 9.6h9.6V86l-9.6 9.6h9.6v9.6l9.6-9.6h-9.6V86l9.6-9.6h-9.6v-9.6z"
      ></path>
      <path
        fill="#211F35"
        fillRule="evenodd"
        d="M89.8 95.6a14.4 14.4 0 000-28.8h-9.6v9.6h-9.6V86h9.6v9.6h-9.6v9.6h9.6v-9.6h9.6zm0-9.6v-9.6h-9.6V86h9.6z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillOpacity="0.4"
        d="M140.293 85.293a1 1 0 000 1.414l6.364 6.364a1 1 0 101.414-1.414L142.414 86l5.657-5.657a1 1 0 10-1.414-1.414l-6.364 6.364zm135.414 1.414a1 1 0 000-1.414l-6.364-6.364a1 1 0 10-1.414 1.414L273.586 86l-5.657 5.657a1 1 0 101.414 1.414l6.364-6.364zM141 87h1.971v-2H141v2zm5.912 0h3.941v-2h-3.941v2zm7.882 0h3.941v-2h-3.941v2zm7.882 0h3.942v-2h-3.942v2zm7.883 0h3.941v-2h-3.941v2zm7.882 0h3.941v-2h-3.941v2zm7.883 0h3.941v-2h-3.941v2zm7.882 0h3.941v-2h-3.941v2zm7.882 0h3.941v-2h-3.941v2zm7.883 0h3.941v-2h-3.941v2zm7.882 0h3.941v-2h-3.941v2zm7.882 0h3.941v-2h-3.941v2zm7.883 0h3.941v-2h-3.941v2zm7.882 0h3.941v-2H241.5v2zm7.882 0h3.942v-2h-3.942v2zm7.883 0h3.941v-2h-3.941v2zm7.882 0h3.941v-2h-3.941v2zm7.882 0H275v-2h-1.971v2z"
      ></path>
      <rect width="64" height="64" x="299" y="54" fill="#fff" rx="32"></rect>
      <g clipPath="url(#clip0_1047_8490)">
        <path
          fill="#000"
          d="M349.85 82.005a10.972 10.972 0 00-.945-9.003 11.086 11.086 0 00-11.935-5.316 11.117 11.117 0 00-15.698-.84 11.118 11.118 0 00-3.141 4.82 10.974 10.974 0 00-7.329 5.317 11.086 11.086 0 001.362 13.01 10.953 10.953 0 00.937 9.004 11.088 11.088 0 0011.943 5.316A10.979 10.979 0 00333.31 108a11.104 11.104 0 0010.581-7.711 10.966 10.966 0 007.329-5.316 11.1 11.1 0 00-1.37-12.967zm-16.54 23.115a8.203 8.203 0 01-5.274-1.908l.26-.147 8.761-5.057a1.455 1.455 0 00.72-1.25v-12.35l3.703 2.142a.132.132 0 01.07.096V96.88a8.254 8.254 0 01-8.24 8.24zm-17.712-7.563a8.203 8.203 0 01-.98-5.525l.261.156 8.769 5.057a1.408 1.408 0 001.431 0l10.711-6.176v4.276a.155.155 0 01-.017.064.152.152 0 01-.043.049l-8.873 5.117a8.249 8.249 0 01-11.259-3.018zm-2.307-19.082a8.227 8.227 0 014.337-3.617v10.409a1.41 1.41 0 00.711 1.24l10.66 6.15-3.703 2.142a.14.14 0 01-.131 0l-8.855-5.109a8.26 8.26 0 01-3.019-11.258v.043zm30.427 7.07l-10.694-6.211 3.694-2.134a.14.14 0 01.131 0l8.855 5.117a8.243 8.243 0 014.093 7.823 8.234 8.234 0 01-5.333 7.035V86.767a1.447 1.447 0 00-.746-1.223zm3.686-5.543l-.26-.156-8.752-5.1a1.419 1.419 0 00-1.439 0L326.25 80.92v-4.276a.116.116 0 01.01-.063.122.122 0 01.042-.05l8.855-5.108a8.25 8.25 0 0112.247 8.543v.035zm-23.175 7.58l-3.704-2.133a.153.153 0 01-.07-.105V75.136a8.252 8.252 0 019.301-8.17c1.553.2 3.017.837 4.222 1.838l-.261.148-8.76 5.056a1.455 1.455 0 00-.72 1.25l-.008 12.324zm2.012-4.336l4.77-2.75 4.779 2.75v5.499l-4.761 2.749-4.779-2.75-.009-5.498z"
        ></path>
      </g>
      <path
        fill="#736285"
        d="M223.789 32.437l-15.375-3.417a.87.87 0 00-.37 0l-15.375 3.417a.85.85 0 00-.669.834v17.083a16.231 16.231 0 0016.229 16.23 16.231 16.231 0 0016.229-16.23V33.271a.858.858 0 00-.669-.834zm-7.872 23.042a.852.852 0 01-.855.855h-13.666a.853.853 0 01-.854-.855v-8.541a.855.855 0 01.854-.855h2.562v-4.27a4.27 4.27 0 018.542 0v4.27h2.562a.853.853 0 01.855.855v8.541z"
      ></path>
      <path
        fill="url(#paint1_linear_1047_8490)"
        fillOpacity="0.4"
        d="M223.789 32.437l-15.375-3.417a.87.87 0 00-.37 0l-15.375 3.417a.85.85 0 00-.669.834v17.083a16.231 16.231 0 0016.229 16.23 16.231 16.231 0 0016.229-16.23V33.271a.858.858 0 00-.669-.834zm-7.872 23.042a.852.852 0 01-.855.855h-13.666a.853.853 0 01-.854-.855v-8.541a.855.855 0 01.854-.855h2.562v-4.27a4.27 4.27 0 018.542 0v4.27h2.562a.853.853 0 01.855.855v8.541z"
      ></path>
      <path
        fill="#736285"
        d="M208.229 39.25a2.563 2.563 0 00-2.562 2.563v4.27h5.124v-4.27a2.56 2.56 0 00-2.562-2.563z"
      ></path>
      <path
        fill="url(#paint2_linear_1047_8490)"
        fillOpacity="0.4"
        d="M208.229 39.25a2.563 2.563 0 00-2.562 2.563v4.27h5.124v-4.27a2.56 2.56 0 00-2.562-2.563z"
      ></path>
      <path
        fill="#736285"
        d="M208.229 52.917a1.709 1.709 0 100-3.417 1.709 1.709 0 000 3.417z"
      ></path>
      <path
        fill="url(#paint3_linear_1047_8490)"
        fillOpacity="0.4"
        d="M208.229 52.917a1.709 1.709 0 100-3.417 1.709 1.709 0 000 3.417z"
      ></path>
      <path
        fill="#736285"
        d="M235.638 113c-1.599.108-7.042.641-14.085.641-9.924 0-10.565 3.521-13.554 3.521-3.413 0-3.199-3.521-13.553-3.521-7.042 0-12.486-.533-14.085-.641-1.152-.077.427 11.311 5.122 15.58 3.554 3.231 12.166 5.978 18.889 1.493 2.562-2.025 3.627-2.025 3.627-2.025s1.07 0 3.63 2.025c6.721 4.485 15.335 1.738 18.888-1.493 4.694-4.269 6.275-15.657 5.121-15.58zm-33.496 9.636c-4.994 5.569-13.158 1.728-13.253-3.844 9.218-1.439 13.615 3.439 13.253 3.844zm11.715 0c-.362-.405 4.034-5.283 13.254-3.844-.096 5.572-8.258 9.413-13.254 3.844z"
      ></path>
      <path
        fill="url(#paint4_linear_1047_8490)"
        fillOpacity="0.4"
        d="M235.638 113c-1.599.108-7.042.641-14.085.641-9.924 0-10.565 3.521-13.554 3.521-3.413 0-3.199-3.521-13.553-3.521-7.042 0-12.486-.533-14.085-.641-1.152-.077.427 11.311 5.122 15.58 3.554 3.231 12.166 5.978 18.889 1.493 2.562-2.025 3.627-2.025 3.627-2.025s1.07 0 3.63 2.025c6.721 4.485 15.335 1.738 18.888-1.493 4.694-4.269 6.275-15.657 5.121-15.58zm-33.496 9.636c-4.994 5.569-13.158 1.728-13.253-3.844 9.218-1.439 13.615 3.439 13.253 3.844zm11.715 0c-.362-.405 4.034-5.283 13.254-3.844-.096 5.572-8.258 9.413-13.254 3.844z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_1047_8490"
          x1="117.77"
          x2="325.261"
          y1="15.918"
          y2="367.779"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#33334D"></stop>
          <stop offset="1" stopColor="#403C5B"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_1047_8490"
          x1="204.5"
          x2="220"
          y1="42"
          y2="72.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B0A5FF"></stop>
          <stop offset="1" stopColor="#736285" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_1047_8490"
          x1="207.64"
          x2="210.682"
          y1="41.614"
          y2="46.811"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B0A5FF"></stop>
          <stop offset="1" stopColor="#736285" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_1047_8490"
          x1="207.837"
          x2="209.121"
          y1="50.682"
          y2="53.607"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B0A5FF"></stop>
          <stop offset="1" stopColor="#736285" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_1047_8490"
          x1="201.566"
          x2="204.517"
          y1="119.719"
          y2="139.103"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B0A5FF"></stop>
          <stop offset="1" stopColor="#736285" stopOpacity="0"></stop>
        </linearGradient>
        <clipPath id="clip0_1047_8490">
          <path fill="#fff" d="M0 0H44V44H0z" transform="translate(309 64)"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
