import React from "react";
import Icon, { IconProps } from "../Icon";

export default function Brex({ viewBox = "0 0 48 48", size = 48, ...iconProps }: IconProps) {
  return (
    <Icon size={size} {...iconProps} viewBox={viewBox}>
      <rect width="48" height="48" rx="24" fill="#171717" />
      <path
        d="M27.5904 15.6C26.6524 16.9 26.4257 17.3331 25.5202 17.3331H12V34H17.3047C18.5014 34 19.6656 33.4 20.4096 32.4C21.3802 31.0669 21.5092 30.6669 22.4473 30.6669H36V14H30.6627C29.466 14 28.3018 14.6 27.5898 15.6H27.5904ZM31.7949 26.3669H26.1345C24.9052 26.3669 23.7736 26.9 23.0297 27.9331C22.059 29.2669 21.8649 29.6669 20.9594 29.6669H16.1725V21.6669H21.833C23.0616 21.6669 24.1938 21.0669 24.9378 20.0669C25.9084 18.7669 26.167 18.3669 27.04 18.3669H31.7949V26.3669Z"
        fill="#F8F8FA"
      />
    </Icon>
  );
}
