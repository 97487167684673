import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Unmatch({ viewBox = "0 0 16 16", ...iconProps }: IconProps) {
  return (
    <Icon {...iconProps} viewBox={viewBox}>
      <mask
        id="mask0_110_14419"
        style={{ maskType: "alpha" }}
        width="16"
        height="16"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0H16V16H0z"></path>
      </mask>
      <g mask="url(#mask0_110_14419)">
        <path
          fill="#B3B2B8"
          d="M10.183 8.467L9.1 7.4h.695c.172 0 .315.058.431.173A.578.578 0 0110.4 8c0 .1-.022.189-.067.267a.726.726 0 01-.166.2h.016zm3.05 5.6a.583.583 0 01-.85 0L1.917 3.617a.583.583 0 010-.85.583.583 0 01.85 0l10.466 10.466a.574.574 0 01.184.417.574.574 0 01-.184.417zM4.8 11.2c-.885 0-1.64-.312-2.264-.936A3.084 3.084 0 011.6 8c0-.71.211-1.343.633-1.9.423-.555 1.023-.994 1.8-1.316L5.2 6h-.383c-.567 0-1.045.195-1.434.583A1.929 1.929 0 002.8 8c0 .556.194 1.028.583 1.417.39.389.861.583 1.417.583h1.8c.17 0 .312.057.427.172a.576.576 0 01.173.425c0 .169-.058.312-.173.428a.577.577 0 01-.427.175H4.8zm1.398-2.6a.583.583 0 01-.423-.171.571.571 0 01-.175-.425c0-.17.057-.312.172-.429A.576.576 0 016.2 7.4h.333l1.184 1.2H6.198zm5.952 1.867a.588.588 0 01-.092-.442.543.543 0 01.242-.375c.278-.189.497-.428.658-.716.161-.29.242-.6.242-.934 0-.555-.194-1.028-.583-1.417A1.929 1.929 0 0011.2 6H9.4a.582.582 0 01-.428-.171.575.575 0 01-.172-.425c0-.17.057-.312.172-.429A.576.576 0 019.4 4.8h1.8c.885 0 1.64.312 2.264.936.624.624.936 1.379.936 2.264a3.07 3.07 0 01-.383 1.502 3.12 3.12 0 01-1.05 1.131.56.56 0 01-.442.084.572.572 0 01-.375-.25z"
        ></path>
      </g>
    </Icon>
  );
}
