import React from "react";
import * as Collapsible from "@radix-ui/react-collapsible";
import { keyframes, styled } from "@puzzle/theme";

const open = keyframes({
  from: { height: 0 },
  to: { height: "var(--radix-collapsible-content-height)" },
});

const close = keyframes({
  from: { height: "var(--radix-collapsible-content-height)" },
  to: { height: 0 },
});

const Content = styled(Collapsible.Content, {
  overflow: "clip",

  variants: {
    animate: {
      true: {
        '&[data-state="open"]': { animation: `${open} 300ms cubic-bezier(0.4, 0, 0.2, 1)` },
        '&[data-state="closed"]': { animation: `${close} 300ms cubic-bezier(0.4, 0, 0.2, 1)` },
      },
      false: {},
    },
  },

  // TODO something about the animation causes the background to flicker on certain displays; these didn't work
  // backfaceVisibility: "hidden",
  // transform: "translateZ(0)",
});

export const Collapse = ({
  children,
  trigger,
  animate = true,
  ...props
}: Collapsible.CollapsibleProps & {
  trigger?: React.ReactElement;
  animate?: boolean;
}) => {
  return (
    <Collapsible.Root {...props}>
      {trigger && <Collapsible.Trigger asChild>{trigger}</Collapsible.Trigger>}

      <Content animate={animate}>{children}</Content>
    </Collapsible.Root>
  );
};
