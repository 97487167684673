import React, { useCallback, useMemo, useState, useEffect } from "react";
import { SortingRule, TableState, UseExpandedRowProps, UseTableRowProps } from "react-table";
import { useQueryState } from "next-usequerystate";
import { CalendarDate } from "@internationalized/date";
import { pluralize, capitalize } from "@puzzle/utils";
import { styled } from "@puzzle/ui";
import { useCompanyDateFormatter } from "components/companies";
import DataGrid from "ve/DataGrid/DataGrid";
import { AccountTransactionDetailFragment } from "./graphql.generated";
import { LedgerAccountFragment } from "../shared/chartOfAccounts.graphql.generated";
import { glAmountToDisplayString } from "./LedgerAccountViewBody";
import { getAccountLabel } from "../shared/utils";
import { LedgerAccountTransactionJournal } from "./LedgerAccountTransactionJournal";
import { createMRTColumnHelper, MRT_Row } from "material-react-table";
import { tableBodyCellRecipe, tableHeaderCellRecipe } from "ve/DataGrid/DataGrid.css";

const MutedCell = styled("div", {
  color: "$gray500",
});

const ValueCell = styled("div", {
  textAlign: "right",
});

const AccountBody = styled("div", {
  width: "100%",
  paddingTop: "$3",
  height: "$3",
});

const AccountEntriesCountRow = styled("div", {
  textAlign: "right",
  width: "100%",
  paddingTop: "$1",
  paddingBottom: "$1",
  height: "$3",
});

const AccountRow = styled("div", {
  display: "inline-flex",
  justifyContent: "space-between",
  background: "$mauve850",
  color: "$gray400",
  width: "100%",
  padding: "$1",
  height: "$5",
});

const TotalText = styled("div", {
  fontStyle: "italic",
  display: "inline-flex",
});

const NumberText = styled("div", {
  fontStyle: "italic",
  display: "inline-flex",
  marginLeft: "$2",
});

const TableContainer = styled("div", {
  "tfoot tr td": {
    color: "$gray400",
  },
});

const EventTypes: Partial<Record<string, string>> = {
  bank_transaction: "Bank transaction",
  credit_card: "Credit card",
  credit_card_transaction: "Credit card transaction",
  payroll: "Payroll",
  managed_platform_shortfall_collection: "Managed platform shortfall collection",
  new_account: "New account",
  new_company: "New company",
  new_entity: "New entity",
  new_integration_bank: "New integration bank",
  new_integration_credit_card: "New integration credit card",
  new_integration_investment: "New integration investment",
  new_integration_payroll: "New integration payroll",
  new_integration_payment_processor: "New integration payment processor",
  payment_available: "Payment available",
  payment_processor_balance_adjustment: "Payment processor balance adjustment",
  payment_processor_dispute_opened: "Payment processor dispute opened",
  payment_processor_dispute_won: "Payment processor dispute won",
  payment_processor_dispute_lost: "Payment processor dispute lost",
  payment_processor_fee_claimed: "Payment processor fee claimed",
  payment_processor_reserve_funds_adjustment: "Payment processor reserve funds adjustment",
  payment_processor_settlement_initiated: "Payment processor settlement initiated",
  payment_processor_settlement_linked: "Payment processor settlement linked",
  payment_processor_fallback: "Payment processor fallback",
  refund_available: "Refund available",
  payment_failure_refund_available: "Payment failure refund available",
  credit_card_payment_linked: "Credit card payment linked",
  beginning_balance: "Beginning balance",
  payroll_linked: "Payroll linked",
  investment_transaction: "Investment transaction",
  set_balance: "Set balance",
  delete_event: "Delete event",
  contractor_pay: "Contractor pay",
  user_beginning_balance: "User beginning balance",
  manual_journal_entry: "Manual journal entry",
  historical_journal_entry: "Historical journal entry",
  bill_received: "Bill", // todo: is this what we want?
  bill_voided: "Bill voided", // todo: is this what we want?
};

const convertEventTypeToString = (eventType: string): string => {
  return EventTypes[eventType] || capitalize(eventType.replace(/_/g, " "));
};

interface LedgerAccountDataTableRow
  extends UseExpandedRowProps<AccountTransactionDetailFragment>,
    AccountTransactionDetailFragment,
    UseTableRowProps<AccountTransactionDetailFragment> {
  credit: string;
  debit: string;
}

interface LedgerAccountTableProps {
  startDate?: CalendarDate;
  endDate?: CalendarDate;
  idx: number;
  account: LedgerAccountFragment;
  numEntries: number;
  accountTotals: Record<string, number>;
  eventsDataByAccDisplayId: Record<string, AccountTransactionDetailFragment[]>;
  chartOfAccountsById: Record<string, LedgerAccountFragment>;
}

const LedgerAccountMRT = ({
  startDate,
  endDate,
  idx,
  account,
  numEntries,
  accountTotals,
  eventsDataByAccDisplayId,
  chartOfAccountsById,
}: LedgerAccountTableProps) => {
  const dateFormatter = useCompanyDateFormatter({
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  const shortDateFormatter = useCompanyDateFormatter({
    dateStyle: "medium",
  });
  const [sortBy, setSortBy] = useState<SortingRule<never>[]>([]);
  const [journalIdQueryParam] = useQueryState("journalId");
  const dataRows = eventsDataByAccDisplayId[account.displayId];

  useEffect(() => {
    if (journalIdQueryParam) {
      const hasMatchingJournal = dataRows.find(({ journal }) => journal.id === journalIdQueryParam);
      if (hasMatchingJournal) {
        const t = setTimeout(() => {
          document
            .querySelector(`[data-journal-id="${journalIdQueryParam}"]`)
            ?.scrollIntoView({ behavior: "smooth", block: "center" });
          return () => {
            clearTimeout(t);
          };
        }, 250);
      }
    }
  }, [dataRows, journalIdQueryParam]);

  const subtotals: Record<string, number> = useMemo(() => {
    return dataRows.reduce(
      (acc, account) => {
        const value = Number(account.amount);
        const type = value > 0 ? "debit" : "credit";
        acc[type] += value;
        return acc;
      },
      { credit: 0, debit: 0 }
    );
  }, [dataRows]);

  const accountLabel = account.parentDisplayId
    ? getAccountLabel(account)
    : `⎿ ${getAccountLabel(account)}`;

  const columnHelper = createMRTColumnHelper<AccountTransactionDetailFragment>();

  // @ts-expect-error fix row type
  const columns = useMemo<Column<LedgerAccountDataTableRow>[]>(() => {
    return [
      columnHelper.accessor(
        (row) => {
          return shortDateFormatter.format(new Date(row.effectiveAt as string));
        },
        {
          header: "Date",
          size: 4,
          Cell: ({ cell }) => <MutedCell>{cell.getValue()}</MutedCell>,
          enableSorting: true,
          Footer: () => {
            if (!startDate || !endDate) {
              return;
            }
            return dateFormatter.formatRange(startDate, endDate);
          },
        }
      ),
      columnHelper.accessor((row) => convertEventTypeToString(row.type || ""), {
        header: "Event Type",
        id: "type",
        size: 4,
        enableSorting: true,
      }),
      columnHelper.accessor((row) => row.description || row.transaction?.descriptor, {
        header: "Description",
        enableSorting: false,
        size: 8,
      }),
      columnHelper.accessor((row) => row.journal?.description, {
        header: "Journal Memo",
        enableSorting: false,
        size: 8,
      }),
      columnHelper.accessor(
        (row) => {
          const value: number = Number(row.amount) > 0 ? Number(row.amount) : 0;
          return value > 0 ? value : "";
        },
        {
          header: "Debit",
          size: 4,
          enableSorting: true,
          Cell: ({ cell }) => <ValueCell>{glAmountToDisplayString(cell.getValue())}</ValueCell>,
          Footer: () => <ValueCell>{glAmountToDisplayString(subtotals["debit"] || 0)}</ValueCell>,
        }
      ),
      columnHelper.accessor(
        (row) => {
          const value: number = Number(row.amount) < 0 ? Number(row.amount) * -1 : 0;
          return value > 0 ? value : "";
        },
        {
          header: "Credit",
          size: 4,
          enableSorting: true,
          Cell: ({ cell }) => <ValueCell>{glAmountToDisplayString(cell.getValue())}</ValueCell>,
          Footer: () => <ValueCell>{glAmountToDisplayString(subtotals["credit"] || 0)}</ValueCell>,
        }
      ),
      columnHelper.accessor((row) => Number(row.amount), {
        header: "Running Total",
        size: 4,
        enableSorting: false,
        Cell: ({ row, table }) => {
          let runningTotal = 0;
          table
            .getCoreRowModel()
            .rows.slice(0, row.index + 1)
            .forEach(({ original }) => {
              runningTotal += Number(original.amount);
            });
          return <ValueCell>{glAmountToDisplayString(runningTotal || 0)}</ValueCell>;
        },
      }),
    ];
  }, [endDate, startDate, subtotals, dateFormatter, shortDateFormatter, columnHelper]);

  const tableOptions = useMemo(
    () => ({
      data: dataRows,
      enableExpanding: true,
      enableExpandAll: false,
      enableRowVirtualization: true,
      rowVirtualizerOptions: { overscan: 5 },
      muiTableHeadCellProps: {
        className: tableHeaderCellRecipe({ border: "borderless" }),
      },
      muiTableBodyCellProps: {
        className: tableBodyCellRecipe({ border: "borderless" }),
      },
      renderDetailPanel: ({ row }: { row: MRT_Row<AccountTransactionDetailFragment> }) => (
        <LedgerAccountTransactionJournal
          journalId={row.original.journal.id}
          chartOfAccountsById={chartOfAccountsById}
        />
      ),
      // Expand row if getting deep linked to
      initialState: {
        expanded: dataRows.reduce((res: { [key in string]: boolean }, detail, i) => {
          res[i] = detail.journal.id === journalIdQueryParam;

          return res;
        }, {}),
      },
      columns: columns,
    }),
    [dataRows, journalIdQueryParam, chartOfAccountsById, columns]
  );

  const onTableChange = useCallback(
    (state: TableState<AccountTransactionDetailFragment>) => {
      const newSortBy = state.sortBy;
      if (newSortBy.length && sortBy !== newSortBy) {
        setSortBy(newSortBy);
      }
    },
    [sortBy]
  );

  return (
    <AccountBody key={idx}>
      <AccountEntriesCountRow>{`Showing ${pluralize(
        numEntries,
        "entry",
        "entries"
      )}`}</AccountEntriesCountRow>
      <AccountRow>
        <div>{accountLabel}</div>
        <div>
          <TotalText>Total</TotalText>
          <NumberText>{glAmountToDisplayString(accountTotals[account.displayId])}</NumberText>
        </div>
      </AccountRow>
      <TableContainer>
        <DataGrid options={tableOptions} height="100%" />
      </TableContainer>
    </AccountBody>
  );
};

export default LedgerAccountMRT;
