import {
  AvailableClassesResult,
  AvailableReportingClass,
  DynamicReportType,
  LedgerReportColumnBy,
  LedgerReportFilterInput,
  LedgerReportSegmentFilterInput,
  ReportTimePeriod,
} from "graphql/types";
import { isEmpty, last } from "lodash";
import { noClassificationUUID } from "./Filters/ClassificationsFilter";
import { GroupBy } from "@puzzle/ui";
import { parseDate } from "@internationalized/date";
import { toCalendarMonthString } from "@puzzle/utils";
import { getMonths } from "./useLedgerReport";

export const getClassificationColumns = (
  filter: LedgerReportFilterInput,
  columnBy: LedgerReportColumnBy,
  groupBy: string,
  classifications?: AvailableClassesResult
): LedgerReportSegmentFilterInput | undefined => {
  if (columnBy === LedgerReportColumnBy.Interval) {
    return;
  }
  if (!classifications || !filter) {
    return;
  }

  if (!isEmpty(filter) && filter.segments?.segmentIds && filter.segments.segmentIds.length > 0) {
    return filter.segments as LedgerReportSegmentFilterInput;
  }
  const getColObj = (reportingClass: AvailableReportingClass): LedgerReportSegmentFilterInput => {
    const segmentIds: string[] = reportingClass.availableValues.map((value) => value.id);
    return {
      reportingClassType: reportingClass.type,
      reportingClassId: reportingClass.id,
      segmentIds: [...segmentIds, noClassificationUUID],
    };
  };
  if (classifications.departments.id === groupBy) {
    return getColObj(classifications.departments);
  }
  if (classifications.locations.id === groupBy) {
    return getColObj(classifications.locations);
  }
  const userCreated = classifications.userCreated.find((c) => c.id === groupBy);
  return userCreated ? getColObj(userCreated) : undefined;
};

export const isGroupedByPeriod = (groupBy: string) => {
  return Object.values(GroupBy).includes(groupBy as GroupBy);
};

export const getColumnBy = (groupBy: string) => {
  return isGroupedByPeriod(groupBy) ? LedgerReportColumnBy.Interval : LedgerReportColumnBy.Segment;
};

export const getPeriod = (timePeriod: ReportTimePeriod | undefined) => {
  if (!timePeriod) {
    return {
      key: "",
      periods: [],
    };
  }
  const start = parseDate(timePeriod.start);
  const end = parseDate(timePeriod.end);
  if (start?.compare(end) === 0) {
    return {
      key: timePeriod?.timePeriodKey,
      periods: [toCalendarMonthString(start)],
    };
  } else {
    return {
      key: timePeriod?.timePeriodKey,
      periods: getMonths(start, end),
    };
  }
};

export const getReportIntervals = (
  timePeriods: ReportTimePeriod[],
  columnBy: LedgerReportColumnBy,
  reportType?: DynamicReportType
) => {
  const allIntervals = timePeriods.map((tp) => {
    return getPeriod(tp);
  });

  if (columnBy === LedgerReportColumnBy.Segment) {
    const allTimePeriods = allIntervals.reduce((acc: string[], interval) => {
      return acc.concat(interval.periods);
    }, []);

    return [
      {
        key: LedgerReportColumnBy.Segment,
        periods: allTimePeriods,
      },
    ];
  }

  if (reportType === DynamicReportType.TrialBalance) {
    return allIntervals.map((interval) => {
      return { ...interval, periods: [last(interval.periods) ?? ""] };
    });
  }
  return allIntervals;
};
