import React from "react";
import Icon, { IconProps } from "./Icon";

export default function OffsetArrow({
  viewBox = "0 0 14 14",
  width = 14,
  height = 14,
  fill = "none",
  color = "currentColor",
  stroke = color,
}: IconProps) {
  return (
    <Icon width={width} height={height} fill={fill} viewBox={viewBox}>
      <rect
        width="3.5"
        height="3.5"
        x="2"
        y="5.25"
        stroke={stroke}
        strokeWidth="1.5"
        rx="1.75"
      ></rect>
      <path stroke={stroke} strokeLinecap="round" strokeWidth="1.5" d="M11 7H6"></path>
      <path
        fill={stroke}
        d="M10.604 4.646a.5.5 0 00-.708.708l.708-.708zM12.25 7l.354.354.353-.354-.353-.354L12.25 7zM9.896 8.646a.5.5 0 10.708.708l-.708-.708zm0-3.292l2 2 .708-.708-2-2-.708.708zm2 1.292l-2 2 .708.708 2-2-.708-.708z"
      ></path>
    </Icon>
  );
}
