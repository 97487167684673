import { AlertDialog, styled } from "@puzzle/ui";
import { LockedPeriodInput, LockWarning } from "graphql/types";
import useAppRouter from "lib/useAppRouter";
import React, { useCallback, useMemo, useState } from "react";
import {
  useSetLockedPeriodMutation,
  usePostTransactionMutation,
  useDeleteTransactionMutation,
} from "./graphql.generated";
import { useToasts } from "@puzzle/ui";
import { AllLockWarning, LockDayWarning } from "./LockedPeriod";
import {
  ActiveCompanyFragment,
  ActiveCompanyFragmentDoc,
} from "components/companies/graphql.generated";
import {
  BasicTransactionFragmentDoc,
  BasicTransactionFragment,
} from "components/dashboard/Transactions/graphql.generated";
import { useActiveCompany, useCompanyDateFormatter } from "components/companies";
import { addMonths, isBefore, parseCalendarMonth } from "@puzzle/utils";
import { CalendarDate, endOfMonth, today } from "@internationalized/date";
import { Route } from "lib/routes";

const AccountingLink = styled("a", {
  textDecoration: "underline",
});

interface Props {
  open: boolean;
  period: string | null;
  warnings: AllLockWarning[] | null;
  onOpenChange: (open: boolean) => void;
  isUnlock?: boolean;
  transactionId?: string;
  transactionDate?: CalendarDate | null;
  onClose?: () => void;
  onComplete?: (period?: string) => void;
}

export const WarningDialog = ({
  period,
  open,
  warnings,
  onOpenChange,
  isUnlock,
  transactionId,
  transactionDate,
  onClose,
  onComplete,
}: Props) => {
  const { router } = useAppRouter();
  const { company, timeZone } = useActiveCompany<true>();
  const companyId = company.id;
  const [setLockedPeriodMutation] = useSetLockedPeriodMutation();
  const [postTransactionMutation] = usePostTransactionMutation();
  const [deleteTransactionMutation] = useDeleteTransactionMutation();
  const [deleteWarning, setDeleteWarning] = useState(false);
  const { toast } = useToasts();

  const handleClose = useCallback(
    (e?: React.MouseEvent | React.KeyboardEvent) => {
      e?.stopPropagation();
      e?.preventDefault();
      onClose?.();
      onOpenChange(false);
      setDeleteWarning(false);
      return;
    },
    [onClose, onOpenChange]
  );

  const goToTransactions = useCallback(() => {
    if (!period) {
      return;
    }

    router.push({
      pathname: "/transactions",
      query: `status=uncategorized&start=${parseCalendarMonth(period)
        // Should we make start unbounded...?
        .subtract({ years: 3 })
        .toString()}&end=${endOfMonth(parseCalendarMonth(period)).toString()}`,
    });
    onOpenChange(false);
  }, [onOpenChange, period, router]);

  const goReconciliations = useCallback(() => {
    router.push({
      pathname: Route.ledgerReconciliations,
    });
    onOpenChange(false);
  }, [onOpenChange, router]);

  const goToAccounting = useCallback(() => {
    router.push({
      pathname: "/accounting/locked-period",
    });
    onOpenChange(false);
  }, [onOpenChange, router]);

  const handleWarning = useCallback(
    async (e: React.MouseEvent) => {
      const updatedPeriod = isUnlock ? null : period;
      const input: LockedPeriodInput = {
        companyId,
        period: updatedPeriod,
      };
      await setLockedPeriodMutation({
        variables: { input },
        update: (cache, { data }) => {
          if (!data?.setLockedPeriod) {
            return;
          }
          const options = {
            fragmentName: "activeCompany",
            fragment: ActiveCompanyFragmentDoc,
          };
          const fragmentId = `Company:${companyId}`;
          const fragment = cache.readFragment<ActiveCompanyFragment>({
            ...options,
            id: fragmentId,
          });
          if (fragment) {
            cache.writeFragment<ActiveCompanyFragment>({
              ...options,
              id: fragmentId,
              data: {
                ...fragment,
                lockedPeriod: { period: updatedPeriod },
                features: {
                  ...fragment.features,
                },
              },
            });
          }
        },

        onCompleted({ setLockedPeriod }) {
          toast({ message: "Locked period was successfully updated." });
          onComplete?.(setLockedPeriod.period ?? undefined);
        },

        onError(error) {
          toast({ message: error.message, status: "error" });
        },
      });

      handleClose(e);
    },
    [companyId, handleClose, isUnlock, onComplete, period, setLockedPeriodMutation, toast]
  );

  const handlePostTransaction = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      if (!transactionId) {
        return handleClose(e);
      }
      const input = { transactionId };
      await postTransactionMutation({
        variables: { input },

        onCompleted() {
          handleClose(e);
        },

        update: (cache, { data }) => {
          // Write latest transaction activity memos
          if (
            !data?.postTransaction.transaction.activity ||
            !data.postTransaction.transaction.detail.postedAt
          ) {
            return;
          }

          const newActivity = data.postTransaction.transaction.activity;
          const newDetail = data.postTransaction.transaction.detail;
          const currentTransaction = cache.readFragment<BasicTransactionFragment>({
            id: `Transaction:${transactionId}`,
            fragmentName: "basicTransaction",
            fragment: BasicTransactionFragmentDoc,
          });
          cache.writeFragment<BasicTransactionFragment>({
            id: `Transaction:${transactionId}`,
            fragmentName: "basicTransaction",
            fragment: BasicTransactionFragmentDoc,
            data: {
              ...currentTransaction!,
              activity: newActivity,
              detail: {
                ...currentTransaction?.detail,
                ...newDetail,
              },
            },
          });
        },
      });
    },
    [handleClose, postTransactionMutation, transactionId]
  );

  const handleDeleteTransaction = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      if (!transactionId) {
        return handleClose(e);
      }
      const input = { companyId: companyId, transactionId: transactionId, unposted: true };
      await deleteTransactionMutation({
        variables: { input },
        update: (cache) => {
          const cacheId = cache.identify({ id: transactionId, __typename: "Transaction" });
          cache.evict({ id: cacheId });
          cache.gc();
        },
      });
      handleClose(e);
    },
    [companyId, deleteTransactionMutation, handleClose, transactionId]
  );

  const monthFormatter = useCompanyDateFormatter({ month: "long", year: "numeric" });
  const [isTransactionInLockedPeriod, postDateFormatted] = useMemo(() => {
    // company no longer has a locked period
    if (!period) {
      return [false, monthFormatter.format(transactionDate ?? today(timeZone))];
    }

    const periodDateCutoff = addMonths(parseCalendarMonth(period), 1);

    // optional parameter, were not in a transaction flow
    if (!transactionDate) {
      return [true, monthFormatter.format(periodDateCutoff)];
    }

    // transaction is still in locked period
    if (isBefore(transactionDate, periodDateCutoff)) {
      return [true, monthFormatter.format(periodDateCutoff)];
    }

    // this transaction once was, but no longer is locked
    // we dont just up and post when locked periods change
    // the user is still required to do this
    return [false, monthFormatter.format(transactionDate)];
  }, [period, transactionDate]);

  const warningDetails = useMemo(() => {
    if (!warnings) {
      return null;
    }
    if (isUnlock) {
      return (
        <>
          <AlertDialog.Title>Are you sure you want to clear the locked period?</AlertDialog.Title>
          <AlertDialog.Body>
            Locked periods stop data from being changed for a specific time. By clearing the locked
            period, you will allow all users to make changes to all transactions.
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <AlertDialog.CancelButton onClick={handleClose}>Cancel</AlertDialog.CancelButton>
            <AlertDialog.ConfirmButton variant="negative" onClick={handleWarning}>
              Clear locked period
            </AlertDialog.ConfirmButton>
          </AlertDialog.Footer>
        </>
      );
    }
    if (deleteWarning) {
      return (
        <>
          <AlertDialog.Title>Are you sure you want to delete this transaction? </AlertDialog.Title>
          <AlertDialog.Body>
            Deleting the transaction is a permanent action. Puzzle recommends posting the
            transaction to current period instead.
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <AlertDialog.CancelButton
              onClick={(e) => {
                handleClose(e);
              }}
            >
              Cancel
            </AlertDialog.CancelButton>
            <AlertDialog.ConfirmButton
              variant="negative"
              onClick={(e) => {
                handleDeleteTransaction(e);
              }}
            >
              Delete
            </AlertDialog.ConfirmButton>
          </AlertDialog.Footer>
        </>
      );
    }
    if (warnings.length > 1) {
      return (
        <>
          <AlertDialog.Title>There are outstanding items in this period</AlertDialog.Title>
          <AlertDialog.Body>
            There are uncategorized transactions and unverified reconciliations that fall on or
            before the period date. Data within a locked period cannot be changed. Consider
            reviewing outstanding transactions and reconciliations before locking this date.
          </AlertDialog.Body>
          <AlertDialog.Footer>
            {!warnings.includes(LockWarning.PeriodInProcessing) && (
              <AlertDialog.CancelButton onClick={handleWarning}>
                Lock anyway
              </AlertDialog.CancelButton>
            )}
            <AlertDialog.ConfirmButton onClick={goToTransactions}>
              View uncategorized transactions
            </AlertDialog.ConfirmButton>
          </AlertDialog.Footer>
        </>
      );
    }
    const warning = warnings[0];
    switch (warning) {
      case LockWarning.UncategorizedTransactions:
        return (
          <>
            <AlertDialog.Title>
              There are uncategorized transactions in this period
            </AlertDialog.Title>
            <AlertDialog.Body>
              There are uncategorized transactions that fall on or before the period date. Data
              within a locked period cannot be changed. Please consider categorizing outstanding
              transactions before locking this date.
            </AlertDialog.Body>
            <AlertDialog.Footer>
              <AlertDialog.CancelButton onClick={handleWarning}>
                Lock anyway
              </AlertDialog.CancelButton>
              <AlertDialog.ConfirmButton onClick={goToTransactions}>
                View uncategorized transactions
              </AlertDialog.ConfirmButton>
            </AlertDialog.Footer>
          </>
        );
      case LockWarning.UnreconciledPeriods:
        return (
          <>
            <AlertDialog.Title>
              There are unverified reconciliations in this period
            </AlertDialog.Title>
            <AlertDialog.Body>
              There are unverified reconciliations that fall on or before the period date. Data
              within a locked period cannot be changed. Please consider categorizing outstanding
              transactions before locking this date.
            </AlertDialog.Body>
            <AlertDialog.Footer>
              <AlertDialog.CancelButton onClick={handleWarning}>
                Lock anyway
              </AlertDialog.CancelButton>
              <AlertDialog.ConfirmButton onClick={goReconciliations}>
                View unverified reconciliations
              </AlertDialog.ConfirmButton>
            </AlertDialog.Footer>
          </>
        );
      case LockWarning.PeriodInProcessing:
        return (
          <>
            <AlertDialog.Title>Transactions currently being processed</AlertDialog.Title>
            <AlertDialog.Body>
              There are currently transactions being processed for this time period, which prevents
              locking the period. Please try again in a few minutes.
            </AlertDialog.Body>
            <AlertDialog.Footer>
              <AlertDialog.CancelButton fullWidth onClick={handleClose}>
                Close
              </AlertDialog.CancelButton>
            </AlertDialog.Footer>
          </>
        );
      case LockDayWarning.WithinFiveDays:
        return (
          <>
            <AlertDialog.Title>This date is within 5 days of period end</AlertDialog.Title>
            <AlertDialog.Body>
              Locking a period within 5 days of the period ending can result in expected
              transactions being inadvertently pushed into a subsequent period. Locking the period
              to this chosen date is not recommended.
            </AlertDialog.Body>
            <AlertDialog.Footer>
              <AlertDialog.CancelButton onClick={handleClose}>Cancel</AlertDialog.CancelButton>
              <AlertDialog.ConfirmButton variant="negative" onClick={handleWarning}>
                Lock anyway
              </AlertDialog.ConfirmButton>
            </AlertDialog.Footer>
          </>
        );
      case LockDayWarning.TransactionWarning:
        return (
          <>
            <AlertDialog.Title>This transaction requires your attention</AlertDialog.Title>
            <AlertDialog.Body>
              {isTransactionInLockedPeriod ? (
                <>
                  <p>
                    {`This transaction is dated to a locked period and cannot be posted during that
                    period. Your company's admin can update the locked period under `}
                    <AccountingLink onClick={goToAccounting}>Accounting.</AccountingLink>
                  </p>
                  <p>
                    You can either post this transaction to an open period or delete the transaction
                    from your financial record. If you choose to post it (Puzzle recommended), the
                    transaction will be posted to {postDateFormatted}, the current period.
                  </p>
                </>
              ) : (
                <>
                  <p>
                    This transaction is dated to a period that was previously locked and has not
                    been automatically posted.
                  </p>

                  <p>
                    You can either post this transaction or delete it from you financial record. If
                    you choose to post it will be posted to {postDateFormatted}.
                  </p>
                </>
              )}
            </AlertDialog.Body>
            <AlertDialog.Footer>
              <AlertDialog.ConfirmButton
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleteWarning(true);
                }}
              >
                Delete
              </AlertDialog.ConfirmButton>
              <AlertDialog.ConfirmButton
                onClick={(e) => {
                  handlePostTransaction(e);
                }}
              >
                Post
              </AlertDialog.ConfirmButton>
            </AlertDialog.Footer>
          </>
        );
    }
  }, [
    warnings,
    isUnlock,
    deleteWarning,
    handleClose,
    handleWarning,
    handleDeleteTransaction,
    goToTransactions,
    goReconciliations,
    period,
    goToAccounting,
    handlePostTransaction,
    postDateFormatted,
  ]);
  return (
    <AlertDialog
      open={open}
      onOpenChange={onOpenChange}
      size={"small"}
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          handleClose(e);
        }
      }}
    >
      {warningDetails}
    </AlertDialog>
  );
};
