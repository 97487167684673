import React, { Ref } from "react";
import RAsyncSelect from "react-select/async-creatable";
import {
  cleraIndicatorContainerStyle,
  divTestIdStyle,
  inputStyle,
  optionStyle,
  placeholderStyle,
  selectControlStyle,
  selectIndicatorContainerStyle,
  selectMenuStyle,
  selectSingleValueStyle,
  selectValueControlStyle,
} from "./selectStyles.css";
import { ChevronDown, Close } from "@puzzle/icons";
import { SelectOption } from "./types";
import { ActionMeta, GroupBase, SelectInstance, SingleValue } from "react-select";
import { SelectComponents } from "react-select/dist/declarations/src/components";
import Select from "react-select/dist/declarations/src/Select";
import { useFieldContext } from "@puzzle/ui";
import { Feedback } from "@puzzle/ui/src/lib/form/types";
import { Box } from "ve/Box";
import { vars } from "ve/theme";
import { Loader } from "ve/Loader";

interface SelectProps {
  cacheOptions?: boolean;
  dataTestId?: string;
  defaultOptions?: SelectOption[] | boolean;
  loadOptions: (value: string) => Promise<SelectOption[]>;
  createOptionPosition?: "first" | "last";
  formatCreateLabel?: (value: string) => string;
  onCreateOption?: (value: string) => void;
  placeholder?: string;
  value?: SelectOption | undefined;
  onChange?: (newValue: SingleValue<SelectOption>, actionMeta: ActionMeta<SelectOption>) => void;
  disabled?: boolean;
  components?: Partial<SelectComponents<SelectOption, false, GroupBase<SelectOption>>> | undefined;
  feedback?: Feedback;
}

// eslint-disable-next-line react/display-name
export const AsyncSelect = React.forwardRef<SelectInstance<SelectOption | null>, SelectProps>(
  (
    {
      dataTestId,
      defaultOptions,
      cacheOptions,
      onCreateOption,
      createOptionPosition = "first",
      formatCreateLabel = () => "+ Add",
      placeholder,
      loadOptions,
      value,
      onChange,
      disabled,
      components,
      feedback,
    },
    ref
  ) => {
    const fieldContext = useFieldContext();
    return (
      <div className={divTestIdStyle()} data-testid={dataTestId}>
        <RAsyncSelect
          ref={ref as Ref<Select<SelectOption, false>>}
          isDisabled={disabled}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          isValidNewOption={() => true}
          allowCreateWhileLoading
          onCreateOption={onCreateOption}
          formatCreateLabel={formatCreateLabel}
          createOptionPosition={createOptionPosition}
          defaultOptions={defaultOptions}
          cacheOptions={cacheOptions}
          loadOptions={loadOptions}
          classNames={{
            control: (props) =>
              selectControlStyle({
                feedback: fieldContext.feedback || feedback,
                variant: props.isFocused ? "focused" : undefined,
              }),
            indicatorsContainer: () => selectIndicatorContainerStyle(),
            valueContainer: () => selectValueControlStyle(),
            singleValue: () => selectSingleValueStyle(),
            menu: () => selectMenuStyle(),
            option: (props) =>
              optionStyle({
                variant: props.isSelected ? "active" : props.isFocused ? "focused" : undefined,
              }),
            input: () => inputStyle(),
            placeholder: () => placeholderStyle(),
          }}
          styles={{
            control: () => ({}),
            indicatorSeparator: () => ({}),
            indicatorsContainer: () => ({}),
            singleValue: (styles) => ({
              ...styles,
              color: undefined,
            }),
            menu: (styles) => ({
              ...styles,
              backgroundColor: undefined,
            }),
            valueContainer: (styles) => ({
              ...styles,
              padding: undefined,
            }),
            option: () => ({}),
            input: (styles) => ({
              ...styles,
              color: undefined,
            }),
          }}
          components={{
            DropdownIndicator: () => <ChevronDown />,
            LoadingIndicator: () => (
              <Box css={{ padding: `0 ${vars.space["0h"]}` }}>
                <Loader css={{ position: "relative" }} size={14} />
              </Box>
            ),
            ClearIndicator: ({ innerProps: { ref, ...restInnerProps } }) => (
              <div className={cleraIndicatorContainerStyle()} {...restInnerProps} ref={ref}>
                <Close size={16} />
              </div>
            ),
            ...components,
          }}
        />
      </div>
    );
  }
);
