import React from "react";

import { useActiveCompany, PricingFeatures } from "components/companies";

interface FeatureGateProps {
  gate: React.ReactNode;
  pricingFeature?: PricingFeatures;
}

export const FeatureGate = ({
  children,
  gate, // alt component to render if feature is gated
  pricingFeature,
}: React.PropsWithChildren<FeatureGateProps>) => {
  const { pricePlanFeatureEnabled } = useActiveCompany<true>();

  const isFeatureGated = pricingFeature && !pricePlanFeatureEnabled.has(pricingFeature);

  return isFeatureGated ? <>{gate}</> : <>{children}</>;
};
