import React, { useMemo, useCallback, useState } from "react";
import { useSetState, useUpdateEffect } from "react-use";
import { omit } from "lodash";
import { CalendarDate } from "@internationalized/date";
import { Nullable, queryTypes, useQueryStates, UseQueryStatesKeysMap } from "next-usequerystate";
import { mergeDeep } from "@apollo/client/utilities";
import { SortingState } from "@tanstack/react-table";

import { RangePreset, RangePresets, useToasts } from "@puzzle/ui";
import { calendarDateSerializer } from "@puzzle/utils";

import { useActiveCompany } from "components/companies";
import Analytics from "lib/analytics";
import {
  InvoiceStatus,
  ContractRevenueScheduleStatus,
  InvoiceSortOrder,
  ContractRevenueScheduleSortOrder,
} from "graphql/types";

import { errorToastConfig } from "../shared";
import {
  useGetContractRevenueSchedulesQuery,
  useGetInvoicesQuery,
  useRemoveInvoiceMutation,
  GetInvoicesDocument,
} from "../graphql.generated";

export const INVOICE_DATE_PRESET = RangePresets.Last90Days;
export const INVOICE_DATE_RANGE = INVOICE_DATE_PRESET.range!();
export const SCHEDULE_DATE_PRESET = RangePresets.Last3Months;
export const SCHEDULE_DATE_RANGE = SCHEDULE_DATE_PRESET.range!();

const DEFAULT_PER_PAGE = 50;

export enum TableView {
  Invoices = "invoices",
  Schedules = "schedules",
}

export enum InvoiceSource {
  Manual = "manual",
  Stripe = "stripe",
  BILL = "bill",
}

export const invoiceSourceToDataSourceTypeId = (invoiceSource: InvoiceSource): number => {
  switch (invoiceSource) {
    case InvoiceSource.Manual:
      return 15;

    case InvoiceSource.Stripe:
      return 6;

    case InvoiceSource.BILL:
      return 12;
  }
};

type QueryFilterState = {
  start: CalendarDate;
  end: CalendarDate;
  preset: RangePreset | null;
  activeTableView: TableView;
  invoiceSortBy: InvoiceSortOrder;
  scheduleSortBy: ContractRevenueScheduleSortOrder;
  invoiceStatuses: InvoiceStatus[];
  scheduleStatuses: ContractRevenueScheduleStatus[];
  invoiceSource: InvoiceSource;
  ledgerCoaKeys: string[];
  productIds: string[];
  customerIds: string[];
};

type OptionalFields = "preset" | "invoiceSortBy" | "scheduleSortBy" | "invoiceSource";

type FilterState = Omit<QueryFilterState, OptionalFields | "invoiceSortBy" | "scheduleSortBy"> &
  Nullable<Pick<QueryFilterState, OptionalFields>>;

const defaultFilter: FilterState = {
  start: INVOICE_DATE_RANGE[0],
  end: INVOICE_DATE_RANGE[1],
  preset: INVOICE_DATE_PRESET,
  activeTableView: TableView.Invoices,
  invoiceStatuses: [],
  scheduleStatuses: [],
  invoiceSortBy: null,
  scheduleSortBy: null,
  invoiceSource: null,
  ledgerCoaKeys: [],
  productIds: [],
  customerIds: [],
};

const useQueryFilterState = () => {
  return useQueryStates<UseQueryStatesKeysMap>({
    start: calendarDateSerializer,
    end: calendarDateSerializer,
    activeTableView: queryTypes.stringEnum<TableView>(Object.values(TableView)),
    invoiceStatuses: queryTypes.array(
      queryTypes.stringEnum<InvoiceStatus>(Object.values(InvoiceStatus)),
      "."
    ),
    invoiceSource: queryTypes.stringEnum<InvoiceSource>(Object.values(InvoiceSource)),
    scheduleStatuses: queryTypes.array(
      queryTypes.stringEnum<ContractRevenueScheduleStatus>(
        Object.values(ContractRevenueScheduleStatus)
      ),
      "."
    ),
    invoiceSortBy: queryTypes.stringEnum<InvoiceSortOrder>(Object.values(InvoiceSortOrder)),
    scheduleSortBy: queryTypes.stringEnum<ContractRevenueScheduleSortOrder>(
      Object.values(ContractRevenueScheduleSortOrder)
    ),
    ledgerCoaKeys: queryTypes.array(queryTypes.string, ".").withDefault([]),
    productIds: queryTypes.array(queryTypes.string, ".").withDefault([]),
    customerIds: queryTypes.array(queryTypes.string, ".").withDefault([]),
  });
};

export const useRemoveInvoice = () => {
  const [removeInvoice, { loading }] = useRemoveInvoiceMutation({
    refetchQueries: [GetInvoicesDocument],
  });

  const { toast } = useToasts();

  return {
    mutate: useCallback(
      (invoiceId?: string, invoiceDescription?: string, invoiceAmount?: string) => {
        if (!invoiceId) {
          return;
        }
        return removeInvoice({
          variables: { input: { invoiceId } },
          onCompleted: (response) => {
            if (!response.removeInvoice.success) {
              toast({
                title: "Invoice delete error",
                message: `Unable to delete the draft invoice for ${invoiceDescription} for ${invoiceAmount}`,
                status: "error",
              });
            }
            toast({
              title: "Invoice deleted",
              message: `You deleted the draft invoice for ${invoiceDescription} for ${invoiceAmount}`,
              status: "success",
            });
            Analytics.invoiceDeleted({ invoiceId });
          },
          onError: () => {
            toast(errorToastConfig);
          },
        });
      },
      [removeInvoice, toast]
    ),
    loading,
  };
};

const useInvoicesQuery = (
  {
    start,
    end,
    invoiceStatuses,
    invoiceSortBy,
    ledgerCoaKeys,
    invoiceSource,
    productIds,
    customerIds,
    activeTableView,
  }: FilterState,
  sortBy?: InvoiceSortOrder
) => {
  const { company } = useActiveCompany<true>();

  const { data, loading, variables, fetchMore } = useGetInvoicesQuery({
    skip: !company?.id || activeTableView !== TableView.Invoices,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    variables: {
      companyId: company.id,
      page: { count: DEFAULT_PER_PAGE },
      sortBy: sortBy ?? invoiceSortBy,
      filterBy: {
        dateRange: {
          fromInclusive: start.toString(),
          toExclusive: end.add({ days: 1 }).toString(),
          // key is a required field on the end exclusive date range model
          // but doesn't matter here since we only have one daterange
          key: "yo im the key",
        },
        dataSourceTypeIds: invoiceSource
          ? [invoiceSourceToDataSourceTypeId(invoiceSource)]
          : undefined,
        statuses: invoiceStatuses?.length ? invoiceStatuses : undefined,
        coaKeys: ledgerCoaKeys && ledgerCoaKeys.length > 0 ? ledgerCoaKeys : undefined,
        productIds: productIds && productIds.length > 0 ? productIds : undefined,
        customerIds: customerIds && customerIds.length > 0 ? customerIds : undefined,
      },
    },
  });

  const hasMore = data?.company?.invoices.pageInfo.hasNextPage;
  const fetchNextPage = useCallback(() => {
    fetchMore({
      variables: mergeDeep(variables, {
        page: {
          after: data?.company?.invoices.pageInfo.endCursor,
          count: DEFAULT_PER_PAGE,
        },
      }),
    });
  }, [fetchMore, variables, data]);

  return useMemo(() => {
    return {
      invoicesData: data?.company?.invoices.items ?? [],
      invoicesTotal: data?.company?.invoices.total,
      invoicesLoading: loading,
      hasMore,
      fetchNextPage,
      count: data?.company?.invoices.pageInfo.total,
    };
  }, [data?.company?.invoices, loading, hasMore, fetchNextPage]);
};

const useContractRevenueSchedulesQuery = (
  {
    start,
    end,
    scheduleStatuses,
    scheduleSortBy,
    ledgerCoaKeys,
    customerIds,
    activeTableView,
  }: FilterState,
  sortBy?: ContractRevenueScheduleSortOrder
) => {
  const { company } = useActiveCompany<true>();
  const sort = sortBy ?? scheduleSortBy;

  const { data, loading, variables, fetchMore } = useGetContractRevenueSchedulesQuery({
    skip: !company?.id || activeTableView !== TableView.Schedules,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    variables: {
      companyId: company.id,
      page: { count: DEFAULT_PER_PAGE },
      sortBy: sort ?? ContractRevenueScheduleSortOrder.StartDayOrderDesc,
      filterBy: {
        statuses: scheduleStatuses?.length ? scheduleStatuses : undefined,
        coaKeys: ledgerCoaKeys && ledgerCoaKeys.length > 0 ? ledgerCoaKeys : undefined,
        customerIds: customerIds && customerIds.length > 0 ? customerIds : undefined,
        searchDayRange: {
          fromInclusive: start.toString(),
          toInclusive: end.toString(),
        },
      },
    },
  });

  const hasMore = data?.company?.contractRevenueSchedules.pageInfo.hasNextPage;
  const fetchNextPage = useCallback(() => {
    fetchMore({
      variables: mergeDeep(variables, {
        page: {
          after: data?.company?.contractRevenueSchedules.pageInfo.endCursor,
          count: DEFAULT_PER_PAGE,
        },
      }),
    });
  }, [fetchMore, variables, data]);

  return useMemo(() => {
    return {
      contractRevenueSchedulesData: data?.company?.contractRevenueSchedules.items ?? [],
      contractRevenueSchedulesTotal: data?.company?.contractRevenueSchedules.total,
      contractRevenueSchdulesLoading: loading,
      hasMoreContracts: hasMore,
      fetchNextContracts: fetchNextPage,
    };
  }, [data?.company?.contractRevenueSchedules, loading, hasMore, fetchNextPage]);
};

const useInvoicesFilter = () => {
  const [queryParamState, setQueryParamState] = useQueryFilterState();

  const [invoiceSortOptions, setInvoiceSortOptions] = useState<SortingState>(() => {
    switch (queryParamState.invoiceSortBy) {
      case InvoiceSortOrder.IssueDateAsc:
        return [{ desc: false, id: "issueDate" }];
      case InvoiceSortOrder.IssueDateDesc:
        return [{ desc: true, id: "issueDate" }];
      case InvoiceSortOrder.DueDateAsc:
        return [{ desc: false, id: "dueDate" }];
      case InvoiceSortOrder.DueDateDesc:
        return [{ desc: true, id: "dueDate" }];
      case InvoiceSortOrder.DescriptorAsc:
        return [{ desc: false, id: "description" }];
      case InvoiceSortOrder.DescriptorDesc:
        return [{ desc: true, id: "description" }];
      case InvoiceSortOrder.CustomerAsc:
        return [{ desc: false, id: "customer" }];
      case InvoiceSortOrder.CustomerDesc:
        return [{ desc: true, id: "customer" }];
      case InvoiceSortOrder.AmountAsc:
        return [{ desc: false, id: "amount.amount" }];
      case InvoiceSortOrder.AmountDesc:
        return [{ desc: true, id: "amount.amount" }];
      default:
        return [];
    }
  });

  const [scheduleSortOptions, setScheduleSortOptions] = useState<SortingState>(() => {
    switch (queryParamState.scheduleSortBy) {
      case ContractRevenueScheduleSortOrder.StartDayOrderAsc:
        return [{ desc: false, id: "startDay" }];
      case ContractRevenueScheduleSortOrder.StartDayOrderDesc:
        return [{ desc: true, id: "startDay" }];
      default:
        return [];
    }
  });

  const invoiceSortBy = useMemo(() => {
    const { id, desc } = invoiceSortOptions?.[0] || {};
    switch (id) {
      case "issueDate":
        return desc ? InvoiceSortOrder.IssueDateDesc : InvoiceSortOrder.IssueDateAsc;
      case "dueDate":
        return desc ? InvoiceSortOrder.DueDateDesc : InvoiceSortOrder.DueDateAsc;
      case "description":
        return desc ? InvoiceSortOrder.DescriptorDesc : InvoiceSortOrder.DescriptorAsc;
      case "customer":
        return desc ? InvoiceSortOrder.CustomerDesc : InvoiceSortOrder.CustomerAsc;
      case "amount.amount":
        return desc ? InvoiceSortOrder.AmountDesc : InvoiceSortOrder.AmountAsc;
      default:
        undefined;
    }
  }, [invoiceSortOptions]);

  const scheduleSortBy = useMemo(() => {
    const { id, desc } = scheduleSortOptions?.[0] || {};

    return {
      startDay: {
        true: ContractRevenueScheduleSortOrder.StartDayOrderDesc,
        false: ContractRevenueScheduleSortOrder.StartDayOrderAsc,
      },
    }[id]?.[desc.toString()];
  }, [scheduleSortOptions]);

  const initialState: FilterState = useMemo(() => {
    return {
      scheduleStatuses: queryParamState.scheduleStatuses ?? defaultFilter.scheduleStatuses,
      invoiceStatuses: queryParamState.invoiceStatuses ?? defaultFilter.invoiceStatuses,
      activeTableView: queryParamState.activeTableView ?? defaultFilter.activeTableView,
      start: queryParamState.start ?? defaultFilter.start,
      end: queryParamState.end ?? defaultFilter.end,
      invoiceSortBy: queryParamState.invoiceSortBy ?? invoiceSortBy,
      scheduleSortBy: queryParamState.scheduleSortBy ?? scheduleSortBy,
      ledgerCoaKeys: queryParamState.ledgerCoaKeys ?? defaultFilter.ledgerCoaKeys,
      productIds: queryParamState.productIds ?? defaultFilter.productIds,
      customerIds: queryParamState.customerIds ?? defaultFilter.customerIds,
      invoiceSource: queryParamState.invoiceSource ?? defaultFilter.invoiceSource,
      preset:
        queryParamState.start || queryParamState.end
          ? { key: "customDay", label: "Custom" }
          : defaultFilter.preset,
    };
  }, [queryParamState, invoiceSortBy, scheduleSortBy]);

  const [filter, setFilter] = useSetState<FilterState>(initialState);

  useUpdateEffect(() => {
    setQueryParamState({ invoiceSortBy, scheduleSortBy }, { scroll: false, shallow: true });
  }, [invoiceSortBy, scheduleSortBy]);

  useUpdateEffect(() => {
    setQueryParamState({
      ...omit(filter, ["preset"]),
      invoiceStatuses: filter.invoiceStatuses.length > 0 ? filter.invoiceStatuses : null,
      scheduleStatuses: filter.scheduleStatuses.length > 0 ? filter.scheduleStatuses : null,
      productIds: filter.productIds && filter.productIds?.length > 0 ? filter.productIds : null,
      customerIds: filter.customerIds && filter.customerIds?.length > 0 ? filter.customerIds : null,
      ledgerCoaKeys:
        filter.ledgerCoaKeys && filter.ledgerCoaKeys?.length > 0 ? filter.ledgerCoaKeys : null,
    });
  }, [filter]);

  return useMemo(() => {
    return {
      filter,
      setFilter,
      invoiceSortOptions,
      setInvoiceSortOptions,
      invoiceSortBy,
      scheduleSortOptions,
      setScheduleSortOptions,
      scheduleSortBy,
    };
  }, [
    filter,
    setFilter,
    invoiceSortOptions,
    setInvoiceSortOptions,
    invoiceSortBy,
    scheduleSortOptions,
    setScheduleSortOptions,
    scheduleSortBy,
  ]);
};

const useInvoicesContextValue = () => {
  const {
    filter,
    setFilter,
    invoiceSortOptions,
    setInvoiceSortOptions,
    invoiceSortBy,
    scheduleSortOptions,
    setScheduleSortOptions,
    scheduleSortBy,
  } = useInvoicesFilter();

  const { invoicesData, invoicesTotal, invoicesLoading, hasMore, fetchNextPage, count } =
    useInvoicesQuery(filter, invoiceSortBy);

  const {
    contractRevenueSchedulesData,
    contractRevenueSchedulesTotal,
    contractRevenueSchdulesLoading,
    hasMoreContracts,
    fetchNextContracts,
  } = useContractRevenueSchedulesQuery(filter, scheduleSortBy);

  return useMemo(() => {
    return {
      filter,
      setFilter,
      invoicesData,
      invoicesTotal,
      invoicesLoading,
      hasMore,
      fetchNextPage,
      count,
      contractRevenueSchedulesData,
      contractRevenueSchedulesTotal,
      contractRevenueSchdulesLoading,
      hasMoreContracts,
      fetchNextContracts,
      invoiceSortOptions,
      setInvoiceSortOptions,
      scheduleSortOptions,
      setScheduleSortOptions,
    };
  }, [
    filter,
    setFilter,
    invoicesData,
    invoicesTotal,
    invoicesLoading,
    hasMore,
    fetchNextPage,
    count,
    contractRevenueSchedulesData,
    contractRevenueSchedulesTotal,
    contractRevenueSchdulesLoading,
    hasMoreContracts,
    fetchNextContracts,
    invoiceSortOptions,
    setInvoiceSortOptions,
    scheduleSortOptions,
    setScheduleSortOptions,
  ]);
};

const InvoicesContext = React.createContext<ReturnType<typeof useInvoicesContextValue> | null>(
  null
);

export const useInvoicesContext = () => {
  const context = React.useContext(InvoicesContext);

  if (context === null) {
    throw new Error("useInvoicesContext must be used as a child within InvoicesContextProvider");
  }

  return context;
};

export const InvoicesContextProvider = ({ ...props }) => {
  return <InvoicesContext.Provider value={useInvoicesContextValue()} {...props} />;
};
