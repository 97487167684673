import React, { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useRouter } from "next/router";

import { Menu, MenuButton, Button, styled, Tooltip } from "@puzzle/ui";
import { Pause, CloseSchedule, VoidSchedule, CaretRight } from "@puzzle/icons";

import { ContractRevenueScheduleStatus } from "graphql/types";
import Link from "components/common/Link";
import { Route } from "lib/routes";

import { ScheduleWarningMode } from "../ScheduleWarningMessage";
import { ContractRevenueScheduleFragment } from "../graphql.generated";
import {
  useVoidSchedule,
  usePauseSchedule,
  useActivateSchedule,
  useCloseSchedule,
} from "../useScheduleActions";
import { VoidOrCloseScheduleModal } from "../VoidOrCloseScheduleModal";
import { Box, S } from "ve";
import { ScheduleFormValues } from "../InvoiceForm/types";
import { useActiveCompany } from "components/companies";
import { parseDate } from "@puzzle/utils";
import { useRemainingToRecognize } from "./hooks/useRemainingToRecognize";

type Props = {
  existingSchedule?: ContractRevenueScheduleFragment;
  onOpenChange?: (open: boolean) => void;
  scheduleForm: UseFormReturn<ScheduleFormValues>;
  handleSubmit: () => void;
  readOnly: boolean;
  submitLoading: boolean;
  amount: string;
};

const MenuItem = styled(Menu.Item, {
  display: "flex",
  alignItems: "center",
  gap: "$1h",
});

export const ScheduleDrawerActions = ({
  existingSchedule,
  onOpenChange,
  scheduleForm,
  handleSubmit,
  readOnly,
  submitLoading,
  amount,
}: Props) => {
  const voidSchedule = useVoidSchedule();
  const pauseSchedule = usePauseSchedule();
  const activateSchedule = useActivateSchedule();
  const closeSchedule = useCloseSchedule();
  const router = useRouter();
  const { isWithinLockedPeriod } = useActiveCompany();
  const [mode, setMode] = useState<ScheduleWarningMode>();
  const remainingToRecognize = useRemainingToRecognize({ amount, scheduleForm });
  const canActivate = activateSchedule.isPossibleWhen(existingSchedule?.status);
  const parentInvoiceId = existingSchedule?.invoiceLine?.invoice.id;
  const isOnInvoicePage = router.pathname === `${Route.invoices}/[id]`;
  const loading =
    voidSchedule.loading ||
    pauseSchedule.loading ||
    activateSchedule.loading ||
    closeSchedule.loading ||
    submitLoading;

  const isInLockedPeriod =
    !!existingSchedule &&
    !!existingSchedule.startDay &&
    isWithinLockedPeriod(parseDate(existingSchedule.startDay));

  if (!(readOnly && existingSchedule)) {
    return (
      <Box css={{ display: "flex", justifyContent: "end", gap: S["2"] }}>
        <Button
          variant="minimal"
          onClick={(e) => {
            e.preventDefault();
            onOpenChange?.(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          disabled={!scheduleForm.formState.isValid || remainingToRecognize.lt(0)}
          onClick={handleSubmit}
          loading={loading}
        >
          Save
        </Button>
      </Box>
    );
  }

  return (
    <Box css={{ display: "flex", justifyContent: "flex-end", gap: S["2"] }}>
      <VoidOrCloseScheduleModal
        existingSchedule={existingSchedule}
        open={Boolean(mode)}
        mode={mode}
        onDone={() => {
          setMode(undefined);
          onOpenChange?.(false);
        }}
        onOpenChange={(open) => {
          if (!open) {
            setMode(undefined);
          }
        }}
      />
      {parentInvoiceId && !isOnInvoicePage && (
        <Link href={`${Route.invoices}/${parentInvoiceId}`} target="_blank">
          <Button size="compact" variant="secondary">
            View invoice
          </Button>
        </Link>
      )}
      {existingSchedule.status !== ContractRevenueScheduleStatus.Closed ? (
        <Menu
          side="right"
          align="end"
          trigger={
            <MenuButton variant="primary" size="compact" loading={loading}>
              Manage schedule
            </MenuButton>
          }
        >
          <MenuItem
            disabled={existingSchedule.status === ContractRevenueScheduleStatus.Draft}
            onSelect={async () => {
              const payload = { scheduleId: existingSchedule.id };
              canActivate
                ? await activateSchedule.mutation(payload)
                : await pauseSchedule.mutation(payload);

              onOpenChange?.(false);
            }}
          >
            {canActivate ? (
              <>
                <CaretRight size={12} />
                Resume recognition
              </>
            ) : (
              <>
                <Pause />
                Pause Recognition
              </>
            )}
          </MenuItem>
          <MenuItem
            onSelect={() => setMode("close")}
            disabled={existingSchedule.status === ContractRevenueScheduleStatus.Draft}
          >
            <CloseSchedule />
            Recognize & close
          </MenuItem>
          <Menu.Separator />
          <Tooltip content={isInLockedPeriod ? "Cannot void schedules in an locked period" : ""}>
            <div>
              <MenuItem
                negative={!isInLockedPeriod}
                disabled={isInLockedPeriod}
                onSelect={() => {
                  if (existingSchedule.status === ContractRevenueScheduleStatus.Draft) {
                    voidSchedule.mutation({ scheduleId: existingSchedule.id });
                    onOpenChange?.(false);
                    return;
                  }

                  setMode("void");
                }}
              >
                <VoidSchedule />
                {existingSchedule.status === ContractRevenueScheduleStatus.Draft
                  ? "Delete"
                  : "Void"}{" "}
                schedule
              </MenuItem>
            </div>
          </Tooltip>
        </Menu>
      ) : (
        <>
          <Tooltip content={isInLockedPeriod ? "Cannot void schedules in a locked period" : ""}>
            <div>
              <Button
                size="compact"
                variant="negative"
                disabled={isInLockedPeriod}
                onClick={() => setMode("void")}
              >
                <VoidSchedule />
                &nbsp;Void Schedule
              </Button>
            </div>
          </Tooltip>
        </>
      )}
    </Box>
  );
};
