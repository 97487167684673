import { styled } from "./stitches";
import { Menu } from "./Menu";
import React from "react";

const Container = styled("div", {
  cursor: "pointer",
  display: "grid",
  gridTemplateColumns: "30px auto",
  rowGap: "$1h",
  alignItems: "center",
  borderRadius: "$2",
  padding: "$2",
  background: "rgba(255, 255, 255, 0.04)",
  marginBottom: "$1",
  width: "100%",
  position: "relative",

  "&:last-of-type": {
    marginBottom: 0,
  },
  "&[aria-disabled=true]": {
    pointerEvents: "none ",
  },
});

export const OptionCard = ({
  onClick,
  checked,
  children,
  css,
  disabled,
}: React.PropsWithChildren<{
  onClick: () => void;
  checked: boolean;
  css?: React.CSSProperties;
  disabled?: boolean;
}>) => {
  return (
    <Container onClick={onClick} style={css} aria-disabled={disabled}>
      <Menu.RadioSymbol checked={checked} />
      {children}
    </Container>
  );
};
