import { styled } from "@puzzle/ui";
import { LedgerAccount } from "graphql/types";

export const getAccountLabel = (
  account?: Pick<LedgerAccount, "parentDisplayId" | "displayId" | "displayName">,
  addSubAccountIndicator = true
): string => {
  if (!account) {
    return "";
  }
  const subaccountString = addSubAccountIndicator && account?.parentDisplayId ? "⎿ " : "";
  return `${subaccountString} ${account?.displayId} - ${account?.displayName}`;
};

export const ButtonGroup = styled("div", {
  display: "flex",
  flexDirection: "row",
});

export const Separator = styled("div", {
  borderLeft: "1px solid $green700",
  height: "100%",
});
