import { styled } from "./stitches";

export const StatusIndicator = styled("div", {
  defaultVariants: {
    status: "neutral",
    shape: "circle",
  },
  variants: {
    shape: {
      circle: {
        borderRadius: "$ellipse",
        height: "10px",
        width: "10px",
      },
      bar: {
        borderRadius: "2px",
        height: "16px",
        width: "3px",
      },
    },
    status: {
      neutral: {
        background: "$gray300",
      },
      positive: {
        // IDK all the tokens are switched up on me
        // background: "$green800",
        background: "$greenalpha",
      },
      negative: {
        background: "$red700",
      },
      warning: {
        background: "#F2A87E",
      },
    },
  },
});
