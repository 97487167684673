import { IntegrationType } from "graphql/types";
import React from "react";
import Logo from "./Logo";
import { IntegrationsListItemBaseProps } from "../List";
import { IntegrationDisplayComponent } from "../shared/types";

interface SetupCaptableListItemProps {
  DisplayComponent: IntegrationDisplayComponent<
    React.PropsWithChildren<IntegrationsListItemBaseProps>
  >;
  companyId: string;
}

export const TITLE = "Captable.io";
export const SUBTITLE = "Link cap table data for the full picture of your company.";

const SetupCaptableListItem = ({ companyId, DisplayComponent }: SetupCaptableListItemProps) => {
  return (
    <DisplayComponent
      integrationType={IntegrationType.Captable}
      title={TITLE}
      subtitle={SUBTITLE}
      logo={<Logo />}
    />
  );
};

export default SetupCaptableListItem;
