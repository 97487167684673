import React, { useMemo } from "react";
import BaseCategorySearch from "components/transactions/CategorySearch";
import { CategoryFragment, LedgerAccountType } from "graphql/types";
import useCategories from "components/common/hooks/useCategories";
import { Dictionary, filter } from "lodash";
import { Box, S } from "ve";

// TODO This will be weird if the window height is really small
const CategorySearch = ({
  selected,
  onSelect,
  categoryTypes,
  subset,
}: {
  onSelect: (category: CategoryFragment) => void;
  selected?: CategoryFragment;
  categoryTypes?: Dictionary<{
    id: string;
    type: LedgerAccountType;
  }>;
  subset?: string;
}) => {
  const { categories } = useCategories();
  const expenseCategories = useMemo(
    () => filter(categoryTypes, (c) => c.type === LedgerAccountType.Expense),
    [categoryTypes]
  );
  const revenueCategories = useMemo(
    () => filter(categoryTypes, (c) => c.type === LedgerAccountType.Revenue),
    [categoryTypes]
  );

  const filteredOptions = useMemo(() => {
    if (subset === "expenses") {
      return categories.filter((c) => expenseCategories.map((c) => c.id).includes(c.coaDisplayId!));
    }
    if (subset === "income") {
      return categories.filter((c) => revenueCategories.map((c) => c.id).includes(c.coaDisplayId!));
    }
    return categories;
  }, [categories, subset, expenseCategories, revenueCategories]);

  return (
    categories && (
      <Box css={{ height: 490, margin: `0 -${S["3"]}`, overflow: "hidden" }}>
        <BaseCategorySearch
          initialValue={selected}
          categories={filteredOptions}
          onChange={onSelect}
          categoryTypes={categoryTypes}
        />
      </Box>
    )
  );
};

export default CategorySearch;
