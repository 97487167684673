import React from "react";
import * as ToastPrimitive from "@radix-ui/react-toast";

import { styled } from "../stitches";
import { ToasterContext, useToastsInternal } from "./useToastsInternal";
import { createRectRef, VIEWPORT_PADDING, ANIMATION_TIMING } from "./common";
import { Toast } from "./Toast";

const ToastViewport = styled(ToastPrimitive.Viewport, {
  position: "fixed",
  bottom: 0,
  left: "50%",
  right: 0,
  transform: "translateX(-50%)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "auto",
  maxWidth: 380,
  gap: "$2",
  padding: 0,
  margin: VIEWPORT_PADDING,
  listStyle: "none",
  zIndex: "$max",
  outline: "none",
});

export const ToastProvider = ({ children, ...props }: ToastPrimitive.ToastProviderProps) => {
  const value = useToastsInternal();
  const { toasts, updateToast, removeToast, calculateOffset } = value;

  // For testing:
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     value.toast({
  //       message:
  //         Math.random() < 0.7
  //           ? "fdsfdsfs fdsf sdfds fdsf dsfdsf dsfdsf dsfs fdsfdsfdsfdsf dsfsd fdsfdsfdsfdsf dsfsd "
  //           : "hello world",
  //     });
  //   }, 2000);
  //   return () => clearInterval(interval);
  // }, []);

  return (
    <ToasterContext.Provider value={value}>
      <ToastPrimitive.Provider {...props}>
        {children}

        {toasts.map((toast, i) => {
          const offset = calculateOffset(toast.id);
          return (
            <Toast
              {...toast}
              ref={
                toast.height
                  ? undefined
                  : createRectRef((rect) => {
                      updateToast(toast.id, { height: rect.height });
                    })
              }
              open={toast.open}
              onClose={() => {
                updateToast(toast.id, { open: false });
                setTimeout(() => removeToast(toast.id), ANIMATION_TIMING.close);
              }}
              data-initialized={toast.height > 0}
              style={{
                // @ts-expect-error ignore
                "--puzzle-toast-vertical-offset": toast.height === 0 ? "150%" : `${offset}px`,
                zIndex: toasts.length - i,
              }}
              key={toast.id}
            />
          );
        })}
        <ToastViewport />
      </ToastPrimitive.Provider>
    </ToasterContext.Provider>
  );
};

export const useToasts = () => {
  const context = React.useContext(ToasterContext);
  if (!context) {
    throw new Error("useToasts must be used inside ToastProvider");
  }
  return context;
};
