import * as Types from '../types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
import { AccountFragmentDoc, AccountWithIntegrationFragmentDoc } from './account.generated';
export type FinancialInstitutionFragment = { __typename?: 'FinancialInstitution', id: string, name: string, nativeInstitutionId?: string | null, accounts: Array<{ __typename?: 'Account', id: string, manuallyAdded: boolean, name: string, subType?: Types.AccountSubType | null, type: Types.AccountType, mask?: string | null, status: Types.IntegrationConnectionStatus, updatedAt: CalendarDateTimeString, lastImportedAt?: CalendarDateTimeString | null, archivedAt?: CalendarDateTimeString | null, connection?: { __typename?: 'IntegrationConnection', id: string, createdAt: CalendarDateTimeString, type: Types.IntegrationType, ingressType: Types.IngressType, status: Types.IntegrationConnectionStatus, message?: string | null, errorCode?: Types.DisconnectionErrorCode | null, lastSyncedAt?: CalendarDateTimeString | null, condition?: Types.IntegrationConnectionCondition | null, latestSync?: { __typename?: 'SyncRun', id: any, status: Types.SyncRunStatus } | null } | null, financialInstitution: { __typename?: 'FinancialInstitution', id: string, name: string }, initialAccountBalance: { __typename?: 'AccountBalance', balance: string, date: CalendarDateTimeString, splits: Array<{ __typename?: 'AccountBalanceSplit', amount: string, description: string, category: { __typename?: 'GatewayCategory', permaKey: string, name: string, displayName?: string | null } | { __typename?: 'LedgerCategory', permaKey: string, name: string, displayName: string } }> }, todaysBalance: { __typename?: 'AccountBalance', balance: string }, epoch?: { __typename?: 'AccountEpoch', cutoffBefore?: CalendarDateString | null, cutoffAtOrAfter?: CalendarDateString | null } | null }> };

export const FinancialInstitutionFragmentDoc = gql`
    fragment financialInstitution on FinancialInstitution {
  id
  name
  nativeInstitutionId
  accounts {
    ...account
  }
}
    ${AccountFragmentDoc}`;