import React from "react";
import { colors, styled, Tag } from "@puzzle/ui";
import { AccountWithIntegrationFragment, IntegrationConnectionStatus } from "graphql/types";
import { PendingAccount } from "../shared";
import Loader from "../../common/Loader";

export const ConnectionSubText = styled("div", {
  textVariant: "$bodyS",

  defaultVariants: {
    status: "ok",
  },

  variants: {
    status: {
      warning: {
        color: colors.yellow600,
      },
      error: {
        color: colors.red500,
      },
      ok: {
        color: colors.gray400,
      },
    },
  },
});

export const Header = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "$1",
  alignItems: "center",
  borderBottom: "1px solid #43445B",
  marginBottom: "$2",
});

export const HeaderGroup = styled("div", {
  display: "flex",
  alignItems: "center",
});

export const TextGroup = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "$0h",
  paddingBottom: "$1",
});

export const Label = styled("div", {
  textVariant: "$headingS",
  fontWeight: "bold",
  color: "$gray300",
});

export const DetailText = styled("div", {
  color: "$gray400",
  textVariant: "$bodyS",
});

export const Logo = styled("div", {
  width: 48,
  height: 48,
  margin: "0 auto",
  img: {
    width: "100%",
    height: "auto",
  },
  marginBottom: "$2",
});

export const Footer = styled("div", {
  textVariant: "$bodyS",
  color: "$gray500",
  display: "flex",
  width: "100%",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "$2",
  borderTop: "1px solid #43445B",
  paddingTop: "$3",
  marginTop: "auto",
});

export const Activity = styled("div", {
  textVariant: "$bodyXS",
  color: "$gray600",
  display: "grid",
  gridAutoFlow: "row",
  gridAutoRows: "min-content",
  gridGap: "$1h",
  whiteSpace: "pre-line",
  textAlign: "left",
});

export const getIntegrationStatusTag = (
  status: IntegrationConnectionStatus | undefined,
  isManualAccount: boolean
) => {
  if (isManualAccount) {
    return <Tag color="inactive">No connection</Tag>;
  }

  if (!status || status === IntegrationConnectionStatus.Disconnected) {
    return <Tag color="warning">Disconnected</Tag>;
  }

  return <Tag color="coolMint">Connected</Tag>;
};

export const getAccountStatusTag = (
  account: AccountWithIntegrationFragment,
  pendingAccounts: PendingAccount[],
  size?: "small" | undefined
) => {
  const pendingAccount = pendingAccounts.find(
    (pendingAccount) => pendingAccount.accountId === account.id
  );

  if (pendingAccount) {
    return (
      <Tag color="active" size={size}>
        {pendingAccount?.pendingLabel}
        <Loader size={size === "small" ? 10 : 12} />
      </Tag>
    );
  }

  if (account.manuallyAdded) {
    return (
      <Tag color="inactive" size={size}>
        Manual
      </Tag>
    );
  }

  if (account.archivedAt) {
    return (
      <Tag color="inactive" size={size}>
        Archived
      </Tag>
    );
  }

  if (account.status === IntegrationConnectionStatus.Disconnected) {
    return (
      <Tag color="warning" size={size}>
        Inactive
      </Tag>
    );
  }

  return (
    <Tag color="coolMint" size={size}>
      Active
    </Tag>
  );
};
