import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Check({
  className,
  rotate,
  size = 16,
  viewBox = "0 0 16 16",
  color = "currentColor",
  stroke = color,
  ...props
}: IconProps) {
  return (
    <Icon className={className} rotate={rotate} viewBox={viewBox} size={size} {...props}>
      <path
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3.5 8.25l3 3.5 6-8"
      ></path>
    </Icon>
  );
}
