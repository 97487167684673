import * as Types from '../types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
import { FileFragmentDoc } from './file.generated';
export type VendorFragment = { __typename?: 'Vendor', id: string, permaName: string, name: string, description?: string | null, is1099Vendor: Types.YesNoUnknown, status?: Types.VendorStatus | null, type?: Types.VendorType | null };

export type VendorWithDetailsFragment = { __typename?: 'Vendor', id: string, name: string, addressJson?: any | null, companyName?: string | null, contactName?: string | null, currency?: any | null, description?: string | null, email?: string | null, is1099Vendor: Types.YesNoUnknown, phoneNumber?: string | null, status?: Types.VendorStatus | null, taxIdType?: Types.TaxIdType | null, type?: Types.VendorType | null, website?: string | null, documents: Array<{ __typename?: 'VendorDocument', file: { __typename?: 'File', id: string, filename?: string | null, contentType: string, size: number, isDeleted: boolean, downloadInfo: { __typename?: 'FileDownloadInfo', signedUrl: string, urlExpiresAt: CalendarDateTimeString } } }> };

export const VendorFragmentDoc = gql`
    fragment vendor on Vendor {
  id
  permaName
  name
  description
  is1099Vendor
  status
  type
}
    `;
export const VendorWithDetailsFragmentDoc = gql`
    fragment vendorWithDetails on Vendor {
  id
  name
  addressJson
  companyName
  contactName
  currency
  description
  documents {
    file {
      ...file
    }
  }
  email
  is1099Vendor
  name
  phoneNumber
  status
  taxIdType
  type
  website
}
    ${FileFragmentDoc}`;