import React from "react";
import Icon, { IconProps } from "./Icon";

export default function BillFile({
  viewBox = "0 0 16 16",
  color = "currentColor",
  fill = color,
  ...iconProps
}: IconProps) {
  return (
    <Icon {...iconProps} viewBox={viewBox}>
      <path
        fill={fill}
        d="M7.978 4a.5.5 0 01.5.5v.41a2.381 2.381 0 011.476.907.5.5 0 11-.798.603 1.381 1.381 0 00-1.178-.547h-.004c-.383 0-.712.12-.935.287-.225.168-.315.36-.315.526 0 .166.09.359.315.527.224.168.555.287.94.287.578 0 1.122.172 1.533.478.413.307.712.77.712 1.327 0 .556-.299 1.02-.71 1.33-.29.218-.647.37-1.036.44v.417a.5.5 0 01-1 0v-.41a2.384 2.384 0 01-1.475-.908.5.5 0 01.798-.603 1.381 1.381 0 001.157.548h.02c.386 0 .715-.118.935-.284.22-.165.311-.357.311-.53 0-.172-.09-.362-.31-.525-.22-.164-.548-.28-.936-.28-.581 0-1.127-.178-1.539-.487-.411-.308-.715-.772-.715-1.327 0-.554.304-1.018.715-1.326.29-.219.649-.372 1.04-.442V4.5a.5.5 0 01.5-.5z"
      ></path>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M2.5 1a.5.5 0 00-.5.5v13a.5.5 0 00.5.5h11a.5.5 0 00.5-.5V4.182a.5.5 0 00-.15-.356L11.13 1.144A.5.5 0 0010.778 1H2.5zM3 14V2h7.573L13 4.391V14H3z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
}
