import React from "react";
import { IntegrationLogo } from "../../shared";
import { ChaseAlt } from "@puzzle/icons";

const ChaseLogo = () => {
  return (
    <IntegrationLogo color="#fff">
      <ChaseAlt />
    </IntegrationLogo>
  );
};

export default ChaseLogo;
