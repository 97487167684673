import { Text, Stack, styled } from "@puzzle/ui";
import { AccountWithIntegrationFragment, IntegrationConnectionStatus } from "graphql/types";
import React, { ReactNode } from "react";
import { Label } from "./shared";
import DescriptionList from "../../common/DescriptionList";
import Link from "../../common/Link";

const AccountRowBase = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  cursor: "pointer",
  border: "1px solid $rhino600",
  borderRadius: "$1",
  padding: "$1h $2",

  "&:hover": {
    borderColor: "$gray600",
  },

  "&:focus": {
    borderColor: "$gray500",
  },
});

const AccountRow = ({ account }: { account: AccountWithIntegrationFragment }) => {
  let title = account.name;
  if (account.mask) {
    title += ` - ${account.mask}`;
  }

  if (account.archivedAt) {
    title += " (Archived)";
  }

  const textColor = account.archivedAt ? "gray600" : "gray200";

  return (
    <AccountRowBase>
      <Text weight="bold" color={textColor}>
        {title}
      </Text>
      <Text variant="bodyXS" color={textColor}>
        {account.type}
      </Text>
    </AccountRowBase>
  );
};

const IntegrationAccountList = ({ accounts }: { accounts: AccountWithIntegrationFragment[] }) => {
  const connectedAccounts: AccountWithIntegrationFragment[] = [];
  const otherAccounts: AccountWithIntegrationFragment[] = [];

  for (const account of accounts) {
    if (
      account.archivedAt ||
      account.manuallyAdded ||
      account.status === IntegrationConnectionStatus.Disconnected
    ) {
      otherAccounts.push(account);
    } else {
      connectedAccounts.push(account);
    }
  }

  const items: [ReactNode, ReactNode][] = [];

  if (connectedAccounts.length > 0) {
    items.push([
      <Label key="connected-accounts">Connected Accounts</Label>,
      <Stack key="connected-accounts" gap="0h">
        {connectedAccounts.map((account) => (
          <Link key={account.id} href={`/integrations/account/${account.id}`}>
            <AccountRow key={account.id} account={account} />
          </Link>
        ))}
      </Stack>,
    ]);
  }

  if (otherAccounts.length > 0) {
    items.push([
      <Label key="other-accounts">Other Accounts</Label>,
      <Stack key="other-accounts" gap="0h">
        {otherAccounts.map((account) => (
          <Link key={account.id} href={`/integrations/account/${account.id}`}>
            <AccountRow key={account.id} account={account} />
          </Link>
        ))}
      </Stack>,
    ]);
  }

  return (
    <DescriptionList
      itemGap="1"
      direction="vertical"
      css={{ width: "100%" }}
      termCss={{ paddingTop: "$1" }}
      items={items}
    ></DescriptionList>
  );
};
export default IntegrationAccountList;
