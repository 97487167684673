import React from "react";
import { Every } from "@puzzle/icons";
import { IntegrationLogo } from "../shared";

const Logo = ({ circular }: { circular?: boolean }) => {
  return (
    <IntegrationLogo color="#000000" width="48px" height="48px" circular={circular}>
      <Every width={40} />
    </IntegrationLogo>
  );
};

export default Logo;
