import React from "react";
import cslx from "clsx";

import { stackRecipe, StackVariants } from "./stackStyles.css";

export const Stack = ({
  direction,
  gap,
  css,
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement> &
  StackVariants & {
    gap?: string;
    css?: React.CSSProperties;
    children: React.ReactNode;
  }) => {
  const variantClass = stackRecipe({ direction });
  const gapStyle = gap ? { gap } : {};
  const className = cslx(variantClass, props.className);

  return (
    <div style={{ ...gapStyle, ...css }} {...props} className={className}>
      {children}
    </div>
  );
};
