import * as Types from '../../../graphql/types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCompanyMembershipMutationVariables = Types.Exact<{
  input: Types.UpdateCompanyMembershipInput;
}>;


export type UpdateCompanyMembershipMutation = { __typename?: 'Mutation', updateCompanyMembership?: { __typename?: 'CompanyMembership', id: string, status: Types.MembershipStatus, role: Types.MembershipRole, createdAt: CalendarDateTimeString, updatedAt: CalendarDateTimeString, mfaRequired?: boolean | null, position?: Types.UserPosition | null, userId: string, user: { __typename?: 'User', id?: string | null, name?: string | null, email?: string | null, createdAt: CalendarDateTimeString, updatedAt: CalendarDateTimeString, lastLogin?: CalendarDateTimeString | null, loginsCount: number, lastIp?: string | null, invitedBy?: { __typename?: 'User', id?: string | null, name?: string | null, email?: string | null } | null } } | null };

export type UpdateCompanyMembershipAsUserMutationVariables = Types.Exact<{
  input: Types.UpdateCompanyMembershipAsUserInput;
}>;


export type UpdateCompanyMembershipAsUserMutation = { __typename?: 'Mutation', updateCompanyMembershipAsUser?: { __typename?: 'CompanyMembership', id: string, position?: Types.UserPosition | null } | null };

export type RemoveCompanyMembershipMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type RemoveCompanyMembershipMutation = { __typename?: 'Mutation', removeCompanyMembership: { __typename?: 'SuccessResult', success: boolean } };

export type InviteToCompanyMutationVariables = Types.Exact<{
  input: Types.InviteToCompanyInput;
}>;


export type InviteToCompanyMutation = { __typename?: 'Mutation', inviteToCompany: { __typename?: 'InviteToCompanyResult', successEmails?: Array<string> | null } };

export type UpdateCompanyInvitationMutationVariables = Types.Exact<{
  input: Types.UpdateCompanyInvitationInput;
}>;


export type UpdateCompanyInvitationMutation = { __typename?: 'Mutation', updateCompanyInvitation?: { __typename?: 'CompanyInvitation', id: string, email: string, companyId: string, mfaRequired?: boolean | null, role: Types.MembershipRole, createdAt: CalendarDateTimeString, updatedAt: CalendarDateTimeString } | null };

export type ResendCompanyInvitationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type ResendCompanyInvitationMutation = { __typename?: 'Mutation', resendCompanyInvitation: { __typename?: 'SuccessResult', success: boolean } };

export type RemoveCompanyInvitationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type RemoveCompanyInvitationMutation = { __typename?: 'Mutation', removeCompanyInvitation: { __typename?: 'SuccessResult', success: boolean } };

export type GetUserPageCompanyQueryVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
}>;


export type GetUserPageCompanyQuery = { __typename?: 'Query', company?: { __typename?: 'Company', id: string, name: string, createdAt: CalendarDateTimeString, updatedAt: CalendarDateTimeString, mfaRequired: boolean, owner?: { __typename?: 'User', id?: string | null } | null, memberships: Array<{ __typename?: 'CompanyMembership', id: string, status: Types.MembershipStatus, role: Types.MembershipRole, createdAt: CalendarDateTimeString, updatedAt: CalendarDateTimeString, mfaRequired?: boolean | null, position?: Types.UserPosition | null, userId: string, user: { __typename?: 'User', id?: string | null, name?: string | null, email?: string | null, createdAt: CalendarDateTimeString, updatedAt: CalendarDateTimeString, lastLogin?: CalendarDateTimeString | null, loginsCount: number, lastIp?: string | null, invitedBy?: { __typename?: 'User', id?: string | null, name?: string | null, email?: string | null } | null } }>, pendingInvitations: Array<{ __typename?: 'CompanyInvitation', id: string, email: string, companyId: string, mfaRequired?: boolean | null, role: Types.MembershipRole, createdAt: CalendarDateTimeString, updatedAt: CalendarDateTimeString }> } | null };

export type GetUserRolesForCompanyQueryVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
}>;


export type GetUserRolesForCompanyQuery = { __typename?: 'Query', company?: { __typename?: 'Company', id: string, memberships: Array<{ __typename?: 'CompanyMembership', id: string, role: Types.MembershipRole }>, pendingInvitations: Array<{ __typename?: 'CompanyInvitation', id: string, role: Types.MembershipRole }> } | null };

export type UserPageCompanyFragment = { __typename?: 'Company', id: string, name: string, createdAt: CalendarDateTimeString, updatedAt: CalendarDateTimeString, mfaRequired: boolean, owner?: { __typename?: 'User', id?: string | null } | null, memberships: Array<{ __typename?: 'CompanyMembership', id: string, status: Types.MembershipStatus, role: Types.MembershipRole, createdAt: CalendarDateTimeString, updatedAt: CalendarDateTimeString, mfaRequired?: boolean | null, position?: Types.UserPosition | null, userId: string, user: { __typename?: 'User', id?: string | null, name?: string | null, email?: string | null, createdAt: CalendarDateTimeString, updatedAt: CalendarDateTimeString, lastLogin?: CalendarDateTimeString | null, loginsCount: number, lastIp?: string | null, invitedBy?: { __typename?: 'User', id?: string | null, name?: string | null, email?: string | null } | null } }>, pendingInvitations: Array<{ __typename?: 'CompanyInvitation', id: string, email: string, companyId: string, mfaRequired?: boolean | null, role: Types.MembershipRole, createdAt: CalendarDateTimeString, updatedAt: CalendarDateTimeString }> };

export type UserPageCompanyLiteFragment = { __typename?: 'Company', id: string, memberships: Array<{ __typename?: 'CompanyMembership', id: string, role: Types.MembershipRole }>, pendingInvitations: Array<{ __typename?: 'CompanyInvitation', id: string, role: Types.MembershipRole }> };

export type UserPageMembershipFragment = { __typename?: 'CompanyMembership', id: string, status: Types.MembershipStatus, role: Types.MembershipRole, createdAt: CalendarDateTimeString, updatedAt: CalendarDateTimeString, mfaRequired?: boolean | null, position?: Types.UserPosition | null, userId: string, user: { __typename?: 'User', id?: string | null, name?: string | null, email?: string | null, createdAt: CalendarDateTimeString, updatedAt: CalendarDateTimeString, lastLogin?: CalendarDateTimeString | null, loginsCount: number, lastIp?: string | null, invitedBy?: { __typename?: 'User', id?: string | null, name?: string | null, email?: string | null } | null } };

export type UserPageMembershipLiteFragment = { __typename?: 'CompanyMembership', id: string, role: Types.MembershipRole };

export type UserPageInvitationFragment = { __typename?: 'CompanyInvitation', id: string, email: string, companyId: string, mfaRequired?: boolean | null, role: Types.MembershipRole, createdAt: CalendarDateTimeString, updatedAt: CalendarDateTimeString };

export type UserPageInvitationLiteFragment = { __typename?: 'CompanyInvitation', id: string, role: Types.MembershipRole };

export type UserPageUserFragment = { __typename?: 'User', id?: string | null, name?: string | null, email?: string | null, createdAt: CalendarDateTimeString, updatedAt: CalendarDateTimeString, lastLogin?: CalendarDateTimeString | null, loginsCount: number, lastIp?: string | null, invitedBy?: { __typename?: 'User', id?: string | null, name?: string | null, email?: string | null } | null };

export const UserPageUserFragmentDoc = gql`
    fragment userPageUser on User {
  id
  name
  email
  createdAt
  updatedAt
  lastLogin
  loginsCount
  lastIp
  invitedBy {
    id
    name
    email
  }
}
    `;
export const UserPageMembershipFragmentDoc = gql`
    fragment userPageMembership on CompanyMembership {
  id
  status
  role
  createdAt
  updatedAt
  mfaRequired
  position
  userId
  user {
    ...userPageUser
  }
}
    ${UserPageUserFragmentDoc}`;
export const UserPageInvitationFragmentDoc = gql`
    fragment userPageInvitation on CompanyInvitation {
  id
  email
  companyId
  mfaRequired
  role
  createdAt
  updatedAt
}
    `;
export const UserPageCompanyFragmentDoc = gql`
    fragment userPageCompany on Company {
  id
  name
  createdAt
  updatedAt
  mfaRequired
  owner {
    id
  }
  memberships {
    ...userPageMembership
  }
  pendingInvitations {
    ...userPageInvitation
  }
}
    ${UserPageMembershipFragmentDoc}
${UserPageInvitationFragmentDoc}`;
export const UserPageMembershipLiteFragmentDoc = gql`
    fragment userPageMembershipLite on CompanyMembership {
  id
  role
}
    `;
export const UserPageInvitationLiteFragmentDoc = gql`
    fragment userPageInvitationLite on CompanyInvitation {
  id
  role
}
    `;
export const UserPageCompanyLiteFragmentDoc = gql`
    fragment userPageCompanyLite on Company {
  id
  memberships {
    ...userPageMembershipLite
  }
  pendingInvitations {
    ...userPageInvitationLite
  }
}
    ${UserPageMembershipLiteFragmentDoc}
${UserPageInvitationLiteFragmentDoc}`;
export const UpdateCompanyMembershipDocument = gql`
    mutation updateCompanyMembership($input: UpdateCompanyMembershipInput!) {
  updateCompanyMembership(input: $input) {
    ...userPageMembership
  }
}
    ${UserPageMembershipFragmentDoc}`;
export type UpdateCompanyMembershipMutationFn = Apollo.MutationFunction<UpdateCompanyMembershipMutation, UpdateCompanyMembershipMutationVariables>;

/**
 * __useUpdateCompanyMembershipMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMembershipMutation, { data, loading, error }] = useUpdateCompanyMembershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyMembershipMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMembershipMutation, UpdateCompanyMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyMembershipMutation, UpdateCompanyMembershipMutationVariables>(UpdateCompanyMembershipDocument, options);
      }
export type UpdateCompanyMembershipMutationHookResult = ReturnType<typeof useUpdateCompanyMembershipMutation>;
export type UpdateCompanyMembershipMutationResult = Apollo.MutationResult<UpdateCompanyMembershipMutation>;
export type UpdateCompanyMembershipMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyMembershipMutation, UpdateCompanyMembershipMutationVariables>;
export const UpdateCompanyMembershipAsUserDocument = gql`
    mutation updateCompanyMembershipAsUser($input: UpdateCompanyMembershipAsUserInput!) {
  updateCompanyMembershipAsUser(input: $input) {
    id
    position
  }
}
    `;
export type UpdateCompanyMembershipAsUserMutationFn = Apollo.MutationFunction<UpdateCompanyMembershipAsUserMutation, UpdateCompanyMembershipAsUserMutationVariables>;

/**
 * __useUpdateCompanyMembershipAsUserMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMembershipAsUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMembershipAsUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMembershipAsUserMutation, { data, loading, error }] = useUpdateCompanyMembershipAsUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyMembershipAsUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMembershipAsUserMutation, UpdateCompanyMembershipAsUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyMembershipAsUserMutation, UpdateCompanyMembershipAsUserMutationVariables>(UpdateCompanyMembershipAsUserDocument, options);
      }
export type UpdateCompanyMembershipAsUserMutationHookResult = ReturnType<typeof useUpdateCompanyMembershipAsUserMutation>;
export type UpdateCompanyMembershipAsUserMutationResult = Apollo.MutationResult<UpdateCompanyMembershipAsUserMutation>;
export type UpdateCompanyMembershipAsUserMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyMembershipAsUserMutation, UpdateCompanyMembershipAsUserMutationVariables>;
export const RemoveCompanyMembershipDocument = gql`
    mutation removeCompanyMembership($id: ID!) {
  removeCompanyMembership(id: $id) {
    success
  }
}
    `;
export type RemoveCompanyMembershipMutationFn = Apollo.MutationFunction<RemoveCompanyMembershipMutation, RemoveCompanyMembershipMutationVariables>;

/**
 * __useRemoveCompanyMembershipMutation__
 *
 * To run a mutation, you first call `useRemoveCompanyMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCompanyMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCompanyMembershipMutation, { data, loading, error }] = useRemoveCompanyMembershipMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCompanyMembershipMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCompanyMembershipMutation, RemoveCompanyMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCompanyMembershipMutation, RemoveCompanyMembershipMutationVariables>(RemoveCompanyMembershipDocument, options);
      }
export type RemoveCompanyMembershipMutationHookResult = ReturnType<typeof useRemoveCompanyMembershipMutation>;
export type RemoveCompanyMembershipMutationResult = Apollo.MutationResult<RemoveCompanyMembershipMutation>;
export type RemoveCompanyMembershipMutationOptions = Apollo.BaseMutationOptions<RemoveCompanyMembershipMutation, RemoveCompanyMembershipMutationVariables>;
export const InviteToCompanyDocument = gql`
    mutation inviteToCompany($input: InviteToCompanyInput!) {
  inviteToCompany(input: $input) {
    successEmails
  }
}
    `;
export type InviteToCompanyMutationFn = Apollo.MutationFunction<InviteToCompanyMutation, InviteToCompanyMutationVariables>;

/**
 * __useInviteToCompanyMutation__
 *
 * To run a mutation, you first call `useInviteToCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteToCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteToCompanyMutation, { data, loading, error }] = useInviteToCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteToCompanyMutation(baseOptions?: Apollo.MutationHookOptions<InviteToCompanyMutation, InviteToCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteToCompanyMutation, InviteToCompanyMutationVariables>(InviteToCompanyDocument, options);
      }
export type InviteToCompanyMutationHookResult = ReturnType<typeof useInviteToCompanyMutation>;
export type InviteToCompanyMutationResult = Apollo.MutationResult<InviteToCompanyMutation>;
export type InviteToCompanyMutationOptions = Apollo.BaseMutationOptions<InviteToCompanyMutation, InviteToCompanyMutationVariables>;
export const UpdateCompanyInvitationDocument = gql`
    mutation updateCompanyInvitation($input: UpdateCompanyInvitationInput!) {
  updateCompanyInvitation(input: $input) {
    ...userPageInvitation
  }
}
    ${UserPageInvitationFragmentDoc}`;
export type UpdateCompanyInvitationMutationFn = Apollo.MutationFunction<UpdateCompanyInvitationMutation, UpdateCompanyInvitationMutationVariables>;

/**
 * __useUpdateCompanyInvitationMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyInvitationMutation, { data, loading, error }] = useUpdateCompanyInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyInvitationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyInvitationMutation, UpdateCompanyInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyInvitationMutation, UpdateCompanyInvitationMutationVariables>(UpdateCompanyInvitationDocument, options);
      }
export type UpdateCompanyInvitationMutationHookResult = ReturnType<typeof useUpdateCompanyInvitationMutation>;
export type UpdateCompanyInvitationMutationResult = Apollo.MutationResult<UpdateCompanyInvitationMutation>;
export type UpdateCompanyInvitationMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyInvitationMutation, UpdateCompanyInvitationMutationVariables>;
export const ResendCompanyInvitationDocument = gql`
    mutation resendCompanyInvitation($id: ID!) {
  resendCompanyInvitation(id: $id) {
    success
  }
}
    `;
export type ResendCompanyInvitationMutationFn = Apollo.MutationFunction<ResendCompanyInvitationMutation, ResendCompanyInvitationMutationVariables>;

/**
 * __useResendCompanyInvitationMutation__
 *
 * To run a mutation, you first call `useResendCompanyInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendCompanyInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendCompanyInvitationMutation, { data, loading, error }] = useResendCompanyInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResendCompanyInvitationMutation(baseOptions?: Apollo.MutationHookOptions<ResendCompanyInvitationMutation, ResendCompanyInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendCompanyInvitationMutation, ResendCompanyInvitationMutationVariables>(ResendCompanyInvitationDocument, options);
      }
export type ResendCompanyInvitationMutationHookResult = ReturnType<typeof useResendCompanyInvitationMutation>;
export type ResendCompanyInvitationMutationResult = Apollo.MutationResult<ResendCompanyInvitationMutation>;
export type ResendCompanyInvitationMutationOptions = Apollo.BaseMutationOptions<ResendCompanyInvitationMutation, ResendCompanyInvitationMutationVariables>;
export const RemoveCompanyInvitationDocument = gql`
    mutation removeCompanyInvitation($id: ID!) {
  removeCompanyInvitation(id: $id) {
    success
  }
}
    `;
export type RemoveCompanyInvitationMutationFn = Apollo.MutationFunction<RemoveCompanyInvitationMutation, RemoveCompanyInvitationMutationVariables>;

/**
 * __useRemoveCompanyInvitationMutation__
 *
 * To run a mutation, you first call `useRemoveCompanyInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCompanyInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCompanyInvitationMutation, { data, loading, error }] = useRemoveCompanyInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCompanyInvitationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCompanyInvitationMutation, RemoveCompanyInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCompanyInvitationMutation, RemoveCompanyInvitationMutationVariables>(RemoveCompanyInvitationDocument, options);
      }
export type RemoveCompanyInvitationMutationHookResult = ReturnType<typeof useRemoveCompanyInvitationMutation>;
export type RemoveCompanyInvitationMutationResult = Apollo.MutationResult<RemoveCompanyInvitationMutation>;
export type RemoveCompanyInvitationMutationOptions = Apollo.BaseMutationOptions<RemoveCompanyInvitationMutation, RemoveCompanyInvitationMutationVariables>;
export const GetUserPageCompanyDocument = gql`
    query getUserPageCompany($companyId: ID!) {
  company(id: $companyId) {
    ...userPageCompany
  }
}
    ${UserPageCompanyFragmentDoc}`;

/**
 * __useGetUserPageCompanyQuery__
 *
 * To run a query within a React component, call `useGetUserPageCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPageCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPageCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetUserPageCompanyQuery(baseOptions: Apollo.QueryHookOptions<GetUserPageCompanyQuery, GetUserPageCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserPageCompanyQuery, GetUserPageCompanyQueryVariables>(GetUserPageCompanyDocument, options);
      }
export function useGetUserPageCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserPageCompanyQuery, GetUserPageCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserPageCompanyQuery, GetUserPageCompanyQueryVariables>(GetUserPageCompanyDocument, options);
        }
export type GetUserPageCompanyQueryHookResult = ReturnType<typeof useGetUserPageCompanyQuery>;
export type GetUserPageCompanyLazyQueryHookResult = ReturnType<typeof useGetUserPageCompanyLazyQuery>;
export type GetUserPageCompanyQueryResult = Apollo.QueryResult<GetUserPageCompanyQuery, GetUserPageCompanyQueryVariables>;
export const GetUserRolesForCompanyDocument = gql`
    query getUserRolesForCompany($companyId: ID!) {
  company(id: $companyId) {
    ...userPageCompanyLite
  }
}
    ${UserPageCompanyLiteFragmentDoc}`;

/**
 * __useGetUserRolesForCompanyQuery__
 *
 * To run a query within a React component, call `useGetUserRolesForCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRolesForCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRolesForCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetUserRolesForCompanyQuery(baseOptions: Apollo.QueryHookOptions<GetUserRolesForCompanyQuery, GetUserRolesForCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserRolesForCompanyQuery, GetUserRolesForCompanyQueryVariables>(GetUserRolesForCompanyDocument, options);
      }
export function useGetUserRolesForCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserRolesForCompanyQuery, GetUserRolesForCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserRolesForCompanyQuery, GetUserRolesForCompanyQueryVariables>(GetUserRolesForCompanyDocument, options);
        }
export type GetUserRolesForCompanyQueryHookResult = ReturnType<typeof useGetUserRolesForCompanyQuery>;
export type GetUserRolesForCompanyLazyQueryHookResult = ReturnType<typeof useGetUserRolesForCompanyLazyQuery>;
export type GetUserRolesForCompanyQueryResult = Apollo.QueryResult<GetUserRolesForCompanyQuery, GetUserRolesForCompanyQueryVariables>;