import React from "react";

interface BoxProps extends React.HTMLProps<HTMLDivElement> {
  children?: React.ReactNode;
  css?: React.CSSProperties;
}

export const Box = ({ css, children, ...props }: BoxProps) => {
  return (
    <div style={css} {...props}>
      {children}
    </div>
  );
};
