import { useCallback } from "react";

import { parseDate } from "@puzzle/utils";
import { useToasts } from "@puzzle/ui";

import { useCompanyDateFormatter } from "components/companies";
import Analytics from "lib/analytics";
import { BillStatus } from "graphql/types";

import {
  GetBillsDocument,
  GetBillsForMatchingDocument,
  usePaidBillMutation,
  useRemoveBillMutation,
  useVoidBillMutation,
  useDeleteBillLinkMutation,
} from "./graphql.generated";
import { errorToastConfig } from "./shared";

const refetchQueries = [GetBillsDocument, GetBillsForMatchingDocument];

export const useUpdateBillIsPaid = () => {
  const [updateBillPaymentMutation, { loading }] = usePaidBillMutation();
  const { toast } = useToasts();
  const dateFormatter = useCompanyDateFormatter({ dateStyle: "short" });

  return {
    mutation: useCallback(
      ({ billId, isPaid }: { billId?: string; isPaid: boolean }) => {
        if (!billId) {
          return;
        }

        return updateBillPaymentMutation({
          variables: {
            input: {
              billId,
              isPaid,
            },
          },

          onCompleted: ({ paidBill }) => {
            const actionText = paidBill.status === BillStatus.Paid ? "paid" : "unpaid";

            toast({
              title: `Bill ${actionText}`,
              message: `You marked the bill for ${paidBill.description} for ${
                paidBill.amount.amount
              } that is due on ${dateFormatter.format(
                parseDate(paidBill.dueDate!)
              )} as ${actionText}.`,
              status: "success",
            });

            Analytics.billPaymentToggled({
              id: paidBill.id,
              isPaid: paidBill.status === BillStatus.Paid,
            });
          },

          onError: () => {
            toast(errorToastConfig);
          },
        });
      },
      [updateBillPaymentMutation, toast, dateFormatter]
    ),
    loading,
  };
};

export const useVoidBill = () => {
  const [voidBill, { loading }] = useVoidBillMutation({
    refetchQueries,
  });
  const { toast } = useToasts();
  const dateFormatter = useCompanyDateFormatter({ dateStyle: "short" });

  return {
    mutation: useCallback(
      ({ billId }: { billId?: string }) => {
        if (!billId) {
          return;
        }

        return voidBill({
          variables: {
            input: {
              billId,
            },
          },

          onCompleted: ({ voidBill }) => {
            toast({
              title: `Bill voided`,
              message: `You voided the bill for ${voidBill.description} for ${
                voidBill.amount.amount
              } that is due on ${dateFormatter.format(parseDate(voidBill.dueDate!))}.`,
              status: "success",
            });

            Analytics.billVoided({ id: voidBill.id });
          },

          onError: () => {
            toast(errorToastConfig);
          },
        });
      },
      [voidBill, toast, dateFormatter]
    ),
    loading,
  };
};

export const useDeleteBillLink = () => {
  const [deleteBillLink, { loading }] = useDeleteBillLinkMutation();
  const { toast } = useToasts();

  return {
    mutation: useCallback(
      ({ billId, bundleId }: { billId?: string; bundleId?: string }) => {
        if (!billId && !bundleId) {
          return;
        }

        return deleteBillLink({
          variables: {
            input: {
              billId,
              bundleId,
            },
          },

          onCompleted: () => {
            toast({
              message: "The bill has been detached successfully",
              status: "success",
            });

            //Analytics.billUnmatched({ id: voidBill.id });
          },

          onError: () => {
            toast(errorToastConfig);
          },
        });
      },
      [deleteBillLink, toast]
    ),
    loading,
  };
};
export const useRemoveBill = () => {
  const [removeBill, { loading }] = useRemoveBillMutation({
    refetchQueries,
  });
  const { toast } = useToasts();

  return {
    mutation: useCallback(
      ({
        billId,
        billAmount,
        billDescription,
      }: {
        billId?: string;
        billAmount?: string;
        billDescription?: string;
      }) => {
        if (!billId) {
          return;
        }

        return removeBill({
          variables: {
            input: {
              billId,
            },
          },

          onCompleted: (response) => {
            if (!response.removeBill.success) {
              toast({
                title: `Bill delete error`,
                message: `Unable to delete the draft bill for ${billDescription} for ${billAmount}`,
                status: "error",
              });
              return;
            }

            toast({
              title: `Bill deleted`,
              message: `You deleted the draft bill for ${billDescription} for ${billAmount}`,
              status: "success",
            });

            Analytics.billDeleted({ id: billId });
          },

          onError: () => {
            toast(errorToastConfig);
          },
        });
      },
      [removeBill, toast]
    ),
    loading,
  };
};
