import type * as Stitches from "@stitches/react";
import { createStitches } from "@stitches/react";
import {
  colors,
  fonts,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
  media,
  radii,
  space,
  gradients,
} from "./theme";

const textVariant = (value: Stitches.PropertyValue<"fontSize">) => ({
  fontSize: value,
  lineHeight: value,
  letterSpacing: value,
  fontWeight: value,
});

export const { styled, css, globalCss, keyframes, getCssText, theme, createTheme, config } =
  createStitches({
    // https://stitches.dev/docs/tokens
    theme: {
      colors,
      gradients,
      space,
      fonts,
      fontSizes,
      lineHeights,
      fontWeights,
      letterSpacings,
      radii,

      sizes: {},
      borderWidths: {},
      borderStyles: {},
      shadows: {},
      transitions: {},
      zIndices: {
        // Just below Intercom
        max: 2147482999,
      },
    },

    media,

    utils: {
      py: (value: Stitches.PropertyValue<"scale">) => ({ paddingTop: value, paddingBottom: value }),
      px: (value: Stitches.PropertyValue<"scale">) => ({ paddingLeft: value, paddingRight: value }),
      my: (value: Stitches.PropertyValue<"scale">) => ({ marginTop: value, marginBottom: value }),
      mx: (value: Stitches.PropertyValue<"scale">) => ({ marginLeft: value, marginRight: value }),

      // overlay is webkit-only, so fallback to scroll; use <ScrollArea /> if you need more customization
      overflow: (value: string) => ({
        overflow: value === "overlay" ? "scroll; overflow: overlay;" : value,
      }),
      overflowY: (value: string) => ({
        overflowY: value === "overlay" ? "scroll; overflow-y: overlay;" : value,
      }),
      overflowX: (value: string) => ({
        overflowX: value === "overlay" ? "scroll; overflow-x: overlay;" : value,
      }),

      /**
       * TODO migrate, jsdoc won't work
       * @deprecated Use textVariant
       */
      textStyle: textVariant,
      textVariant,

      unstyled: (value: boolean) =>
        value && {
          appearance: "none",
          outline: "none",
          border: "none",
          background: "none",
        },
    },
  });

export type CSS = Stitches.CSS<typeof config>;
export type CSSProps = {
  css?: CSS;
};
