import React from "react";
import Icon, { IconProps } from "../Icon";

export default function Captable({
  viewBox = "0 0 48 48",
  width = 48,
  height = 48,
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} fill="transparent" {...iconProps} viewBox={viewBox}>
      <path d="M14.8398 16.8H22.3998V31.2H14.8398V16.8Z" stroke="white" strokeWidth="2" />
      <path d="M14.4199 20.8H22.8199V22.4H14.4199V20.8Z" fill="white" />
      <path d="M14.4199 25.6001H22.8199V27.2001H14.4199V25.6001Z" fill="white" />
      <path
        d="M29.7077 28C31.7954 28 33.4877 26.3883 33.4877 24.4C33.4877 22.4118 31.7954 20.8 29.7077 20.8C27.6201 20.8 25.9277 22.4118 25.9277 24.4C25.9277 26.3883 27.6201 28 29.7077 28Z"
        stroke="white"
        strokeWidth="2"
      />
    </Icon>
  );
}
