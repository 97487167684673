import React from "react";
import { Text, styled } from "@puzzle/ui";
import { Variant } from "../List/types";
import { IntegrationLogo } from "../shared";
import { BetaTag } from "../../common/BetaTag";
import { Box, S } from "ve";

const getSize = (variant?: Variant) => (variant === "compact" ? 48 : 72);

const ListItem = styled("li", {
  backgroundColor: "#3C3B4F",
  border: "1px solid $mauve680",
  padding: "$1 $2",
  borderRadius: "$1",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
});

const CardContent = styled("div", {
  display: "flex",
  alignItems: "center",
  width: "100%",
});

interface BasicListItemBaseProps {
  className?: string;
  variant?: Variant;
  logo: React.ReactNode;
  endAdornment?: React.ReactNode;
  listItemText?: React.ReactNode;
  dataTestId?: string;
  onClick?: () => void;
  title: React.ReactNode;
  subtitle: React.ReactNode;
  subContent?: React.ReactElement;
  isBeta?: boolean;
  tooltipContent?: string;
}

const BasicListItemBase = ({
  className,
  variant = "normal",
  logo,
  endAdornment,
  listItemText,
  onClick,
  dataTestId,
  title,
  subtitle,
  subContent,
  isBeta,
  tooltipContent
}: BasicListItemBaseProps) => {
  return (
    <ListItem data-testid={dataTestId} className={className}>
      <Box css={{ width: "100%" }}>

          <CardContent>
            {logo && (
              <Box css={{ marginRight: S["2"] }}>
                <IntegrationLogo
                  width={`${getSize(variant)}px`}
                  height={`${getSize(variant)}px`}
                  tooltipContent={tooltipContent}
                >
                  {logo}
                </IntegrationLogo>
              </Box>
            )}
            {listItemText ? (
              listItemText
            ) : (
              <Box css={{ margin: `${S["1"]} 0` }}>
                <Box css={{ display: "flex" }}>
                  <Text variant="headingS" color="gray100" css={{ marginTop: "3px" }}>
                    {title}
                  </Text>
                  {isBeta && <BetaTag css={{ marginLeft: "$1" }} />}
                </Box>
                <Text as="div" variant="body" color="gray300">
                  {subtitle}
                </Text>
              </Box>
            )}
            <Box css={{ marginLeft: "auto" }} onClick={onClick}>
              {endAdornment}
            </Box>
          </CardContent>

        {subContent}
      </Box>
    </ListItem>
  );
};

export default BasicListItemBase;
