import { formatAccountName, formatMoney } from "@puzzle/utils";
import { AccountType } from "graphql/types";
import React, { useState } from "react";
import InstitutionLogo from "../bank/InstitutionLogo";
import { AccountDetailsProps } from "./types";
import {
  Footer,
  Header,
  HeaderGroup,
  Logo,
  TextGroup,
  getIntegrationStatusTag,
  getAccountStatusTag,
} from "./shared";
import { Button, Text } from "@puzzle/ui";
import { ImportTransactionsModal } from "../../dashboard/Transactions/ImportTransactionsModal";
import ManageAccountSyncButton from "./ManageAccountSyncButton";
import RemoveAccountButton from "./RemoveAccountButton";
import ArchiveAccountButton from "./ArchiveAccountButton";
import AccountEpochsSection from "./AccountEpochsSection";
import ResetAccountButton from "./ResetAccountButton";
import { useFinancialInstitutions } from "../shared";

const AccountDetails = ({ account, ...props }: AccountDetailsProps) => {
  const [importModalOpen, setImportModalOpen] = useState(false);
  const { pendingAccounts } = useFinancialInstitutions();

  const financialInstitution = account.financialInstitution;
  const connection = account.connection;
  const isArchived = !!account.archivedAt;

  return (
    <>
      <Header>
        <HeaderGroup css={{ justifyContent: "space-between", width: "100%" }}>
          <HeaderGroup css={{ gap: "$2" }}>
            <Logo>
              <InstitutionLogo institution={financialInstitution} />
            </Logo>
            <TextGroup>
              <Text variant="headingM">{formatAccountName(account)}</Text>
              <Text variant="bodyS">{financialInstitution.name} </Text>
              <Text variant="bodyXS" color="gray500">
                {formatMoney({ currency: "USD", amount: account.todaysBalance.balance })}
              </Text>
            </TextGroup>
          </HeaderGroup>
          <div>
            {getIntegrationStatusTag(connection?.status, account.manuallyAdded)}
            {getAccountStatusTag(account, pendingAccounts)}
          </div>
        </HeaderGroup>
      </Header>

      <AccountEpochsSection account={account} />

      {account.manuallyAdded && account.type !== AccountType.Payroll && (
        <>
          <TextGroup>
            <Text variant="bodyS" color="gray400" css={{ textAlign: "left" }}>
              To stay on top of your finances, please remember to import the most recent transaction
              CSV. Once the integration is enabled, we recommend that you connect the source account
              so that we can automatically assemble, verify, and reconcile your latest financials
              and metrics.
            </Text>
          </TextGroup>
          <Button
            size="compact"
            variant="secondary"
            color="primary"
            onClick={() => setImportModalOpen(true)}
          >
            Import transactions
          </Button>
          <ImportTransactionsModal open={importModalOpen} onOpenChange={setImportModalOpen} />
        </>
      )}

      <Footer>
        {!isArchived && <ManageAccountSyncButton account={account} />}
        <ArchiveAccountButton account={account} />
        <ResetAccountButton account={account} />
        <RemoveAccountButton account={account} />
      </Footer>
    </>
  );
};

export default AccountDetails;
