import React from "react";
import { Stack, Text } from "@puzzle/ui";

export type ScheduleWarningMode = "void" | "close";

type Props = {
  mode: ScheduleWarningMode;
};

export const ScheduleWarningMessage = ({ mode }: Props) => {
  if (mode === "void") {
    return (
      <Stack gap="1">
        <div>
          <Text>
            Voiding this revenue schedule will result in the reversal of previously recorded
            entries, and no future entries for this schedule will be recorded. It will be possible
            to replace the voided revenue schedule with a new revenue schedule.
          </Text>
        </div>
      </Stack>
    );
  }

  return (
    <Text>
      Closing the schedule recognizes all remaining revenue on the date you enter. Separate entries
      will be required for any subsequent adjustments.
    </Text>
  );
};
