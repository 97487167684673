import React, { useMemo } from "react";
import { IntegrationsListItemBaseProps } from "../List";
import { IntegrationType } from "graphql/types";
import { usePushIntegrations } from ".";
import { IntegrationDisplayComponent } from "../shared";
import { useToasts, Text, Tooltip } from "@puzzle/ui";
import InstitutionLogo from "../bank/InstitutionLogo";
import { Help } from "@puzzle/icons";

interface PushIntegrationListItemProps {
  DisplayComponent: IntegrationDisplayComponent<
    React.PropsWithChildren<IntegrationsListItemBaseProps>
  >;
  companyId: string;
  integrationType: IntegrationType;
  isBeta?: boolean;
}

const PushIntegrationListItem = ({
  DisplayComponent,
  companyId,
  integrationType,
  isBeta,
}: PushIntegrationListItemProps) => {
  const { toast } = useToasts();
  const { connectionMap, loading } = usePushIntegrations({ companyId });

  const connectionInfo = connectionMap.get(integrationType);

  const connect = useMemo(() => {
    if (connectionInfo?.connectUrl) {
      return () => {
        window.open(connectionInfo.connectUrl, "_blank");
      };
    }

    return () => {
      toast({
        message: `Please connect your ${integrationType} integration in the ${integrationType} app.`,
        status: "warning",
      });
    };
  }, [integrationType, connectionInfo, toast]);

  const subtitle = (
    <>
      <Text>{`via ${integrationType} portal `}</Text>
      <Tooltip
        content={`Set up the connection to securely receive data into Puzzle. This must be initiated from the ${integrationType} portal.`}
      >
        <span>
          <Help />
        </span>
      </Tooltip>
    </>
  );

  return (
    <DisplayComponent
      integrationType={integrationType}
      connectIntegration={connect}
      title={integrationType}
      logo={<InstitutionLogo institution={{ name: integrationType }} />}
      connection={connectionInfo?.connection}
      loading={loading}
      requiresExternalSetup={true}
      isBeta={isBeta}
      subtitle={subtitle}
    />
  );
};

export default PushIntegrationListItem;
