import React from "react";
import { FeedItem } from "@knocklabs/client";
import { DetailPane } from "components/integrations/DetailDrawer";
import { IntegrationFeedItemData } from "../shared";

export const IntegrationPane = ({ item }: { item: FeedItem<IntegrationFeedItemData> }) => {
  const data = item.data!;

  return (
    <DetailPane
      integrationType={data.integrationType!}
      accountId={data.integrationId}
      css={{ padding: "$8 $4 $12" }}
    />
  );

  // This is what the designs asked for, but I don't want to reimplement all the logic.
  // return (
  //   <MiniPane
  //     item={item}
  //     title="Integration disconnected"
  //     data={[
  //       {
  //         label: "Integration",
  //         value: data.integrationType,
  //       },
  //       // {
  //       //   label: "Last synced",
  //       //   value: "February 28, 2023",
  //       // },
  //     ]}
  //     headerButtonOptions={{
  //       label: "Go to Integrations",
  //       href: Route.integrations,
  //     }}
  //     actions={
  //       <>
  //         <Button size="mini">Reconnect</Button>
  //         <Button color="negative" variant="secondary" size="mini">
  //           Disconnect integration
  //         </Button>
  //       </>
  //     }
  //   />
  // );
};
