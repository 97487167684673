import React from "react";
import Icon, { IconProps } from "./Icon";

export default function NavChecklist({
  viewBox = "0 0 24 24",
  width = 24,
  height = 24,
  ...iconProps
}: IconProps) {
  return (
    <Icon viewBox={viewBox} width={width} height={height} {...iconProps} fill="none" stroke="none">
      <path
        fill="#D1D1D5"
        d="M8.138 18.236l-.993 1.47c-.193.29-.193.787 0 1.077.193.29.524.29.717 0l.993-1.491c.193-.29.193-.787 0-1.077-.207-.29-.524-.29-.717.02zM6.82 17.168a.599.599 0 00-.83 0l-1.822 1.835a.597.597 0 000 .83.599.599 0 00.83 0L6.82 17.98a.55.55 0 000-.814zM5.783 15.862c.29-.193.29-.524 0-.717-.29-.193-.786-.193-1.076 0l-1.49.993c-.29.193-.29.524 0 .717.29.193.786.193 1.076 0l1.49-.993z"
      ></path>
      <path
        fill="#95949B"
        d="M17.527 13.082c2.825-3.372 3.545-8.069 3.467-9.492 0-.156-.077-.292-.175-.409a.57.57 0 00-.409-.175c-1.422-.078-6.117.623-9.468 3.45l-.779-.293a3.452 3.452 0 00-3.838 1.014L4.163 9.73c-.312.35-.156.916.292 1.072l2.318.857a22.904 22.904 0 00-1.09 2.086c-.195.41-.098.897.233 1.228l3.117 3.138c.312.312.8.41 1.228.214a22.932 22.932 0 002.084-1.091l.838 2.32c.156.447.72.584 1.071.292l2.533-2.164a3.456 3.456 0 001.013-3.84l-.273-.76zm-1.461-2.436c-.74.74-1.948.74-2.708 0-.74-.74-.74-1.95 0-2.71.74-.74 1.948-.74 2.708 0 .74.76.74 1.97 0 2.71z"
      ></path>
    </Icon>
  );
}
