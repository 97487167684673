import { tinykeys } from "tinykeys";

function isEventTargetInputOrTextArea(target: HTMLElement) {
  if (target === null) return false;

  const targetElementName = target.tagName.toLowerCase();
  return ["input", "textarea", "select"].includes(targetElementName);
}

export function hotkeys(
  target: HTMLElement | Window,
  bindings: Record<string, (e: KeyboardEvent) => void>,
  disableOnInputs = true
) {
  const wrappedBindings = disableOnInputs
    ? Object.fromEntries(
        Object.entries(bindings).map(([key, handler]) => [
          key,
          (event: KeyboardEvent) => {
            if (!isEventTargetInputOrTextArea(event.target as HTMLElement)) {
              handler(event);
            }
          },
        ])
      )
    : bindings;
  return tinykeys(target, wrappedBindings);
}
