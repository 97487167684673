import React, { useState } from "react";
import { Button, DateInput, styled } from "@puzzle/ui";
import { useActiveCompany, useCompanyDateFormatter } from "components/companies";
import { DateValue, toCalendarDate } from "@internationalized/date";
import { dateToDateValue, isBefore, isSameDateValue, parseDate } from "@puzzle/utils";
import Link from "components/common/Link";
import { Route } from "lib/routes";
import { FeedItem } from "@knocklabs/client";
import { ActionHeader } from "./ActionHeader";
import { Box, S, color } from "ve";

const Card = styled("div", {
  background: "$mauve950",
  border: "1px solid $mauve550",
  borderRadius: "4px",
  maxWidth: 488,
  width: "75%",
  margin: "0 auto",
  marginTop: "100px",
  padding: "20px",
});

const TextBlock = styled("div", {
  marginTop: "20px",
});

const Header = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  borderBottom: "1px solid $mauve550",
  paddingBottom: "$1h",

  fontWeight: "$bold",
  fontSize: "15px",
  lineHeight: "22px",
  alignItems: "center",
  height: "calc($space$3 + $1h)",

  color: "$gray100",
});

export const getFirstDateOfCurrentYear = () => {
  const currentYear = new Date().getFullYear();
  return parseDate(`${currentYear}-01-01`);
};

enum ProposeStartIngestionDateTrigger {
  QboConnected = "qbo-connected",
  CompanySettings = "company-settings",
  Onboarding = "onboarding",
}

const IntroSection = ({ trigger }: { trigger: ProposeStartIngestionDateTrigger }) => {
  switch (trigger) {
    case ProposeStartIngestionDateTrigger.QboConnected: {
      return (
        <TextBlock>
          You connected your previous accounting data via an integration with QuickBooks. You can
          review your books in QuickBook's format within{" "}
          <Link css={{ color: "$green600" }} href={Route.reports}>
            the Reports page
          </Link>
          .
        </TextBlock>
      );
    }
    case ProposeStartIngestionDateTrigger.CompanySettings: {
      return (
        <TextBlock>
          You indicated that you have historical books from another accounting system (e.g.
          QuickBooks).
        </TextBlock>
      );
    }
    case ProposeStartIngestionDateTrigger.Onboarding: {
      return (
        <TextBlock>
          During onboarding, you indicated that you had historical books from another accounting
          system (e.g. QuickBooks).
        </TextBlock>
      );
    }
    default: {
      throw new Error("unsupported ProposeStartIngestionDateTrigger");
    }
  }
};

export const ProposeStartIngestionDatePane = ({ item }: { item: FeedItem }) => {
  const { company, updateCompany, loading } = useActiveCompany();
  const dateFormatter = useCompanyDateFormatter({ dateStyle: "medium" });
  const currentDateSelection =
    company?.userProposedStartIngestionDate && parseDate(company?.userProposedStartIngestionDate);
  const [proposedStartIngestionDate, setProposedStartIngestionDate] = useState<DateValue | null>(
    currentDateSelection || getFirstDateOfCurrentYear() // default to first of the year
  );
  // create warning text for two problematic selections
  // 1) today
  // 2) in the last 30 days

  // fall back to browser timezone if needed, shouldn't be needed
  const timeZone = company?.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;

  const now = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

  // get today with companies timezone or browser timezone if there is not one found on the company
  const todayDateValue = dateToDateValue(now, timeZone);
  const thirtyDaysAgeDateValue = dateToDateValue(thirtyDaysAgo, timeZone);

  let warning = "";
  if (proposedStartIngestionDate) {
    if (isSameDateValue(todayDateValue, proposedStartIngestionDate)) {
      warning = "We recommend not choosing today's date in almost all cases.";
    } else if (isBefore(thirtyDaysAgeDateValue, proposedStartIngestionDate)) {
      warning = "Are you sure your historical financials are finalized through this date?";
    }
  }

  return (
    <Box css={{ height: "100%", width: "100%" }}>
      <ActionHeader item={item} location="propose-start-ingestion-date" />
      <Card>
        <Header>Propose a cutover date for historical financials</Header>
        <IntroSection trigger={item.data?.trigger || ProposeStartIngestionDateTrigger.Onboarding} />
        <TextBlock>
          To jumpstart your experience of Puzzle without re-doing those books, you can import and
          convert your historical financials to Puzzle's format. These will serve as your historical
          financial records. This is optional.
        </TextBlock>
        <TextBlock>
          To start this process, please indicate the date you want to start ingesting new
          (non-historical) data into Puzzle. This should be the day immediately after your most
          recent finalized financial statements.
        </TextBlock>
        <Box
          css={{
            width: "250px",
            marginTop: S["2h"],
            display: "flex",
            flexDirection: "row",
            gap: S["2h"],
            alignContent: "center",
          }}
        >
          <DateInput
            placeholder="Propose a start ingestion date"
            size="small"
            maxDate={todayDateValue}
            value={proposedStartIngestionDate}
            onChange={(value) => {
              setProposedStartIngestionDate(value);
            }}
          />
          <Button
            size="small"
            loading={loading}
            disabled={!proposedStartIngestionDate}
            onClick={() => {
              updateCompany({
                userProposedStartIngestionDate:
                  proposedStartIngestionDate &&
                  toCalendarDate(proposedStartIngestionDate).toString(),
              });
            }}
          >
            Set date
          </Button>
        </Box>
        {currentDateSelection && (
          <Box css={{ marginTop: S["1"] }}>
            <strong>Currently selected:</strong> {dateFormatter.format(currentDateSelection)}
          </Box>
        )}
        {!!warning && (
          <Box
            css={{
              marginTop: "5px",
              backgroundColor: color.red300,
              borderRadius: "5px",
              padding: "5px",
              color: color.black,
            }}
          >
            {warning}
          </Box>
        )}
        <TextBlock>
          {`Setting this date will not have an immediate impact on any existing data. A Puzzle team member will review the date before the cutover date is effective. Once the date is approved, the impact will be:`}
        </TextBlock>
        <TextBlock>
          {`Before this date (exclusive) -> All existing financial statement data will be removed and replaced with Historical Journal Entries.`}
        </TextBlock>
        <TextBlock>
          {`On and after this date (inclusive) -> All data will remain driven by integrations; the data as it exists currently will not be modified.`}
        </TextBlock>
        {company?.userProposedStartIngestionDate && (
          <>
            <Box
              css={{
                marginTop: S["2h"],
              }}
            >
              You can complete the next steps within{" "}
              <Link css={{ color: "$green600" }} underline href={Route.historicalBooks}>
                your Historical Books Checklist.
              </Link>
            </Box>
          </>
        )}
      </Card>
    </Box>
  );
};
