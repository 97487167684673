import { ActiveCompanyFragment, useActiveCompany } from "components/companies";
import {
  useConsentToAiMutation,
  useListPromptsQuery,
  useUpdateAiWorkflowSettingsMutation,
} from "./graphql.generated";
import { TemplateType, UpdateAiWorkflowSettingsInput } from "graphql/types";
import Analytics from "lib/analytics";
import useSelf from "components/users/useSelf";

export const AI_NAME = "Generated with PuzzleAI";
export const LOADING_PLACEHOLDER_MESSAGE = "[LOADING]";

export const isLastMileWorkflow = (company: ActiveCompanyFragment, request: string) => {
  return (
    company?.features.assistedCategorizationEnabled &&
    new RegExp(`(categ|context)`, "gi").test(request.toLowerCase())
  );
};

interface UpdateAIWorkflowSettingsInputWithoutCompanyId
  extends Omit<UpdateAiWorkflowSettingsInput, "companyId"> {}

export const useAI = () => {
  const { company } = useActiveCompany<true>();
  const { self } = useSelf();
  const { data, error } = useListPromptsQuery({
    variables: { input: { templateType: TemplateType.MyBusinessInformation } },
  });

  const [_consentToAI] = useConsentToAiMutation();
  const [_updateAiWorkflow] = useUpdateAiWorkflowSettingsMutation();

  const consentToAI = () => {
    return _consentToAI({
      variables: { input: { companyId: company.id, consent: true } },
      optimisticResponse: {
        __typename: "Mutation",
        consentToAI: {
          id: company.id,
          consentsToAI: true,
        },
      },
      onCompleted: () => {
        Analytics.openAiDataSharingConsentGranted({
          companyId: company.id,
          userId: self?.id ?? undefined,
        });
      },
    });
  };

  const enableAutonomousAccounting = () => {
    return updateAiWorkflow({
      assistedCategorizationEnabled: true,
      automaticAssignmentEnabled: true,
    });
  };

  const updateAiWorkflow = ({
    assistedCategorizationEnabled,
    automaticAssignmentEnabled,
    lastMilePreference,
  }: UpdateAIWorkflowSettingsInputWithoutCompanyId) => {
    return _updateAiWorkflow({
      variables: {
        input: {
          companyId: company.id,
          automaticAssignmentEnabled: automaticAssignmentEnabled ?? true,
          assistedCategorizationEnabled: assistedCategorizationEnabled ?? true,
          lastMilePreference,
        },
      },
      optimisticResponse: {
        __typename: "Mutation",
        updateAIWorkflowSettings: {
          company: {
            id: company.id,
            consentsToAI: true,
            features: {
              automaticAssignmentEnabled: company.features.automaticAssignmentEnabled,
              assistedCategorizationEnabled: company.features.assistedCategorizationEnabled,
            },
          },
        },
      },
      onCompleted: () => {
        Analytics.aiCategorizerEnabled({
          enabled: assistedCategorizationEnabled ?? true,
          companyId: company.id,
        });
        Analytics.autonomousAccountingToggled({
          enabled: automaticAssignmentEnabled ?? true,
          companyId: company.id,
        });
      },
    });
  };

  return {
    consentToAI,
    enableAutonomousAccounting,
    updateAiWorkflow,
    error,
    askAboutMyBizPrompts: data?.aiPrompts.prompts,
  };
};
