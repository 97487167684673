import React from "react";
import IntegrationLogo from "../shared/IntegrationLogo";
import { Captable } from "@puzzle/icons";

const Logo = () => {
  return (
    <IntegrationLogo color="#FD9800">
      <Captable />
    </IntegrationLogo>
  );
};

export default Logo;
