import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Hourglass({
  viewBox = "0 0 16 16",
  width = 16,
  height = 16,
  color = "currentColor",
  fill = color,
}: IconProps) {
  return (
    <Icon width={width} height={height} fill="none" viewBox={viewBox}>
      <mask
        id="mask0_66_2507"
        style={{ maskType: "alpha" }}
        width={width}
        height={height}
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill={fill} d="M0 0H16V16H0z"></path>
      </mask>
      <g mask="url(#mask0_66_2507)">
        <path
          fill={fill}
          d="M5.333 13.333h5.334v-2c0-.733-.261-1.36-.784-1.883A2.568 2.568 0 008 8.667c-.733 0-1.361.26-1.883.783a2.568 2.568 0 00-.784 1.883v2zm2.667-6c.733 0 1.361-.26 1.883-.783.523-.522.784-1.15.784-1.883v-2H5.333v2c0 .733.261 1.36.784 1.883.522.522 1.15.783 1.883.783zm-5.333 7.334v-1.334H4v-2c0-.677.158-1.313.475-1.908A3.715 3.715 0 015.8 8a3.715 3.715 0 01-1.325-1.425A3.999 3.999 0 014 4.667v-2H2.667V1.333h10.666v1.334H12v2a4 4 0 01-.475 1.908A3.716 3.716 0 0110.2 8c.567.356 1.008.83 1.325 1.425A4 4 0 0112 11.333v2h1.333v1.334H2.667z"
        ></path>
      </g>
    </Icon>
  );
}
