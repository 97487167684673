import React from "react";
import { Tag } from "@puzzle/ui";

export const BetaTag = ({ ...props }) => {
  return (
    <Tag
      variant="outlined"
      color="vivid"
      {...props}
      style={{ boxSizing: "border-box", overflow: "visible" }}
    >
      Beta
    </Tag>
  );
};
