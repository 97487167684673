import React from "react";

import { AutocompleteMenu, Menu, Select, Toolbar } from "@puzzle/ui";
import { AvailableReportingClassFragment } from "components/common/hooks/graphql.generated";
import { LedgerReportFilterInput } from "graphql/types";
import { compact, noop } from "lodash";
import { useReportContext } from "components/reports/ReportContext";

type ClassSegmentOption = { id: string; name: string };

export const noClassificationUUID = "00000000-0000-0000-0000-000000000000";

const SegmentAutoComplete = ({
  reportingClass,
  trigger,
  onChange,
  filter,
  disabled,
}: {
  reportingClass?: AvailableReportingClassFragment;
  trigger?: React.JSX.Element;
  onChange?: (nextState: { filter: LedgerReportFilterInput }) => void;
  filter?: LedgerReportFilterInput;
  disabled?: boolean;
}) => {
  if (!reportingClass || !filter) return null;
  const existingSegmentIds = filter.segments?.segmentIds ?? [];
  const options = reportingClass.availableValues.map((segment) => {
    return {
      id: segment.id,
      name: segment.name,
    };
  });

  return (
    <AutocompleteMenu<ClassSegmentOption, true, false, false>
      disabled={disabled}
      getOptionLabel={(option) => option.name}
      getOptionKey={(option) => option.id}
      options={[
        ...options,
        {
          id: noClassificationUUID,
          name: `No ${reportingClass.name}`,
        },
      ]}
      value={compact(
        existingSegmentIds.map((id) => {
          if (id === noClassificationUUID) {
            if (filter?.segments?.reportingClassId !== reportingClass.id) return null;
            return {
              id: noClassificationUUID,
              name: `No ${reportingClass.name}`,
            };
          }
          const match = reportingClass.availableValues.find((segment) => segment.id === id);
          if (!match) return null;
          return {
            id: match?.id,
            name: match?.name,
          };
        })
      )}
      onChange={(_e, newValue) => {
        const update =
          newValue.length > 0
            ? {
                filter: {
                  segments: {
                    reportingClassType: reportingClass.type,
                    reportingClassId: reportingClass.id,
                    segmentIds: newValue.map((option) => option.id),
                  },
                } as LedgerReportFilterInput,
              }
            : { filter: {} };
        onChange?.(update);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      subMenuTrigger={trigger ? undefined : reportingClass.name}
      multiple
      trigger={trigger}
    />
  );
};

export const ClassificationsFilter = ({
  onChange,
  filter,
  groupBy,
  disabled,
  placeholderText = "Filter",
}: {
  onChange?: (nextState: { filter: LedgerReportFilterInput }) => void;
  filter?: LedgerReportFilterInput;
  groupBy?: string;
  disabled?: boolean;
  placeholderText?: string;
}) => {
  const passthroughProps = { onChange, filter, disabled, placeholderText };
  const { sortedClassifications, normalizedReportingClasses } = useReportContext();

  if (sortedClassifications.length <= 0) return null;

  const trigger = (
    <Toolbar.Button disabled={disabled} variant="menu" asChild>
      <Select
        variant="filter"
        size="small"
        options={[]}
        value={placeholderText}
        placeholder={placeholderText}
        onSelectionChange={noop}
      />
    </Toolbar.Button>
  );

  if (groupBy && normalizedReportingClasses[groupBy]) {
    const reportingClass = normalizedReportingClasses[groupBy];

    return (
      <SegmentAutoComplete
        {...passthroughProps}
        trigger={trigger}
        reportingClass={reportingClass}
      />
    );
  }

  return (
    <Menu
      trigger={trigger}
      modal={false}
      label={placeholderText === "Filter" ? "Classifications" : undefined}
    >
      {sortedClassifications.map((reportingClass) => (
        <SegmentAutoComplete
          key={reportingClass.id}
          {...passthroughProps}
          reportingClass={reportingClass}
        />
      ))}
    </Menu>
  );
};
