import React from "react";
import { Dialog, Button, styled, Text } from "@puzzle/ui";
import { Reports } from "@puzzle/icons";
import { Clock, Network } from "./icons";
import Link from "components/common/Link";
import links from "lib/links";

const Line = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "$1h",
  alignItems: "center",
  paddingBottom: "$1h",
});

const IconContainer = styled("div", {
  background: "$mauve700",
  width: "40px",
  height: "40px",
  borderRadius: "$ellipse",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flex: "none",
  order: 0,
  flexGrow: 0,
});

export const InfoModal = ({
  open,
  onContinue,
  onCancel,
}: {
  open: boolean;
  onContinue: () => void;
  onCancel: () => void;
}) => {
  return (
    <Dialog open={open} size="small" onOpenChange={onCancel}>
      <Dialog.Title>Import historical books from QuickBooks Online</Dialog.Title>
      <Dialog.Body css={{ color: "$gray200" }}>
        You can directly import your historical books from QBO within minutes.
        <Line css={{ paddingTop: "$2h" }}>
          <IconContainer>
            <Clock />
          </IconContainer>
          <Text>Puzzle will pull in your historical financials in the QBO format.</Text>
        </Line>
        <Line>
          <IconContainer>
            <Reports active={true} />
          </IconContainer>
          <Text>
            You will be able to access your QBO reports within the Puzzle app, so that you do not
            have to refer to QBO.
          </Text>
        </Line>
        <Line css={{ paddingBottom: "$0" }}>
          <IconContainer>
            <Network />
          </IconContainer>
          <Text>
            Puzzle will help you migrate your historical books from Quickbooks Online.{" "}
            <Link href={links.historialFinancialMigration} external target="_blank">
              Request a QBO migration
            </Link>
          </Text>
        </Line>
      </Dialog.Body>
      <Dialog.Footer align="right" tabIndex={0}>
        <Button variant="primary" onClick={onContinue}>
          Connect with QuickBooks Online
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};
