import React from "react";
import { FinancialInstitutionFragment } from "graphql/types";
import { institutions } from "../metadata";
import { BankLogo, SiliconValleyBankLogo, FirstRepublicLogo, WellsFargoLogo } from "../bank";
import {
  AmericanExpressLogo,
  ChaseLogo,
  BrexLogo,
  GustoLogo,
  RipplingLogo,
  RampLogo,
  MercuryLogo,
  StripeLogo,
  BillDotComLogo,
  QuickbooksLogo,
  MeowLogo,
} from "components/integrations";
import { DeelLogo } from "../deel";
import { EveryLogo } from "../every";
import { CentralLogo } from "../pushIntegrations";

const InstitutionLogo = ({
  institution,
  circular,
}: {
  institution: Pick<FinancialInstitutionFragment, "name">;
  circular?: boolean;
}) => {
  const institutionName = institution.name;
  const institutionMetadata = institutions.find(
    ({ name }) => name.split(" - ")[0] === institutionName.split(" - ")[0]
  );

  if (institutionName === "Mercury") {
    return <MercuryLogo circular={circular} />;
  } else if (institutionName === "Ramp") {
    return <RampLogo circular={circular} />;
  } else if (institutionName.startsWith("Brex")) {
    return <BrexLogo circular={circular} />;
  } else if (institutionName === "Rippling") {
    return <RipplingLogo circular={circular}/>;
  } else if (institutionName === "Gusto") {
    return <GustoLogo circular={circular} />;
  } else if (institutionName === "Stripe") {
    return <StripeLogo circular={circular} />;
  } else if (institutionName === "BILL") {
    return <BillDotComLogo circular={circular} />;
  } else if (institutionName.toLowerCase() === "quickbooks") {
    return <QuickbooksLogo circular={circular} />;
  } else if (institutionName.startsWith("Silicon Valley Bank")) {
    return <SiliconValleyBankLogo circular={circular} />;
  } else if (institutionName.startsWith("First Republic")) {
    return <FirstRepublicLogo circular={circular} />;
  } else if (institutionName.startsWith("Chase")) {
    return <ChaseLogo circular={circular} />;
  } else if (institutionName.startsWith("Wells Fargo")) {
    return <WellsFargoLogo circular={circular} />;
  } else if (institutionName.startsWith("American Express")) {
    return <AmericanExpressLogo circular={circular} />;
  } else if (institutionName === "Meow") {
    return <MeowLogo circular={circular} />;
  } else if (institutionName === "Deel") {
    return <DeelLogo circular={circular} />;
  } else if (institutionName === "Every") {
    return <EveryLogo circular={circular} />;
  } else if (institutionName === "Central") {
    return <CentralLogo circular={circular} />;
  } else if (institutionMetadata?.logo) {
    return (
      <img
        src={`data:image/png;base64,${institutionMetadata.logo}`}
        alt={`${institutionName} logo`}
      />
    );
  } else {
    return <BankLogo circular={circular} />;
  }
};

 export default React.memo(InstitutionLogo);
