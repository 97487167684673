/* eslint-disable react/display-name */
import React from "react";
import { CategoryFragment } from "graphql/types";
import { useExtraTransactionState, useGetTransaction } from "./hooks/useSingleTransaction";
import useEditSplits from "./hooks/useEditSplits";

import SelectCategoryInput from "components/transactions/SelectCategoryInput";

import useCategories from "components/common/hooks/useCategories";
import { SplitRowData } from "./shared";

export const EditSplitCategoryCell = ({
  data,
  wrap,
}: {
  data: Pick<SplitRowData, "transactionId" | "detailId" | "splitIndex">;
  wrap?: boolean;
}) => {
  const transactionResult = useGetTransaction(data.transactionId);
  const transaction = transactionResult.data?.transaction;
  const { canEditSplits } = useExtraTransactionState(transaction);
  const { updateSplitCategory, persistSplits, getSplit } = useEditSplits(transaction);
  const { categories } = useCategories();
  const handleUpdate = (c: CategoryFragment) => {
    const newSplits = updateSplitCategory(data.detailId, c);
    persistSplits(newSplits);
  };

  const split = getSplit(data.detailId) || transaction?.splits[data.splitIndex];
  if (!split) {
    return null;
  }

  return (
    <SelectCategoryInput
      value={split.category}
      categories={categories}
      canEdit={canEditSplits}
      onChange={handleUpdate}
      wrap={wrap}
      isBillPayment={!!split.isBillPayment}
      isInvoicePayment={!!split.isInvoicePayment}
    />
  );
};
