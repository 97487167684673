import { Help } from "@puzzle/icons";
import { Button, Stack, Tooltip, styled } from "@puzzle/ui";
import Analytics from "lib/analytics";
import React from "react";
import { useInboxContext } from "../InboxContext";
import { FeedItem } from "@knocklabs/client";

const Header = styled(Stack, {
  display: "flex",
  width: "100%",
  padding: "$2 $5h",
});

type ActionHeaderProps = {
  item: FeedItem;
  location: string;
};

export const ActionHeader = ({ item, location }: ActionHeaderProps) => {
  const { handleArchivalClick } = useInboxContext();

  return (
    <Header direction="horizontal" gap="2">
      <Stack direction="horizontal" gap="1" css={{ alignItems: "center" }}>
        <Button
          size="compact"
          variant="primary"
          onClick={(event) =>
            handleArchivalClick({
              event,
              analyticsLocation: `${location}-action-header`,
            })
          }
        >
          Mark as {item.archived_at ? "uncompleted" : "completed"}
        </Button>
        <Tooltip
          content={`Marking this task as ${
            item.archived_at ? "uncompleted" : "completed"
          } will move it to the ${item.archived_at ? "My tasks" : "Archived"} feed.`}
          align="center"
          side="bottom"
          sideOffset={8}
        >
          <span>
            <Help />
          </span>
        </Tooltip>
      </Stack>
      <Button
        // BEWARE: this attribute is being used by Intercom to launch a chat
        // removing it will break that feature.
        data-intercom-id={`onboarding-checklist-${location}-help-trigger`}
        size="compact"
        variant="secondary"
        onClick={() => {
          Analytics.onboardingChecklistItemHelpRequested({
            location: `${location}-action-header`,
          });
        }}
      >
        Request help
      </Button>
    </Header>
  );
};
