import React, { useMemo } from "react";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";

/**
 * Returns higlighted text for a given text and query.
 * Matching text is wrapped with <mark /> which can be targetted via CSS
 * Minisearch is faster than Fuse. Fuse provides useless indexes for highlighting.
 * This is a fast and happy medium.
 */
export function SearchHighlight({
  text,
  query,
  ...props
}: {
  text: string;
  query: string;
} & React.HTMLAttributes<HTMLSpanElement>) {
  const matches = useMemo(() => match(text, query.replace(/[^\w ]/g, "")), [text, query]);
  const parts = useMemo(() => parse(text, matches), [text, matches]);

  return useMemo(() => {
    return (
      <span {...props}>
        {parts.map((part, i) => {
          const Component = part.highlight ? "mark" : React.Fragment;
          return <Component key={`${part.text} - ${i}`}>{part.text}</Component>;
        })}
      </span>
    );
  }, [parts, props]);
}
