import React from "react";
import { IntegrationLogo } from "../../shared";
import { CreditCard } from "@puzzle/icons";

const CreditCardLogo = () => {
  return (
    <IntegrationLogo color="#2A2A38">
      <CreditCard active size={18} />
    </IntegrationLogo>
  );
};

export default CreditCardLogo;
