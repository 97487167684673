import { ProgrammaticRuleType, TransactionFileUploaded, TransactionMemoType } from "graphql/types";
import { FullTransactionFragment, FullTransactionFragmentDoc, TransactionDocumentFragment, TransactionPageActivity_TransactionFileUploaded_Fragment } from "../graphql.generated";
import { ApolloCache } from "@apollo/client";

export const getInstitutionForDocument = (
  document: TransactionDocumentFragment,
  transaction: FullTransactionFragment
): string => {
  const activity = transaction.activity.activity.find(
    (a) =>
      a.type === TransactionMemoType.FileUploaded &&
      (a as TransactionFileUploaded).file.id === document.file.id
  ) as TransactionPageActivity_TransactionFileUploaded_Fragment | undefined;
  if (
    activity?.latestDetail?.programmaticRuleType === ProgrammaticRuleType.ReimbursementLink &&
    activity?.latestDetail?.programmaticRuleSource
  ) {
    return activity.latestDetail.programmaticRuleSource;
  }

  return transaction.account.financialInstitution.name;
};


export function updateTransactionDocumentCache(cache: ApolloCache<FullTransactionFragment>, fileId: string, transaction: FullTransactionFragment) {
  cache.writeFragment<FullTransactionFragment>({
    id: `Transaction:${transaction.id}`,
    fragmentName: "fullTransaction",
    fragment: FullTransactionFragmentDoc,
    data: {
      ...transaction,
      documentation: transaction.documentation.filter(
        (document) => document.file.id !== fileId
      ),
    },
  });
}
