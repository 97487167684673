import React from "react";
import Icon, { IconProps } from "../Icon";

export default function Plaid({
  viewBox = "0 0 48 48",
  width = 48,
  height = 48,
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} {...iconProps} viewBox={viewBox}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.309 11L13.2624 13.0867L11.0448 21.0987L13.8183 23.9181L11 26.691L13.087 34.7381L21.0988 36.9545L23.9176 34.1811L26.691 37L34.7376 34.9133L36.9546 26.9008L34.1817 24.0824L37 21.3095L34.913 13.2619L26.9001 11.0455L24.0824 13.8184L21.309 11ZM16.371 14.6278L20.6098 13.5281L22.4634 15.4121L19.7601 18.0716L16.371 14.6278ZM25.676 15.4373L27.5595 13.5845L31.78 14.7522L28.3355 18.1406L25.676 15.4373ZM13.5843 20.4409L14.752 16.221L18.14 19.6648L15.4373 22.3244L13.5843 20.4409ZM29.928 19.7601L33.3725 16.3705L34.471 20.6096L32.5881 22.4629L29.928 19.7601ZM21.3532 19.6906L24.0564 17.031L26.7154 19.7344L24.0127 22.3939L21.3532 19.6906ZM17.0309 23.9433L19.7336 21.2838L22.3942 23.9871L19.6905 26.6467L17.0309 23.9433ZM25.6063 24.0129L28.309 21.3533L30.9685 24.0567L28.2653 26.7162L25.6063 24.0129ZM13.5279 27.3904L15.4119 25.5365L18.0708 28.2404L14.6275 31.6284L13.5279 27.3904ZM21.2835 28.2656L23.9867 25.6061L26.6462 28.3094L23.9436 30.969L21.2835 28.2656ZM29.8584 28.3357L32.5616 25.6762L34.4152 27.5596L33.248 31.7795L29.8584 28.3357ZM16.22 33.2484L19.6639 29.8588L22.3245 32.5622L20.4405 34.416L16.22 33.2484ZM25.5366 32.5879L28.2393 29.9284L31.6279 33.3727L27.3897 34.4719L25.5366 32.5879Z"
        fill="white"
      />
    </Icon>
  );
}
