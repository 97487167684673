import React from "react";
import Icon, { IconProps } from "../Icon";

export default function Quickbooks({
  viewBox = "0 0 48 48",
  width = 48,
  height = 48,
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} {...iconProps} viewBox={viewBox}>
      <path
        d="M12.4448 24.037C12.4448 27.4503 15.2892 30.2236 18.738 30.2236H19.5558V27.9125H18.6669C17.6296 27.9125 16.6349 27.5005 15.9014 26.767C15.1679 26.0335 14.7559 25.0388 14.7559 24.0015C14.7559 22.9642 15.1679 21.9694 15.9014 21.236C16.6349 20.5025 17.6296 20.0905 18.6669 20.0905H20.8002V32.1791C20.8002 33.4591 21.8313 34.4902 23.1113 34.4902V17.7794H18.7025C15.2537 17.7794 12.4448 20.5882 12.4448 24.037ZM29.4045 17.7794H28.4445V20.0905H29.3334C30.3706 20.0905 31.3654 20.5025 32.0989 21.236C32.8323 21.9694 33.2444 22.9642 33.2444 24.0015C33.2444 25.0388 32.8323 26.0335 32.0989 26.767C31.3654 27.5005 30.3706 27.9125 29.3334 27.9125H27.2001V15.8239C27.2001 14.5439 26.1334 13.5128 24.889 13.5128V30.2236H29.3334C30.9836 30.2236 32.5662 29.568 33.7331 28.4012C34.8999 27.2343 35.5555 25.6517 35.5555 24.0015C35.5555 22.3513 34.8999 20.7687 33.7331 19.6018C32.5662 18.4349 30.9836 17.7794 29.3334 17.7794H29.4045Z"
        fill="white"
      />
    </Icon>
  );
}
