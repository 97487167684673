import React from "react";
import Icon, { IconProps } from "./Icon";
import { colors } from "@puzzle/theme";

export default function Edit({
  viewBox = "0 0 16 16",
  color = colors.gray500,
  ...iconProps
}: IconProps) {
  return (
    <Icon {...iconProps} viewBox={viewBox}>
      <path
        d="M12.0858 1.9142C12.351 1.64899 12.7107 1.49999 13.0858 1.49999C13.4609 1.49999 13.8206 1.64899 14.0858 1.9142C14.351 2.17942 14.5 2.53913 14.5 2.9142C14.5 3.28928 14.351 3.64899 14.0858 3.9142L8.66666 9.33334L5.99999 10L6.66666 7.33334L12.0858 1.9142Z"
        stroke={color}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 9.49999L13.5 12.1667C13.5 12.5203 13.3595 12.8594 13.1095 13.1095C12.8594 13.3595 12.5203 13.5 12.1667 13.5L3.83333 13.5C3.47971 13.5 3.14057 13.3595 2.89052 13.1095C2.64048 12.8594 2.5 12.5203 2.5 12.1667L2.5 6"
        stroke={color}
        strokeWidth="1.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 6L2.5 4.33336C2.5 3.97974 2.64047 3.6406 2.89052 3.39056C3.14057 3.14051 3.47971 3.00003 3.83333 3.00003L6.49999 3.00003"
        stroke={color}
        strokeWidth="1.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
