import React from "react";
import Icon, { IconProps } from "./Icon";

export default function VoidSchedule({
  viewBox = "0 0 12 14",
  width = 12,
  height = 14,
  color = "currentColor",
  fill = color,
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} {...iconProps} fill="none" viewBox={viewBox}>
      <path
        d="M4.6499 11.4001L3.7999 10.5501L5.1499 9.2001L3.7999 7.8501L4.6499 7.0001L5.9999 8.3501L7.3499 7.0001L8.1999 7.8501L6.8499 9.2001L8.1999 10.5501L7.3499 11.4001L5.9999 10.0501L4.6499 11.4001ZM1.5999 13.4001C1.2699 13.4001 0.987402 13.2807 0.752402 13.0418C0.517402 12.8029 0.399902 12.5223 0.399902 12.2001V3.4001C0.399902 3.07788 0.517402 2.79732 0.752402 2.55843C0.987402 2.31954 1.2699 2.2001 1.5999 2.2001H2.7999V0.600098H3.9999V2.2001H7.9999V0.600098H9.1999V2.2001H10.3999C10.7299 2.2001 11.0124 2.31954 11.2474 2.55843C11.4824 2.79732 11.5999 3.07788 11.5999 3.4001V12.2001C11.5999 12.5223 11.4824 12.8029 11.2474 13.0418C11.0124 13.2807 10.7299 13.4001 10.3999 13.4001H1.5999ZM1.5999 12.2001H10.3999V6.2001H1.5999V12.2001ZM1.5999 5.0001H10.3999V3.4001H1.5999V5.0001Z"
        fill={fill}
      />
    </Icon>
  );
}
