import { AlertDialog } from "@puzzle/ui";
import React from "react";

const ArchiveAccountConfirmationModal = ({
  onConfirm,
  ...props
}: {
  onConfirm: () => void;
} & React.ComponentProps<typeof AlertDialog>) => {
  return (
    <AlertDialog {...props} size="small">
      <AlertDialog.Title basic showClose>
        Archive Account
      </AlertDialog.Title>
      <AlertDialog.Body>
        Archiving this account will pause the syncing of data from the current source to this
        account and hide it from this page. Your historical records will still exist in the system,
        but no new transactions will be added. You can restore your account at any time.
      </AlertDialog.Body>
      <AlertDialog.Footer>
        <AlertDialog.CancelButton>Never mind</AlertDialog.CancelButton>
        <AlertDialog.ConfirmButton onClick={onConfirm} variant="negative">
          Archive
        </AlertDialog.ConfirmButton>
      </AlertDialog.Footer>
    </AlertDialog>
  );
};

export default ArchiveAccountConfirmationModal;
