import React from "react";
import Icon, { IconProps } from "./Icon";
import { rgba } from "polished";
import { colors } from "@puzzle/theme";

export default function PlugOutline({
  viewBox = "0 0 15 16",
  width = 16,
  height = 20,
  fill = "none",
  stroke = rgba(colors.white, 0.5),
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} {...iconProps} viewBox={viewBox}>
      <path
        stroke={stroke}
        fill={fill}
        d="M12.323 5.22H3.597a.408.408 0 0 0-.397.397v.793c0 .223.174.397.397.397h.397v.68c0 1.81 1.413 3.545 3.173 3.892V14.4h1.587v-3.021c1.735-.347 3.148-2.083 3.173-3.892v-.68h.396a.408.408 0 0 0 .397-.397v-.793a.427.427 0 0 0-.397-.397Z"
      />
      <path
        fill={stroke}
        d="M11.133 1.593A.816.816 0 0 0 10.34.8a.799.799 0 0 0-.793.793V4.2h1.586V1.593Zm-4.76 0A.816.816 0 0 0 5.58.8a.799.799 0 0 0-.793.793V4.2h1.586V1.593Z"
      />
      <path
        fill={stroke}
        d="M10.34.8c.421 0 .793.372.793.793V4.2H9.547V1.593c0-.421.347-.793.793-.793Z"
      />
      <path
        fill={stroke}
        d="M5.58.8c.421 0 .793.372.793.793V4.2H4.787V1.593c0-.421.347-.793.793-.793Z"
      />
    </Icon>
  );
}
