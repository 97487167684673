import { useToasts } from "@puzzle/ui";
import {
  useConnectGustoMutation,
  GustoIntegrationDocument,
  useGustoIntegrationQuery,
  GustoIntegrationQuery,
  IntegrationType,
} from "graphql/types";
import { ConnectGustoInput, UseGustoResult } from "./types";
import { merge } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import useSavePage from "../shared/useSavePage";
import { UseIntegrationHook, useFinancialInstitutions, usePendingConnections } from "../shared";
import config from "lib/config";
import Analytics from "lib/analytics";
import { useGetProvisionGustoAccountUrlLazyQuery } from "./graphql.generated";

const useGusto: UseIntegrationHook<UseGustoResult> = ({
  companyId,
  integrationConnectionId,
  onClickConnect: baseOnClickConnect,
  onClickProvision: baseOnClickProvision,
}): UseGustoResult => {
  const { toast } = useToasts();
  const { data, loading, refetch } = useGustoIntegrationQuery({
    variables: { companyId },
  });
  const { integrations } = data || {};

  const { addPendingConnection, removePendingConnection } = usePendingConnections();
  const { savePage } = useSavePage();
  const { disconnectIntegrationConnection, integrationConnections } = useFinancialInstitutions();

  const [_getProvisionGustoAccountUrl, { loading: initiatedAccountProvision }] =
    useGetProvisionGustoAccountUrlLazyQuery({
      onCompleted(data) {
        const { getProvisionGustoAccountUrl } = data;
        if (getProvisionGustoAccountUrl?.accountClaimUrl) {
          window.open(getProvisionGustoAccountUrl.accountClaimUrl, "_blank");
          toast({
            message: `Initiated Gusto account creation.`,
          });
        }
      },
      onError(error) {
        if (error.graphQLErrors.length > 0) {
          const provisionAccountGqlError = error.graphQLErrors[0];
          const errorMessageRegEx = /A Gusto account with this email address .+ already exists\./;
          if (provisionAccountGqlError?.message.match(errorMessageRegEx)) {
            return toast({
              message: `A Gusto account with this email address already exists.`,
              status: "error",
            });
          }
        } else {
          return toast({
            message: `Could not create new Gusto account.`,
            status: "error",
          });
        }
      },
    });

  const connection = useMemo(() => {
    const connectionId = integrationConnectionId ?? integrations?.gusto.connection?.id;
    return integrationConnections.find((ic) => ic.id === connectionId);
  }, [integrationConnections, integrationConnectionId, integrations]);

  const disconnectIntegration = useMemo(() => {
    const connectionId = connection?.id;
    if (!connectionId) {
      return undefined;
    }

    return async () => {
      const result = disconnectIntegrationConnection(connectionId);

      if (!integrationConnectionId) {
        refetch();
      }

      return result;
    };
  }, [disconnectIntegrationConnection, integrationConnectionId, connection?.id, refetch]);

  const [connectGustoMutation, { loading: connecting, error: connectionError }] =
    useConnectGustoMutation();

  useEffect(() => {
    if (connectionError) {
      Analytics.integrationConnectionFailed({
        integrationType: "Gusto",
        reason: connectionError.message,
      });
    }
  }, [connectionError]);

  const initializationInfo = integrations?.gusto.initializationInfo;

  const onClickConnect = () => {
    baseOnClickConnect && baseOnClickConnect();
    savePage();

    if (!initializationInfo) {
      if (config.IS_LOCAL_DEVELOPMENT) {
        toast({
          message: "useGusto's onClickConnect was called before initialization info was defined",
          status: "error",
        });
      }
      return;
    }
    window.location.href = initializationInfo.connectUrl;
  };

  const onClickProvision = useCallback(() => {
    baseOnClickProvision && baseOnClickProvision();
    savePage();

    return _getProvisionGustoAccountUrl({
      fetchPolicy: "network-only",
      variables: { input: { companyId } },
    });
  }, [companyId]);

  const connectGusto = async (input: ConnectGustoInput) => {
    addPendingConnection(IntegrationType.Gusto);

    return connectGustoMutation({
      variables: { input },

      update(cache, { data }) {
        if (!data) return;
        const gustoIntegration = cache.readQuery<GustoIntegrationQuery>({
          query: GustoIntegrationDocument,
          variables: {
            companyId,
          },
        });

        cache.writeQuery({
          query: GustoIntegrationDocument,
          variables: {
            companyId,
          },
          data: merge({}, gustoIntegration, {
            integrations: {
              gusto: {
                connection: {
                  ...data.connectGusto.connection,
                },
              },
            },
          }),
        });
      },

      onCompleted(data) {
        const { connection } = data.connectGusto;
        removePendingConnection(IntegrationType.Gusto);

        // todo - this is a short term solution to communicating to the user
        // that there is stuff that's processing and to expect loading indicators
        toast({
          message: `Your account has been connected! Data ingestion may take a couple minutes.`,
          status: "success",
        });

        Analytics.integrationConnected({
          connectionId: connection.id,
          integrationType: "Gusto",
          totalAccounts: 1,
        });
      },

      onError({ message }) {
        Analytics.integrationConnectionFailed({
          integrationType: "Gusto",
          reason: message,
        });
      },
    });
  };

  return {
    connectionError,
    connectGusto,
    loading,
    disconnect: disconnectIntegration,
    connecting,
    onClickConnect,
    connection,
    onClickProvision,
    initiatedAccountProvision,
  };
};

export default useGusto;
