import React from "react";
import { IntegrationLogo } from "../../shared";
import { Bank } from "@puzzle/icons";

const BankLogo = ({ circular }: { circular?: boolean }) => {
  return (
    <IntegrationLogo color="#2A2A38" circular={circular}>
      <Bank size={18} />
    </IntegrationLogo>
  );
};

export default BankLogo;
