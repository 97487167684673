import { DateFormatterOptions } from "react-aria";
import { useActiveCompany } from "./ActiveCompanyProvider";
import { DateFormatterResult, useNormalizedDateFormatter } from "@puzzle/utils";

/**
 * Creates an Intl.DateTimeFormat instance using the company's time zone.
 * Compatible with DateValues.
 * If you specifically want local time, use useLocalDateFormatter.
 *
 * @param {DateFormatterOptions} options
 * @returns {DateFormatterResult} formatter
 */
export function useCompanyDateFormatter(options?: DateFormatterOptions): DateFormatterResult {
  const { timeZone } = useActiveCompany<true>();

  return useNormalizedDateFormatter({ timeZone, ...options });
}
