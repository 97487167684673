import React, { useMemo } from "react";
import { AccountWithIntegrationFragment } from "graphql/types";
import { getIntegrationTypeForAccount } from "./ListItemUtils";
import { Button, Text, Stack, styled, Tooltip } from "@puzzle/ui";
import useAppRouter from "lib/useAppRouter";
import { toIntegrationsListItemId } from "../testIds";
import { getAccountStatusTag } from "../DetailDrawer/shared";
import { useFinancialInstitutions } from "../shared";
import { Box, S } from "ve";
import { getDisplayNameForIntegrationType } from "../setup/utils";

const Item = styled("div", {
  display: "flex",
  justifyContent: "center",
  backgroundColor: "$mauve700",
  border: "none",
  padding: "$2",
  borderRadius: "$1",
  alignItems: "center",
});

const Content = styled("div", {
  flex: "1 1 auto",
  alignItems: "center",
  defaultVariants: { disabled: false },
  variants: {
    disabled: {
      true: {
        color: "$gray300",
      },
    },
  },
});

const CardContent = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  color: "$white",
  width: "100%",
  flexGrow: 1,
});

interface AccountListItemProps {
  account: AccountWithIntegrationFragment;
  companyId: string;
  showFullContent: boolean;
}

const AccountListItem = ({ account, showFullContent }: AccountListItemProps) => {
  const { name, mask, manuallyAdded } = account;
  const integrationType = getIntegrationTypeForAccount(account);
  const { pendingAccounts } = useFinancialInstitutions();
  const { router, isIntegrationRoute, getCurrentRoute } = useAppRouter();

  let title = name;
  if (mask) {
    title += ` - ${mask}`;
  }

  const isArchived = !!account.archivedAt;

  const showAccountDetails = (accountId: string) => {
    const integrationPath = ["account", accountId];

    router.push(
      {
        pathname: "/integrations/[[...integrationPath]]",
        query: {
          integrationPath,
        },
      },
      undefined,
      { shallow: isIntegrationRoute(getCurrentRoute()) } // Shallow breaks routing in from inbox tasks
    );
  };

  const matchingPendingAccount = useMemo(() => {
    return pendingAccounts.find((pendingAccount) => pendingAccount.accountId === account.id);
  }, [pendingAccounts, account.id]);

  const subtitle = useMemo(() => {
    const displayName = getDisplayNameForIntegrationType(integrationType);
    return manuallyAdded ? "Data source: None (manual)" : `Data source: ${displayName}`;
  }, [manuallyAdded, integrationType]);

  const tag = useMemo(() => {
    if (manuallyAdded && !matchingPendingAccount) {
      return null;
    }

    return (
      <Box css={{ marginLeft: S["1"], marginRight: S["1"] }}>
        {getAccountStatusTag(account, pendingAccounts, "small")}
      </Box>
    );
  }, [account, manuallyAdded, pendingAccounts, matchingPendingAccount]);

  const manageAccountButton = (
    <Button
      variant="secondary"
      shape="pill"
      disabled={!!matchingPendingAccount}
      onClick={() => {
        showAccountDetails(account.id);
      }}
    >
      Manage account
    </Button>
  );

  const adornment = matchingPendingAccount ? (
    <Tooltip content={matchingPendingAccount.manageAccountTooltip}>
      <div>{manageAccountButton}</div>
    </Tooltip>
  ) : (
    manageAccountButton
  );

  return (
    <Item aria-labelledby={title} data-testid={toIntegrationsListItemId(title)}>
      <CardContent>
        <Content disabled={isArchived} css={{ overflow: "hidden" }}>
          <Stack gap="0h">
            <Box css={{ display: "flex" }}>
              <Text
                variant="headingS"
                color={isArchived ? "gray500" : "gray50"}
                css={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {title}
              </Text>
              {showFullContent && tag}
            </Box>
            {showFullContent && subtitle && (
              <Text variant="bodyS" color={isArchived ? "gray500" : "gray300"}>
                {subtitle}
              </Text>
            )}
          </Stack>
        </Content>

        {showFullContent ? adornment ?? null : null}
      </CardContent>
    </Item>
  );
};

export default AccountListItem;
