import React from "react";
import { IntegrationLogo } from "../../shared";
import { TDCanadaTrust } from "@puzzle/icons";

const TDCanadaTrustLogo = () => {
  return (
    <IntegrationLogo color="#6cb35d">
      <TDCanadaTrust />
    </IntegrationLogo>
  );
};

export default TDCanadaTrustLogo;
