import React from "react";
import { IntegrationLogo } from "../../shared";
import { AmericanExpress } from "@puzzle/icons";

const AmericanExpressLogo = ({ circular }: { circular?: boolean }) => {
  return (
    <IntegrationLogo color="#016FD0" circular={circular}>
      <AmericanExpress />
    </IntegrationLogo>
  );
};

export default AmericanExpressLogo;
