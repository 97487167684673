import {
  IntegrationType,
  useEveryIntegrationQuery,
  useFinancialInstitutionsQuery,
} from "graphql/types";
import { useEffect, useMemo, useState } from "react";
import { UseIntegrationHook, useFinancialInstitutions, usePendingConnections } from "../shared";
import { UseEveryResult } from "./types";

const useEvery: UseIntegrationHook<UseEveryResult> = ({
  companyId,
  integrationConnectionId,
}): UseEveryResult => {
  const { data, loading, refetch } = useEveryIntegrationQuery({
    variables: { companyId },
  });
  const { integrations } = data || {};

  const {
    disconnectIntegrationConnection,
    integrationConnections,
    refetchAccountsAndIntegrations,
  } = useFinancialInstitutions();

  const connection = useMemo(() => {
    const connectionId = integrationConnectionId ?? integrations?.every.connection?.id;
    return integrationConnections.find((ic) => ic.id === connectionId);
  }, [integrationConnections, integrationConnectionId, integrations]);

  const disconnectIntegration = useMemo(() => {
    const connectionId = connection?.id;
    if (!connectionId) {
      return undefined;
    }

    return async () => {
      const result = disconnectIntegrationConnection(connectionId);

      if (!integrationConnectionId) {
        refetch();
      }

      return result;
    };
  }, [disconnectIntegrationConnection, integrationConnectionId, connection?.id, refetch]);

  const [pollInterval, setPollInterval] = useState(0);
  const { data: fiData } = useFinancialInstitutionsQuery({
    pollInterval,
    variables: { companyId, integrationType: IntegrationType.Every },
  });

  const financialInstitutions = useMemo(() => fiData?.financialInstitutionsFor ?? [], [fiData]);
  const accounts = financialInstitutions.flatMap((fi) => fi.accounts);

  const { addPendingConnection, removePendingConnection } = usePendingConnections();

  useEffect(() => {
    // If we have a connection, but no accounts, we need to poll until we have accounts
    if (connection && financialInstitutions.length > 0 && accounts.length === 0) {
      addPendingConnection(IntegrationType.Every);
      setPollInterval(1000);
    } else if (pollInterval > 0) {
      // If we have accounts and we were polling, stop polling and refetch the accounts
      removePendingConnection(IntegrationType.Every);
      setPollInterval(0);
      refetchAccountsAndIntegrations();
    }
  }, [
    financialInstitutions,
    integrations?.every.connection,
    addPendingConnection,
    connection,
    accounts.length,
    removePendingConnection,
    refetchAccountsAndIntegrations,
    pollInterval,
  ]);

  return {
    loading,
    disconnect: disconnectIntegration,
    connecting: false,
    connection,
    financialInstitutions,
  };
};

export default useEvery;
