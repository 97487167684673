import { MeowNew } from "@puzzle/icons";
import React from "react";
import { IntegrationLogo } from "../shared";

const MeowLogoAlt = () => {
  return (
    <IntegrationLogo key="meow" color="#0C1D37">
      <MeowNew />
    </IntegrationLogo>
  );
};

export default MeowLogoAlt;
