import React from "react";
import Logo from "../quickbooks/Logo";
import { useQuickbooks } from "../quickbooks";
import IntegrationDetails from "./IntegrationDetails";
import { DetailProps } from "./types";

const QuickbooksDetails = ({ companyId, integrationConnectionId, ...props }: DetailProps) => {
  const quickbooks = useQuickbooks({ companyId, integrationConnectionId });
  //TODO update QBO status
  return (
    <IntegrationDetails
      logo={<Logo />}
      title="QuickBooks Online"
      subtitle="Historical financials"
      connection={quickbooks.connection}
      onReconnect={quickbooks.onClickConnect}
      onDisconnect={quickbooks.disconnect}
      {...props}
    />
  );
};

export default QuickbooksDetails;
