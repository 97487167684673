import React from "react";
import { IntegrationLogo } from "../../shared";
import { FirstRepublic } from "@puzzle/icons";

const FirstRepublicLogo = ({ circular }: { circular?: boolean }) => {
  return (
    <IntegrationLogo color="#0A382B" circular={circular}>
      <FirstRepublic />
    </IntegrationLogo>
  );
};

export default FirstRepublicLogo;
