import React, { useMemo, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Timeline, Text } from "@puzzle/ui";
import { Receipt, Sparkle } from "@puzzle/icons";

import {
  TransactionPageActivityFragment,
  TransactionPageActivity_TransactionAssignment_Fragment,
  TransactionPageActivity_TransactionAssignmentCanceled_Fragment,
  TransactionPageActivity_TransactionAssignmentCompleted_Fragment,
  TransactionPageActivity_TransactionCapitalizable_Fragment,
  TransactionPageActivity_TransactionCategorized_Fragment,
  TransactionPageActivity_TransactionFileDeleted_Fragment,
  TransactionPageActivity_TransactionFileUploaded_Fragment,
  TransactionPageActivity_TransactionFinalized_Fragment,
  TransactionPageActivity_TransactionImplicitlyCategorized_Fragment,
  TransactionPageActivity_TransactionMessage_Fragment,
  TransactionPageActivity_TransactionPosted_Fragment,
  TransactionPageActivity_TransactionUncapitalizable_Fragment,
  TransactionPageActivity_TransactionUnFinalized_Fragment,
  TransactionPageActivity_TransactionVendorChanged_Fragment,
  TransactionPageActivity_TransactionVendorImplicitlyChanged_Fragment,
  TransactionPageActivity_TransactionMemoChanged_Fragment,
  FullTransactionFragment,
  TransactionPageActivity_TransactionMarkedAsBillPayment_Fragment,
  TransactionPageActivity_TransactionUnmarkedAsBillPayment_Fragment,
  TransactionPageActivity_TransactionRecurrence_Fragment,
  TransactionPageActivity_TransactionAiComment_Fragment,
  TransactionPageActivity_TransactionContextProvided_Fragment,
} from "components/dashboard/Transactions/graphql.generated";
import {
  CommunicationMedium,
  ProgrammaticRuleType,
  TransactionDetailActorType,
} from "graphql/types";

import DescriptionList from "components/common/DescriptionList";
import { RuleModal } from "../Rules/RuleModal";
import { CreateRuleInput } from "../Rules/hooks";
import { capitalize } from "@puzzle/utils";
import Loader from "components/common/Loader";
import { AlphaTag } from "../AI/AlphaTag";
import { LOADING_PLACEHOLDER_MESSAGE } from "components/AI/useAI";
import { Box } from "ve";
import { modifyRuleBaseStyle, modifyRuleVisibleStyle } from "./ActivitySection.css";

type MinimalTransaction = Pick<FullTransactionFragment, "account" | "activity">;

/**
 * Linking can lead to a sharing of metadata
 * (e.g) reimbursement linking takes vendor|coaKey from reimbursement and adds it to transaction
 *
 * to give the user a correct history, we use ProgrammaticRuleType and ProgrammaticRuleSource
 * to show that a system change from linking took data from an institution (e.g Ramp reimbursement)
 * that differs from the transaction institution (e.g BoA reimbursement payment)
 */
type ProgrammaticRuleLinkType =
  | ProgrammaticRuleType.ReimbursementLink
  | ProgrammaticRuleType.BillLink;
const resourceByRuleType = new Map<ProgrammaticRuleLinkType, string>([
  [ProgrammaticRuleType.ReimbursementLink, "reimbursement"],
  [ProgrammaticRuleType.BillLink, "bill"],
]);
const isProgrammaticRuleLink = (
  programmaticRuleType?: ProgrammaticRuleType | null
): programmaticRuleType is ProgrammaticRuleLinkType =>
  !!programmaticRuleType &&
  [ProgrammaticRuleType.ReimbursementLink, ProgrammaticRuleType.BillLink].includes(
    programmaticRuleType
  );
const programmaticRuleLinkSuffix = (
  type: ProgrammaticRuleLinkType,
  source?: string | null
): string => ` based on a matched ${resourceByRuleType.get(type)} from ${source ?? "Unknown"}`;

const ImplicitRuleItem = ({
  data,
  fieldName,
  previousFieldName,
  value,
  initialValues,
}: {
  fieldName: string;
  previousFieldName: string;
  value: string;
  initialValues?: Partial<CreateRuleInput>;
  data: Extract<TransactionPageActivityFragment, { detail: { descriptor: string } }>;
}) => {
  const [hovered, setHovered] = useState(false);
  const [isCreatingRule, setIsCreatingRule] = useState(false);

  const { createdAt, detail, actor } = data;
  const { descriptor } = detail;

  return (
    <>
      <Timeline.Item
        onMouseMove={() => {
          if (!hovered) {
            setHovered(true);
          }
        }}
        onMouseLeave={() => setHovered(false)}
        date={createdAt}
        body={
          <>
            {actor.name} suggested the {fieldName} <em>{value}</em> based upon a previous{" "}
            {previousFieldName} of {descriptor}.
          </>
        }
        subtext={
          <Box className={hovered || isCreatingRule ? modifyRuleVisibleStyle : modifyRuleBaseStyle}>
            Incorrect?{" "}
            <Text
              css={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => {
                setIsCreatingRule(true);
              }}
              role="button"
              tabIndex={0}
            >
              Modify this rule
            </Text>
          </Box>
        }
      />

      {/* TODO Consider only one instance of this. But at most I'd expect 2 renders (category and vendor). */}
      <RuleModal
        open={isCreatingRule}
        onOpenChange={(open) => !open && setIsCreatingRule(false)}
        title="Modify and save to active rules"
        initialValues={{
          rule: descriptor,
          ...initialValues,
        }}
        location="transactionSideDrawer"
      />
    </>
  );
};

const Assignment = ({ data }: { data: TransactionPageActivity_TransactionAssignment_Fragment }) => {
  const { createdByUser, targetUser, actor } = data;
  return (
    <Timeline.Item
      icon={<Timeline.Avatar user={createdByUser ?? { name: "System" }} />}
      date={data.createdAt}
      body={
        <>
          <em>{actor.name}</em> requested <em>{data.request}</em> from <em>{targetUser.name}</em>.
        </>
      }
    />
  );
};

const AssignmentComplete = ({
  data,
}: {
  data: TransactionPageActivity_TransactionAssignmentCompleted_Fragment;
}) => {
  const { assignment, createdAt, createdByUser, actor } = data;
  return (
    <Timeline.Item
      icon={<Timeline.Avatar user={createdByUser ?? { name: actor.name }} />}
      date={createdAt}
      body={
        <>
          <em>{actor.name}</em> marked the request for <em>{assignment.request}</em> as{" "}
          <em>resolved</em>.
        </>
      }
    />
  );
};

const AssignmentCanceled = ({
  data,
}: {
  data: TransactionPageActivity_TransactionAssignmentCanceled_Fragment;
}) => {
  const { actor, createdAt, createdByUser, assignment } = data;
  return (
    <Timeline.Item
      icon={<Timeline.Avatar user={createdByUser ?? { name: "System" }} />}
      date={createdAt}
      body={
        <>
          <em>{actor.name}</em> canceled the request for <em>{assignment.request}</em>.
        </>
      }
    />
  );
};

const Categorized = ({
  data,
}: {
  data: TransactionPageActivity_TransactionCategorized_Fragment;
}) => {
  const { createdAt, detail, actor } = data;
  const { category, actorType, programmaticRuleType, programmaticRuleSource } = detail;
  const suffix = isProgrammaticRuleLink(programmaticRuleType)
    ? programmaticRuleLinkSuffix(programmaticRuleType, programmaticRuleSource)
    : "";

  return (
    <Timeline.Item
      date={createdAt}
      body={
        actorType !== TransactionDetailActorType.SystemActor ? (
          <>
            <em>{actor.name}</em> categorized this transaction as <em>{category.name}</em>.
          </>
        ) : (
          <>
            {actor.name} suggested the category <em>{category.name}</em>
            {suffix}.
          </>
        )
      }
    />
  );
};

const ImplicitlyCategorized = ({
  data,
}: {
  data: TransactionPageActivity_TransactionImplicitlyCategorized_Fragment;
}) => {
  const { detail } = data;
  const { category } = detail;

  if (!category.coaKey) {
    // Shouldn't happen?
    return null;
  }

  return (
    <ImplicitRuleItem
      data={data}
      fieldName="category"
      previousFieldName="categorization"
      value={category.name}
      initialValues={{
        ledgerCoaKey: category.coaKey,
      }}
    />
  );
};

const VendorChanged = ({
  data,
}: {
  data: TransactionPageActivity_TransactionVendorChanged_Fragment;
}) => {
  const { createdAt, detail, actor } = data;
  const { categorizedByUser, vendor, actorType, programmaticRuleSource, programmaticRuleType } =
    detail;
  const suffix = isProgrammaticRuleLink(programmaticRuleType)
    ? programmaticRuleLinkSuffix(programmaticRuleType, programmaticRuleSource)
    : "";

  if (!vendor) {
    return null;
  }

  if (categorizedByUser && actorType === TransactionDetailActorType.UserActor) {
    return (
      <Timeline.Item
        date={createdAt}
        body={
          vendor ? (
            <>
              <em>{actor.name}</em> updated the vendor to <em>{vendor.name}</em>.
            </>
          ) : (
            <>
              <em>{actor.name}</em> deleted the vendor.
            </>
          )
        }
      />
    );
  }

  return (
    <Timeline.Item
      date={createdAt}
      body={
        <>
          {actor.name} suggested the vendor <em>{vendor.name}</em>
          {suffix}.
        </>
      }
    />
  );
};

const VendorImplicitlyChanged = ({
  data,
}: {
  data: TransactionPageActivity_TransactionVendorImplicitlyChanged_Fragment;
}) => {
  const vendor = data?.detail.vendor;

  if (!vendor) {
    // Shouldn't happen? Bad type?
    return null;
  }

  return (
    <ImplicitRuleItem
      data={data}
      fieldName="vendor"
      previousFieldName="vendor"
      value={vendor.name}
      initialValues={{
        vendorId: vendor.id,
      }}
    />
  );
};

const Capitalizable = ({
  data,
}: {
  data: TransactionPageActivity_TransactionCapitalizable_Fragment;
}) => {
  const { createdByUser, createdAt, actor } = data;
  return (
    <Timeline.Item
      date={createdAt}
      body={
        <>
          <em>{actor.name}</em> marked this transaction as <em>capitalizable</em>.
        </>
      }
    />
  );
};

const Uncapitalizable = ({
  data,
}: {
  data: TransactionPageActivity_TransactionUncapitalizable_Fragment;
}) => {
  const { createdByUser, createdAt, actor } = data;
  return (
    <Timeline.Item
      date={createdAt}
      body={
        <>
          <em>{actor.name}</em> marked this transaction as <em>not capitalizable</em>.
        </>
      }
    />
  );
};

const MarkedBillPayment = ({
  data,
}: {
  data: TransactionPageActivity_TransactionMarkedAsBillPayment_Fragment;
}) => {
  const { createdByUser, createdAt, actor } = data;
  return (
    <Timeline.Item
      date={createdAt}
      body={
        <>
          <em>{actor.name}</em> marked this transaction as a<em> bill payment</em>.
        </>
      }
    />
  );
};

const UnmarkedBillPayment = ({
  data,
}: {
  data: TransactionPageActivity_TransactionUnmarkedAsBillPayment_Fragment;
}) => {
  const { createdByUser, createdAt, actor } = data;
  return (
    <Timeline.Item
      date={createdAt}
      body={
        <>
          <em>{actor.name}</em> marked this transaction as <em>not</em> a<em> bill payment</em>.
        </>
      }
    />
  );
};

const FileUploaded = ({
  data,
  transaction,
}: {
  data: TransactionPageActivity_TransactionFileUploaded_Fragment;
  transaction: MinimalTransaction;
}) => {
  const { createdByUser, createdAt, file, actor, latestDetail } = data;
  const type = latestDetail?.programmaticRuleType;
  const suffix = isProgrammaticRuleLink(type)
    ? programmaticRuleLinkSuffix(type, latestDetail?.programmaticRuleSource)
    : ` through ${transaction.account.financialInstitution.name}`;

  return (
    <Timeline.Item
      icon={<Receipt />}
      date={createdAt}
      body={
        createdByUser ? (
          <>
            <em>{actor.name}</em> uploaded the documentation <em>{file.filename}</em>.
          </>
        ) : (
          <>
            {actor.name} added the documentation <em>{file.filename}</em>
            {suffix}.
          </>
        )
      }
    />
  );
};

const FileDeleted = ({
  data,
}: {
  data: TransactionPageActivity_TransactionFileDeleted_Fragment;
}) => {
  const { createdByUser, createdAt, file, actor } = data;
  return (
    <Timeline.Item
      icon={<Receipt />}
      date={createdAt}
      body={
        <>
          <em>{actor.name}</em> deleted the documentation <em>{file.filename}</em>.
        </>
      }
    />
  );
};

const ContextProvidedViaMedium = ({
  data,
}: {
  data: TransactionPageActivity_TransactionContextProvided_Fragment;
}) => {
  const { createdByUser, createdAt, context, viaMedium } = data;
  return (
    <Timeline.Item
      type="comment"
      icon={<Timeline.Avatar user={createdByUser!} />}
      author={createdByUser}
      viaMedium={viaMedium !== CommunicationMedium.Dashboard ? viaMedium : undefined}
      date={createdAt}
      body={context ? <Markdown>{context}</Markdown> : ""}
    />
  );
};

const Finalized = ({ data }: { data: TransactionPageActivity_TransactionFinalized_Fragment }) => {
  const { createdByUser, createdAt, actor } = data;
  return (
    <Timeline.Item
      date={createdAt}
      body={
        <>
          <em>{actor.name}</em> marked this transaction as <em>finalized</em>.
        </>
      }
    />
  );
};

const Unfinalized = ({
  data,
}: {
  data: TransactionPageActivity_TransactionUnFinalized_Fragment;
}) => {
  const { createdByUser, createdAt, actor } = data;
  return (
    <Timeline.Item
      date={createdAt}
      body={
        <>
          <em>{actor.name}</em> marked this transaction as <em>not finalized</em>
        </>
      }
    />
  );
};

const defaultDisallowed = [
  "blockquote",
  "br",
  "code",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "hr",
  "img",
  "li",
  "ol",
  "pre",
  "strong",
  "ul",
  "del",
  "input",
  "table",
  "tbody",
  "td",
  "th",
  "thead",
  "tr",
  "em",
];

const Markdown = ({
  disallowedElements = defaultDisallowed,
  ...props
}: React.ComponentProps<typeof ReactMarkdown>) => {
  return (
    <ReactMarkdown
      linkTarget="_blank"
      remarkPlugins={[remarkGfm]}
      // only allow links and paragraphs for now
      disallowedElements={disallowedElements}
      {...props}
    />
  );
};

const Message = ({ data }: { data: TransactionPageActivity_TransactionMessage_Fragment }) => {
  const { createdByUser, createdAt, text } = data;
  return (
    <Timeline.Item
      type="comment"
      icon={<Timeline.Avatar user={data.createdByUser!} />}
      author={createdByUser}
      date={createdAt}
      body={text ? <Markdown>{text}</Markdown> : ""}
    />
  );
};

const AIComment = ({ data }: { data: TransactionPageActivity_TransactionAiComment_Fragment }) => {
  const { createdByUser, createdAt, message, actor } = data;
  return (
    <Timeline.Item
      type="comment"
      icon={<Sparkle />}
      author={
        <div style={{ display: "flex", gap: 8 }}>
          Generated with {actor.name} <AlphaTag />
        </div>
      }
      date={createdAt}
      body={
        message ? (
          <div>
            {message === LOADING_PLACEHOLDER_MESSAGE ? (
              <div>
                <Loader
                  variant="secondary"
                  css={{
                    float: "left",
                    scale: 0.75,
                    width: 30,
                    marginTop: -2,
                  }}
                />{" "}
                Gathering insights{" "}
              </div>
            ) : (
              message
            )}
          </div>
        ) : (
          ""
        )
      }
    />
  );
};

const Posted = ({ data }: { data: TransactionPageActivity_TransactionPosted_Fragment }) => {
  const { createdByUser, createdAt, actor } = data;
  return (
    <Timeline.Item
      icon={<Timeline.Avatar user={createdByUser ?? { name: "System" }} />}
      author={createdByUser}
      date={createdAt}
      body={
        <>
          <em>{actor.name}</em> posted this transaction to the current period.
        </>
      }
    />
  );
};

const RecurrenceUpdated = ({
  data,
}: {
  data: TransactionPageActivity_TransactionRecurrence_Fragment;
}) => {
  const { createdByUser, createdAt, detail, actor } = data;
  return (
    <Timeline.Item
      date={createdAt}
      author={createdByUser}
      body={
        <>
          <em>{actor.name}</em> updated the recurrence of this transaction to{" "}
          <em>{capitalize(detail?.recurrence!.toLowerCase())}</em>.
        </>
      }
    />
  );
};

const MemoChanged = ({
  data,
  transaction,
}: {
  data: TransactionPageActivity_TransactionMemoChanged_Fragment;
  transaction: MinimalTransaction;
}) => {
  const { createdByUser, createdAt, detail, previousValue, actor } = data;
  const institutionName = transaction.account.financialInstitution.name;
  const suffix = isProgrammaticRuleLink(detail.programmaticRuleType)
    ? programmaticRuleLinkSuffix(detail.programmaticRuleType, detail.programmaticRuleSource)
    : ` based on the change in ${institutionName}`;
  const basedOnText = !createdByUser ? suffix : "";

  let body = (
    <>
      updated memo from <em>{previousValue}</em> to <em>{detail.memo}</em>
    </>
  );

  if (!detail.memo) {
    body = (
      <>
        removed memo <em>{previousValue}</em>
      </>
    );
  } else if (!previousValue) {
    body = (
      <>
        updated memo to <em>{detail.memo}</em>
      </>
    );
  }

  return (
    <Timeline.Item
      date={createdAt}
      // prettier-ignore
      body={
        <>
          {actor.name} {body}{basedOnText}.
        </>
      }
    />
  );
};

const ActivityItemMap: {
  [k in NonNullable<TransactionPageActivityFragment["__typename"]>]?: (
    props: React.PropsWithChildren<{
      data: Extract<TransactionPageActivityFragment, { __typename?: k }>;
      transaction: MinimalTransaction;
    }>
  ) => React.ReactNode;
} = {
  TransactionAssignment: Assignment,
  TransactionAssignmentCompleted: AssignmentComplete,
  TransactionAssignmentCanceled: AssignmentCanceled,
  TransactionCapitalizable: Capitalizable,
  TransactionFileDeleted: FileDeleted,
  TransactionFileUploaded: FileUploaded,
  TransactionFinalized: Finalized,
  TransactionMessage: Message,
  TransactionAIComment: AIComment,
  TransactionPosted: Posted,
  TransactionCategorized: Categorized,
  TransactionImplicitlyCategorized: ImplicitlyCategorized,
  TransactionUncapitalizable: Uncapitalizable,
  TransactionUnFinalized: Unfinalized,
  TransactionVendorChanged: VendorChanged,
  TransactionMarkedAsBillPayment: MarkedBillPayment,
  TransactionUnmarkedAsBillPayment: UnmarkedBillPayment,
  TransactionVendorImplicitlyChanged: VendorImplicitlyChanged,
  TransactionMemoChanged: MemoChanged,
  TransactionRecurrence: RecurrenceUpdated,
  TransactionContextProvided: ContextProvidedViaMedium,
};

const ActivitySection = ({ transaction }: { transaction: MinimalTransaction }) => {
  const activity = transaction.activity.activity;

  const timeline = useMemo(() => {
    return (
      <Timeline>
        {activity.map((data, i) => {
          const Component = data.__typename && ActivityItemMap[data.__typename];
          if (!Component) {
            return null;
          }

          return (
            <Component
              key={`${data.id}-${i}`}
              // ActivityItemMap is properly typed, so `any` is fine.
              // You can maybe assert to the right type, but that's not really safer.
              data={data as any}
              transaction={transaction}
            />
          );
        })}
        <div id="activity-footer"></div>
      </Timeline>
    );
  }, [activity, transaction]);

  return <DescriptionList direction="vertical" items={[["Activity", timeline]]} />;
};

export default ActivitySection;
