import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Stripe({
  viewBox = "0 0 48 48",
  width = 48,
  height = 48,
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} {...iconProps} viewBox={viewBox}>
      <path d="M25.4891 17.5V19.4933L22.9791 20.0236V18.0204L25.4891 17.5Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.7105 20.6031C29.7305 20.6031 29.1005 21.0548 28.7505 21.369L28.6205 20.7602H26.4206V32.2096L28.9205 31.6891L28.9305 28.9103C29.2905 29.1656 29.8205 29.5289 30.7005 29.5289C32.4905 29.5289 34.1205 28.1149 34.1205 25.0022C34.1105 22.1545 32.4605 20.6031 30.7105 20.6031ZM30.1105 27.3686C29.5205 27.3686 29.1705 27.1624 28.9305 26.9071L28.9205 23.2641C29.1805 22.9794 29.5405 22.783 30.1105 22.783C31.0205 22.783 31.6505 23.7846 31.6505 25.0709C31.6505 26.3867 31.0305 27.3686 30.1105 27.3686Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42 25.1003C42 22.5866 40.76 20.6031 38.39 20.6031C36.01 20.6031 34.5701 22.5866 34.5701 25.0807C34.5701 28.0363 36.27 29.5289 38.71 29.5289C39.9 29.5289 40.8 29.2637 41.48 28.8906V26.9267C40.8 27.2606 40.02 27.4668 39.03 27.4668C38.06 27.4668 37.2 27.1329 37.09 25.9743H41.98C41.98 25.9201 41.9836 25.7972 41.9877 25.6555C41.9934 25.4632 42 25.2359 42 25.1003ZM37.06 24.1675C37.06 23.0579 37.75 22.5964 38.38 22.5964C38.99 22.5964 39.64 23.0579 39.64 24.1675H37.06Z"
        fill="white"
      />
      <path d="M25.489 20.77H22.979V29.3619H25.489V20.77Z" fill="white" />
      <path
        d="M20.1305 20.7696L20.2905 21.4962C20.8805 20.4357 22.0505 20.6518 22.3705 20.7696V23.028C22.0605 22.92 21.0605 22.7826 20.4705 23.5386V29.3615H17.9706V20.7696H20.1305Z"
        fill="white"
      />
      <path
        d="M15.2894 18.639L12.8494 19.1496L12.8394 27.0149C12.8394 28.4682 13.9494 29.5385 15.4294 29.5385C16.2494 29.5385 16.8494 29.3912 17.1794 29.2145V27.2211C16.8594 27.3488 15.2794 27.8005 15.2794 26.3472V22.8614H17.1794V20.7698H15.2794L15.2894 18.639Z"
        fill="white"
      />
      <path
        d="M9.37997 22.7339C8.84997 22.7339 8.52998 22.8812 8.52998 23.2641C8.52998 23.6823 9.0807 23.8662 9.76393 24.0944C10.8778 24.4664 12.3437 24.956 12.3499 26.7696C12.3499 28.5273 10.92 29.5387 8.83997 29.5387C7.97998 29.5387 7.03999 29.3718 6.11 28.979V26.642C6.94999 27.0937 8.00998 27.4275 8.83997 27.4275C9.39997 27.4275 9.79997 27.2802 9.79997 26.8286C9.79997 26.3654 9.20297 26.1537 8.48224 25.8981C7.38462 25.5089 6 25.0179 6 23.382C6 21.6439 7.34999 20.6031 9.37997 20.6031C10.21 20.6031 11.03 20.7307 11.8599 21.0548V23.3623C11.1 22.9597 10.14 22.7339 9.37997 22.7339Z"
        fill="white"
      />
    </Icon>
  );
}
