import { UseFormReturn, useWatch } from "react-hook-form";
import { ScheduleFormValues } from "../../InvoiceForm/types";
import { sumAmounts } from "@puzzle/utils";
import Big from "big.js";

export const useRemainingToRecognize = ({
  amount,
  scheduleForm,
}: {
  amount: string;
  scheduleForm: UseFormReturn<ScheduleFormValues>;
}) => {
  const entries = useWatch({ control: scheduleForm.control, name: "manualEntries" });

  const amountRecognized = entries?.length
    ? sumAmounts(entries.filter((entry) => !!entry.amount).map((entry) => entry.amount))
    : 0;

  const remainingToRecognize = Big(amount).minus(amountRecognized);

  return remainingToRecognize;
};
