import List from "@mui/material/List";
import React from "react";
import { AccountFragment, FinancialInstitutionFragment, IntegrationType } from "graphql/types";
import { useActiveCompany } from "../../companies/ActiveCompanyProvider";
import { SetupGustoListItem } from "../gusto";
import { SetupQuickbooksListItem } from "../quickbooks";
import { AddBankListItem, DisplayAccountListItem, DisplayBankListItem } from "../bank";
import { SetupTrinetListItem } from "../trinet";
import { IntegrationsListItemBaseProps } from "./types";
import { flatten } from "lodash";
import { SetupStripeListItem } from "../stripe";
import { SetupSalesforceListItem } from "../salesforce";
import { SetupBillDotComListItem } from "../billdotcom";
import { SetupExpensifyListItem } from "../expensify";
import { SetupCartaListItem } from "../carta";
import { SetupCaptableListItem } from "../captable";
import { SetupBrexListItem } from "../brex";
import { SetupRampListItem } from "../ramp";
import { SetupRipplingListItem } from "../rippling";
import { SetupMercuryListItem } from "../mercury";
import { SetupMeowListItem } from "../meow";
import { SetupDeelListItem } from "../deel";
import { IntegrationDisplayComponent } from "../shared/types";
import { EveryListItem } from "../every";

export type IntegrationsListItem = IntegrationType | FinancialInstitutionFragment;
interface IntegrationsListProps {
  DisplayComponent: IntegrationDisplayComponent<
    React.PropsWithChildren<IntegrationsListItemBaseProps>
  >;
  integrations: Array<IntegrationsListItem>;
  filterAccounts?: (account: AccountFragment) => boolean;
  children?: React.ReactNode;
  className?: string;
}

export function isFinancialInstutitionIntegration(
  i: IntegrationsListItem
): i is FinancialInstitutionFragment {
  return (i as FinancialInstitutionFragment)?.id !== undefined;
}

const IntegrationsList = ({
  integrations,
  filterAccounts = () => true,
  children,
  DisplayComponent,
  className,
}: IntegrationsListProps) => {
  const { company } = useActiveCompany<true>();
  const companyId = company.id;

  if (!companyId) {
    return <></>;
  }

  const integrationsToListItem: Partial<Record<IntegrationType, React.ReactNode>> = {
    [IntegrationType.Finicity]: (
      <SetupBrexListItem
        key={IntegrationType.Finicity}
        DisplayComponent={DisplayComponent}
        companyId={companyId}
      />
    ),
    [IntegrationType.Gusto]: (
      <SetupGustoListItem
        key={IntegrationType.Gusto}
        DisplayComponent={DisplayComponent}
        companyId={companyId}
      />
    ),
    [IntegrationType.Plaid]: (
      <AddBankListItem
        key={IntegrationType.Plaid}
        DisplayComponent={DisplayComponent}
        companyId={companyId}
      />
    ),
    [IntegrationType.QuickBooks]: (
      <SetupQuickbooksListItem
        key={IntegrationType.QuickBooks}
        DisplayComponent={DisplayComponent}
        companyId={companyId}
      />
    ),
    [IntegrationType.TriNet]: (
      <SetupTrinetListItem
        key={IntegrationType.TriNet}
        DisplayComponent={DisplayComponent}
        companyId={companyId}
      />
    ),
    [IntegrationType.Stripe]: (
      <SetupStripeListItem
        key={IntegrationType.Stripe}
        DisplayComponent={DisplayComponent}
        companyId={companyId}
      />
    ),
    [IntegrationType.Salesforce]: (
      <SetupSalesforceListItem
        key={IntegrationType.Salesforce}
        DisplayComponent={DisplayComponent}
        companyId={companyId}
      />
    ),
    [IntegrationType.BillDotCom]: (
      <SetupBillDotComListItem
        key={IntegrationType.BillDotCom}
        DisplayComponent={DisplayComponent}
        companyId={companyId}
      />
    ),
    [IntegrationType.Expensify]: (
      <SetupExpensifyListItem
        key={IntegrationType.Expensify}
        DisplayComponent={DisplayComponent}
        companyId={companyId}
      />
    ),
    [IntegrationType.Captable]: (
      <SetupCaptableListItem
        key={IntegrationType.Captable}
        DisplayComponent={DisplayComponent}
        companyId={companyId}
      />
    ),
    [IntegrationType.Carta]: (
      <SetupCartaListItem
        key={IntegrationType.Carta}
        DisplayComponent={DisplayComponent}
        companyId={companyId}
      />
    ),
    [IntegrationType.Brex]: (
      <SetupBrexListItem
        key={IntegrationType.Brex}
        DisplayComponent={DisplayComponent}
        companyId={companyId}
      />
    ),
    [IntegrationType.Ramp]: (
      <SetupRampListItem
        key={IntegrationType.Ramp}
        DisplayComponent={DisplayComponent}
        companyId={companyId}
      />
    ),
    [IntegrationType.Rippling]: (
      <SetupRipplingListItem
        key={IntegrationType.Rippling}
        DisplayComponent={DisplayComponent}
        companyId={companyId}
      />
    ),
    [IntegrationType.Mercury]: (
      <SetupMercuryListItem
        key={IntegrationType.Mercury}
        DisplayComponent={DisplayComponent}
        companyId={companyId}
      />
    ),
    [IntegrationType.Meow]: (
      <SetupMeowListItem
        key={IntegrationType.Meow}
        DisplayComponent={DisplayComponent}
        companyId={companyId}
      />
    ),

    [IntegrationType.Deel]: (
      <SetupDeelListItem
        key={IntegrationType.Deel}
        DisplayComponent={DisplayComponent}
        companyId={companyId}
      />
    ),

    [IntegrationType.Every]: (
      <EveryListItem
        key={IntegrationType.Every}
        DisplayComponent={DisplayComponent}
        companyId={companyId}
      />
    ),
    // Stub for now
    [IntegrationType.SvbOfx]: null,
    [IntegrationType.DirectIngest]: null,
    [IntegrationType.Human]: null,
    [IntegrationType.Slack]: null,
  };

  const items = integrations.map((i) => {
    let component: React.ReactNode;
    if (isFinancialInstutitionIntegration(i)) {
      component = (
        <DisplayBankListItem
          key={i.id}
          financialInstitution={i}
          companyId={companyId}
          DisplayComponent={DisplayComponent}
        />
      );
      if (i.accounts && i.accounts.length) {
        component = i.accounts
          .filter((account) => filterAccounts(account))
          .map((account) => (
            <DisplayAccountListItem
              key={account.id}
              financialInstitution={i}
              account={account}
              companyId={companyId}
              DisplayComponent={DisplayComponent}
            />
          ));
      }
    } else {
      component = integrationsToListItem[i];
    }

    return component;
  });
  return (
    <List className={className}>
      {flatten(items)}
      {children}
    </List>
  );
};

export default IntegrationsList;
