import { useMemo } from "react";
import { merge } from "lodash";
import {
  IntegrationConnectionCondition,
  IntegrationType,
  SvbOfxIntegrationDocument,
  SvbOfxIntegrationQuery,
  useConnectSvbOfxMutation,
  useFinancialInstitutionsQuery,
  useSvbOfxIntegrationQuery,
} from "graphql/types";
import { usePendingConnections } from "../shared/PendingConnectionsProvider";
import { UseSvbOfxResult } from "./types";
import { UseIntegrationHook, useFinancialInstitutions } from "../shared";
import Analytics from "lib/analytics";

const useSvbOfx: UseIntegrationHook<UseSvbOfxResult> = ({
  companyId,
  integrationConnectionId,
}): UseSvbOfxResult => {
  const { data, loading, refetch } = useSvbOfxIntegrationQuery({
    variables: { companyId },
  });
  const { integrations } = data || {};
  const { addPendingConnection, removePendingConnection } = usePendingConnections();
  const { disconnectIntegrationConnection, integrationConnections } = useFinancialInstitutions();

  const connection = useMemo(() => {
    const connectionId = integrationConnectionId ?? integrations?.svbOfx.connection?.id;
    return integrationConnections.find((ic) => ic.id === connectionId);
  }, [integrationConnections, integrationConnectionId, integrations]);

  const disconnectIntegration = useMemo(() => {
    const connectionId = connection?.id;
    if (!connectionId) {
      return undefined;
    }

    return async () => {
      const result = disconnectIntegrationConnection(connectionId);

      if (!integrationConnectionId) {
        refetch();
      }

      return result;
    };
  }, [disconnectIntegrationConnection, integrationConnectionId, connection?.id, refetch]);

  const [connectSvbOfxMutation, { loading: connecting, error: connectionError }] =
    useConnectSvbOfxMutation();

  const onClickConnect = () => {
    return;
  };

  const connectSvbOfx = async (input: {
    companyId: string;
    username: string;
    pin: string;
    condition?: IntegrationConnectionCondition;
  }) => {
    addPendingConnection(IntegrationType.SvbOfx);

    return connectSvbOfxMutation({
      variables: { input },

      update(cache, { data }) {
        if (!data) return;

        const svbOfxIntegration = cache.readQuery<SvbOfxIntegrationQuery>({
          query: SvbOfxIntegrationDocument,
          variables: {
            companyId: companyId,
          },
        });

        cache.writeQuery({
          query: SvbOfxIntegrationDocument,
          variables: {
            companyId,
          },
          data: merge({}, svbOfxIntegration, {
            integrations: {
              svbOfx: {
                connection: {
                  ...data.connectSvbOfx.connection,
                },
              },
            },
          }),
        });
      },

      onCompleted(data) {
        const { connection } = data.connectSvbOfx;
        removePendingConnection(IntegrationType.SvbOfx);

        Analytics.integrationConnected({
          connectionId: connection.id,
          integrationType: "SvbOfx",
          totalAccounts: 1,
        });
      },

      onError({ message }) {
        Analytics.integrationConnectionFailed({
          integrationType: "SvbOfx",
          reason: message,
        });
      },
    });
  };

  const { data: fiData } = useFinancialInstitutionsQuery({
    variables: { companyId, integrationType: IntegrationType.SvbOfx },
  });

  const financialInstitutions = fiData?.financialInstitutionsFor ?? [];

  return {
    connectionError,
    connectSvbOfx,
    connecting,
    loading,
    onClickConnect,
    connection,
    disconnect: disconnectIntegration,
    financialInstitutions,
  };
};

export default useSvbOfx;
