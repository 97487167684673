import React, { useEffect } from "react";
import { styled, Text, Stack } from "@puzzle/ui";
import { Box, color } from "ve";

export type ProgressBarProps = {
  progress: number;
};

const Header = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
});

const PercentBox = styled("div", {
  borderRadius: "4px",
  backgroundColor: "rgba(0, 0, 0, 0.2)",
  color: "$purple400",
  padding: "$0h $1",
});

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = React.useRef<(() => void) | null>(null);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export const useProgress = () => {
  const Duration = 3.5 * 60 * 1000; // 3.5 minutes in milliseconds
  const Interval = 1 * 1000; // 1 second in milliseconds

  const [startTime, setStartTime] = React.useState<number | null>(null);
  const [progress, setProgress] = React.useState(0);

  const updateProgress = () => {
    if (startTime) {
      const newProgress = Math.min((Date.now() - startTime) / Duration, 1.0);

      setProgress(newProgress);
    } else {
      setStartTime(Date.now());
    }
  };

  const stopProgress = () => {
    setProgress(1.0);
  };

  useInterval(updateProgress, progress < 1.0 ? Interval : null);

  return [progress, stopProgress] as const;
};

const subtitleFromProgress = (progress: number) => {
  if (progress >= 0.92) {
    return "Creating categorization rules for future transactions";
  } else if (progress >= 0.73) {
    return "Automagically categorizing your transactions";
  } else if (progress >= 0.62) {
    return "Linking matching transactions";
  } else if (progress >= 0.46) {
    return "Transforming date into journal entries";
  } else {
    return "Ingesting your financial data";
  }
};

export const ProgressBar = ({ progress }: ProgressBarProps) => {
  const progressPercent = progress * 100;
  const progressDisplay = Math.round(progressPercent);

  return (
    <Stack gap="2h">
      <Header>
        <Text size="bodyM" lineHeight="headingL" color="gray200">
          Preparing your financials
        </Text>
        <PercentBox>{progressDisplay}%</PercentBox>
      </Header>

      <Box
        css={{ width: "100%", height: "8px", backgroundColor: color.black, borderRadius: "100px" }}
      >
        <Box
          css={{
            width: `${progressPercent}%`,
            height: "100%",
            backgroundColor: color.purple800,
            borderRadius: "100px",
          }}
        />
      </Box>
      <Text size="bodyXS" lineHeight="headingS" color="gray400">
        {subtitleFromProgress(progress)}
      </Text>
    </Stack>
  );
};
