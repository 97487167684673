import React from "react";
import Icon, { IconProps } from "./Icon";

/**
 * Use ExternalInline instead of External next to inline text
 */
export default function ExternalInline({
  viewBox = "0 0 12 12",
  color = "currentColor",
  fill = color,
  width = 12,
  height = 12,
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} {...iconProps} viewBox={viewBox}>
      <path
        d="M4.16 2.02407L10.904 2.00007V8.74407H9.824L9.848 3.82407L2.816 10.8801L2 10.0641L9.032 3.03207H4.16V2.02407Z"
        fill={fill}
      />
    </Icon>
  );
}
