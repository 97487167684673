import { AlertDialog, Checkbox, Stack, Text, styled } from "@puzzle/ui";
import React, { useCallback, useState } from "react";

const WarningList = styled("ul", {
  paddingLeft: "$2",
  margin: "0 0 $2 0",
});

const ResetAccountConfirmationModal = ({
  onConfirm,
  onOpenChange,
  ...props
}: {
  onConfirm: () => void;
  onOpenChange: (open: boolean) => void;
} & React.ComponentProps<typeof AlertDialog>) => {
  const [intentConfirmed, setIntentConfirmed] = useState(false);

  const handleOpenChange = useCallback(
    (open: boolean) => {
      onOpenChange(open);

      // when modal closes, unset intentConfirmed so it's not still checked when
      // modal is re-opened.
      if (!open) {
        setIntentConfirmed(false);
      }
    },
    [onOpenChange, setIntentConfirmed]
  );

  return (
    <AlertDialog onOpenChange={handleOpenChange} {...props} size="small">
      <AlertDialog.Title basic showClose>
        Reset Account
      </AlertDialog.Title>
      <AlertDialog.Body>
        <WarningList>
          <li>Removes all historical data ingested from the account</li>
          <li>Immediately resyncs data from the source</li>
        </WarningList>
        <Text>
          The primary use case is to reset this account as if it were a new account that was just
          connected.
        </Text>
        <Text css={{ marginTop: "$1", display: "inline-block" }}>
          Note: Having a locked period prevents resetting accounts.
        </Text>
        <Stack direction="horizontal" css={{ padding: "$3 0 0 0" }}>
          <Checkbox
            size={18}
            onClick={() => setIntentConfirmed(!intentConfirmed)}
            checked={intentConfirmed}
          />
          <p>
            I understand that resetting this account will remove all existing data associated to it
            and recreate it from the source and that this action cannot be undone.
          </p>
        </Stack>
      </AlertDialog.Body>
      <AlertDialog.Footer>
        <AlertDialog.CancelButton>Never mind</AlertDialog.CancelButton>
        <AlertDialog.ConfirmButton
          onClick={onConfirm}
          disabled={!intentConfirmed}
          variant="negative"
        >
          Reset
        </AlertDialog.ConfirmButton>
      </AlertDialog.Footer>
    </AlertDialog>
  );
};

export default ResetAccountConfirmationModal;
