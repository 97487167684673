import * as Types from '../types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
export type FileFragment = { __typename?: 'File', id: string, filename?: string | null, contentType: string, size: number, isDeleted: boolean, downloadInfo: { __typename?: 'FileDownloadInfo', signedUrl: string, urlExpiresAt: CalendarDateTimeString } };

export const FileFragmentDoc = gql`
    fragment file on File {
  id
  filename
  contentType
  size
  isDeleted
  downloadInfo {
    signedUrl
    urlExpiresAt
  }
}
    `;