import { useCallback, useEffect, useRef } from "react";
import { differenceInMilliseconds } from "date-fns";
import { useToasts } from "@puzzle/ui";
import { parseAbsolute, parseAbsoluteToLocal, useLocalDateFormatter } from "@puzzle/utils";
import { IntegrationConnectionWithAccountStatsFragment } from "graphql/types";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import useSyncData from "./shared/useSyncData";
import { SyncRunStatus } from "graphql/types";

const ONE_MIN = 60000;
export const JUST_NOW = "just now";
const POLL_INTERVAL = 5000;

interface ManualSyncProps {
  connection: IntegrationConnectionWithAccountStatsFragment | null | undefined;
  integrationName: string;
  startPolling: (pollInterval: number) => void;
  stopPolling: () => void;
  refetch: () => void;
}

/**
 * Hook to manually sync an integration
 */
export const useManualSync = ({
  integrationName,
  connection,
  refetch,
  startPolling,
  stopPolling,
}: ManualSyncProps) => {
  const { timeZone } = useActiveCompany<true>();

  const { toast } = useToasts();
  const isPolling = useRef(false);
  const { syncData, syncing } = useSyncData();
  const triggerSync = async () => {
    if (!connection?.id) return null;
    await syncData({ integrationConnectionId: connection.id });
  };

  const isSyncing = connection?.latestSync?.status === SyncRunStatus.InProgress || syncing;

  const dateFormatter = useLocalDateFormatter({
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

  const getSyncedAt = useCallback(() => {
    if (!connection) return "";

    const syncedAt = parseAbsoluteToLocal(connection.lastSyncedAt ?? connection.createdAt).toDate();
    if (differenceInMilliseconds(new Date(), syncedAt) > ONE_MIN) {
      return `${dateFormatter.format(
        parseAbsolute(connection.lastSyncedAt ?? connection.createdAt, timeZone)
      )}`;
    }

    return JUST_NOW;
  }, [connection, timeZone, dateFormatter]);

  const handleCompletedSync = useCallback(() => {
    if (connection?.latestSync?.status === SyncRunStatus.Succeeded) {
      refetch();
      toast({
        message: `${integrationName} has successfully synchronized.`,
        duration: 10000,
      });
    }

    if (connection?.latestSync?.status === SyncRunStatus.Failed) {
      toast({
        status: "error",
        message: `${integrationName} failed to synchronize.`,
        duration: 10000,
      });
    }
  }, [connection?.latestSync?.status, toast, refetch, integrationName]);

  useEffect(() => {
    if (isSyncing && !isPolling.current) {
      isPolling.current = true;
      startPolling(POLL_INTERVAL);
      return;
    }

    if (!isSyncing && isPolling.current) {
      isPolling.current = false;
      stopPolling();
      handleCompletedSync();
    }
  }, [isSyncing, startPolling, stopPolling, handleCompletedSync]);

  return {
    isSyncing,
    getSyncedAt,
    triggerSync,
  };
};
