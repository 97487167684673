import React from "react";
import Icon, { IconProps } from "./Icon";

export default function External({
  viewBox = "0 0 14 14",
  color = "currentColor",
  fill = color,
  width = 14,
  height = 14,
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} {...iconProps} viewBox={viewBox}>
      <path
        fill={fill}
        d="M3.5 1.75A1.75 1.75 0 001.75 3.5v7c0 .966.784 1.75 1.75 1.75h7a1.75 1.75 0 001.75-1.75V9a.75.75 0 00-1.5 0v1.5a.25.25 0 01-.25.25h-7a.25.25 0 01-.25-.25v-7a.25.25 0 01.25-.25H5a.75.75 0 000-1.5H3.5z"
      ></path>
      <path
        fill={fill}
        d="M8.5 1.75h3.75V5.5a.75.75 0 01-1.5 0V4.31L7.03 8.03a.75.75 0 01-1.06-1.06l3.72-3.72H8.5a.75.75 0 010-1.5z"
      ></path>
    </Icon>
  );
}
