import { useMemo } from "react";
import { merge } from "lodash";

import {
  BillDotComIntegrationDocument,
  BillDotComIntegrationQuery,
  ConnectBillDotComInput,
  useBillDotComIntegrationQuery,
  useConnectBillDotComMutation,
} from "graphql/types";
import Analytics from "lib/analytics";
import { useFinancialInstitutions } from "../shared";

export function useBillDotCom({
  companyId,
  integrationConnectionId,
}: {
  companyId: string;
  integrationConnectionId?: string;
}) {
  const { data, loading, refetch } = useBillDotComIntegrationQuery({ variables: { companyId } });
  const [_connectBillDotComMutation, { loading: connecting, error: connectingError }] =
    useConnectBillDotComMutation();
  const { disconnectIntegrationConnection, integrationConnections } = useFinancialInstitutions();

  const connect = useMemo(() => {
    return ({
      formValues,
      onCompleted,
    }: {
      formValues: Omit<ConnectBillDotComInput, "companyId">;
      onCompleted: () => void;
    }) =>
      _connectBillDotComMutation({
        variables: {
          input: {
            companyId,
            ...formValues,
          },
        },

        update(cache, { data }) {
          if (!data) return;
          const integration = cache.readQuery<BillDotComIntegrationQuery>({
            query: BillDotComIntegrationDocument,
            variables: {
              companyId,
            },
          });

          cache.writeQuery({
            query: BillDotComIntegrationDocument,
            variables: {
              companyId,
            },
            data: merge({}, integration, {
              integrations: {
                billDotCom: {
                  connection: {
                    ...data.connectBillDotCom.connection,
                  },
                },
              },
            }),
          });
        },

        onCompleted(data) {
          const { connection } = data.connectBillDotCom;

          onCompleted();

          Analytics.integrationConnected({
            connectionId: connection.id,
            integrationType: "BillDotCom",
            totalAccounts: 1,
          });
        },

        onError({ message }) {
          Analytics.integrationConnectionFailed({
            integrationType: "BillDotCom",
            reason: message,
          });
        },
      });
  }, [_connectBillDotComMutation, companyId]);

  const connection = useMemo(() => {
    const connectionId = integrationConnectionId ?? data?.integrations.billDotCom?.connection?.id;
    return integrationConnections.find((ic) => ic.id === connectionId);
  }, [integrationConnections, integrationConnectionId, data]);

  const disconnect = useMemo(() => {
    const connectionId = connection?.id;
    if (!connectionId) {
      return undefined;
    }

    return async () => {
      const result = disconnectIntegrationConnection(connectionId);

      if (!integrationConnectionId) {
        refetch();
      }

      return result;
    };
  }, [disconnectIntegrationConnection, integrationConnectionId, connection, refetch]);

  return {
    connection,
    connect,
    disconnect,
    loading,
    connecting,
    connectingError,
  };
}
