import React from "react";
import IntegrationLogo from "../shared/IntegrationLogo";
import { TriNet } from "@puzzle/icons";

const Logo = () => {
  return (
    <IntegrationLogo color="#0072CE">
      <TriNet />
    </IntegrationLogo>
  );
};

export default Logo;
