import { useCallback, useMemo } from "react";
import { NextApiRequest, NextApiResponse } from "next";
import { NextRouter, useRouter } from "next/router";
import url from "url";
import { IncomingMessage, ServerResponse } from "http";
import { omit } from "lodash";
import redirect from "./redirect";
import config from "./config";
import { CompanyRoute, Route } from "./routes";
import Analytics from "./analytics";

// TODO Use pathpida for typesafety, and eventually try to obfuscate Next's router less.
// We're hiding a lot of vital functionality that may hurt us in the long-run.

type GoToPath = (params?: Omit<RouteParams, "path">) => void;
export interface AppRouterResult {
  goBack: GoToPath;
  goToPath: (path: Route | string, params?: Omit<RouteParams, "path">) => void;
  goToPathWithCurrentQuery: (
    path: Route | string,
    omitKeys?: string[],
    overrides?: Record<string, any>
  ) => void;
  goToLandingPage: GoToPath;
  goToTreemap: GoToPath;
  formatPath: (input: RouteParams) => string;
  goToCompanySettings: GoToPath;
  goToNotificationSettings: GoToPath;
  goToConnectPlaid: (publicToken: string, institutionName: string, institutionId: string) => void;
  goToConnectFinicity: (customerId: string, institution: string) => void;
  goHome: GoToPath;
  goToConnectionError: GoToPath;
  goToIntegrations: GoToPath;
  logout: GoToPath;
  goToWhitelistError: GoToPath;
  goToIntro: GoToPath;
  goToLogin: GoToPath;
  goToUsers: GoToPath;
  goToAuditLog: GoToPath;
  goToBilling: GoToPath;
  goToEmailVerification: GoToPath;
  goToEmailVerificationSuccess: GoToPath;
  goToManualJournals: GoToPath;
  goToManualJournal: (id: string) => void;
  goToTransaction: (id: string, shallow?: boolean) => void;
  goToAuthorizePartner: GoToPath;
  goToConnectSuccess: GoToPath;
  goToConnectPartnerDataSource: GoToPath;
  goToTransactions: (shallow?: boolean) => void;
  goToVendors: (shallow?: boolean) => void;
  goToReports: GoToPath;
  goToRules: GoToPath;
  goToRule: (id: string) => void;
  goToNoAccess: GoToPath;
  goToReconciliations: GoToPath;
  goToReconciliation: (id: string) => void;
  goToReconciliationEdit: (id: string) => void;
  goToCustomers: (shallow?: boolean) => void;
  goToProducts: (shallow?: boolean) => void;
  formatCompanyPath: (pathname: string, route: CompanyRoute) => void;
  isUnknownRoute: (pathname: string) => boolean;
  isIntegrationRoute: (pathname: string) => boolean;
  isTransactionRoute: (pathname: string) => boolean;
  isInboxRoute: (pathname: string) => boolean;
  isRulesRoute: (pathname: string) => boolean;
  isAccountingRoute: (pathname: string) => boolean;
  isVendorRoute: (pathname: string) => boolean;
  isCustomersRoute: (pathname: string) => boolean;
  isProductRoute: (pathname: string) => boolean;
  isTempAuthRoute: (pathname: string) => boolean;
  getCurrentRoute: () => Route;
  replaceQueryParam: (key: string, value: string) => void;
  router: NextRouter;
}

export interface RouteParams {
  path: Route | string;
  query?: any;
  shallow?: boolean;
  scroll?: boolean;
}

// sometimes asPath doesn't fully expand, so check for the page name too
export const isTempAuthRoute = (path: string) =>
  path === "/company/[...companyPath]" || !!path.match(`^[/]company[/][^/]+[/]auth$`);

// TODO avoid custom handling for catch-all routes
export const isIntegrationRoute = (path: string) =>
  path === "/integrations/[[...integrationPath]]" ||
  (path.startsWith("/integrations") && path !== "/integrations/guest");

export const isTransactionRoute = (path: string) =>
  path === "/transactions/[[...path]]" || path.startsWith("/transactions");

export const isInboxRoute = (path: string) =>
  path === "/inbox/[[...slug]]" || path.startsWith("/inbox");

export const isRulesRoute = (path: string) =>
  path === "/rules/[[...path]]" || path.startsWith("/rules");

export const isAccountingRoute = (path: string) =>
  path === "/accounting/[[...path]]" || path.startsWith("/accounting");

export const isVendorRoute = (path: string) =>
  path === "/vendors/[[...path]]" || path.startsWith("/vendors");

export const isCustomersRoute = (path: string) =>
  path === "/manage-customers/[[...path]]" || path.startsWith("/manage-customers");

export const isProductRoute = (path: string) =>
  path === "/products/[[...path]]" || path.startsWith("/products");

export const isPayrollRoute = (path: string) =>
  path === "/payroll/[[...path]]" || path.startsWith("/payroll");

export const isUnknownRoute = (path: string) =>
  !isTempAuthRoute(path) &&
  !isIntegrationRoute(path) &&
  !isTransactionRoute(path) &&
  !isInboxRoute(path) &&
  !isRulesRoute(path) &&
  !isAccountingRoute(path) &&
  !isVendorRoute(path) &&
  !isCustomersRoute(path) &&
  !isProductRoute(path) &&
  !isPayrollRoute(path) &&
  !Object.values(Route).includes(path as Route);

export const isDeprecatedPartnerConnectionPage = (path: string) =>
  (path.startsWith("/connect") && !path.startsWith("/connect/success")) || path.startsWith("/ltse");

export default function useAppRouter(
  req?: NextApiRequest | IncomingMessage,
  res?: NextApiResponse | ServerResponse
): AppRouterResult {
  const router = useRouter();

  const formatPath = useCallback(({ path, query }: RouteParams) => {
    return url.format({ href: path, query });
  }, []);

  const goTo = useCallback(
    ({ path, query, shallow = false, scroll }: RouteParams) => {
      redirect(
        {
          path,
          query,
          shallow,
          scroll: scroll ?? !shallow ?? true,
        },
        req,
        res
      );
    },
    [req, res]
  );

  const goToPath = useCallback(
    (path: string | Route, params: Omit<RouteParams, "path"> = {}) => goTo({ path, ...params }),
    [goTo]
  );

  const createGoToPath = useCallback(
    (path: Route) =>
      (params: Omit<RouteParams, "path"> = {}) =>
        goToPath(path, params),
    [goToPath]
  );

  const logout = useCallback(() => {
    goToPath(Route.logout);
  }, [goToPath]);

  const goBack = useCallback(() => window.history.back(), []);

  const goToAngelListConnect = useCallback(
    (token?: string, redirectUri?: string) =>
      goTo({
        path: Route.angelListConnect,
        query: { token, redirect_uri: redirectUri },
      }),
    [goTo]
  );

  const goToConnectFinicity = useCallback(
    (customerId: string, institution: string) =>
      goTo({
        path: Route.connectFinicity,
        query: { customerId, institution },
      }),
    [goTo]
  );

  const goToConnectPlaid = useCallback(
    (publicToken: string, institutionName: string, institutionId: string) =>
      goTo({
        path: Route.connectPlaid,
        query: { token: publicToken, name: institutionName, institutionId },
      }),
    [goTo]
  );

  const goToTransaction = useCallback(
    (id: string, shallow = true) => {
      router.push(
        {
          pathname: "/transactions/[[...slug]]",
          query: {
            // If we're coming from the transactions page or another transaction,
            // copy over the filter so we can preserve it later.
            ...(router.asPath.startsWith(Route.transactions) &&
            // obfuscating the router was a mistake
            !router.asPath.startsWith(Route.rules) &&
            !router.asPath.startsWith(Route.payroll)
              ? router.query
              : {}),
            slug: [id],
          },
        },
        undefined,
        { shallow, scroll: false }
      );
      Analytics.transactionOpened({
        transactionId: id,
      });
    },
    [router]
  );

  const goToRule = useCallback(
    (id: string) => {
      router.push(
        {
          pathname: "/transactions/rules/[[...slug]]",
          query: {
            slug: [id],
          },
        },
        undefined,
        { shallow: true, scroll: false }
      );
    },
    [router]
  );

  const goToManualJournal = useCallback(
    (id: string) => {
      goTo({
        path: `/accounting/journals/${id}`,
      });
    },
    [goTo]
  );

  const goToRules = useCallback(() => {
    goTo({
      path: Route.rules,
      shallow: true,
    });
  }, [goTo]);

  const goToPathWithCurrentQuery = useCallback(
    (path: Route | string, omitKeys: string[] = [], overrides?: Record<string, string>) => {
      goTo({
        path,
        query: {
          ...omit(router.query, omitKeys),
          ...overrides,
        },
      });
    },
    [goTo, router]
  );

  const goToTransactions = useCallback(
    (shallow?: boolean) => {
      const { slug, ...queryWithoutId } = router.query;
      goTo({
        path: Route.transactions,
        // If we're coming from a single transaction, copy over the filter.
        // This also lets you link to a specific transaction with a predetermined filter in the background.
        query: router.asPath.startsWith(Route.transactions) && slug ? queryWithoutId : {},
        shallow,
      });
    },
    [goTo, router]
  );

  const goToVendors = useCallback(
    (shallow?: boolean) => {
      const { slug, ...queryWithoutId } = router.query;
      goTo({
        path: Route.vendors,
        // If we're coming from a single vendor, copy over the filter.
        // This also lets you link to a specific vendor with a predetermined filter in the background.
        query: router.asPath.startsWith(Route.vendors) && slug ? queryWithoutId : {},
        shallow,
      });
    },
    [goTo, router]
  );

  const goToCustomers = useCallback(
    (shallow?: boolean) => {
      const { slug, ...queryWithoutId } = router.query;
      goTo({
        path: Route.customers,
        // If we're coming from a single customer, copy over the filter.
        // This also lets you link to a specific customer with a predetermined filter in the background.
        query: router.asPath.startsWith(Route.customers) && slug ? queryWithoutId : {},
        shallow,
      });
    },
    [goTo, router]
  );

  const goToProducts = useCallback(
    (shallow?: boolean) => {
      const { slug, ...queryWithoutId } = router.query;
      goTo({
        path: Route.products,
        // If we're coming from a single customer, copy over the filter.
        // This also lets you link to a specific customer with a predetermined filter in the background.
        query: router.asPath.startsWith(Route.products) && slug ? queryWithoutId : {},
        shallow,
      });
    },
    [goTo, router]
  );

  const formatCompanyPath = useCallback((uniquePathname: string, route: CompanyRoute) => {
    return config.ROOT_URL + Route.companyPrivateRoute + "/" + uniquePathname + "/" + route;
    // return [config.ROOT_URL, Route.companyPrivateRoute, uniquePathname, route].concat('/');
  }, []);

  const getCurrentRoute = useCallback(() => {
    // TODO better way to deal with special characters, better way to identify the route we care about
    // This is too hardcoded to the current app
    const path = router.asPath.split("?")[0] as Route; //only take the path before the query params
    return path.split("#")[0] as Route; // if there is a fragment, take only what is before the fragment
  }, [router]);

  const replaceQueryParam = useCallback(
    (key: string, value: string) => {
      const { pathname, query } = router;
      const newQuery = { ...query, [key]: value };
      router.replace({ pathname, query: newQuery }, undefined, { shallow: true });
    },
    [router]
  );

  return useMemo(
    () => ({
      goBack,
      formatPath,
      goToPath,
      goToLandingPage: createGoToPath(Route.landing),
      goToEmailVerification: createGoToPath(Route.emailVerification),
      goToEmailVerificationSuccess: createGoToPath(Route.emailVerificationSuccess),
      goToPathWithCurrentQuery,
      goToCompanySettings: createGoToPath(Route.companySettings),
      goToNotificationSettings: createGoToPath(Route.notificationSettings),
      goToConnectPlaid,
      goToConnectFinicity,
      logout,
      goToTreemap: createGoToPath(Route.burn),
      goToConnectionError: createGoToPath(Route.connectionError),
      goHome: createGoToPath(Route.home),
      goToUsers: createGoToPath(Route.usersSettings),
      goToAuditLog: createGoToPath(Route.auditLog),
      goToBilling: createGoToPath(Route.billing),
      goToIntro: createGoToPath(Route.intro),
      goToIntegrations: createGoToPath(Route.integrations),
      goToWhitelistError: createGoToPath(Route.error),
      goToAngelListConnect,
      goToLogin: createGoToPath(Route.login),
      goToManualJournals: createGoToPath(Route.manualJournals),
      goToManualJournal,
      goToConnectSuccess: createGoToPath(Route.connectSuccess),
      goToConnectPartnerDataSource: createGoToPath(Route.connectPartnerDataSource),
      goToAuthorizePartner: createGoToPath(Route.authorizePartner),
      goToReconciliations: createGoToPath(Route.ledgerReconciliations),
      goToReconciliation: (id: string) =>
        goToPath(Route.ledgerReconciliationViewer.replace(":reconciliationId", id)),
      goToReconciliationEdit: (id: string) =>
        goToPath(Route.ledgerReconciliationViewer.replace(":reconciliationId", id)),
      goToTransaction,
      goToTransactions,
      goToVendors,
      goToReports: createGoToPath(Route.reports),
      goToRules,
      goToRule,
      goToNoAccess: createGoToPath(Route.noAccess),
      formatCompanyPath,
      isUnknownRoute,
      isTempAuthRoute,
      isIntegrationRoute,
      isTransactionRoute,
      isInboxRoute,
      isAccountingRoute,
      isVendorRoute,
      isCustomersRoute,
      isRulesRoute,
      getCurrentRoute,
      replaceQueryParam,
      goToCustomers,
      goToProducts,
      isProductRoute,
      router,
    }),
    [
      createGoToPath,
      router,
      goToPath,
      goToManualJournal,
      goToAngelListConnect,
      goToTransaction,
      goToTransactions,
      goToVendors,
      goToRules,
      goToRule,
      formatCompanyPath,
      getCurrentRoute,
      logout,
      goBack,
      goToPathWithCurrentQuery,
      formatPath,
      goToConnectPlaid,
      goToConnectFinicity,
      replaceQueryParam,
      goToCustomers,
      goToProducts,
    ]
  );
}
