import React from "react";
import Icon, { IconProps } from "./Icon";
import { getNumericRotate } from "./utils";

export function LongArrow({
  viewBox = "0 0 16 16",
  width = 16,
  height = 16,
  fill = "#95949B",
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} {...iconProps} viewBox={viewBox}>
      <path
        d="M10.7188 4.125C10.7812 4.0625 10.875 4 10.9688 4C11.0625 4 11.1562 4.0625 11.25 4.125L14.875 7.75C14.9375 7.8125 15 7.90625 15 8C15 8.125 14.9375 8.1875 14.875 8.25L11.25 11.875C11.1562 11.9688 11.0625 12 10.9688 12C10.875 12 10.7812 11.9688 10.7188 11.875L10.5 11.6562C10.4062 11.5938 10.375 11.5312 10.375 11.4062C10.375 11.3125 10.4062 11.2188 10.5 11.125L13.125 8.53125H1.375C1.25 8.53125 1.15625 8.5 1.09375 8.4375C1.03125 8.375 1 8.28125 1 8.15625V7.84375C1 7.75 1.03125 7.65625 1.09375 7.59375C1.15625 7.53125 1.25 7.46875 1.375 7.46875H13.125L10.5 4.875C10.4062 4.8125 10.375 4.71875 10.375 4.59375C10.375 4.5 10.4062 4.40625 10.5 4.34375L10.7188 4.125Z"
        fill={fill}
      />
    </Icon>
  );
}

export function LongArrowLeft(props: IconProps) {
  const totalRotate = 180 + getNumericRotate(props.rotate);
  return <LongArrow {...props} rotate={totalRotate} />;
}
