import React from "react";
import Icon, { IconProps } from "./Icon";

export default function AIChecklist({
  className,
  rotate,
  size = 44,
  viewBox = "0 0 44 44",
  ...props
}: IconProps) {
  return (
    <Icon className={className} rotate={rotate} viewBox={viewBox} size={size} {...props}>
      <path stroke="#fff" strokeLinecap="round" strokeWidth="2" d="M24 17h11" opacity="0.4"></path>
      <path
        fill="#DD4EB9"
        fillRule="evenodd"
        d="M18.95 12.4a.75.75 0 01.15 1.05l-4.5 6a.75.75 0 01-1.176.03l-2.5-3a.75.75 0 111.152-.96l1.893 2.271L17.9 12.55a.75.75 0 011.05-.15z"
        clipRule="evenodd"
      ></path>
      <path stroke="#fff" strokeLinecap="round" strokeWidth="2" d="M24 29h11" opacity="0.4"></path>
      <path
        fill="#DD4EB9"
        fillRule="evenodd"
        d="M18.95 24.4a.75.75 0 01.15 1.05l-4.5 6a.75.75 0 01-1.176.03l-2.5-3a.75.75 0 111.152-.96l1.893 2.271L17.9 24.55a.75.75 0 011.05-.15z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
}
