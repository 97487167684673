import React from "react";
import useGusto from "../gusto/useGusto";
import Logo from "../gusto/Logo";
import IntegrationDetails from "./IntegrationDetails";
import { DetailProps } from "./types";

const GustoDetails = ({ companyId, integrationConnectionId, ...props }: DetailProps) => {
  const gusto = useGusto({ companyId, integrationConnectionId });

  return (
    <IntegrationDetails
      logo={<Logo />}
      title="Gusto"
      connection={gusto.connection}
      onDisconnect={gusto.disconnect}
      onReconnect={gusto.onClickConnect}
      {...props}
    />
  );
};

export default GustoDetails;
