import React, { useCallback, useMemo, useState } from "react";
import { AccountDetailsProps } from "./types";
import { TextGroup } from "./shared";
import { Button, Help, Text, Tooltip, useToasts } from "@puzzle/ui";
import { useFinancialInstitutions } from "../shared";
import { getIntegrationTypeForAccount } from "../ListItem/ListItemUtils";
import RemoveAccountEpochConfirmationModal from "./RemoveAccountEpochConfirmationModal";
import Link from "../../common/Link";
import { useSetAccountEpochMutation } from "graphql/types";
import { getDisplayNameForIntegrationType, isPushIntegationType } from "../setup/utils";
import { useActiveCompany, useCompanyDateFormatter } from "../../companies";
import { CalendarDateString, parseDate } from "@puzzle/utils";
import { SelectStartDateModal } from "../setup/modals/SelectStartDateModal";

const AccountEpochsSection = ({ account }: AccountDetailsProps) => {
  const { refetchAccountsAndIntegrations } = useFinancialInstitutions();
  const { company } = useActiveCompany<true>();
  const { toast } = useToasts();
  const [isRemoving, setIsRemoving] = useState(false);
  const [showSetEpochModal, setShowSetEpochModal] = useState(false);

  const [setAccountEpochMutation, { loading }] = useSetAccountEpochMutation();

  const integrationType = getIntegrationTypeForAccount(account);
  const integrationDisplayName = getDisplayNameForIntegrationType(integrationType);

  const accountEpochs = useMemo(
    () => account.epochs.filter((epoch) => epoch.cutoffBefore || epoch.cutoffAtOrAfter),
    [account.epochs]
  );

  const canRemoveEpoch = useMemo(() => {
    if (company.startIngestionDate) {
      //Admin has set a start ingestion date, only they can remove epochs
      return false;
    }
    // Only allow removing the epoch if there's only one epoch and it wasn't the result of a merge
    if (account.epochs.length === 1 && !account.epochs[0].shouldCorrectHistory) {
      return true;
    }

    return false;
  }, [account.epochs, company.startIngestionDate]);

  const clearEpoch = useCallback(async () => {
    const accountId = account.id;

    setIsRemoving(true);
    const result = await setAccountEpochMutation({
      variables: { input: { accountId, cutoffBefore: null, shouldUpdateHistory: true } },
    });

    if (result?.errors) {
      toast({ message: "Failed to remove restricted range.", status: "error" });
    } else {
      toast({ message: "Successfully removed restricted range.", status: "success" });
    }

    setIsRemoving(false);
    refetchAccountsAndIntegrations();
  }, [refetchAccountsAndIntegrations, account, toast, setAccountEpochMutation]);

  const dateFormatter = useCompanyDateFormatter({ dateStyle: "long" });

  const labels = accountEpochs.map((epoch) => {
    if (epoch.cutoffBefore && !epoch.cutoffAtOrAfter) {
      return `Prior to ${dateFormatter.format(
        parseDate(epoch.cutoffBefore as CalendarDateString)
      )}`;
    }

    if (!epoch.cutoffBefore && epoch.cutoffAtOrAfter) {
      return `On or after ${dateFormatter.format(
        parseDate(epoch.cutoffAtOrAfter as CalendarDateString)
      )}`;
    }

    if (epoch.cutoffBefore && epoch.cutoffAtOrAfter) {
      return `Between ${dateFormatter.format(
        parseDate(epoch.cutoffBefore as CalendarDateString)
      )} and ${dateFormatter.format(parseDate(epoch.cutoffAtOrAfter as CalendarDateString))}`;
    }
  });

  const setEpochSuccessToastMessageOverride = isPushIntegationType(integrationType)
    ? "Restricted ranges have been set!"
    : undefined;

  if (account.manuallyAdded) {
    return null;
  }

  const existingEpochTooltipContent = `Based on previous selections for this account, Puzzle will not ingest any data for this account from ${integrationDisplayName} within a restricted range.

The data for this range can be input manually or come from a different integration. Often, this can be a previous integration.
  
Restricted ranges are intended to prevent duplication.`;

  const noEpochsTooltipContent = `You can set a restricted range to prevent Puzzle from ingesting any data for this account from ${integrationDisplayName} within a restricted range.
  
If no restricted range is set, Puzzle will ingest all available historical data for this account from ${integrationDisplayName}.

Restricted ranges are intended to prevent duplication.`;

  const existingEpochsView = (
    <TextGroup>
      <Text variant="headingS">
        {`Restricted ranges for ${integrationDisplayName}`}{" "}
        <Tooltip content={existingEpochTooltipContent} align="center" side="bottom" sideOffset={8}>
          <span>
            <Help />
          </span>
        </Tooltip>
      </Text>

      {labels.map((label, index) => (
        <Text key={`epoch_${index}`} variant="bodyS" color="gray400">
          {label}
        </Text>
      ))}
      {canRemoveEpoch && (
        <Link onClick={() => setIsRemoving(true)} color="negative" underline>
          Remove
        </Link>
      )}
    </TextGroup>
  );

  const noEpochsView = (
    <>
      <TextGroup>
        <Text variant="bodyS" color="gray400" css={{ textAlign: "left" }}>
          {`Currently syncing all historical data for this account`}{" "}
          <Tooltip content={noEpochsTooltipContent} align="center" side="bottom" sideOffset={8}>
            <span>
              <Help />
            </span>
          </Tooltip>
        </Text>
      </TextGroup>

      <Button
        onClick={() => setShowSetEpochModal(true)}
        size="compact"
        variant="secondary"
        color="primary"
      >
        Set restricted range{" "}
      </Button>
    </>
  );

  return (
    <>
      {accountEpochs.length ? existingEpochsView : noEpochsView}

      <RemoveAccountEpochConfirmationModal
        integrationType={integrationDisplayName}
        epochLabel={labels[0] ?? ""}
        onConfirm={clearEpoch}
        open={isRemoving}
        onOpenChange={setIsRemoving}
        loading={loading}
      />

      <SelectStartDateModal
        open={showSetEpochModal}
        companyId={company.id}
        accounts={[account]}
        connectionId={account.connection?.id}
        onOpenChange={setShowSetEpochModal}
        isReconnect={true}
        accountsLoading={!account}
        successToastMessageOverride={setEpochSuccessToastMessageOverride}
      />
    </>
  );
};

export default AccountEpochsSection;
