import React, { useState } from "react";
import useGusto from "./useGusto";
import { IntegrationsListItemBaseProps } from "../List";
import { IntegrationType } from "graphql/types";
import Logo from "./Logo";
import ConnectGustoModal from "./ConnectGustoModal";

interface GustoListItemProps {
  DisplayComponent: (props: IntegrationsListItemBaseProps) => React.ReactNode;
  companyId: string;
}

export const TITLE = "Gusto";
export const SUBTITLE = "Link employment costs for your financial statements and taxes.";

const GustoListItem = ({ DisplayComponent, companyId }: GustoListItemProps) => {
  const { onClickConnect, connection, loading, onClickProvision, initiatedAccountProvision } =
    useGusto({ companyId });
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <DisplayComponent
        integrationType={IntegrationType.Gusto}
        connectIntegration={() => setOpenModal(true)}
        title={TITLE}
        logo={<Logo />}
        connection={connection}
        loading={loading}
        defaultButtonText="View Options"
      />
      <ConnectGustoModal
        DisplayComponent={DisplayComponent}
        onConfirmConnectExisting={onClickConnect}
        onConfirmProvisionNew={onClickProvision}
        title={TITLE}
        open={openModal}
        onOpenChange={() => setOpenModal(false)}
        companyId={companyId}
        initiatedAccountProvision={initiatedAccountProvision}
      />
    </>
  );
};

export default GustoListItem;
