import React from "react";
// @ts-expect-error The types are outdated but I can't use it otherwise because it breaks the whole project
import ReactCSSTransitionReplace from "react-css-transition-replace";
import { css } from "@puzzle/ui";

const DURATION = 400;
const EASING = "cubic-bezier(0.4, 0, 0.2, 1)";
const getSlideCss = (reverse = false) => {
  const start = reverse ? "-100%" : "100%";
  const end = reverse ? "100%" : "-100%";

  return css({
    "&-height": {
      overflow: "hidden",
      transition: `height ${DURATION}ms ${EASING}`,
    },

    "&-enter": {
      transform: `translate3d(${start}, 0, 0)`,

      "&&-active": {
        transform: "translate3d(0, 0, 0)",
        transition: `${DURATION}ms ${EASING}`,
      },
    },

    "&-leave": {
      transform: "translate3d(0, 0, 0)",

      "&&-active": {
        transform: `translate3d(${end}, 0, 0)`,
        transition: `${DURATION}ms ${EASING}`,
      },
    },
  });
};

const slideForward = getSlideCss();
const slideBackward = getSlideCss(true);

const Slider = ({
  children,
  direction = "forward",
}: {
  children: React.ReactChild;
  // TODO this could handle the indexing?
  direction?: "forward" | "backward";
}) => {
  return (
    <ReactCSSTransitionReplace
      // TODO Double-clicking will lead to overlaps. Need to disable pagination during animation.
      // I think react-spring handled that well too?

      transitionName={(direction === "forward" ? slideForward : slideBackward).toString()}
      transitionEnterTimeout={DURATION}
      transitionLeaveTimeout={DURATION}
    >
      {children}
    </ReactCSSTransitionReplace>
  );
};

export default Slider;
