import * as Types from '../../../graphql/types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
import { CompanyFragmentDoc } from '../../../graphql/fragments/company.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveClassMutationVariables = Types.Exact<{
  input: Types.RemoveClassInput;
}>;


export type RemoveClassMutation = { __typename?: 'Mutation', removeClass: { __typename?: 'Company', id: string, name: string, type: Types.CompanyType, createdAt: CalendarDateTimeString, onboardingStage: Types.OnboardingStage, uniquePathname: string, timeZone?: string | null, trackingId?: string | null, revenueModel?: Types.RevenueModel | null } };


export const RemoveClassDocument = gql`
    mutation removeClass($input: RemoveClassInput!) {
  removeClass(input: $input) {
    ...company
  }
}
    ${CompanyFragmentDoc}`;
export type RemoveClassMutationFn = Apollo.MutationFunction<RemoveClassMutation, RemoveClassMutationVariables>;

/**
 * __useRemoveClassMutation__
 *
 * To run a mutation, you first call `useRemoveClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClassMutation, { data, loading, error }] = useRemoveClassMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveClassMutation(baseOptions?: Apollo.MutationHookOptions<RemoveClassMutation, RemoveClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveClassMutation, RemoveClassMutationVariables>(RemoveClassDocument, options);
      }
export type RemoveClassMutationHookResult = ReturnType<typeof useRemoveClassMutation>;
export type RemoveClassMutationResult = Apollo.MutationResult<RemoveClassMutation>;
export type RemoveClassMutationOptions = Apollo.BaseMutationOptions<RemoveClassMutation, RemoveClassMutationVariables>;