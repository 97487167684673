import React, { useContext } from "react";

// This is only used to know if a menu is being rendered in a dialog/popover.
// Menus can be rendered in portals, so CSS selectors aren't enough.
// We can't unportal everything at the moment.
const InDialogContext = React.createContext<boolean>(false);
export const InDialogProvider = ({
  value = true,
  ...props
}: React.PropsWithChildren<{ value?: boolean }>) => (
  <InDialogContext.Provider {...props} value={value} />
);
// Intentionally allows optionals
export const useInDialogContext = () => useContext(InDialogContext);
