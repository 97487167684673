import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Star({
  size = 16,
  color = "currentColor",
  viewBox = "0 0 16 16",
  ...props
}: IconProps) {
  return (
    <Icon viewBox={viewBox} size={size} {...props}>
      <path
        fill={color}
        d="M7.547 5.976a.5.5 0 01.906 0l.988 2.127a.5.5 0 00.393.286l2.328.282a.5.5 0 01.28.862l-1.717 1.597a.5.5 0 00-.15.462l.45 2.301a.5.5 0 01-.733.533l-2.049-1.14a.5.5 0 00-.486 0l-2.049 1.14a.5.5 0 01-.734-.533l.451-2.3a.5.5 0 00-.15-.463L3.558 9.533a.5.5 0 01.28-.862l2.328-.282a.5.5 0 00.393-.286l.988-2.127z"
      ></path>
      <path stroke={color} strokeLinecap="round" d="M8 4L8 1.5"></path>
      <path stroke={color} strokeLinecap="round" d="M10.6 3.993L12.368 2.225"></path>
      <path stroke={color} strokeLinecap="round" d="M5.293 4L3.525 2.232"></path>
    </Icon>
  );
}
