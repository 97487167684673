import React, { useMemo } from "react";
import IntegrationDetails from "./IntegrationDetails";
import { DetailProps } from "./types";
import { useToasts } from "@puzzle/ui";
import InstitutionLogo from "../bank/InstitutionLogo";
import { useFinancialInstitutions } from "../shared";
import usePushIntegrations from "../pushIntegrations/usePushIntegrations";

const PushIntegrationDetails = ({ companyId, integrationConnectionId, ...props }: DetailProps) => {
  const { integrationConnections, disconnectIntegrationConnection } = useFinancialInstitutions();
  const { connectionMap } = usePushIntegrations({ companyId });
  const { toast } = useToasts();

  const connection = integrationConnections.find(
    (connection) => connection.id === integrationConnectionId
  );

  const disconnect = useMemo(() => {
    if (!integrationConnectionId) {
      return undefined;
    }

    return async () => {
      const result = disconnectIntegrationConnection(integrationConnectionId);

      return result;
    };
  }, [disconnectIntegrationConnection, integrationConnectionId]);

  const reconnect = useMemo(() => {
    const integrationType = connection?.type;

    if (integrationType) {
      const connectionInfo = connectionMap.get(integrationType);

      if (connectionInfo?.connectUrl) {
        return () => {
          window.open(connectionInfo.connectUrl, "_blank");
        };
      }
    }

    return () => {
      toast({
        message: `Please reconnect your ${integrationType} integration in the ${integrationType} app.`,
        status: "warning",
      });
    };
  }, [connection, connectionMap, toast]);

  if (!connection) {
    return null;
  }

  return (
    <IntegrationDetails
      logo={<InstitutionLogo institution={{ name: connection.type }} />}
      title={connection.type}
      connection={connection}
      onDisconnect={disconnect}
      onReconnect={reconnect}
      requiresExternalSetup={true}
      {...props}
    />
  );
};

export default PushIntegrationDetails;
