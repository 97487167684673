import React, { useState } from "react";
import { Button, ButtonGroup, Dialog, styled, Field, Input, useToasts } from "@puzzle/ui";
import Link from "components/common/Link";
import { External } from "@puzzle/icons";
import { isEmpty, some } from "lodash";
import { IntegrationConnectionCondition, IntegrationType } from "graphql/types";
import useSvbOfx from "components/integrations/svb/useSvbOfxIntegration";
import { usePollIntegration } from "../../shared/usePollIntegration";

type ConnectSvbOfxAccountModalProps = React.ComponentPropsWithoutRef<typeof Dialog> & {
  companyId: string;
  onConnect: () => void;
  onClose: () => void;
  isOnboarding: boolean;
};

const Form = styled("div", {
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  columnGap: "$2",
  rowGap: "$2",
  marginBottom: "$2h",
});

const FormCell = styled("div", {
  minHeight: 40,
});

export interface SvbOfxAccountFormValues {
  username?: string;
  pin?: string;
}

export const ConnectSvbOfxAccountModal = ({
  companyId,
  onConnect,
  onClose,
  isOnboarding,
  ...rest
}: ConnectSvbOfxAccountModalProps) => {
  const { toast } = useToasts();

  const { connectSvbOfx, loading } = useSvbOfx({ companyId });
  const { financialInstitutions, startPolling } = usePollIntegration(IntegrationType.SvbOfx, false);

  const [values, setValues] = useState<SvbOfxAccountFormValues>({
    username: "",
    pin: "",
  });

  const createSvbOfxAccount = async () => {
    if (!values.username) {
      toast({ message: `Username is required`, status: "error" });
      return;
    } else if (!values.pin) {
      toast({ message: `PIN is required`, status: "error" });
      return;
    }

    try {
      const result = await connectSvbOfx({
        companyId,
        username: values.username,
        pin: values.pin,
        condition: isOnboarding ? undefined : IntegrationConnectionCondition.WaitingForUserEpoch,
      });
      if (!result || !result?.data || result.errors) {
        toast({ message: `Error connecting to SVB via OFX`, status: "error" });
        return;
      }

      if (financialInstitutions.length === 0) {
        startPolling(1000);
      }

      onConnect();
    } catch (error) {
      let title = `Please check your username and your 'My SVB Direct Connect' PIN`;
      let message = error instanceof Error ? error.message : "Error connecting to SVB";

      if (message.includes("IDX_ENCRYPTED_VALUES")) {
        title =
          "This account is already configured, and is in the process of loading. Please choose another another account to add";
        message = "";
      }
      toast({
        title,
        message,
        status: "error",
      });
    }
  };

  return (
    <Dialog
      {...rest}
      width={500}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
        rest.onOpenChange?.(open);
      }}
    >
      <Dialog.Title showClose>Connect to Silicon Valley Bank</Dialog.Title>
      <Dialog.Body>
        <p>
          This will connect to Silicon Valley Bank directly, as opposed to connecting to them via
          Plaid.
        </p>

        <p>Would you like to connect directly now?</p>
        <Form>
          <FormCell>
            <Field label="SVB Username">
              <Input
                size="small"
                placeholder="Your svb.com username"
                value={values.username}
                onChange={(e) => {
                  setValues((values) => ({
                    ...values,
                    username: e.target.value,
                  }));
                }}
              />
            </Field>
          </FormCell>
          <FormCell>
            <Field
              label="SVB Direct Connect PIN"
              hint="SVB Direct Connect PIN, different from your password (see link below for more details)"
            >
              <Input
                size="small"
                placeholder="Your SVB Direct PIN"
                value={values.pin}
                type="password"
                onChange={(e) => {
                  setValues((values) => ({
                    ...values,
                    pin: e.target.value,
                  }));
                }}
              />
            </Field>
          </FormCell>
        </Form>
        <Link
          href="https://www.svb.com/learning-central/direct-connect"
          target="_blank"
          underline
          external
          css={{ display: "flex", alignItems: "center", fontSize: "$bodyS" }}
        >
          How to set up your My SVB Direct Connect PIN <External style={{ marginLeft: 4 }} />
        </Link>
      </Dialog.Body>
      <Dialog.Footer>
        <ButtonGroup>
          <Dialog.Close asChild>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          </Dialog.Close>
          <Button
            variant="primary"
            onClick={createSvbOfxAccount}
            loading={loading}
            disabled={some(values, isEmpty)}
          >
            Save
          </Button>
        </ButtonGroup>
      </Dialog.Footer>
    </Dialog>
  );
};
