import { useCallback } from "react";
import { useToasts } from "@puzzle/ui";
import { useActiveCompany } from "components/companies";
import {
  AvailableReportingClassFragment,
  GetCompanyAvailableClassesDocument,
  useCreateClassSegmentMutation,
  useCreateReportingClassMutation,
} from "components/common/hooks/graphql.generated";
import Analytics from "lib/analytics";

const useCreateClassification = ({
  handleClose,
  reportingClass,
}: {
  handleClose: () => void;
  reportingClass: AvailableReportingClassFragment | null;
}) => {
  const { company } = useActiveCompany<true>();
  const { toast } = useToasts();
  const [
    createReportingClass,
    { loading: createReportingClassLoading, reset: resetReportingClassMutation },
  ] = useCreateReportingClassMutation({ refetchQueries: [GetCompanyAvailableClassesDocument] });
  const [
    createClassSegment,
    { loading: createClassSegmentLoading, reset: resetClassSegmentMutation },
  ] = useCreateClassSegmentMutation({ refetchQueries: [GetCompanyAvailableClassesDocument] });

  const showSuccessToast = useCallback(
    (name: string, objectName: string = "class") => {
      toast({
        title: `New ${objectName} added`,
        message: `${name} was successfully added.`,
        status: "success",
      });
    },
    [toast]
  );

  const showErrorToast = useCallback(
    (errorMessage: string, objectName: string = "class") => {
      toast({
        title: `New ${objectName} failed`,
        message: errorMessage.includes("already exists")
          ? "Class names must be unique"
          : "Something went wrong, and our team has been notified. We apologize for the inconvenience. Please try again in a few minutes.",
        status: "warning",
      });
    },
    [toast]
  );

  const onSubmit = useCallback(
    async ({ name, description }: { name: string; description: string }) => {
      if (reportingClass) {
        await createClassSegment({
          variables: {
            input: {
              companyId: company.id,
              reportingClassId: reportingClass.id,
              name,
              description,
            },
          },
          onCompleted: () => {
            Analytics.createClassSegment({
              className: reportingClass.name,
              classSegmentName: name,
              actionType: reportingClass.type,
            });
            showSuccessToast(name, reportingClass.name);
            handleClose();
          },
          onError: (err) => {
            showErrorToast(reportingClass.name, err.message);
            handleClose();
          },
        });
      } else {
        await createReportingClass({
          variables: {
            input: {
              companyId: company.id,
              name,
              description,
            },
          },
          onCompleted: () => {
            Analytics.createReportingClass({ className: name, actionType: "UserCreated" });
            showSuccessToast(name);
            handleClose();
          },
          onError: (err) => {
            showErrorToast("class", err.message);
            handleClose();
          },
        });
      }
    },
    [
      createClassSegment,
      createReportingClass,
      company.id,
      showSuccessToast,
      showErrorToast,
      handleClose,
      reportingClass,
    ]
  );

  if (reportingClass) {
    return {
      onSubmit,
      loading: createClassSegmentLoading,
      resetMutation: resetClassSegmentMutation,
    };
  }

  return {
    onSubmit,
    loading: createReportingClassLoading,
    resetMutation: resetReportingClassMutation,
  };
};

export default useCreateClassification;
