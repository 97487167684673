import React from "react";
import { Stack, Text } from "@puzzle/ui";

const DesertImage = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="210"
      height="220"
      fill="none"
      viewBox="0 0 210 220"
      {...props}
    >
      <path
        fill="#5A5A7A"
        d="M207.564 38.087c-9.786 9.785-25.678 9.785-35.555 0-9.877-9.785-9.786-25.678 0-35.55.538-.542.538-1.437 0-2.066-.361-.361-.809-.538-1.257-.448-18.32 3.32-30.53 20.918-27.207 39.23 3.32 18.313 20.921 30.522 39.237 27.203 13.919-2.514 24.782-13.38 27.207-27.293.09-.809-.358-1.524-1.167-1.614-.362 0-.9.177-1.258.538z"
      ></path>
      <path
        fill="#44445C"
        d="M0 77c15.789-40.174 39.058-37.84 46.539-18.034 0 0 11.418-18.48 26.442-20.695 21.244-3.154 23.063 22.26 23.063 22.26S123.331 43.114 129 77H0z"
      ></path>
      <path
        fill="#5A5A7A"
        d="M162.341 100.892c-8.137 0-14.75 6.617-14.75 14.76v10.318a6.496 6.496 0 01-6.495 6.501h-5.308V68.754c0-8.143-6.612-14.754-14.749-14.754-8.131 0-14.743 6.61-14.743 14.754v46.021h-5.308a6.506 6.506 0 01-6.502-6.5V94.989c0-8.144-6.606-14.76-14.743-14.76S65 86.845 65 94.988V121.3c0 12.67 10.252 22.956 22.906 22.956h18.364v75.503h29.46v-57.808h18.331c12.653 0 22.938-10.26 22.938-22.923v-23.377c.085-8.143-6.528-14.76-14.658-14.76z"
      ></path>
      <path
        fill="#4E4E65"
        d="M106.322 144.254v75.503h8.558v-66.964a8.53 8.53 0 00-8.558-8.539zM106.322 68.752v46.021c4.717 0 8.533-3.844 8.558-8.565V55.356a14.768 14.768 0 00-8.558 13.396zM147.591 115.651v10.318a6.497 6.497 0 01-6.495 6.501h8.416a7.575 7.575 0 007.572-7.579v-23.039a14.774 14.774 0 00-9.493 13.799zM65.085 95.017v26.32c0 7.065 3.244 13.766 8.837 18.117v-57.95c-5.366 2.318-8.837 7.637-8.837 13.513z"
      ></path>
    </svg>
  );
};

export const EmptyPane = () => {
  return (
    <Stack gap="7h" css={{ px: "$8", alignItems: "center" }}>
      <DesertImage />
      <Stack gap="1h" css={{ alignItems: "center", textAlign: "center" }}>
        <Text variant="headingL">Nothing to show</Text>

        <Text variant="headingM">Select something from Inbox to display details</Text>
      </Stack>
    </Stack>
  );
};
