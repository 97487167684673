import React from "react";
import IntegrationLogo from "../shared/IntegrationLogo";
import { Quickbooks } from "@puzzle/icons";

const Logo = ({ circular }: { circular?: boolean }) => {
  return (
    <IntegrationLogo color="#2CA01C" circular={circular}>
      <Quickbooks />
    </IntegrationLogo>
  );
};

export default Logo;
