import { makeVar, useReactiveVar } from "@apollo/client";
import { CalendarDate } from "@internationalized/date";
import { useCallback } from "react";

export type ParsedCSVRow = {
  amount?: string;
  date?: string;
  description?: string;
};

export type ParsedCSV = {
  data: ParsedCSVRow[];
  meta: {
    minDate: CalendarDate | null;
    maxDate: CalendarDate | null;
    isValid: boolean;
  };
} | null;

export type SetImportState = (
  patch: Partial<ImportState> | ((prevState: ImportState) => Partial<ImportState>)
) => void;

export type ImportState = {
  selectedAccountId: string | null;
  selectedFile: File | null;
  parsedCSV: ParsedCSV;
  uploadedFilePath: string | null;
};

const importStateVar = makeVar<ImportState>({
  selectedAccountId: null,
  selectedFile: null,
  parsedCSV: null,
  uploadedFilePath: null,
});

export const useImportState = () => {
  const importState = useReactiveVar(importStateVar);

  const updateImportState = useCallback((patch: Partial<ImportState>) => {
    importStateVar({
      ...importStateVar(),
      ...patch,
    });
  }, []);

  const reset = useCallback(() => {
    importStateVar({
      selectedAccountId: null,
      selectedFile: null,
      parsedCSV: null,
      uploadedFilePath: null,
    });
  }, []);

  return {
    ...importState,
    updateImportState,
    reset,
  };
};
