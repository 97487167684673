import { useMemo } from "react";
import { merge } from "lodash";
import { useToasts } from "@puzzle/ui";
import {
  FinancialInstitutionFragment,
  IntegrationConnectionCondition,
  IntegrationConnectionStatus,
  IntegrationType,
  MercuryIntegrationDocument,
  MercuryIntegrationQuery,
  useConnectMercuryMutation,
  useFinancialInstitutionsQuery,
  useMercuryIntegrationQuery,
} from "graphql/types";
import { usePendingConnections } from "../shared/PendingConnectionsProvider";
import { UseMercuryResult } from "./types";
import usePlaid, { MERCURY_INSTITUTION_NAME } from "../plaid/usePlaid";
import { useFinancialInstitutions, UseIntegrationHook } from "../shared";
import useSavePage from "../shared/useSavePage";
import config from "lib/config";
import Analytics from "lib/analytics";

export const TITLE = "Mercury";
export const SUBTITLE = "Credit Card";

const useMercury: UseIntegrationHook<UseMercuryResult> = ({
  companyId,
  integrationConnectionId,
  onClickConnect: baseOnClickConnect,
}): UseMercuryResult => {
  const { toast } = useToasts();
  const { data, loading, refetch } = useMercuryIntegrationQuery({
    variables: { companyId },
  });
  const { integrations } = data || {};
  const { financialInstitutions: plaidFinancialInstitutions } = usePlaid({ companyId });
  const { addPendingConnection, removePendingConnection } = usePendingConnections();
  const { savePage } = useSavePage();
  const { disconnectIntegrationConnection, integrationConnections } = useFinancialInstitutions();

  const connection = useMemo(() => {
    const connectionId = integrationConnectionId ?? integrations?.mercury.connection?.id;
    return integrationConnections.find((ic) => ic.id === connectionId);
  }, [integrationConnections, integrationConnectionId, integrations]);

  const disconnectIntegration = useMemo(() => {
    const connectionId = connection?.id;
    if (!connectionId) {
      return undefined;
    }

    return async () => {
      const result = disconnectIntegrationConnection(connectionId);

      if (!integrationConnectionId) {
        refetch();
      }

      return result;
    };
  }, [disconnectIntegrationConnection, integrationConnectionId, connection?.id, refetch]);

  const [connectMercuryMutation, { loading: connecting, error: connectionError }] =
    useConnectMercuryMutation();

  const initializationInfo = integrations?.mercury.initializationInfo;

  const onClickConnect = () => {
    baseOnClickConnect && baseOnClickConnect();
    savePage();

    if (!initializationInfo || !initializationInfo.connectUrl) {
      if (config.IS_LOCAL_DEVELOPMENT) {
        toast({
          message: "onClickConnect was called before initialization info was defined",
          status: "error",
        });
      }
      return;
    }
    window.open(initializationInfo.connectUrl, "_blank");
  };

  const connectMercury = async (input: {
    companyId: string;
    code: string;
    state: string;
    condition?: IntegrationConnectionCondition;
  }) => {
    if (
      plaidFinancialInstitutions.some((institution: FinancialInstitutionFragment) => {

        const accountWithConnection = institution.accounts.find((acct) => acct.connection);
        const connection = accountWithConnection?.connection;

        return (
          connection &&
          institution.name.toLowerCase().includes(MERCURY_INSTITUTION_NAME.toLowerCase()) &&
          [IntegrationConnectionStatus.Error, IntegrationConnectionStatus.Ok].includes(
            connection.status
          )
        );
      })
    ) {
      toast({
        message:
          "It looks like you are trying to connect to Mercury! You are already connected to Mercury through Plaid. Please reconnect via Plaid if you are disconnected.",
        status: "warning",
      });
      return;
    }

    addPendingConnection(IntegrationType.Mercury);

    const result = await connectMercuryMutation({
      variables: { input },

      update(cache, { data }) {
        if (!data) return;

        const mercuryIntegration = cache.readQuery<MercuryIntegrationQuery>({
          query: MercuryIntegrationDocument,
          variables: {
            companyId: companyId,
          },
        });

        cache.writeQuery({
          query: MercuryIntegrationDocument,
          variables: {
            companyId,
          },
          data: merge({}, mercuryIntegration, {
            integrations: {
              mercury: {
                connection: {
                  ...data.connectMercury.connection,
                },
              },
            },
          }),
        });
      },

      onCompleted(data) {
        const { connection } = data.connectMercury;
        removePendingConnection(IntegrationType.Mercury);

        Analytics.integrationConnected({
          connectionId: connection.id,
          integrationType: "Mercury",
          totalAccounts: 1,
        });
      },

      onError({ message }) {
        Analytics.integrationConnectionFailed({
          integrationType: "Mercury",
          reason: message,
        });
      },
    });

    return result;
  };

  const { data: fiData } = useFinancialInstitutionsQuery({
    variables: { companyId, integrationType: IntegrationType.Mercury },
  });

  const financialInstitutions = fiData?.financialInstitutionsFor ?? [];

  return {
    connectionError,
    connectMercury,
    connecting,
    loading,
    onClickConnect,
    connection,
    disconnect: disconnectIntegration,
    financialInstitutions,
  };
};

export default useMercury;
