import { space } from "@puzzle/theme";

export const VIEWPORT_PADDING_UNITS = 4;
export const VIEWPORT_PADDING = space[VIEWPORT_PADDING_UNITS];

export const ANIMATION_TIMING = {
  open: 300,
  cancel: 200,
  close: 100,
};

export const createRectRef = (onRect: (rect: DOMRect) => void) => (el: HTMLElement | null) => {
  if (el) {
    setTimeout(() => {
      const boundingRect = el.getBoundingClientRect();
      onRect(boundingRect);
    });
  }
};
