import React from "react";
import { Central } from "@puzzle/icons";
import { IntegrationLogo } from "../../shared";

const CentralLogo = ({ circular }: { circular?: boolean }) => {
  return (
    <IntegrationLogo color="#1A0DAB" width="48px" height="48px" circular={circular}>
      <Central size={40} />
    </IntegrationLogo>
  );
};

export default CentralLogo;
