import {
  ContractRevenueScheduleStatus,
  DynamicReportType,
  InvoiceStatus,
  LedgerAccount,
  LedgerReportFilterInput,
  LedgerReportLineType,
  ManualJournalEntryStatus,
  ManualJournalEntryViewFilter,
} from "graphql/types";
import { Route } from "lib/routes";
import { Dictionary, isEmpty, omitBy } from "lodash";
import { ColumnInstance, Row } from "react-table";
import { UrlObject } from "url";
import { EnhancedBalanceByReportColumn, EnhancedLedgerReportLine } from "./types";
import { TableView } from "../Invoices/InvoicesTable/InvoicesContext";
import { capitalizeName } from "@puzzle/utils";
import Big from "big.js";
import { noClassificationUUID } from "./Filters/ClassificationsFilter";

type BalanceForColumnResult = { value: string; isPercent: boolean; columnKey: string };

export const balanceForColumn = (
  columnKey: string,
  periods: EnhancedBalanceByReportColumn[]
): BalanceForColumnResult => {
  const result: BalanceForColumnResult | null = periods.reduce(
    (res: BalanceForColumnResult | null, period) => {
      if (period.columnKey === columnKey) {
        let value = period.balance.amount;
        if (Object.is(value, -0)) {
          // happens when 0 is divided by a negative value
          value = Big(0).toString();
        }
        return { value: period.balance.amount, isPercent: !!period.isPercent, columnKey };
      }
      return res;
    },
    { value: Big(0).toString(), isPercent: false, columnKey }
  );

  if (!result) {
    throw new Error("Could not find a value for the column " + columnKey);
  }

  return result;
};

const buildClassesFilter = (filter?: LedgerReportFilterInput, activeTab?: DynamicReportType) => {
  const hasFilterSegments = filter?.segments?.segmentIds && filter?.segments?.segmentIds.length > 0;
  const hasExclusionFilter = filter?.segments?.segmentIds.includes(noClassificationUUID);

  const shouldAddClassesFilter =
    activeTab === DynamicReportType.ProfitAndLoss && (hasFilterSegments || hasExclusionFilter);

  if (!shouldAddClassesFilter) return undefined;

  const withSomeOfClassSegmentIdsString = `[${
    filter?.segments?.segmentIds
      .filter((segment) => segment !== noClassificationUUID)
      .map((segment) => `"${segment}"`) || ""
  }]`;

  const withNoneOfClassIdsString = `[${
    hasExclusionFilter ? `"${filter?.segments?.reportingClassId}"` : ""
  }]`;

  return encodeURIComponent(
    `{"withSomeOfClassSegmentIds":${withSomeOfClassSegmentIdsString}, "withNoneOfClassIds":${withNoneOfClassIdsString}}`
  );
};

export const getLinkForNode = ({
  nodeConfig: { row, column },
  view,
  activeTab,
  filter,
  squashingEnabled,
}: {
  nodeConfig: {
    row: Row<EnhancedLedgerReportLine>;
    column: ColumnInstance<EnhancedLedgerReportLine>;
  };
  view: string;
  activeTab?: DynamicReportType;
  filter?: LedgerReportFilterInput;
  squashingEnabled?: boolean;
}): UrlObject | undefined => {
  const { balanceByColumn, accountIds, ledgerCoaKeys, lineType, metadata, vendorIds } =
    row.original;
  const balance = balanceByColumn.find((x) => x.columnKey === column.id);
  const coaQueryParams = ledgerCoaKeys.join(".");
  const revenueSourceType = metadata?.revenueSourceType;

  if (accountIds.length === 0 && ledgerCoaKeys.length === 0 && vendorIds.length === 0) {
    return undefined;
  }

  const isRevenue = squashingEnabled && Boolean(ledgerCoaKeys.find((k) => k.includes("income")));

  if (lineType === LedgerReportLineType.AccrualRevenueSource) {
    if (revenueSourceType === "invoice") {
      return {
        pathname: Route.invoices,
        query: omitBy(
          {
            ledgerCoaKeys: coaQueryParams,
            start: balance?.dateRange.fromInclusive,
            end: balance?.dateRange.toInclusive,
            invoiceStatuses: `${InvoiceStatus.Posted}.${InvoiceStatus.Paid}`,
            scheduleStatuses: [ContractRevenueScheduleStatus.Active],
            activeTableView: TableView.Invoices,
          },
          isEmpty
        ),
      };
    }

    if (revenueSourceType === "schedule") {
      return {
        pathname: Route.accrualRevenue,
        query: omitBy(
          {
            start: balance?.dateRange.fromInclusive,
            end: balance?.dateRange.toInclusive,
          },
          isEmpty
        ),
      };
    }

    if (revenueSourceType === "mje") {
      return {
        pathname: Route.manualJournals,
        query: omitBy(
          {
            ledgerCoaKeys: coaQueryParams,
            start: balance?.dateRange.fromInclusive,
            end: balance?.dateRange.toInclusive,
            status: ManualJournalEntryStatus.Posted,
            view: ManualJournalEntryViewFilter.Accrual,
          },
          isEmpty
        ),
      };
    }

    if (revenueSourceType === "other" || revenueSourceType === "subledger") {
      return undefined;
    }
  }
  if (isRevenue) {
    return {
      pathname: Route.stripe,
      query: omitBy(
        { start: balance?.dateRange.fromInclusive, end: balance?.dateRange.toInclusive },
        isEmpty
      ),
    };
  }

  return {
    pathname: Route.transactions,
    // Using dots because of next-usequerystate
    query: omitBy(
      {
        //include no_category for uncateogrized revenue lines
        ledgerCoaKeys: ledgerCoaKeys.includes("income")
          ? [...ledgerCoaKeys, "no_category"].join(".")
          : coaQueryParams,
        accountIds: accountIds.join("."),
        vendorIds: vendorIds.join("."),
        status: row.original.title.includes("Uncategorized") ? "uncategorized" : undefined,
        showAvailableDate:
          view === "cash" && row.original.aggregateLineTypes.includes("revenue_source")
            ? "true"
            : undefined,
        start: balance?.dateRange.fromInclusive,
        end: balance?.dateRange.toInclusive,
        classesFilter: buildClassesFilter(filter, activeTab),
      },
      isEmpty
    ),
  };
};

export const getLinkForNodeGL = ({
  nodeConfig: { row, column },
  view,
  squashingEnabled,
}: {
  nodeConfig: {
    row: Row<EnhancedLedgerReportLine>;
    column: ColumnInstance<EnhancedLedgerReportLine>;
  };
  view: string;
  activeTab?: DynamicReportType;
  filter?: LedgerReportFilterInput;
  squashingEnabled?: boolean;
}): UrlObject | undefined => {
  const { balanceByColumn, accountIds, ledgerCoaKeys, lineType, metadata, vendorIds } =
    row.original;
  const balance = balanceByColumn.find((x) => x.columnKey === column.id);
  const revenueSourceType = metadata?.revenueSourceType;

  if (accountIds.length === 0 && ledgerCoaKeys.length === 0 && vendorIds.length === 0) {
    return undefined;
  }

  const isRevenue = squashingEnabled && Boolean(ledgerCoaKeys.find((k) => k.includes("income")));

  if (lineType === LedgerReportLineType.AccrualRevenueSource) {
    if (revenueSourceType === "invoice") {
      return {
        pathname: Route.generalLedger,
        query: omitBy(
          {
            accountId: accountIds.length >= 1 ? accountIds[0] : undefined,
            start: balance?.dateRange.fromInclusive,
            end: balance?.dateRange.toInclusive,
          },
          isEmpty
        ),
      };
    }

    if (revenueSourceType === "schedule") {
      return {
        pathname: Route.generalLedger,
        query: omitBy(
          {
            accountId: accountIds.length >= 1 ? accountIds[0] : undefined,
            start: balance?.dateRange.fromInclusive,
            end: balance?.dateRange.toInclusive,
          },
          isEmpty
        ),
      };
    }

    if (revenueSourceType === "mje") {
      return {
        pathname: Route.generalLedger,
        query: omitBy(
          {
            accountId: accountIds.length >= 1 ? accountIds[0] : undefined,
            start: balance?.dateRange.fromInclusive,
            end: balance?.dateRange.toInclusive,
            view: ManualJournalEntryViewFilter.Accrual,
          },
          isEmpty
        ),
      };
    }

    if (revenueSourceType === "other") {
      return undefined;
    }
  }
  if (isRevenue) {
    return {
      pathname: Route.generalLedger,
      query: omitBy(
        {
          accountId: accountIds.length >= 1 ? accountIds[0] : undefined,
          start: balance?.dateRange.fromInclusive,
          end: balance?.dateRange.toInclusive,
        },
        isEmpty
      ),
    };
  }

  return {
    pathname: Route.generalLedger,
    // Using dots because of next-usequerystate
    query: omitBy(
      {
        accountId: accountIds.length >= 1 ? accountIds[0] : undefined,
        start: balance?.dateRange.fromInclusive,
        end: balance?.dateRange.toInclusive,
        view,
      },
      isEmpty
    ),
  };
};

export const dynamicReportTypeDisplays: Record<DynamicReportType, string> = {
  [DynamicReportType.BalanceSheet]: "Balance Sheet",
  [DynamicReportType.CashActivityReport]: "Cash Activity Report",
  [DynamicReportType.ProfitAndLoss]: "Profit & Loss",
  [DynamicReportType.TrialBalance]: "Trial Balance",
};

export const formatTitleForNode = (node: EnhancedLedgerReportLine) => {
  if (node.lineType === LedgerReportLineType.AccrualRevenueSource) {
    if (node.metadata?.revenueSourceType === "mje") return "Manual Journal Entry";
    return capitalizeName(node.title);
  }

  return node.title;
};

export const getLinkForAccrualRevenueTotal = ({
  row,
  column,
  categoriesByPermaKey,
}: {
  row: Row<EnhancedLedgerReportLine>;
  column: ColumnInstance<EnhancedLedgerReportLine>;
  categoriesByPermaKey: Dictionary<Partial<LedgerAccount>>;
}) => {
  const { balanceByColumn, ledgerCoaKeys } = row.original;
  const balance = balanceByColumn.find(({ columnKey }) => columnKey === column.id);

  return {
    pathname: Route.generalLedger,
    query: omitBy(
      {
        accountId: categoriesByPermaKey[ledgerCoaKeys[0]]?.id,
        start: balance?.dateRange.fromInclusive,
        end: balance?.dateRange.toInclusive,
        view: "accrual",
      },
      isEmpty
    ),
  };
};
