import React from "react";
import { UniversalProps } from "./utils/types";

export const LightBackgroundLoadingBar = ({ className }: UniversalProps) => (
  <div className={className + " loading light-background"} />
);

export const DarkBackgroundLoadingBar = ({ className }: UniversalProps) => (
  <div className={className + " loading dark-background"} />
);
