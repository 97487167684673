import * as Types from '../types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
import { AccountFragmentDoc, AccountWithIntegrationFragmentDoc } from '../fragments/account.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateManualAccountMutationVariables = Types.Exact<{
  input: Types.CreateManualAccountInput;
}>;


export type CreateManualAccountMutation = { __typename?: 'Mutation', createManualAccount: { __typename?: 'CreateManualAccountResult', error?: Types.CreateManualAccountError | null, account?: { __typename?: 'Account', id: string, manuallyAdded: boolean, name: string, subType?: Types.AccountSubType | null, type: Types.AccountType, mask?: string | null, status: Types.IntegrationConnectionStatus, updatedAt: CalendarDateTimeString, lastImportedAt?: CalendarDateTimeString | null, archivedAt?: CalendarDateTimeString | null, connection?: { __typename?: 'IntegrationConnection', id: string, createdAt: CalendarDateTimeString, type: Types.IntegrationType, ingressType: Types.IngressType, status: Types.IntegrationConnectionStatus, message?: string | null, errorCode?: Types.DisconnectionErrorCode | null, lastSyncedAt?: CalendarDateTimeString | null, condition?: Types.IntegrationConnectionCondition | null, latestSync?: { __typename?: 'SyncRun', id: any, status: Types.SyncRunStatus } | null } | null, financialInstitution: { __typename?: 'FinancialInstitution', id: string, name: string }, initialAccountBalance: { __typename?: 'AccountBalance', balance: string, date: CalendarDateTimeString, splits: Array<{ __typename?: 'AccountBalanceSplit', amount: string, description: string, category: { __typename?: 'GatewayCategory', permaKey: string, name: string, displayName?: string | null } | { __typename?: 'LedgerCategory', permaKey: string, name: string, displayName: string } }> }, todaysBalance: { __typename?: 'AccountBalance', balance: string }, epoch?: { __typename?: 'AccountEpoch', cutoffBefore?: CalendarDateString | null, cutoffAtOrAfter?: CalendarDateString | null } | null } | null } };


export const CreateManualAccountDocument = gql`
    mutation createManualAccount($input: CreateManualAccountInput!) {
  createManualAccount(input: $input) {
    account {
      ...account
    }
    error
  }
}
    ${AccountFragmentDoc}`;
export type CreateManualAccountMutationFn = Apollo.MutationFunction<CreateManualAccountMutation, CreateManualAccountMutationVariables>;

/**
 * __useCreateManualAccountMutation__
 *
 * To run a mutation, you first call `useCreateManualAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManualAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManualAccountMutation, { data, loading, error }] = useCreateManualAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateManualAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateManualAccountMutation, CreateManualAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateManualAccountMutation, CreateManualAccountMutationVariables>(CreateManualAccountDocument, options);
      }
export type CreateManualAccountMutationHookResult = ReturnType<typeof useCreateManualAccountMutation>;
export type CreateManualAccountMutationResult = Apollo.MutationResult<CreateManualAccountMutation>;
export type CreateManualAccountMutationOptions = Apollo.BaseMutationOptions<CreateManualAccountMutation, CreateManualAccountMutationVariables>;