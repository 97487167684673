// Record of keys and the last fetched timestamp

import { WatchQueryFetchPolicy } from "@apollo/client";

// These are universal for all calls and hooks
const keys = new Map<string, number>();

export const ONE_MINUTE = 1000 * 60;
export const ONE_HOUR = 60 * ONE_MINUTE;

/**
 * This function accepts a unique key and an expiration date. It returns "network-only" if the cache key is expired
 * or "cache-first" if the key is still valid for the given expiration time
 * Adapted from: https://www.redsunsoft.com/2021/10/simple-cache-expiration-for-apollo-graphql-queries/
 *
 * TODO Consider turning this into a hook so we can add a timer?
 *
 * @param key - the unique name you want to give this expiration key
 * @param expirationMs)
 */
export const getFetchPolicyForKey = (
  key: string,
  expirationMs: number,
  expiredPolicy: WatchQueryFetchPolicy = "cache-and-network"
): WatchQueryFetchPolicy => {
  const lastFetchTimestamp = keys.get(key);
  const diffFromNow = lastFetchTimestamp
    ? Date.now() - lastFetchTimestamp
    : Number.MAX_SAFE_INTEGER;
  let fetchPolicy: WatchQueryFetchPolicy = "cache-first";

  // Force network request if it's expired
  if (diffFromNow > expirationMs) {
    keys.set(key, Date.now());
    fetchPolicy = expiredPolicy;
  }

  return fetchPolicy;
};
