import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Exclamation({
  viewBox = "0 0 12 12",
  fill = "currentColor",
  width = 12,
  height = 12,
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} viewBox={viewBox} {...iconProps}>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M6 12A6 6 0 106 0a6 6 0 000 12zm0-9.75a.75.75 0 01.75.75v3a.75.75 0 01-1.5 0V3A.75.75 0 016 2.25zM6 10a1 1 0 100-2 1 1 0 000 2z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
}
