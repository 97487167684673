import { ClassSegment } from "components/common/Classifications/TagEntity";
import {
  AccountingConfiguration,
  AccountingRecognitionTiming,
  ContractRevenueSchedulePostingMethod,
  CreateInvoiceInput,
  CreateInvoiceLineInput,
  CreateInvoiceLineSchedule,
  InclusiveLocalDateRangeInput,
  InvoiceStatus,
  UpdateInvoiceDiscountLineInput,
  UpdateInvoiceInput,
  UpdateInvoiceLineInput,
  UpdateInvoiceShippingLineInput,
  UpsertClassSegmentInput,
} from "graphql/types";
import { FormLine, FormValues, ScheduleFormValues } from "./types";
import {
  assumePeriodMethod,
  calculateServiceDuration,
  toScheduleDateRangePayload,
} from "../shared";
import Big from "big.js";
import { ContractRevenueScheduleFragment } from "../graphql.generated";
import { CalendarDateString } from "scalars";

const toUpsertClassSegmentInput = (formSegment: ClassSegment): UpsertClassSegmentInput => {
  return {
    class: formSegment.reportingClass.name,
    segment: formSegment.name,
    reportingClassType: formSegment.reportingClass.type,
  };
};

const toUpsertClassSegmentInputs = (
  formSegments?: ClassSegment[]
): UpsertClassSegmentInput[] | undefined => {
  return formSegments?.map((f) => toUpsertClassSegmentInput(f));
};

const toCreateInvoiceLineScheduleDateRangeInput = (
  formSchedule: ScheduleFormValues
): InclusiveLocalDateRangeInput => {
  if (formSchedule.periodMethod === "duration") {
    return toScheduleDateRangePayload({
      startDate: formSchedule.startDate || "",
      serviceDuration: formSchedule.serviceDuration,
    });
  } else {
    return {
      fromInclusive: formSchedule.startDate || "",
      toInclusive: formSchedule.endDate || "",
    };
  }
};

const toCreateInvoiceLineScheduleInput = (
  formSchedule?: ScheduleFormValues
): CreateInvoiceLineSchedule | undefined => {
  return formSchedule
    ? {
        postingMethod: ContractRevenueSchedulePostingMethod.Automatically,
        dateRange: toCreateInvoiceLineScheduleDateRangeInput(formSchedule),
        // todo: once backend has enabled
        // accountingConfigurationId: formSchedule.accountingConfigurationId,
      }
    : undefined;
};

const toInvoiceLineRevRecOptionsInput = (formSchedule?: ScheduleFormValues): any | undefined => {
  return formSchedule && formSchedule.accountingConfigurationId
    ? {
        accountingConfigurationId: formSchedule.accountingConfigurationId,
      }
    : undefined;
};

const toCreateInvoiceLineInput = (
  formLine: FormLine,
  revRecEnabled?: boolean
): CreateInvoiceLineInput | any => {
  const obj: CreateInvoiceLineInput = {
    description: formLine.description,
    //if new product don't send id
    productId: formLine.product?.id?.startsWith("product") ? undefined : formLine.product?.id,
    productName: formLine.product?.name,
    coaKey: formLine.category?.coaKey ?? "",
    amount: Big(formLine.amount || 0).toString(),
    schedule: toCreateInvoiceLineScheduleInput(formLine.schedule),
    segments: toUpsertClassSegmentInputs(formLine.segments),
  };

  if (revRecEnabled) {
    return {
      ...obj,
      revRecOptions: toInvoiceLineRevRecOptionsInput(formLine.schedule),
    };
  } else {
    return obj;
  }
};

const toUpdateInvoiceLineInput = (formLine: FormLine): UpdateInvoiceLineInput => {
  return { id: formLine.id, ...toCreateInvoiceLineInput(formLine) };
};

const toUpdateInvoiceDiscountLineInput = (formLine: FormLine): UpdateInvoiceDiscountLineInput => {
  return {
    id: formLine.id,
    description: formLine.description,
    coaKey: formLine.category?.coaKey ?? "",
    amount: Big(formLine.amount || 0)
      .neg()
      .toString(),
    schedule: toCreateInvoiceLineScheduleInput(formLine.schedule),
    segments: toUpsertClassSegmentInputs(formLine.segments),
  };
};

const toUpdateInvoiceShippingLineInput = (formLine: FormLine): UpdateInvoiceShippingLineInput => {
  return {
    id: formLine.id,
    description: formLine.description,
    coaKey: formLine.category?.coaKey ?? "",
    subtotal: Big(formLine.amount || 0).toString(),
    segments: toUpsertClassSegmentInputs(formLine.segments),
  };
};

const toCreateInvoiceLineInputs = (
  formLines: FormLine[],
  revRecEnabled?: boolean
): CreateInvoiceLineInput[] => formLines.map((f) => toCreateInvoiceLineInput(f, revRecEnabled));

const toUpdateInvoiceLineInputs = (formLines: FormLine[]): UpdateInvoiceLineInput[] =>
  formLines.map((f) => toUpdateInvoiceLineInput(f));

const toUpdateInvoiceDiscountLineInputs = (
  formLines: FormLine[]
): UpdateInvoiceDiscountLineInput[] => formLines.map((f) => toUpdateInvoiceDiscountLineInput(f));

const toUpdateInvoiceShippingLineInputs = (
  formLines: FormLine[]
): UpdateInvoiceShippingLineInput[] => formLines.map((f) => toUpdateInvoiceShippingLineInput(f));

export const calculateNumSchedules = (lines: UpdateInvoiceLineInput[]) =>
  lines.reduce((acc, { schedule }) => (schedule ? acc + 1 : acc), 0);

export const toCreateInvoiceInput = (
  companyId: string,
  formData: FormValues,
  isDraft: boolean,
  overrides?: Partial<CreateInvoiceInput>,
  revRecEnabled?: boolean
): CreateInvoiceInput => {
  return {
    companyId,
    description: formData.description,
    dueDate: formData.dueDate,
    issueDate: formData.issueDate,
    customerId: formData.customer?.id,
    externalId: formData.externalId,
    status: isDraft ? InvoiceStatus.Draft : InvoiceStatus.Posted,
    lines: toCreateInvoiceLineInputs(formData.lines, revRecEnabled),
    ...overrides,
  };
};

export const toUpdateInvoiceInput = (
  invoiceId: string,
  formData: FormValues,
  status: InvoiceStatus
): UpdateInvoiceInput => {
  return {
    invoiceId,
    description: formData.description,
    dueDate: formData.dueDate,
    issueDate: formData.issueDate,
    customerId: formData.customer?.id,
    externalId: formData.externalId,
    status: status,
    lines: toUpdateInvoiceLineInputs(formData.lines),
    discountLines: toUpdateInvoiceDiscountLineInputs(formData.discountLines),
    shippingLines: toUpdateInvoiceShippingLineInputs(formData.shippingLines),
  };
};

export const toInvoiceScheduleForm = (
  timeZone: string,
  invoiceStartDate: CalendarDateString,
  schedule?: ContractRevenueScheduleFragment | null,
  contractConfiguration?: AccountingConfiguration
): ScheduleFormValues | undefined => {
  if (contractConfiguration?.config?.method === AccountingRecognitionTiming.PointInTime) {
    // currently when there is a point in time policy applied to a line
    // the point that it is recognized must be the invoice issue date
    return {
      id: undefined,
      serviceDuration: "1",
      startDate: invoiceStartDate,
      endDate: invoiceStartDate,
      accountingConfigurationId: contractConfiguration.id,
      periodMethod: "endDate",
    };
  }
  if (schedule?.id) {
    return {
      id: schedule?.id ?? undefined,
      serviceDuration: calculateServiceDuration({
        startDay: schedule?.startDay,
        endDay: schedule?.endDay,
        timeZone,
      }).toString(),
      startDate: schedule?.startDay ?? undefined,
      endDate: schedule?.endDay ?? undefined,
      accountingConfigurationId: schedule?.contractLine?.accountingConfigurationId || undefined,
      periodMethod: assumePeriodMethod(
        timeZone,
        schedule?.startDay || undefined,
        schedule?.endDay || undefined
      ),
    };
  }
};
