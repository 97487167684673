import React from "react";
import Icon, { IconProps } from "./Icon";

export default function MoneyOut({
  viewBox = "0 0 14 14",
  color = "currentColor",
  ...iconProps
}: IconProps) {
  return (
    <Icon {...iconProps} viewBox={viewBox}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M3.146 10.854a.5.5 0 00.708 0L10 4.707V8.5a.5.5 0 001 0V3H5.5a.5.5 0 000 1h3.793l-6.147 6.146a.5.5 0 000 .708z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
}
