import React from "react";
import { EveryLogo, useEvery } from "../every";
import IntegrationDetails from "./IntegrationDetails";
import { DetailProps } from "./types";
import { useToasts } from "@puzzle/ui";

const EveryDetails = ({ companyId, integrationConnectionId, ...props }: DetailProps) => {
  const every = useEvery({ companyId, integrationConnectionId });
  const { toast } = useToasts();

  return (
    <IntegrationDetails
      logo={<EveryLogo />}
      title="Every"
      connection={every.connection}
      onDisconnect={every.disconnect}
      onReconnect={() => {
        toast({
          message: "Please reconnect your Every account in the Every app.",
          status: "warning",
        });
      }}
      requiresExternalSetup={true}
      {...props}
    />
  );
};

export default EveryDetails;
