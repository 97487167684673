import React, { useState } from "react";
import useRamp from "./useRamp";
import { IntegrationLoading } from "../setup";
import { IntegrationConnectionCondition, IntegrationType } from "graphql/types";
import { SelectStartDateModal } from "../setup/modals/SelectStartDateModal";
import { usePollIntegration } from "../shared/usePollIntegration";

interface ConnectRampPageProps {
  companyId: string;
  code: string;
  state: string;
  isOnboarding: boolean;
}

const ConnectRampPage = ({ companyId, code, state, isOnboarding }: ConnectRampPageProps) => {
  const { connectRamp, connectionError, connection, loading } = useRamp({ companyId });
  const [isReconnect, setIsReconnect] = useState(false);
  const { financialInstitutions, accounts, startPolling } = usePollIntegration(
    IntegrationType.Ramp,
    !isOnboarding
  );
  const [openSelectDate, onOpenSelectDateChange] = useState(false);

  const connect = async () => {
    if (!code || !state) {
      return;
    }
    if (connection?.id) {
      setIsReconnect(true);
    }

    const result = await connectRamp({
      companyId,
      code,
      state,
      condition: !isOnboarding ? IntegrationConnectionCondition.WaitingForUserEpoch : undefined,
    });

    if (!result || !result?.data || result.errors) {
      throw new Error("Error connecting ramp");
    }
    if (financialInstitutions.length === 0) {
      startPolling(1000);
    }
    onOpenSelectDateChange(true);
  };

  return (
    <>
      <IntegrationLoading
        integrationType={IntegrationType.Ramp}
        title="Connecting to Ramp"
        connectIntegration={connect}
        connection={connection}
        loading={loading}
        error={!!connectionError}
      />
      {!isOnboarding && (
        <SelectStartDateModal
          companyId={companyId}
          open={openSelectDate}
          accounts={accounts}
          onOpenChange={onOpenSelectDateChange}
          isReconnect={isReconnect}
          connectionId={connection?.id}
          accountsLoading={accounts.length === 0 ?? false}
        />
      )}
    </>
  );
};

export default ConnectRampPage;
