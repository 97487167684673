import React from "react";
import { noop } from "lodash";
import { styled } from "@puzzle/ui";
import { Confirmed } from "@puzzle/icons";

const CONTAINER_HEIGHT = 48;
const THUMB_LENGTH = 16;

const Container = styled("div", {
  display: "flex",
  alignItems: "center",
  height: CONTAINER_HEIGHT,
});

const IconContainer = styled("span", {
  lineHeight: 0,
});

const TrackContainer = styled("span", {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: THUMB_LENGTH,
  height: "100%",
});

const Thumb = styled("span", {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: THUMB_LENGTH,
  height: THUMB_LENGTH,
  borderRadius: "$ellipse",
  border: "2px solid $gray700",
  zIndex: 100,
  backgroundColor: "$mauve800",
  "&::after": {
    content: "",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "inline-block",
    width: "6px",
    height: "6px",
    borderRadius: "8px",
    opacity: 0,
    backgroundColor: "$gray200",
  },
  variants: {
    active: {
      true: {
        borderColor: "$gray200",
        "&::after": {
          opacity: 1,
        },
      },
    },
    visited: {
      true: {
        borderColor: "$gray200",
      },
    },
  },
});

const TrackHalf = styled("span", {
  width: 2,
  height: "50%",
  backgroundColor: "$gray700",
  variants: {
    active: {
      true: {
        backgroundColor: "$gray200",
      },
    },
    hide: {
      true: {
        opacity: 0,
      },
    },
  },
});

const Text = styled("div", {
  marginLeft: "$1h",
  textVariant: "$headingS",
  cursor: "default",
  color: "$gray600",
  transition: "color 80ms ease-in-out",
  userSelect: "none",
  variants: {
    active: {
      true: {
        "&&": {
          cursor: "pointer",
          color: "$gray50",
        },
      },
    },
    visited: {
      true: {
        cursor: "pointer",
        color: "$gray300",
        "&:hover": {
          color: "$gray50",
        },
      },
    },
  },
});

export interface StepperProps {
  label: string;
  active?: boolean;
  completed?: boolean;
  onClick?: () => void;
  _visited?: boolean;
  _first?: boolean;
  _last?: boolean;
}

// Styling is done by applying the disabled state first to simplify overriding.
// From weakest to strongest: disabled -> visited -> completed|active
// A visited state is needed because a user may decide to go back to a previous step without
// completing the step they were on. We mark that step as visited so we can add a styling
// that doesn't look disabled or active
export const Stepper = ({
  label,
  active,
  completed,
  onClick = noop,
  _visited,
  _first,
  _last,
}: StepperProps) => {
  const disabled = !active && !completed && !_visited;
  const renderThumb = () => {
    // This was the original design. Might want to have people playing around
    // with it before removing it altogether.
    // if (active) {
    //   return <Thumb visited={_visited} active />;
    // }
    if (completed) {
      return (
        <IconContainer>
          <Confirmed size={16} />
        </IconContainer>
      );
    }
    return <Thumb visited={_visited} active={active} />;
  };

  return (
    <Container>
      <TrackContainer>
        <TrackHalf active={active || completed || _visited} hide={_first} />
        {renderThumb()}
        <TrackHalf active={completed} hide={_last} />
      </TrackContainer>
      <Text
        visited={_visited}
        active={active}
        onClick={() => {
          if (disabled) {
            return;
          }
          onClick();
        }}
      >
        {label}
      </Text>
    </Container>
  );
};
