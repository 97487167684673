import React from "react";
import Icon, { IconProps } from "./Icon";
import { Colors } from "./colors";

export default function Revenue({
  viewBox = "0 0 24 24",
  width = 24,
  height = 24,
  active,
  color = active ? Colors.Active : Colors.BaseGrey,
  fill = color,
  ...iconProps
}: IconProps) {
  return (
    <Icon viewBox={viewBox} width={width} height={height} {...iconProps}>
      <path
        fill={"#595861"}
        d="M4 4a2 2 0 012-2h8l3 3 3 3v12a2 2 0 01-2 2H6a2 2 0 01-2-2V4z"
      ></path>
      <path fill={fill} d="M14 2l3 3 3 3h-4a2 2 0 01-2-2V2z"></path>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M13.598 12.874l-2.315 6.175a.85.85 0 01-1.556.081l-1.69-3.38H2.75a.75.75 0 010-1.5h5.688a.85.85 0 01.76.47l1.204 2.406 2.315-6.175a.85.85 0 011.556-.081l1.69 3.38h1.287a.75.75 0 010 1.5h-1.688a.85.85 0 01-.76-.47l-1.204-2.406z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
}
