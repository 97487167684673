import React from "react";
import Icon from "../Icon";

function Every({ width = 48, height = 48, viewBox = "0 0 48 48" }) {
  return (
    <Icon width={width} height={height} fill="none" viewBox={viewBox}>
      <g clipPath="url(#clip0_1_8)">
        <path
          fill="#fff"
          d="M6.214 24.103c-.206.584-.817.939-1.601.939-1.056 0-1.774-.71-1.774-1.737v-.173h6.065v-.79c0-2.628-1.724-4.325-4.431-4.325C1.725 18.017 0 19.785 0 22.563c0 2.779 1.708 4.46 4.572 4.46 2.352 0 4.002-1.145 4.283-2.92h-2.64zm-1.692-4.097c.925 0 1.576.616 1.626 1.531H2.88c.074-.892.743-1.531 1.642-1.531zM18.666 18.222h-3.061l-1.535 6.252h-.058l-1.568-6.252H9.3l3.012 8.596h3.375l2.98-8.596zM25.226 24.103c-.206.584-.816.939-1.6.939-1.057 0-1.775-.71-1.775-1.737v-.173h6.066v-.79c0-2.628-1.725-4.325-4.432-4.325-2.748 0-4.473 1.768-4.473 4.546 0 2.779 1.709 4.46 4.572 4.46 2.352 0 4.003-1.145 4.283-2.92h-2.64zm-1.691-4.097c.924 0 1.576.616 1.625 1.531h-3.267c.074-.892.742-1.531 1.642-1.531zM29.237 26.818h2.93v-4.523c0-1.2.668-1.863 1.897-1.863.347 0 .685.063.933.158v-2.384a2.213 2.213 0 00-.743-.11c-1.056 0-1.782.592-2.113 1.752h-.057v-1.626h-2.847v8.596zM37.43 29.983c2.625 0 3.945-.884 4.713-3.189l2.872-8.572h-3.087l-1.609 6.496h-.058l-1.625-6.496h-3.227l3.029 8.596c.016.055-.017.268-.017.316-.075.52-.512.741-1.428.741-.04 0-.594-.007-.635-.015v2.115c.041.008 1.031.008 1.073.008z"
        ></path>
        <circle cx="46.195" cy="25.065" r="1.805" fill="#3F69FF"></circle>
      </g>
      <defs>
        <clipPath id="clip0_1_8">
          <path fill="#fff" d="M0 0H48V48H0z"></path>
        </clipPath>
      </defs>
    </Icon>
  );
}

export default Every;
