import React, { useState } from "react";
import useQuickbooks from "./useQuickbooks";
import { IntegrationLoading } from "../setup";
import { IntegrationType } from "graphql/types";
import { SelectIngestionDateModal } from "./modals";

interface ConnectQuickbooksPageProps {
  companyId: string;
  code: string;
  realmId: string;
  state: string;
  isOnboarding: boolean;
}

const ConnectQuickbooksPage = ({
  companyId,
  code,
  realmId,
  state,
  isOnboarding,
}: ConnectQuickbooksPageProps) => {
  const { connectQuickbooks, connectionError, connection, loading } = useQuickbooks({
    companyId,
  });
  const [openSelectDate, onOpenSelectDate] = useState(false);

  const connect = () => {
    onOpenSelectDate(true);
    connectQuickbooks({ code, companyId, state, realmId }).then((result) => {
      // start syncing data while we go back to the integrations page
      if (!result?.data || result.errors) {
        throw new Error("Error connecting quickbooks");
      }
    });
  };

  return (
    <>
      <IntegrationLoading
        integrationType={IntegrationType.QuickBooks}
        title="Connecting to Quickbooks"
        loading={loading}
        connection={connection}
        connectIntegration={connect}
        error={!!connectionError}
      />
      {!isOnboarding && (
        <SelectIngestionDateModal
          open={openSelectDate}
          onOpenChange={() => onOpenSelectDate(false)}
        />
      )}
    </>
  );
};

export default ConnectQuickbooksPage;
