import { MRT_RowData } from "material-react-table";
import { RefObject, useCallback, useEffect } from "react";

export const formatMrtCol = <RowData extends MRT_RowData>(col: RowData[]) => {
  return col.map((obj) => {
    if (obj.meta?.align) {
      return {
        ...obj,
        muiTableHeadCellProps: { align: obj.meta.headerAlign || obj.meta.align },
        muiTableBodyCellProps: { align: obj.meta.align },
      };
    }
    return obj;
  });
};

export function useInfiniteLoadOnScroll({
  tableContainerRef,
  hasMore = false,
  fetchMore,
}: {
  tableContainerRef: RefObject<HTMLDivElement>;
  hasMore?: boolean;
  fetchMore: () => void;
}) {
  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (scrollHeight - scrollTop === clientHeight && hasMore) {
          fetchMore();
        }
      }
    },
    [fetchMore, hasMore]
  );

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached, tableContainerRef]);

  return { fetchMoreOnBottomReached };
}
