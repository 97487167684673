import React from "react";

import { Alert, Text, styled } from "@puzzle/ui";
import { ExternalInline } from "@puzzle/icons";

import Link from "components/common/Link";

const StyledExternalInline = styled(ExternalInline, { margin: "0 $0h" });

export const HELP_LINK =
  "https://puzzlefin.notion.site/Importing-bank-and-credit-card-transactions-5c86c97b11744afbb4d8b32121e90386";

export const InvalidImportMessage = () => {
  return (
    <Alert kind="error">
      <Text size="bodyM">
        <Text weight="bold">We have detected an error in your uploaded file.</Text> Please include
        Date, Amount, and Description (in that order and in the{" "}
        <Link
          external
          target="_blank"
          href={HELP_LINK}
          css={{ textDecoration: "none !important", color: "inherit" }}
        >
          required format
          <StyledExternalInline />
        </Link>
        ) with no additional fields, no empty cells, and no total rows. Please modify and re-upload
        the CSV.
      </Text>
    </Alert>
  );
};
