import { ageInDays, parseAbsolute, today } from "@puzzle/utils";
import {
  AccountWithIntegrationFragment,
  IngressType,
  IntegrationConnectionStatus,
  IntegrationType,
} from "graphql/types";

export const getIntegrationTypeForAccount = (
  account: AccountWithIntegrationFragment
): IntegrationType => {
  const { financialInstitution } = account;
  let integrationType;

  // Try to use the type from the integration connection
  const integrationConnection = account.connection;
  integrationType = integrationConnection?.type;

  // But if that doesn't work, get it from the financial institution name
  if (!integrationType && financialInstitution?.name) {
    integrationType = Object.values(IntegrationType).find(
      (integrationType) => integrationType.toLowerCase() === financialInstitution.name.toLowerCase()
    );
  }

  // If there's still not a type, guess based on account type
  if (!integrationType) {
    if (account.manuallyAdded) {
      integrationType = IntegrationType.DirectIngest;
    } else {
      integrationType = IntegrationType.Plaid;
    }
  }

  return integrationType;
};

export const getConnectionStatusForAccount = (account: AccountWithIntegrationFragment) => {
  const connection = account.connection;

  if (!connection) {
    // Currently Gateway only returns connected integration connections for accounts
    return IntegrationConnectionStatus.Disconnected;
  }

  const hasDisconnection = [account.status, connection.status].some(
    (status) => status === IntegrationConnectionStatus.Disconnected
  );

  const hasError = [account.status, connection.status].some(
    (status) => status === IntegrationConnectionStatus.Error
  );

  if (hasDisconnection) {
    return IntegrationConnectionStatus.Disconnected;
  }
  if (hasError) {
    return IntegrationConnectionStatus.Error;
  }
  return IntegrationConnectionStatus.Ok;
};

// Since we can't control when push integrations will send us data, we should show a warning
// if we haven't received data in the last 30 days from a push integration
export const hasIndeterminateConnection = (
  connection:
    | {
        ingressType: IngressType;
        status: IntegrationConnectionStatus;
        lastSyncedAt?: string | null;
      }
    | null
    | undefined,
  timeZone: string
) => {
  if (
    connection?.ingressType === IngressType.Push &&
    connection?.status === IntegrationConnectionStatus.Ok &&
    connection?.lastSyncedAt
  ) {
    const lastSyncDate = parseAbsolute(connection.lastSyncedAt, timeZone);
    const daysSinceSync = ageInDays(lastSyncDate, today(timeZone), timeZone);

    return daysSinceSync > 30;
  }

  return false;
};

export const getIntegratedAccount = (accounts: AccountWithIntegrationFragment[]) => {
  const possibleAccounts = accounts.filter((account) => account.manuallyAdded === false);
  
  // sort first by connection status, then by last synced date
  possibleAccounts.sort((a, b) => {
    if (!a.connection) return 1;
    if (!b.connection) return -1;

    if (
      a.connection.status === IntegrationConnectionStatus.Ok &&
      b.connection.status !== IntegrationConnectionStatus.Ok
    ) {
      return -1;
    }
    if (
      a.connection.status !== IntegrationConnectionStatus.Ok &&
      b.connection.status === IntegrationConnectionStatus.Ok
    ) {
      return 1;
    }

    const aLastSynced = a.connection.lastSyncedAt
      ? new Date(a.connection.lastSyncedAt).getTime()
      : 0;
    const bLastSynced = b.connection.lastSyncedAt
      ? new Date(b.connection.lastSyncedAt).getTime()
      : 0;
    return bLastSynced - aLastSynced;
  });
  return possibleAccounts.length > 0 ? possibleAccounts[0] : undefined;
};
