import React from "react";
import Icon, { IconProps } from "../Icon";

export default function Meow({
  viewBox = "0 0 48 48",
  size = 48,
  ...iconProps
}: IconProps) {
  return (
    <Icon size={size} {...iconProps} viewBox={viewBox}>
      <circle cx="24" cy="24" r="24" fill="white" />
      <path d="M37.7951 8.50029C37.5089 8.56519 30.9054 10.0751 23.9995 12.6689C17.0938 10.075 10.4907 8.56519 10.2048 8.50029L8 8V25.7046C8 26.936 8.17504 28.1321 8.5042 29.2728C8.6713 29.8507 8.87755 30.4145 9.12048 30.9617C11.4695 36.2503 17.2536 40 23.9995 40C31.7453 40 38.2228 35.0571 39.6893 28.5169C39.7363 28.3055 39.7785 28.093 39.8153 27.879C39.8966 27.406 39.9509 26.9258 39.9785 26.4393C39.9927 26.1957 40 8 40 8L37.7951 8.50029ZM36.3712 12.6926V19.2123C34.6109 17.7077 32.2067 16.2443 29.1406 14.8118C32.0243 13.8673 34.6323 13.1451 36.3712 12.6926ZM23.9995 36.2669C17.1781 36.2664 11.6287 31.5284 11.6287 25.7046C11.6287 24.4371 12.8497 21.0481 23.9995 16.6545C35.1496 21.0482 36.3712 24.4372 36.3712 25.7046C36.3712 26.0688 36.3499 26.4283 36.3068 26.783C36.2439 27.3152 36.1336 27.8356 35.9811 28.341C34.6054 32.8932 29.7551 36.2669 23.9995 36.2669ZM11.6286 12.6926C13.3673 13.1451 15.9756 13.8673 18.8594 14.8118C15.7932 16.2443 13.3888 17.7077 11.6286 19.2123V12.6926Z" fill="#FF6531" />
    </Icon>
  );
}