import React from "react";
import { IconProps } from "./Icon";

export default function Pointer({ viewBox = "0 0 13 17", width = 13, height = 17 }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
    >
      <path
        fill="#50FAAB"
        d="M12.684 8.428L8.928 9.553a.44.44 0 00-.247.667l3.017 4.71a.468.468 0 01-.123.624L9.482 16.93a.452.452 0 01-.616-.125l-3.016-4.71a.43.43 0 00-.698-.041l-2.565 3c-.246.292-.718.146-.78-.229L.004.51C-.038.135.352-.136.68.072l12.106 7.543c.328.209.267.709-.102.813z"
      ></path>
    </svg>
  );
}
