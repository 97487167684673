import React from "react";
import { colors } from "@puzzle/theme";
import Icon, { IconProps } from "./Icon";

export default function SemiConfirmed({
  className,
  rotate,
  size = 18,
  viewBox = "0 0 18 18",
  color = colors.greenalpha,
  ...props
}: IconProps) {
  return (
    <Icon className={className} rotate={rotate} viewBox={viewBox} size={size} {...props}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M16.932 10.045l.496.065.496.064a9.073 9.073 0 000-2.348l-.496.064-.496.065a8.077 8.077 0 010 2.09zm-.077-4.299l-.462.192a7.993 7.993 0 00-1.046-1.808l.397-.305.396-.305a8.993 8.993 0 011.177 2.035l-.462.191zm-2.68-3.49l-.305.397a7.992 7.992 0 00-1.808-1.046l.192-.462.191-.462c.733.304 1.416.701 2.035 1.177l-.305.396zM10.109.572l-.064.496a8.076 8.076 0 00-2.09 0L7.89.572 7.826.076a9.075 9.075 0 012.348 0l-.064.496zm-4.363.573l.192.462c-.65.27-1.258.623-1.808 1.046l-.305-.397-.305-.396A8.992 8.992 0 015.555.683l.191.462zm-3.49 2.68l.397.305a7.992 7.992 0 00-1.046 1.808l-.462-.192-.462-.191c.304-.733.701-1.416 1.177-2.035l.396.305zM1 9c0-.354.023-.703.068-1.045L.572 7.89l-.496-.064a9.075 9.075 0 000 2.348l.496-.064.496-.065A8.076 8.076 0 011 9zm.145 3.254l.462-.192c.27.65.623 1.258 1.046 1.808l-.397.305-.396.305a8.992 8.992 0 01-1.177-2.035l.462-.191zm2.68 3.49l.305-.397c.55.423 1.157.776 1.808 1.046l-.192.462-.191.462A8.993 8.993 0 013.52 16.14l.305-.396zm4.065 1.684l.065-.496a8.077 8.077 0 002.09 0l.065.496.064.496a9.073 9.073 0 01-2.348 0l.064-.496zm4.364-.573l-.192-.462a7.993 7.993 0 001.808-1.046l.305.397.305.396a8.993 8.993 0 01-2.035 1.177l-.191-.462zm3.49-2.68l-.397-.305a7.993 7.993 0 001.046-1.808l.462.192.462.191a8.993 8.993 0 01-1.177 2.035l-.396-.305zm-8.08-3.786l4.204-4.202a.668.668 0 01.944.944l-4.676 4.674a.668.668 0 01-.944 0L5.188 9.802a.667.667 0 01.944-.944l1.532 1.53z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
}
