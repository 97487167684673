import React from "react";
import useRamp from "./useRamp";
import { IntegrationsListItemBaseProps } from "../List";
import { IntegrationType } from "graphql/types";
import RampLogo from "./RampLogo";
import { IntegrationDisplayComponent } from "../shared/types";

interface RampListItemProps {
  DisplayComponent: IntegrationDisplayComponent<
    React.PropsWithChildren<IntegrationsListItemBaseProps>
  >;
  companyId: string;
}
export const TITLE = "Ramp";
export const SUBTITLE = "Credit Card";

const RampListItem = ({ DisplayComponent, companyId }: RampListItemProps) => {
  const { onClickConnect, connection, loading } = useRamp({
    companyId,
  });

  return (
    <DisplayComponent
      integrationType={IntegrationType.Ramp}
      connectIntegration={onClickConnect}
      title={TITLE}
      // subtitle={SUBTITLE}
      connection={connection}
      logo={<RampLogo />}
      loading={loading}
    />
  );
};

export default RampListItem;
