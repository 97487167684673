import React from "react";

import { VendorFragment, CustomerFragment } from "graphql/types";
import { Button } from "@puzzle/ui";

import {
  useExtraTransactionState,
  useUpdateCustomer,
  useUpdateVendor,
} from "components/dashboard/Transactions/hooks/useSingleTransaction";
import CustomerSelect from "components/dashboard/Transactions/UpdateField/CustomerSelect";
import { VendorSelect, noVendor } from "../vendors";
import { BasicTransactionFragment } from "components/dashboard/Transactions/graphql.generated";
import { Box, S } from "ve";

export const VendorCell = ({
  transaction,
  vendor,
  customer,
  isRevenueTransaction,
}: {
  transaction: BasicTransactionFragment;
  vendor?: VendorFragment | null;
  customer?: CustomerFragment | null;
  isRevenueTransaction: boolean;
}) => {
  const { canEdit } = useExtraTransactionState(transaction);
  const updateCustomer = useUpdateCustomer(transaction);
  const updateVendor = useUpdateVendor(transaction);

  const vendorLabel = vendor?.name ?? noVendor.name;
  const customerLabel = customer?.name ?? "No customer";

  if (!canEdit) {
    return (
      <Box css={{ marginLeft: S["1"] }}>{isRevenueTransaction ? customerLabel : vendorLabel}</Box>
    );
  }

  if (isRevenueTransaction) {
    return (
      <CustomerSelect
        key="customer-select"
        value={customer}
        onSelect={updateCustomer}
        onCreateCustomer={updateCustomer}
        customTrigger={
          <Button size="compact" variant="minimal" css={{ padding: "$0 $1" }}>
            {customerLabel}
          </Button>
        }
      />
    );
  }
  return (
    <VendorSelect
      key="vendor-select"
      value={vendor}
      onSelect={updateVendor}
      onCreate={updateVendor}
      customTrigger={
        <Button
          size="compact"
          variant="minimal"
          css={{
            padding: "$0 $1",
            "&:focus": { backgroundColor: "transparent", color: "$gray300" },
          }}
        >
          {vendorLabel}
        </Button>
      }
    />
  );
};
