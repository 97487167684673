import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Calendar({
  viewBox = "0 0 16 16",
  color = "currentColor",
  fill = color,
  width = 16,
  height = 16,
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} {...iconProps} viewBox={viewBox}>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M14 2h-2V1h-1v1H5V1H4v1H2c-.55 0-1 .487-1 1.087v10.825c0 .6.45 1.088 1 1.088h12c.55 0 1-.488 1-1.088V3.088C15 2.488 14.55 2 14 2zm0 11.912a.138.138 0 01-.025.088H2.025A.137.137 0 012 13.912V5h12v8.912zM5.5 8.5H4V7h1.5v1.5zm0 3.5H4v-1.5h1.5V12zm3.25-3.5h-1.5V7h1.5v1.5zm0 3.5h-1.5v-1.5h1.5V12zM12 12h-1.5v-1.5H12V12zm0-3.5h-1.5V7H12v1.5z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
}
