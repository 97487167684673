import React from "react";
import * as Collapsible from "@radix-ui/react-collapsible";

import { contentRecipe } from "./collapseStyles.css";

export const Collapse = ({
  children,
  trigger,
  animate = true,
  visibleOverflow = false,
  ...props
}: Collapsible.CollapsibleProps & {
  trigger?: React.ReactElement;
  animate?: boolean;
  visibleOverflow?: boolean;
}) => {
  const contentClass = contentRecipe({ animate, overflow: visibleOverflow ? "visible" : "clip" });

  return (
    <Collapsible.Root {...props}>
      {trigger && <Collapsible.Trigger asChild>{trigger}</Collapsible.Trigger>}

      <Collapsible.Content className={contentClass}>{children}</Collapsible.Content>
    </Collapsible.Root>
  );
};
