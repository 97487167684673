import { Button } from "@puzzle/ui";
import React, { useState, useMemo } from "react";
import { OpeningBalanceFeedItemData } from "../shared";
import { LoadingPane } from "./LoadingPane";
import { ErrorPane } from "./ErrorPane";
import { MiniPane } from "./MiniPane";
import { formatAccountNameWithInstitution, formatMoney } from "@puzzle/utils";
import { useCompanyDateFormatter } from "components/companies";
import OpeningBalanceModal from "components/dashboard/Accounting/Reconciliation/OpeningBalanceModal";
import { useInboxContext } from "../InboxContext";
import { FeedItem } from "@knocklabs/client";
import Analytics from "lib/analytics";
import { useActiveCompany } from "components/companies";
import { AccountType, useAccountsQuery } from "graphql/types";
import { useChartOfAccounts } from "components/dashboard/Accounting/shared/useChartOfAccounts";

const useAccounts = ({
  companyId,
  accountTypes,
}: {
  companyId: string;
  accountTypes: AccountType[];
}) => {
  const { data, loading, refetch } = useAccountsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      filter: {
        companyId,
        types: accountTypes,
      },
    },
  });
  return useMemo(() => ({ accounts: data?.accounts, loading, refetch }), [data, loading, refetch]);
};

export const OpeningBalancePane = ({ item }: { item: FeedItem<OpeningBalanceFeedItemData> }) => {
  const { feedClient, setActiveItem } = useInboxContext();
  const { company } = useActiveCompany<true>();
  const accountTypes = [AccountType.Depository, AccountType.Investment, AccountType.Credit];
  const [modalOpen, setModalOpen] = useState(false);
  const data = item.data!;
  const { accounts, loading, refetch } = useAccounts({
    companyId: company.id,
    accountTypes,
  });
  const { data: chartOfAccounts } = useChartOfAccounts();
  const account = useMemo(() => accounts?.find((a) => a.id === data.accountId), [data, accounts]);
  const ledgerAccountId = useMemo(
    () =>
      account
        ? chartOfAccounts?.company?.chartOfAccounts.find((a) =>
            a.displayName.includes(account.financialInstitution.name)
          )?.id
        : "",
    [chartOfAccounts, account]
  );
  const monthFormatter = useCompanyDateFormatter({
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  if (loading) {
    return <LoadingPane />;
  }

  if (!account) {
    return <ErrorPane />;
  }

  const { initialAccountBalance } = account;

  return (
    <>
      <MiniPane
        title="Review opening balance"
        item={item}
        data={[
          {
            label: "Account",
            value: formatAccountNameWithInstitution(account),
          },
          {
            label: `Opening balance for ${monthFormatter.format(new Date(initialAccountBalance.date))}`,
            value: formatMoney({ currency: "USD", amount: initialAccountBalance.balance }),
          },
        ]}
        actions={
          <>
            <Button
              size="mini"
              onClick={() => {
                feedClient?.markAsRead(item);
                Analytics.inboxItemStatusChanged({ id: item.id, action: "read" });
                Analytics.openingBalanceInboxTaskActioned({ id: item.id, action: "confirm" });
              }}
            >
              Confirm
            </Button>
            <Button
              variant="secondary"
              size="mini"
              onClick={() => {
                Analytics.openingBalanceInboxTaskActioned({ id: item.id, action: "revise" });
                setModalOpen(true);
              }}
            >
              Revise
            </Button>
          </>
        }
      />

      <OpeningBalanceModal
        initialAmount={initialAccountBalance.balance}
        account={account}
        open={modalOpen}
        onOpenChange={setModalOpen}
        refetch={refetch}
        companyId={company.id}
        ledgerAccountId={ledgerAccountId ?? ""}
        onSuccess={() => {
          feedClient?.markAsRead(item);
          Analytics.inboxItemStatusChanged({ id: item.id, action: "read" });
          setActiveItem({
            ...item,
            read_at: new Date().toISOString(),
          });
        }}
      />
    </>
  );
};
