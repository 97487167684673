import { MembershipStatus } from "graphql/types";
import { useMemo } from "react";
import { useGetTransactionsPageUsersQuery } from "../graphql.generated";

export default function useTransactionPageUsers({ companyId }: { companyId: string }) {
  const { data, loading } = useGetTransactionsPageUsersQuery({
    variables: { companyId },
  });

  const activeUsers = useMemo(() => {
    const activeMemberships =
      data?.company?.memberships.filter((m) => m.status === MembershipStatus.Active) || [];
    return activeMemberships?.map((u) => u.user);
  }, [data]);

  return {
    activeUsers,
    loading,
  };
}
