import React from "react";
import { noop } from "lodash";
import { Dialog } from "@puzzle/ui";
import { ConnectModalProps } from "components/intro/components/ConnectModal";
import Wizard from "components/common/Wizard";
import { ConnectIntegrationStep } from "./ConnectIntegrationStep";
import {
  ConnectManualAccountFormStep,
  manualAccountFormValues,
} from "./ConnectManualAccountFormStep";
import { ConnectManualAccountPreviewStep } from "./ConnectManualAccountPreviewStep";

type ConnectBankAndCreditCardsModalProps = ConnectModalProps & {
  // This is specifically for manual accounts. While a wizard is being used, it is possible to only complete the first step.
  // Make this clearer?
  onSave: () => void;
};

export const ConnectBankAndCreditCardsModal = ({
  onSave,
  onOpenChange = noop,
  ...rest
}: ConnectBankAndCreditCardsModalProps) => {
  return (
    <Dialog
      {...rest}
      width={520}
      onOpenChange={(open: boolean) => {
        if (!open) {
          manualAccountFormValues({});
        }
        onOpenChange(open);
      }}
    >
      <Wizard
        steps={[
          {
            label: "Integration",
            name: "integration",
            component: ConnectIntegrationStep,
          },
          {
            label: "Manual Account Form",
            name: "manual-account-form",
            component: ConnectManualAccountFormStep,
          },
          {
            label: "Manual Account Preview",
            name: "manual-account-preview",
            component: ConnectManualAccountPreviewStep,
          },
        ]}
        onComplete={async () => onSave()}
        render={({ activeStep }) => {
          const Step = activeStep.component;
          return <Step />;
        }}
      />
    </Dialog>
  );
};
