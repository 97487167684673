import { useMemo } from "react";
import { ClassFilter } from "../TransactionsProvider";
import useClassifications from "components/common/hooks/useClassifications";
import {
  AvailableClassSegmentFragment,
  AvailableReportingClassFragment,
} from "components/common/hooks/graphql.generated";

export type ClassFilterOption = {
  id: string;
  name: string;
  parentName: string;
  parentId: string;
  isExclusionClass: boolean;
};

export const formatClassificationsForMenu = (
  sortedClassifications: AvailableReportingClassFragment[]
) => {
  if (sortedClassifications.length <= 0) return [];
  const result: ClassFilterOption[] = [];

  const handleGroup = (
    availableSegmentValues: AvailableClassSegmentFragment[],
    parentId: string,
    parentName: string
  ) => {
    availableSegmentValues.forEach((segment) =>
      result.push({
        id: segment.id,
        name: segment.name,
        parentName,
        parentId,
        isExclusionClass: false,
      })
    );
    result.push({
      id: parentId,
      name: `No ${parentName}`,
      parentName,
      parentId,
      isExclusionClass: true,
    });
  };

  sortedClassifications.forEach((reportingClass) => {
    handleGroup(reportingClass.availableValues, reportingClass.id, reportingClass.name);
  });

  return result;
};

export function useClassificationFilters(classesFilter: ClassFilter) {
  const { sortedClassifications } = useClassifications({});

  const classificationOptions = useMemo(() => {
    return formatClassificationsForMenu(sortedClassifications);
  }, [sortedClassifications]);

  const selectedClassFilters = classificationOptions.filter((option) => {
    return (
      classesFilter.withSomeOfClassSegmentIds.includes(option.id) ||
      classesFilter.withNoneOfClassIds.includes(option.id)
    );
  });

  const removeClassFilterFormatter = (classId: string) => {
    return {
      classesFilter: {
        withSomeOfClassSegmentIds: classesFilter?.withSomeOfClassSegmentIds?.filter(
          (id) => id !== classId
        ),
        withNoneOfClassIds: classesFilter?.withNoneOfClassIds?.filter((id) => id !== classId),
      },
    };
  };

  return { classificationOptions, selectedClassFilters, removeClassFilterFormatter };
}
