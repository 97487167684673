import { FeedItem } from "@knocklabs/client";
import { DetailPane } from "components/dashboard/Transactions/DetailDrawer";
import React from "react";
import { TransactionFeedItemData } from "../shared";
import { CommentBoxMode } from "components/dashboard/Transactions/DetailDrawer/DetailPaneContext";

export const TransactionPane = ({
  commentBoxMode,
  id,
  item,
}: {
  id: string;
  item: FeedItem<TransactionFeedItemData>;
  commentBoxMode: CommentBoxMode;
}) => {
  // TODO optimistically call markAsRead when complete/unassign or "request review" are used
  // (unless the backend updates quickly enough)
  return <DetailPane id={id} commentBoxMode={commentBoxMode} />;
};
