import React from "react";
import Icon, { IconProps } from "../Icon";

export default function BillDotCom({
  viewBox = "0 0 869 530",
  width = 48,
  height = 48,
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} {...iconProps} viewBox={viewBox}>
      <path
        d="M251.766 188.771V102.91L335.643 167.818C364.69 190.591 370.2 223.74 369.519 222.911C350.608 199.415 325.352 189.104 307.965 188.828L251.766 188.771Z"
        fill="#FF9C6C"
      />
      <path d="M477.761 207.301H415V434.393H477.761V207.301Z" fill="#FF5A0A" />
      <path d="M587.792 100.09H525.031V434.394H587.792V100.09Z" fill="#FF5A0A" />
      <path d="M697.778 100.09H635.018V434.394H697.778V100.09Z" fill="#FF5A0A" />
      <path
        d="M306.704 355.941C306.704 366.057 297.929 374.36 288.009 374.36H234.441V266.27H288.009C297.991 266.27 306.704 274.476 306.704 284.688V355.941ZM234.859 207.374V100.227H172.415V434.361H303.09C339.852 434.361 369.635 403.089 369.635 364.489V277.246C369.635 238.646 339.852 207.374 303.09 207.374H234.859Z"
        fill="#FF5A0A"
      />
      <circle cx="446" cy="134" r="41" fill="#FF5A0A" />
    </Icon>
  );
}
