import React, { useState, useCallback, useMemo } from "react";
import { styled, Popover, IconButton, Tabs, PopoverProps } from "@puzzle/ui";
import { Settings } from "@puzzle/icons";
import useAppRouter from "lib/useAppRouter";
import Analytics from "lib/analytics";

import { useInboxStore } from "../InboxContext";
import UpdatesList from "./UpdatesList";
import TaskList from "./TaskList";
import { TABS } from "../shared";

const NotificationsWrapper = styled("div", {
  position: "relative",

  defaultVariants: {
    minimized: false,
  },

  variants: {
    minimized: {
      true: {
        paddingBottom: "$1h",
      },
    },
  },
});

const PopoverTitle = styled(Popover.Title, {
  defaultVariants: {
    taskPopover: true,
  },

  variants: {
    taskPopover: {
      true: {
        padding: "$3 $2 $2",
        color: "$gray300",
        fontSize: "$headingM",
        lineHeight: "$headingM",
        fontWeight: "$headingM",
        background: "$rhino700",
      },
    },
  },
});

const PopoverBody = styled(Popover.Body, {
  width: 400,
  background: "$rhino700",
  padding: 0,
});

const TabContent = styled("div", {
  maxHeight: 450,

  overflow: "auto",
  padding: "0 $2 $2 $2",
});

const CountBadge = styled("span", {
  background: "#F8F8FA14",
  borderRadius: "6px",
  marginLeft: "10px",
  padding: "2px $0h 2px $0h",
  textVariant: "$bodyXS",
  lineHeight: "14px",
});

const TabContentMap = {
  updates: <UpdatesList />,
  tasks: <TaskList />,
  archived: <TaskList archived />,
};

export const InboxPopover = ({
  children,
  minimized = false,
  onOpenChange,
}: React.PropsWithChildren<
  {
    minimized: boolean;
  } & Pick<PopoverProps, "onOpenChange">
>) => {
  const { goToNotificationSettings } = useAppRouter();

  const [open, setOpen] = useState(false);
  const [activeTabValue, setActiveTabValue] = useState<(typeof TABS)[number]["value"]>(
    TABS[0].value
  );
  const activeTab = useMemo(() => TABS.find((t) => t.value === activeTabValue)!, [activeTabValue]);

  const onTabChange = useCallback((value: typeof activeTabValue) => {
    Analytics.notificationTabClicked({ tab: value });
    setActiveTabValue(value);
  }, []);

  const { unseen_count: tasksCount } = useInboxStore("tasks", (state) => state.metadata);
  const { unseen_count: updatesCount } = useInboxStore("updates", (state) => state.metadata);
  const counts = useMemo(
    () => ({
      tasks: tasksCount,
      updates: updatesCount,
      archived: 0,
    }),
    [tasksCount, updatesCount]
  );

  const unreadCount = tasksCount + updatesCount;

  const tabsWithCounts = useMemo(
    () =>
      TABS.map((tab) => {
        const count = counts[tab.value];

        return {
          ...tab,
          title: (
            <>
              {tab.title} {count > 0 && <CountBadge>{count}</CountBadge>}
            </>
          ),
        };
      }),
    [counts]
  );

  return (
    <NotificationsWrapper minimized={minimized}>
      <Popover
        side="right"
        align="start"
        arrow={false}
        open={open}
        modal
        onOpenChange={(open) => {
          if (open) {
            Analytics.notificationsViewed({
              badgeCount: unreadCount,
            });
          }
          setOpen(open);
          onOpenChange?.(open);
        }}
        alignOffset={-16}
        sideOffset={32}
        trigger={children}
      >
        <PopoverTitle>Notifications</PopoverTitle>
        <PopoverBody>
          <Tabs
            css={{ px: "$2" }}
            items={tabsWithCounts}
            onValueChange={(value) => onTabChange(value as typeof activeTabValue)}
            value={activeTabValue}
            actions={
              <IconButton
                onClick={() => {
                  Analytics.notificationSettingsClicked({
                    location: "notification-popover",
                  });
                  setOpen(false);
                  goToNotificationSettings();
                }}
              >
                <Settings />
              </IconButton>
            }
          />
          <TabContent
            css={{
              a: { color: "$gray300" },
            }}
          >
            {TabContentMap[activeTab.value]}
          </TabContent>
        </PopoverBody>
      </Popover>
    </NotificationsWrapper>
  );
};
