import React, { useState } from "react";
import { Text, Button } from "@puzzle/ui";
import { Box, S, color, vars } from "ve";

const OpenAIShareInfoContent = [
  <>
    <Text>Puzzle categorizes transactions on your behalf by sharing:</Text>
    <ul>
      <li>User-created responses explaining transactions in plain language</li>
      <li>Transaction descriptions</li>
      <li>Transaction amounts</li>
      <li>Vendor names</li>
      <li>Chart of accounts / categories</li>
    </ul>
    <Text>Data is only shared when a user requests AI support for categorization.</Text>
  </>,

  <>
    <Text>
      Puzzle provides context about your transactions in plain language (including category
      suggestions) by sharing:
    </Text>
    <ul>
      <li>Transaction descriptions</li>
      <li>Transaction amounts</li>
      <li>Vendor names</li>
      <li>Chart of accounts / categories</li>
    </ul>
  </>,

  <>
    <Text>Puzzle shares:</Text>
    <ul>
      <li>Monthly financial statements</li>
      <li>Monthly spending reports by vendor</li>
      <li>
        Monthly metrics, including Burn, Runway, Cash, MRR/ARR, and Payroll vs Non-Payroll Spending
      </li>
      <li>For significant transactions: vendors (if applicable), descriptions, amounts, dates</li>
    </ul>
  </>,
];

export const OpenAIShareInfo = () => {
  const [active, setActive] = useState(0);

  return (
    <Box
      css={{
        marginTop: S["2"],
        padding: S["2"],
        border: `1px solid ${color.gray600}`,
        borderRadius: vars.radii[2],
      }}
    >
      <Box css={{ display: "flex", flexDirection: "row", gap: S["1"], marginBottom: S["2"] }}>
        <Button onClick={() => setActive(0)} variant="secondary" css={{ borderRadius: "100px" }}>
          AI Categorizer
        </Button>
        <Button onClick={() => setActive(1)} variant="secondary" css={{ borderRadius: "100px" }}>
          AI Lookup
        </Button>
        <Button onClick={() => setActive(2)} variant="secondary" css={{ borderRadius: "100px" }}>
          AI Insights
        </Button>
      </Box>
      {OpenAIShareInfoContent[active]}
    </Box>
  );
};
