import * as Types from '../../../../graphql/types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
import { CategoryFragmentDoc } from '../../../../graphql/fragments/category.generated';
import { TransactionPageAccountFragmentDoc } from '../../Transactions/graphql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetReconciliationOptionsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
}>;


export type GetReconciliationOptionsQuery = { __typename?: 'Query', categories: Array<{ __typename: 'LedgerCategory', defaultCashlike: Types.Cashlike, accountType?: Types.LedgerAccountType | null, views?: Array<string | null> | null, name: string, permaKey: string, examples?: string | null, description?: string | null, coaDisplayId?: string | null, coaKey: string, deprecated?: boolean | null, ruleType?: string | null, displayName: string, displayId: string, shortName: string, companyId: string, id?: string | null }>, balanceCategories: Array<{ __typename: 'LedgerCategory', defaultCashlike: Types.Cashlike, accountType?: Types.LedgerAccountType | null, views?: Array<string | null> | null, name: string, permaKey: string, examples?: string | null, description?: string | null, coaDisplayId?: string | null, coaKey: string, deprecated?: boolean | null, ruleType?: string | null, displayName: string, displayId: string, shortName: string, companyId: string, id?: string | null }> };

export type UpsertInitialUserLedgerAccountBalanceMutationVariables = Types.Exact<{
  input: Types.UpsertInitialUserLedgerAccountBalanceInput;
}>;


export type UpsertInitialUserLedgerAccountBalanceMutation = { __typename?: 'Mutation', upsertInitialUserLedgerAccountBalance: { __typename?: 'UpsertInitialUserAccountBalanceResult', accountBalance?: { __typename?: 'AccountBalance', balance: string, date: CalendarDateTimeString } | null } };

export type GetStatementBalancesQueryVariables = Types.Exact<{
  input: Types.GetStatementBalancesInput;
}>;


export type GetStatementBalancesQuery = { __typename?: 'Query', statementBalances?: { __typename?: 'StatementBalances', startDate: CalendarDateString, endDate: CalendarDateString, startingBalance?: { __typename?: 'Money', amount: string, currency: any } | null, endingBalance?: { __typename?: 'Money', amount: string, currency: any } | null } | null };

export type ReconciliationsPageStatementBalancesFragment = { __typename?: 'StatementBalances', startDate: CalendarDateString, endDate: CalendarDateString, startingBalance?: { __typename?: 'Money', amount: string, currency: any } | null, endingBalance?: { __typename?: 'Money', amount: string, currency: any } | null };

export type CreateManualCreditCardOffsetTransactionMutationVariables = Types.Exact<{
  input: Types.ManualCreditCardOffsetTransactionInput;
}>;


export type CreateManualCreditCardOffsetTransactionMutation = { __typename?: 'Mutation', createManualCreditCardOffsetTransaction: { __typename?: 'TransactionResult', transaction: { __typename?: 'UserTransaction', id: string, account: { __typename?: 'Account', id: string, name: string, type: Types.AccountType, subType?: Types.AccountSubType | null, mask?: string | null, financialInstitution: { __typename?: 'FinancialInstitution', id: string, name: string } } } } };

export const ReconciliationsPageStatementBalancesFragmentDoc = gql`
    fragment reconciliationsPageStatementBalances on StatementBalances {
  startDate
  endDate
  startingBalance {
    amount
    currency
  }
  endingBalance {
    amount
    currency
  }
}
    `;
export const GetReconciliationOptionsDocument = gql`
    query getReconciliationOptions($companyId: ID!) {
  categories(input: {companyId: $companyId, context: RECONCILIATION}) {
    ...category
  }
  balanceCategories: categories(
    input: {companyId: $companyId, context: OPENING_BALANCE}
  ) {
    ...category
  }
}
    ${CategoryFragmentDoc}`;

/**
 * __useGetReconciliationOptionsQuery__
 *
 * To run a query within a React component, call `useGetReconciliationOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReconciliationOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReconciliationOptionsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetReconciliationOptionsQuery(baseOptions: Apollo.QueryHookOptions<GetReconciliationOptionsQuery, GetReconciliationOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReconciliationOptionsQuery, GetReconciliationOptionsQueryVariables>(GetReconciliationOptionsDocument, options);
      }
export function useGetReconciliationOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReconciliationOptionsQuery, GetReconciliationOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReconciliationOptionsQuery, GetReconciliationOptionsQueryVariables>(GetReconciliationOptionsDocument, options);
        }
export type GetReconciliationOptionsQueryHookResult = ReturnType<typeof useGetReconciliationOptionsQuery>;
export type GetReconciliationOptionsLazyQueryHookResult = ReturnType<typeof useGetReconciliationOptionsLazyQuery>;
export type GetReconciliationOptionsQueryResult = Apollo.QueryResult<GetReconciliationOptionsQuery, GetReconciliationOptionsQueryVariables>;
export const UpsertInitialUserLedgerAccountBalanceDocument = gql`
    mutation upsertInitialUserLedgerAccountBalance($input: UpsertInitialUserLedgerAccountBalanceInput!) {
  upsertInitialUserLedgerAccountBalance(input: $input) {
    accountBalance {
      balance
      date
    }
  }
}
    `;
export type UpsertInitialUserLedgerAccountBalanceMutationFn = Apollo.MutationFunction<UpsertInitialUserLedgerAccountBalanceMutation, UpsertInitialUserLedgerAccountBalanceMutationVariables>;

/**
 * __useUpsertInitialUserLedgerAccountBalanceMutation__
 *
 * To run a mutation, you first call `useUpsertInitialUserLedgerAccountBalanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertInitialUserLedgerAccountBalanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertInitialUserLedgerAccountBalanceMutation, { data, loading, error }] = useUpsertInitialUserLedgerAccountBalanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertInitialUserLedgerAccountBalanceMutation(baseOptions?: Apollo.MutationHookOptions<UpsertInitialUserLedgerAccountBalanceMutation, UpsertInitialUserLedgerAccountBalanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertInitialUserLedgerAccountBalanceMutation, UpsertInitialUserLedgerAccountBalanceMutationVariables>(UpsertInitialUserLedgerAccountBalanceDocument, options);
      }
export type UpsertInitialUserLedgerAccountBalanceMutationHookResult = ReturnType<typeof useUpsertInitialUserLedgerAccountBalanceMutation>;
export type UpsertInitialUserLedgerAccountBalanceMutationResult = Apollo.MutationResult<UpsertInitialUserLedgerAccountBalanceMutation>;
export type UpsertInitialUserLedgerAccountBalanceMutationOptions = Apollo.BaseMutationOptions<UpsertInitialUserLedgerAccountBalanceMutation, UpsertInitialUserLedgerAccountBalanceMutationVariables>;
export const GetStatementBalancesDocument = gql`
    query getStatementBalances($input: GetStatementBalancesInput!) {
  statementBalances(input: $input) {
    ...reconciliationsPageStatementBalances
  }
}
    ${ReconciliationsPageStatementBalancesFragmentDoc}`;

/**
 * __useGetStatementBalancesQuery__
 *
 * To run a query within a React component, call `useGetStatementBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatementBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatementBalancesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetStatementBalancesQuery(baseOptions: Apollo.QueryHookOptions<GetStatementBalancesQuery, GetStatementBalancesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStatementBalancesQuery, GetStatementBalancesQueryVariables>(GetStatementBalancesDocument, options);
      }
export function useGetStatementBalancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatementBalancesQuery, GetStatementBalancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStatementBalancesQuery, GetStatementBalancesQueryVariables>(GetStatementBalancesDocument, options);
        }
export type GetStatementBalancesQueryHookResult = ReturnType<typeof useGetStatementBalancesQuery>;
export type GetStatementBalancesLazyQueryHookResult = ReturnType<typeof useGetStatementBalancesLazyQuery>;
export type GetStatementBalancesQueryResult = Apollo.QueryResult<GetStatementBalancesQuery, GetStatementBalancesQueryVariables>;
export const CreateManualCreditCardOffsetTransactionDocument = gql`
    mutation createManualCreditCardOffsetTransaction($input: ManualCreditCardOffsetTransactionInput!) {
  createManualCreditCardOffsetTransaction(input: $input) {
    transaction {
      id
      account {
        ...transactionPageAccount
        financialInstitution {
          id
          name
        }
      }
    }
  }
}
    ${TransactionPageAccountFragmentDoc}`;
export type CreateManualCreditCardOffsetTransactionMutationFn = Apollo.MutationFunction<CreateManualCreditCardOffsetTransactionMutation, CreateManualCreditCardOffsetTransactionMutationVariables>;

/**
 * __useCreateManualCreditCardOffsetTransactionMutation__
 *
 * To run a mutation, you first call `useCreateManualCreditCardOffsetTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManualCreditCardOffsetTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManualCreditCardOffsetTransactionMutation, { data, loading, error }] = useCreateManualCreditCardOffsetTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateManualCreditCardOffsetTransactionMutation(baseOptions?: Apollo.MutationHookOptions<CreateManualCreditCardOffsetTransactionMutation, CreateManualCreditCardOffsetTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateManualCreditCardOffsetTransactionMutation, CreateManualCreditCardOffsetTransactionMutationVariables>(CreateManualCreditCardOffsetTransactionDocument, options);
      }
export type CreateManualCreditCardOffsetTransactionMutationHookResult = ReturnType<typeof useCreateManualCreditCardOffsetTransactionMutation>;
export type CreateManualCreditCardOffsetTransactionMutationResult = Apollo.MutationResult<CreateManualCreditCardOffsetTransactionMutation>;
export type CreateManualCreditCardOffsetTransactionMutationOptions = Apollo.BaseMutationOptions<CreateManualCreditCardOffsetTransactionMutation, CreateManualCreditCardOffsetTransactionMutationVariables>;