import React, { useCallback, useEffect, useState } from "react";
import { Button, Dialog, Field, Input, Select, TextArea, useDialogReset } from "@puzzle/ui";
import { Controller, useForm } from "react-hook-form";
import { validItemName } from "./utils";
import { capitalize } from "@puzzle/utils";
import useCreateClassification from "components/common/hooks/useCreateClassification";
import {
  AvailableClassSegmentFragment,
  AvailableReportingClassFragment,
} from "components/common/hooks/graphql.generated";
import { ReportingClassType } from "graphql/types";

type CreateClassificationModalProps = Pick<
  React.ComponentPropsWithoutRef<typeof Dialog>,
  "open" | "onOpenChange"
> & {
  classifications?: AvailableReportingClassFragment[] | AvailableClassSegmentFragment[];
  reportingClass: AvailableReportingClassFragment | null;
  reportingClassType: ReportingClassType;
  // These two properties are just for showing reportingClass dropdown
  allowReportingClassSelection?: boolean;
  allReportingClasses?: AvailableReportingClassFragment[];
};

const CreateClassificationModal = ({
  open,
  onOpenChange,
  classifications = [],
  reportingClass,
  reportingClassType,
  allowReportingClassSelection = false,
  allReportingClasses = [],
  ...props
}: CreateClassificationModalProps) => {
  const handleClose = useCallback(() => {
    onOpenChange?.(false);
  }, [onOpenChange]);

  const [activeReportingClass, setActiveReportingClass] = useState<string>(
    reportingClass?.id || ""
  );

  // This useEffect is needed b/c the modal doesn't re-render on prop change
  useEffect(() => {
    setActiveReportingClass(reportingClass?.id || null);
  }, [reportingClass]);

  const { onSubmit, loading, resetMutation } = useCreateClassification({
    handleClose,
    reportingClass:
      allReportingClasses.length === 0
        ? reportingClass
        : allReportingClasses.find((rc) => rc.id === activeReportingClass) || null,
  });

  const defaultFormValues = () => {
    return {
      name: "",
      description: "",
      reportingClass: reportingClass?.id || "",
    };
  };

  const {
    control,
    handleSubmit,
    formState,
    reset: resetForm,
  } = useForm<{
    name: string;
    description: string;
    reportingClass: string;
  }>({
    mode: "onChange",
    defaultValues: defaultFormValues(),
  });

  useDialogReset(open, () => {
    resetForm(defaultFormValues());
    resetMutation();
  });

  const displayName = () => {
    if (reportingClass && reportingClassType !== ReportingClassType.UserCreated) {
      return reportingClass.name;
    }
    if (allowReportingClassSelection) return "Class";
    return "Group";
  };

  const nameLabel = () => {
    if (reportingClassType !== ReportingClassType.UserCreated) {
      return "Name";
    }
    if (allowReportingClassSelection) {
      return "Class Name";
    } else {
      return "Group Name";
    }
  };

  const namePlaceholder = () => {
    if (allowReportingClassSelection) {
      return "e.g. Southwest, Enterprise";
    } else if (reportingClassType === ReportingClassType.UserCreated) {
      return "e.g. Region, Product";
    } else if (reportingClassType === ReportingClassType.Department) {
      return "e.g. Marketing, Engineering";
    } else if (reportingClassType === ReportingClassType.Location) {
      return "e.g. New York, San Francisco";
    }
  };

  const renderReportingClassDropdown = () => {
    if (!allowReportingClassSelection || reportingClassType !== ReportingClassType.UserCreated) {
      return null;
    }

    const reportingClassOptions = allReportingClasses.map((classification) => {
      return {
        value: classification.id,
        label: classification.name,
      };
    });
    reportingClassOptions.sort((a, b) => a.label.localeCompare(b.label));

    return (
      <Controller
        name="reportingClass"
        control={control}
        rules={{ required: true }}
        render={({ field }) => {
          return (
            <Field label="Group">
              <Select
                value={field.value}
                options={reportingClassOptions}
                placeholder="Select group"
                css={{ marginBottom: "$1h" }}
                menuCss={{ minWidth: "max-content", width: "400px" }}
                onSelectionChange={(val) => {
                  field.onChange({
                    target: {
                      value: val,
                      name: field.name,
                    },
                  });
                  setActiveReportingClass(val);
                }}
              ></Select>
            </Field>
          );
        }}
      />
    );
  };
  return (
    <Dialog size="small" open={open} onOpenChange={onOpenChange} {...props}>
      <Dialog.Title onClose={handleClose}>New {displayName()}</Dialog.Title>

      <Dialog.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="name"
            rules={{
              required: true,
              validate: (name) =>
                validItemName(name, classifications)
                  ? true
                  : `${capitalize(displayName())} names must be unique`,
            }}
            render={({ field: { onChange, value } }) => (
              <Field
                label={nameLabel()}
                error={formState.errors.name && formState.errors.name.message}
                css={{ marginBottom: "$1h" }}
              >
                <Input
                  data-testid="segment_name"
                  placeholder={namePlaceholder()}
                  id="name"
                  value={value}
                  onChange={(e) => {
                    onChange({ target: { value: e.target.value } });
                  }}
                />
              </Field>
            )}
          />
          <Controller
            control={control}
            name="description"
            render={({ field: { onChange, value } }) => {
              return (
                <Field label="Description">
                  <TextArea
                    data-testid="segment_description"
                    placeholder="Optional"
                    id="description"
                    value={value}
                    onChange={(e) => {
                      onChange({ target: { value: e.target.value } });
                    }}
                    css={{ marginBottom: "$1h" }}
                  />
                </Field>
              );
            }}
          />
          {renderReportingClassDropdown()}
        </form>
      </Dialog.Body>
      <Dialog.Footer css={{ justifyContent: "right" }}>
        <Button
          onClick={() => {
            handleClose();
          }}
          variant="secondary"
          css={{ marginRight: "$1" }}
        >
          Cancel
        </Button>
        <Button
          data-testid="submit_new_segment"
          onClick={handleSubmit(onSubmit)}
          disabled={!formState.isValid}
          loading={loading || formState.isSubmitting}
        >
          Save
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};

export default CreateClassificationModal;
