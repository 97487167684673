import { AlertDialog } from "@puzzle/ui";
import { lowerFirst } from "lodash";
import React from "react";

const RemoveAccountEpochConfirmationModal = ({
  integrationType,
  epochLabel,
  loading,
  onConfirm,
  ...props
}: {
  integrationType: string;
  epochLabel: string;
  loading: boolean;
  onConfirm: () => void;
} & React.ComponentProps<typeof AlertDialog>) => {
  return (
    <AlertDialog {...props} size="small">
      <AlertDialog.Title basic showClose>
        Are you sure you want to remove the restricted range?
      </AlertDialog.Title>
      <AlertDialog.Body>
        If you remove this restricted range, Puzzle will ingest any available data{" "}
        {lowerFirst(epochLabel)} from {integrationType}. Please confirm that the new data will not
        create duplication of existing data.
      </AlertDialog.Body>
      <AlertDialog.Footer>
        <AlertDialog.CancelButton onClick={onConfirm} loading={loading}>
          Confirm
        </AlertDialog.CancelButton>
        <AlertDialog.ConfirmButton>Do not change</AlertDialog.ConfirmButton>
      </AlertDialog.Footer>
    </AlertDialog>
  );
};

export default RemoveAccountEpochConfirmationModal;
