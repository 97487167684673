import { AlertDialog, Checkbox, Stack, Text, styled } from "@puzzle/ui";
import { AccountWithIntegrationFragment, IntegrationConnectionStatus } from "graphql/types";
import React, { Dispatch, SetStateAction, useCallback, useState } from "react";

const WarningList = styled("ul", {
  paddingLeft: "$2",
  margin: "0 0 $2 0",
});

const RemoveAccountConfirmationModal = ({
  onConfirm,
  onRemove,
  account,
  onOpenChange,
  ...props
}: {
  onConfirm: () => void;
  onRemove: () => void;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
  account?: AccountWithIntegrationFragment;
} & React.ComponentProps<typeof AlertDialog>) => {
  const [intentConfirmed, setIntentConfirmed] = useState(false);

  const handleOpenChange = useCallback(
    (open: boolean) => {
      onOpenChange(open);

      // when modal closes, unset intentConfirmed so it's not still checked when
      // modal is re-opened.
      if (!open) {
        setIntentConfirmed(false);
      }
    },
    [onOpenChange, setIntentConfirmed]
  );

  return (
    <AlertDialog onOpenChange={handleOpenChange} {...props} size="small">
      <AlertDialog.Title basic showClose>
        Remove an account:
      </AlertDialog.Title>
      <AlertDialog.Body>
        <WarningList>
          <li>Removes all historical data ingested from the account</li>
          <li>Prevents any data associated with the account from syncing in the future</li>
        </WarningList>
        <Text>
          The primary use case is to remove data from non-business accounts that should not and will
          never be included in Puzzle.
        </Text>
        <Text css={{ marginTop: "$1", display: "inline-block" }}>
          Note: Having a locked period prevents account removal.
        </Text>
        <Stack direction="horizontal" css={{ padding: "$3 0 0 0" }}>
          <Checkbox
            size={18}
            onClick={() => setIntentConfirmed(!intentConfirmed)}
            checked={intentConfirmed}
          />
          <p>
            I understand that deleting this account will remove all associated data, including
            transactions, reconciliations, ledger accounts, and any impact on the existing financial
            statements.
          </p>
        </Stack>
      </AlertDialog.Body>
      <AlertDialog.Footer>
        <AlertDialog.ConfirmButton
          onClick={onRemove}
          variant="minimal"
          disabled={!intentConfirmed}
          css={{
            "&:disabled": {
              color: "$gray500",
            },
          }}
        >
          Remove Account
        </AlertDialog.ConfirmButton>
        {account &&
        !account.manuallyAdded &&
        account.status !== IntegrationConnectionStatus.Disconnected ? (
          <AlertDialog.ConfirmButton onClick={onConfirm}>
            Deactivate account instead
          </AlertDialog.ConfirmButton>
        ) : null}
      </AlertDialog.Footer>
    </AlertDialog>
  );
};

export default RemoveAccountConfirmationModal;
