import { Brex } from "@puzzle/icons";
import React from "react";
import { IntegrationLogo } from "../shared";

const BrexLogo = ({ circular }: { circular?: boolean }) => {
  return (
    <IntegrationLogo key="brex" color="#171717" circular={circular}>
      <Brex />
    </IntegrationLogo>
  );
};

export default BrexLogo;
