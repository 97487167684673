import React from "react";
import type { Tag as TagType } from "./TagFan";
import { tag as tagStyle } from "./tagFanStyles.css";
import { zIndex } from "@puzzle/utils";

type TagProps = {
  tag: TagType;
  i: number;
  coverupAmt: number;
  handleMouseEnter: (i: number) => void;
  handleMouseLeave: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

const Tag = ({ i, tag, coverupAmt, handleMouseEnter, handleMouseLeave }: TagProps) => {
  return (
    <div
      onMouseEnter={() => {
        handleMouseEnter(i);
      }}
      onMouseLeave={() => {
        handleMouseLeave();
      }}
      className={tagStyle}
      style={{
        width: tag.width === "auto" ? "auto" : `${tag.width}px`,
        maxWidth: `${tag.maxWidth}px`,
        zIndex: zIndex("tableTag") - i, // each tag should have a lower z-index to appear to go "under" the previous one
        marginRight: `${i === 0 ? 0 : -coverupAmt}px`, // pull the tags to the right by the coverup amt, except for the first tag
        transition: tag.transition,
      }}
    >
      {tag.name}
    </div>
  );
};

export default Tag;
