import React from "react";
import Logo from "../meow/MeowLogo";
import IntegrationDetails from "./IntegrationDetails";
import { DetailProps } from "./types";
import { useMeow } from "../meow";

const MeowDetails = ({ companyId, integrationConnectionId, ...props }: DetailProps) => {
  const meow = useMeow({ companyId, integrationConnectionId });

  return (
    <IntegrationDetails
      logo={<Logo />}
      title="Meow"
      connection={meow.connection}
      onDisconnect={meow.disconnect}
      onReconnect={meow.onClickConnect}
      {...props}
    />
  );
};

export default MeowDetails;
