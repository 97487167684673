import React from "react";
import { Dialog, Button, styled, Text, colors } from "@puzzle/ui";
import { Check } from "@puzzle/icons";
import { useWizardContext } from "components/common/Wizard";
import Link from "components/common/Link";
import { Box, S } from "ve";

const Line = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "$1h",
});

const NumberContainer = styled("div", {
  background: "$gray600",
  color: "$gray200",
  width: "24px",
  height: "24px",
  borderRadius: "$ellipse",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flex: "none",
  order: 0,
  flexGrow: 0,
});

const CheckContainer = styled("div", {
  background: "$greenalpha",
  width: "42px",
  height: "42px",
  borderRadius: "$ellipse",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const Success = () => {
  const { goToPreviousStep, goToNextStep } = useWizardContext();
  return (
    <>
      <Dialog.Title onBack={goToPreviousStep}>
        Import historical books from QuickBooks Online
      </Dialog.Title>
      <Dialog.Body css={{ color: "$gray200" }}>
        <Line css={{ alignItems: "center", paddingBottom: "$2" }}>
          <CheckContainer>
            <Check color={colors.gray600} size={20} />
          </CheckContainer>
          <Text css={{ textVariant: "$headingL", color: "$white" }}>
            Historical financials are being imported
          </Text>
        </Line>
        <Text css={{ color: "$white", textVariant: "$headingS" }}>
          What will happen in the next few days
        </Text>
        <Line css={{ paddingTop: "$1h" }}>
          <NumberContainer>1</NumberContainer>
          You can review your trial balance report that includes your ending balance for each
          account for each historical month.
        </Line>
        <Line css={{ paddingTop: "$1h" }}>
          <NumberContainer>2</NumberContainer>
          Our team will review the mapping between your historical financials and Puzzle accounts to
          record historical balances and summary activity.
        </Line>
        <Line css={{ paddingTop: "$1h" }}>
          <NumberContainer>3</NumberContainer>
          Puzzle can help you convert your historical books from QuickBooks.{" "}
          <Link
            href="https://cal.com/arash-puzzle/historical-financials-migration"
            external
            target="_blank"
          >
            Request a migration
          </Link>
          .
        </Line>
        <Box css={{ paddingTop: S["2h"] }}>
          <Text css={{ fontWeight: "$heavy" }}>Note:</Text> At the moment, we do not support
          importing individual transactions, journal entries, bills, invoices, or chart of accounts.
        </Box>
      </Dialog.Body>
      <Dialog.Footer align="right">
        <Button variant="primary" onClick={goToNextStep}>
          Done
        </Button>
      </Dialog.Footer>
    </>
  );
};
