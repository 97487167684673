import React from "react";
import IntegrationLogo from "../shared/IntegrationLogo";
import { Stripe } from "@puzzle/icons";

const Logo = ({ circular }: { circular?: boolean }) => {
  return (
    <IntegrationLogo color="#6772E5" circular={circular}>
      <Stripe />
    </IntegrationLogo>
  );
};

export default Logo;
