import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Dialog } from "@puzzle/ui";

import Wizard, { StepOption } from "components/common/Wizard";
import { ManualImportBills } from "components/dashboard/Accounting/Bills/Import/ManualImportBills";

import ImportBillsProvider, { ImportMode, useImportBillsContext } from "./ImportBillsProvider";
import {
  FormValues,
  validationSchema,
  Intro,
  UploadCoa,
  Integrate,
  Connected,
} from "./BillDotComImportBills";

type ImportBillsModalProps = React.ComponentPropsWithoutRef<typeof Dialog> & {
  mode: ImportMode;
};

export const ImportBills = (props: ImportBillsModalProps) => {
  return (
    <ImportBillsProvider initialMode={props.mode} onOpenChange={props.onOpenChange}>
      <Dialog
        {...props}
        size="medium"
        onOpenChange={(open: boolean) => {
          props.onOpenChange?.(open);
        }}
      >
        <ImportBillsWizard />
      </Dialog>
    </ImportBillsProvider>
  );
};

const ImportBillsWizard = () => {
  const { mode } = useImportBillsContext();

  const form = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const stepsMap: Record<ImportMode, StepOption[]> = {
    manual: [{ label: "Manual Import", name: "manualImport", component: ManualImportBills }],
    billDotCom: [
      { label: "Intro", name: "intro", component: Intro },
      {
        label: "UploadCoa",
        name: "uploadCoa",
        component: UploadCoa,
      },
      {
        label: "Integrate",
        name: "integrate",
        component: Integrate,
      },
      {
        label: "Connected",
        name: "connected",
        component: Connected,
      },
    ],
  };

  return (
    <Wizard
      steps={stepsMap[mode]}
      render={({ activeStep }) => {
        const Step = activeStep.component;
        return <Step form={form} />;
      }}
    />
  );
};
