import * as Types from '../../graphql/types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateStripeCheckoutSessionMutationVariables = Types.Exact<{
  input: Types.CreateStripeCheckoutSessionInput;
}>;


export type CreateStripeCheckoutSessionMutation = { __typename?: 'Mutation', createStripeCheckoutSession: { __typename?: 'StripeCheckoutSession', id: string, expiresAt?: number | null, url: string } };

export type RequestAdditionalServicesMutationVariables = Types.Exact<{
  input: Types.AdditionalServicesInput;
}>;


export type RequestAdditionalServicesMutation = { __typename?: 'Mutation', requestAdditionalServices: Array<Types.AdditionalServices> };

export type AddItemsToStripeSubscriptionMutationVariables = Types.Exact<{
  input: Types.AddItemsToStripeSubscriptionInput;
}>;


export type AddItemsToStripeSubscriptionMutation = { __typename?: 'Mutation', addItemsToStripeSubscription: { __typename?: 'StripeSubscription', stripePriceIds?: Array<string> | null } };


export const CreateStripeCheckoutSessionDocument = gql`
    mutation createStripeCheckoutSession($input: CreateStripeCheckoutSessionInput!) {
  createStripeCheckoutSession(input: $input) {
    id
    expiresAt
    url
  }
}
    `;
export type CreateStripeCheckoutSessionMutationFn = Apollo.MutationFunction<CreateStripeCheckoutSessionMutation, CreateStripeCheckoutSessionMutationVariables>;

/**
 * __useCreateStripeCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useCreateStripeCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeCheckoutSessionMutation, { data, loading, error }] = useCreateStripeCheckoutSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStripeCheckoutSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateStripeCheckoutSessionMutation, CreateStripeCheckoutSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStripeCheckoutSessionMutation, CreateStripeCheckoutSessionMutationVariables>(CreateStripeCheckoutSessionDocument, options);
      }
export type CreateStripeCheckoutSessionMutationHookResult = ReturnType<typeof useCreateStripeCheckoutSessionMutation>;
export type CreateStripeCheckoutSessionMutationResult = Apollo.MutationResult<CreateStripeCheckoutSessionMutation>;
export type CreateStripeCheckoutSessionMutationOptions = Apollo.BaseMutationOptions<CreateStripeCheckoutSessionMutation, CreateStripeCheckoutSessionMutationVariables>;
export const RequestAdditionalServicesDocument = gql`
    mutation requestAdditionalServices($input: AdditionalServicesInput!) {
  requestAdditionalServices(input: $input)
}
    `;
export type RequestAdditionalServicesMutationFn = Apollo.MutationFunction<RequestAdditionalServicesMutation, RequestAdditionalServicesMutationVariables>;

/**
 * __useRequestAdditionalServicesMutation__
 *
 * To run a mutation, you first call `useRequestAdditionalServicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestAdditionalServicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestAdditionalServicesMutation, { data, loading, error }] = useRequestAdditionalServicesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestAdditionalServicesMutation(baseOptions?: Apollo.MutationHookOptions<RequestAdditionalServicesMutation, RequestAdditionalServicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestAdditionalServicesMutation, RequestAdditionalServicesMutationVariables>(RequestAdditionalServicesDocument, options);
      }
export type RequestAdditionalServicesMutationHookResult = ReturnType<typeof useRequestAdditionalServicesMutation>;
export type RequestAdditionalServicesMutationResult = Apollo.MutationResult<RequestAdditionalServicesMutation>;
export type RequestAdditionalServicesMutationOptions = Apollo.BaseMutationOptions<RequestAdditionalServicesMutation, RequestAdditionalServicesMutationVariables>;
export const AddItemsToStripeSubscriptionDocument = gql`
    mutation addItemsToStripeSubscription($input: AddItemsToStripeSubscriptionInput!) {
  addItemsToStripeSubscription(input: $input) {
    stripePriceIds
  }
}
    `;
export type AddItemsToStripeSubscriptionMutationFn = Apollo.MutationFunction<AddItemsToStripeSubscriptionMutation, AddItemsToStripeSubscriptionMutationVariables>;

/**
 * __useAddItemsToStripeSubscriptionMutation__
 *
 * To run a mutation, you first call `useAddItemsToStripeSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddItemsToStripeSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addItemsToStripeSubscriptionMutation, { data, loading, error }] = useAddItemsToStripeSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddItemsToStripeSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<AddItemsToStripeSubscriptionMutation, AddItemsToStripeSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddItemsToStripeSubscriptionMutation, AddItemsToStripeSubscriptionMutationVariables>(AddItemsToStripeSubscriptionDocument, options);
      }
export type AddItemsToStripeSubscriptionMutationHookResult = ReturnType<typeof useAddItemsToStripeSubscriptionMutation>;
export type AddItemsToStripeSubscriptionMutationResult = Apollo.MutationResult<AddItemsToStripeSubscriptionMutation>;
export type AddItemsToStripeSubscriptionMutationOptions = Apollo.BaseMutationOptions<AddItemsToStripeSubscriptionMutation, AddItemsToStripeSubscriptionMutationVariables>;