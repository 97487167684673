import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Add({
  viewBox = "0 0 14 14",
  color = "currentColor",
  fill = color,
  ...iconProps
}: IconProps) {
  return (
    <Icon {...iconProps} viewBox={viewBox}>
      <path
        fill={fill}
        d="M11.3125 6.4375H7.5625V2.6875C7.5625 2.59375 7.46875 2.5 7.375 2.5H6.625C6.50781 2.5 6.4375 2.59375 6.4375 2.6875V6.4375H2.6875C2.57031 6.4375 2.5 6.53125 2.5 6.625V7.375C2.5 7.49219 2.57031 7.5625 2.6875 7.5625H6.4375V11.3125C6.4375 11.4297 6.50781 11.5 6.625 11.5H7.375C7.46875 11.5 7.5625 11.4297 7.5625 11.3125V7.5625H11.3125C11.4062 7.5625 11.5 7.49219 11.5 7.375V6.625C11.5 6.53125 11.4062 6.4375 11.3125 6.4375Z"
      />
    </Icon>
  );
}
