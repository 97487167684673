import React from "react";
import Icon, { IconProps } from "./Icon";
import { getNumericRotate } from "./utils";

export function Arrow({
  viewBox = "0 0 14 14",
  width = 14,
  height = 14,
  fill = "#95949B",
  color = fill,
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} {...iconProps} viewBox={viewBox}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.20921 10.8047C6.93026 10.5444 6.93026 10.1223 7.20921 9.86193L9.56128 7.66667H2.71429C2.3198 7.66667 2 7.36819 2 7C2 6.63181 2.3198 6.33333 2.71429 6.33333H9.56128L7.20921 4.13807C6.93026 3.87772 6.93026 3.45561 7.20921 3.19526C7.48815 2.93491 7.94042 2.93491 8.21936 3.19526L11.7908 6.5286C12.0697 6.78894 12.0697 7.21106 11.7908 7.4714L8.21936 10.8047C7.94042 11.0651 7.48815 11.0651 7.20921 10.8047Z"
        fill={color}
      />
    </Icon>
  );
}

export function ArrowLeft(props: IconProps) {
  const totalRotate = 180 + getNumericRotate(props.rotate);
  return <Arrow {...props} rotate={totalRotate} />;
}
