import React from "react";
import { useCallback, useMemo, useState } from "react";
import { takeRightWhile, uniqueId } from "lodash";

import { spaceUnit } from "@puzzle/theme";
import { ToastInput, ToastItem } from "./types";

export const useToastsInternal = () => {
  const [toasts, setToasts] = useState<ToastItem[]>([]);
  const updateToast = useCallback((id: string, updates: Partial<ToastItem>) => {
    setToasts((toasts) => toasts.map((t) => (t.id !== id ? t : { ...t, ...updates })));
  }, []);

  const toast = useCallback((input: ToastInput) => {
    setToasts((toasts) => {
      const result = [...toasts];

      const existingIndex = toasts.findIndex((toast) => toast.id === input.id);
      if (existingIndex >= 0) {
        result[existingIndex] = {
          ...result[existingIndex],
          ...input,
          // Force height calculation to rerun in case the content changes
          height: 0,
        };
      } else {
        result.push({
          ...input,
          id: input.id ?? uniqueId("toast"),
          status: typeof input.status !== "undefined" ? input.status : "success",
          sensitivity: input.sensitivity ?? "foreground",
          open: true,
          height: 0,
        });
      }

      return result;
    });
  }, []);

  const calculateOffset = useCallback(
    (id: string) => {
      const previousToasts = takeRightWhile(toasts, (t) => t.id !== id).filter((t) => t.open);
      return -previousToasts.reduce(
        (result, toast) => result + toast.height,
        Math.max(previousToasts.length, 0) * spaceUnit
      );
    },
    [toasts]
  );

  const removeToast = useCallback(
    (id: string) => setToasts((toasts) => toasts.filter((t) => t.id !== id)),
    []
  );

  return useMemo(
    () => ({ toasts, updateToast, toast, removeToast, calculateOffset }),
    [toasts, updateToast, toast, removeToast, calculateOffset]
  );
};

type ToasterContextType = ReturnType<typeof useToastsInternal> | undefined;
export const ToasterContext = React.createContext<ToasterContextType>(undefined);
