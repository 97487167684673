import React from "react";

export const TransactionIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
      {...props}
    >
      <rect width="28" height="28" fill="#5A5A7A" rx="4"></rect>
      <path
        fill="#B3B2B8"
        fillRule="evenodd"
        d="M20.325 6.3c1.34 0 2.681.241 4.022.791.378.172.653.585.653.997V19.02c0 .618-.516 1.065-1.134 1.065-.104 0-.241 0-.344-.034a10.31 10.31 0 00-3.197-.481c-2.114 0-4.228.532-6.342 1.065-2.114.533-4.228 1.066-6.342 1.066-1.341 0-2.682-.207-4.022-.756C3.24 20.773 3 20.36 3 19.948V9.016c0-.618.516-1.1 1.1-1.1.052.018.112.026.172.035.06.008.12.017.172.034 1.065.344 2.131.481 3.197.481 2.114 0 4.228-.541 6.342-1.082C16.097 6.842 18.21 6.3 20.325 6.3zM7.641 20.05c1.89 0 3.85-.48 5.947-.996l.088-.023c2.139-.543 4.377-1.111 6.649-1.111h.034c.378 0 .997.034 1.375.068.344-.515 1.032-1.134 1.616-1.41V9.808v6.77c-.585.275-1.272.894-1.616 1.41a18.395 18.395 0 00-1.375-.069h-.034c-2.272 0-4.51.569-6.65 1.112l-.088.022c-2.097.516-4.056.997-5.947.997h-.034c-.31 0-.86 0-1.169-.069a3.627 3.627 0 000 .002c.31.069.86.069 1.17.069h.034zM11.25 14c0 1.821 1.203 3.3 2.75 3.3 1.512 0 2.75-1.479 2.75-3.3 0-1.823-1.238-3.3-2.75-3.3-1.547 0-2.75 1.477-2.75 3.3z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};
