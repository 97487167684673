import React, { useState } from "react";

import { H } from "highlight.run";
import { ErrorBoundary as HighlightErrorBoundary } from "@highlight-run/react";

import { Button, Dialog, Field, Stack, TextArea } from "@puzzle/ui";

import Bugsnag from "../bugsnag";
import config, { IS_LOCAL_DEVELOPMENT, IS_PROD } from "../config";

if (!config.IS_TEST) {
  H.init(config.HIGHLIGHT_ID, {
    version: process.env.BUILD_ID,
    environment: config.PUZZLE_ENV,
    enableStrictPrivacy: false,
    enableSegmentIntegration: true,
    backendUrl: "/highlight-events",
    tracingOrigins: [/^.*\/api\/proxy\/graphql$/],
    networkRecording: {
      recordHeadersAndBody: true,
      urlBlocklist: [
        "https://api.knock.app",
        "https://sessions.bugsnag.com",
        "https://notify.bugsnag.com",
        "https://ph.puzzle.io",
      ],
    },
  });
}

// We don't need both Highlight and Bugsnag's error boundaries.
// Highlight's enables users to submit crash reports and stil report to Bugsnag.
// const BugsnagErrorBoundary: React.ComponentType =
//   Bugsnag.getPlugin("react")?.createErrorBoundary(React) ||
//   ((props) => <React.Fragment {...props} />);

const CrashDialog = () => {
  const [open, setOpen] = useState(true);
  const [verbatim, setVerbatim] = useState("");
  const submit = (e: React.FormEvent) => {
    e.preventDefault();
    H.addSessionFeedback({ verbatim });
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onOpenChange={setOpen}
      onInteractOutside={(e) => e.preventDefault()}
      size="small"
    >
      <form onSubmit={submit}>
        <Dialog.Title>It looks like we’re having issues.</Dialog.Title>

        <Dialog.Body>
          <Stack>
            Our team has been notified. If you’d like to help, tell us what happened below.
            <Field label="What happened?">
              <TextArea autoFocus value={verbatim} onChange={(e) => setVerbatim(e.target.value)} />
            </Field>
          </Stack>
        </Dialog.Body>

        <Dialog.Footer>
          <Dialog.Actions>
            <Button variant="secondary" type="button" onClick={() => setOpen(false)}>
              Close
            </Button>
            <Button variant="primary" type="submit" disabled={!verbatim}>
              Submit
            </Button>
          </Dialog.Actions>
        </Dialog.Footer>
      </form>
    </Dialog>
  );
};

// Just in case...
// https://github.com/bugsnag/bugsnag-js/blob/next/packages/plugin-react/src/index.js#L43-L50
const formatComponentStack = (str: string) => {
  const lines = str.split(/\s*\n\s*/g);
  let ret = "";
  for (let line = 0, len = lines.length; line < len; line++) {
    if (lines[line].length) ret += `${ret.length ? "\n" : ""}${lines[line]}`;
  }
  return ret;
};

// Feel free to use this in more granular parts of the app to allow for recoverable crash reporting.
export const ErrorBoundary = ({ children }: React.PropsWithChildren<unknown>) => {
  return (
    <HighlightErrorBoundary
      showDialog={IS_PROD}
      customDialog={<CrashDialog />}
      onError={(error, componentStack) =>
        Bugsnag.notify(error, (e) =>
          e.addMetadata("react", { componentStack: formatComponentStack(componentStack) })
        )
      }
    >
      {children}
    </HighlightErrorBoundary>
  );
};

export const reportError = (
  error: Error | string,
  metadata?: Record<string, any>,
  unhandled = false
) => {
  Bugsnag.notify(error, (event) => {
    // Prevent this wrapper function from showing up in Bugsnag's stacktrace.
    // It causes incorrect groupings.
    event.errors[0].stacktrace[0].inProject = false;

    Object.entries(metadata || {}).map(([key, value]) => {
      event.addMetadata(key, value);
    });

    if (unhandled) {
      event.unhandled = true;
      event.severity = "error";
    }
  });

  if (IS_LOCAL_DEVELOPMENT) {
    console.error(error, metadata?.error);
  }

  const message = error instanceof Error ? error.message : error;

  // TODO It's possible new Error adds an unwanted frame.
  // Not sure how Highlight groups things yet.
  // It may be best to enforce Error objects and prevent strings.
  // https://linear.app/puzzlefin/issue/PZ-5594/nextjs-highlight-sdk
  H.consumeError(error instanceof Error ? error : new Error(error), message, metadata);
};
