import { useMemo } from "react";
import { merge } from "lodash";
import { useToasts } from "@puzzle/ui";
import {
  useConnectRampMutation,
  useRampIntegrationQuery,
  RampIntegrationDocument,
  RampIntegrationQuery,
  IntegrationType,
  IntegrationConnectionCondition,
  useFinancialInstitutionsQuery,
} from "graphql/types";
import { usePendingConnections } from "../shared/PendingConnectionsProvider";
import { UseRampResult } from "./types";
import useSavePage from "../shared/useSavePage";
import { UseIntegrationHook, useFinancialInstitutions } from "../shared";
import config from "lib/config";
import Analytics from "lib/analytics";

const useRamp: UseIntegrationHook<UseRampResult> = ({
  companyId,
  integrationConnectionId,
  onClickConnect: baseOnClickConnect,
}): UseRampResult => {
  const { toast } = useToasts();
  const { data, loading, refetch, startPolling, stopPolling } = useRampIntegrationQuery({
    variables: { companyId },
  });
  const { integrations } = data || {};
  const { addPendingConnection, removePendingConnection } = usePendingConnections();
  const { savePage } = useSavePage();
  const { disconnectIntegrationConnection, integrationConnections } = useFinancialInstitutions();

  const connection = useMemo(() => {
    const connectionId = integrationConnectionId ?? integrations?.ramp.connection?.id;
    return integrationConnections.find((ic) => ic.id === connectionId);
  }, [integrationConnections, integrationConnectionId, integrations]);

  const disconnectIntegration = useMemo(() => {
    const connectionId = integrationConnectionId ?? integrations?.ramp.connection?.id;
    if (!connectionId) {
      return undefined;
    }

    return async () => {
      const result = disconnectIntegrationConnection(connectionId);

      if (!integrationConnectionId) {
        refetch();
      }

      return result;
    };
  }, [disconnectIntegrationConnection, refetch, integrationConnectionId, integrations]);

  const [connectRampMutation, { loading: connecting, error: connectionError }] =
    useConnectRampMutation();

  const initializationInfo = integrations?.ramp.initializationInfo;

  const onClickConnect = () => {
    baseOnClickConnect && baseOnClickConnect();
    savePage();

    if (!initializationInfo || !initializationInfo.connectUrl) {
      if (config.IS_LOCAL_DEVELOPMENT) {
        toast({
          message: "onClickConnect was called before initialization info was defined",
          status: "error",
        });
      }
      return;
    }
    window.location.href = initializationInfo.connectUrl;
  };

  const connectRamp = async (input: {
    companyId: string;
    code: string;
    state: string;
    condition?: IntegrationConnectionCondition;
  }) => {
    addPendingConnection(IntegrationType.Ramp);

    return connectRampMutation({
      variables: { input },

      update(cache, { data }) {
        if (!data) return;

        const rampIntegration = cache.readQuery<RampIntegrationQuery>({
          query: RampIntegrationDocument,
          variables: {
            companyId: companyId,
          },
        });

        cache.writeQuery({
          query: RampIntegrationDocument,
          variables: {
            companyId,
          },
          data: merge({}, rampIntegration, {
            integrations: {
              ramp: {
                connection: data.connectRamp.connection,
              },
            },
          }),
        });
      },

      onCompleted(data) {
        const { connection } = data.connectRamp;
        removePendingConnection(IntegrationType.Ramp);

        Analytics.integrationConnected({
          connectionId: connection.id,
          integrationType: "Ramp",
          totalAccounts: 1,
        });
      },

      onError({ message }) {
        Analytics.integrationConnectionFailed({
          integrationType: "Ramp",
          reason: message,
        });
      },
    });
  };

  const { data: fiData } = useFinancialInstitutionsQuery({
    variables: { companyId, integrationType: IntegrationType.Ramp },
  });

  const financialInstitutions = fiData?.financialInstitutionsFor ?? [];

  return {
    connectionError,
    connectRamp,
    connecting,
    loading,
    onClickConnect,
    connection,
    disconnect: disconnectIntegration,
    startPolling,
    stopPolling,
    financialInstitutions,
  };
};

export default useRamp;
