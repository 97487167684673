import React from "react";
import Icon, { IconProps } from "../Icon";

export default function Chase({
  viewBox = "0 0 48 48",
  width = 48,
  height = 48,
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} {...iconProps} viewBox={viewBox}>
      <path
        d="M20.0161 10C19.7538 9.99993 19.5021 10.104 19.3165 10.2895C19.1309 10.4749 19.0264 10.7265 19.0262 10.9889V17.9185H37.3295L28.9942 10.0021L20.0161 10ZM38 20.0149C38.0001 19.885 37.9746 19.7564 37.9249 19.6364C37.8752 19.5164 37.8023 19.4075 37.7104 19.3158C37.6185 19.224 37.5094 19.1514 37.3894 19.1019C37.2693 19.0525 37.1407 19.0272 37.0109 19.0276H30.0837V37.3355L37.9968 28.9958L38 20.0149ZM27.9847 38C28.2466 37.9993 28.4975 37.8947 28.6824 37.7093C28.8673 37.5238 28.9712 37.2725 28.9712 37.0106V30.0817H10.6686L19.0025 37.9981L27.9847 38ZM10 27.9862C9.99996 28.1161 10.0255 28.2448 10.0752 28.3648C10.1248 28.4848 10.1976 28.5939 10.2894 28.6857C10.3812 28.7776 10.4902 28.8505 10.6102 28.9002C10.7302 28.95 10.8587 28.9756 10.9886 28.9756H17.9163V10.6664L10.0011 19.004L10 27.9862Z"
        fill="white"
      />
    </Icon>
  );
}
