// TODO Automate with something like Pathpida
// https://linear.app/puzzlefin/issue/PZ-2292/typesafe-routes-for-nextjs
export enum Route {
  sync = "/api/sync",
  token = "/api/token",
  login = "/api/login",
  authorizePartner = "/api/authorize-partner",
  // This first goes to Auth0, then our own logout handler.
  logout = "/logout",
  me = "/api/me",
  home = "/",
  emailVerification = "/email-verification",
  emailVerificationSuccess = "/email-verification-success",
  emailNotifications = "/email-notifications",
  connectPlaid = "/oauth/plaid",
  connectFinicity = "/oauth/finicity",
  connectBrex = "/oauth/brex",
  connectGusto = "/oauth/gusto",
  connectRippling = "/oauth/rippling",
  linkRippling = "/link/rippling",
  connectQuickbooks = "/oauth/quickbooks",
  connectStripe = "/oauth/stripe",
  integrations = "/integrations",
  connectionError = "/oauth/error",
  createCompany = "/company/create",
  intro = "/intro",
  invoices = "/accounting/invoices",
  newInvoice = "/accounting/invoices/new",
  error = "/error",
  accountDetails = "/account",
  burn = "/burn",
  landing = "/landing",
  checklist = "/checklist",
  companyPrivateRoute = "/company",
  reports = "/reports",
  printableReport = "/print",
  report = "/reports/:id",
  revenue = "/revenue",
  accrualRevenue = "/revenue/accrual",
  mrrRevenue = "/revenue/mrr-arr",
  mrrRevenueByCustomer = "/revenue/mrr-arr-customers",
  expenses = "/expenses",
  people = "/people",
  ltse = "/ltse",
  angelListConnect = "/connect/angellist",
  connectSuccess = "/connect/success",
  angellistCategorize = "/connect/angellist/categorize",
  angellistManage = "/connect/angellist/manage",
  genericConnect = "/connect/generic",
  acmeConnect = "/connect/acme",
  introConnect = "/connect/intro",
  genericCategorize = "/connect/generic/categorize",
  connectPartnerDataSource = "/connect/partner-data-source",
  transactions = "/transactions",
  rules = "/transactions/rules",
  rulesSettings = "/settings/rules",
  settings = "/settings",
  companySettings = "/settings/company",
  automationSettings = "/settings/automation",
  notificationSettings = "/settings/notifications",
  auditLog = "/settings/audit-log",
  billing = "/settings/billing",
  historicalBooks = "/settings/historical-books",
  classesSettings = "/settings/classes",
  usersSettings = "/settings/users",
  dataSettings = "/settings/data",
  otherSettings = "/settings/other",
  policySettings = "/settings/policies",
  chartOfAccountsSettings = "/settings/coa",
  lockedPeriodSettings = "/settings/locked-period",
  accounting = "/accounting",
  chartOfAccounts = "/accounting/coa",
  generalLedger = "/accounting/ledger",
  manualJournals = "/accounting/journals",
  newManualJournal = "/accounting/journals/new",
  lockedPeriod = "/accounting/locked-period",
  payroll = "/payroll",
  bills = "/accounting/bills",
  newBill = "/accounting/bills/new",
  fixedAssets = "/accounting/fixed-assets",
  fixedAssetsTransactions = "/accounting/fixed-assets/transactions",
  prepaidExpenses = "/accounting/prepaid-expenses",
  payments = "/accounting/payments",
  newPayment = "/accounting/payments/new",
  prepaidExpensesTransactions = "/accounting/prepaid-expenses/transactions",
  spending = "/spending",
  vendorList = "/spending/vendor-list",
  askAccountant = "/ask-an-accountant",
  inbox = "/inbox",
  partnerOnboarding = "partner-onboarding/:partner",
  ledgerReconciliations = "/accounting/ledger-reconciliations",
  ledgerReconciliationsByAccount = "/accounting/ledger-reconciliations/account/:accountId",
  newLedgerReconciliation = "/accounting/ledger-reconciliations/account/:accountId/new",
  ledgerReconciliationViewer = "/accounting/ledger-reconciliations/:reconciliationId",
  vendors = "/vendors",
  vendors1099 = "/vendors/1099",
  customers = "/manage-customers",
  apAging = "/accounting/ap-aging",
  stripe = "/transactions/stripe",
  stripeDetail = "/transactions/stripe/:bucketId",
  icons = "/icons",
  arAging = "/accounting/ar-aging",
  accountingConfigurations = "/accounting/policies",
  revenueRecognition = "/accounting/revenue-recognition",
  products = "/products",
  noAccess = "/no-access",
}
export const RouteMap = new Set(Object.values(Route));

export enum CompanyRoute {
  auth = "auth",
}

export enum ErrorPageCode {
  Whitelist = "whitelist",
  VerifyEmail = "verify_email",

  CodeInvalid = "code_invalid",
  CodeExpired = "code_expired",
  CodeAlreadyActivated = "code_already_activated",
  CodeError = "code_error",

  AuthError = "auth_error",
}

export const isDynamicRoute = (path: string) => {
  return /\[(.*?)\]/.test(path);
};

export const getClosestStaticRoute = (path: string) => {
  const segments = path.split("/");
  const redirectPathParts = [];

  for (const segment of segments) {
    if (isDynamicRoute(segment)) break;
    redirectPathParts.push(segment);
  }

  let redirectPath = redirectPathParts.join("/");
  for (let i = 0; i < redirectPathParts.length; i++) {
    if (RouteMap.has(redirectPath as Route)) {
      break;
    } else {
      const pathMinusNSegments = redirectPathParts
        .slice(0, redirectPathParts.length - 1 - i)
        .join("/");
      redirectPath = pathMinusNSegments;
    }
  }

  return redirectPath || "/";
};

// Given the current route, return the title of the page.
// This is presently only used in the CmdK modal to display the current page.
export const getRouteTitle = (currentRoute: Route): string | undefined => {
  // Get the first part of the pathname. e.g. /inbox/updates -> /inbox
  const firstPartOfPathname = `/${currentRoute.split("/")[1]}` as Route;
  // Return a title based on the first part of the pathname.
  // e.g. /inbox -> "Inbox"
  switch (firstPartOfPathname) {
    case Route.inbox:
      return "Inbox";
    case Route.checklist:
      return "Monthly checklist";
    case Route.reports:
      return "Reports";
    case Route.accounting:
      return "Accounting";
    case Route.generalLedger:
      return "General ledger";
    case Route.ledgerReconciliations:
      return "Reconciliations";
    case Route.manualJournals:
      return "Manual journal entries";
    case Route.fixedAssets:
      return "Fixed assets";
    case Route.transactions:
      return "Transactions";
    case Route.spending:
      return "Spending";
    case Route.revenue:
      return "Revenue";
    case Route.invoices:
      return "Invoices";
    case Route.customers:
      return "Customers";
    case Route.arAging:
      return "AR aging";
    case Route.people:
      return "People";
    case Route.integrations:
      return "Integrations";
    case Route.companySettings:
      return "Settings";
    case Route.rulesSettings:
      return "Rules";
    case Route.automationSettings:
      return "Automation";
    case Route.policySettings:
      return "Policy settings";
    case Route.chartOfAccountsSettings:
      return "Chart of accounts";
    case Route.lockedPeriodSettings:
      return "Locked period";
    case Route.notificationSettings:
      return "Notification settings";
    case Route.otherSettings:
      return "Other settings";
    case Route.classesSettings:
      return "Classifications";
    case Route.bills:
      return "Bills";
    case Route.vendors:
      return "Vendors";
    case Route.prepaidExpenses:
      return "Prepaid expenses";
    case Route.apAging:
      return "AP aging";
    case Route.auditLog:
      return "Audit log";
    case Route.askAccountant:
      return "Ask an accountant";
    case Route.billing:
      return "Billing";
    case Route.historicalBooks:
      return "Historical books";
    default:
      return;
  }
};
