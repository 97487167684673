import React from "react";

import { iconButtonRecipe, IconButtonVariants } from "./iconButtonStyles.css";

const IconButton = React.forwardRef<
  HTMLButtonElement,
  React.HTMLAttributes<HTMLButtonElement> &
    IconButtonVariants & {
      fullWidthContent?: boolean;
      children?: React.ReactNode;
      css?: React.CSSProperties;
    }
>(({ children, css, circle, size, ...props }, ref) => {
  const buttonClass = iconButtonRecipe({ circle, size });

  return (
    <button className={buttonClass} style={css} {...props} ref={ref}>
      {children}
    </button>
  );
});

IconButton.displayName = "IconButton";
export { IconButton };
