import React from "react";
import { Deel } from "@puzzle/icons";
import { IntegrationLogo } from "../shared";

const Logo = ({ circular }: { circular?: boolean }) => {
  return (
    <IntegrationLogo color="#FFFFFF" width="48px" height="48px" circular={circular}>
      <Deel width={40} />
    </IntegrationLogo>
  );
};

export default Logo;
