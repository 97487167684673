import React, { useCallback, useState } from "react";
import { AccountWithIntegrationFragment, IntegrationConnectionStatus } from "graphql/types";
import { useToasts } from "@puzzle/ui";
import StopSyncingAccountConfirmationModal from "../shared/StopSyncingAccountConfirmationModal";
import { useFinancialInstitutions } from "../shared";
import FooterButton from "./FooterButton";

const ManageAccountSyncButton = ({ account }: { account: AccountWithIntegrationFragment }) => {
  const { toast } = useToasts();
  const { disconnectAccount: stopSyncing, reconnectAccount: startSyncing } =
    useFinancialInstitutions();
  const [isDisconnecting, setIsDisconnecting] = useState(false);

  const stopSyncingAccount = useCallback(async () => {
    if (!account) {
      return;
    }
    const result = await stopSyncing(account.id);

    if (result?.errors) {
      toast({ message: "Failed to deactivate account.", status: "error" });
    } else {
      toast({ message: "Successfully deactivated account.", status: "success" });
    }
  }, [account, toast, stopSyncing]);

  const startSyncingAccount = useCallback(async () => {
    if (!account) {
      return;
    }
    const result = await startSyncing(account.id);

    if (result?.errors) {
      toast({ message: "Failed to activate account.", status: "error" });
    } else {
      toast({ message: "Successfully activated account.", status: "success" });
    }
  }, [account, toast, startSyncing]);

  if (!account || account.manuallyAdded) {
    return null;
  }

  return account.status === IntegrationConnectionStatus.Disconnected ? (
    <>
      <FooterButton
        label="Activate"
        variant="primary"
        description={`This account is currently inactive. Activate this account to start automatically ingesting your financial data from ${account.financialInstitution.name}.`}
        onClick={startSyncingAccount}
        canBeUndone={true}
      />
    </>
  ) : (
    <>
      <FooterButton
        label="Deactivate"
        variant="negative"
        description={`Pause syncing data for this account.`}
        onClick={() => setIsDisconnecting(true)}
        canBeUndone={true}
      />

      <StopSyncingAccountConfirmationModal
        open={isDisconnecting}
        onOpenChange={setIsDisconnecting}
        onConfirm={() => {
          stopSyncingAccount();
        }}
      />
    </>
  );
};

export default ManageAccountSyncButton;
