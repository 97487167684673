import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Processing({
  viewBox = "0 0 16 16",
  width = 16,
  height = 16,
  color = "currentColor",
  fill = color,
}: IconProps) {
  return (
    <Icon width={width} height={height} fill="none" viewBox={viewBox}>
      <mask
        id="mask0_64_2375"
        style={{ maskType: "alpha" }}
        width={width}
        height={height}
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill={fill} d="M0 0H16V16H0z"></path>
      </mask>
      <g mask="url(#mask0_64_2375)">
        <path
          fill={fill}
          d="M2 13.333V12h1.833l-.266-.233c-.578-.511-.984-1.095-1.217-1.75A5.878 5.878 0 012 8.033c0-1.233.37-2.33 1.108-3.291A5.21 5.21 0 016 2.833v1.4c-.8.29-1.444.78-1.933 1.475a3.946 3.946 0 00-.734 2.325c0 .5.095.986.284 1.459.189.472.483.908.883 1.308l.167.167V9.333H6v4H2zm6-2a.645.645 0 01-.475-.191.645.645 0 01-.192-.475c0-.19.064-.348.192-.475A.645.645 0 018 10c.189 0 .347.064.475.192a.645.645 0 01.192.475.645.645 0 01-.192.475.645.645 0 01-.475.191zm-.667-2.666v-4h1.334v4H7.333zm2.667 4.5v-1.4c.8-.29 1.444-.78 1.933-1.475.49-.695.734-1.47.734-2.325 0-.5-.095-.986-.284-1.459A3.894 3.894 0 0011.5 5.2l-.167-.167v1.634H10v-4h4V4h-1.833l.266.233c.545.545.942 1.136 1.192 1.775.25.64.375 1.292.375 1.959 0 1.233-.37 2.33-1.108 3.291A5.211 5.211 0 0110 13.167z"
        ></path>
      </g>
    </Icon>
  );
}
