import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Crown({
  viewBox = "0 0 12 12",
  width = 12,
  height = 12,
  color = "currentColor",
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} {...iconProps} fill="none" viewBox={viewBox}>
      <g fill={color}>
        <path d="M5.862.793c-.293.051-.558.263-.703.563-.056.115-.067.177-.067.435 0 .271.008.314.075.443.1.197.285.38.47.469.13.063.192.074.418.074.226 0 .288-.011.419-.074.184-.089.368-.272.469-.469.067-.129.075-.174.075-.443 0-.26-.008-.32-.07-.443A1.003 1.003 0 005.862.793z"></path>
        <path d="M1.255 2.131a1.054 1.054 0 00-.408.257c-.469.49-.335 1.336.26 1.636.114.057.176.069.427.069.26 0 .31-.01.447-.075.39-.189.6-.626.527-1.09a.99.99 0 00-.6-.763c-.16-.063-.505-.08-.653-.034z"></path>
        <path d="M10.026 2.165c-.182.075-.416.304-.511.498-.053.111-.07.2-.076.4-.014.35.053.538.271.76.22.224.402.292.743.278.402-.017.661-.183.829-.532.084-.174.092-.211.092-.463s-.008-.289-.092-.463a.926.926 0 00-.45-.455c-.19-.094-.603-.106-.806-.023z"></path>
        <path d="M5.804 3.37c-.073.025-.439.38-1.424 1.383L3.054 6.106l-.587-.595c-.672-.684-.737-.726-1.007-.666-.199.04-.3.117-.38.286l-.064.134v5.004l.061.12a.564.564 0 00.371.291c.182.043 8.881.043 9.077 0a.623.623 0 00.405-.331c.03-.072.039-.592.039-2.637.003-2.842.016-2.644-.196-2.802a.571.571 0 00-.48-.088c-.103.028-.207.123-.734.66l-.614.626-1.299-1.33C6.91 4.025 6.306 3.43 6.25 3.402a.686.686 0 00-.446-.032z"></path>
        <path d="M.793 11.367c-.246.114-.293.245-.293.794 0 .36.008.435.056.538a.48.48 0 00.324.269c.153.043 10.087.043 10.24 0a.48.48 0 00.324-.269c.048-.103.056-.177.056-.546-.003-.555-.053-.683-.316-.798-.089-.037-.672-.043-5.19-.043-4.88 0-5.095.003-5.2.055z"></path>
      </g>
    </Icon>
  );
}
