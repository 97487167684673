import { CalendarDate } from "@internationalized/date";
import { CalendarView } from "@puzzle/utils";

export { CalendarView };
export type Modifier =
  | "today"
  | "outside"
  | "disabled"
  | "selected"
  | "selectedStart"
  | "selectedMiddle"
  | "selectedEnd";
export type Modifiers = Partial<Record<Modifier, (date: CalendarDate) => boolean>>;
export type ComputedModifiers = Partial<Record<Modifier, boolean>>;

export enum SelectionMode {
  Single,
  Multiple,
  Range,
}

// TODO Maybe this could just be Date
export type DateCell = {
  date: CalendarDate;
};

export interface Calendar {
  firstDay: CalendarDate;
  lastDay: CalendarDate;
  rows: Array<Array<DateCell>>;
  year: number;
}

export type Month = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
export type DayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export interface CalendarOfDays extends Calendar {
  month: Month;
}
export type CalendarOfMonths = Calendar;
export type CalendarOfQuarters = Calendar;
export type CalendarOfYears = Calendar;

export interface UseCalendarOptions {
  /**
   * Date to anchor the initial calendar, e.g. using the year or the month of today.
   * Not usually necessary, but make sure the date is stable or memoized!
   */
  initialDate?: CalendarDate;
  maxDate?: CalendarDate;
  minDate?: CalendarDate;
  timeZone?: string;
  /**
   * Number of calendars (e.g. months) to display.
   */
  count?: number;
  firstDayOfWeek?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  showOutsideDays?: boolean;
  view: `${CalendarView}`;
  modifiers?: Modifiers;
}

export enum GroupBy {
  Total = "total",
  Year = "year",
  Quarter = "quarter",
  Month = "month",
  Day = "day",
}

export type ReportGroupBy = GroupBy | string;

export type RangePresetKey =
  | "allTime"
  | "last7Days"
  | "last30Days"
  | "last90Days"
  | "currentMonth"
  | "lastFullMonth"
  | "last3Months"
  | "last3FullMonths"
  | "lastQuarter"
  | "quarterToDate"
  | "yearToDateByMonth"
  | "yearToDate"
  | "lastYear"
  | "monthOverMonth"
  | "quarterOverQuarter"
  | "yearOverYear"
  | "custom"
  | "customDay"
  | "customMonth"
  | "customQuarter"
  | "customYear";

export interface RangePreset {
  key: RangePresetKey;
  label: string;
  view?: CalendarView;
  range?: () => [CalendarDate, CalendarDate];
  groupBy?: GroupBy;
}

export enum ReportExportFormat {
  PDF = "PDF",
  CSV = "CSV",
  EXCEL = "Excel",
}
