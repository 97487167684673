import React, { useMemo } from "react";
import { useFinancialInstitutions, usePlaid } from "..";
import InstitutionLogo from "../bank/InstitutionLogo";
import IntegrationDetails from "./IntegrationDetails";
import { ConnectionDetailsProps } from "./types";

const PlaidConnectionDetails = ({
  companyId,
  integrationConnectionId,
  financialInstitutionId,
  ...props
}: ConnectionDetailsProps) => {
  const { integrationConnections, accounts } = useFinancialInstitutions();
  const { onClickConnect, disconnect } = usePlaid({
    companyId,
    integrationConnectionId,
    financialInstitutionId,
  });

  const financialInstitution = useMemo(
    () =>
      accounts.find((a) => a.financialInstitution?.id === financialInstitutionId)
        ?.financialInstitution,
    [accounts, financialInstitutionId]
  );

  const connection = useMemo(
    () => integrationConnections.find((ic) => ic.id === integrationConnectionId),
    [integrationConnections, integrationConnectionId]
  );

  const integrationType = connection?.type;

  return (
    <>
      <IntegrationDetails
        logo={
          <InstitutionLogo
            institution={{ name: financialInstitution?.name ?? integrationType ?? "" }}
          />
        }
        title={integrationType ?? ""}
        subtitle={financialInstitution?.name ?? ""}
        connection={connection}
        onDisconnect={disconnect}
        onReconnect={onClickConnect}
        financialInstitutionId={financialInstitutionId}
        {...props}
      />
    </>
  );
};

export default PlaidConnectionDetails;
