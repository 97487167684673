import * as Types from '../../../graphql/types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
import { FileFragmentDoc } from '../../../graphql/fragments/file.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUploadInfoQueryVariables = Types.Exact<{
  input: Types.GetUploadInfoInput;
}>;


export type GetUploadInfoQuery = { __typename?: 'Query', getUploadInfo: { __typename?: 'GetUploadInfoResult', signedUrl: string, path: string } };

export type GetFileUploadInfoQueryVariables = Types.Exact<{
  input: Types.GetFileUploadInfoInput;
}>;


export type GetFileUploadInfoQuery = { __typename?: 'Query', getFileUploadInfo: { __typename?: 'GetUploadInfoResult', signedUrl: string, path: string } };

export type GetDownloadInfoQueryVariables = Types.Exact<{
  fileId: Types.Scalars['ID'];
}>;


export type GetDownloadInfoQuery = { __typename?: 'Query', file: { __typename?: 'File', id: string, filename?: string | null, contentType: string, size: number, isDeleted: boolean, downloadInfo: { __typename?: 'FileDownloadInfo', signedUrl: string, urlExpiresAt: CalendarDateTimeString } } };

export type HandleFileUploadedMutationVariables = Types.Exact<{
  input: Types.HandleFileUploadedInput;
}>;


export type HandleFileUploadedMutation = { __typename?: 'Mutation', handleFileUploaded: { __typename?: 'HandleFileUploadedResult', entityType: Types.AssociatedEntity, entityId: string, file: { __typename?: 'File', id: string, filename?: string | null, contentType: string, size: number, isDeleted: boolean, downloadInfo: { __typename?: 'FileDownloadInfo', signedUrl: string, urlExpiresAt: CalendarDateTimeString } } } };

export type DeleteFileMutationVariables = Types.Exact<{
  input: Types.DeleteFileInput;
}>;


export type DeleteFileMutation = { __typename?: 'Mutation', deleteFile: { __typename?: 'DeleteFileResult', entityType: Types.AssociatedEntity, fileId: string } };


export const GetUploadInfoDocument = gql`
    query getUploadInfo($input: GetUploadInfoInput!) {
  getUploadInfo(input: $input) {
    signedUrl
    path
  }
}
    `;

/**
 * __useGetUploadInfoQuery__
 *
 * To run a query within a React component, call `useGetUploadInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUploadInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUploadInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUploadInfoQuery(baseOptions: Apollo.QueryHookOptions<GetUploadInfoQuery, GetUploadInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUploadInfoQuery, GetUploadInfoQueryVariables>(GetUploadInfoDocument, options);
      }
export function useGetUploadInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUploadInfoQuery, GetUploadInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUploadInfoQuery, GetUploadInfoQueryVariables>(GetUploadInfoDocument, options);
        }
export type GetUploadInfoQueryHookResult = ReturnType<typeof useGetUploadInfoQuery>;
export type GetUploadInfoLazyQueryHookResult = ReturnType<typeof useGetUploadInfoLazyQuery>;
export type GetUploadInfoQueryResult = Apollo.QueryResult<GetUploadInfoQuery, GetUploadInfoQueryVariables>;
export const GetFileUploadInfoDocument = gql`
    query getFileUploadInfo($input: GetFileUploadInfoInput!) {
  getFileUploadInfo(input: $input) {
    signedUrl
    path
  }
}
    `;

/**
 * __useGetFileUploadInfoQuery__
 *
 * To run a query within a React component, call `useGetFileUploadInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileUploadInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileUploadInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFileUploadInfoQuery(baseOptions: Apollo.QueryHookOptions<GetFileUploadInfoQuery, GetFileUploadInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFileUploadInfoQuery, GetFileUploadInfoQueryVariables>(GetFileUploadInfoDocument, options);
      }
export function useGetFileUploadInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFileUploadInfoQuery, GetFileUploadInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFileUploadInfoQuery, GetFileUploadInfoQueryVariables>(GetFileUploadInfoDocument, options);
        }
export type GetFileUploadInfoQueryHookResult = ReturnType<typeof useGetFileUploadInfoQuery>;
export type GetFileUploadInfoLazyQueryHookResult = ReturnType<typeof useGetFileUploadInfoLazyQuery>;
export type GetFileUploadInfoQueryResult = Apollo.QueryResult<GetFileUploadInfoQuery, GetFileUploadInfoQueryVariables>;
export const GetDownloadInfoDocument = gql`
    query getDownloadInfo($fileId: ID!) {
  file(id: $fileId) {
    ...file
  }
}
    ${FileFragmentDoc}`;

/**
 * __useGetDownloadInfoQuery__
 *
 * To run a query within a React component, call `useGetDownloadInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDownloadInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDownloadInfoQuery({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useGetDownloadInfoQuery(baseOptions: Apollo.QueryHookOptions<GetDownloadInfoQuery, GetDownloadInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDownloadInfoQuery, GetDownloadInfoQueryVariables>(GetDownloadInfoDocument, options);
      }
export function useGetDownloadInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDownloadInfoQuery, GetDownloadInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDownloadInfoQuery, GetDownloadInfoQueryVariables>(GetDownloadInfoDocument, options);
        }
export type GetDownloadInfoQueryHookResult = ReturnType<typeof useGetDownloadInfoQuery>;
export type GetDownloadInfoLazyQueryHookResult = ReturnType<typeof useGetDownloadInfoLazyQuery>;
export type GetDownloadInfoQueryResult = Apollo.QueryResult<GetDownloadInfoQuery, GetDownloadInfoQueryVariables>;
export const HandleFileUploadedDocument = gql`
    mutation handleFileUploaded($input: HandleFileUploadedInput!) {
  handleFileUploaded(input: $input) {
    entityType
    entityId
    file {
      ...file
    }
  }
}
    ${FileFragmentDoc}`;
export type HandleFileUploadedMutationFn = Apollo.MutationFunction<HandleFileUploadedMutation, HandleFileUploadedMutationVariables>;

/**
 * __useHandleFileUploadedMutation__
 *
 * To run a mutation, you first call `useHandleFileUploadedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHandleFileUploadedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [handleFileUploadedMutation, { data, loading, error }] = useHandleFileUploadedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHandleFileUploadedMutation(baseOptions?: Apollo.MutationHookOptions<HandleFileUploadedMutation, HandleFileUploadedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HandleFileUploadedMutation, HandleFileUploadedMutationVariables>(HandleFileUploadedDocument, options);
      }
export type HandleFileUploadedMutationHookResult = ReturnType<typeof useHandleFileUploadedMutation>;
export type HandleFileUploadedMutationResult = Apollo.MutationResult<HandleFileUploadedMutation>;
export type HandleFileUploadedMutationOptions = Apollo.BaseMutationOptions<HandleFileUploadedMutation, HandleFileUploadedMutationVariables>;
export const DeleteFileDocument = gql`
    mutation deleteFile($input: DeleteFileInput!) {
  deleteFile(input: $input) {
    entityType
    fileId
  }
}
    `;
export type DeleteFileMutationFn = Apollo.MutationFunction<DeleteFileMutation, DeleteFileMutationVariables>;

/**
 * __useDeleteFileMutation__
 *
 * To run a mutation, you first call `useDeleteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileMutation, { data, loading, error }] = useDeleteFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFileMutation, DeleteFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFileMutation, DeleteFileMutationVariables>(DeleteFileDocument, options);
      }
export type DeleteFileMutationHookResult = ReturnType<typeof useDeleteFileMutation>;
export type DeleteFileMutationResult = Apollo.MutationResult<DeleteFileMutation>;
export type DeleteFileMutationOptions = Apollo.BaseMutationOptions<DeleteFileMutation, DeleteFileMutationVariables>;