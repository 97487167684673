import React, { useMemo, useState } from "react";
import {
  AccountFragment,
  AccountType,
  FinancialInstitutionFragment,
  IntegrationConnectionFragment,
  IntegrationConnectionStatus,
  IntegrationType,
} from "graphql/types";
import { IntegrationsListItemBaseProps } from "../List";
import { usePlaid } from "../plaid";
import { IntegrationDisplayComponent, useFinancialInstitutions } from "../shared";
import { institutions } from "../metadata";
import { SelectStartDateModal } from "../setup/modals/SelectStartDateModal";
import { useActiveCompany } from "components/companies";
import { ConnectSvbOfxAccountModal } from "../setup/modals/ConnectSvbOfxAccountModal";
import { useSvbOfx } from "../svb";
import InstitutionLogo from "./InstitutionLogo";

interface DisplayAccountListItemProps {
  DisplayComponent: IntegrationDisplayComponent<
    React.PropsWithChildren<IntegrationsListItemBaseProps>
  >;
  financialInstitution: FinancialInstitutionFragment;
  account: AccountFragment;
  companyId: string;
}

const isCreditCard = (account: AccountFragment) => {
  return account.type === AccountType.Credit;
};

// resolve the account status between the account and the connection. if either is disconnected, then
// the account should show disconnected, etc
const resolveAccountStatus = (
  account: AccountFragment,
  connection: IntegrationConnectionFragment
) => {
  const hasDisconnection = [account.status, connection.status].some(
    (status) => status === IntegrationConnectionStatus.Disconnected
  );
  const hasError = [account.status, connection.status].some(
    (status) => status === IntegrationConnectionStatus.Error
  );
  if (hasDisconnection) {
    return IntegrationConnectionStatus.Disconnected;
  }
  if (hasError) {
    return IntegrationConnectionStatus.Error;
  }
  return IntegrationConnectionStatus.Ok;
};

const DisplayAccountListItem = ({
  DisplayComponent,
  financialInstitution,
  account,
  companyId,
}: DisplayAccountListItemProps) => {
  const {
    onClickConnect,
    openSelectDate,
    accounts,
    onOpenSelectDateChange,
    isReconnect,
    connectionId,
  } = usePlaid({
    financialInstitutionId: financialInstitution.id,
    companyId,
  });
  const [svbOfxModalOpen, setSvbOfxModalOpen] = useState(false);

  const { company } = useActiveCompany<true>();
  const { disconnectAccount, reconnectAccount } = useFinancialInstitutions();
  const { financialInstitutions: svbOfxFinancialInstitutions } = useSvbOfx({ companyId });

  const institutionName = financialInstitution.name;
  const institutionMetadata = useMemo(
    () =>
      institutions.find(
        (institution) => institution.name.split(" - ")[0] === institutionName.split(" - ")[0]
      ),
    [institutionName]
  );

  const disconnect = () => {
    disconnectAccount(account.id);
  };

  const reconnect = () => {
    if (account.connection?.status === IntegrationConnectionStatus.Disconnected) {
      if (
        svbOfxFinancialInstitutions.some(
          (svbOfxFinancialInstitution) => svbOfxFinancialInstitution.id === financialInstitution.id
        )
      ) {
        setSvbOfxModalOpen(true);
      }

      onClickConnect();
    } else {
      reconnectAccount(account.id);
    }
  };

  let subtitle = account.name;
  if (account.mask) {
    subtitle += ` - ${account.mask}`;
  }

  let integrationType = IntegrationType.Plaid;

  // prefer the account's connection status over the institutions status
  let connection = undefined;
  if (account.connection) {
    connection = { ...account.connection };
    connection.status = resolveAccountStatus(account, connection);
    integrationType = connection.type;
  }

  return (
    <>
      <DisplayComponent
        title={financialInstitution.name}
        connectIntegration={reconnect}
        disconnectIntegration={disconnect}
        subtitle={subtitle}
        logo={<InstitutionLogo institution={{ name: institutionName }} />}
        connection={connection}
        account={account}
        integrationType={integrationType}
      />

      <SelectStartDateModal
        companyId={company.id}
        open={openSelectDate}
        accounts={accounts}
        onOpenChange={onOpenSelectDateChange}
        isReconnect={isReconnect}
        connectionId={connectionId}
        accountsLoading={accounts.length === 0 ?? false}
      />

      <ConnectSvbOfxAccountModal
        companyId={company.id}
        open={svbOfxModalOpen}
        onOpenChange={setSvbOfxModalOpen}
        isOnboarding={false}
        onConnect={() => {
          setSvbOfxModalOpen(false);
        }}
        onClose={() => {
          setSvbOfxModalOpen(false);
        }}
      />
    </>
  );
};

export default DisplayAccountListItem;
