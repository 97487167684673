import { Tag } from "@puzzle/ui";
import React from "react";

export const AlphaTag = () => {
  return (
    <Tag variant="outlined" color="vivid" style={{ height: 16 }}>
      Alpha
    </Tag>
  );
};
