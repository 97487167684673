import React from "react";
import { IntegrationLogo } from "../../shared";
import { BMO } from "@puzzle/icons";

const BMOLogo = () => {
  return (
    <IntegrationLogo color="#ed3124">
      <BMO />
    </IntegrationLogo>
  );
};

export default BMOLogo;
