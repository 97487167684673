import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Processing({
  viewBox = "0 0 16 16",
  width = 16,
  height = 16,
  color = "currentColor",
  fill = color,
}: IconProps) {
  return (
    <Icon width={width} height={height} fill="none" viewBox={viewBox}>
      <mask
        id="mask0_64_2381"
        style={{ maskType: "alpha" }}
        width={width}
        height={height}
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill={fill} d="M0 0H16V16H0z"></path>
      </mask>
      <g mask="url(#mask0_64_2381)">
        <path
          fill={fill}
          d="M2.667 13.333V12H4.5l-.267-.233c-.577-.511-.983-1.095-1.216-1.75a5.878 5.878 0 01-.35-1.984c0-1.233.37-2.33 1.108-3.291a5.21 5.21 0 012.892-1.909v1.4c-.8.29-1.445.78-1.934 1.475A3.945 3.945 0 004 8.033c0 .5.094.986.283 1.459.19.472.484.908.884 1.308l.166.167V9.333h1.334v4h-4zm6.666-.166v-1.4c.8-.29 1.445-.78 1.934-1.475.489-.695.733-1.47.733-2.325 0-.5-.095-.986-.283-1.459a3.894 3.894 0 00-.884-1.308l-.166-.167v1.634H9.333v-4h4V4H11.5l.267.233c.544.545.941 1.136 1.191 1.775.25.64.375 1.292.375 1.959 0 1.233-.37 2.33-1.108 3.291a5.21 5.21 0 01-2.892 1.909z"
        ></path>
      </g>
    </Icon>
  );
}
