import { IntegrationType } from "graphql/types";
import React, { useCallback, useEffect, useState } from "react";
import Logo from "./Logo";
import { IntegrationsListItemBaseProps } from "../List";
import { useBillDotComIntegrationQuery } from "graphql/queries/integrations/billDotComIntegration.generated";
import { ImportBills } from "components/dashboard/Accounting/Bills/Import/ImportBills";
import { IntegrationDisplayComponent, useFinancialInstitutions } from "../shared";

interface SetupBillDotComListItemProps {
  DisplayComponent: IntegrationDisplayComponent<
    React.PropsWithChildren<IntegrationsListItemBaseProps>
  >;
  companyId: string;
  onConnect?: () => void;
}

export const TITLE = "BILL";
export const SUBTITLE = "Link invoice, accounts payable and accounts receivable data.";

const SetupBillDotComListItem = ({
  DisplayComponent,
  companyId,
  onConnect,
}: SetupBillDotComListItemProps) => {
  const [showWizard, setShowWizard] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);

  const { data, loading } = useBillDotComIntegrationQuery({ variables: { companyId } });
  const { refetchAccountsAndIntegrations } = useFinancialInstitutions();

  const onClickConnect = useCallback(() => {
    setShowWizard(true);
    setIsConnecting(true);
  }, []);

  useEffect(() => {
    if (!showWizard && isConnecting) {
      refetchAccountsAndIntegrations();
      onConnect?.();
    }
  }, [showWizard, isConnecting, refetchAccountsAndIntegrations, onConnect]);

  return (
    <>
      <DisplayComponent
        integrationType={IntegrationType.BillDotCom}
        connectIntegration={onClickConnect}
        title={TITLE}
        logo={<Logo />}
        connection={data?.integrations.billDotCom?.connection}
        loading={loading}
      />
      <ImportBills open={showWizard} onOpenChange={setShowWizard} mode="billDotCom" />
    </>
  );
};

export default SetupBillDotComListItem;
