/* eslint-disable react/display-name */
import React, { useState, useMemo } from "react";
import { CategoryModal } from "components/transactions";
import { CategoryFragment } from "graphql/types";
import Tag from "components/common/Tag";
import { Text, Tooltip, TooltipProps } from "@puzzle/ui";
import { BasicTransactionFragment } from "../dashboard/Transactions/graphql.generated";
import { CategorySteps } from "./CategoryModal";
import { toCalendarMonthString, zIndex } from "@puzzle/utils";
import { WarningDialog } from "components/dashboard/Accounting/LockedPeriods/WarningDialog";
import { LockDayWarning } from "components/dashboard/Accounting/LockedPeriods/LockedPeriod";
import { useActiveCompany } from "components/companies";
import { parseDate } from "@internationalized/date";
import { Bill } from "@puzzle/icons";
import { Box, vars } from "ve";

import { nonEditContainerBase, nonEditContainerAlt } from "./selectCategoryInput.css";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";

interface SelectCategoryInputProps {
  categories: CategoryFragment[];
  value: CategoryFragment;
  onChange: (c: CategoryFragment) => void;
  canEdit: boolean;
  wrap?: boolean;
  transaction?: BasicTransactionFragment | null;
  capitalizable?: boolean;
  isBillPayment?: boolean;
  isInvoicePayment?: boolean;
  maxWidth?: number;
  tooltipProps?: TooltipProps;
  altStyle?: boolean;
}

const SelectCategoryInput = React.forwardRef<HTMLButtonElement, SelectCategoryInputProps>(
  (
    {
      categories,
      onChange,
      value,
      canEdit,
      wrap,
      transaction,
      isBillPayment = false,
      isInvoicePayment = false,
      maxWidth,
      tooltipProps,
      altStyle = false,
    },
    ref
  ) => {
    const { lockedPeriodDate, isWithinLockedPeriod } = useActiveCompany<true>();
    const [showWarning, setShowWarning] = useState(false);
    const showLockWarning = useMemo(
      () =>
        transaction &&
        isWithinLockedPeriod(parseDate(transaction.date)) &&
        !transaction.detail.postedAt,
      [isWithinLockedPeriod, transaction]
    );

    if (!canEdit) {
      const NonEditContainer = altStyle ? "div" : "span";

      return (
        <NonEditContainer className={altStyle ? nonEditContainerAlt : nonEditContainerBase}>
          {(isInvoicePayment || isBillPayment) && (
            <Box css={{ paddingTop: 1 }}>
              <Bill style={{ height: 12, width: 20 }} />
            </Box>
          )}
          <Text
            color="gray100"
            size="bodyS"
            weight={altStyle ? "normal" : undefined}
            css={{ whiteSpace: "nowrap" }}
          >
            {value?.name}
          </Text>
        </NonEditContainer>
      );
    }

    if (showLockWarning && lockedPeriodDate) {
      return (
        <>
          <Text
            color="gray100"
            size="bodyS"
            onClick={(e) => {
              e.stopPropagation();
              if (!transaction?.detail.postedAt) {
                setShowWarning(true);
              }
            }}
          >
            {value?.name}
          </Text>
          <WarningDialog
            period={toCalendarMonthString(lockedPeriodDate)}
            warnings={[LockDayWarning.TransactionWarning]}
            open={showWarning}
            onOpenChange={setShowWarning}
            transactionDate={transaction?.date ? parseDate(transaction.date) : null}
            transactionId={transaction?.id}
          />
        </>
      );
    }

    const Noop = ({ children }: React.PropsWithChildren<unknown>) => <>{children}</>;
    const Wrapper = tooltipProps
      ? ({ children }: React.PropsWithChildren<unknown>) => (
          <Tooltip content={value.name} arrow={false} {...tooltipProps}>
            {children}
          </Tooltip>
        )
      : Noop;

    return (
      <div style={altStyle ? { width: "100%" } : undefined}>
        <CategoryModal
          style={{
            zIndex: isPosthogFeatureFlagEnabled(FeatureFlag.Z) ? zIndex("modal") : "auto",
          }}
          trigger={
            <div
              onClick={(e) => {
                // Don't open the drawer
                e.stopPropagation();
              }}
            >
              <Wrapper>
                <Tag
                  role="button"
                  wrap={wrap}
                  variant={altStyle ? "j2_outlined" : "pill"}
                  maxWidth={altStyle ? undefined : maxWidth}
                  css={{ borderRadius: vars.radii[2], width: altStyle ? "100%" : undefined }}
                >
                  {isInvoicePayment ||
                    (isBillPayment && <Bill style={{ height: 14, width: 14 }} />)}
                  <Text
                    size={altStyle ? "bodyS" : undefined}
                    weight={altStyle ? "normal" : undefined}
                  >
                    {value.name}
                  </Text>
                </Tag>
              </Wrapper>
            </div>
          }
          categories={categories}
          initialValue={value}
          transaction={transaction}
          onChange={onChange}
          initialStep={CategorySteps.Category}
        />
      </div>
    );
  }
);

export default SelectCategoryInput;
