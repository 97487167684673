import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Split({
  viewBox = "0 0 18 18",
  fill = "currentColor",
  ...iconProps
}: IconProps) {
  return (
    <Icon fill={fill} {...iconProps} viewBox={viewBox}>
      <path
        fill={fill}
        d="M9 2a1 1 0 011 1v1.082c.308.058.609.148.897.27a4.19 4.19 0 011.57 1.13 1 1 0 11-1.52 1.301 2.19 2.19 0 00-.821-.587 2.588 2.588 0 00-1.122-.194c-.66 0-1.212.18-1.57.418-.365.243-.434.468-.434.581 0 .115.07.34.433.583.356.238.906.417 1.565.417.998 0 1.947.268 2.676.753.722.48 1.326 1.254 1.326 2.245 0 .991-.604 1.765-1.326 2.245a4.541 4.541 0 01-1.674.661V15a1 1 0 11-2 0v-1.082a4.536 4.536 0 01-.897-.269 4.197 4.197 0 01-1.57-1.13 1 1 0 111.517-1.303c.208.243.489.448.824.588a2.593 2.593 0 001.124.194c.658 0 1.21-.18 1.568-.418.365-.244.434-.469.434-.581 0-.112-.069-.337-.434-.58-.357-.238-.91-.418-1.568-.418-.999 0-1.947-.268-2.676-.754C5.601 8.766 5 7.991 5 7.001c0-.991.604-1.766 1.326-2.246A4.542 4.542 0 018 4.095V3a1 1 0 011-1z"
      ></path>
      <path
        fill={fill}
        d="M15 2.25h-3.747L13.05 3.6a6.76 6.76 0 012.45 3.575l.194.949A6.739 6.739 0 0113.05 14.4a.75.75 0 00.9 1.2 8.238 8.238 0 003.227-7.7 8.22 8.22 0 00-1.868-4.218A.75.75 0 0015 2.25zM4.95 3.6a.75.75 0 10-.901-1.2A8.239 8.239 0 00.75 9c0 2.026.73 3.88 1.941 5.316A.75.75 0 003 15.75h3.746L4.95 14.4A6.739 6.739 0 012.25 9a6.739 6.739 0 012.7-5.4z"
      ></path>
    </Icon>
  );
}
