import React from "react";

export default function CreditCard3() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <g>
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M2.292 8.125v6.247c0 .92.746 1.666 1.666 1.666h12.08c.92 0 1.667-.746 1.667-1.666V8.125m-15.413 0V5.627c0-.92.746-1.666 1.666-1.666H16.04c.918 0 1.664.743 1.665 1.662v2.502m-15.413 0h15.413m-12.08 2.917h2.5"
          opacity="0.25"
        ></path>
      </g>
    </svg>
  );
}
