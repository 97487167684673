import React, { useCallback, useMemo } from "react";
import { IntegrationType } from "graphql/types";
import { isEqual } from "lodash";

/**
 * Stores connections for which a connect mutation has been sent, under the assumption
 * that the connection will be successful.
 *
 * this is instead of an optimistic response, which is another way this could be done.
 * i chose this method because I would have needed to make up a lot of data for the optimistic response,
 * such as the id and most other required fields. This seemed fallible, something might try to use that data
 * not knowing that it was made up.
 *
 * My jury is out whether this was the correct decision, so this may change in the future.
 */

const PendingConnectionsContext = React.createContext<{
  isPending: (type: IntegrationType, nativeInstitutionId?: string) => boolean;
  addPendingConnection: (type: IntegrationType, nativeInstitutionId?: string) => void;
  removePendingConnection: (type: IntegrationType, nativeInstitutionId?: string) => void;
  pendingAccountType?: IntegrationType;
  pendingAccountsCount: number;
}>({
  isPending: (type: IntegrationType, nativeInstitutionId?: string) => false,
  addPendingConnection: (type: IntegrationType, nativeInstitutionId?: string) => ({}),
  removePendingConnection: (type: IntegrationType, nativeInstitutionId?: string) => ({}),
  pendingAccountType: undefined,
  pendingAccountsCount: 0,
});

interface PendingConnectionsProviderProps {
  children: React.ReactNode;
}

const PendingConnectionsProvider = ({ children }: PendingConnectionsProviderProps) => {
  const [connections, setConnections] = React.useState<
    { type: IntegrationType; nativeInstitutionId?: string }[]
  >([]);

  const addPendingConnection = useCallback(
    (type: IntegrationType, nativeInstitutionId?: string) => {
      setConnections((connections) => [...connections, { type, nativeInstitutionId }]);
    },
    []
  );

  const removePendingConnection = useCallback(
    (type: IntegrationType, nativeInstitutionId?: string) => {
      setConnections((connections) =>
        connections.filter((c) => !isEqual(c, { type, nativeInstitutionId }))
      );
    },
    []
  );

  const isPending = useCallback(
    (type: IntegrationType, nativeInstitutionId?: string) => {
      if (!nativeInstitutionId) {
        // if any of the connections are of that type, then it is pending
        return !!connections.find((c) => c.type === type);
      }
      return !!connections.find((c) => isEqual(c, { type, nativeInstitutionId }));
    },
    [connections]
  );

  const pendingStates = useMemo(
    () => ({
      // Banks & Credit Cards
      Brex: isPending(IntegrationType.Brex),
      Meow: isPending(IntegrationType.Meow),
      Mercury: isPending(IntegrationType.Mercury),
      Ramp: isPending(IntegrationType.Ramp),

      // Payroll
      Gusto: isPending(IntegrationType.Gusto),
      Rippling: isPending(IntegrationType.Rippling),
      Deel: isPending(IntegrationType.Deel),

      // Payments
      Stripe: isPending(IntegrationType.Stripe),

      // Other
      Every: isPending(IntegrationType.Every),
      QuickBooks: isPending(IntegrationType.QuickBooks),
      Plaid: isPending(IntegrationType.Plaid),
    }),
    [isPending]
  );

  const pendingAccounts = Object.entries(pendingStates).find(([type, loading]) => loading);

  return (
    <PendingConnectionsContext.Provider
      value={{
        isPending,
        addPendingConnection,
        removePendingConnection,
        pendingAccountType: pendingAccounts?.[0] as IntegrationType | undefined,
        pendingAccountsCount: pendingAccounts?.length || 0,
      }}
    >
      {children}
    </PendingConnectionsContext.Provider>
  );
};

export default PendingConnectionsProvider;

export const usePendingConnections = () => React.useContext(PendingConnectionsContext);
