import React, { useEffect, useMemo } from "react";
import { useIntercom } from "react-use-intercom";
import { isNil, omitBy } from "lodash";
import { useUser } from "@auth0/nextjs-auth0";

import { ToastProvider } from "@puzzle/ui";
import Analytics, {
  usePageAnalytics,
  FeatureFlag,
  isPosthogFeatureFlagEnabled,
} from "lib/analytics";

import VerifyRouteAccessProvider from "lib/VerifyRouteAccessProvider";
import { ReportContextProvider } from "components/reports/ReportContext";
import { StickyReportContextProvider } from "components/reports/StickyReportContext";
import PendingConnectionsProvider from "components/integrations/shared/PendingConnectionsProvider";
import ActiveCompanyProvider, {
  useActiveCompany,
} from "components/companies/ActiveCompanyProvider";
import useSelf from "components/users/useSelf";
import useThirdPartyLogin from "components/partners/useThirdPartyLogin";
import { SelfFragment } from "components/users/graphql.generated";
import { ActiveCompanyFragment } from "components/companies";
import { FitTestProvider } from "components/fitTest/useFitTest";
import { FinancialInstitutionsProvider } from "../integrations";
import { AccountType, useAccountsWithIntegrationsQuery } from "graphql/types";
import { reportError } from "lib/errors";
import { InboxProvider } from "components/dashboard/Inbox";

// Persistence is hard until Next.js's layout solution comes out
// This prevents duplicate identify calls
let lastTrackedUser: SelfFragment | null = null;
let lastTrackedCompany: ActiveCompanyFragment | null = null;

/**
 * This listens to user/company changes to perform `identify` calls.
 * This targets Analytics and Intercom.
 * This is only a component to more easily use parent contexts.
 */
const UserTracker = () => {
  const intercom = useIntercom();
  const { thirdParty } = useThirdPartyLogin();
  const { user: userProfile } = useUser();
  const { self, intercomHash } = useSelf();
  const { company, membership, memberships } = useActiveCompany<true>();

  const { data: accountsData } = useAccountsWithIntegrationsQuery({
    skip: !company?.id,
    variables: {
      filter: {
        companyId: company?.id,
        types: [
          AccountType.Depository,
          AccountType.Credit,
          AccountType.Payroll,
          AccountType.PaymentProcessing,
        ],
      },
    },
  });

  const accounts = useMemo(() => {
    if (isNil(accountsData)) return undefined;
    const banks = new Set();
    const credit = new Set();
    const payroll = new Set();
    const payment = new Set();
    for (const account of accountsData.accounts) {
      const accountName =
        account.connection?.type === "Plaid"
          ? `Plaid - ${account.financialInstitution.name}`
          : account.connection?.type ?? account.financialInstitution.name;
      switch (account.type) {
        case AccountType.Depository:
          banks.add(accountName);
          break;
        case AccountType.Credit:
          credit.add(accountName);
          break;
        case AccountType.Payroll:
          payroll.add(accountName);
          break;
        case AccountType.PaymentProcessing:
          payment.add(accountName);
          break;
        default:
          reportError(`Unknown account type ${account.type}`);
          break;
      }
    }
    return {
      banks: Array.from(banks),
      credit: Array.from(credit),
      payroll: Array.from(payroll),
      payment: Array.from(payment),
    };
  }, [accountsData]);

  useEffect(() => {
    if (self && self !== lastTrackedUser) {
      Analytics.identifyUser(self, userProfile, memberships, membership, company);
      lastTrackedUser = self;
    }

    if (company && company !== lastTrackedCompany) {
      Analytics.identifyCompany(
        company,
        membership,
        omitBy({ partner: thirdParty }, isNil),
        accounts
      );
      lastTrackedCompany = company;
    }

    intercom.boot({
      hideDefaultLauncher: isPosthogFeatureFlagEnabled(FeatureFlag.HeaderHelpMenu) ? true : false,
      userId: self?.id ?? undefined,
      userHash: intercomHash ?? undefined,
      email: self?.email ?? undefined,
      createdAt: self?.createdAt ? new Date(self.createdAt).getTime().toString() : undefined,
      name: userProfile?.name ?? undefined,
      avatar: userProfile?.picture
        ? {
            type: "avatar",
            imageUrl: userProfile.picture,
          }
        : undefined,
      company: company
        ? {
            companyId: company.id ?? undefined,
            name: company.name,
            industry: company.type,
            createdAt: new Date(company.createdAt).getTime().toString(),
          }
        : undefined,
    });
  }, [
    company,
    intercom,
    intercomHash,
    self,
    thirdParty,
    userProfile,
    membership,
    memberships,
    accounts,
  ]);

  // Moving the pageview event here so it has access to user info
  // <UserTracker /> appears to run for every child component
  //
  // self.id is the "device_id" for anonymouns users and becomes "distinct_id"
  // inside PostHog.
  usePageAnalytics(self?.email || userProfile?.email, self?.id);

  return null;
};

const DataProviders = ({ children }: React.PropsWithChildren<unknown>) => {
  return (
    <ActiveCompanyProvider>
      <InboxProvider>
        <FinancialInstitutionsProvider>
          <PendingConnectionsProvider>
            <ReportContextProvider>
              <StickyReportContextProvider>
                <FitTestProvider>
                  <VerifyRouteAccessProvider>
                    <UserTracker />
                    {children}
                  </VerifyRouteAccessProvider>
                </FitTestProvider>
              </StickyReportContextProvider>
            </ReportContextProvider>
          </PendingConnectionsProvider>
        </FinancialInstitutionsProvider>
      </InboxProvider>
    </ActiveCompanyProvider>
  );
};

const Noop = ({ children }: React.PropsWithChildren<unknown>) => <>{children}</>;

const AppProviders = ({
  children,
  disableDataProviders = false,
}: React.PropsWithChildren<{
  disableDataProviders?: boolean;
}>) => {
  const OtherProviders = disableDataProviders ? Noop : DataProviders;

  return (
    <ToastProvider>
      <OtherProviders>{children}</OtherProviders>
    </ToastProvider>
  );
};

export default AppProviders;
