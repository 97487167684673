import React from "react";

export default function BankOutline2() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <g opacity="0.25">
        <path
          stroke="#fff"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M16.042 7.708v6.667m-3.334 0V7.708m-8.75 0v6.667m3.334 0V7.708m8.382 6.667H4.326c-.717 0-1.354.459-1.58 1.14l-.089.263a.833.833 0 00.79 1.097h13.105c.569 0 .97-.557.79-1.097l-.087-.263a1.667 1.667 0 00-1.581-1.14zm.963-6.667H3.363A1.072 1.072 0 012.88 5.68L9.25 2.463a1.667 1.667 0 011.503 0L17.12 5.68a1.071 1.071 0 01-.483 2.028z"
        ></path>
      </g>
    </svg>
  );
}
