import React from "react";

import { Exclamation } from "@puzzle/icons";

import { Spinner } from "components/common/Loader";
import { color, S, Box, Stack, Text, Button, vars } from "ve";

import Link from "components/common/Link";
import { Route } from "lib/routes";
import { useActiveCompany } from "components/companies";
import { useInboxContext } from "../InboxContext";
import { PLANS } from "components/monetization/PricingModal/plans";
import UpgradeButton from "components/featureGate/UpgradeButton";
import { useBilling } from "components/settings/Billing/useBilling";
import { FORMATION_SPENDING_LIMIT } from "components/settings/Billing/util";

const ProgressBar = ({ progress, exceeding }: { progress: number; exceeding: boolean }) => {
  const clampedProgress = Math.min(Math.max(progress, 0), 100);

  return (
    <Box
      css={{
        width: "100%",
        height: "6px",
        backgroundColor: exceeding ? color.red500 : color.black,
        borderRadius: vars.radii[1],
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Box
        css={{
          width: `${clampedProgress}%`,
          height: "100%",
          backgroundColor: color.green600,
          borderRadius: "6px",
        }}
      />
    </Box>
  );
};

const Separator = () => {
  return <Box css={{ width: "100%", height: "1px", backgroundColor: color.white50 }} />;
};

export const MonthlyExpensesExceededPane = () => {
  const { handleArchivalClick } = useInboxContext();
  const { currentSubscription, isOnPaidPlan, loading: companyLoading } = useActiveCompany<true>();
  const { monthlySpendingAverage, loading: billingLoading, progress, exceeding } = useBilling();
  const currentPlan = PLANS.find((p) => p.id === currentSubscription);

  if (billingLoading || companyLoading) {
    return <Spinner css={{ height: "100px", width: "100px" }} />;
  }

  return (
    <Stack
      gap={S["$3"]}
      css={{
        height: "100%",
        padding: S.$7h,
        alignItems: "flex-start",
      }}
    >
      {isOnPaidPlan && (
        <>
          <Stack
            direction="horizontal"
            css={{ width: "100%", justifyContent: "space-between", alignItems: "center" }}
          >
            <Text variant="heading3" color="gray200">
              Thank you for upgrading!
            </Text>
            <Button
              size="compact"
              onClick={(event) =>
                handleArchivalClick({
                  event,
                })
              }
            >
              Mark as completed
            </Button>
          </Stack>
          <Text variant="bodyM" color="gray400">
            Looks like you've successfully upgraded, congrats! You can safely ignore the information
            below.
          </Text>
        </>
      )}

      <Stack
        gap={S["$2"]}
        css={{
          width: "100%",
          padding: S.$2,
          alignItems: "flex-start",
          alignSelf: "flex-start",
          border: "1px solid #4F4666", // yet another one-off colour
          borderRadius: "8px",
        }}
      >
        <Text variant="bodyM" color="gray200">
          Your current plan
        </Text>
        <Text variant="heading1" color="gray200">
          {currentPlan?.title}
        </Text>
        <Text variant="bodyXL" color="gray200">
          {isOnPaidPlan ? "Paid" : "Free"}
        </Text>

        <Separator />

        <Stack gap={S.$1h}>
          <Text variant="bodyM" color="gray200">
            Average Expenses in the last completed 3 months
          </Text>
          <Box css={{ width: "240px" }}>
            <ProgressBar progress={progress} exceeding={exceeding} />
          </Box>
          <div>
            <Text variant="bodyM" color="red500">
              ${Number(monthlySpendingAverage || 0).toLocaleString()}
            </Text>
            <Text variant="bodyM" color="gray400">
              {" / "}${Number(FORMATION_SPENDING_LIMIT).toLocaleString()}
            </Text>
          </div>
          <Text variant="bodyM" color="gray400">
            For a detailed view of your expenses check out{" "}
            <Link color="blue500" href={Route.spending}>
              Spend Explorer
            </Link>
          </Text>
        </Stack>

        <Separator />

        <Stack
          css={{
            backgroundColor: color.red100,
            color: color.red1000,
            padding: S.$2,
            borderRadius: "8px",
          }}
        >
          <Stack direction="horizontal">
            <Exclamation width={16} height={16} />
            <Text variant="bodyM" weight="extrabold" color="error">
              Monthly Expense Allowance Exceeded
            </Text>
          </Stack>
          <Text variant="bodyM" weight="bold" color="error">
            It looks like you've exceeded the monthly expense allowance of $15,000 for the Formation
            plan. To access newly generated financial statements, reports, and insights, please
            upgrade to a paid plan.
          </Text>
        </Stack>
        <UpgradeButton showIcon={false} css={{ width: "100%" }} />
      </Stack>
    </Stack>
  );
};
