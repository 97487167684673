import React from "react";
import { styled, Button } from "@puzzle/ui";
import useAppRouter from "lib/useAppRouter";
import Analytics from "lib/analytics";

const Root = styled("div", {
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: "0 $5h",
  justifyContent: "center",
});

const Card = styled("div", {
  background: "$mauve950",
  border: "1px solid $mauve550",
  borderRadius: "4px",
  width: "100%",
  padding: "$2h",
});

const Header = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  borderBottom: "1px solid $mauve550",
  paddingBottom: "$1h",
  marginBottom: "$2",

  fontWeight: "$bold",
  fontSize: "15px",
  lineHeight: "22px",
  alignItems: "center",
  height: "calc($space$3 + $1h)",

  color: "$gray100",
});

const Description = styled("dd", {
  color: "$gray100",
  margin: 0,
});

const Footer = styled("div", {
  marginTop: "$3",
  display: "flex",
  alignItems: "center",
  gap: "$1h",
});

export const InviteUsersPane = () => {
  const { goToUsers } = useAppRouter();

  const navToUserManagement = () => {
    Analytics.inviteYourTeamInboxCtaClicked();
    goToUsers();
  };

  return (
    <Root>
      <Card>
        <Header>Invite your team</Header>

        <Description>
          Puzzle is better with your bookkeeper and teammates. Invite them to help you categorize,
          close your books, and increase visibility into your financial situation.
        </Description>

        <Footer>
          <Button size="mini" variant="secondary" onClick={() => navToUserManagement()}>
            Go to User management
          </Button>
        </Footer>
      </Card>
    </Root>
  );
};
