import { UseFormReturn } from "react-hook-form";
import { sumPeriodTotal } from "../../shared";
import { FormValues, LineField } from "../../InvoiceForm/types";
import { ContractRevenueScheduleFragment } from "../../graphql.generated";

export const useAmount = ({
  lineIndex,
  lineField,
  existingSchedule,
  parentForm,
}: {
  lineIndex: number;
  lineField?: LineField;
  existingSchedule?: ContractRevenueScheduleFragment;
  parentForm?: UseFormReturn<FormValues>;
}) => {
  const amount =
    (lineField && parentForm?.watch(`${lineField}.${lineIndex}.amount`)?.toString()) ??
    sumPeriodTotal(existingSchedule?.periods).toString();

  return amount;
};
