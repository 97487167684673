import React from "react";
import Icon, { IconProps } from "./Icon";

export default function LockTransaction({
  viewBox = "0 0 10 11",
  width = 10,
  height = 11,
  color = "currentColor",
  fill = color,
}: IconProps) {
  return (
    <Icon width={width} height={height} fill={fill} viewBox={viewBox}>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M1.875 4.889V3.36c0-.855.303-1.695.873-2.332A3.024 3.024 0 015 0c.872 0 1.679.388 2.252 1.029.57.637.873 1.477.873 2.332V4.89h.625c.69 0 1.25.547 1.25 1.222v3.667C10 10.453 9.44 11 8.75 11h-7.5C.56 11 0 10.453 0 9.778V6.11C0 5.436.56 4.89 1.25 4.89h.625zm2.284-2.653c.247-.276.553-.403.841-.403.288 0 .594.127.841.403.25.28.409.683.409 1.125V4.89h-2.5V3.36c0-.442.158-.845.409-1.125z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
}
