import {
  WebTracerProvider,
  Span,
  BatchSpanProcessor,
  SpanExporter,
  SimpleSpanProcessor,
} from "@opentelemetry/sdk-trace-web";
import { getWebAutoInstrumentations } from "@opentelemetry/auto-instrumentations-web";
import { Resource } from "@opentelemetry/resources";
import {
  SEMRESATTRS_SERVICE_NAME,
  SEMRESATTRS_SERVICE_VERSION,
} from "@opentelemetry/semantic-conventions";
import { registerInstrumentations } from "@opentelemetry/instrumentation";
import packageInfo from "../../package.json";
import { StructuredLogSpanExporter } from "./structuredSpanExporter";

const traceConfig = {
  serviceName: packageInfo.name,
  serviceVersion: packageInfo.version,
  // Only want to sample about 10% of traces
  traceSampler: process.env.TRACE_SAMPLER || "parentbased_traceidratio",
  traceIdRatio: process.env.TRACE_ID_RATIO || "0.1",
};

const getCompanyId = (): string => {
  if (typeof window !== "undefined" && window.localStorage) {
    const companyId = window.localStorage.getItem("activeCompany") || "";
    try {
      return companyId ? JSON.parse(companyId) : "";
    } catch (error) {
      return companyId;
    }
  }
  return "";
};
let companyId = "";

export const resetTraceCompanyId = () => {
  companyId = getCompanyId();
};

const addAttributes = (span: Span) => {
  span.setAttribute("page.path", window.location.pathname);
  span.setAttribute("page.params", window.location.search);
  span.setAttribute("page.origin", window.location.origin);
  span.setAttribute("company.id", companyId);
};
class CustomBatchSpanProcessor extends BatchSpanProcessor {
  onStart(span: Span) {
    addAttributes(span);
  }
}

class CustomSimpleSpanProcessor extends SimpleSpanProcessor {
  onStart(span: Span) {
    addAttributes(span);
  }
}

const getExporter = async () => {
  if (process.env.PUZZLE_ENV === "local") {
    const ZipkinExporter = (await import("@opentelemetry/exporter-zipkin")).ZipkinExporter;
    return new ZipkinExporter();
  }

  return new StructuredLogSpanExporter();
};

const getProcessor = (exporter: SpanExporter) => {
  if (process.env.PUZZLE_ENV === "local") {
    return new CustomSimpleSpanProcessor(exporter);
  }
  return new CustomBatchSpanProcessor(exporter);
};

export const startTracing = async () => {
  // console.log("Tracing enabled");
  const resourceSettings = new Resource({
    [SEMRESATTRS_SERVICE_NAME]: traceConfig.serviceName,
    [SEMRESATTRS_SERVICE_VERSION]: traceConfig.serviceVersion,
  });
  companyId = getCompanyId();

  process.env.OTEL_TRACES_SAMPLER = traceConfig.traceSampler;
  process.env.OTEL_TRACES_SAMPLER_ARG = traceConfig.traceIdRatio;

  const provider = new WebTracerProvider({ resource: resourceSettings });
  const exporter = await getExporter();

  const processor = getProcessor(exporter);
  provider.addSpanProcessor(processor);

  provider.register();

  registerInstrumentations({
    instrumentations: [
      getWebAutoInstrumentations({
        "@opentelemetry/instrumentation-document-load": {
          enabled: true,
        },
        "@opentelemetry/instrumentation-xml-http-request": {
          enabled: true,
        },
        "@opentelemetry/instrumentation-user-interaction": {
          enabled: false,
        },
      }),
    ],
  });
};
