import React, { createContext, ReactNode, useContext, useMemo, useState } from "react";
import { parseCookies, setCookie } from "nookies";
import { Cookies } from "lib/cookies";
import { getApiPartnerSettings, getThirdPartyLoginCookie } from "lib/partnerCookie";

const getPassedFitTestCookie = () => {
  return parseCookies()[Cookies.PassedFitTest];
};

const setPassedFitTestCookie = (passed: boolean) => {
  setCookie(null, Cookies.PassedFitTest, String(passed));
};

const FitTestContext = createContext<
  { passedFitTest: boolean; setPassedFitTest: (passed: boolean) => void } | undefined
>(undefined);

interface FitTestProviderProps {
  children: ReactNode;
}

// TODO This... doesn't need to be a context.
export const FitTestProvider = ({ children }: FitTestProviderProps) => {
  // Just check the existence of the cookie
  const [passedFitTest, setPassedFitTest] = useState(() => !!getPassedFitTestCookie());
  const thirdPartyLogin = getThirdPartyLoginCookie();
  const apiPartnerSettings = getApiPartnerSettings();
  const bypassFitTest =
    thirdPartyLogin && apiPartnerSettings && !apiPartnerSettings.requiresFitTest;

  const value = useMemo(
    () => ({
      passedFitTest: bypassFitTest || passedFitTest,
      setPassedFitTest: (passed: boolean) => {
        setPassedFitTest(passed);
        setPassedFitTestCookie(passed);
      },
    }),
    [bypassFitTest, passedFitTest]
  );

  return <FitTestContext.Provider value={value}>{children}</FitTestContext.Provider>;
};

export const useFitTest = () => {
  const context = useContext(FitTestContext);
  if (typeof context === "undefined") {
    throw new Error("useFitTest must be used inside FitTestProvider.");
  }
  return context;
};
